import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Datetime from 'react-datetime';
import { I18n } from 'react-redux-i18n';

import './datetime-picker.scss';

class DatetimePicker extends React.Component {

    static propTypes = {
        initialValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(Date),
            PropTypes.instanceOf(moment),
        ]),
        placeholder: PropTypes.string,
        autoComplete: PropTypes.string,
        name: PropTypes.string,
        required: PropTypes.bool,
        readOnly: PropTypes.bool,
        onInvalid: PropTypes.func,
        open: PropTypes.bool,
        onChange: PropTypes.func,
        innerRef: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.func,
            PropTypes.string
        ]),
        dateFormat: PropTypes.string,
        timeFormat: PropTypes.string,
    }

    static defaultProps = {
        initialValue: undefined,
        placeholder: 'Now',
        autoComplete: 'off',
        name: undefined,
        required: false,
        readOnly: false,
        open: undefined,
        onInvalid: () => {},
        onChange: () => {},
        innerRef: undefined,
        dateFormat: I18n.t('datetime_format.date'),
        timeFormat: I18n.t('datetime_format.time'),
    }

    constructor(props) {
        super(props);
        // ref for inside of DatetimePicker, whereas innerRef is for outsiders
        this.elInput = React.createRef();
    }

    componentDidMount() {
        const { innerRef } = this.props;
        if (innerRef) {
            innerRef.current = new Proxy(this.elInput.current || {}, {
                get: (obj, prop) => {
                    let ret = obj[prop];

                    if (prop === 'value') {
                        ret = ret ? moment(ret) : moment();
                    };

                    return ret;
                },
            });
        }
    }

    handleChange = datetime => {
        const { innerRef, onChange } = this.props;
        onChange(innerRef.current.value);

        if (!datetime) {
            this.elInput.current.value = '';
        }
    }

    renderInput = inputProps => {
        return (
            <div className="input-wrap">
                <input { ...inputProps } ref={ this.elInput } />
                <button className="btn-reset fas fa-times" type="button" onClick={ () => this.handleChange() } />
            </div>
        );
    }

    render() {
        const { placeholder, open, dateFormat, timeFormat, initialValue } = this.props;
        const { autoComplete, name, readOnly, required, onInvalid, onInput } = this.props;

        return (
            <div className="datetime-picker">
                <Datetime
                    closeOnSelect
                    initialValue={ initialValue }
                    dateFormat={ dateFormat }
                    timeFormat={ timeFormat }
                    renderInput={ this.renderInput }
                    inputProps={ {
                        placeholder,
                        name,
                        required,
                        onInvalid,
                        onInput,
                        readOnly,
                        autoComplete,
                    } }
                    open={ open }
                    onChange={ this.handleChange }
                />
            </div>
        );
    }
}
export default DatetimePicker;
