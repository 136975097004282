/**
 * Action - Rental
 */
import api from 'api';

import {
    FETCH_RENTAL_HISTORY,
    CLEAR_RENTAL_HISTORY,
    FETCH_RENTAL_DETAIL,
    CLEAR_RENTAL_DETAIL,
    FETCH_REFUND_COUPON,
    CLEAR_REFUND_COUPON,
    REFUND,
    FETCH_RETURN_PHOTO_URL,
} from 'constants/action-type';

import { asyncInterface } from './util';

export const fetchRentalHistory = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchRentalHistory(params),
        type: FETCH_RENTAL_HISTORY,
        dispatch,
    });
};

export const clearRentalHistory = () => dispatch => dispatch({
    type: CLEAR_RENTAL_HISTORY,
});

export const fetchRentalDetail = id => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchRentalDetail(id),
        type: FETCH_RENTAL_DETAIL,
        dispatch,
    });
};

export const clearRentalDetail = () => dispatch => dispatch({
    type: CLEAR_RENTAL_DETAIL,
});

export const fetchRefundCoupon = () => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchRefundCoupon(),
        type: FETCH_REFUND_COUPON,
        dispatch,
    });
};

export const clearRefundCoupon = () => dispatch => dispatch({
    type: CLEAR_REFUND_COUPON,
});

export const refund = params => dispatch => {
    return asyncInterface({
        asyncAction: api.refund(params),
        type: REFUND,
        dispatch,
    });
};

export const updateSubtotal = ({ id, amount }) => dispatch => {
    return asyncInterface({
        asyncAction: api.updateSubtotal({ id, amount }),
        type: FETCH_RENTAL_DETAIL,
        dispatch,
    });
};

export const fetchReturnPhotoUrl = id => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchReturnPhotoUrl(id),
        type: FETCH_RETURN_PHOTO_URL,
        dispatch,
    });
};
