import {
    STATUS_DISABLE,
    STATUS_DRAFT,
    STATUS_PUBLISH,
    STATUS_LIVE,
    STATUS_EXPIRY,
    TYPE_TIME,
    TYPE_PRICE,
    TYPE_COUPON_SINGLE,
    TYPE_COUPON_BULK,
    TYPE_RANDOM_USER,
    TYPE_RANDOM_UPPER_ENG,
    TYPE_RANDOM_UPPER_EXCLUSIVE,
    TYPE_RANDOM_DIGIT,
    TYPE_RANDOM_DIGIT_EXCLUSIVE,
    TYPE_RANDOM_MIXED,
    PROMOTE_TYPE_PROMOTION_EVENT,
    PROMOTE_TYPE_MISSION,
    ERROR_CODE_0,
    ERROR_CODE_1,
    ERROR_CODE_2,
    ERROR_CODE_3,
    ERROR_CODE_4,
    ERROR_CODE_5,
    ERROR_CODE_6,
    ERROR_CODE_7,
    ERROR_CODE_8,
    ERROR_CODE_9,
    ERROR_CODE_10,
    ERROR_CODE_11,
    ERROR_CODE_12,
    ERROR_CODE_13,
    ERROR_CODE_14,
    ERROR_CODE_15,
    ERROR_CODE_16,
    ERROR_CODE_17,
    ERROR_CODE_18,
    ERROR_CODE_19,
    ERROR_CODE_20,
    ERROR_CODE_21,
    ERROR_CODE_22,
    ERROR_CODE_23,
    BULK_RESULT_CODE_SUCCESS,
    BULK_RESULT_CODE_ERROR,
    BULK_RESULT_CODE_DUPLICATE,
} from 'constants/coupon';

const coupon = {
    'document_title': 'Coupon',
    'new_document_title': 'New Coupon',
    'new_system_coupon_title': 'New System Coupon',
    [`status_${ STATUS_DISABLE }`]: 'Disabled',
    [`status_${ STATUS_DRAFT }`]: 'Draft',
    [`status_${ STATUS_PUBLISH }`]: 'Published',
    [`status_${ STATUS_LIVE }`]: 'Online',
    [`status_${ STATUS_EXPIRY }`]: 'Expired',
    [`type_${ TYPE_TIME }`]: 'Time',
    [`type_${ TYPE_PRICE }`]: 'Price',
    [`type_coupon_${ TYPE_COUPON_SINGLE }`]: 'Single',
    [`type_coupon_${ TYPE_COUPON_BULK }`]: 'Bulk',
    [`type_random_${ TYPE_RANDOM_USER }`]: 'User defined',
    [`type_random_${ TYPE_RANDOM_UPPER_ENG }`]: 'Uppercase English alphabet [A-Z]',
    [`type_random_${ TYPE_RANDOM_UPPER_EXCLUSIVE }`]: 'Uppercase English alphabet [A-Z] exclusive [O, I]',
    [`type_random_${ TYPE_RANDOM_DIGIT }`]: 'Digits [0-9]',
    [`type_random_${ TYPE_RANDOM_DIGIT_EXCLUSIVE }`]: 'Digits [0-9] exclusive [0, 1]',
    [`type_random_${ TYPE_RANDOM_MIXED }`]:
    'Mixed uppercase [A-Z] English alphabet & digits [0-9] exclusive [0, O, 1, I]',
    'promote_type': 'Promote type',
    [`promote_type_${ PROMOTE_TYPE_PROMOTION_EVENT }`]: 'Promotion',
    [`promote_type_${ PROMOTE_TYPE_MISSION }`]: 'Mission',
    'new_coupon': 'New Coupon',
    'coupon_no': 'Coupon No.',
    'title': 'Title',
    'status': 'Status',
    'type': 'Type',
    'coupon_type': 'Coupon Type',
    'random_type': 'Random Type',
    'discount': 'Discount',
    [`discount_${ TYPE_TIME }`]: 'Minutes',
    [`discount_${ TYPE_PRICE }`]: 'NTD',
    'valid_from': 'Valid From',
    'valid_to': 'Valid To',
    'valid_duration': 'Valid Duration',
    'remaining_amount': 'Remaining',
    'last_updated': 'Last Updated',
    'timetable': 'Timetable',
    'details': 'Details',
    'description': 'Description',
    'plans': 'Available Plans',
    'new_user_only': 'New Users Only',
    'number_of_people': 'Number of People',
    'number_of_event': 'Number of Bulk Coupon',
    'number_per_person': 'Number Per Person',
    'redeem_type': 'Convert Type',
    'redeem_from': 'Convert From',
    'redeem_to': 'Convert To',
    'coupon_code': 'Promo Code',
    'save': 'Save',
    'redeem_type_duration': 'Duration',
    'redeem_type_period': 'Period',
    'days': 'Days',
    'total_coupons': 'Total Coupons',
    'total_cost': 'Total Cost',
    'invalid_date': 'Invalid Date',
    'wrong_sequence_date': '`To` Time Has To Be Ahead of `From` Time',
    'wrong_redeem_dates': 'Wrong Date',
    'wrong_api_call': 'Oops! Something Went Wrong',
    'publish': 'Publish',
    'withdraw': 'Withdraw',
    'convert_time': 'Convert Time',
    'search_box_placeholder': 'Promo Code or Title keywords',
    'published_by': 'Published By',
    'published_time': 'Published Time',
    'bulk_processing': 'Bulk job processing',
    'bulk_pending': 'Bulk job pending',
    [`bulk_result_status_${BULK_RESULT_CODE_SUCCESS}`]: 'Bulk job success',
    [`bulk_result_status_${BULK_RESULT_CODE_ERROR}`]: 'Bulk job error',
    [`bulk_result_status_${BULK_RESULT_CODE_DUPLICATE}`]: 'Bulk job error - Duplicate coupon code',
    [`${ERROR_CODE_0}_error_message`]: 'Code is too short (at least > 5)',
    [`${ERROR_CODE_1}_error_message`]: 'Code existed',
    [`${ERROR_CODE_2}_error_message`]: 'Convert_from/convert_to is not valid',
    [`${ERROR_CODE_3}_error_message`]: 'Valid_from/valid_to is not valid',
    [`${ERROR_CODE_4}_error_message`]: 'Convert_to and valid_to conflict',
    [`${ERROR_CODE_5}_error_message`]: 'Total_limit is not valid',
    [`${ERROR_CODE_6}_error_message`]: 'Redeem_limit is not valid',
    [`${ERROR_CODE_7}_error_message`]: 'Title is not valid',
    [`${ERROR_CODE_8}_error_message`]: 'Description is not valid',
    [`${ERROR_CODE_9}_error_message`]: 'Discount_type is not valid',
    [`${ERROR_CODE_10}_error_message`]: 'Discount_value is not valid',
    [`${ERROR_CODE_11}_error_message`]: 'Can\'t change status',
    [`${ERROR_CODE_12}_error_message`]: '',
    [`${ERROR_CODE_13}_error_message`]: '',
    [`${ERROR_CODE_14}_error_message`]: 'Event type not valid',
    [`${ERROR_CODE_15}_error_message`]: 'Random code length not valid',
    [`${ERROR_CODE_16}_error_message`]: 'Random code type not valid',
    [`${ERROR_CODE_17}_error_message`]: 'Coupon total count not valid',
    [`${ERROR_CODE_18}_error_message`]: 'Sum of prefix and random code length too short',
    [`${ERROR_CODE_19}_error_message`]: 'Bulk coupon state is pending or processing',
    [`${ERROR_CODE_20}_error_message`]: 'Single coupon contains prefix. bulk coupon contains coupon_code',
    [`${ERROR_CODE_21}_error_message`]: 'Event_total_count over maxiumum available random coupon code',
    [`${ERROR_CODE_22}_error_message`]: 'Can\'t change status, the coupon already binded with the promotion',
    [`${ERROR_CODE_23}_error_message`]: 'Coupon type not valid to update',
};

export default coupon;
