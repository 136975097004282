import React from 'react';
import PropTypes from 'prop-types';

const Component = ({ value, className }) => {
    const renderProperText = () => {
        let el;
        const TWD = 0;
        switch (value) {
        case TWD:
            el = 'TWD';
            break;
        default:
            el = 'TWD';
        }
        return el;
    };

    return (
        <div className={ className }>
            { renderProperText() }
        </div>
    );
};

Component.propTypes = {
    value: PropTypes.number.isRequired,
    className: PropTypes.string,
};

Component.defaultProps = {
    className: '',
};

export default Component;
