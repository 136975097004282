import React, { Component } from 'react';
import { connect } from 'react-redux';
import isMobile from 'is-mobile';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Smart from 'components/Smart';
import { headerConfig } from './ListHeader';
import ListView from 'components/ListView';
import ListRow from './ListRow';
import Pagination, { TotalCount } from 'components/Pagination';
import PageSizeSelect from 'components/Pagination/PageSizeSelect';
import FlexGroup from 'components/FlexGroup';



class MyTaskList extends Component {
    static propTypes = {
        tasks: PropTypes.shape({
            page_index: PropTypes.number,
            page_count: PropTypes.number,
            data_list: ImmutablePropTypes.list,
        }).isRequired,
        pageSize: PropTypes.number.isRequired,
        onSelect: PropTypes.func.isRequired,
        onPageSizeChange: PropTypes.func.isRequired,
        onSort: PropTypes.func.isRequired,
        showMap: PropTypes.bool.isRequired,
    };

    render() {
        const {
            pageSize,
            onSelect,
            onPageSizeChange,
            onSort,
            tasks,
            showMap
        } = this.props;
        const {
            page_index,
            page_count,
            total,
            data_list,
        } = tasks.toJS();

        const ListItem = ({ columns, rowData }) => {
            return (
                <ListRow
                    key={ `mytasks-${ rowData.scooter_id }` }
                    columns={ columns }
                    data={ rowData }
                />
            );
        };

        const controlStyle = classNames({
            'list-view-control': true,
            'hide-control': showMap,
        });

        return (
            <React.Fragment>
                <FlexGroup start className={ controlStyle }>
                    <Pagination
                        page={ page_index || 0 }
                        total={ page_count || 0 }
                        offset={ pageSize }
                        onSelect={ onSelect }
                    />
                    <PageSizeSelect
                        onChange={ selection => onPageSizeChange(selection.value) }
                    />
                    <TotalCount count={ total || 0 } />
                </FlexGroup>
                <section className="scooter-list-table">
                    <div className="list-view-wrap">
                        <Smart
                            fetch={ () => {} }
                            pauseRefresh
                            seamless
                            tag=""
                        >
                            <ListView
                                dataKey="scooter_id"
                                header={ headerConfig }
                                onSort={ onSort }
                                list={ data_list }
                                renderListRow={ ListItem }
                                fixed={ isMobile() }
                            />
                        </Smart>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    tasks: state.scooter.get('tasks'),
    nearbyList: state.scooter.get('nearbyList'),
    currentGeoLocation: state.geolocation.get('currentLocation'),
}))(MyTaskList);
