import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ToolButton } from 'components/Map/Toolbox';


class LocateToCurrentLocation extends Component {
    static propTypes = {
        onFetch: PropTypes.func.isRequired,
    };

    handleFetch = () => {
        const { onFetch } = this.props;

        return onFetch();
    }

    render() {
        return (
            <ToolButton onClick={ this.handleFetch }>
                <span className="fas fa-battery-half" />
            </ToolButton>
        );
    }
}

export default LocateToCurrentLocation;
