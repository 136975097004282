// status
export const STATUS_DRAFT = 0;
export const STATUS_SCHEDULED = 1;
export const STATUS_PROCESSING = 2;
export const STATUS_FINISHING = 3;
export const STATUS_FAILED = 4;

export const STATUS_GROUP = [
    STATUS_DRAFT,
    STATUS_SCHEDULED,
    STATUS_FINISHING,
    STATUS_FAILED,
];

// type
export const TYPE_SEND_NOTIFICATION = 0;
export const TYPE_SEND_COUPON_AND_NOTIFICATION = 1;

export const TYPE_GROUP = [
    TYPE_SEND_NOTIFICATION,
    TYPE_SEND_COUPON_AND_NOTIFICATION
];

// notification sound
export const NO_NOTIFICATION_SOUND = 0;
export const NOTIFICATION_SOUND = 1;

export const NOTIFICATION_GROUP = [
    NO_NOTIFICATION_SOUND,
    NOTIFICATION_SOUND
];

export const NOTIFICATION_DEFAULT = 1;
export const NO_NOTIFICATION_DEFAULT = 0;

export const USER_IDS = 0;
export const USER_PHONES = 1;

export const USER_LIST_TYPE_GROUP = [
    USER_IDS,
    USER_PHONES,
];

export const USER_FILE_TYPE_SOURCE = 0;
export const USER_FILE_TYPE_RESULT = 1;

// error code
export const TITLE_NOT_VALID = 4001;
export const TYPE_NOT_VALID = 4002;
export const SCHEDULE_TIME_NOT_VALID = 4003;
export const COUPON_ID_NOT_VALID = 4004;
export const NOTIFICATION_TITLE_NOT_VALID = 4005;
export const NOTIFICATION_BODY_NOT_VALID = 4006;
export const NOTIFICATION_SOUND_NOT_VALID = 4007;
export const NOTIFICATION_OPEN_URL_NOT_VALID = 4008;
export const USER_FILE_LIST_NOT_VALID = 4009;
export const USER_FILE_LIST_USER_ID_NOT_EXISTS = 40010;
export const CAN_NOT_CHANGE_STATUS = 40011;
export const CAN_NOT_MODIFY_CONTENT = 40012;
export const NOTIFICATION_COUPON_DEFAULT_NOT_VALID = 40013;
export const USER_FILE_LIST_HEADER_NOT_VALID = 40014;
export const USER_FILE_LIST_CONTENT_CANNOT_EMPTY = 40015;
export const USER_FILE_LIST_USER_ID_CANNOT_EMPTY = 40016;
export const USER_FILE_LIST_USER_ID_FORMAT_NOT_VALID = 40017;
export const USER_FILE_LIST_PHONE_CANNOT_EMPTY = 40018;
export const USER_FILE_LIST_PHONE_FORMAT_NOT_VALID = 40019;
export const USER_LIST_TYPE_IS_NOT_VALID = 40020;
