const ListHeader = [{
    name: 'title',
    value: 'coupon.title',
    width: 269,
}, {
    name: 'coupon_code',
    value: 'coupon.coupon_code',
    width: 143,
}, {
    name: 'state',
    value: 'coupon.status',
    width: 112,
}, {
    name: 'create_time',
    value: 'customer.create_time',
    width: 293,
}, {
    name: 'valid_from',
    value: 'customer.valid_from',
    width: 293,
}, {
    name: 'valid_to',
    value: 'customer.valid_to',
    width: 293,
}, {
    name: 'used_time',
    value: 'customer.used_time',
    width: 177,
}];

export default ListHeader;
