import React from 'react';
import PropTypes from 'prop-types';


const SliderArrow = ({ onClick, className }) => {

    return (
        <div
            className={ className }
            onClick={ onClick }
        />
    );
};

SliderArrow.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};

SliderArrow.defaultProps = {
    className: '',
    onClick: () => {},
};

export default SliderArrow;
