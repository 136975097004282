import {
    ERROR_CODE_40001,
    ERROR_CODE_40002,
    ERROR_CODE_40003,
    ERROR_CODE_40004,
} from 'constants/violation';

const violation = {
    'traffic_violation': 'Traffic Violation',
    'violator': 'Violator',
    'violate_time': 'Violate Time',
    'detail': 'Detail',
    'violate_detail': 'Violation Detail',
    'violate_in_rental': 'Violate in rental',
    'import_violation_list': 'Import traffic violation',
    'import_info': 'Import violation file here (.csv)',
    'print': 'Print',
    'id': 'ID',
    [`import_error_${ERROR_CODE_40001}`]: 'Header format not valid.',
    [`import_error_${ERROR_CODE_40002}`]: 'Content cannot be empty.',
    [`import_error_${ERROR_CODE_40003}`]: 'Time format not valid.',
    [`import_error_${ERROR_CODE_40004}`]: 'File invalid.',
};

export default violation;
