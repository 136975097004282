import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import BaseTable, { AutoResizer } from 'react-base-table';
import ListHeader from './ListHeader';
import ListRow from './ListRow';
import CheckboxCell from './CheckboxCell';

import 'react-base-table/styles.css';
import './list-view.scss';


class ListView extends Component {
    static propTypes = {
        header: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
            ]),
            sortable: PropTypes.bool,
            hide: PropTypes.bool,
        })),
        dataKey: PropTypes.string.isRequired,
        list: PropTypes.arrayOf(PropTypes.shape({})),
        renderListRow: PropTypes.func,
        renderEmpty: PropTypes.func,
        selectable: PropTypes.bool,
        defaultSelect: PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
        ),
        onSort: PropTypes.func,
        onSelect: PropTypes.func,
        rowEventHandlers: PropTypes.shape(),
        fixed: PropTypes.bool,
        rowHeight: PropTypes.number,
    }

    static defaultProps = {
        header: [],
        list: [],
        renderListRow: undefined,
        renderEmpty: undefined,
        selectable: false,
        defaultSelect: [],
        onSort: undefined,
        onSelect: undefined,
        rowEventHandlers: undefined,
        fixed: false,
        rowHeight: 40,
    }

    constructor(props) {
        super(props);

        this.tableEl = React.createRef();
        this.state = {
            sortBy: undefined,
        };
    }

    handleSelectAll = checked => {
        const { list, onSelect, dataKey } = this.props;
        let result = [];

        if (checked) {
            result = list.map(item => item[dataKey]);
        }

        return onSelect(result, true);
    };

    handleColumnSort = ({ field, order }) => {
        const { onSort } = this.props;

        this.setState({
            sortBy: {
                key: field,
                order,
            },
        });

        onSort({
            field,
            order,
        });
    }

    render() {
        const { sortBy } = this.state;
        const { header, dataKey, fixed, rowEventHandlers, renderEmpty, rowHeight } = this.props;
        const { list, renderListRow, selectable, defaultSelect, onSelect } = this.props;
        const columns = header.map(({
            name,
            value,
            width,
            sortable,
            hide,
            key,
        }, i) => ({
            dataKey: name,
            key: name,
            title: I18n.t(value),
            hidden: hide,
            width,
            sortable,
        }));


        return (
            <div ref={ this.tableEl } className="list-view">
                <AutoResizer>
                    { ({ width, height }) => {
                        let updateColumns = [...columns];
                        let totWidth = columns.reduce((w, { width }) => w += width, 0);
                        let defaultWidth = totWidth;

                        if (fixed) {
                            defaultWidth = totWidth;
                        }
                        else {
                            // recalculate width for each column by the ratio to total width
                            updateColumns = columns.map(column => {
                                const ratio = column.width / totWidth;
                                const newWidth = width * ratio;

                                return {
                                    ...column,
                                    width: newWidth,
                                };
                            });

                            defaultWidth = width;
                        }

                        return (
                            <BaseTable
                                className="table table-striped"
                                headerClassName="header"
                                data={ list.map(item => {
                                    return {
                                        parentId: null,
                                        id: item[dataKey],
                                        ...item,
                                    };
                                }) }
                                width={ defaultWidth }
                                height={ height }
                                headerHeight={ 40 }
                                rowHeight={ rowHeight }
                                headerRenderer={
                                    item => {
                                        const { columns } = item;

                                        return (
                                            <ListHeader
                                                onSort={ this.handleColumnSort }
                                                onSelectAll={ this.handleSelectAll }
                                                items={ columns }
                                                selectable={ selectable }
                                                selected={
                                                    defaultSelect.length > 0 && defaultSelect.length === list.length
                                                }
                                            />
                                        );
                                    }
                                }
                                rowClassName={
                                    ({ rowIndex }) => `list-row row-${ rowIndex % 2 === 0 ? 'odd' : 'even' }`
                                }
                                rowRenderer={
                                    item => {
                                        const { columns, rowData } = item;
                                        const checkbox = (
                                            selectable ? (
                                                <CheckboxCell
                                                    key={ `checkbox-${ rowData.id?.toString() }` }
                                                    onSelect={ () => onSelect([rowData.id]) }
                                                    selected={ defaultSelect.indexOf(rowData.id) > -1 }
                                                />
                                            ) : null
                                        );
                                        const dataColumns = (
                                            renderListRow ?
                                                renderListRow({ columns, rowData }) : (
                                                    <ListRow
                                                        columns={ columns }
                                                        data={ rowData }
                                                    />
                                                )
                                        );
                                        const row = [
                                            checkbox,
                                            dataColumns
                                        ];

                                        return row;
                                    }
                                }
                                emptyRenderer={ renderEmpty }
                                columns={ updateColumns }
                                rowEventHandlers={ rowEventHandlers }
                                sortBy={ sortBy }
                                fixed={ fixed }
                            />
                        );
                    } }
                </AutoResizer>
            </div>
        );

    }
}

export default ListView;
