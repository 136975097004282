import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import MultiSelectFilter from 'components/Filter/MultiselectFilter';
import { STATUS_GROUP } from 'constants/promotion';

export default function StatusFilter({ onChange, selected }) {
    const options = STATUS_GROUP.map(status => ({
        value: status,
        text: I18n.t(`promotion.status_${ status }`),
    }));

    return (
        <MultiSelectFilter
            title={ I18n.t('promotion.status') }
            options={ options }
            defaultSelected={ selected }
            onChange={ onChange }
        />
    );
}

StatusFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.number),
};

StatusFilter.defaultProps = {
    selected: [],
};
