import React from 'react';
import FlexGroup from 'components/FlexGroup';
import XSSTranslate from 'components/XSSTranslate';
import PrevIcon from 'assets/slide-arrow-prev.svg';
import NextIcon from 'assets/slide-arrow-next.svg';

const Empty = () => {
    const xssWhiteList = {
        span: ['class'],
    };
    return (
        <FlexGroup className="dashboard-empty">
            <img className="arrow-left" src={ PrevIcon } alt="" />
            <XSSTranslate className="warning" value="dashboard.empty_warning" whiteList={ xssWhiteList } />
            <img className="arrow-right" src={ NextIcon } alt="" />
        </FlexGroup>
    );
};

export default Empty;
