import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import MultiselectFilter from 'components/Filter/MultiselectFilter';

class TicketFlowFilter extends React.Component {
    static propTypes = {
        myTicketFlowList: ImmutablePropTypes.listOf(PropTypes.shape({
            text: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        })),
        onChange: PropTypes.func.isRequired,
        selected: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        selected: [],
        myTicketFlowList: undefined,
    };

    render() {
        const { myTicketFlowList, selected, onChange } = this.props;
        const list = myTicketFlowList.toJS();
        return (
            <MultiselectFilter
                title={ I18n.t('ticket.flow') }
                options={ list }
                defaultSelected={ selected }
                onChange={ onChange }
            />
        );
    }
}

export default connect(state => ({
    myTicketFlowList: state.ticket.get('myTicketFlowList'),
}))(TicketFlowFilter);
