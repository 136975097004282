import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'components/Datetime';
import Cell from 'components/ListView/Cell';
import Plate from 'components/Scooter/Plate';
import TicketNo from 'components/Ticket/TicketNo';
import { Translate } from 'react-redux-i18n';

class ListRow extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.shape({
            id: PropTypes.number.isRequired,
            tracker_id: PropTypes.number.isRequired,
            status_id: PropTypes.number.isRequired,
            scooter_id: PropTypes.string.isRequired,
            plate_no: PropTypes.string.isRequired,
            assigned_to_name: PropTypes.string.isRequired,
            severity_id: PropTypes.number.isRequired,
            create_time: PropTypes.string.isRequired,
        }).isRequired,
    }

    renderField({ data, name, width }) {
        let el;

        switch (name) {
        case 'id':
            el = (
                <TicketNo id={ data.id } />
            );
            break;
        case 'tracker_id':
            el = <Translate value={ `ticket.main_type_${ data.tracker_id }` } />;
            break;
        case 'tracker_subtype_id':
            el = <Translate value={ `ticket.sub_type_${ data.tracker_subtype_id }` } />;
            break;
        case 'status_id':
            el = <Translate value={ `ticket.status_${ data.status_id }` } />;
            break;
        case 'severity_id':
            el = <Translate value={ `ticket.severity_${ data.severity_id }` } />;
            break;
        case 'plate_no':
            el = (
                <Plate
                    id={ data.scooter_id }
                    plateNo={ data.plate_no }
                    modal={ false }
                />
            );
            break;
        case 'create_time':
        case 'update_time':
            el = <Datetime time={ data[name] } />;
            break;
        case 'assigned_to_id':
            el = data.assigned_to_name;
            break;
        default:
            el = data[name];
            break;
        }

        return (
            <Cell
                key={ `${ data.id }-${ name }` }
                name={ name }
                style={ {
                    width,
                } }
            >
                { el }
            </Cell>
        );
    }

    render() {
        const { columns, data } = this.props;

        return columns.map(({ key, width }, i) => {
            return this.renderField({ data, width, name: key, });
        });
    }
}

export default ListRow;
