const ListHeader = [{
    name: 'title',
    value: 'mission.title',
    width: 269,
}, {
    name: 'state',
    value: 'customer.state',
    width: 112,
}, {
    name: 'valid_from',
    value: 'mission.valid_from',
    width: 233,
}, {
    name: 'valid_to',
    value: 'mission.valid_to',
    width: 233,
}, {
    name: 'start_time',
    value: 'customer.start_time',
    width: 233,
}, {
    name: 'finish_time',
    value: 'customer.finish_time',
    width: 233,
}, {
    name: 'criteria_type',
    value: 'mission.criteria_type',
    width: 222,
}, {
    name: 'progress_value',
    value: 'customer.progress_value',
    width: 112,
}];

export default ListHeader;
