import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import MultiselectFilter from 'components/Filter/MultiselectFilter';
import {
    GROUP_SEVERITY,
} from 'constants/ticket';

const CustomPriority = ({ selected, onChange }) => {
    const options = GROUP_SEVERITY.map(item => ({
        value: item,
        text: I18n.t(`ticket.severity_${ item }`),
    }));

    return (
        <MultiselectFilter
            title={ I18n.t('ticket.priority') }
            options={ options }
            defaultSelected={ selected }
            onChange={ onChange }
        />
    );
};
CustomPriority.propTypes = {
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.number),
};
CustomPriority.defaultProps = {
    selected: [],
};

export default CustomPriority;
