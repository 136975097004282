import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import XSSTranslate from 'components/XSSTranslate';
import Datetime from 'components/Datetime';
import CopyToClipBoard from 'components/CopyToClipBoard';
import Cell from 'components/ListView/Cell';
import { thousandsSeparator } from 'helpers/util';
import {
    LOG_COUPON_REFUND,
} from 'constants/log';

class ListRow extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.shape({
            rental_id: PropTypes.string,
            type: PropTypes.number,
            modified_by_user_account: PropTypes.string,
            old_value: PropTypes.string,
            value: PropTypes.string,
            create_time: PropTypes.string,
        }).isRequired,
    }

    renderField({ data, name, width }) {
        let el;

        switch (name) {
        case 'type':
            el = (
                <Translate className="text-ellipsis" value={ `log.type_${ data.type }` } />
            );
            break;
        case 'create_time':
            el = <Datetime time={ data.create_time } />;
            break;
        case 'rental_id':
            el = <CopyToClipBoard>{ data.rental_id }</CopyToClipBoard>;
            break;
        case 'description':
            const { type, old_value, value, points_discount } = data;
            const isString = type === LOG_COUPON_REFUND;
            const before = (isString ? old_value : thousandsSeparator(old_value));
            const after = (isString ? value : thousandsSeparator(value));
            const hasPoints = (points_discount !== null) ? '_points' : '';
            el = (
                <XSSTranslate
                    value={ `log.description_${ data.type }${ hasPoints }` }
                    before={ before }
                    after={ after }
                    points={ points_discount }
                />
            );
            break;
        case 'modified_by_user_account':
        default:
            el = data[name];
            break;
        }
        return (
            <Cell key={ `${ data.auditlog_id }-${ name }` } name={ name } style={ { width } }>
                { el }
            </Cell>
        );
    }

    render() {
        const { columns, data } = this.props;

        return columns.map(({ key, width }) => this.renderField({ data, width, name: key, }));
    }
}

export default ListRow;
