import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import FlexGroup from 'components/FlexGroup';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import AdditionalFeeTableList from 'components/AdditionalFeeTableList';
import Pagination, { Refresh, TotalCount } from 'components/Pagination';
import PageSizeSelect from 'components/Pagination/PageSizeSelect';
import AuthFeature from 'components/AuthFeature';
import { Translate } from 'react-redux-i18n';
import Period from 'components/Period';
import { AUTH_CREATE_EXPENSE } from "constants/permission";
import { NEW_ADDITIONAL_FEE } from 'constants/routes';



const AdditionalFeeList = ({
    onChange,
    onFetch,
    onSort,
    onSelect,
    fromTime,
    toTime,
    data,
}) => {
    const [responseTime, setResponseTime] = useState(Date.now());
    const {
        data_list,
        page_count,
        page_index,
        size: pageSize,
        total,
        updatedTime,
    } = data;

    useEffect(() => {
        if (updatedTime > responseTime) {
            setResponseTime(updatedTime);
        }
    }, [responseTime, updatedTime]);


    return (
        <section className="list-view-wrap">
            <FlexGroup spaceBetween className="pagination-control-sec">
                <FlexGroup>
                    <AuthFeature requiredList={ [AUTH_CREATE_EXPENSE] }>
                        <NavLink to={ NEW_ADDITIONAL_FEE }>
                            <Button
                                className="new-btn"
                                outline
                                type="button"
                            >
                                <Translate value="additional_fee.new" />
                            </Button>
                        </NavLink>
                    </AuthFeature>
                    <Pagination
                        page={ page_index }
                        total={ page_count }
                        offset={ pageSize }
                        onSelect={ onSelect }
                    />
                    <div className="page-size-select">
                        <PageSizeSelect
                            onChange={ selection => onChange(selection.value) }
                        />
                    </div>
                    <TotalCount count={ total } />
                    <Period fromTime={ fromTime } toTime={ toTime } />
                </FlexGroup>
                <Refresh time={ moment(responseTime) } onClick={ onFetch } />
            </FlexGroup>
            <AdditionalFeeTableList
                history={ data_list }
                onSort={ onSort }
            />
        </section>
    );
};

AdditionalFeeList.propTypes = {
    onChange: PropTypes.func,
    onFetch: PropTypes.func,
    onSort: PropTypes.func,
    onSelect: PropTypes.func,
    fromTime: PropTypes.oneOfType([
        PropTypes.instanceOf(moment),
        PropTypes.string,
    ]),
    toTime: PropTypes.oneOfType([
        PropTypes.instanceOf(moment),
        PropTypes.string,
    ]),
    data: PropTypes.shape({
        data_list: PropTypes.arrayOf(PropTypes.shape({})),
        page_count: PropTypes.number,
        page_index: PropTypes.number,
        size: PropTypes.number,
        total: PropTypes.number,
        updatedTime: PropTypes.oneOfType([
            PropTypes.instanceOf(moment),
            PropTypes.number,
        ]),
    }),
};
AdditionalFeeList.defaultProps = {
    onChange: () => {},
    onFetch: () => {},
    onSort: () => {},
    onSelect: () => {},
    fromTime: undefined,
    toTime: undefined,
    data: {},
};
export default AdditionalFeeList;
