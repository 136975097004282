import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import mobile from 'is-mobile';
import ImmutablePropTypes from 'react-immutable-proptypes';
import OrderableListView from 'components/OrderableListView';
import AdOrderListRow from './AdOrderListRow';
import { headerConfig } from './headerConfig';

class AdOrderList extends React.PureComponent {
    static propTypes = {
        orderableList: ImmutablePropTypes.map.isRequired,
        onMoveAd: PropTypes.func,
    }

    static defaultProps = {
        onMoveAd: () => {}
    }

    render() {
        const { orderableList, onMoveAd } = this.props;
        const { data_list: adDataList } = orderableList.toJS();

        return (
            <section className="orderable-ad-list">
                <OrderableListView
                    dataKey="id"
                    header={ headerConfig }
                    list={ adDataList }
                    renderOrderableListRow={ AdOrderListRow }
                    onMoveRow={ onMoveAd }
                    fixed={ mobile() }
                />
            </section>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    orderableList: state.ad.get('orderableList'),
}))(AdOrderList);
