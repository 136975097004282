import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Smart from 'components/Smart';
import AuthView from 'components/AuthView';
import ScooterProfile from 'components/Scooter/Profile';
import NavigationBar from 'components/NavigationBar';
import Pagination from 'components/Pagination';
import CollapseBanner from 'components/CollapseBanner';
import { Refresh } from 'components/Pagination';
import {
    fetchSingleScooter,
    clearSingleScooter,
    fetchCustomerRentalHistory,
    clearCustomerRentalHistory,
    toogleNavigation,
} from 'actions';
import History, { RENTAL_HISTORY_SCOOTER } from 'components/RentalHistory';
import './scooter-profile.scss';

class PopupScooterDetail extends Component {
    static propTypes = {
        singleScooter: ImmutablePropTypes.map,
        customerHistory: ImmutablePropTypes.map,
    };

    static defaultProps = {
        singleScooter: new Map(),
        customerHistory: undefined,
    }

    constructor(props) {
        super(props);

        this.queryPayload = {
            scooter_id: undefined,
            page: 1,
            size: 20,
        };
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearSingleScooter());
        dispatch(clearCustomerRentalHistory());
    }

    fetchData = () => {
        const { dispatch, match } = this.props;
        const scooterId = match.params.scooterId;

        return dispatch(fetchSingleScooter(scooterId)).then(data => {
            if (data.error?.response.status === 403) {
                dispatch(clearSingleScooter());
                dispatch(toogleNavigation());
            }
        });
    }

    handleHistoryPageSelect = page => {
        this.queryPayload.page = page;

        this.fetchScooterRentalHistory();
    }

    fetchScooterRentalHistory = () => {
        const { dispatch, singleScooter } = this.props;
        const scooter_id = singleScooter.get('scooter_id');
        if (!scooter_id) {
            return Promise.resolve();
        }

        this.queryPayload.scooter_id = scooter_id;

        return dispatch(fetchCustomerRentalHistory(this.queryPayload));
    };

    render() {
        const { singleScooter, customerHistory } = this.props;
        const { page_index, page_count, data_list } = customerHistory.toJS();
        const historyHeight = (data_list.length + 1) * 40;
        const children = (
            singleScooter ?
                (
                    <Smart
                        className="scooter-profile-page"
                        fetch={ this.fetchData }
                        pauseRefresh
                        seamless
                    >
                        <NavigationBar sticky title={ singleScooter.get('plate') }>
                            <Refresh onClick={ this.fetchData } time={ singleScooter.get('__responseTime') } />
                        </NavigationBar>
                        <ScooterProfile item={ singleScooter.toJS() } />

                        <CollapseBanner
                            className="rental-history-list"
                            captionKey="customer.recent_history"
                            onFetch={ this.fetchScooterRentalHistory }
                        >
                            <Pagination
                                page={ page_index }
                                total={ page_count }
                                offset={ this.queryPayload.size }
                                onSelect={ this.handleHistoryPageSelect }
                                center={ false }
                            />
                            <div style={ { height: historyHeight } }>
                                <History
                                    history={ data_list }
                                    type={ RENTAL_HISTORY_SCOOTER }
                                />
                            </div>
                        </CollapseBanner>
                    </Smart>
                ) :
                ''
        );

        return (
            <AuthView>
                { children }
            </AuthView>
        );
    }
}

export default connect((state, ownProps) => ({
    singleScooter: state.scooter.get('singleScooter')[ownProps.match.params.scooterId],
    customerHistory: state.customer.get('history'),
    i18n: state.i18n,
}))(PopupScooterDetail);
