import requireAll from 'helpers/requireAll';


const apis = requireAll(require.context('./context/', true, /^\.\/.*\.js$/), 'api/context');

let apiMap = Object.keys(apis).reduce((map, groupName) => {
    map = {
        ...map,
        ...apis[groupName],
    };

    return map;
}, {});

export default apiMap;
