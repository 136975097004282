import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Form/Button';
import FlexGroup from 'components/FlexGroup';
import { Link } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import { VIOLATION_DETAIL } from 'constants/routes';
import {
    AUTH_EDIT_EXPENSE,
    AUTH_REVOKE_EXPENSE,
    AUTH_REFUND_EXPENSE,
    AUTH_ABSORB_EXPENSE,
    VIEW_VIOLATE_RENTAL_LIST,
} from 'constants/permission';
import AuthFeature from 'components/AuthFeature';
import {
    CLAIM_TYPE_CLAIM,
    CLAIM_TYPE_NO_CLAIM,

    FEE_STATUS_PAID,
    FEE_STATUS_CANCEL,
    FEE_STATUS_ABSORBED_BY_INTR,

    REVOKE_FEE_WHITELIST,
    PRINT_FEE_WHITELIST,
} from 'constants/additional-fee';
import buildActualPath from 'helpers/build-actual-path';
import RevokeFee from './RevokeFee';
import RefundFee from './RefundFee';
import AbsorbedFee from './AbsorbedFee';

const UPDATE_TYPE_REVOKE = 'revoke';
const UPDATE_TYPE_ABSORBED = 'absorbed';


const FeeFunctionGroup = ({
    isEdit,
    feeDetail,
    onUpdate,
    onRefund,
    onChangEdit,
    onEditUpdate,
}) => {
    const {
        status,
        expense_id,
        claim_type,
        amount,
        payment_id,
        rental_id,
    } = feeDetail || {};

    const [showRevoke, setShowRevoke] = useState(false);
    const [showRefund, setShowRefund] = useState(false);
    const [showAbsorbed, setShowAbsorbed] = useState(false);

    const disableRevokeFee = REVOKE_FEE_WHITELIST.indexOf(status) === -1;
    const disabledRefund = status !== FEE_STATUS_PAID;
    const disableAbsorbedFee = REVOKE_FEE_WHITELIST.indexOf(status) === -1;
    const disablePrintFee = (!rental_id || PRINT_FEE_WHITELIST.indexOf(status) === -1);

    const handleRevoke = isShow => e => {
        setShowRevoke(isShow);
    };
    const handleRefund = isShow => e => {
        setShowRefund(isShow);
    };

    const handleAbsorbed = isShow => e => {
        setShowAbsorbed(isShow);
    };

    const handleUpdate = type => e => {
        let params;
        let onErrorCallback;
        switch (type) {
            case UPDATE_TYPE_REVOKE:
                params = {
                    expense_id,
                    status: FEE_STATUS_CANCEL,
                };
                onErrorCallback = () => {
                    handleRevoke(false)();
                };
                break;
            case UPDATE_TYPE_ABSORBED:
                params = {
                    expense_id,
                    status: FEE_STATUS_ABSORBED_BY_INTR,
                };
                onErrorCallback = () => {
                    handleAbsorbed(false)();
                };
                break;
            default:
        }

        return onUpdate(params).finally(onErrorCallback);
    };

    const handleRefundFeeCharge = e => {
        return onRefund({
            expense_id,
            payment_id,
        }).finally(() => {
            handleRefund(false)();
        });
    };

    const handleEditFee = e => {
        onChangEdit(true);
    };
    const handleCancelEdit = e => {
        onChangEdit(false);
    };
    const handleEditUpdate = e => {
        return onEditUpdate();
    };


    return (
        <FlexGroup className="fee-functions">
            {
                isEdit ? (
                    <>
                        <Button
                            type="button"
                            className="btn-cancel"
                            onClick={ handleCancelEdit }
                            outline
                            stopTriggerByTab>
                            <Translate value="cancel" />
                        </Button>
                        <Button
                            color="primary"
                            type="submit"
                            className="btn-save"
                            onClick={ handleEditUpdate }
                            stopTriggerByTab
                        >
                            <Translate value="save" />
                        </Button>
                    </>
                ) : (
                    <>
                        <AuthFeature requiredList={ [AUTH_REVOKE_EXPENSE] }>
                            <Button
                                type="button"
                                outline
                                className="btn-revoke-fee"
                                disabled={ disableRevokeFee }
                                onClick={ handleRevoke(true) }
                                stopTriggerByTab
                            >
                                <Translate value="additional_fee.revoke_fee" />
                            </Button>
                        </AuthFeature>
                        {
                            claim_type === CLAIM_TYPE_CLAIM ? (
                                <AuthFeature requiredList={ [AUTH_REFUND_EXPENSE] }>
                                    <Button
                                        outline
                                        className="btn-refund-fee"
                                        type="button"
                                        onClick={ handleRefund(true) }
                                        disabled={ disabledRefund }
                                        stopTriggerByTab
                                    >
                                        <Translate value="rental.refund" />
                                    </Button>
                                </AuthFeature>
                            ) : null
                        }
                        <AuthFeature requiredList={ [AUTH_ABSORB_EXPENSE] }>
                            <Button
                                type="button"
                                outline
                                className="btn-absorbed-fee"
                                disabled={ disableAbsorbedFee }
                                onClick={ handleAbsorbed(true) }
                                stopTriggerByTab
                            >
                                <Translate value="additional_fee.absorbed_fee" />
                            </Button>
                        </AuthFeature>
                        {
                            (claim_type === CLAIM_TYPE_NO_CLAIM ) ? (
                                <AuthFeature requiredList={ [VIEW_VIOLATE_RENTAL_LIST] }>

                                    {
                                        disablePrintFee ? (
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="btn-print-fee"
                                                disabled={ disablePrintFee }
                                                onClick={ () => {} }
                                                stopTriggerByTab
                                            >
                                                <Translate value="violation.print" />
                                            </Button>
                                        ) : (
                                            <Link className="btn btn-primary btn-print-fee" target="_blank" to={ buildActualPath(VIOLATION_DETAIL, { rentalId: rental_id }) }>
                                                { I18n.t('violation.print') }
                                            </Link>
                                        )
                                    }

                                </AuthFeature>
                            ) : null
                        }
                        <AuthFeature requiredList={ [AUTH_EDIT_EXPENSE] }>
                            {
                                <Button
                                    type="button"
                                    color="primary"
                                    className="btn-edit-fee"
                                    onClick={ handleEditFee }
                                    stopTriggerByTab
                                >
                                    <Translate value="edit" />
                                </Button>
                            }
                        </AuthFeature>
                    </>
                )
            }
            <RevokeFee
                show={ showRevoke }
                onSubmit={ handleUpdate(UPDATE_TYPE_REVOKE) }
                onClose={ handleRevoke(false) }
            />
            <RefundFee
                show={ showRefund }
                charged={ amount }
                onSubmit={ handleRefundFeeCharge }
                onClose={ handleRefund(false) }
            />
            <AbsorbedFee
                show={ showAbsorbed }
                onSubmit={ handleUpdate(UPDATE_TYPE_ABSORBED) }
                onClose={ handleAbsorbed(false) }
            />
        </FlexGroup>
    );
};

FeeFunctionGroup.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    feeDetail: PropTypes.shape({}),
    onUpdate: PropTypes.func,
    onRefund: PropTypes.func,
    onChangEdit: PropTypes.func,
    onEditUpdate: PropTypes.func,
};
FeeFunctionGroup.defaultProps = {
    feeDetail: undefined,
    onUpdate: () => {},
    onRefund: () => {},
    onChangEdit: () => {},
    onEditUpdate: () => {},
};
export default FeeFunctionGroup;
