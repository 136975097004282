import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

const Component = ({ value, className }) => {
    const renderProperText = () => {
        let el;
        const NOT_YET = 0;
        const SUCCESS = 1;
        const DENIED_FRAUD = 2;
        const DEINED_UNSUPPORTED_ID_TYPE = 3;
        const DEINED_UNSUPPORTED_ID_COUNTRY = 4;
        const ERROR_NOT_READABLE_ID = 5;
        const VALIDITY_FALSE = 6;
        const NO_MATCH = 7;
        const OTHER_TYPE = 8;
        const INVALIDE_CARD = 9;
        switch (value) {
        case NOT_YET:
            el = <Translate value="unverified" />;
            break;
        case SUCCESS:
            el = <Translate value="success" />;
            break;
        case ERROR_NOT_READABLE_ID:
            el = <Translate value="readability_issue" />;
            break;
        case NO_MATCH:
            el = <Translate value="document_not_match" />;
            break;
        case DENIED_FRAUD:
        case DEINED_UNSUPPORTED_ID_TYPE:
        case DEINED_UNSUPPORTED_ID_COUNTRY:
        case VALIDITY_FALSE:
        case OTHER_TYPE:
        case INVALIDE_CARD:
            el = <Translate value="flawed" />;
            break;
        default:
            el = <Translate value="unknown" />;
        }
        return el;
    };

    return (
        <div className={ className }>
            { renderProperText() }
        </div>
    );
};

Component.propTypes = {
    value: PropTypes.number.isRequired,
    className: PropTypes.string,
};

Component.defaultProps = {
    className: '',
};

export default Component;
