import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import AuthFeature from 'components/AuthFeature';
import Smart from 'components/Smart';
import { AUTH_EDIT_BANNER } from 'constants/permission';
import { headerConfig } from './ListHeader';
import ListRow from './ListRow';
import ListView from 'components/ListView';
import Pagination, { TotalCount, Refresh, } from 'components/Pagination';
import PageSizeSelect from 'components/Pagination/PageSizeSelect';
import { NEW_AD, ORDER_AD } from 'constants/routes';
import { Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import FlexGroup from 'components/FlexGroup';
import { Translate } from 'react-redux-i18n';
import mobile from 'is-mobile';

const isMobile = mobile();

class AdList extends Component {
    static propTypes = {
        list: ImmutablePropTypes.map.isRequired,
        onSort: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        pageSize: PropTypes.number.isRequired,
        onFetch: PropTypes.func.isRequired,
    };
    static defaultProps = {};

    render() {
        const { list, onSort, onSelect, onChange, pageSize, onFetch } = this.props;
        const {
            page_index: pageIndex,
            page_count: pageCount,
            data_list: dataList,
            total,
            __responseTime,
        } = (list && list.toJS()) || {};
        const ListItem = ({ columns, rowData }) => (
            <ListRow
                key={ `ad-${ rowData.id }` }
                columns={ columns }
                data={ rowData }
            />
        );

        return (
            <React.Fragment>
                <FlexGroup className="functional-group" gap>
                    <FlexGroup gap>
                        <AuthFeature requiredList={ [AUTH_EDIT_BANNER] }>
                            <NavLink to={ NEW_AD }>
                                <Button color="primary" className="btn-new-ad">
                                    <Translate value="ad.new_ad" />
                                </Button>
                            </NavLink>
                        </AuthFeature>
                        <AuthFeature requiredList={ [AUTH_EDIT_BANNER] }>
                            <NavLink to={ ORDER_AD }>
                                <Button outline>
                                    <Translate value="ad.change_order" />
                                </Button>
                            </NavLink>
                        </AuthFeature>
                    </FlexGroup>
                    <FlexGroup className="pagination-status">
                        <FlexGroup className="pagination-control">
                            <Pagination
                                page={ pageIndex }
                                total={ pageCount }
                                offset={ pageSize }
                                onSelect={ onSelect }
                            />
                            <PageSizeSelect
                                onChange={ onChange }
                            />
                            <TotalCount count={ total } />
                        </FlexGroup>
                        <FlexGroup className="update-status">
                            <Refresh time={ __responseTime } onClick={ onFetch } />
                        </FlexGroup>
                    </FlexGroup>
                </FlexGroup>
                <Smart
                    seamless
                    fetch={ onFetch }
                    refreshTime={ 60 }
                    className="list-view-wrap"
                >
                    <ListView
                        header={ headerConfig }
                        onSort={ onSort }
                        list={ dataList }
                        dataKey="id"
                        renderListRow={ ListItem }
                        fixed={ isMobile }
                    />
                </Smart>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    list: state.ad.get('list'),
}))(AdList);
