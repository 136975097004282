import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'components/Datetime';
import Cell from 'components/ListView/Cell';
import { NavLink } from 'react-router-dom';
import { thousandsSeparator } from 'helpers/util';
import CopyToClipBoard from 'components/CopyToClipBoard';
import AuthFeature from 'components/AuthFeature';
import {
    AUTH_VIEW_EXPENSE_DETAIL,
    AUTH_VIEW_RENTAL_DETAIL
} from 'constants/permission';
import {
    ADDITIONAL_FEE_DETAIL,
    RENTAL_DETAIL,
} from 'constants/routes';
import buildActualPath from 'helpers/build-actual-path';
import FeeType from './FeeType';
import Subtype from './Subtype';
import ClaimType from './ClaimType';
import AdditionalFeeStatus from './AdditionalFeeStatus';

const CUSTOMER_COLUMNS_LENGTH = 6;

class ListRow extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.shape({
            expense_id: PropTypes.string.isRequired,
            customer_id: PropTypes.string,
            rental_id: PropTypes.string,
            claim_type: PropTypes.number,
            fee_type: PropTypes.number,
            subtype: PropTypes.number,
            plate_no: PropTypes.string,
            ticket_no: PropTypes.string,
            amount: PropTypes.number,
            create_time: PropTypes.string,
            expired_time: PropTypes.string,
            status: PropTypes.number,
        }).isRequired,
    };

    renderField({ data, name, length, width }) {
        let el = null;
        switch (name) {
        case 'expense_id':
            el = (
                <CopyToClipBoard>
                    <AuthFeature
                        requiredList={ [AUTH_VIEW_EXPENSE_DETAIL] }
                        fallback={ () => data.expense_id }
                    >
                        <NavLink to={ buildActualPath(ADDITIONAL_FEE_DETAIL, { expenseId: data.expense_id }) }>
                            { data.expense_id }
                        </NavLink>
                    </AuthFeature>
                </CopyToClipBoard>
            );
            break;
        case 'customer_id':
            el = (
                <CopyToClipBoard>
                    { data.customer_id }
                </CopyToClipBoard>
            );
            break;
        case 'rental_id':
            el = (
                length === CUSTOMER_COLUMNS_LENGTH ? (
                <CopyToClipBoard>
                    <AuthFeature
                        requiredList={ [AUTH_VIEW_RENTAL_DETAIL] }
                        fallback={ () => data.rental_id }
                    >
                        <NavLink to={ buildActualPath(RENTAL_DETAIL, { rentalId: data.rental_id }) }>
                            { data.rental_id }
                        </NavLink>
                    </AuthFeature>
                </CopyToClipBoard>
                ) :
                (
                    <CopyToClipBoard>
                        { data.rental_id }
                    </CopyToClipBoard>
                )
            );
            break;
        case 'claim_type':
            el = (<ClaimType type={ data.claim_type } />);
            break;
        case 'fee_type':
            el = (<FeeType type={ data.fee_type } />);
            break;
        case 'subtype':
            el = (<Subtype type={ data.subtype } />);
            break;
        case 'plate_no':
        case 'ticket_no':
            el = data[name] ?? '--';
            break;
        case 'amount':
            el = thousandsSeparator(data.amount);
            break;
        case 'create_time':
            el = (<Datetime time={ data[name] } />);
            break;
        case 'status':
            el = (<AdditionalFeeStatus type={ data.status } />);
            break;
        default:
            el = data[name];
            break;
        }

        return (
            <Cell
                key={ `${ data.id }-${ name }` }
                name={ name }
                style={ {
                    width,
                } }
            >
                { el }
            </Cell>
        );
    }

    render() {
        const { columns, data } = this.props;
        return columns.map(({ key, width }, i) => {
            return this.renderField({ data, width, name: key, length: columns.length });
        });
    }
}

export default ListRow;
