import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import {
    EMAIL_VERIFIED,
    EMAIL_UNVERIFIED,
    EMAIL_FORCE_VERIFY,
} from 'constants/customer';
import ControlTemplate from './ControlTemplate';
import { Translate } from 'react-redux-i18n';

const Component = ({ status }) => {
    let el;
    const colorMap = {
        [EMAIL_VERIFIED]: 'success',
        [EMAIL_UNVERIFIED]: 'warning',
        [EMAIL_FORCE_VERIFY]: 'danger',
    };

    switch (status) {
    case EMAIL_VERIFIED:
    case EMAIL_UNVERIFIED:
    case EMAIL_FORCE_VERIFY:
        el = (
            <Badge color={ colorMap[status] }>
                <Translate value={ `customer.email_status_${ status }` } />
            </Badge>
        );
        break;
    default:
        el = (
            <Badge color="secondary">
                <Translate value="unknown" />
            </Badge>
        );
        break;
    }

    return (
        <ControlTemplate large captionKey="customer.email_status" required={ false }>
            { el }
        </ControlTemplate>
    );
};

Component.propTypes = {
    status: PropTypes.number,
};
Component.defaultProps = {
    status: undefined,
};

export default Component;
