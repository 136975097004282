import store from 'store';

export const MATCH_ALL = 'MATCH_ALL';
export const ANY_ONE = 'ANY_ONE';

const permissionHandler = ({ requiredList, permissionList, hasLoggedIn = false, term = MATCH_ALL }) => {
    const account = store.getState().account;
    const permission = permissionList || account.get('permission');
    const isLoggedIn = hasLoggedIn || account.get('isLoggedIn');

    let isAuthenticated = isLoggedIn && permission;

    if (isAuthenticated) {
        const { authorities } = permission.toJS();
        const plainAuthorities = authorities.map(({ authority }) => authority);
        const checker = (term === MATCH_ALL ? 'every' : 'some');

        isAuthenticated = requiredList[checker](authority => plainAuthorities.indexOf(authority) > -1);
    }

    return isAuthenticated;
};

export default permissionHandler;
