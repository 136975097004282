import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import { toggleErrorDialog } from 'actions';

import './error-message.scss';

class ErrorMessage extends Component {
    static propTypes = {
        err: PropTypes.shape({}),
        errObj: PropTypes.shape({
            message: PropTypes.string,
            onClose: PropTypes.func,
        }),
    };

    static defaultProps = {
        err: undefined,
        errObj: undefined,
    }

    handleClose = () => {
        const { dispatch, errObj } = this.props;
        dispatch(toggleErrorDialog()).then(errObj.onClose);
    }

    render() {
        const { err, errObj } = this.props;

        return (
            err && errObj ?
                (
                    <Modal className="popup-error-message" onClose={ this.handleClose }>
                        <p className="error">
                            <span className="icon fas fa-exclamation-circle" />
                            <span>{ errObj.message }</span>
                        </p>
                        <Button color="primary" onClick={ this.handleClose }>
                            <Translate value="ok" />
                        </Button>
                    </Modal>
                ) :
                null
        );
    }
}

export default connect(state => ({
    err: state.general.get('err'),
    errObj: state.general.get('errObj'),
}))(ErrorMessage);
