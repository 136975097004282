import React from 'react';
import PropTypes from 'prop-types';
import ListView from 'components/ListView';
import ListRow from './ListRow';
import headerConfigFor from './ListHeader';
import mobile from 'is-mobile';

export const RENTAL_HISTORY_FULL = 0;
export const RENTAL_HISTORY_SCOOTER = 1;
export const RENTAL_HISTORY_CUSTOMER = 2;

const isMobile = mobile();
const ListRowGroup = ({ columns, rowData }) => (
    <ListRow
        key={ `ad-${ rowData.id }` }
        columns={ columns }
        data={ rowData }
    />
);
ListRowGroup.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    rowData: PropTypes.shape({
        rental_id: PropTypes.string.isRequired,
        customer_id: PropTypes.string.isRequired,
        scooter_id: PropTypes.string,
        plate_no: PropTypes.string,
        reserve_time: PropTypes.string,
        start_time: PropTypes.string,
        end_time: PropTypes.string,
        duration: PropTypes.number,
        rental_status: PropTypes.number,
        price: PropTypes.number,
        payment_status: PropTypes.number,
        corporate_type: PropTypes.number,
        distance: PropTypes.number,
    }).isRequired,
};


const RentalHistory = ({ type, sortable, onSort, history }) => {
    const columns = headerConfigFor(type);

    return (
        <ListView
            header={ columns }
            list={ history }
            onSort={ onSort }
            renderListRow={ ListRowGroup }
            dataKey="rental_id"
            fixed={ isMobile }
        />
    );
};
RentalHistory.propTypes = {
    history: PropTypes.arrayOf(
        PropTypes.shape()
    ),
    sortable: PropTypes.bool,
    onSort: PropTypes.func,
    type: PropTypes.oneOf([
        RENTAL_HISTORY_FULL,
        RENTAL_HISTORY_SCOOTER,
        RENTAL_HISTORY_CUSTOMER,
    ]),
};
RentalHistory.defaultProps = {
    history: [],
    sortable: false,
    onSort: () => {},
    type: RENTAL_HISTORY_FULL,
};

export default RentalHistory;
