import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import classNames from 'classnames';
import xss from 'xss';
import { getPieChartHole, animateArrayValue } from 'helpers/dashboard';
import {
    DASH_COLOR_BLUE,
    SCOOTER_MODEL_CHART_THICK,
} from 'constants/dashboard';
import {
    CHART_CONFIG_DEFAULT,
    CHART_DURATION_DEFAULT,
    xssOptions,
} from 'constants/chart';


class PieChart extends React.Component {
    static propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        pieValues: PropTypes.arrayOf(PropTypes.number).isRequired,
        subTitle: PropTypes.string,
        pieColors: PropTypes.arrayOf(PropTypes.string),
        thick: PropTypes.number,
        duration: PropTypes.number,
        className: PropTypes.string,
        autoResize: PropTypes.bool,
        renderLabel: PropTypes.func,
        renderSubtitle: PropTypes.func,
        labelColor: PropTypes.string,
        subTitleColor: PropTypes.string,
    };

    static defaultProps = {
        width: 0,
        height: 0,
        subTitle: '',
        pieColors: [],
        thick: undefined,
        duration: CHART_DURATION_DEFAULT,
        className: '',
        autoResize: false,
        renderLabel: () => {},
        renderSubtitle: () => {},
        labelColor: '',
        subTitleColor: '',
    };

    constructor(props) {
        super(props);
        this.animateTimer = [];
        this.state = {
            value: props.pieValues,
        };
    }

    componentDidUpdate(prevProps) {
        const { pieValues, duration } = this.props;
        if (prevProps.pieValues !== pieValues) {
            this.animateTimer = animateArrayValue(prevProps.pieValues, pieValues, duration, this.handleState);
        }
    }

    componentWillUnmount() {
        if (this.animateTimer && typeof this.animateTimer.kill === 'function') {
            this.animateTimer.kill();
        }
    }

    handleState = value => {
        this.setState({
            value,
        });
    }

    render() {
        const { value } = this.state;
        const { width,
                height,
                pieColors,
                subTitle,
                thick,
                className,
                autoResize,
                renderLabel,
                renderSubtitle,
                labelColor,
                subTitleColor } = this.props;
        const sumValue = value.reduce((a, b) => a + b, 0);
        const chartWidth = width;
        const chartHole = getPieChartHole(chartWidth, thick || SCOOTER_MODEL_CHART_THICK);
        const labelText = xss(renderLabel(sumValue), xssOptions);
        const subTitleText = xss(renderSubtitle(subTitle), xssOptions);
        const chartStyle = classNames({
            [className]: true,
            'auto-resize': autoResize,
        });

        return (
            <>
                <Plot
                    data={ [
                        {
                            values: value,
                            textinfo: 'none',
                            hoverinfo: 'none',
                            hole: chartHole,
                            sort: false,
                            direction: 'clockwise',
                            marker: {
                                colors: pieColors
                            },
                            type: 'pie',
                        }
                    ] }
                    className={ chartStyle }
                    useResizeHandler={ autoResize }
                    layout={ {
                        autosize: autoResize,
                        paper_bgcolor: 'transparent',
                        annotations: [
                            {
                                font: {
                                    size: 28,
                                    color: labelColor || DASH_COLOR_BLUE,
                                },
                                borderpad: 0,
                                showarrow: false,
                                text: labelText,
                                x: 0.5,
                                y: 0.54
                            },
                            {
                                font: {
                                    size: 16,
                                    color: subTitleColor || DASH_COLOR_BLUE,
                                },
                                borderpad: 0,
                                showarrow: false,
                                text: subTitleText,
                                x: 0.5,
                                y: 0.42,
                            }
                        ],
                        width: autoResize ? undefined : width,
                        height: autoResize ? undefined : height,
                        margin: {
                            l: 0,
                            r: 0,
                            b: 0,
                            t: 0,
                        },
                        showlegend: false,
                    } }
                    config={ CHART_CONFIG_DEFAULT }
                />
            </>
        );
    };
};

export default PieChart;
