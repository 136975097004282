import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { getBounds } from 'helpers/gmap-utils';
import { Translate, I18n } from 'react-redux-i18n';
import Modal from 'components/Modal';
import GoogleMap, { VIP_READ } from 'components/Map';
import FlexGroup from 'components/FlexGroup';
import { exporthtmlToCanvas } from 'helpers/util';
import SearchField from 'components/SearchField';
import Datetime from 'components/Datetime';
import AuthFeature from 'components/AuthFeature';
import { AUTH_CHECK_ILLEGAL_PARKING } from 'constants/permission';
import { geocodeAddress } from 'helpers/gmap-utils';
import { formatTime } from 'helpers/time-handler';

import './parking-check.scss';

const SEARCH_TIME_DEFAULT = '--';
const ADDRESS_DEFAULT = '';

const GridItem = ({ title, children }) => {
    return (
        <>
            <h5 className="profile-caption">
                <Translate value={ title } />
                <span>:</span>
            </h5>
            <div className="profile-item">
                { children }
            </div>
        </>
    );
};

class ParkingCheck extends Component {
    static propTypes = {
        item: PropTypes.shape({
            scooter_id: PropTypes.string,
            plate: PropTypes.string,
            gtu_fw_version: PropTypes.string,
            gtu_imei: PropTypes.number,
            is_activate: PropTypes.bool,
            last_connection_date: PropTypes.string,
            last_rental_end_time: PropTypes.string,
            model: PropTypes.string,
            query_date_time: PropTypes.string,
            rental_state: PropTypes.number,
            scooter_fw_version: PropTypes.string,
            scooter_state: PropTypes.number,
            soc1: PropTypes.number,
            soc2: PropTypes.number,
            tag: PropTypes.number,
            total_mileage: PropTypes.number,
            trunk_state: PropTypes.number,
            current_location: PropTypes.shape({
                lat: PropTypes.number,
                lng: PropTypes.number,
            }),
            tracker_soc: PropTypes.number,
            csq: PropTypes.number,
            satellites: PropTypes.number,
            service_id: PropTypes.string,
            corporate_name: PropTypes.string,
        }),
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        item: {},
        disabled: false,
    };
    constructor(props) {
        super(props);
        this.vipLayer = VIP_READ;
        this.marker = new window.google.maps.Marker();
        this.state = {
            openDialog: false,
            searchTime: SEARCH_TIME_DEFAULT,
            address: ADDRESS_DEFAULT
        };
    }

    mapFitBounds = (list) => {
        if (this.map) {
            if (list.length === 1) {
                const { lat, lng } = list[0];
                this.map.setCenter({
                    lat,
                    lng,
                });
                this.map.setZoom(15);
            }
            else if (list.length > 1) {
                const bounds = getBounds(list, this.maps);
                this.map.fitBounds(bounds);
            }
        }
    }

    getOneScooterList = () => {
        const { item } = this.props;
        let result = [];
        const { scooter_id, current_location, service_type, model_code } = item;
        const { lat, lng } = current_location;
        result = [{
            id: scooter_id,
            lat,
            lng,
            service_type,
            model_code,
            ...item,
        }];
        return result;
    };

    handleGoogleApi = ({ map, maps }) => {
        this.map = map;
        this.maps = maps;
        const options = {
            zoomControl: false
        };
        map.setOptions(options);
        const list = this.getOneScooterList();
        this.mapFitBounds(list);
    }

    handleToggleDialog = () => {
        const { openDialog } = this.state;
        this.setState({
            openDialog: !openDialog,
            searchTime: SEARCH_TIME_DEFAULT,
            address: ADDRESS_DEFAULT
        });
    }

    handleSubmit = value => {
        this.setState({
            address: value,
            searchTime: Date.now()
        }, () => {
            const { address } = this.state;
            geocodeAddress(address)
                .then((result) => {
                    const { lat, lng } = this.getOneScooterList()[0];
                    const scooterLocation = [{ lat, lng }];
                    const list = [...scooterLocation, result];
                    this.marker.setMap(null);
                    this.mapFitBounds(list);
                    this.map.addListener('bounds_changed', () => {
                        this.marker.setOptions({
                            position: { lat: result.lat, lng: result.lng },
                            title: address,
                        });
                        this.marker.setMap(this.map);
                    });
                })
                .catch(error => console.error(error) );
        });
    }

    exportMap = () => {
        const { item } = this.props;
        const { plate } = item;
        const { searchTime } = this.state;
        const fileName = `${plate}_${formatTime(searchTime, 'YYYYMMDD')}`;
        const exportImage = document.querySelector('.map-wrapper');
        exporthtmlToCanvas(exportImage, fileName);
    }

    render() {
        const { openDialog, searchTime, address } = this.state;
        const { item, disabled } = this.props;
        const { plate } = item;
        return (
            <AuthFeature requiredList={ [AUTH_CHECK_ILLEGAL_PARKING] }>
                <div className="parking-check">
                    <Button
                        color="outline-primary"
                        type="button"
                        onClick={ this.handleToggleDialog }
                        disabled={ disabled }
                    >
                        <Translate value="scooter.parking_check" />
                    </Button>
                    {
                        openDialog && (
                            <Modal
                                title={ I18n.t('scooter.parking_check') }
                                onClose={ this.handleToggleDialog }
                            >
                                <SearchField
                                    placeholder={ I18n.t('search') }
                                    onSubmit={ this.handleSubmit }
                                    value={ address }
                                />
                                <div className="map-wrapper">
                                    <div className="info-grid">
                                        <GridItem title={ I18n.t('scooter.plate') }>
                                            { plate }
                                        </GridItem>
                                        <GridItem title={ I18n.t('scooter.address') }>
                                            { address }
                                        </GridItem>
                                        <GridItem title={ I18n.t('scooter.search_time') }>
                                            <Datetime time={ searchTime } />
                                        </GridItem>
                                    </div>
                                    <GoogleMap
                                        innerRef={ this.elMap }
                                        className="map-view"
                                        onInternalAPI={ this.handleGoogleApi }
                                        list={ this.getOneScooterList() }
                                        vipLayer={ this.vipLayer }
                                        withoutScooterCluster
                                    />
                                </div>
                                <FlexGroup end>
                                    <Button
                                        color="primary"
                                        type="button"
                                        onClick={ this.exportMap }
                                        disabled={ address === '' }
                                    >
                                        <Translate value="export" />
                                    </Button>
                                </FlexGroup>
                            </Modal>
                        )
                    }
                </div>
            </AuthFeature>
        );
    }
}

export default ParkingCheck;
