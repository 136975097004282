import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import {
    VIOLATE_ZONE_GROUP,
} from 'constants/additional-fee';

const ViolateZone = ({ type, className }) => {
    let el;
    if (type !== undefined && VIOLATE_ZONE_GROUP.indexOf(type) > -1) {
        el = <Translate value={ `additional_fee.violate_zone_map.${ type }` } />;
    }
    else {
        el = <span>--</span>;
    }

    return (
        <div className={ className }>
            { el }
        </div>
    );
};
ViolateZone.propTypes = {
    type: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    className: PropTypes.string,
};
ViolateZone.defaultProps = {
    type: undefined,
    className: undefined,
};
export default ViolateZone;
