import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RentalState from 'components/Scooter/RentalState';
import { convertDistance, reduceCities } from 'helpers/util';
import { Translate, I18n } from 'react-redux-i18n';
import Cell from 'components/ListView/Cell';
import moment from 'moment';

class RegularMaintenanceListRow extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.shape({
            scooter_id: PropTypes.string,
            model: PropTypes.string,
            is_activate: PropTypes.bool,
            plate: PropTypes.string,
            rental_state: PropTypes.number,
            total_mileage: PropTypes.number,
            maintain_mileage: PropTypes.number,
            maintain_date: PropTypes.string,
            diff_mileage: PropTypes.number,
            city: PropTypes.number,
            service_id: PropTypes.string,
            service_type: PropTypes.number,
            corporate_id: PropTypes.string,
            corporate_name: PropTypes.string,
        }).isRequired,
    }

    renderField({ data, name, width }) {
        let el;
        let contextStyle = '';
        const value = typeof data[name] === 'string' && data[name].trim() === ''
            ? ' ' : data[name];

        switch (name) {
        case 'city':
            const { cities } = this.props;
            const citiesOptions = reduceCities(cities.toJS());
            el = citiesOptions[value];
            break;

        case 'is_activate':
            el = value ?
                <Translate value="scooter.activation.on" /> :
                <Translate value="scooter.activation.off" />;
            break;
        case 'rental_state':
            el = <RentalState status={ value } />;
            break;
        case 'corporate_name':
            let corporateName = value ? value : 'GoShare';
            el = (
                <div className="corporate-cell">
                    <div className="corporate-cell-display">
                        <span>
                            { corporateName }
                        </span>
                    </div>
                </div>
            );
            break;

        case 'total_mileage':
            el = convertDistance(value, '');
            break;
        case 'diff_mileage':
            contextStyle = data.maintain_mileage_notice ? 'text-criteria' : '';
            el = value;
            break;
        case 'maintain_date':
            contextStyle = data.maintain_date_notice ? 'text-criteria' : '';
            el = value ? moment(value).format(I18n.t('datetime_format.date')) : '--';
            break;
        case 'plate':
        case 'maintain_mileage':
        default:
            el = value;
            break;
        }
        return (
            <Cell key={ `${ data.id }-${ name }` } name={ name } style={ { width } } className={ contextStyle }>
                { el }
            </Cell>
        );
    }

    render() {
        const { columns, data } = this.props;

        return columns.map(({ key, width }) => this.renderField({ data, width, name: key, }));
    }
};

export default connect(state => ({
    cities: state.cities.get('cities')
}))(RegularMaintenanceListRow);

