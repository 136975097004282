import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RingSpinner from 'components/Spinner/Ring';
import { checkToken } from 'actions/util';

const NODE_ENV = process.env.NODE_ENV;

class Smart extends Component {
    static propTypes = {
        // Fetching api. must return a `Promise`
        fetch: PropTypes.func.isRequired,
        onUnmount: PropTypes.func,
        refreshTime: PropTypes.number,
        pauseRefresh: PropTypes.bool,
        seamless: PropTypes.bool,
        className: PropTypes.string,
        tag: PropTypes.string,
        errorMessage: PropTypes.string,
        background: PropTypes.bool,
    };

    static defaultProps = {
        // set in sec. `0` means never refresh
        refreshTime: 0,
        pauseRefresh: false,
        seamless: false,
        className: '',
        tag: 'div',
        onUnmount: () => {},
        errorMessage: 'Something went wrong.',
        background: false,
    };

    constructor(props) {
        super(props);

        this.refWrapper = React.createRef();
        this.wrapperSize = {
            width: undefined,
            height: undefined,
        };
        this.timer = null;
        this.state = {
            isLoading: true,
            hasLoaded: false,
            err: null,
        };
    }

    componentDidMount() {
        this.fetchData().then(() => {
            this.setState({
                hasLoaded: true,
            });
        });

        document.addEventListener('visibilitychange', this.handleVisibilityChange, false);

        this.start();
    }

    componentDidUpdate(prevProps) {
        const { pauseRefresh } = this.props;
        const { isLoading, seamless } = this.state;
        const elWrapper = this.refWrapper.current;
        const pauseRefreshChanged = prevProps.pauseRefresh !== pauseRefresh;

        if (elWrapper && !isLoading && !seamless && NODE_ENV !== 'test') {
            this.wrapperSize = {
                width: elWrapper.offsetWidth,
                height: elWrapper.offsetHeight,
            };
        }

        if (!pauseRefresh) {
            pauseRefreshChanged && this.start();
        }
        else {
            this.pause();
        }
    }

    componentWillUnmount() {
        const { onUnmount } = this.props;
        this.pause();
        onUnmount();
        document.removeEventListener('visibilitychange', this.handleVisibilityChange, false);
    }

    handleVisibilityChange = () => {
        const { pauseRefresh } = this.props;

        if (document.visibilityState === 'hidden') {
            this.pause();
        }

        if (document.visibilityState === 'visible' && !pauseRefresh) {
            // fetch immediately
            this.fetchData();
            this.start();
        }
    }

    start = () => {
        const { refreshTime } = this.props;

        if (!Number.isNaN(refreshTime) && refreshTime > 0) {
            this.timer = setInterval(this.fetchData, refreshTime * 1000);
        }
    }

    pause = () => {
        clearInterval(this.timer);
    }

    fetchData = () =>  {
        let promise;

        if (!this.props) {
            this.pause();
            promise = Promise.resolve();
        }
        else {
            const { dispatch, fetch } = this.props;
            this.setState({
                isLoading: true,
            });

            promise = checkToken(dispatch).then(fetch).then((res = {}) => {
                const { error } = res;

                if (error) {
                    throw error;
                }

                this.setState({
                    isLoading: false,
                    err: null,
                });

                return res;
            }).catch(err => {
                this.setState({
                    isLoading: false,
                    err,
                });
            });
        }

        return promise;
    }

    renderSpinner() {
        return <div><RingSpinner /></div>;
    }

    renderError() {
        const { err } = this.state;
        const { errorMessage } = this.props;

        return (err ? <p className="error">{ errorMessage }</p> : null);
    }

    render() {
        const { background, children, tag, className, seamless } = this.props;
        const { width, height } = this.wrapperSize;
        const { isLoading, hasLoaded } = this.state;
        const CustomTag = tag && !background ? tag : React.Fragment;
        const customStyle = (
            (isLoading && !seamless && width && height) ?
                {
                    width: `${ width }px`,
                    height: `${ height }px`,
                } :
                {}
        );
        let props = {};

        const errMessage = this.renderError();
        let el = ( isLoading && !seamless ? this.renderSpinner() : children );

        if (errMessage && !background) {
            el = errMessage;
        }

        if (!hasLoaded || background) {
            el = null;
        }

        if (tag && !background) {
            props = {
                className,
                style: customStyle,
                ref: this.refWrapper,
            };
        }

        return (
            <CustomTag { ...props }>
                { el }
            </CustomTag>
        );
    }
}

export default connect()(Smart);
