export const CLAIM_TYPE_NO_CLAIM = 0;
export const CLAIM_TYPE_CLAIM = 1;
export const CLAIM_TYPE_GROUP = [
    CLAIM_TYPE_NO_CLAIM,
    CLAIM_TYPE_CLAIM,
];

export const FEE_TYPE_FINE = 0;
export const FEE_TYPE_DISPATCH = 1;
export const FEE_TYPE_REPAIR = 2;
export const FEE_TYPE_WITHHOLDING = 3;
export const FEE_TYPE_GROUP = [
    FEE_TYPE_FINE,
    FEE_TYPE_WITHHOLDING,
    FEE_TYPE_DISPATCH,
    FEE_TYPE_REPAIR,
];

export const FEE_STATUS_UNPAID = 11;
export const FEE_STATUS_FAILED = 12;
export const FEE_STATUS_PAID = 21;
export const FEE_STATUS_REFUND = 31;
export const FEE_STATUS_CANCEL = 41;
export const FEE_STATUS_PROCESSING = 51;
export const FEE_STATUS_PROCESS_SUCCESS = 52;
export const FEE_STATUS_PROCESS_FAILED = 53;
export const FEE_STATUS_ABSORBED_BY_INTR = 61;

export const REVOKE_FEE_WHITELIST = [
    FEE_STATUS_UNPAID,
    FEE_STATUS_FAILED,
    FEE_STATUS_PROCESSING,
    FEE_STATUS_PROCESS_SUCCESS,
    FEE_STATUS_PROCESS_FAILED,
];

export const PRINT_FEE_WHITELIST = [
    FEE_STATUS_PROCESSING,
    FEE_STATUS_PROCESS_SUCCESS,
    FEE_STATUS_PROCESS_FAILED,
];
export const NO_CLAIM_FEE_EDIT_STATUS_GROUP = [
    FEE_STATUS_PROCESSING,
    FEE_STATUS_PROCESS_SUCCESS,
    FEE_STATUS_PROCESS_FAILED,
];

export const FEE_SUBTYPE_VIOLATE_ILLEGAL_PARKING = 0;
export const FEE_SUBTYPE_VIOLATE_NON_ILLEGAL_PARKING = 1;
export const FEE_SUBTYPE_CHARGE_PRIVATE_PARKING = 9;
export const FEE_SUBTYPE_CHARGE_TOWING_YARD = 10;
export const FEE_SUBTYPE_TOWING_ILLEGAL_PARKING = 2;
export const FEE_SUBTYPE_TOWING_TOWING_YARD = 3;
export const FEE_SUBTYPE_TOWING_OUTSIDE = 4;
export const FEE_SUBTYPE_TOWING_TRAFFIC_ACCIDENT = 5;
export const FEE_SUBTYPE_TOWING_DRUNK_DRIVING = 6;
export const FEE_SUBTYPE_REPAIR_CAR_DAMAGE = 7;
export const FEE_SUBTYPE_REPAIR_SUSPECTED_DAMAGE = 8;

export const FEE_SUBTYPE_GROUP = {
    [FEE_TYPE_FINE]: [
        FEE_SUBTYPE_VIOLATE_ILLEGAL_PARKING,
        FEE_SUBTYPE_VIOLATE_NON_ILLEGAL_PARKING,
    ],
    [FEE_TYPE_WITHHOLDING]: [
        FEE_SUBTYPE_CHARGE_PRIVATE_PARKING,
        FEE_SUBTYPE_CHARGE_TOWING_YARD,
    ],
    [FEE_TYPE_DISPATCH]: [
        FEE_SUBTYPE_TOWING_ILLEGAL_PARKING,
        FEE_SUBTYPE_TOWING_TOWING_YARD,
        FEE_SUBTYPE_TOWING_OUTSIDE,
        FEE_SUBTYPE_TOWING_TRAFFIC_ACCIDENT,
        FEE_SUBTYPE_TOWING_DRUNK_DRIVING,
    ],
    [FEE_TYPE_REPAIR]: [
        FEE_SUBTYPE_REPAIR_CAR_DAMAGE,
        FEE_SUBTYPE_REPAIR_SUSPECTED_DAMAGE,
    ],
};

export const VIOLATE_ZONE_KLU = 1;
export const VIOLATE_ZONE_TPE = 2;
export const VIOLATE_ZONE_TPH = 3;
export const VIOLATE_ZONE_TYC = 4;
export const VIOLATE_ZONE_HSC = 5;
export const VIOLATE_ZONE_HSH = 6;
export const VIOLATE_ZONE_MAL = 7;
export const VIOLATE_ZONE_TXG = 8;
export const VIOLATE_ZONE_CWH = 9;
export const VIOLATE_ZONE_NTO = 10;
export const VIOLATE_ZONE_YLH = 11;
export const VIOLATE_ZONE_CYI = 12;
export const VIOLATE_ZONE_CHY = 13;
export const VIOLATE_ZONE_TNN = 14;
export const VIOLATE_ZONE_KHH = 15;
export const VIOLATE_ZONE_IUH = 16;
export const VIOLATE_ZONE_TTT = 17;
export const VIOLATE_ZONE_HWA = 18;
export const VIOLATE_ZONE_ILN = 19;
export const VIOLATE_ZONE_PEH = 20;
export const VIOLATE_ZONE_KMN = 21;
export const VIOLATE_ZONE_LNN = 22;


export const VIOLATE_ZONE_GROUP = [
    VIOLATE_ZONE_TPE,
    VIOLATE_ZONE_TPH,
    VIOLATE_ZONE_TYC,
    VIOLATE_ZONE_TXG,
    VIOLATE_ZONE_TNN,
    VIOLATE_ZONE_KHH,
    VIOLATE_ZONE_YLH,
    VIOLATE_ZONE_HSC,
    VIOLATE_ZONE_HSH,
    VIOLATE_ZONE_KLU,
    VIOLATE_ZONE_MAL,
    VIOLATE_ZONE_CWH,
    VIOLATE_ZONE_NTO,
    VIOLATE_ZONE_CHY,
    VIOLATE_ZONE_CYI,
    VIOLATE_ZONE_IUH,
    VIOLATE_ZONE_ILN,
    VIOLATE_ZONE_HWA,
    VIOLATE_ZONE_TTT,
    VIOLATE_ZONE_PEH,
    VIOLATE_ZONE_KMN,
    VIOLATE_ZONE_LNN,
];


export const ERROR_RENTAL_NOT_FOUND = 1;
export const ERROR_ZENDESK_NO_EMPTY = 2;
export const ERROR_FEE_TYPE_ERROR = 3;
export const ERROR_AMOUNT_OUT_OF_RANGE = 4;
export const ERROR_RENTAL_STATUS_ERROR = 9;
export const ERROR_RENTAL_PLATE_NOT_MATCH = 10;
export const ERROR_RENTAL_PLATE_NOT_FOUND = 11;
export const ERROR_RENTAL_SUBTYPE_ERROR = 12;
export const ERROR_RENTAL_PLATE_EMPTY = 13;
export const ERROR_VIOLATE_TIME_EMPTY = 14;
export const ERROR_TICKET_NO_EMPTY = 15;
export const ERROR_ATTACHMENT_DATA_ERROR = 16;

export const ERROR_EXPENSE_NOT_FOUND = 5;
export const ERROR_EXPENSE_STATUS_ERROR = 6;

