/**
 * Customer related
 */
// id
export const APPROVAL_APPROVE_ID = 1;
export const APPROVAL_REVOKE_ID = 0;

// license
export const APPROVAL_APPROVE_LIGHT_DUTY = 0;
export const APPROVAL_APPROVE_HEAVY_DUTY = 1;
export const APPROVAL_REVOKE_DRIVER_LICENSE = 2;

export const APPROVAL_KEEP_CURRENT_VALUE = -1;

export const FORBIDDEN_DRIVER_LICENSE_ISSUE = 15;
// api
export const VERIFICATION_NOT_YET = 0;
export const VERIFICATION_SUCCESS = 1;
export const VERIFICATION_DENIED_FRAUD = 2;
export const VERIFICATION_DENIED_UNSUPPORTED_ID_TYPE = 3;
export const VERIFICATION_DENIED_UNSUPPORTED_ID_COUNTRY = 4;
export const VERIFICATION_ERRO_NOT_READABLE_ID = 5;
export const VERIFICATION_VALIDITY_FALSE = 6;
export const VERIFICATION_VALIDITY_TRUE = 7;
export const VERIFICATION_RESULT_NOT_MATCH_OTHER_TYPE = 8;
export const VERIFICATION_INVALID_DOC = 9;
export const VERIFICATION_DUPLICATED_ID = 10;
export const VERIFICATION_VERIFYING = 11;
export const VERIFICATION_ID_TYPE_MISMATCH = 12;

export const LICENSE_TYPE_INVALID_LICENSE = -1;
export const LICENSE_TYPE_OTHER = 0;
export const LICENSE_TYPE_ORDINARY_HEAVY_DUTY_MOTORCYCLE = 1;
export const LICENSE_TYPE_LARGE_HEAVY_DUTY_MOTORCYCLE = 2;

export const COUPON_STATE_ACTIVE = 0;
export const COUPON_STATE_PAST = 1;

export const PLAN_BASIC = 0;
export const PLAN_CORPORATE = 2;

export const CORPORATE_TYPE_NORMAL = 0;
export const CORPORATE_TYPE_BASIC = 1;
export const CORPORATE_TYPE_VIP = 2;

// email
export const EMAIL_VERIFIED = 0;
export const EMAIL_UNVERIFIED = 1;
export const EMAIL_FORCE_VERIFY = 2;

// error code
export const ERROR_NOT_FOUND = 2001;
export const ERROR_ID_DUPLICATED = 2002;
export const ERROR_EMAIL_DUPLICATED = 2003;
export const ERROR_BANNED_LIGHT_DUTY = 2004;
export const ERROR_FORMAT_ERROR = 2005;
export const ERROR_PHONE_DUPLICATED = 2006;

// is this customer able to rent
export const OVERALL_UNVERIFIED = 0;
export const OVERALL_ALLOWED = 1;
export const OVERALL_MANDATORY_VERIFY_EMAIL = 2;
export const OVERALL_NOT_MATCH_ID = 3;
export const OVERALL_DUPLICATED_ID = 4;
export const OVERALL_FAILED_VERIFICATION = 5;
export const OVERALL_RENT_SUSPENDED = 6;
export const OVERALL_NO_BINDING_CREDITCARD = 100;
export const OVERALL_EXPIRED_PAYMENT_REQUEST = 101;
export const OVERALL_OUTSTANDING_RENTAL_PAYMENT = 102;

// state
export const IN_PROGRESS = 0;
export const ABORTED = 1;
export const ACHIEVED = 2;
export const EXPIRED = 3;

// document type (for watermark)
export const ID_SCAN_IMAGE = 'idScanImage';
export const DRIVER_SCAN_IMAGE_FRONT = 'driverScanFrontImage';
export const DRIVER_SCAN_IMAGE_BACK = 'driverScanBackImage';

export const TIMES_LIMIT_TYPE_UNLIMITED = 0;
export const TIMES_LIMIT_TYPE_PER_DAY = 1;
export const TIMES_LIMIT_TYPE_PER_MONTH = 2;

export const EASY_CARD = 0;
export const MENGO = 1;

export const PURCHASE_CANCELED = 0;
export const PURCHASE_ACTIVE = 1;
export const PURCHASE_EXPIRED = 2;
export const PURCHASE_INCOMING = 3;

export const ERROR_CODE_REFUND_0 = 0;
export const ERROR_CODE_REFUND_40001 = 40001;
export const ERROR_CODE_REFUND_40901 = 40901;
export const ERROR_CODE_REFUND_40902 = 40902;
export const ERROR_CODE_REFUND_40903 = 40903;
export const ERROR_CODE_REFUND_40904 = 40904;

// Day Pass (GSH Pass)
export const GSH_PASS_STATE_ACTIVE = 1;
export const GSH_PASS_STATE_EXPIRED = 2;

export const ERROR_CODE_PASS_REFUND_41001 = 41001;
export const ERROR_CODE_PASS_REFUND_41002 = 41002;
export const ERROR_CODE_PASS_REFUND_41003 = 41003;
export const ERROR_CODE_PASS_REFUND_41004 = 41004;
export const ERROR_CODE_PASS_REFUND_41005 = 41005;
