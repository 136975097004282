/**
 * Reduder - Dashboard
 */
import { Map, List } from 'immutable';
import {
    FETCH_OVERALL_STATISTICS,
    FETCH_DOTS_STATISTICS,
    FETCH_TV_MONITOR_STATISTICS,
} from 'constants/action-type';
import {
    SERVICE_TYPE_FREE_FLOATING,
    SERVICE_TYPE_DOTS,
    CITY_OTHERS,
} from 'constants/scooter';
import creater from './util/creater';

const initialState = Map({
    updatedTime: undefined,
    overall: Map({
        regions: Map({
            overall: {},
            list: [],
        }),
        scooterModels: List([]),
    }),
    dots: Map({
        overall: Map({}),
        list: List([]),
    }),
    tvMonitor: Map({
        overallList: List([]),
        overallFreeFloating: Map({}),
        scooterModels: List([]),
    })
});

export const getOverallTotalStat = data => {
    const { operating, service_level } = data;
    return {
        operating,
        service_level,
    };
};

export const getFreeFloatingRegions = list => {
    return list.map(item => ({
        ...item,
        service_type: SERVICE_TYPE_FREE_FLOATING,
    }));
};

export const getDotsRegions = list => {
    return list.filter(item => item.service_type === SERVICE_TYPE_DOTS)
        .map(dot => ({
            ...dot,
            city_id: -1,
            service_type: SERVICE_TYPE_DOTS,
        }));
};

export const getDotsList = list => {
    return list.map(dot => ({
        ...dot,
    }));
};

export const orderCities = (freeFloatingList, dotsList) => {
    const cityOthers = freeFloatingList.filter( item =>
        item.service_type === SERVICE_TYPE_FREE_FLOATING && item.city_id === CITY_OTHERS);
    const restCities = freeFloatingList.filter( item => item.city_id !== CITY_OTHERS);

    return [...restCities, ...dotsList, ...cityOthers];
};

const actionsMap = {
    [FETCH_TV_MONITOR_STATISTICS]: (state, action) => {
        const { data } = action;
        const { cities, services } = data;
        const { scooter_models } = data.overall;
        const dots = getDotsRegions(services);
        const freeFloating = getFreeFloatingRegions(cities);
        const list = orderCities(freeFloating, dots);
        const overallFreeFloating = services.filter(({ service_type }) => service_type === SERVICE_TYPE_FREE_FLOATING);
        return state.merge({
            tvMonitor: Map({
                overallList: List(list),
                overallFreeFloating: Map(overallFreeFloating[0]),
                scooterModels: List(scooter_models),
            }),
        });
    },
    [FETCH_OVERALL_STATISTICS]: (state, action) => {
        const { data } = action;
        const { update_time, cities, services } = data;
        const overall = getOverallTotalStat(data.overall);
        const freeFloating = getFreeFloatingRegions(cities);
        const dots = getDotsRegions(services);
        const list = orderCities(freeFloating, dots);
        const { scooter_models } = data.overall;
        return state.merge({
            updatedTime: update_time,
            overall: Map({
                regions: Map({
                    overall,
                    list,
                }),
                scooterModels: List(scooter_models),
            }),
        });
    },
    [FETCH_DOTS_STATISTICS]: (state, action) => {
        const { data } = action;
        const { update_time, services, dots } = data;
        const dotsOverall = getDotsRegions(services);
        const dotsList = getDotsList(dots);
        return state.merge({
            updatedTime: update_time,
            dots: Map({
                overall: Map({
                    ...dotsOverall[0],
                }),
                list: List(dotsList),
            }),
        });
    },

};

export default creater(actionsMap, initialState);
