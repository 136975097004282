import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { Translate } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import { COPY_CONFIG, COPY_TYPE_MISSION, COPY_TYPE_AD, COPY_TYPE_COUPON, setFastCopied } from 'helpers/fast-copy';
import { useHistory } from 'react-router-dom';


const FastCopy = ({
    type,
    copyItem,
}) => {
    const [config, setConfig] = useState();
    const history = useHistory();

    const getCopyConfig = type => {
        return COPY_CONFIG[type];
    };

    const handleClick = e => {
        const { redirect, method } = config;
        const copied = method(copyItem);
        history.push({
            pathname: redirect,
        });
        setFastCopied(type, copied);
    };

    useEffect(() => {
        if (type && type.length) {
            setConfig(getCopyConfig(type));
        }
    }, [type]);

    return (
        <Button
            type="button"
            color="outline-primary"
            onClick={ handleClick }
            disabled={ !type.length || !copyItem?.id?.length || config === undefined }
        >
            <Translate value="copy" />
        </Button>
    );
};

FastCopy.propTypes = {
    type: PropTypes.oneOf([COPY_TYPE_MISSION, COPY_TYPE_AD, COPY_TYPE_COUPON, '']),
    copyItem: PropTypes.shape({
        id: PropTypes.string,
    }),
};

FastCopy.defaultProps = {
    type: '',
    copyItem: {},
};

export default FastCopy;
