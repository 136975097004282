import { getBounds } from 'helpers/gmap-utils';

export const overallListMapFitbound = (list, map, maps) => {
    if (list.length > 0 && map) {
        if (list.length === 1) {
            const { lat, lng } = list[0];
            map.setCenter({
                lat,
                lng,
            });
            map.setZoom(15);
        }
        else if (list.length > 1) {
            const bounds = getBounds(list, maps);
            map.fitBounds(bounds);
        }
    }
};

export const listFitBound = (list, map, maps, panTo) => {
    if (list.length > 0 && map) {
        // map fit bounds
        let locations = [];
        list.forEach( ({ location_lat, location_lng }) => {
            location_lat && location_lng && locations.push({
                lat: location_lat,
                lng: location_lng,
            });
        });

        if (panTo) {
            const bounds = getBounds(locations, maps);
            map.fitBounds(bounds);
        }
    }
};

export const checkRouteStatus = (params) => Object.keys(params).length === 0;

export const checkRouteDifferent = (prevParams, params) => {
    const key = Object.keys(params)[0];
    return prevParams.hasOwnProperty(key);
};
