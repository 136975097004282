import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { AutoResizer } from 'react-base-table';
import { DropTarget } from 'react-dnd';
import 'react-base-table/styles.css';
import AnimatedTable from './AnimatedTable';
import ListHeader from './ListHeader';
import './orderable-list-view.scss';
import { LIST_ROW } from './type';

class OrderableListView extends Component {
    static propTypes = {
        header: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
            ]),
            sortable: PropTypes.bool,
            hide: PropTypes.bool,
        })),
        dataKey: PropTypes.string.isRequired,
        onMoveRow: PropTypes.func,
        list: PropTypes.arrayOf(PropTypes.shape({})),
        renderOrderableListRow: PropTypes.func.isRequired,
        connectDropTarget: PropTypes.func.isRequired,
        fixed: PropTypes.bool,
    }

    static defaultProps = {
        header: [],
        list: [],
        fixed: false,
        onMoveRow: () => {},
    }

    state = {
        draggingIndex: undefined,
    }

    setRef = node => {
        const { connectDropTarget } = this.props;
        connectDropTarget(node);

    }

    findRow = uniqKey => {
        const { list, dataKey } = this.props;
        const row = list.find(row => row[dataKey] === uniqKey);
        const index = list.findIndex(row => row[dataKey] === uniqKey);

        return {
            index,
            row,
        };
    }

    startMovingRow = uniqKey => {
        const { index } = this.findRow(uniqKey);
        this.setState({ draggingIndex: index });
    }

    moveRow = (uniqKey, nextIndex) => {
        const { onMoveRow } = this.props;
        const { index: currentIndex } = this.findRow(uniqKey);

        // For performance reason, avoid useless render makes animation smoonth
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.draggingIndex = nextIndex;
        onMoveRow(currentIndex, nextIndex);
    }

    rowDidMove = () => {
        this.setState({ draggingIndex: undefined });
    }

    headerRenderer = ({ columns }) => {
        return (
            <ListHeader items={ columns } />
        );
    }

    rowClassName = ({ index }) => {
        return (
            `row-${ index % 2 === 0 ? 'odd' : 'even' }`
        );
    }

    render() {
        const { header, dataKey, fixed } = this.props;
        const { list, renderOrderableListRow } = this.props;
        const { draggingIndex } = this.state;
        const columns = header.map(({
            name,
            value,
            width,
            hide,
        }) => ({
            dataKey: name,
            key: name,
            title: I18n.t(value),
            hidden: hide,
            width,
        }));
        let totalRowHeight = 0;
        const data = list.map((item, index) => {
            const scale = index === draggingIndex ? 1.03 : 1;
            const y = totalRowHeight;
            const boxShadow = index === draggingIndex ? 10 : 0;
            const rowHeight = 40;
            totalRowHeight += rowHeight;

            return {
                rowHeight,
                transform: [y, scale],
                boxShadow,
                parentId: null,
                id: item[dataKey],
                ...item,
            };
        });

        const dndProps = {
            startMovingRow: this.startMovingRow,
            rowDidMove: this.rowDidMove,
            moveRow: this.moveRow,
            findRow: this.findRow,
        };

        return (
            <div ref={ this.setRef } className="orderable-list-view">
                <AutoResizer>
                    { ({ width, height }) => {
                        const totalWidth = columns.reduce((w, { width }) => w += width, 0);
                        const defaultWidth = fixed ? totalWidth : width;
                        const resizedColumns = fixed ? columns : columns.map(column => {
                            // recalculate width for each column by the ratio to total width
                            const ratio = column.width / totalWidth;
                            const resizedWidth = width * ratio;

                            return {
                                ...column,
                                width: resizedWidth,
                            };
                        });

                        return (
                            <AnimatedTable
                                width={ defaultWidth }
                                headerHeight={ 40 }
                                height={ height }
                                data={ data }
                                headerRenderer={ this.headerRenderer }
                                rowClassName={ this.rowClassName }
                                rowRenderer={ renderOrderableListRow }
                                columns={ resizedColumns }
                                dndProps={ dndProps }
                            />
                        );
                    } }
                </AutoResizer>
            </div>
        );
    }
}

export default (DropTarget(
    [LIST_ROW],
    {},
    connect => ({
        connectDropTarget: connect.dropTarget(),
    }),
)(OrderableListView));
