import {
    STATUS_DISABLE,
    STATUS_DRAFT,
    STATUS_PUBLISH,
    STATUS_LIVE,
    STATUS_EXPIRY,
    LAUNCH_MODE_INAPP_WEBVIEW,
    LAUNCH_MODE_DEFAULT_BROWSER,
    AD_ORDER_STATUS_ERROR,
} from 'constants/ad';

const ad = {
    'new_document_title': 'New Ad',
    'order_document_title': 'Ad Order',
    [`status_${ STATUS_DISABLE }`]: 'Disabled',
    [`status_${ STATUS_DRAFT }`]: 'Draft',
    [`status_${ STATUS_PUBLISH }`]: 'Published',
    [`status_${ STATUS_LIVE }`]: 'Online',
    [`status_${ STATUS_EXPIRY }`]: 'Expired',
    [`type_launch_${ LAUNCH_MODE_INAPP_WEBVIEW }`]: 'In App Webview',
    [`type_launch_${ LAUNCH_MODE_DEFAULT_BROWSER }`]: 'Device default web browser',
    'new_ad': 'New Ad',
    'id': 'ID',
    'title': 'Title',
    'status': 'Status',
    'description': 'Description',
    'launch_mode': 'Launch Mode',
    'link': 'Link',
    'visual': 'Visual',
    'publish_time': 'Published',
    'publish_period': 'Published Period',
    'valid_period': 'Valid Period',
    'valid_from': 'Valid From',
    'valid_to': 'Valid To',
    'search_box_placeholder': 'Title keywords',
    'details': 'Details',
    'save': 'Save',
    'invalid_date': 'Invalid Date',
    'wrong_sequence_date': '`To` Time Has To Be Ahead of `From` Time',
    'wrong_api_call': 'Oops! Something Went Wrong',
    'publish': 'Publish',
    'withdraw': 'Withdraw',
    'published_by': 'Published By',
    'published_time': 'Published Time',
    'change_order': 'Order Ad',
    'brief_intro': 'Please drag the table row to change AD order',
    'confirm_order_change': 'Confirm Ad order',
    'confirm_order_change_describe': 'Are you sure to apply the current order?',
    'continuely_edit': 'Edit again',
    [`ad_order_error_${ AD_ORDER_STATUS_ERROR }`]: 'Ad status was changed, please order again.',
};

export default ad;
