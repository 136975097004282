import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { CardBody, Card } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import Select from 'react-select';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Form/Button';
import Form from 'components/Form';
import { fetchCompensationCoupon } from 'actions';

const CouponSelectField = ({ coupons, onChange }) => {

    const options = coupons.map(
        ({ coupon_id, title }) => ({
            value: coupon_id,
            label: title,
        })
    );
    const handleChange = opt => {
        onChange(opt.value);
    };
    return (
        <div className="control-group view-mode">
            <div className="control-wrapper center">
                <span className="view-control">
                    <Select
                        options={ options }
                        onChange={ handleChange }
                    />
                </span>
            </div>
        </div>
    );
};

CouponSelectField.propTypes = {
    coupons: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onChange: PropTypes.func.isRequired,
};


class CompensationModal extends Component {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        compensationList: ImmutablePropTypes.list.isRequired,
    };

    static defaultProps = {
        onClose: () => {},
        onSubmit: () => {},
    };

    constructor(props) {
        super(props);

        this.couponId = undefined;
        this.state = {
            inProgress: false,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fetchCompensationCoupon());
    }

    handleCouponChange = couponId => {
        this.couponId = couponId;
    }

    handleSubmit = () => {
        const { onSubmit } = this.props;
        this.setState({
            inProgress: true,
        });

        onSubmit({
            coupon_id: this.couponId,
        }).then(() => {
            this.setState({
                inProgress: false,
            });
        });
    }

    render() {
        const { show, onClose, compensationList } = this.props;
        const { inProgress } = this.state;
        const coupons = compensationList.toJS();

        return show ? (
            <Modal
                className="compensation-modal"
                title={ I18n.t('customer.compensation') }
                onClose={ onClose }
            >
                <Form
                    inline
                    onSubmit={ this.handleSubmit }
                    inProgress={ inProgress }
                >
                    <Card>
                        <CardBody>
                            <CouponSelectField
                                coupons={ coupons }
                                onChange={ this.handleCouponChange }
                            />
                        </CardBody>
                    </Card>
                    <ButtonGroup>
                        <Button
                            type="submit"
                            color="primary"
                        >
                            <Translate value="apply" />
                        </Button>
                    </ButtonGroup>
                </Form>
            </Modal>
        ) : null;
    }
}
export default connect(state => ({
    compensationList: state.customer.get('compensationList'),
}))(CompensationModal);
