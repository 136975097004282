import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import {
    RENTAL_ACTION_TIMEOUT,
    RENTAL_ACTION_RESERVE,
    RENTAL_ACTION_CANCEL,
    RENTAL_ACTION_BEEP,
    RENTAL_ACTION_RIDE,
    RENTAL_ACTION_PARK,
    RENTAL_ACTION_OPEN_TRUNK,
    RENTAL_ACTION_RETURN,
} from 'constants/rental';

const Component = ({ status, className }) => {
    const renderProperText = () => {
        let el;

        switch (status) {
        case RENTAL_ACTION_TIMEOUT:
        case RENTAL_ACTION_RESERVE:
        case RENTAL_ACTION_CANCEL:
        case RENTAL_ACTION_BEEP:
        case RENTAL_ACTION_RIDE:
        case RENTAL_ACTION_PARK:
        case RENTAL_ACTION_OPEN_TRUNK:
        case RENTAL_ACTION_RETURN:
            el = <Translate value={ `scooter.rental_action_map.${ status }` } />;
            break;
        default:
            el = <Translate value="unknown" />;
        }

        return el;
    };

    return (
        <div className={ className }>
            { renderProperText() }
        </div>
    );
};

Component.propTypes = {
    status: PropTypes.number.isRequired,
    className: PropTypes.string,
};

Component.defaultProps = {
    className: '',
};

export default Component;
