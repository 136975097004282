import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import mobile from 'is-mobile';
import moment from 'moment';
import { Button } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import ButtonGroup from 'components/ButtonGroup';
import FlexGroup from 'components/FlexGroup';
import ListView from 'components/ListView';
import { TotalCount } from 'components/Pagination';
import { exportCSV } from 'helpers/util';
import headerConfig from './ListHeader';
import ListRow from './ListRow';
import ImportFile from './ImportFile';
import EmptyResult from './EmptyResult';

const exportKey = [
    'plate_no',
    'violate_time',
    'rental_id',
    'start_time',
    'end_time',
    'full_name',
    'customer_id',
    'scooter_id',
    'first_name',
    'last_name',
    'email',
    'phone',
];

class TotalList extends Component {
    static propTypes = {
        list: PropTypes.arrayOf(PropTypes.object).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showActionPanel: false,
        };
    }

    toggleActionPanel = () => {
        const { showActionPanel } = this.state;
        this.setState({
            showActionPanel: !showActionPanel,
        });
    }

    handleExport = () => {
        const { list } = this.props;
        if (list.length !== 0) {
            const dataList = list.map(item => {
                let result = item;
                exportKey.forEach((key, index) => {
                    if (!item.hasOwnProperty(key)) {
                        result = { ...result, [key]: ' - ' };
                    }
                });
                result['start_time'] = result['start_time'] && moment(new Date(result['start_time'])).isValid() ?
                    moment(new Date(result['start_time'])).format(I18n.t('datetime_format.long')) : ' - ';
                result['violate_time'] = result['violate_time'] && moment(new Date(result['violate_time'])).isValid() ?
                    moment(new Date(result['violate_time'])).format(I18n.t('datetime_format.long')) : ' - ';
                result['end_time'] = result['end_time'] && moment(new Date(result['end_time'])).isValid() ?
                    moment(new Date(result['end_time'])).format(I18n.t('datetime_format.long')) : ' - ';

                result = Object.entries(result).sort()
                    .reduce( (o, [k, v]) => ((o[k] = v, o)), {} );
                const exportOrder = {
                    'plate_no': null,
                    'violate_time': null,
                    'rental_id': null,
                    'start_time': null,
                    'end_time': null,
                    'full_name': null,
                    'customer_id': null,
                    'email': null,
                    'phone': null,
                };
                result['full_name'] = '';
                delete result['first_name'];
                delete result['last_name'];
                delete result['scooter_id'];
                delete result['violate_in_rental'];
                delete result['id'];
                result = Object.assign(exportOrder, result);
                return [...Object.values(result)];
            });
            const convertArray = dataList.map((data) => {
                return data.join();
            });
            exportCSV(`${I18n.t('violation.traffic_violation')}_${moment().format('YYYYMMDD')}`,
                '車號,案件發生時間,Rental ID,租車時間,還車時間,承租人（收費對象）,User ID,mail,承租人電話\n',
                convertArray,
                '\n'
            );
        }
    }


    render() {
        const { list } = this.props;
        const { showActionPanel } = this.state;
        const ListItem = ({ columns, rowData }) => {
            return (
                <ListRow
                    key={ `violation-${ rowData.customer_id }` }
                    columns={ columns }
                    data={ rowData }
                />
            );
        };
        const listCls = classNames([
            'list-view-wrap',
            'full',
        ]);

        return (
            <React.Fragment>
                {
                    list.length === 0 ? <EmptyResult onClick={ this.toggleActionPanel } /> : (
                        <>
                            <FlexGroup className="violation-list-control-sec">
                                <FlexGroup start className="list-view-control">
                                    <TotalCount count={ list.length } />
                                </FlexGroup>
                                <FlexGroup end>
                                    <ButtonGroup>
                                        <Button
                                            disabled={ list.length === 0 }
                                            outline
                                            color="primary"
                                            onClick={ this.handleExport }
                                        >
                                            <Translate value="export" />
                                        </Button>
                                        <Button color="primary" onClick={ this.toggleActionPanel }>
                                            <Translate value="import" />
                                        </Button>
                                    </ButtonGroup>
                                </FlexGroup>
                            </FlexGroup>
                            <section className="violation-list-table">
                                <div className={ listCls }>
                                    <ListView
                                        header={ headerConfig }
                                        dataKey="id"
                                        list={ list }
                                        renderListRow={ ListItem }
                                        fixed={ mobile() }
                                    />
                                </div>
                            </section>
                        </>
                    )
                }

                {
                    showActionPanel && <ImportFile onCancel={ this.toggleActionPanel } />
                }
            </React.Fragment>
        );
    }
}

export default TotalList;
