import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import {
    SCOOTER_STATE_UNLOCK,
    SCOOTER_STATE_ENGINE_ON,
    SCOOTER_STATE_LOCK,
    SCOOTER_STATE_SHUTDOWN,
} from 'constants/scooter';

const Component = ({ status, className }) => {
    const renderProperText = () => {
        let el;

        switch (status) {
        case SCOOTER_STATE_UNLOCK:
        case SCOOTER_STATE_ENGINE_ON:
        case SCOOTER_STATE_LOCK:
        case SCOOTER_STATE_SHUTDOWN:
            el = <Translate value={ `scooter.scooter_state_map.${ status }` } />;
            break;
        case undefined:
        case null:
            el = '--';
            break;
        default:
            el = <Translate value="unknown" />;
        }

        return el;
    };

    return <div className={ className }>{ renderProperText() }</div>;
};

Component.propTypes = {
    status: PropTypes.number,
    className: PropTypes.string,
};

Component.defaultProps = {
    status: undefined,
    className: '',
};

export default Component;
