import {
    TRUNK_CLOSED,
    TRUNK_OPEN,
    TRUNK_LOCKED,

    SCOOTER_STATE_UNLOCK,
    SCOOTER_STATE_ENGINE_ON,
    SCOOTER_STATE_LOCK,
    SCOOTER_STATE_SHUTDOWN,

    SCOOTER_OFFLINE,
    SCOOTER_ONLINE,

    TICKET_NONE,
    TICKET_SWAP,
    TICKET_SWAP_F,
    TICKET_DISPATCH,
    TICKET_MAINTAIN,

    GTU_ERROR_CODE_15000,
    GTU_ERROR_CODE_15001,
    GTU_ERROR_CODE_15002,
    GTU_ERROR_CODE_15003,
    GTU_ERROR_CODE_15004,
    GTU_ERROR_CODE_15005,
    GTU_ERROR_CODE_15006,
    GTU_ERROR_CODE_15007,
    FORCE_RETURN_ERROR_CODE_3000,
    FORCE_RETURN_ERROR_CODE_3001,
    FORCE_RETURN_ERROR_CODE_3006,
    FORCE_RETURN_ERROR_CODE_3007,
    FORCE_RETURN_ERROR_CODE_3008,
    FORCE_RETURN_ERROR_CODE_3009,
    FORCE_RETURN_ERROR_CODE_3010,

    FIRMWARE_ERROR_CODE_1400,
} from 'constants/scooter';

const trunkState = {
    [TRUNK_CLOSED]: 'Closed',
    [TRUNK_OPEN]: 'Open',
    [TRUNK_LOCKED]: 'Locked',
};
const scooterState = {
    [SCOOTER_STATE_UNLOCK]: 'Unlock',
    [SCOOTER_STATE_ENGINE_ON]: 'Motor On',
    [SCOOTER_STATE_LOCK]: 'Lock',
    [SCOOTER_STATE_SHUTDOWN]: 'Shutdown',
};
const availabilityState = {
    [SCOOTER_OFFLINE]: 'Offline',
    [SCOOTER_ONLINE]: 'Online',
};
const tag = {
    [TICKET_NONE]: 'NONE',
    [TICKET_SWAP]: 'SWAP',
    [TICKET_SWAP_F]: 'SWAP-FLOATING',
    [TICKET_DISPATCH]: 'DISPATCH',
    [TICKET_MAINTAIN]: 'MAINTAIN',
};

const scooter = {
    'remote_control': 'Remote Control',
    'lock': 'Lock',
    'unlock': 'Unlock',
    'open_trunk': 'Open Trunk',
    'find': 'Find',
    'plate': 'Plate No.',
    'tag': 'Tag',
    'tag_description': 'Description',
    'tag_assignee': 'Assignee',
    'assign_to_me': 'Assign to me',
    'battery': 'SOC(%)',
    'battery-wo-percent': 'SOC',
    'tracker_soc': 'GTU(%)',
    'remaining_mileage': 'Remaining',
    'csq': 'CSQ',
    'satellites': 'Satellites',
    'model': 'Model',
    'activity': 'Activity',
    'rental_state': 'State',
    'last_rental_end_time': 'Last Rental Time',
    'idle': 'Idle(hrs)',
    'total_mileage': 'ODO',
    'scooter_state': 'Scooter State',
    'location': 'Location',
    'trunk_state': 'Trunk',
    'last_connection_date': 'LCT',
    'lct': 'LCT(hrs)',
    'gtu_imei': 'IMEI',
    'gtu_fw_version': 'GTU FW Version',
    'scooter_fw_version': 'Scooter FW Version',
    'last_udpated': 'Last Updated',
    'service': 'Service',
    'activation': {
        'on': 'Online',
        'off': 'Offline',
    },
    'trunk_state_map': trunkState,
    'scooter_state_map': scooterState,
    'availability_state_map': availabilityState,
    'tag_map': tag,
    'batch_update_service_state_error_message': 'Oops! Scooter(s) (%{plates}) Failed to Set Service State.',
    'force_return': 'Force Return',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3000}_error_message`]: 'The Rental is not found',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3001}_error_message`]: 'Price not in range',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3006}_error_message`]:
        'Battery state is invalid',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3007}_error_message`]: 'Trunk is not closed (trunk open or unlock)',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3008}_error_message`]: 'Engine is on',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3009}_error_message`]: 'Scooter is not lock',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3010}_error_message`]: 'GTU connection error',
    'force_return_error_message': 'Force Return failed (%{code})',
    'force_return_error_by_linepay_message': 'Force Return successful. Please pay on the APP.',
    'input_rental_fee': 'Input Rental Fee',
    'no_gtu_data': 'No GTU Data',
    'has_no_gtu_data': '%{plate} has no GTU Data',
    'gtu_replacement': 'GTU Replacement',
    'gtu_id': 'GTU_ID',
    'gtu_info': 'GTU Info',
    'gtu_cert': 'Certificate',
    'gtu_new_info': 'New GTU Info',
    'gtu_cert_subject': 'Subject',
    'gtu_cert_valid_from': 'Valid From',
    'gtu_cert_valid_to': 'Valid To',
    'gtu_cert_issuer': 'Issuer',
    'flash_fill': 'Flash Fill',
    'no_searched_imei': 'No IMEI found',
    'gtu_general_error_message': 'Oops! Something went wrong. Please try again.',
    [`gtu_${GTU_ERROR_CODE_15000}_error_message`]: 'Required parameter is not present',
    [`gtu_${GTU_ERROR_CODE_15001}_error_message`]: 'Invalid certificate subject',
    [`gtu_${GTU_ERROR_CODE_15002}_error_message`]: 'Invalid certificate valid from',
    [`gtu_${GTU_ERROR_CODE_15003}_error_message`]: 'Invalid certificate valid to',
    [`gtu_${GTU_ERROR_CODE_15004}_error_message`]: 'GTU already paired with another scooter',
    [`gtu_${GTU_ERROR_CODE_15005}_error_message`]: 'Certificate subject already used by another GTU',
    [`gtu_${GTU_ERROR_CODE_15006}_error_message`]: 'Disconnect original GTU failed',
    [`gtu_${GTU_ERROR_CODE_15007}_error_message`]: 'Scooter active status cannot be online',
    'city': 'City',
    [`firmware_${FIRMWARE_ERROR_CODE_1400}_error_message`]: 'Can\'t connect to GTU, please try again later',
    'unassign_user': 'Unassigned',
    'number_constraint_of_scooter': 'Choose no more than 200 scooters.',
    'related_tickets': 'My Ticket Link',
    'corporate': 'Corporate',
    'firmware_check': 'Firmware Check',
    'firmware_info': 'Firmware Info',
    'firmware_version': 'Scooter Version',
    'gtu_version': 'GTU Version',
    'lack_feautres': 'Lack features',
    'parking_check': 'Parking Check',
    'search_time': 'Search Time',
    'address': 'Address',
    'pause_description': 'Are you sure to unlock the scooter?',
    'pause_fee_warning': 'It will switch to riding fee automatically after unlocking.'
};

export default scooter;
