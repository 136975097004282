import { createFleetApiInstance, createFleetApiInstanceWithToken, createOAuthInstance } from '../util';
const account = {
    fetchTokenByCode: code => {
        return createOAuthInstance().post('/', {
            code,
        });
    },
    fetchNewToken: () => {
        return createOAuthInstance().post('/', {});
    },
    fetchPermission: accessToken => {
        return createFleetApiInstance({
            'Authorization': `Bearer ${ accessToken }`,
        }).get('/fleet_users');
    },
    fetchOperationUsers: (params = {}) => {
        return createFleetApiInstanceWithToken().get('/fleet_users/op_users', { params });
    },
    deleteCookie: () => {
        return createFleetApiInstance().delete('fleet_users/frf_cookie');
    }
};

export default account;
