import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { TV_MONITOR_SCALE } from 'constants/tvMonitor';
import AnimatedText from 'components/AnimatedText/AnimatedText';

import './time-counter.scss';

const TIME_UNIT_MINS = 60 * 1000;

class TimeCounter extends React.Component {
    static propTypes = {
        refreshTime: PropTypes.number,
        className: PropTypes.string,
        refreshUnit: PropTypes.number,
        unitString: PropTypes.string,
    };

    static defaultProps = {
        // set in min. `0` means never refresh
        refreshTime: 0,
        className: '',
        refreshUnit: TIME_UNIT_MINS,
        unitString: 'second',
    };

    constructor(props) {
        super(props);
        this.state = {
            date: undefined,
            time: undefined,
            period: undefined,
        };
        this.startTimer = undefined;
    }
    componentDidMount() {
        this.updateTime();
    }

    componentWillUnmount() {
        this.startTimer && clearTimeout(this.startTimer);
    }

    getMSTillNextRefresh = now => {
        const { refreshTime, refreshUnit, unitString } = this.props;
        const start = now.valueOf();
        const next = moment().add(refreshTime * refreshUnit, 'ms').set(unitString, 0).valueOf();
        return moment(next).diff(start);

    }

    setRefreshTimer= (now = moment()) => {
        const { refreshTime } = this.props;

        if (refreshTime > 0) {
            const timeCorrector = this.getMSTillNextRefresh(now);

            this.startTimer && clearTimeout(this.startTimer);
            return setTimeout(() => {
                this.updateTime();
            }, timeCorrector);
        }

        return;
    }

    updateTime = () => {
        const { date, time, period, now } = this.getTime();
        this.setState({
            date,
            time,
            period,
        }, () => {
            this.startTimer = this.setRefreshTimer(now);
        });
    }

    getTime = () => {
        const fullTimeString = moment();
        const date = fullTimeString.format('MMMM DD, YYYY');
        const time = fullTimeString.format('hh:mm');
        const period = fullTimeString.format('A');
        return {
            date,
            time,
            period,
            now: fullTimeString,
        };
    }

    render() {
        const { className } = this.props;
        const { date, time, period } = this.state;
        const style = classNames({
            'time-counter': true,
            [className]: true,
        });

        return (
            <div className={ style }>
                <span className="clock">
                    <AnimatedText
                        value={ time }
                        size={ 84 / TV_MONITOR_SCALE }
                        className="char"
                    />
                    <span className="period">{ period }</span>
                </span>
                <span className="date">{ date }</span>
            </div>
        );
    }
}

export default TimeCounter;
