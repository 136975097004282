/*
 * watermark on customer documents related helpers
 */
import watermark from 'watermarkjs';
import {
    ID_SCAN_IMAGE,
    DRIVER_SCAN_IMAGE_FRONT,
    DRIVER_SCAN_IMAGE_BACK,
} from 'constants/customer';
import waterMarkImgForId from 'assets/watermark-idcard.svg';
import waterMarkImgDriverFront from 'assets/watermark-driver-front.svg';
import waterMarkImgDriverBack from 'assets/watermark-driver-back.svg';

import violationWaterMarkImgForId from 'assets/violation-watermark-idcard.svg';
import violationWaterMarkImgDriverFront from 'assets/violation-watermark-driver-front.svg';
import violationWaterMarkImgDriverBack from 'assets/violation-watermark-driver-back.svg';

export const getViolationWatermarkImg = type => {
    let img;
    switch (type) {
    case DRIVER_SCAN_IMAGE_BACK:
        img = violationWaterMarkImgDriverBack;
        break;
    case DRIVER_SCAN_IMAGE_FRONT:
        img = violationWaterMarkImgDriverFront;
        break;
    case ID_SCAN_IMAGE:
        img = violationWaterMarkImgForId;
        break;
    default:
    }
    return img;
};

export const getWatermarkImg = type => {
    let img;
    switch (type) {
    case DRIVER_SCAN_IMAGE_BACK:
        img = waterMarkImgDriverBack;
        break;
    case DRIVER_SCAN_IMAGE_FRONT:
        img = waterMarkImgDriverFront;
        break;
    case ID_SCAN_IMAGE:
        img = waterMarkImgForId;
        break;
    default:
    }
    return img;
};

const getWaterMarkScale = (targetCanvas, waterMarkCanvas) => {
    const ratioHeight = targetCanvas.height / waterMarkCanvas.height;
    const ratioWidth = targetCanvas.width / waterMarkCanvas.width;
    const scale = ratioWidth < ratioHeight ? ratioWidth : ratioHeight;

    return scale;

};

const getWaterMarkPos = (type, scale, targetCanvas, waterMarkCanvas) => {
    let pos = {};
    switch (type) {
    case DRIVER_SCAN_IMAGE_BACK:
        pos = {
            x: (targetCanvas.width - waterMarkCanvas.width * scale) / 2,
            y: (targetCanvas.height - waterMarkCanvas.height * scale) / 2,
        };
        break;
    case DRIVER_SCAN_IMAGE_FRONT:
        pos = {
            x: 10,
            y: (targetCanvas.height - waterMarkCanvas.height * scale) / 2,
        };
        break;
    case ID_SCAN_IMAGE:
        pos = {
            x: 10,
            y: 10,
        };
        break;
    default:
    }
    return pos;
};

const getImgWithWatermark = (type, img, waterMarkImg) => {
    return watermark([img, waterMarkImg], { type: 'image/jpeg' })
        .dataUrl((imgCanvas, watermarkCanvas) => {
            let context = imgCanvas.getContext('2d');
            const scale = getWaterMarkScale(imgCanvas, watermarkCanvas);
            const pos = getWaterMarkPos(type, scale, imgCanvas, watermarkCanvas);
            context.globalAlpha = 0.4;
            context.save();
            context.drawImage(
                watermarkCanvas,
                pos.x,
                pos.y,
                watermarkCanvas.width * scale,
                watermarkCanvas.height * scale);
            context.restore();
            return imgCanvas;
        })
        .then((result) => {
            watermark.destroy();
            return result;
        });

};
export default getImgWithWatermark;
