import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FlexGroup from 'components/FlexGroup';
import './dash-info.scss';

const PERCENTAGE_CHAR = '%';

const DashInfo = ({ title, value, hasPercentage, showWarning, children }) => {
    const cls = classNames({
        'warning': showWarning,
        'dash-value': true,
    });
    return (
        <FlexGroup spaceBetween alignCenter className="dash-info">
            <span className="dash-title">{ title }</span>
            <span className={ cls }>
                { children || value || 0 }
                { hasPercentage ? PERCENTAGE_CHAR : '' }
            </span>

        </FlexGroup>
    );
};

DashInfo.propTypes = {
    title: PropTypes.string,
    value: PropTypes.number,
    hasPercentage: PropTypes.bool,
    showWarning: PropTypes.bool,
};

DashInfo.defaultProps = {
    title: '',
    value: 0,
    hasPercentage: false,
    showWarning: false,
};


export default DashInfo;
