import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, CardBody, Card, Label } from 'reactstrap';
import AuthFeature from 'components/AuthFeature';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Form/Button';
import {
    REFUND_BY_MONEY,
    REFUND_BY_COUPON,
} from 'constants/rental';
import {
    AUTH_REFUND_RENTAL,
    AUTH_UPDATE_RENTAL_SUBTOTAL,
} from 'constants/permission';
import { Translate } from 'react-redux-i18n';


class FunctionButtons extends React.Component {
    static propTypes = {
        disabledRefund: PropTypes.bool.isRequired,
        disabledChangeAmount: PropTypes.bool.isRequired,
        onSelectRefundType: PropTypes.func.isRequired,
        onOpenChangeAmountModal: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            openRefundOptions: false,
        };
    }

    handleOpenChangeAmountModal = () => {
        const { onOpenChangeAmountModal } = this.props;

        onOpenChangeAmountModal();
    }

    handleRefundTypeSelect = type => () => {
        const { onSelectRefundType } = this.props;

        onSelectRefundType(type);

        this.setState({
            openRefundOptions: false,
        });
    }

    render() {
        const { openRefundOptions } = this.state;
        const { disabledRefund, disabledChangeAmount } = this.props;
        const CollapsiablePanel = ({ open }) => {
            return (
                <Collapse
                    className="collasible-panel"
                    isOpen={ open }
                >
                    <Card>
                        <CardBody>
                            {
                                [
                                    REFUND_BY_MONEY,
                                    REFUND_BY_COUPON,
                                ].map(type => (
                                    <Label
                                        className="filter-option"
                                        key={ type }
                                        onClick={ this.handleRefundTypeSelect(type) }
                                    >
                                        <Translate value={ `rental.refund_type_map.${ type }` } />
                                    </Label>
                                ))
                            }
                        </CardBody>
                    </Card>
                </Collapse>
            );
        };

        return (
            <ButtonGroup>
                <AuthFeature requiredList={ [AUTH_UPDATE_RENTAL_SUBTOTAL] }>
                    <Button
                        outline
                        className="btn-change-amount"
                        type="button"
                        onClick={ this.handleOpenChangeAmountModal }
                        disabled={ disabledChangeAmount }
                    >
                        <Translate value="rental.change_amount" />
                    </Button>
                </AuthFeature>
                <AuthFeature requiredList={ [AUTH_REFUND_RENTAL] }>
                    <Button
                        outline
                        className="btn-refund"
                        type="button"
                        onClick={ e => this.setState({ openRefundOptions: !openRefundOptions }) }
                        disabled={ disabledRefund }
                    >
                        <Translate value="rental.refund" />
                    </Button>
                    <CollapsiablePanel open={ openRefundOptions } />
                </AuthFeature>
            </ButtonGroup>
        );
    }
}

export default FunctionButtons;
