import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CONTROL_UNIT_BCU, ECU_TYPE_DEFAULT } from 'constants/scooter';

import './battery-capacity.scss';

const BatteryCapacity = ({
    capacity,
    hasPercentage,
    className,
    ecuType,
    isGTU,
}) => {
    const noGTUPowerBCU = isGTU && ecuType === CONTROL_UNIT_BCU && capacity === 0;
    const styles = classNames({
        'battery-capacity': true,
        'has-percentage': hasPercentage && !noGTUPowerBCU,
        [className]: true,
    });

    return (
        <>
            {
                (capacity !== null && !isNaN(capacity)) ? (
                    <div className={ styles }>
                        {
                            noGTUPowerBCU ? 'N/A' : capacity
                        }
                    </div>
                ) : null
            }
        </>
    );
};
BatteryCapacity.propTypes = {
    capacity: PropTypes.number,
    hasPercentage: PropTypes.bool,
    className: PropTypes.string,
    ecuType: PropTypes.number,
    isGTU: PropTypes.bool,
};
BatteryCapacity.defaultProps = {
    capacity: undefined,
    className: '',
    hasPercentage: true,
    ecuType:ECU_TYPE_DEFAULT,
    isGTU: false,
};

export default BatteryCapacity;
