import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import {
    VERIFICATION_NOT_YET,
    VERIFICATION_SUCCESS,
    VERIFICATION_DENIED_FRAUD,
    VERIFICATION_DENIED_UNSUPPORTED_ID_TYPE,
    VERIFICATION_DENIED_UNSUPPORTED_ID_COUNTRY,
    VERIFICATION_ERRO_NOT_READABLE_ID,
    VERIFICATION_VALIDITY_FALSE,
    VERIFICATION_VALIDITY_TRUE,
    VERIFICATION_RESULT_NOT_MATCH_OTHER_TYPE,
    VERIFICATION_INVALID_DOC,
    VERIFICATION_DUPLICATED_ID,
    VERIFICATION_VERIFYING,
    VERIFICATION_ID_TYPE_MISMATCH,
} from 'constants/customer';
import { Translate } from 'react-redux-i18n';
import { FORBIDDEN_DRIVER_LICENSE_ISSUE } from "constants/customer";

const Component = ({ status }) => {
    let el;

    switch (status) {
    case VERIFICATION_NOT_YET:
    case VERIFICATION_VERIFYING:
        el = null;
        break;
    case VERIFICATION_SUCCESS:
        el = (
            <Badge color="success">
                <Translate value={ `customer.verification_${ status }` } />
            </Badge>
        );
        break;

    case VERIFICATION_RESULT_NOT_MATCH_OTHER_TYPE:
    case VERIFICATION_DUPLICATED_ID:
    case VERIFICATION_DENIED_FRAUD:
    case VERIFICATION_DENIED_UNSUPPORTED_ID_TYPE:
    case VERIFICATION_DENIED_UNSUPPORTED_ID_COUNTRY:
    case VERIFICATION_ERRO_NOT_READABLE_ID:
    case VERIFICATION_VALIDITY_FALSE:
    case VERIFICATION_VALIDITY_TRUE:
    case VERIFICATION_INVALID_DOC:
    case VERIFICATION_ID_TYPE_MISMATCH:
    case FORBIDDEN_DRIVER_LICENSE_ISSUE:
        el = (
            <Badge color="danger">
                <Translate value="customer.verification_failed" />
            </Badge>
        );
        break;
    default:
        el = (
            <Badge color="secondary">
                <Translate value="unknown" />
            </Badge>
        );
        break;
    }

    return el;
};

Component.propTypes = {
    status: PropTypes.number.isRequired,
};

export default Component;
