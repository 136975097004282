import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import buildActualPath from 'helpers/build-actual-path';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import AuthFeature from 'components/AuthFeature';
import AuthView from 'components/AuthView';
import FlexGroup from 'components/FlexGroup';
import { Refresh } from 'components/Pagination';
import BatchEdit from 'view/Ticket/BatchEdit';
import CustomChoiceGroup, { SEARCH_BY_PLATE } from 'view/Ticket/Filter/ChoiceGroup';
import CustomMainTypeFilter from 'components/Ticket/MainType';
import CustomSubTypeFilter from 'components/Ticket/SubType';
import CustomStatusFilter from 'view/Ticket/Filter/Status';
import CustomPriorityFilter from 'view/Ticket/Filter/Priority';
import CustomAssigneeFilter from 'view/Ticket/Filter/Assignee';
import CustomTicketFlowFilter from 'view/Ticket/Filter/TicketFlow';
import MapView from './MapView';
import AssignmentList from './AssignmentList';
import {
    updateDocumentTitle,
    initAssignments,
    fetchAssignment,
    selectScooter,
    toggleErrorDialog
} from 'actions';
import {
    MAIN_TYPE_SWAP,
    MAIN_TYPE_DISPATCH,
    MAIN_TYPE_MAINTENANCE,
    MAIN_TYPE_OTHERS
} from 'constants/ticket';
import {
    AUTH_BATCH_EDIT_TICKET,
    AUTH_EDIT_TICKET,
} from 'constants/permission';
import permissionHandler from 'helpers/permission-handler';
import {
    TICKET_DETAIL,
} from 'constants/routes';
import VipScooterFilter from 'components/Filter/VipScooterFilter';
import './ticket-map.scss';
import { filterCorp } from 'helpers/corp-warning';

const FILTER_KEYWORD = 'keyword';
const FILTER_TICKET_MAIN_TYPE = 'tracker_id';
const FILTER_TICKET_SUB_TYPE = 'tracker_subtype_id';
const FILTER_STATUS = 'status_id';
const FILTER_PRIORITY = 'severity_id';
const FILTER_ASSIGNEE = 'assigned_to_id';
const FILTER_TICKET_FLOW = 'franchisee_author_assignee';
const FILTER_VIP_SCOOTER = 'service_id';

class TicketAssignment extends Component {

    static propTypes = {
        assignments: ImmutablePropTypes.map.isRequired,
        selectedIdList: ImmutablePropTypes.list.isRequired,
        defaultTicketFlowList: ImmutablePropTypes.listOf(PropTypes.shape({
            text: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        })).isRequired
    }

    constructor(props) {
        super(props);

        this.searchType = SEARCH_BY_PLATE;
        this.queryPayload = {
            [FILTER_TICKET_MAIN_TYPE]: undefined,
            [FILTER_TICKET_SUB_TYPE]: undefined,
            [FILTER_STATUS]: undefined,
            [FILTER_PRIORITY]: undefined,
            [FILTER_ASSIGNEE]: undefined,
            [FILTER_TICKET_FLOW]: undefined,
            [FILTER_VIP_SCOOTER]: undefined,
        };
        this.queryString = {};

        this.state = {
            showBatchEditPanel: false,
            resetBounds: 0,
            selectedAssignees: this.initSelectedAssignees,
            selectedTicketIdList: [],
            corpName: []
        };
    }

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { pathname } = location;

        dispatch(updateDocumentTitle(`routes.${ pathname }`));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(selectScooter([]));
    }

    get initSelectedAssignees() {
        return Immutable.fromJS({
            [MAIN_TYPE_SWAP]: {},
            [MAIN_TYPE_DISPATCH]: {},
            [MAIN_TYPE_MAINTENANCE]: {},
            [MAIN_TYPE_OTHERS]: {}
        });
    }

    changeSearchType = e => {
        const value = e.currentTarget.value;
        this.searchType = value;
    }

    setKeyword = value => {
        this.keyword = value;
    }

    handleQueryPayloadChanged = name => value => {
        switch (name) {
        case FILTER_TICKET_MAIN_TYPE:
        case FILTER_TICKET_SUB_TYPE:
        case FILTER_STATUS:
        case FILTER_PRIORITY:
        case FILTER_ASSIGNEE:
        case FILTER_VIP_SCOOTER:
        case FILTER_TICKET_FLOW:
            this.queryPayload[name] = value.inputSelect;
            break;
        case FILTER_KEYWORD:
            this.setKeyword(value);
            this.queryPayload = {};
            this.queryString = {
                [this.searchType]: value || undefined,
            };
            break;
        default:
            console.warn('Not a defined parameter name', name, value);
            break;
        }

        this.fetchData(true);
    }

    fetchData = async (resetBounds = false) => {
        const { dispatch } = this.props;

        const {
            ticket_id,
            franchisee_author_assignee,
            ...rest
        } = {
            ...this.queryString,
            ...this.queryPayload,
        };
        const updatedTicketId = ticket_id && Number(ticket_id);

        let ticketFlowFilter = franchisee_author_assignee;

        // default ticket flow filter
        if (!franchisee_author_assignee || franchisee_author_assignee.length === 0) {
            const { defaultTicketFlowList } = this.props;
            ticketFlowFilter = defaultTicketFlowList.toJS().map(ticketFlow => ticketFlow.value);
        }

        const payload = {
            ticket_id: Number.isNaN(updatedTicketId) ? 0 : updatedTicketId,
            franchisee_author_assignee: ticketFlowFilter,
            ...rest
        };

        dispatch(initAssignments());
        await dispatch(fetchAssignment(payload));

        if (resetBounds) {
            this.setState({
                resetBounds: Date.now(),
            });
            this.clearAllSelected();
        }

    }

    handleSubmit = () => {
        this.fetchData().then(() => {
            this.toggleBatchEditor();
            this.clearAllSelected();
        });
    }

    toggleBatchEditor = (constraint = false) => {
        const { selectedTicketIdList } = this.state;
        const { dispatch } = this.props;
        if (constraint && selectedTicketIdList.length > 200) {
            dispatch(toggleErrorDialog(I18n.t('ticket.number_constraint_of_ticket')));
            return;
        }

        const { showBatchEditPanel } = this.state;
        this.setState({
            showBatchEditPanel: !showBatchEditPanel,
        });
    }

    handleAssigneeSelected = ({ tracker_id, assigned_to_id }) => {
        const path = [tracker_id, assigned_to_id];
        const { assignments } = this.props;
        const { selectedAssignees } = this.state;
        const selected = selectedAssignees.getIn(path);
        const list = assignments.get('data_list').toJS();
        const newSelectedIdList = list.find(
            assignment => assignment.tracker_id === tracker_id && assignment.assigned_to_id === assigned_to_id
        ).tickets.map(ticket => ticket.scooter_id);
        const uniqueSelectedIdList = [...new Set(newSelectedIdList)];
        const newSelectedTicketIdList = list.find(
            assignment => assignment.tracker_id === tracker_id && assignment.assigned_to_id === assigned_to_id
        ).tickets.map(ticket => ticket.id);

        this.selectScooters(uniqueSelectedIdList, !selected);
        this.selectTickets(newSelectedTicketIdList, !selected);

        this.setState({
            selectedAssignees: selectedAssignees.setIn(path, !selected)
        });
    }

    handleScooterMarkerSelected = (newSelectedIdList, { selected }) => {
        this.selectScooters(newSelectedIdList, selected);
        const { assignments } = this.props;
        const list = assignments.get('data_list').toJS();
        const newSelectedTicketIdList = list.reduce((ticketIdList, assignment) => ([
            ...ticketIdList,
            ...assignment.tickets
                .filter(ticket => newSelectedIdList.includes(ticket.scooter_id))
                .map(ticket => ticket.id)
        ]), []);

        this.selectTickets(newSelectedTicketIdList, selected);
    }

    getSelectedTicketIdList = assignments => {
        const { selectedIdList } = this.props;
        return assignments
            .filter(({ scooter_id }) => selectedIdList.includes(scooter_id))
            .map(({ id }) => id);
    }

    clearAllSelected = () => {
        const { dispatch } = this.props;
        dispatch(selectScooter([]));
        this.setState({
            selectedTicketIdList: [],
            selectedAssignees: this.initSelectedAssignees
        });
    }

    selectScooters(newSelectedScooterIdList, selected) {
        const { dispatch, selectedIdList, assignments } = this.props;
        const scooterIdSet = new Set(selectedIdList.toJS());
        newSelectedScooterIdList.forEach(id => {
            if (selected) {
                scooterIdSet.add(id);
            }
            else {
                scooterIdSet.delete(id);
            }
        });
        const data_list = assignments.get('data_list').toJS();
        const reduceData = data_list.reduce((totalTicketList, { tickets }) => ([
            ...totalTicketList,
            ...tickets.map(({ id, scooter_id, ...rest }) => ({ id: scooter_id, scooter_id, ...rest }))
        ]), []);
        const corporates = filterCorp({
            dataList: reduceData,
            updatedDataList: Array.from(scooterIdSet),
            key: 'scooter_id'
        });
        this.setState({ corpName: [...new Set(corporates)] });

        dispatch(selectScooter(Array.from(scooterIdSet)));
    }

    selectTickets(newSelectedTicketIdList, selected) {
        const { selectedTicketIdList } = this.state;
        const ticketIdSet = new Set(selectedTicketIdList);
        newSelectedTicketIdList.forEach(ticketId => {
            if (selected) {
                ticketIdSet.add(ticketId);
            }
            else  {
                ticketIdSet.delete(ticketId);
            }
        });

        this.setState({ selectedTicketIdList: Array.from(ticketIdSet) });
    }

    sortAssignments(list) {
        function campareAssigneeName(a, b) {
            const assigneeNameA = a.assigned_to_name.toUpperCase();
            const assigneeNameB = b.assigned_to_name.toUpperCase();
            if (assigneeNameA < assigneeNameB) {
                return -1;
            }
            if (assigneeNameA > assigneeNameB) {
                return 1;
            }

            return 0;
        }

        const sp = list.filter(assignment => assignment.tracker_id === 1).sort(campareAssigneeName);
        const oth = list.filter(assignment => assignment.tracker_id === 4).sort(campareAssigneeName);
        const mt = list.filter(assignment => assignment.tracker_id === 3).sort(campareAssigneeName);
        const dp = list.filter(assignment => assignment.tracker_id === 2).sort(campareAssigneeName);

        return [...sp, ...oth, ...mt, ...dp];
    }

    render() {
        const { selectedAssignees, selectedTicketIdList, showBatchEditPanel, resetBounds, corpName } = this.state;
        const { assignments, selectedIdList } = this.props;
        const list = assignments.get('data_list').toJS();
        const __responseTime = assignments.get('__responseTime');
        const sortedAssignments = this.sortAssignments(list);

        return (
            <AuthView className="ticket-assignment-map">
                <FlexGroup start className="filters">
                    <CustomChoiceGroup
                        defaultChecked={ this.searchType }
                        onChangeType={ this.changeSearchType }
                        onSubmit={ this.handleQueryPayloadChanged(FILTER_KEYWORD) }
                        onChange={ this.setKeyword }
                        value={ this.keyword }
                    >
                        <CustomMainTypeFilter
                            selected={ this.queryPayload[FILTER_TICKET_MAIN_TYPE] }
                            onChange={ this.handleQueryPayloadChanged(FILTER_TICKET_MAIN_TYPE) }
                        />
                        <CustomSubTypeFilter
                            selected={ this.queryPayload[FILTER_TICKET_SUB_TYPE] }
                            onChange={ this.handleQueryPayloadChanged(FILTER_TICKET_SUB_TYPE) }
                        />
                        <CustomStatusFilter
                            selected={ this.queryPayload[FILTER_STATUS] }
                            onChange={ this.handleQueryPayloadChanged(FILTER_STATUS) }
                            withoutClosed
                        />
                        <CustomPriorityFilter
                            selected={ this.queryPayload[FILTER_PRIORITY] }
                            onChange={ this.handleQueryPayloadChanged(FILTER_PRIORITY) }
                        />
                        <CustomAssigneeFilter
                            selected={ this.queryPayload[FILTER_ASSIGNEE] }
                            onChange={ this.handleQueryPayloadChanged(FILTER_ASSIGNEE) }
                        />
                        <CustomTicketFlowFilter
                            selected={ this.queryPayload[FILTER_TICKET_FLOW] }
                            onChange={ this.handleQueryPayloadChanged(FILTER_TICKET_FLOW) }
                        />
                        <VipScooterFilter
                            defaultSelected={ this.queryPayload[FILTER_VIP_SCOOTER] }
                            onChange={ this.handleQueryPayloadChanged(FILTER_VIP_SCOOTER) }
                        />
                    </CustomChoiceGroup>
                </FlexGroup>
                <FlexGroup>
                    <FlexGroup start>
                        <FlexGroup start gap>
                            <AuthFeature requiredList={ [AUTH_BATCH_EDIT_TICKET] }>
                                <Button
                                    outline
                                    color="primary"
                                    className="btn-batch-edit"
                                    onClick={ () => this.toggleBatchEditor(true) }
                                    disabled={ selectedIdList.size === 0 }
                                >
                                    <Translate value="ticket.batch_edit" />
                                </Button>
                            </AuthFeature>
                            <Button
                                color="secondary"
                                onClick={ this.clearAllSelected }
                                disabled={ selectedIdList.size === 0 }
                            >
                                <Translate value="clear_all" />
                            </Button>
                            <FlexGroup start gap className="selection">
                                <Translate gap tag="p" value="total_selection" className="title" />
                                <FlexGroup className="selected-amount">
                                    {
                                        selectedTicketIdList.length === 1 &&
                                        permissionHandler({ requiredList: [AUTH_EDIT_TICKET] }) ?
                                            (
                                                <NavLink to={ buildActualPath(TICKET_DETAIL,
                                                    { ticketId: selectedTicketIdList[0] }) }
                                                >
                                                    <span className="amount">{ selectedTicketIdList.length }</span>
                                                    <Translate value="ticket.tickets" />
                                                </NavLink>
                                            ) : (
                                                <React.Fragment>
                                                    <span className="amount">{ selectedTicketIdList.length }</span>
                                                    <Translate value="ticket.tickets" />
                                                </React.Fragment>
                                            )
                                    }
                                </FlexGroup>
                                <FlexGroup gap tag="p" className="selected-amount">
                                    <span className="amount">{ selectedIdList.size }</span>
                                    <Translate value="ticket.scooters" />
                                </FlexGroup>
                            </FlexGroup>
                        </FlexGroup>
                    </FlexGroup>
                    <FlexGroup end>
                        <Refresh
                            time={ __responseTime }
                            onClick={ this.fetchData }
                            disabled={ selectedTicketIdList.length > 0 }
                        />
                    </FlexGroup>
                </FlexGroup>
                <FlexGroup className="assignment-container" alignStretch>
                    <AssignmentList
                        onAssigneeSelect={ this.handleAssigneeSelected }
                        selectedAssignees={ selectedAssignees }
                        list={ sortedAssignments }
                    />
                    <MapView
                        fetchData={ this.fetchData }
                        mergeSelectedStatus={ this.handleMergeSelected }
                        onScooterSelected={ this.handleScooterMarkerSelected }
                        resetBounds={ resetBounds }
                    />
                </FlexGroup>
                { showBatchEditPanel ? (
                    <BatchEdit
                        ticketIdList={ selectedTicketIdList }
                        onCancel={ this.toggleBatchEditor }
                        onSubmit={ this.handleSubmit }
                        corporate={ corpName }
                    />
                ) : null }
            </AuthView>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    assignments: state.ticket.get('assignments'),
    selectedIdList: state.map.get('selectedIdList'),
    defaultTicketFlowList: state.ticket.get('defaultTicketFlowList'),
}))(TicketAssignment);
