import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import serialize from 'form-serialize';
import { Translate, I18n } from 'react-redux-i18n';
import Form from 'components/Form';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Form/Button';
import FactorAuthIdleTimer from 'components/FactorAuthIdleTimer';
import {
    fetchCustomerProfile,
    toggleErrorDialog,
    putFactorAuth,
} from 'actions';
import OneFactor from './OneFactor';
import TwoFactor from './TwoFactor';

import './factor-auth.scss';

export const FACTOR_PHONE = 'phone';
export const FACTOR_EMAIL = 'email';
export const FACTOR_ID = 'id_number';

export const VERIFY_OPTIONS = [
    {
        name: 'factor_auth.id_no',
        value: FACTOR_ID,
    },
    {
        name: 'customer.mobile',
        value: FACTOR_PHONE,
    },
    {
        name: 'customer.email',
        value: FACTOR_EMAIL,
    }
];

export const FACTOR_CONFIG = {
    [FACTOR_PHONE]: {
        maxLength: '13',
    },
    [FACTOR_EMAIL]: {

    },
    [FACTOR_ID]: {
        maxLength: '4',
    }
};

class FactorAuth extends Component {
    static propTypes = {
        customerId: PropTypes.string.isRequired,
        show: PropTypes.bool.isRequired,
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        onIdle: PropTypes.func,
        idleDisabled: PropTypes.bool,
    };
    static defaultProps = {
        onClose: () => {},
        onSubmit: () => {},
        onIdle: () => {},
        idleDisabled: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            modalHide: false,
        };
        this.elForm = React.createRef();
    }

    handleClose = e => {
        const { onClose } = this.props;
        this.setState({
            ...this.state,
            modalHide: false,
        });
        onClose();
    };

    getFirstFactor = () => {
        const { factorItems } = this.props;
        const factors = factorItems.toJS();
        let firstFactor = undefined;
        if (factors[FACTOR_PHONE]) {
            firstFactor = FACTOR_PHONE;
        }
        else if (factors[FACTOR_EMAIL]) {
            firstFactor = FACTOR_EMAIL;
        }
        else if (factors[FACTOR_ID]) {
            firstFactor = FACTOR_ID;
        }
        return firstFactor;

    };

    handleFactorChange = (state, field) => e => {
        const { value } = e.currentTarget;
        let currentState;
        switch(field) {
            case FACTOR_PHONE:
                currentState = {
                    ...state,
                    phone: value,
                };
                break;
            case FACTOR_EMAIL:
                currentState = {
                    ...state,
                    email: value,
                };
                break;
            case FACTOR_ID:
                currentState = {
                    ...state,
                    idNumber: value,
                };
                break;
            default:
        }
        return currentState;
    };

    handleVerify = e => {
        const isValid = this.elForm.current.reportValidity();
        if (isValid) {
            const formData = serialize(this.elForm.current, { hash: true });
            const firstFactor = this.getFirstFactor();
            const { factorItems, dispatch, customerId, onSubmit } = this.props;
            const factors = factorItems.toJS();
            const payload = {
                [firstFactor]: factors[firstFactor],
                ...formData,
            };
            dispatch(fetchCustomerProfile(customerId, payload)).then(({ error }) => {
                this.setState({
                    ...this.state,
                    modalHide: true,
                });
                if (error) {
                    const { response } = error || {};
                    const { status } = response || {};
                    switch (status) {
                    case 400:
                    case 404:
                        dispatch(toggleErrorDialog(
                            I18n.t('factor_auth.verification_failed'),
                            () => {
                                this.setState({
                                    ...this.state,
                                    modalHide: false,
                                });
                        }));
                        break;
                    default:
                        this.setState({
                            ...this.state,
                            modalHide: false,
                        }, () => {
                            this.handleClose();
                        });
                    }
                    onSubmit(true);
                }
                else {
                    dispatch(putFactorAuth(payload));
                    this.handleClose();
                    onSubmit(false);
                }

            });
        }

    };

    render() {
        const { show, onIdle, idleDisabled } = this.props;
        const { modalHide } = this.state;
        const firstFactor = this.getFirstFactor();
        const modalStyle = classNames({
            'factor-auth-modal': true,
            'hide': modalHide,
        });
        return (
            <>
                <FactorAuthIdleTimer onIdle={ onIdle } disabled={ idleDisabled } />
                {
                    show ? (
                        <Modal
                            className={ modalStyle }
                            title={ I18n.t('factor_auth.title') }
                            onClose={ this.handleClose }
                        >
                            <Form
                                inline
                                innerRef={ this.elForm }
                            >
                                {
                                    firstFactor ? (
                                        <OneFactor
                                            firstFactor={ firstFactor }
                                            onChange = { this.handleFactorChange }
                                        />
                                    ) : (
                                        <TwoFactor
                                            onChange = { this.handleFactorChange }
                                        />
                                    )
                                }
                                <ButtonGroup start>
                                    <Button
                                        color="primary"
                                        onClick={ this.handleVerify }
                                    >
                                        <Translate value="confirm" />
                                    </Button>
                                </ButtonGroup>
                            </Form>
                        </Modal>
                    ) : null
                }
            </>
        );
    };
}

export default connect(state => ({
    factorItems: state.factorAuth.get('items'),
}))(FactorAuth);
