import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import Form from 'components/Form';
import Input from 'components/Form/Input';
import {
    TYPE_RADIO,
} from 'components/Form/Input/input-type';
import {
    MAIN_TYPE_SWAP,
    MAIN_TYPE_GROUP,
} from 'constants/ticket';


class FirstStep extends React.Component {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        onSelected: PropTypes.func.isRequired,
        mainType: PropTypes.number,
    };
    static defaultProps = {
        mainType: MAIN_TYPE_SWAP,
    };

    constructor(props) {
        super(props);

        const { mainType } = props;

        this.state = {
            mainType,
        };
    }

    handleSelected = ([mainType]) => {
        const { onSelected } = this.props;

        this.setState({
            mainType,
        });

        onSelected(mainType);
    }

    render() {
        const { show } = this.props;
        const { mainType } = this.state;

        return (
            show ? (
                <Form className="main-type-section" tag="div">
                    <Input
                        type={ TYPE_RADIO }
                        caption={ I18n.t('ticket.main_type') }
                        value={
                            MAIN_TYPE_GROUP.map(mainType => ({
                                value: mainType,
                                name: I18n.t(`ticket.main_type_${ mainType }`),
                            }))
                        }
                        selected={ [mainType] }
                        onChange={ this.handleSelected }
                    />
                </Form>
            ) : null
        );
    }
}
export default FirstStep;
