import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoCard from 'components/InfoCard';
import DashInfo from 'components/DashInfo';
import Gauge from 'components/Chart/Gauge';
import { Translate, I18n } from 'react-redux-i18n';
import { showServiceLevelWarning, getServiceLevelGaugeLabel } from 'helpers/dashboard';
import {
    DASH_COLOR_YELLOW,
    DASH_COLOR_BLUE,
} from 'constants/dashboard';

import './overall-service-level.scss';

const OVERALL_SL_CHART_SIZE = 130;

const OverallSL = ({ title, className, data, listCaption }) => {

    const {
        overall,
        list,
    } = data;

    const CardStyle = classNames({
        'overall-SL': true,
        [className]: true,
    });

    const chartStyle = classNames({
        'title': true,
        'warning': showServiceLevelWarning(overall.service_level),
    });

    const getListCaption = item => {
        const caption = ( listCaption && typeof listCaption === 'function') ? listCaption(item) : listCaption;
        return I18n.t(caption);
    };

    const renderInfoList = item => {
        const {
            service_level,
            city_id,
        } = item;
        const caption = getListCaption(item) ;
        return (
            <DashInfo
                title={ caption }
                value={ service_level }
                hasPercentage
                showWarning={ showServiceLevelWarning(service_level) }
                key={ `${service_level}${city_id}` }
            />
        );
    };

    return (
        <InfoCard className={ CardStyle }>
            <div className="info">
                <h2 className="title">{ title }</h2>
                {
                    (list && list.length) && list.map(item => renderInfoList(item))
                }
            </div>
            <div className="chart">
                <div className="service-level">
                    <Gauge
                        width={ OVERALL_SL_CHART_SIZE }
                        height={ OVERALL_SL_CHART_SIZE }
                        gaugeValue={ overall.service_level || 0 }
                        renderLabel={ getServiceLevelGaugeLabel() }
                        color={ showServiceLevelWarning(overall.service_level) ? DASH_COLOR_YELLOW : DASH_COLOR_BLUE }
                    />
                    <Translate className={ chartStyle } value="dashboard.service_level" />
                </div>
                <div className="total-scooters">
                    <span className="total">{ overall.operating || '--' }</span>
                    <Translate className="title" value="dashboard.total_scooters" />
                </div>

            </div>


        </InfoCard>
    );
};

OverallSL.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    data: PropTypes.shape({}),
    listCaption: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
    ]),
};

OverallSL.defaultProps = {
    title: '',
    className: '',
    data: {},
    listCaption: '',
};

export default OverallSL;
