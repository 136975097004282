import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './button-group.scss';

export const DIR_LEFT = 'LEFT';
export const DIR_RIGHT = 'RIGHT';

const Component = ({ children, direction }) => {
    const cls = classNames({
        'button-group': true,
        'start': direction === DIR_LEFT,
        'end': direction === DIR_RIGHT,
    });

    return (
        <section className={ cls }>
            { children }
        </section>
    );
};
Component.propTypes = {
    direction: PropTypes.oneOf([DIR_LEFT, DIR_RIGHT]),
};
Component.defaultProps = {
    direction: DIR_RIGHT,
};

export default Component;
