export const UPLOAD_SCOOTER_DATA = 0;
export const UPDATE_SCOOTER_PLATE = 1;

// error code
export const HEADER_FORMAT_INVALID = 5001;
export const CONTENT_CANNOT_EMPTY = 5002;
export const FILE_INVALID = 5003;
export const DATA_DUPLICATE = 5004;
export const SCOOTER_MODEL_ID_NOT_FOUND = 5005;
export const SCOOTER_NOT_FOUND = 5006;
export const SCOOTER_NOT_DISABLED = 5007;
export const PLATE_NOT_EQUALS_VIN = 5008;