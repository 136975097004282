import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import Modal from 'components/Modal';
import ButtonGroup from 'components/ButtonGroup';
import { Button } from 'reactstrap';
import iconAlert from 'assets/icon-alert.svg';

import './warning-modal.scss';

class WarningModal extends Component {
    static propTypes = {
        show: PropTypes.bool,
        corporate: PropTypes.arrayOf(PropTypes.string),
        onClose: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        inProgress: PropTypes.bool,
    };
    static defaultProps = {
        show: false,
        corporate: [],
        inProgress: false,
    };
    render() {
        const { corporate, show, onClose, onSubmit, inProgress } = this.props;
        return (
            <>
                { show && (
                    <Modal
                        className="warning-modal"
                        title={ (
                            <div className="warning">
                                <img src={ iconAlert } alt="" />
                                <span>{ I18n.t('warning') }</span>
                            </div>
                        ) }
                        onClose={ onClose }
                    >
                        <p className="warning-message">
                            { I18n.t(
                                'corp_warning',
                                { companies: corporate.join(', ') }
                            ) }
                        </p>
                        <div className="section">
                            <ButtonGroup start>
                                <Button
                                    type="button"
                                    className="btn-cancel"
                                    outline
                                    onClick={ onClose }
                                >
                                    <Translate value="cancel" />
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={ onSubmit }
                                    disabled={ inProgress }
                                >
                                    <Translate value="continue" />
                                </Button>
                            </ButtonGroup>
                        </div>
                    </Modal>
                )
                }
            </>

        );
    }
}

export default WarningModal;
