import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuidv4 } from 'uuid';

const ModalWrapper = ({ children }) => {
    const rootEl = document.getElementById('root');
    const appEl = rootEl.querySelector('.app');
    const containerRef = useRef(document.createElement('div'));
    const name = uuidv4();

    containerRef.current.id = name;
    containerRef.current.className = "modal-portal-wrapper";

    useEffect(() => {
        appEl.appendChild(containerRef.current);
        return (() => {
            const el = document.getElementById(name);
            if (el) {
                appEl.removeChild(el);
            }
        });
    }, [appEl, name]);


    return ReactDOM.createPortal(children, containerRef.current);
};

export default ModalWrapper;
