export const CHART_CONFIG_DEFAULT = {
    displayModeBar: false,
    responsive: true,
};

export const PIE_CHART_THICK_DEFAULT = 20;

export const CHART_DURATION_DEFAULT = 1.2;

export const CHART_FONT_STYLE_MEDIUM = 'TTNormsPro-Medium, sans-serif, PingFangTC-Semibold, Microsoft JhengHei';
export const CHART_FONT_STYLE_BOLD = 'TTNormsPro-Bold, sans-serif, PingFangTC-Semibold, Microsoft JhengHei';

export const xssOptions = {
    whiteList: {
        b: ['style'],
        tspan: ['style'],
        span: ['style'],
    },
};
