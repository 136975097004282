import AuthRouteList, { sortedGroupList } from 'constants/routes-with-auth';

const getAvailableMenuItems = (authorities, list) => {
    return (list || AuthRouteList).filter(menuItem => {
        const { auth, isMenuItem } = menuItem;
        return authorities.map(({ authority }) => authority ).indexOf(auth) > -1 && isMenuItem;
    }).sort((a, b) => (a.order - b.order));
};

export const defaultOpenCategoryList = (authorities, list) => {
    const availableMenuItems = getAvailableMenuItems(authorities, list);
    let result = [];
    if (availableMenuItems.length <= 6) {
        result = [...new Set(availableMenuItems.map(({ category }) => category))];
    }
    return result;
};

export const getCategoryByPathName = (pathName) => {
    const target = AuthRouteList.find( item => {
        return (item.route === pathName);
    });

    return target?.category;
};

export const sortCategory = (list) => {
    return sortedGroupList.filter(item => list.indexOf(item) > -1);
};

const getHierarchicalMenu = (authorities, list) => {
    const availableMenuItems = getAvailableMenuItems(authorities, list);

    return availableMenuItems.reduce((menuMap, menuItem) => {
        const { route, category } = menuItem;
        const list = menuMap[category] || [];
        menuMap[category] = list.concat({
            route,
            category,
        });

        return menuMap;
    }, {});
};

export default getHierarchicalMenu;
