const Columns = [{
    name: 'expense_id',
    value: 'additional_fee.request_no',
    sortable: true,
    width: 80,
}, {
    name: 'customer_id',
    value: 'customer.customer_no',
    sortable: true,
    width: 80,
}, {
    name: 'rental_id',
    value: 'rental.rental_no',
    sortable: true,
    width: 80,
}, {
    name: 'claim_type',
    value: 'additional_fee.claim_type',
    sortable: true,
    width: 60,
}, {
    name: 'fee_type',
    value: 'additional_fee.fee_type',
    sortable: true,
    width: 60,
}, {
    name: 'subtype',
    value: 'additional_fee.subtype',
    sortable: true,
    width: 90,
}, {
    name: 'plate_no',
    value: 'scooter.plate',
    sortable: true,
    width: 60,
}, {
    name: 'ticket_no',
    value: 'additional_fee.ticket_no',
    sortable: true,
    width: 60,
}, {
    name: 'amount',
    value: 'rental.price',
    sortable: true,
    width: 60,
}, {
    name: 'create_time',
    value: 'customer.create_time',
    sortable: true,
    width: 100,
}, {
    name: 'status',
    value: 'additional_fee.status',
    sortable: true,
    width: 60,
}];

export default Columns;
