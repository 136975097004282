import React, { memo } from 'react';
import PropsTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import Cell from 'components/OrderableListView/Cell';
import OrderableListRow from 'components/OrderableListView/OrderableListRow';
import Datetime from 'components/Datetime';
import { MISSION_DETAIL } from 'constants/routes';
import { STATUS_LIVED } from 'constants/mission';
import buildActualPath from 'helpers/build-actual-path';

function MissionOrderField({ id, value, name, width }) {
    let element;
    switch (name) {
    case 'title':
        element = (
            <NavLink to={ buildActualPath(MISSION_DETAIL, { missionId: id }) }>{ value }</NavLink>
        );
        break;
    case 'status':
        const status = value;
        const className = status === STATUS_LIVED ? 'green' : '';
        element = <Translate className={ className } value={ `mission.status_${ status }` } />;
        break;
    case 'valid_from':
        case 'valid_to':
        case 'publish_time':
            element = <Datetime time={ value } />;
            break;
    default:
        element = value;
    }
    return (
        <Cell
            key={ `${ id }-${ name }` }
            style={ { width } }
        >
            { element }
        </Cell>
    );
}
MissionOrderField.PropsTypes = {
    id: PropsTypes.node.isRequired,
    value: PropsTypes.node.isRequired,
    name: PropsTypes.string.isRequired,
    width: PropsTypes.number.isRequired,
};

const MissionOrderRow = OrderableListRow(memo(MissionOrderField));
export default MissionOrderRow;
