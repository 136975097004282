import store from 'store';
import { fetchZones } from 'actions';
import { polygon } from 'polygon-tools';

const { dispatch } = store;

const fetchData = (params = {}) => {
    return async () => {
        return dispatch(fetchZones(params));
    };
};
const addServiceZoneLayer = ({ map, params = {}, selected = false, vipLayer }) => {
    fetchData(params)().then(
        ({ type, err, data = { data: [] } }) => {
            if ('ERROR' === type) {
                throw err;
            }
            map.data.forEach((feature) => {
                map.data.remove(feature);
            });

            const zoneFilter = (zone, zoneType = '') => {
                const { service_type, type } = zone;
                if (zoneType === 'vips') {
                    return service_type > 1 && type <= 1;
                }
                return service_type <= 1 && type <= 1;
            };

            let zones = selected && params.service_id.length === 0 ? [] :
                data.data.filter(zone =>
                    zoneFilter(zone))
                    .reduce((boundaries, zone) => [...boundaries, zone.boundary], []);
            let vips = selected && params.service_id.length === 0 ? [] :
                data.data.filter(zone => zoneFilter(zone, 'vips'))
                    .reduce((boundaries, zone) => [...boundaries, zone.boundary], []);
            if (!vipLayer) {
                zones = data.data.filter(zone => zone.type <= 1).reduce(
                    (boundaries, zone) => [...boundaries, zone.boundary],
                    []
                );
                vips = [];
            }
            const worldBoundary = [
                [180, -90],
                [0, -90],
                [-180, -90],
                [-180, 90],
                [0, 90],
                [180, 90],
                [180, -90]
            ];
            let features = [];
            if (zones.length !== 0) {
                features = [...features, {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: [worldBoundary, ...zones],
                    },
                    properties: {
                        name: 'zones',
                    }
                }, {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: zones,
                    },
                    properties: {
                        name: 'border',
                    }
                }];
            }
            if (vips.length !== 0) {
                features = [
                    ...features,
                    {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: vips,
                        },
                        properties: {
                            name: 'vips',
                        }
                    }, {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: [worldBoundary, ...vips],
                        },
                        properties: {
                            name: 'vips_outside',
                        }
                    },
                ];
                let union = polygon.union(...vips);

                // the first and last positions in a LinearRing of coordinates must be the same
                for (let i = 0; i < union.length; i += 1) {
                    union[i].push(union[i][0]);
                }
                if (union.length !== 0) {
                    features = [
                        ...features,
                        {
                            type: 'Feature',
                            geometry: {
                                type: 'Polygon',
                                coordinates: union,
                            },
                            properties: {
                                name: 'union',
                            }
                        },
                    ];
                }
            }
            map.data.addGeoJson({
                type: 'FeatureCollection',
                features
            });
            map.data.setStyle(feature => {
                const name = feature.getProperty('name');
                let layerStyle = {
                    strokeColor: 'transparent',
                    strokeWidth: 0,
                    fillColor: 'transparent',
                };
                switch (name) {
                case 'zones':
                    layerStyle = {
                        strokeColor: 'transparent',
                        strokeWidth: 0,
                        fillColor: '#6c6c6c',
                        fillOpacity: 0.5,
                    };
                    break;
                case 'vips':
                    layerStyle = {
                        strokeColor: '#173e6f',
                        strokeWidth: 3,
                        fillColor: '#173e6f',
                        fillOpacity: 0.2,
                    };
                    break;
                case 'vips_outside':
                    layerStyle = {
                        strokeColor: 'transparent',
                        strokeWidth: 0,
                        fillColor: 'transparent',
                    };
                    break;
                case 'border':
                    layerStyle = {
                        strokeColor: '#06f3f9',
                        strokeWidth: 3,
                        fillColor: 'transparent',
                    };
                    break;
                case 'union':
                    layerStyle =  {
                        strokeColor: '#173e6f',
                        strokeWidth: 1,
                        fillColor: '#173e6f',
                        fillOpacity: 0.5,
                    };
                    break;
                default:
                    break;
                }
                return layerStyle;
            });
        }
    ).catch(err => {
        // Do something?
    });
};

export default addServiceZoneLayer;
