import api from 'api';
import {
    FETCH_FLEET_ADMIN_USERS,
    FETCH_FRANCHISEE_LIST,
    FETCH_USER_ROLES,
    UPDATE_FLEET_ADMIN_USER,
    UPDATE_MANY_FLEET_ADMIN_USERS,
    NEW_FLEET_USER,
} from 'constants/action-type';
import { asyncInterface } from './util';

export const fetchFleetUsers = params => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchFleetUsers(params),
        type: FETCH_FLEET_ADMIN_USERS,
        dispatch,
    });
};

export const fetchFranchiseeList = () => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchFranchiseeList(),
        type: FETCH_FRANCHISEE_LIST,
        dispatch
    });
};

export const fetchUserRoles = (payload = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchUserRoles(payload),
        type: FETCH_USER_ROLES,
        dispatch
    });
};

export const updateFleetAdminUser = (userId, payload = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.updateFleetAdminUser(userId, payload),
        type: UPDATE_FLEET_ADMIN_USER,
        dispatch,
    });
};

export const updateManyFleetAdminUsers = (payload = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.updateFleetManyAdminUsers(payload),
        type: UPDATE_MANY_FLEET_ADMIN_USERS,
        dispatch,
    });
};

export const newFleetUser = (payload = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.newFleetUser(payload),
        type: NEW_FLEET_USER,
        dispatch,
    });
};
