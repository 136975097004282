import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import {
    OVERALL_UNVERIFIED,
    OVERALL_ALLOWED,
    OVERALL_MANDATORY_VERIFY_EMAIL,
    OVERALL_NOT_MATCH_ID,
    OVERALL_DUPLICATED_ID,
    OVERALL_FAILED_VERIFICATION,
    OVERALL_RENT_SUSPENDED,
    OVERALL_NO_BINDING_CREDITCARD,
    OVERALL_EXPIRED_PAYMENT_REQUEST,
    OVERALL_OUTSTANDING_RENTAL_PAYMENT,
} from 'constants/customer';
import ControlTemplate from './ControlTemplate';
import { Translate } from 'react-redux-i18n';

const Component = ({ status }) => {
    let el;
    const colorMap = {
        [OVERALL_UNVERIFIED]: 'secondary',
        [OVERALL_ALLOWED]: 'success',
    };

    switch (status) {
    case OVERALL_UNVERIFIED:
    case OVERALL_ALLOWED:
    case OVERALL_MANDATORY_VERIFY_EMAIL:
    case OVERALL_NOT_MATCH_ID:
    case OVERALL_DUPLICATED_ID:
    case OVERALL_FAILED_VERIFICATION:
    case OVERALL_RENT_SUSPENDED:
    case OVERALL_NO_BINDING_CREDITCARD:
    case OVERALL_EXPIRED_PAYMENT_REQUEST:
    case OVERALL_OUTSTANDING_RENTAL_PAYMENT:
        el = (
            <Badge color={ colorMap[status] || 'danger' }>
                <Translate value={ `customer.overall_${ status }` } />
            </Badge>
        );
        break;
    default:
        el = (
            <Badge color="secondary">
                <Translate value="unknown" />
            </Badge>
        );
        break;
    }

    return (
        <ControlTemplate large captionKey="customer.overall_status" required={ false }>
            { el }
        </ControlTemplate>
    );
};

Component.propTypes = {
    status: PropTypes.number,
};
Component.defaultProps = {
    status: undefined,
};

export default Component;
