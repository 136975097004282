import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Smart from 'components/Smart';
import { Refresh } from 'components/Pagination';
import {
    REFUND_BY_MONEY,
    PAYMENT_STATUS_SUCCESS,
    PAYMENT_STATUS_FAIL,
    PAYMENT_CHANNEL_TAPPAY,
} from 'constants/rental';
import AuthView from 'components/AuthView';
import NavigationBar from 'components/NavigationBar';
import FlexGroup from 'components/FlexGroup';
import {
    updateDocumentTitle,
    fetchRentalDetail,
    clearRentalDetail,
    refund,
    updateSubtotal,
    toggleErrorDialog,
} from 'actions';
import { I18n, Translate } from 'react-redux-i18n';
import DetailsSection from './FormSection/Details';
import RefundModal from './FormSection/RefundModal';
import ChangeAmountModal from './FormSection/ChangeAmountModal';
import FunctionButtons from './FormSection/FunctionButtons';
import './rental-detail.scss';

class RentalDetail extends React.Component {
    static propTypes = {
        detail: ImmutablePropTypes.map,
    };

    static defaultProps = {
        detail: undefined,
    };

    constructor(props) {
        super(props);

        const { match } = props;
        this.state = {
            showRefundModal: false,
            showChangeAmountModal: false,
            refundType: REFUND_BY_MONEY,
        };
        this.elForm = React.createRef();
        this.rentalId = match.params.rentalId;
        this.queryPayload = {
            id: this.rentalId,
            price: undefined,
            coupon_id: undefined,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(updateDocumentTitle('rental.rental_detail'));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearRentalDetail());
    }

    fetchData = () => {
        const { dispatch } = this.props;
        return dispatch(fetchRentalDetail(this.rentalId));
    }

    handleRefundSubmit = payload => {
        const { dispatch, detail } = this.props;
        const { payment_channel } = (detail && detail.toJS()) || {};

        return dispatch(refund({
            id: this.rentalId,
            ...payload,
        })).then(
            ({ type, error }) => {
                if (type === 'ERROR') {
                    const { code } = error.response.data;
                    let message = (payment_channel === PAYMENT_CHANNEL_TAPPAY) ?
                        I18n.t('rental.refund_failed', {
                        code,
                    }) : I18n.t('rental.refund_failed_go_rewards');

                    if (300004 === code) {
                        message = I18n.t('rental.partial_refund_failed');
                    }

                    dispatch(toggleErrorDialog(message));
                }
                else {
                    this.setState({
                        showRefundModal: false,
                    });
                }
            }
        );
    }

    handleChangeAmount = ({ price }) => {
        const { dispatch } = this.props;

        return dispatch(updateSubtotal({
            id: this.rentalId,
            amount: price,
        })).then(
            ({ type, error }) => {
                if (type === 'ERROR') {
                    const { code } = error.response.data;
                    const message = I18n.t('rental.chagne_amount_failed', {
                        code,
                    });

                    dispatch(toggleErrorDialog(message));
                }
                else {
                    this.setState({
                        showChangeAmountModal: false,
                    });
                }
            }
        );
    }

    handleRefundTypeSelect = type => {
        this.setState({
            showRefundModal: true,
            refundType: type,
        });
    }

    render() {
        const { showRefundModal, showChangeAmountModal, refundType } = this.state;
        const { detail } = this.props;
        const rentalDetail = (detail && detail.toJS()) || {};
        const refunded = typeof rentalDetail.refund_price === 'number' ||
            typeof rentalDetail.refund_coupon_title === 'string';
        const hasCharged = (
            rentalDetail.payment_sub_total > 0 && rentalDetail.payment_status === PAYMENT_STATUS_SUCCESS
        );

        return (
            <AuthView className="rental rental-detail">
                <Smart
                    fetch={ this.fetchData }
                    pauseRefresh
                    seamless
                >
                    <NavigationBar title={ this.rentalId } sticky>
                        <FunctionButtons
                            disabledChangeAmount={ rentalDetail.payment_status !== PAYMENT_STATUS_FAIL }
                            disabledRefund={ refunded || !hasCharged }
                            onSelectRefundType={ this.handleRefundTypeSelect }
                            onOpenChangeAmountModal={ () => this.setState({ showChangeAmountModal: true }) }
                        />
                    </NavigationBar>
                    <RefundModal
                        show={ showRefundModal }
                        type={ refundType }
                        charged={ rentalDetail.payment_sub_total }
                        onClose={ () => this.setState({ showRefundModal: false }) }
                        onSubmit={ this.handleRefundSubmit }
                    />
                    <ChangeAmountModal
                        show={ showChangeAmountModal }
                        type={ refundType }
                        discount={ rentalDetail.discount }
                        subTotal={ rentalDetail.sub_total }
                        onClose={ () => this.setState({ showChangeAmountModal: false }) }
                        onSubmit={ this.handleChangeAmount }
                    />
                    <section className="form-container">
                        <FlexGroup spaceBetween>
                            <Translate value="customer.details" className="caption" tag="h2" />
                            <FlexGroup className="refresh-section">
                                <Refresh
                                    time={ rentalDetail.__responseTime }
                                    onClick={ this.fetchData }
                                />
                            </FlexGroup>
                        </FlexGroup>
                        <DetailsSection data={ rentalDetail } />
                    </section>
                </Smart>
            </AuthView>
        );
    }
}

export default connect((state, ownProps) => ({
    i18n: state.i18n,
    detail: state.rental.get('detail')[ownProps.match.params.rentalId],
}))(RentalDetail);
