import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAPIkey } from 'api/util';
import qs from 'query-string';
import { store as rtkStore } from "redux/store";
import { authApi } from "./authApi";
import account from 'helpers/account';
import { login, logout, openPleaseLoginDialog } from "redux/features/authSlice";

const { REACT_APP_API_BASE_URL } = process.env;

const headersWithToken = async (headers) => {
    headers.set('Authorization', getAPIkey());
    headers.set('Content-Type', 'application/json');
    return headers;
};

const baseQuery = fetchBaseQuery({
    baseUrl: REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headersWithToken,
    paramsSerializer: (params) => qs.stringify(params)
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        await api.dispatch(logout());
        return await rtkStore.dispatch(authApi.endpoints.deleteCookie.initiate());
    }
    else {
        const expires_at = account.getExpiresAt();
        if (account.diff()) {
            return await api.dispatch(openPleaseLoginDialog());
        }
        else if (account.exists()) {
            if (Date.now() > expires_at || account.getID() === '') {
                const refreshResult = await rtkStore.dispatch(authApi.endpoints.googleAuth.initiate({}, {
                    forceRefetch: true
                }));
                if (refreshResult.data) {
                    const { data } = refreshResult;
                    await api.dispatch(login(data));
                    result = await baseQuery(args, api, extraOptions);
                }
            }
        }
        else {
            await api.dispatch(logout());
            await rtkStore.dispatch(authApi.endpoints.deleteCookie.initiate());
        }
    }
    return result;
}
