import React, { useEffect, useState } from 'react';
import { store as rtkStore } from "redux/store";
import { Redirect } from 'react-router-dom';
import { LOGIN } from 'constants/routes';
import { useDispatch } from "react-redux";
import usePrevious from 'hooks/usePrevious';
import {
    logout,
} from 'actions';
import { addListener } from '@reduxjs/toolkit';
import { authApi } from "redux/services/authApi";

const RTKIsLogin = () => {
    const dispatch = useDispatch();
    const [rtkIsLogin, setRtkIsLogin] = useState(rtkStore.getState().auth.isLogin);
    const prevRtkIsLogin = usePrevious(rtkIsLogin);

    useEffect(() => {
        const unsubscribe = rtkStore.dispatch(
            addListener({
                predicate: () => true,
                effect: async (action, listenerApi) => {
                    const { type } = action;
                    const isFullfilled = type.includes(authApi.reducerPath) && type.includes('fulfilled');
                    if (isFullfilled) {
                        const authData = await listenerApi.getState().auth;
                        const { isLogin } = authData;

                        if (isLogin !== rtkIsLogin) {
                            setRtkIsLogin(isLogin);
                        }
                    }
                },
            })
        );
        return () => {
            (typeof unsubscribe === 'function') && unsubscribe();
        }
    }, [rtkIsLogin]);



    useEffect(() => {
        async function logoutByRTK() {
            await dispatch(logout());
        }
        if (prevRtkIsLogin && !rtkIsLogin) {
            logoutByRTK();
        }

    }, [dispatch, rtkIsLogin, prevRtkIsLogin]);


    return (
        <>
            {
                (prevRtkIsLogin && !rtkIsLogin) ? (
                    <Redirect to={ `${ LOGIN }` } />
                ) : null
            }
        </>
    );
};


export default RTKIsLogin;
