/**
 * Action - Map
 */
import api from 'api';
import { asyncInterface } from './util';
import {
    FETCH_ZONES,
    FETCH_VMS,
    FOOTER_CONTENT,
    SELECT_SCOOTER,
    GET_VIP_LAYER,
    SELECT_VIP_LAYER,
    CLEAR_VIP_LAYER,
} from 'constants/action-type';

export const fetchZones = (service_id) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchZones(service_id),
        type: FETCH_ZONES,
        dispatch,
    });
};

export const fetchVMS = acceptLang => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchVMS(acceptLang),
        type: FETCH_VMS,
        dispatch,
    });
};

export const clearVMS = () => dispatch => dispatch({
    type: FETCH_VMS,
    data: [],
});

export const showMapContent = content => dispatch => dispatch({
    type: FOOTER_CONTENT,
    data: content,
});

export const selectScooter = selectIdList => {
    return {
        type: SELECT_SCOOTER,
        data: selectIdList,
    };
};

export const selectVipLayer = selectedLayerList => {
    return {
        type: SELECT_VIP_LAYER,
        data: selectedLayerList,
    };
};

export const clearVipLayer = () => {
    return {
        type: CLEAR_VIP_LAYER,
    };
};

export const getVipLayer = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.getVipLayer(params),
        type: GET_VIP_LAYER,
        dispatch,
    });
};
