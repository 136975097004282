import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import Modal from 'components/Modal';
import { fetchReturnPhotoUrl } from 'actions';
import './rental-photo-attachment.scss';

class RentalPhotoAttachment  extends React.Component {
    static propTypes = {
        rentalId: PropTypes.string,
        url: PropTypes.string,
    }

    static defaultProps = {
        rentalId: '',
        url: '',
    }

    state = {
        photoUrl: '',
        show: false,
    }

    fetchPhoto = async () => {
        const { rentalId, dispatch } = this.props;
        const { data } = await dispatch(fetchReturnPhotoUrl(rentalId));
        this.setState({ photoUrl: data.photo_url }, this.togglePreview);
    }

    togglePreview = () => {
        this.setState(state => ({ show: !state.show }));
    }

    render () {
        const { rentalId, url } = this.props;
        const { photoUrl, show } = this.state;

        return (
            <span className="return-photo">
                <span className="link" onClick={ rentalId !== '' ? this.fetchPhoto : this.togglePreview }>
                    { I18n.t('link') }
                </span>
                { (photoUrl || url) && show && (
                    <Modal className="preview-modal" title={ I18n.t('rental.photo') } onClose={ this.togglePreview }>
                        <img
                            className="preview"
                            src={ rentalId !== '' ? photoUrl : url }
                            alt=""
                        />
                    </Modal>
                ) }
            </span>
        );
    }
}

export default connect()(RentalPhotoAttachment);
