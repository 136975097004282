import { createFleetApiInstance, createFleetApiInstanceWithToken, getAPIkey } from '../util';

const user = {
    fetchUser: (params = {}) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get('/users', { params });
    },

    fetchUserRentalHistory: (params = {}) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get('/hist_rentals', { params });
    },

    fetchFranchiseeList: () => {
        return createFleetApiInstanceWithToken().get('/fleet_users/franchisees');
    },
};

export default user;
