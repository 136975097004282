import { createFleetApiInstanceWithToken } from '../util';


const log = {
    fetchRentalLog: (params = {}) => {
        return createFleetApiInstanceWithToken().get('/audit_logs', { params });
    },

    exportRentalLog: (params = {}) => {
        return createFleetApiInstanceWithToken().get('/audit_logs/files', { params });
    },
};

export default log;
