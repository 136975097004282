import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Redirect, withRouter } from 'react-router-dom';
import { CUSTOMER_DETAIL } from 'constants/routes';
import buildActualPath from 'helpers/build-actual-path';
import debounce from 'lodash/debounce';
import AuthView from 'components/AuthView';
import SearchResultWrap from 'components/SearchResultWrap';
import { getHeaderConfig } from './ListHeader';
import ChoiceGroup from 'components/ChoiceGroup';
import ListRow from './ListRow';
import ListView from 'components/ListView';
import SearchField from 'components/SearchField';
import FlexGroup from 'components/FlexGroup';
import PageHeader from 'components/PageHeader';
import { Translate, I18n } from 'react-redux-i18n';
import permissionHandler from 'helpers/permission-handler';
import {
    AUTH_FIND_CUSTOMER_BY_ID_NUMBER,
    AUTH_VIEW_CUSTOMER_DETAIL_PRIVACY,
} from 'constants/permission';
import {
    updateDocumentTitle,
    fetchCustomersByFinder,
    clearCustomers,
    clearAndPutFactorAuth,
} from 'actions';
import { getDefaultPageSize } from 'components/Pagination/PageSizeSelect';


import './customer-finder.scss';

const FILTER_PHONE = 'phone';
const FILTER_EMAIL = 'email';
const FILTER_ID = 'id';
const FILTER_ID_NO = 'id_number';
const SEARCH_INTERVAL = 1000;

class Customer extends React.Component {
    static propTypes = {
        list: ImmutablePropTypes.map.isRequired,
    };

    constructor(props) {
        super(props);
        this.searchType = FILTER_PHONE;
        this.queryPayload = {
            filter: {
                [this.searchType]: '',
            },
            sort: {},
            pageSize: getDefaultPageSize().value,
            pageIndex: 1,
        };
        this.state = {
            searchPlaceholder: 'customer.mobile',
            searchResultMessage: '',
            toDetailView: undefined,
        };
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { pathname } = location;

        dispatch(updateDocumentTitle(`routes.${ pathname }`));
        dispatch(clearCustomers());
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearCustomers());
    }

    fetchData = () => {
        const { dispatch } = this.props;
        const { filter } = this.queryPayload;
        let promise = Promise.resolve();
        let searchResultMessage = '';

        this.setState({
            ...this.state,
            toDetailView: undefined,
        });

        if (filter[this.searchType]) {
            promise = dispatch(
                fetchCustomersByFinder({
                    [this.searchType]: filter[this.searchType],
                })
            ).then(
                async ({ data, error }) => {
                    const { response } = error || {};
                    const { status } = response || {};
                    if (error) {
                        dispatch(clearCustomers());
                        switch (status) {
                        case 429:
                            searchResultMessage = 'customer.access_over_rate_limit';
                            break;
                        case 400:
                        case 401:
                        case 500:
                        default:
                            searchResultMessage = 'general_error_message';

                        }
                        this.setState({
                            searchResultMessage,
                        });
                    }
                    else {
                        const { data_list } = data;
                        const factor = {};
                        if (this.searchType === FILTER_PHONE || this.searchType === FILTER_EMAIL) {
                            factor[this.searchType] = filter[this.searchType];
                        }
                        await dispatch(clearAndPutFactorAuth(factor));

                        if (data_list.length === 1) {
                            // Clear for the debounce delay
                            dispatch(clearCustomers());
                            this.setState({
                                ...this.state,
                                toDetailView: <Redirect push to={ buildActualPath(CUSTOMER_DETAIL, { customerId: data_list[0].id }) } />,
                                searchResultMessage,
                            });
                        }
                    }


                }
            ).catch(
                error => {
                    // show error
                }
            );
        }

        return promise;
    }

    handleSearchTypeChange = e => {
        const { dispatch } = this.props;
        dispatch(clearCustomers());
        if (this.inputRef.current) {
            this.inputRef.current.value = '';
        }
        this.searchType = e.currentTarget.value;
        this.queryPayload.filter = { [this.searchType]: this.inputRef.current?.value };
        this.getSearchFieldPlaceHolderAndClearWarning();
    }
    handleKeywordChange = (value) => {
        if (this.inputRef.current) {
            this.inputRef.current.value = value;
        }
        this.queryPayload.filter = { [this.searchType]: value };
    };

    search = type => value => {
        const { filter } = this.queryPayload;
        filter[type] = value;
        this.fetchData();
    };

    getSearchFieldPlaceHolderAndClearWarning = () => {
        let searchPlaceholder;
        switch (this.searchType) {
        case FILTER_PHONE:
            searchPlaceholder = 'customer.mobile';
            break;
        case FILTER_EMAIL:
            searchPlaceholder = 'email';
            break;
        case FILTER_ID:
            searchPlaceholder = 'customer.customer_no';
            break;
        case FILTER_ID_NO:
            searchPlaceholder = 'customer.id_no';
            break;
        default:
        }

        this.setState({
            searchPlaceholder,
            searchResultMessage: '',
        });
    }

    getChoiceGroupOptions = () => {
        const options = [
            {
                id: FILTER_PHONE,
                name: I18n.t('customer.mobile'),
                value: FILTER_PHONE,
            }, {
                id: FILTER_EMAIL,
                name: I18n.t('email'),
                value: FILTER_EMAIL,
            }
        ];
        if (permissionHandler({ requiredList: [AUTH_VIEW_CUSTOMER_DETAIL_PRIVACY] })) {
            options.push({
                id: FILTER_ID,
                name: I18n.t('customer.customer_no'),
                value: FILTER_ID,
            });
        }
        if (permissionHandler({ requiredList: [AUTH_FIND_CUSTOMER_BY_ID_NUMBER] })) {
            options.push({
                id: FILTER_ID_NO,
                name: I18n.t('customer.id_no'),
                value: FILTER_ID_NO,
            });
        }

        return options;

    };

    render() {
        const headerConfig = getHeaderConfig();
        const { list } = this.props;
        const { searchPlaceholder, searchResultMessage, toDetailView } = this.state;
        const { data_list: dataList } = (list && list.toJS()) || {};
        const ListItem = ({ columns, rowData }) => (
            <ListRow
                key={ `customer-${ rowData.id }` }
                columns={ columns }
                data={ rowData }
            />
        );
        const searchOptions = this.getChoiceGroupOptions();

        return (
            <AuthView className="customer-finder">
                <PageHeader>
                    <Translate value="customer.customer_finder" />
                </PageHeader>
                <FlexGroup className="search-section">
                    <Translate className="search-for-caption" value="search_for" />
                    <ChoiceGroup
                        defaultChecked={ this.searchType }
                        options={ searchOptions }
                        onChange={ this.handleSearchTypeChange }
                    />
                    <SearchField
                        inputRef={ this.inputRef }
                        placeholder={ I18n.t(searchPlaceholder) }
                        onSubmit={ debounce(this.search(this.searchType), SEARCH_INTERVAL) }
                        value={ this.queryPayload.filter[this.searchType] }
                        onChange={ this.handleKeywordChange }
                    />
                </FlexGroup>
                <SearchResultWrap
                    show={ dataList.length > 0 }
                    message={ I18n.t(searchResultMessage) }
                >
                    <ListView
                        header={ headerConfig }
                        list={ dataList }
                        renderListRow={ ListItem }
                        dataKey="id"
                    />
                </SearchResultWrap>
                { toDetailView }
            </AuthView>
        );
    }
};

export default withRouter(connect(state => ({
    i18n: state.i18n,
    list: state.customer.get('list'),
}))(Customer));
