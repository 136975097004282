import React from 'react';
import ScooterFilterBar, {
    FILTER_BATTERY,
    FILTER_TRACKER_SOC,
    FILTER_MAIN_TYPE,
    FILTER_SUB_TYPE,
    FILTER_TAG_ASSIGNEE,
    FILTER_SCOOTER_TICKET,
} from 'view/ScooterList/FilterBar';

const EXCLUDE_LIST = [
    FILTER_BATTERY,
    FILTER_TRACKER_SOC,
    FILTER_MAIN_TYPE,
    FILTER_SUB_TYPE,
    FILTER_TAG_ASSIGNEE,
    FILTER_SCOOTER_TICKET,
];
const FilterBar = (props) => {
    return (
        <ScooterFilterBar
            { ...props }
            excludeList={ EXCLUDE_LIST }
        />
    );
};


export default FilterBar;
