import permissionHandler from 'helpers/permission-handler';
import {
    AUTH_READ_CUSTOMER_MEMBERSHIP_INFO,
    AUTH_READ_CUSTOMER_PERSONAL_INFO,
} from 'constants/permission';

const getPersonalInfo = () => permissionHandler({ requiredList: [AUTH_READ_CUSTOMER_PERSONAL_INFO] }) ? [{
    name: 'phone',
    value: 'customer.mobile',
    width: 242,
}, {
    name: 'first_name',
    value: 'customer.first_name/last_name',
    width: 200,
}, {
    name: 'email',
    value: 'email',
    width: 360,
}, {
    name: 'birthday',
    value: 'birthday',
    width: 298,
}] : [];
const getMembershiplInfo = () => permissionHandler({ requiredList: [AUTH_READ_CUSTOMER_MEMBERSHIP_INFO] }) ? [{
    name: 'attributes',
    value: 'customer.attributes',
    width: 197,
}] : [];
export const getHeaderConfig = () => [
    {
        name: 'id',
        value: 'customer.customer_no',
        width: 192,
    },
    ...getPersonalInfo(),
    ...getMembershiplInfo(),
];
