import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Col, FormGroup, Label } from 'reactstrap';
import Button from 'components/Form/Button';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { exportCSV } from 'helpers/util';
import Modal from 'components/Modal';
import {
    MAIN_TYPE_SWAP
} from 'constants/ticket';
import {
    updateServiceState,
    toggleErrorDialog,
    fetchOperationUsers,
    createTicket,
} from 'actions';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import './new-ticket.scss';


class NewTicket extends React.Component {
    static propTypes = {
        virtualUsers: ImmutablePropTypes.list.isRequired,
        opUsers: ImmutablePropTypes.list.isRequired,
        scooterIdList: PropTypes.arrayOf(PropTypes.string).isRequired,
        show: PropTypes.bool,
        onCancelled: PropTypes.func,
        onCreated: PropTypes.func,
        isConditional: PropTypes.bool,
        corporate: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        show: true,
        onCancelled: () => {},
        onCreated: () => {},
        isConditional: false,
        corporate: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedMainType: MAIN_TYPE_SWAP,
            showConditionalResult: false,
            responseData: {},
            isSubmitting: false,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(fetchOperationUsers());
    }
    componentDidUpdate(prevProps) {
        const { show } = this.props;
        // initial selectedMainType if closing newTicket
        if (show === false && prevProps.show === true) {
            this.setState({
                selectedMainType: MAIN_TYPE_SWAP,
                showConditionalResult: false,
                responseData: {},
                isSubmitting: false,
            });
        }
    }

    handleSubmit = async (formData, doOfflineScooterList) => {
        const { isSubmitting } = this.state;
        if (isSubmitting) {
            return;
        }
        const {
            onCreated,
            scooterIdList,
            dispatch,
            isConditional,
        } = this.props;
        const updateFormData = {
            ...formData,
            scooter_ids: scooterIdList,
        };
        this.setState({
            isSubmitting: true,
        });
        try {
            const empty = obj => Object.keys(obj).length === 0;
            if (!empty(doOfflineScooterList)) {
                const { data, type } = await dispatch(updateServiceState(doOfflineScooterList));
                if (type === 'ERROR' || data.failure_plate_list?.length > 0) {
                    throw data.failure_plate_list;
                }
            }

            const { error, data } = await dispatch(createTicket(updateFormData));

            if (isConditional && data && !error) {
                const { plate_no } = updateFormData;
                const { tickets } = data;
                const allScooters = plate_no ? plate_no.length : 0;
                const uniqueScooters = plate_no ? [...new Set(plate_no)].length : 0;

                this.setState({
                    responseData: {
                        data: tickets,
                        allScooterList: plate_no,
                        uniqueScooters,
                        allScooters,
                        tickets: tickets.length,
                        missedScooters: uniqueScooters - tickets.length,
                    },
                    showConditionalResult: true,
                });
                onCreated(!isConditional);
                return;
            }

            if (error) {
                const { response } = error;
                const { code } = response.data;
                if (isConditional) {
                    switch (code) {
                    case 404:
                        dispatch(toggleErrorDialog(I18n.t('ticket.conditional_no_intersection_error_message')));
                        break;
                    case 400:
                        dispatch(toggleErrorDialog(I18n.t('ticket.conditional_scooters_overflow_error_message')));
                        break;
                    default:
                        throw error;
                    }
                }
                else {
                    throw error;
                }
            }
        }
        catch (err) {
            console.error(err);
        }

        onCreated();
    }

    onSelectMainType = mainType => {
        this.setState({
            selectedMainType: mainType,
        });
    }

    downloadConditionalResult = e => {
        const fileName = 'ConditionalTickets_' + moment().format(I18n.t('datetime_format.date')).replace(/-/g, '');
        const { responseData } = this.state;
        const { data, allScooterList } = responseData;
        let dataString = allScooterList.map(item => ({ 'plate_no': item.toUpperCase() })).concat(data);
        dataString = [...new Map(dataString.map(
            item => [item.plate_no, item.plate_no + ',' + (item.id || 'N/A')]
        )).values()];

        exportCSV(fileName, '', dataString, '\r\n');
    }

    renderResponseCtrl = (captionKey, data ) => {
        return (
            <FormGroup row>
                <Label sm={ 6 } className="field">
                    <Translate value={ `ticket.${ captionKey }` } />
                </Label>
                <Col sm={ 6 } className="data">
                    { data }
                </Col>
            </FormGroup>
        );
    }


    render() {
        const { show, virtualUsers, opUsers, onCancelled, scooterIdList, isConditional, corporate } = this.props;
        const { selectedMainType, showConditionalResult, responseData, isSubmitting } = this.state;
        const { allScooters, tickets, missedScooters, uniqueScooters } = responseData;
        const title = isConditional ? I18n.t('ticket.conditional_modal_title') : I18n.t('ticket.new');
        const repeatedScooters = allScooters - uniqueScooters;
        return (
            show ? (
                <Modal className="new-ticket" title={ title } onClose={ onCancelled }>
                    {
                        showConditionalResult ? (
                            <div className="conditional-result">
                                { this.renderResponseCtrl(
                                    'conditional_result_imported_scooters_amount', uniqueScooters) }
                                { this.renderResponseCtrl(
                                    'conditional_result_unqualified_scooters_amount', missedScooters) }
                                { this.renderResponseCtrl(
                                    'conditional_result_tickets_amount', tickets) }
                                { repeatedScooters ? this.renderResponseCtrl(
                                    'conditional_result_repeated_scooters', repeatedScooters)
                                    : null }
                                <Button
                                    type="button"
                                    className="btn-cancel"
                                    outline
                                    onClick={ this.downloadConditionalResult }
                                >
                                    <Translate value="download" />
                                </Button>
                            </div>
                        ) : (
                            <div>
                                { scooterIdList.length === 0 ? <h1>No Scooter(s) have been selected</h1> : null }
                                <FirstStep
                                    show={ scooterIdList.length > 0 }
                                    mainType={ selectedMainType }
                                    onSelected={ this.onSelectMainType }
                                />
                                <SecondStep
                                    scooterIdList={ scooterIdList }
                                    isConditional={ isConditional }
                                    show={
                                        scooterIdList.length > 0 && !!selectedMainType
                                    }
                                    virtualUsers={ convertOpUsers(virtualUsers) }
                                    opUsers={ convertOpUsers(opUsers) }
                                    mainType={ selectedMainType }
                                    onCancelled={ onCancelled }
                                    onSubmit={ this.handleSubmit }
                                    corporate={ corporate }
                                    inProgress={ isSubmitting }
                                />
                            </div>
                        )
                    }
                </Modal>
            ) : null
        );
    }
}
export default connect(state => ({
    opUsers: state.account.get('opUsers'),
    virtualUsers: state.account.get('virtualUsers'),
}))(NewTicket);

export const convertOpUsers = (opUsers, needNoneUser = false) => {
    if (needNoneUser) {
        opUsers = opUsers.insert(0, {
            user_id: '',
            name: 'N/A',
        });
    }

    return opUsers.map(({ user_id, name }) => {
        return {
            value: user_id,
            text: name,
            name: name,
            translate: false,
        };
    }).toArray();
};
