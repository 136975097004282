import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Label } from 'reactstrap';
import { Translate } from 'react-redux-i18n';
import classnames from 'classnames';
import AuthFeature from 'components/AuthFeature';
import {
    TYPE_RADIO,
    TYPE_CHECKBOX,
} from './input-type';

class GroupSelection extends Component {
    static propTypes = {
        type: PropTypes.oneOf([
            TYPE_RADIO,
            TYPE_CHECKBOX,
        ]).isRequired,
        innerRef: PropTypes.shape(),
        name: PropTypes.string,
        value: PropTypes.arrayOf(PropTypes.shape({
            // translation key
            name: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.node,
                PropTypes.bool
            ]),
            disabled: PropTypes.bool
        })).isRequired,
        selected: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.bool)
        ]),
        onChange: PropTypes.func,
        required: PropTypes.bool,
    };

    static defaultProps = {
        innerRef: undefined,
        name: undefined,
        selected: [],
        onChange: () => {},
        required: false,
    };

    constructor(props) {
        super(props);
        const { innerRef } = this.props;
        this.elInput = innerRef || React.createRef();
    }

    getRequired() {
        const { selected, required } = this.props;

        return selected.length === 0 && required;
    }

    render() {
        const { name, type, value, selected, onChange } = this.props;
        const realSelected = Array.isArray(selected) ? [...selected] : [selected];
        return (
            <div className="group-selection">
                {
                    value.map((item, i) => {
                        const checked = realSelected.indexOf(item.value) > -1;
                        const labelClassname = classnames({ disabled: item.disabled });
                        const el = (
                            <Label className={ labelClassname } check>
                                <Input
                                    { ...this.props }
                                    type={ type }
                                    name={ name }
                                    required={ i === 0 ? this.getRequired() : false }
                                    value={ item.value }
                                    disabled={ item.disabled }
                                    onChange={ onChange(item.value) }
                                    checked={ checked }
                                />
                                <Translate value={ item.name } />
                            </Label>
                        );
                        return item.auth ? (
                            <AuthFeature key={ item.value } requiredList={ [item.auth] }>
                                { el }
                            </AuthFeature>
                        ) : (
                            <div key={ item.value }>
                                { el }
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}

export default GroupSelection;
