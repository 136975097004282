import React from 'react';
import { Translate } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import './total-selection.scss';


const Component = ({ count, innerRef }) => (
    (!Number.isNaN(count) && 'number' === (typeof count)) ? (
        <p className="tot-selection">
            <Translate value="total_selection" />
            <span ref={ innerRef }>{ `: ${ count }` }</span>
        </p>
    ) : null
);
Component.propTypes = {
    count: PropTypes.number,
    innerRef: PropTypes.shape(),
};
Component.defaultProps = {
    count: undefined,
    innerRef: React.createRef(),
};

export default Component;
