import {
    STATUS_DISABLED,
    STATUS_DRAFT,
    STATUS_PUBLISHED,
    STATUS_LIVED,
    STATUS_EXPIRED,

    TRIP_COUNT,
    TRIP_DISTANCE,
    BATTERY_SWAP_COUNT,

    IN_APP_WEBVIEW,
    OS_DEFAULT_WEB_BROWSER,

    TITLE_NOT_VALID,
    WEB_URL_NOT_VALID,
    URL_LAUNCH_MODE_NOT_VALID,
    CRITERIA_TYPE_NOT_VALID,
    CRITERIA_VALUE_NOT_VALID,
    VALID_FROM_TO_NOT_VALID,
    REWARD_COUPON_ID_NOT_VALID,
    CANNOT_CHANGE_STATUS,
    STATUS_IS_PUBLISHED,
    MISSION_ORDER_STATUS_ERROR,
} from 'constants/mission';

const mission = {
    title: 'Title',
    status: 'Status',
    search_for: 'Search title',
    rule_link: 'Rule Link',
    url_launch_mode: 'Launch Mode',
    valid_time: 'Valid time',
    valid_from: 'Valid From',
    valid_to: 'Valid To',
    create_time: 'Created Time',
    criteria_type: 'Criteria',
    criteria_value: 'Value',
    edit_mission_info: 'Edit mission',
    details: 'Details',
    publish: 'Publish',
    withdraw: 'Withdraw',
    view_title: 'Mission Detail',
    publish_time: 'Publish time',
    publisher: 'Publisher',
    [`status_${ STATUS_DISABLED }`]: 'Disabled',
    [`status_${ STATUS_DRAFT }`]: 'Draft',
    [`status_${ STATUS_PUBLISHED }`]: 'Published',
    [`status_${ STATUS_LIVED }`]: 'Online',
    [`status_${ STATUS_EXPIRED }`]: 'Expired',
    [`criteria_type_${ TRIP_COUNT }`]: 'Trip count',
    [`criteria_type_${ TRIP_DISTANCE }`]: 'Trip distance',
    [`criteria_type_${ BATTERY_SWAP_COUNT }`]: 'Low SOC battery swap count',
    [`url_launch_mode_${ IN_APP_WEBVIEW }`]: 'In app webview',
    [`url_launch_mode_${ OS_DEFAULT_WEB_BROWSER }`]: 'Device default web browser',
    [`error_message_${ TITLE_NOT_VALID }`]: 'Title is not valid',
    [`error_message_${ WEB_URL_NOT_VALID }`]: 'Rule link is not valid',
    [`error_message_${ URL_LAUNCH_MODE_NOT_VALID }`]: 'Launch mode is not valid',
    [`error_message_${ CRITERIA_TYPE_NOT_VALID }`]: 'Criteria is not valid',
    [`error_message_${ CRITERIA_VALUE_NOT_VALID }`]: 'Criteria value is not valid',
    [`error_message_${ VALID_FROM_TO_NOT_VALID }`]: 'Mission valid time is not valid',
    [`error_message_${ REWARD_COUPON_ID_NOT_VALID }`]: 'Coupon ID is not valid',
    [`error_message_${ CANNOT_CHANGE_STATUS }`]: 'Can\'t change status',
    [`error_message_${ STATUS_IS_PUBLISHED }`]: 'Can\'t modify content, when status is scheduled',
    new_mission: 'New mission',
    change_order: 'Order Mission',
    order_document_title: 'Mission Order',
    brief_intro: 'Please drag the table row to change Mission order',
    [`mission_order_error_${ MISSION_ORDER_STATUS_ERROR }`]:'Mission status was changed, please order again.',
    confirm_order_change: 'Confirm Mission order',
    confirm_order_change_describe: 'Are you sure to apply the current order?',
    continuely_edit: 'Edit again',
};

export default mission;
