import {
    TRUNK_CLOSED,
    TRUNK_OPEN,
    TRUNK_LOCKED,

    SCOOTER_STATE_UNLOCK,
    SCOOTER_STATE_ENGINE_ON,
    SCOOTER_STATE_LOCK,
    SCOOTER_STATE_SHUTDOWN,

    SCOOTER_OFFLINE,
    SCOOTER_ONLINE,

    TICKET_NONE,
    TICKET_SWAP,
    TICKET_SWAP_F,
    TICKET_DISPATCH,
    TICKET_MAINTAIN,

    GTU_ERROR_CODE_15000,
    GTU_ERROR_CODE_15001,
    GTU_ERROR_CODE_15002,
    GTU_ERROR_CODE_15003,
    GTU_ERROR_CODE_15004,
    GTU_ERROR_CODE_15005,
    GTU_ERROR_CODE_15006,
    GTU_ERROR_CODE_15007,
    FORCE_RETURN_ERROR_CODE_3000,
    FORCE_RETURN_ERROR_CODE_3001,
    FORCE_RETURN_ERROR_CODE_3006,
    FORCE_RETURN_ERROR_CODE_3007,
    FORCE_RETURN_ERROR_CODE_3008,
    FORCE_RETURN_ERROR_CODE_3009,
    FORCE_RETURN_ERROR_CODE_3010,

    FIRMWARE_ERROR_CODE_1400,
} from 'constants/scooter';

const trunkState = {
    [TRUNK_CLOSED]: '關閉',
    [TRUNK_OPEN]: '開啟',
    [TRUNK_LOCKED]: '上鎖',
};
const scooterState = {
    [SCOOTER_STATE_UNLOCK]: '解鎖',
    [SCOOTER_STATE_ENGINE_ON]: '馬達啟動',
    [SCOOTER_STATE_LOCK]: '上鎖',
    [SCOOTER_STATE_SHUTDOWN]: '休眠',
};
const availabilityState = {
    [SCOOTER_OFFLINE]: '下線',
    [SCOOTER_ONLINE]: '上線',
};
const tag = {
    [TICKET_NONE]: 'NONE',
    [TICKET_SWAP]: 'SWAP',
    [TICKET_SWAP_F]: 'SWAP-FLOATING',
    [TICKET_DISPATCH]: 'DISPATCH',
    [TICKET_MAINTAIN]: 'MAINTAIN',
};

const scooter = {
    'remote_control': '遠端控制',
    'lock': '上鎖',
    'unlock': '解鎖',
    'open_trunk': '開車廂',
    'find': '尋車',
    'plate': '車牌號碼',
    'tag': '標籤',
    'tag_description': '描述',
    'tag_assignee': '指派',
    'assign_to_me': '指派給我',
    'battery': '電量(%)',
    'battery-wo-percent': '電量',
    'tracker_soc': 'GTU(%)',
    'remaining_mileage': '可行駛里程',
    'csq': 'CSQ',
    'satellites': '衛星',
    'model': '型號',
    'activity': '上線狀況',
    'rental_state': '狀態',
    'last_rental_end_time': '上次租賃時間',
    'idle': '閒置(hrs)',
    'total_mileage': '總里程',
    'scooter_state': '車輛狀況',
    'location': '位置',
    'trunk_state': '車廂',
    'last_connection_date': '離線',
    'lct': '離線(hrs)',
    'gtu_imei': 'IMEI',
    'gtu_fw_version': 'GTU 韌體版本',
    'scooter_fw_version': '車輛韌體版本',
    'last_udpated': '更新時間',
    'service': '服務',
    'activation': {
        'on': '上線',
        'off': '下線',
    },
    'trunk_state_map': trunkState,
    'scooter_state_map': scooterState,
    'availability_state_map': availabilityState,
    'tag_map': tag,
    'batch_update_service_state_error_message': '哦喔! 車輛(%{plates}) 服務狀態設定失敗，請再試一次',
    'force_return': '強制還車',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3000}_error_message`]: '租賃不存在',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3001}_error_message`]: '價格超出範圍',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3006}_error_message`]:
        '電池狀態異常',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3007}_error_message`]: '車廂未關閉 (車廂開啟或未鎖)',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3008}_error_message`]: '馬達未關閉',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3009}_error_message`]: '車輛未鎖',
    [`force_return_${FORCE_RETURN_ERROR_CODE_3010}_error_message`]: 'GTU 連線錯誤',
    'force_return_error_message': '強制還車錯誤 (%{code})',
    'force_return_error_by_linepay_message': '強制還車成功，請用戶至 APP 付款',
    'input_rental_fee': '輸入還車金額',
    'no_gtu_data': '尚未配對 GTU',
    'has_no_gtu_data': '%{plate} 尚無 GTU 資訊。',
    'gtu_replacement': 'GTU 置換',
    'gtu_id': 'GTU ID',
    'gtu_info': 'GTU 資訊',
    'gtu_cert': '憑證',
    'gtu_new_info': '新 GTU 資訊',
    'gtu_cert_subject': '主體',
    'gtu_cert_valid_from': '有效期(起)',
    'gtu_cert_valid_to': '有效期(迄)',
    'gtu_cert_issuer': '簽發者',
    'flash_fill': '快速填入',
    'no_searched_imei': '查無此 IMEI',
    'gtu_general_error_message': '哦喔! 好像發生問題了，請再試一次',
    [`gtu_${GTU_ERROR_CODE_15000}_error_message`]: '未傳入必填欄位',
    [`gtu_${GTU_ERROR_CODE_15001}_error_message`]: '無效的憑證主體',
    [`gtu_${GTU_ERROR_CODE_15002}_error_message`]: '無效的憑證有效日期',
    [`gtu_${GTU_ERROR_CODE_15003}_error_message`]: '無效的憑證失效日期',
    [`gtu_${GTU_ERROR_CODE_15004}_error_message`]: 'GTU 已經配對在其他車輛上',
    [`gtu_${GTU_ERROR_CODE_15005}_error_message`]: '憑證主體已被其他 GTU 使用',
    [`gtu_${GTU_ERROR_CODE_15006}_error_message`]: '中斷原本 GTU 連線失敗',
    [`gtu_${GTU_ERROR_CODE_15007}_error_message`]: '車輛服務狀態必須是下線',
    'city': '城市',
    [`firmware_${FIRMWARE_ERROR_CODE_1400}_error_message`]: '暫時無法取得車輛韌體資訊，請確認GTU連線狀態後再試',
    'unassign_user': '尚未指派',
    'number_constraint_of_scooter': '選擇的車輛請勿多於 200 台',
    'related_tickets': '我的工單連結',
    'corporate': '企業',
    'firmware_check': '檢查韌體',
    'firmware_info': '韌體資訊',
    'firmware_version': 'Scooter 版本',
    'gtu_version': 'GTU 版本',
    'lack_feautres': '缺乏功能',
    'parking_check': '違停檢查',
    'search_time': '搜尋時間',
    'address': '地址',
    'pause_description': '確定要解鎖嗎?',
    'pause_fee_warning': '解鎖後會自動切回騎乘費率'
};

export default scooter;
