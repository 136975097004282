/**
 * Action - General
 */
import {
    TOGGLE_MENU,
    UPDATE_DOCUMENT_TITLE,
    TOGGLE_ERROR_DIALOG,
    TOGGLE_NAVIGATION,
    OPEN_CATEGORY,
    CLOSE_CATEGORY,
    OPEN_PLEASE_LOGIN_DIALOG,
} from 'constants/action-type';

export const toggleMenu = () => {
    return {
        type: TOGGLE_MENU,
    };
};

export const openCategory = (category) => {
    return {
        type: OPEN_CATEGORY,
        data: {
            category,
        }
    };
};

export const closeCategory = (category) => {
    return {
        type: CLOSE_CATEGORY,
        data: {
            category,
        }
    };
};

export const updateDocumentTitle = (title) => {
    return {
        type: UPDATE_DOCUMENT_TITLE,
        data: { title: title }
    };
};

export const toggleErrorDialog = (message, onClose) => dispatch => Promise.resolve(dispatch({
    type: TOGGLE_ERROR_DIALOG,
    data: {
        message,
        onClose,
    },
}));

export const toogleNavigation = () => {
    return {
        type: TOGGLE_NAVIGATION
    };
};

export const openPleaseLoginDialog = () => {
    return {
        type: OPEN_PLEASE_LOGIN_DIALOG,
    };
};
