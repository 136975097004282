import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FlexGroup from 'components/FlexGroup';
import { Translate, I18n } from 'react-redux-i18n';
import './Allowance.scss';

const INFINITY_YEAR = 9999;

const Allowance = ({
    isVip,
    item,
}) => {

    const conPeriod = (allowance) => {
        const { valid_from, valid_to } = allowance;
        const isInfinity = moment(valid_to).year() >= INFINITY_YEAR;
        const from = moment(valid_from).format(I18n.t('datetime_format.date'));
        const to = isInfinity ? '' : moment(valid_to).format(I18n.t('datetime_format.date'));
        return `${from} ~ ${to}`;
    };

    const renderSubsidy = ( allowance ) => {
        const { discount_percentage, discount_value } = allowance;
        let string = '';
        let count = undefined;
        let max = undefined;

        if ( discount_value && discount_percentage ) {
            string = 'customer.subsidy_percent_max';
            count = discount_percentage;
            max = discount_value;
        }
        else if ( discount_value ) {
            string = 'customer.subsidy_price';
            count = discount_value;
        }
        else if ( discount_percentage ) {
            string = 'customer.subsidy_percent';
            count = discount_percentage;
        }
        else {
            string = 'customer.subsidy_all';
        }
        return (<Translate value={ string } count={ count } max={ max } />);
    };

    const renderItinerary = ( allowance ) => {
        const { times_limit_type, times_limit_value } = allowance;
        let string = '';
        let count = times_limit_value || 0;

        switch (times_limit_type) {
        case 1:
            string = 'customer.itinerary_day';
            break;
        case 2:
            string = 'customer.itinerary_month';
            break;
        case 0:
        default:
            string = 'customer.itinerary_all';
        }


        return (<Translate value={ string } count={ count } />);
    };

    return (
        item !== null ? (
            <div className="allowance">
                <FlexGroup className="title" spaceBetween>
                    {
                        isVip ?
                            ( <Translate value="customer.vip" /> ) : ( <Translate value="customer.general" /> )
                    }
                </FlexGroup>
                <FlexGroup className="content" alignStart>
                    <div className="condition">
                        <p className="caption">
                            <Translate value="customer.contract_period" />
                        </p>
                        <p className="text">{ conPeriod(item) }</p>
                    </div>
                    <div className="condition">
                        <p className="caption">
                            <Translate value="customer.subsidy_amount" />
                        </p>
                        <p className="text">
                            { renderSubsidy(item) }
                        </p>
                    </div>
                    <div className="condition">
                        <p className="caption">
                            <Translate value="customer.itinerary" />
                        </p>
                        <p className="text">
                            { renderItinerary(item) }
                        </p>
                    </div>
                </FlexGroup>
            </div>
        ) : null
    );
};

Allowance.propTypes = {
    isVip: PropTypes.bool,
    item: PropTypes.oneOfType([
        PropTypes.shape({
            valid_from: PropTypes.string,
            valid_to: PropTypes.string,
            times_limit_type: PropTypes.number,
            times_limit_value: PropTypes.number,
            discount_percentage: PropTypes.number,
            discount_value: PropTypes.number,
        }),
    ]),
};

Allowance.defaultProps = {
    isVip: false,
    item: null,
};


export default Allowance;