import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import MultiSelectFilter from 'components/Filter/MultiselectFilter';
import { CRITERIA_TYPE_GROUP } from 'constants/mission';

export default function CriteriaTypeFilter({ onChange, selected }) {
    const options = CRITERIA_TYPE_GROUP.map(type => ({
        value: type,
        text: I18n.t(`mission.criteria_type_${ type }`),
    }));

    return (
        <MultiSelectFilter
            title={ I18n.t('mission.criteria_type') }
            options={ options }
            defaultSelected={ selected }
            onChange={ onChange }
        />
    );
}

CriteriaTypeFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.number),
};

CriteriaTypeFilter.defaultProps = {
    selected: [],
};
