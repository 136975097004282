import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import mobile from 'is-mobile';
import { Translate } from 'react-redux-i18n';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Button } from 'reactstrap';
import Pagination, { TotalCount, Refresh } from 'components/Pagination';
import PageSizeSelect from 'components/Pagination/PageSizeSelect';
import Smart from 'components/Smart';
import ListView from 'components/ListView';
import FlexGroup from 'components/FlexGroup';
import AuthFeature from 'components/AuthFeature';
import { AUTH_EDIT_PROMOTION_EVENT } from 'constants/permission';
import promotionHeaderConfig from './promotionHeaderConfig';
import PromotionListRow from './PromotionListRow';
import { NEW_PROMOTION } from 'constants/routes';

export const FILTER_PAGE_INDEX = 'page';
export const FILTER_PAGE_SIZE = 'size';
export const FILTER_SORT = 'sort';

class PromotionList extends React.Component {

    static propTypes = {
        pageSize: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.arrayOf(PropTypes.number),
        ]).isRequired,
        list: PropTypes.shape({
            page_index: PropTypes.number,
            page_count: PropTypes.number,
            data_list: ImmutablePropTypes.list,
        }).isRequired,
        onFetch: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const { onFetch, onChange, list, pageSize } = this.props;
        const { page_index, page_count, data_list, total, __responseTime } = list.toJS();

        return (
            <React.Fragment>
                <FlexGroup>
                    <FlexGroup start gap>
                        <AuthFeature requiredList={ [AUTH_EDIT_PROMOTION_EVENT] }>
                            <NavLink to={ NEW_PROMOTION }>
                                <Button color="primary">
                                    <Translate value="promotion.new_promotion" />
                                </Button>
                            </NavLink>
                        </AuthFeature>
                        <Pagination
                            page={ page_index }
                            total={ page_count }
                            offset={ pageSize }
                            onSelect={ onChange(FILTER_PAGE_INDEX) }
                        />
                        <PageSizeSelect
                            onChange={ onChange(FILTER_PAGE_SIZE) }
                        />
                        <TotalCount count={ total } />
                    </FlexGroup>
                    <FlexGroup end gap>
                        <Refresh onClick={ onFetch } time={  __responseTime } />
                    </FlexGroup>
                </FlexGroup>
                <Smart
                    fetch={ onFetch }
                    className="main-content-container"
                    seamless
                    pauseRefresh
                >
                    <section className="promotion-list-table">
                        <ListView
                            header={ promotionHeaderConfig }
                            selectable={ false }
                            dataKey="id"
                            fixed={ mobile() }
                            renderListRow={ PromotionListRow }
                            list={ data_list }
                            onSort={ onChange(FILTER_SORT) }
                        />
                    </section>
                </Smart>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    list: state.promotion.get('promotionList')
}))(PromotionList);
