/**
 * Action - Payment request
 */
import api from 'api';

import {
    FETCH_PAYMENT_REQUEST_LIST,
    FETCH_PAYMENT_REQUEST_DETAIL,
    FETCH_PAYMENT_RENTAL_HISTORY,
    CLEAR_PAYMENT_RENTAL_HISTORY,
    NEW_PAYMENT_REQUEST,
    CANCEL_PAYMENT_REQUEST,
    PAYMENT_REFUND
} from 'constants/action-type';

import { asyncInterface } from './util';

export const fetchPaymentRequestList = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchPaymentRequestList(params),
        type: FETCH_PAYMENT_REQUEST_LIST,
        dispatch,
    });
};

export const fetchPaymentRequestDetail = id => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchPaymentRequestDetail(id),
        type: FETCH_PAYMENT_REQUEST_DETAIL,
        dispatch,
    });
};

export const fetchPaymentRentalHistory = id => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchPaymentRentalHistory(id),
        type: FETCH_PAYMENT_RENTAL_HISTORY,
        dispatch,
    });
};

export const clearPaymentRentalHistory = () => dispatch => dispatch({
    type: CLEAR_PAYMENT_RENTAL_HISTORY,
});

export const newPaymentRequest = (payload = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.newPaymentRequest(payload),
        type: NEW_PAYMENT_REQUEST,
        dispatch,
    });
};

export const cancelPaymentRequest = id => dispatch => {
    return asyncInterface({
        asyncAction: api.cancelPaymentRequest(id),
        type: CANCEL_PAYMENT_REQUEST,
        dispatch,
    });
};

export const paymentRefund = id => dispatch => {
    return asyncInterface({
        asyncAction: api.paymentRefund(id),
        type: PAYMENT_REFUND,
        dispatch,
    });
};
