import React, { Component } from 'react';
import packageInfo from '../../../package.json';
import classNames from 'classnames';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { LOGIN } from 'constants/routes';
import {
    toggleMenu,
    openCategory,
    closeCategory,
} from 'actions';
import { Translate } from 'react-redux-i18n';
import Blocker from 'components/Blocker';
import getHierarchicalMenu, { getCategoryByPathName, sortCategory } from './get-hierarchical-menu';
import './sidebar.scss';

const MenuLink = ({ to, children }) => (
    <li className="menu-item"><NavLink exact className="menu-link" to={ to }>{ children }</NavLink></li>
);
MenuLink.propTypes = {
    to: PropTypes.string.isRequired,
};

class Sidebar extends Component {
    static propTypes = {
        toggleMenu: PropTypes.bool.isRequired,
        isLoggedIn: PropTypes.bool.isRequired,
        permission: ImmutablePropTypes.map,
        openCategoryList: ImmutablePropTypes.list,
        history: PropTypes.shape({}),
    }

    static defaultProps = {
        permission: null,
        openCategoryList: List([]),
        history: {},
    }

    componentDidUpdate() {
        const { history, dispatch } = this.props;
        (history && history.location) && ( () => {
            const { pathname } = history.location;
            const category = getCategoryByPathName(pathname);
            if (category) {
                dispatch(openCategory(category));
            }
        })();

    }

    handleCloseMenu = e => {
        const { dispatch } = this.props;
        dispatch(toggleMenu());
    }

    handleCategoryClick = category => e => {
        e.preventDefault();
        e.stopPropagation();
        const { openCategoryList, dispatch } = this.props;
        const openStatusCategory = openCategoryList.toJS();

        if (openStatusCategory.indexOf(category) > -1) {
            dispatch(closeCategory(category));
        }
        else {
            dispatch(openCategory(category));
        }
    }

    getRoutes = () => {
        const { isLoggedIn, permission } = this.props;
        let routes = [];

        if (isLoggedIn && permission) {
            const { authorities } = permission.toJS();
            routes = getHierarchicalMenu(authorities);
        }

        return routes;
    }

    renderCategory() {
        const routes = this.getRoutes();
        const { openCategoryList } = this.props;
        const openStatusCategory = openCategoryList.toJS();
        const sortedCategory = sortCategory(Object.keys(routes));

        return sortedCategory.map(category => {
            const isOpen = !!(openStatusCategory.indexOf(category) > -1);
            const iconCls = classNames({
                'collapse-icon': true,
                'open': !isOpen,
                'close': isOpen,
            });
            const listCls = classNames({
                'menu': true,
                'is-closed': !isOpen,
            });
            const categoryCls = classNames({
                'category': true,
                'is-closed': !isOpen,
            });
            return (
                <li className="menu-category" key={ category }>
                    <h3
                        className={ categoryCls }
                        onClick={ this.handleCategoryClick(category) }
                    >
                        <Translate className="category-link" value={ `routes.category_${ category }` } />
                        <div className={ iconCls } />
                    </h3>
                    <ul className={ listCls }>
                        { this.renderMenu(routes[category]) }
                    </ul>
                </li>
            );
        });
    }

    renderMenu(routes) {
        return routes.map(({ route }) => (
            <MenuLink key={ route } to={ route }>
                <Translate value={ `routes.${ route }` } />
            </MenuLink>
        ));
    }

    render() {
        const { toggleMenu, isLoggedIn } = this.props;
        const styles = classNames({
            'sidebar': true,
            'hide': !toggleMenu,
        });
        let menuList;

        if (!isLoggedIn) {
            menuList = (
                <ul className="menu">
                    <MenuLink to={ LOGIN }>
                        <Translate value="login" />
                    </MenuLink>
                </ul>
            );
        }
        else {
            menuList = (
                <ul className="menu">
                    { this.renderCategory() }
                </ul>
            );
        }

        return (
            <Blocker className={ styles } onClick={ this.handleCloseMenu }>
                <aside onClick={ this.handleCloseMenu }>
                    <div className="header">FLEET MANAGEMENT</div>
                    <menu>
                        { menuList }
                    </menu>
                    <p className="version">{ `v${packageInfo.version}` }</p>
                </aside>
            </Blocker>
        );
    }
}

export default connect(state => ({
    toggleMenu: state.general.get('toggleMenu'),
    isLoggedIn: state.account.get('isLoggedIn'),
    permission: state.account.get('permission'),
    openCategoryList: state.account.get('openCategoryList'),
}))(Sidebar);
