import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import logo from 'assets/logo.svg';
import iconMenu from 'assets/icon-menu.svg';
import { toggleMenu } from 'actions';
import Avatar from 'components/Avatar';
import { withRouter } from 'react-router-dom';

import './main-header.scss';

class Header extends Component {
    handleMenuClick = () => {
        const { dispatch } = this.props;
        dispatch(toggleMenu());
    }

    render() {
        return (
            <header className="main-header">
                <Button className="btn-menu" onClick={ this.handleMenuClick }>
                    <img src={ iconMenu } alt="" />
                </Button>
                <img className="logo" src={ logo } alt="" />
                <section className="profile-sec">
                    <Avatar />
                </section>
            </header>
        );
    }
}

export default withRouter(connect(state => ({}))(Header));
