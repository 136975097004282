import {
    STATUS_DISABLE,
    STATUS_DRAFT,
    STATUS_PUBLISH,
    STATUS_LIVE,
    STATUS_EXPIRY,
    TYPE_TIME,
    TYPE_PRICE,
    TYPE_COUPON_SINGLE,
    TYPE_COUPON_BULK,
    TYPE_RANDOM_USER,
    TYPE_RANDOM_UPPER_ENG,
    TYPE_RANDOM_UPPER_EXCLUSIVE,
    TYPE_RANDOM_DIGIT,
    TYPE_RANDOM_DIGIT_EXCLUSIVE,
    TYPE_RANDOM_MIXED,
    PROMOTE_TYPE_PROMOTION_EVENT,
    PROMOTE_TYPE_MISSION,
    ERROR_CODE_0,
    ERROR_CODE_1,
    ERROR_CODE_2,
    ERROR_CODE_3,
    ERROR_CODE_4,
    ERROR_CODE_5,
    ERROR_CODE_6,
    ERROR_CODE_7,
    ERROR_CODE_8,
    ERROR_CODE_9,
    ERROR_CODE_10,
    ERROR_CODE_11,
    ERROR_CODE_12,
    ERROR_CODE_13,
    ERROR_CODE_14,
    ERROR_CODE_15,
    ERROR_CODE_16,
    ERROR_CODE_17,
    ERROR_CODE_18,
    ERROR_CODE_19,
    ERROR_CODE_20,
    ERROR_CODE_21,
    ERROR_CODE_22,
    ERROR_CODE_23,
    BULK_RESULT_CODE_SUCCESS,
    BULK_RESULT_CODE_ERROR,
    BULK_RESULT_CODE_DUPLICATE,
} from 'constants/coupon';

const coupon = {
    'document_title': '折扣碼',
    'new_document_title': '新增折扣碼',
    'new_system_coupon_title': '新增系統折扣',
    [`status_${ STATUS_DISABLE }`]: '停用',
    [`status_${ STATUS_DRAFT }`]: '草案',
    [`status_${ STATUS_PUBLISH }`]: '已發佈',
    [`status_${ STATUS_LIVE }`]: '上線',
    [`status_${ STATUS_EXPIRY }`]: '過期',
    [`type_${ TYPE_TIME }`]: '折時',
    [`type_${ TYPE_PRICE }`]: '折價',
    [`type_coupon_${ TYPE_COUPON_SINGLE }`]: '單一',
    [`type_coupon_${ TYPE_COUPON_BULK }`]: '批次',
    [`type_random_${ TYPE_RANDOM_USER }`]: '使用者定義',
    [`type_random_${ TYPE_RANDOM_UPPER_ENG }`]: '大寫英文字母 [A-Z]',
    [`type_random_${ TYPE_RANDOM_UPPER_EXCLUSIVE }`]: '大寫英文字母 [A-Z] 排除 [O, I]',
    [`type_random_${ TYPE_RANDOM_DIGIT }`]: '數字 [0-9]',
    [`type_random_${ TYPE_RANDOM_DIGIT_EXCLUSIVE }`]: '數字 [0-9] 排除 [0, 1]',
    [`type_random_${ TYPE_RANDOM_MIXED }`]:
    '混合大寫英文字母 [A-Z] 和數字 [0-9] 排除 [0, O, 1, I]',
    'promote_type': '促銷類型',
    [`promote_type_${ PROMOTE_TYPE_PROMOTION_EVENT }`]: '促銷活動',
    [`promote_type_${ PROMOTE_TYPE_MISSION }`]: '任務',
    'new_coupon': '新增折扣碼',
    'coupon_no': '編號',
    'title': '標題',
    'status': '狀態',
    'type': '類型',
    'coupon_type': '折扣碼類型',
    'random_type': '隨機碼類型',
    'discount': '折扣',
    [`discount_${ TYPE_TIME }`]: '分鐘',
    [`discount_${ TYPE_PRICE }`]: 'NTD',
    'valid_from': '有效開始時間',
    'valid_to': '有效結束時間',
    'valid_duration': '有效天數',
    'remaining_amount': '剩餘數量',
    'last_updated': '最後更新時間',
    'timetable': '使用期間',
    'details': '詳細資料',
    'description': '描述',
    'plans': '支援資費方案',
    'new_user_only': '限定新用戶使用',
    'number_of_people': '總人數',
    'number_of_event': '批次產生張數',
    'number_per_person': '每人可用張數',
    'redeem_type': '兌換方式',
    'redeem_from': '兌換起日',
    'redeem_to': '兌換迄日',
    'coupon_code': '折扣碼',
    'save': '儲存',
    'redeem_type_duration': '天數',
    'redeem_type_period': '期間',
    'days': '天',
    'total_coupons': '總Coupon數',
    'total_cost': '總成本',
    'invalid_date': '錯誤日期格式',
    'wrong_sequence_date': '迄時需在起日之後',
    'wrong_redeem_dates': '兌換日需在有效迄日前',
    'wrong_api_call': '哦哦，好像出了什麼問題',
    'publish': '發佈',
    'withdraw': '撤回',
    'convert_time': '兌換期限',
    'search_box_placeholder': '輸入折扣碼或標題',
    'published_by': '發佈人',
    'published_time': '發佈時間',
    'bulk_processing': '批次作業處理中',
    'bulk_pending': '批次作業等待中',
    [`bulk_result_status_${BULK_RESULT_CODE_SUCCESS}`]: '批次作業成功',
    [`bulk_result_status_${BULK_RESULT_CODE_ERROR}`]: '批次作業失敗',
    [`bulk_result_status_${BULK_RESULT_CODE_DUPLICATE}`]: '批次作業失敗 - 重複折扣碼',
    [`${ERROR_CODE_0}_error_message`]: '折扣碼太短(至少大於5)',
    [`${ERROR_CODE_1}_error_message`]: '折扣碼已存在',
    [`${ERROR_CODE_2}_error_message`]: '非有效兌換起/迄日',
    [`${ERROR_CODE_3}_error_message`]: '非有效開始/結束時間',
    [`${ERROR_CODE_4}_error_message`]: '兌換起日和有效開始時間衝突',
    [`${ERROR_CODE_5}_error_message`]: '非有效總張數',
    [`${ERROR_CODE_6}_error_message`]: '非有效兌換張數',
    [`${ERROR_CODE_7}_error_message`]: '標題錯誤',
    [`${ERROR_CODE_8}_error_message`]: '描述錯誤',
    [`${ERROR_CODE_9}_error_message`]: '折扣類型錯誤',
    [`${ERROR_CODE_10}_error_message`]: '折扣值錯誤',
    [`${ERROR_CODE_11}_error_message`]: '無法變更狀態',
    [`${ERROR_CODE_12}_error_message`]: '',
    [`${ERROR_CODE_13}_error_message`]: '',
    [`${ERROR_CODE_14}_error_message`]: '折扣碼類型錯誤',
    [`${ERROR_CODE_15}_error_message`]: '隨機碼長度錯誤',
    [`${ERROR_CODE_16}_error_message`]: '隨機碼類型錯誤',
    [`${ERROR_CODE_17}_error_message`]: '折扣卷張數錯誤',
    [`${ERROR_CODE_18}_error_message`]: '折扣碼前綴詞與隨機碼長度總和太短',
    [`${ERROR_CODE_19}_error_message`]: '批次折扣碼狀態為等待中或處理中',
    [`${ERROR_CODE_20}_error_message`]: '單一折扣碼包含前綴詞或批次折扣碼包含coupon_code',
    [`${ERROR_CODE_21}_error_message`]: '總張數超過隨機折扣碼可提供之張數',
    [`${ERROR_CODE_22}_error_message`]: '無法變更狀態，折扣已與促銷活動綁定',
    [`${ERROR_CODE_23}_error_message`]: '錯誤的 coupon_type',
};

export default coupon;
