import { createFleetApiInstanceWithToken } from '../util';

const cities = {
    fetchCities: acceptLang => {
        return createFleetApiInstanceWithToken({
            'Accept-Language': acceptLang
        }).get('/cities');
    },
};

export default cities;
