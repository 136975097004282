import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, FormGroup, Label } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import Button from 'components/Form/Button';
import CollapseBanner from 'components/CollapseBanner';
import Modal from 'components/Modal';
import {
    toggleErrorDialog
} from 'actions';
import AuthFeature from 'components/AuthFeature';
import { AUTH_VIEW_SCOOTER_DETAIL } from 'constants/permission';
import { ECU_TYPE_DEFAULT, CONTROL_UNIT_BCU } from 'constants/scooter';
import Blocker from 'components/Blocker';
import RingSpinner from 'components/Spinner/Ring';

import {
    useLazyGetFirmwareVersionQuery,
} from 'redux/hook/useScooter';


import './firmware-version.scss';

const FirmwareVersion = ({
    id,
    plate,
    ecuType,
}) => {
    const dispatch = useDispatch();
    const i18n = useSelector(state => state.i18n);
    const asyncStart = useSelector(state => (state.asyncHandler && state.asyncHandler.get('asyncStart')));
    const [openDialog, setOpenDialog] = useState(false)
    const [warning, setWarning] = useState([]);
    const spinnerStyle = classNames({
        'spinner-container': true,
        hide: !asyncStart,
    });
    const [ getFirmwareVersion, result ] = useLazyGetFirmwareVersionQuery();
    const { firmware_version, lack_features, gtu_firmware_version } = result?.data ?? {};


    const handleOpenDialog = async() => {
        setOpenDialog(true);
        const { error } = await getFirmwareVersion({ id });
        if (error?.status === 409) {
            setOpenDialog(false);
            dispatch(
                toggleErrorDialog(I18n.t(
                    `scooter.firmware_${ error.response.data.code }_error_message`
                ))
            );
        }

    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    useEffect(() => { }, [i18n]);

    useEffect(() => {
        setWarning(lack_features ? lack_features.map(({ message }) => message) : []);
    }, [lack_features]);

    return (
        <AuthFeature requiredList={ [AUTH_VIEW_SCOOTER_DETAIL] }>
            <Button
                color="outline-primary"
                type="button"
                onClick={ handleOpenDialog }
                disabled={ !id || ecuType === CONTROL_UNIT_BCU }
            >
                <Translate value="scooter.firmware_check" />
            </Button>
            {
                openDialog && (
                    <Modal
                        className="firmware-version"
                        title={ I18n.t('scooter.firmware_info') }
                        onClose={ handleCloseDialog }
                    >
                        {
                            (lack_features && lack_features.length !== 0) && (
                                <Translate
                                    className="error"
                                    value={ `警示: ${warning.join(';')}` }
                                    tag="span"
                                />
                            )
                        }
                        <CollapseBanner
                                className="firmware-version-info"
                                captionKey={ plate }
                                open={ true }
                            >
                                <FormGroup className="firmware-info-group">
                                    {
                                        (firmware_version || gtu_firmware_version) && (
                                            <>
                                                {
                                                    firmware_version && (
                                                        <FormGroup row>
                                                            <Label sm={ 2 }>
                                                                <Translate
                                                                    value="scooter.firmware_version"
                                                                    className="field"
                                                                />
                                                            </Label>
                                                            <Col sm={ 10 } className="data">
                                                                { firmware_version }
                                                            </Col>
                                                        </FormGroup>
                                                    )
                                                }
                                                {
                                                    gtu_firmware_version && (
                                                        <FormGroup row>
                                                            <Label sm={ 2 }>
                                                                <Translate
                                                                    value="scooter.gtu_version"
                                                                    className="field"
                                                                />
                                                            </Label>
                                                            <Col
                                                                sm={ 10 }
                                                                className="data"
                                                            >
                                                                { gtu_firmware_version }
                                                            </Col>
                                                        </FormGroup>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                </FormGroup>
                            </CollapseBanner>
                            <Blocker className={ spinnerStyle }>
                                <RingSpinner />
                            </Blocker>
                    </Modal>
                )
            }
        </AuthFeature>
    );
};

FirmwareVersion.propTypes = {
    id: PropTypes.string,
    plate: PropTypes.string,
    ecuType: PropTypes.number,
};

FirmwareVersion.defaultProps = {
    id: undefined,
    plate: undefined,
    ecuType: ECU_TYPE_DEFAULT,
};

export default FirmwareVersion;

