import { createFleetApiInstance, getAPIkey } from '../util';

const ad = {
    fetchImgUploadUrl: id => {
        const path = id !== undefined ? `/ads_banners/${id}/image_upload_url` : '/ads_banners/image_upload_url';
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get(path);
    },

    fetchAdList: (payload = {}) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get('/ads_banners', payload);
    },

    createSingleAd: payload => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).post('/ads_banners', payload);
    },

    fetchSingleAd: id => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get(`/ads_banners/${id}`);
    },

    updateSingleAd: (id, payload) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).patch(`/ads_banners/${id}`, payload);
    },

    updateAdOrder: payload => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).patch('/ads_banners/orders', payload);
    }
};

export default ad;
