import { createFleetApiInstanceWithToken } from '../util';

const violation = {
    importViolationList: (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return createFleetApiInstanceWithToken().post('/violate_rentals', formData);
    },
    getViolationDetail: (id) => {
        return createFleetApiInstanceWithToken().get(`/violate_rentals/${id}/transfer`);
    }
};

export default violation;
