/**
 * Reduder - Customer
 */
import { Map, List } from 'immutable';
import {
    FETCH_CUSTOMERS,
    CLEAR_CUSTOMERS,
    FETCH_CUSTOMER_PROFILE,
    UPDATE_CUSTOMER_PROFILE,
    CLEAR_CUSTOMER_PROFILE,
    FETCH_CUSTOMER_RENTAL_HISTORY,
    CLEAR_CUSTOMER_RENTAL_HISTORY,
    FETCH_CUSTOMER_COUPON,
    CLEAR_CUSTOMER_COUPON,
    FETCH_COMPENSATION_COUPON,
    UPDATE_CUSTOMER_COUPON,
    FETCH_CUSTOMER_MISSION,
    FETCH_CUSTOMER_PURCHASE_HISTORY,
    TOGGLE_PURCHASE_REFUND_MODAL,
    PURCHASE_REFUND,
    FETCH_PAYMENT_REQUEST_HISTORY,
    FETCH_GSH_PASS_HISTORY,
    TOGGLE_GSHPASS_REFUND_MODAL,
} from 'constants/action-type';
import creater from './util/creater';

const initialState = Map({
    list: Map({
        page_index: 1,
        page_count: 0,
        data_list: List([]),
    }),
    profile: {},
    history: Map({
        data_list: List([]),
        page_index: 1,
        page_count: 0,
    }),
    coupon: Map({
        data_list: List([]),
    }),
    compensationList: List([]),
    mission: Map({
        data_list: List([]),
    }),
    purchase: Map({
        data_list: List([]),
    }),
    showPurchaseModal: false,
    refundItem: {
        user_gov_addi_purchase_id: '',
        price: 0,
    },
    purchaseDetail: {},
    paymentHistory: Map({
        data_list: List([]),
        page_index: 1,
        page_count: 0,
    }),
    GSHPassHistory: Map({
        data_list: List([]),
        page_index: 1,
        page_count: 0,
    }),
    showPassRefundModal: false,
    refundPassItem: {
        payment_channel: 0,
        user_pass_id: '',
        price: 0,
    }
});
const setProfile = (state, data) => {
    let profile;

    if (data) {
        profile = {
            ...state.get('profile'),
            [data.id]: Map(data)
        };
    }
    else {
        profile = {};
    }
    return state.merge({
        profile: profile
    });
};

const setDetail = (state, data) => {
    let detail;

    if (data) {
        detail = {
            ...state.get('purchaseDetail'),
            [data.user_gov_addi_purchase_id]: Map(data)
        };
    }
    else {
        detail = {};
    }
    return state.merge({
        purchaseDetail: detail
    });
};

const actionsMap = {
    [FETCH_CUSTOMERS]: (state, action) => {
        return state.merge({
            list: Map(action.data),
        });
    },
    [CLEAR_CUSTOMERS]: state => {
        return state.merge({
            list: initialState.get('list'),
        });
    },
    [FETCH_CUSTOMER_PROFILE]: (state, action) => setProfile(state, action.data),
    [UPDATE_CUSTOMER_PROFILE]: (state, action) => setProfile(state, action.data),
    [CLEAR_CUSTOMER_PROFILE]: state => setProfile(state),
    [FETCH_CUSTOMER_RENTAL_HISTORY]: (state, action) => {
        const { data_list, page_index, page_count } = action.data;
        return state.merge({
            history: Map({
                data_list: List(data_list),
                page_index,
                page_count,
            }),
        });
    },
    [CLEAR_CUSTOMER_RENTAL_HISTORY]: state => {
        return state.merge({
            history: initialState.get('history'),
        });
    },
    [FETCH_CUSTOMER_COUPON]: (state, action) => {
        let { data_list } = action.data;
        data_list = data_list.map((item, i) => ({
            id: item.coupon_id + i,
            ...item,
        }));

        return state.merge({
            coupon: Map({
                ...action.data,
                data_list: List(data_list),
            }),
        });
    },
    [CLEAR_CUSTOMER_COUPON]: state => {
        return state.merge({
            coupon: initialState.get('coupon'),
        });
    },
    [FETCH_COMPENSATION_COUPON]: (state, action) => {
        return state.merge({
            compensationList: List(action.data)
        });
    },
    [UPDATE_CUSTOMER_COUPON]: state => {
        return state.merge({
            coupon: initialState.get('coupon'),
        });
    },

    [FETCH_CUSTOMER_MISSION]: (state, action) => {
        const { data_list, page_index, page_count } = action.data;

        return state.merge({
            mission: Map({
                data_list: List(data_list),
                page_index,
                page_count,
            }),
        });
    },

    [FETCH_CUSTOMER_PURCHASE_HISTORY]: (state, action) => {
        const { data_list, page_index, page_count } = action.data;
        return state.merge({
            purchase: Map({
                data_list: List(data_list),
                page_index,
                page_count,
            }),
        });
    },

    [TOGGLE_PURCHASE_REFUND_MODAL]: (state, action) => {
        const showPurchaseModal = !state.get('showPurchaseModal');
        return state.merge({
            showPurchaseModal,
            refundItem: action.data
        });
    },

    [PURCHASE_REFUND]: (state, action) => setDetail(state, action.data),

    [FETCH_PAYMENT_REQUEST_HISTORY]: (state, action) => {
        const { data_list, page_index, page_count } = action.data;
        return state.merge({
            paymentHistory: Map({
                data_list: List(data_list),
                page_index,
                page_count,
            }),
        });
    },
    [FETCH_GSH_PASS_HISTORY]: (state, action) => {
        const { data_list, page_index, page_count } = action.data;
        return state.merge({
            GSHPassHistory: Map({
                data_list: List(data_list),
                page_index,
                page_count,
            }),
        });
    },

    [TOGGLE_GSHPASS_REFUND_MODAL]: (state, action) => {
        const showPassRefundModal = !state.get('showPassRefundModal');
        return state.merge({
            showPassRefundModal,
            refundPassItem: action.data
        });
    },
};

export default creater(actionsMap, initialState);
