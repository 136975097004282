import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './form-group.scss';

const FormGroup = ({ children, title, className, withBadge }) => {
    const groupClass = classNames({
        'form-group-section': true,
        [className]: !!className,
    });

    return (
        <section className={ groupClass }>
            <h2 className="group-title">{ title }</h2>
            { withBadge ? withBadge : null}
            { children }
        </section>
    );
};
FormGroup.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    withBadge: PropTypes.node,
};
FormGroup.defaultProps = {
    className: '',
    withBadge: null,
};

export default FormGroup;
