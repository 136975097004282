import {
    MY_TICKET,
    SCOOTER_LIST,
    SCOOTER_MAP,
    SCOOTER_SEARCH,
    RENTAL_MANAGEMENT,
    LOGIN,
    COUPON_HOME,
    SYSTEM_COUPON,
    AD_MANAGEMENT,
    CUSTOMER_FINDER,
    LOG_RENTAL,
    TICKET_LIST,
    TICKET_ASSIGNMENT,
    ADMIN,
    PROMOTION_MANAGEMENT,
    MISSION_MANAGEMENT,
    ASSET_MANAGEMENT,
    DASHBOARD,
    TV_MONITOR,
    REGULAR_MAINTENANCE,
    ADDITIONAL_FEE,
} from 'constants/routes';
import {
    GROUP_OPERATION,
    GROUP_CUSTOMER,
    GROUP_MARKETING,
    GROUP_AUDIT,
    GROUP_TICKET,
    GROUP_ADMIN,
    GROUP_PROMOTION,
    GROUP_TRAFFIC,
    GROUP_DASHBOARD,
    GROUP_TV,
} from 'constants/routes-with-auth';

const routes = {
    [MY_TICKET]: 'My Tickets',
    [SCOOTER_LIST]: 'Scooter Management',
    [SCOOTER_MAP]: 'Scooter Map',
    [SCOOTER_SEARCH]: 'Scooter Finder',
    [RENTAL_MANAGEMENT]: 'Rental Management',
    [LOGIN]: 'Login',
    [COUPON_HOME]: 'Coupon',
    [SYSTEM_COUPON]: 'System Coupon',
    [AD_MANAGEMENT]: 'Ad',
    [CUSTOMER_FINDER]: 'Customer Finder',
    [LOG_RENTAL]: 'Rental Log',
    [TICKET_LIST]: 'Ticket Management',
    [TICKET_ASSIGNMENT]: 'Ticket Assignment',
    [ADMIN]: 'User Management',
    [PROMOTION_MANAGEMENT]: 'Promotion Management',
    [ADDITIONAL_FEE]: 'Additional Fee',
    [MISSION_MANAGEMENT]: 'Mission Management',
    [ASSET_MANAGEMENT]: 'Asset Management',
    [DASHBOARD]: 'Overview',
    [TV_MONITOR]: 'Real-time Monitoring',
    [REGULAR_MAINTENANCE]: 'Regular Maintenance',
    [`category_${ GROUP_OPERATION }`]: 'Operation',
    [`category_${ GROUP_DASHBOARD }`]: 'Dashboard',
    [`category_${ GROUP_TV }`]: 'Monitoring',
    [`category_${ GROUP_CUSTOMER }`]: 'Customer Service',
    [`category_${ GROUP_MARKETING }`]: 'Marketing',
    [`category_${ GROUP_AUDIT }`]: 'Audit',
    [`category_${ GROUP_TICKET }`]: 'Ticket System',
    [`category_${ GROUP_ADMIN }`]: 'Administrator',
    [`category_${ GROUP_PROMOTION }`]: 'Promotion',
    [`category_${ GROUP_TRAFFIC }`]: 'Additional Fee Management',
};
export default routes;
