export const headerConfig = [{
    name: 'title',
    value: 'coupon.title',
    sortable: true,
    width: 285,
}, {
    name: 'coupon_code',
    value: 'coupon.coupon_code',
    sortable: true,
    width: 225,
}, {
    name: 'status',
    value: 'coupon.status',
    sortable: true,
    width: 109,
}, {
    name: 'discount_type',
    value: 'coupon.type',
    sortable: true,
    width: 109,
}, {
    name: 'discount_value',
    value: 'coupon.discount',
    sortable: true,
    width: 139,
}, {
    name: 'convert_from',
    value: 'coupon.redeem_from',
    sortable: true,
    width: 254,
}, {
    name: 'convert_to',
    value: 'coupon.redeem_to',
    sortable: true,
    width: 254,
}, {
    name: 'remaining_quota',
    value: 'coupon.remaining_amount',
    sortable: true,
    width: 163,
}];
