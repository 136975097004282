import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import Datetime from 'components/Datetime';
import NavigationBar from 'components/NavigationBar';
import ErrorMessage from 'components/ErrorMessage';
import serialize from 'form-serialize';
import Form from 'components/Form';
import { convertToUTC } from 'helpers/time-handler';
import {
    TYPE_TEXT,
    TYPE_DATETIME,
    TYPE_NUMBER,
    TYPE_TEXTAREA,
    TYPE_SELECT,
} from 'components/Form/Input/input-type';
import {
    CLAIM_TYPE_NO_CLAIM,
    CLAIM_TYPE_CLAIM,
    NO_CLAIM_FEE_EDIT_STATUS_GROUP,
    FEE_STATUS_PROCESSING,
    VIOLATE_ZONE_GROUP,
    FEE_STATUS_FAILED,
    FEE_STATUS_PAID,
} from 'constants/additional-fee';
import { PAYMENT_CHANNEL_TAPPAY } from 'constants/rental';
import Input from 'components/Form/Input';
import FormGroup from 'components/Form/FormGroup';
import FlexGroup from 'components/FlexGroup';
import { Card, CardBody } from 'reactstrap';
import AuthView from 'components/AuthView';
import RentalPhotoAttachment from 'components/RentalPhotoAttachment';
import { Refresh } from 'components/Pagination';
import ControlTemplate  from './ControlTemplate';
import FeeFunctionGroup  from './FeeFunctionGroup';
import ClaimType from 'components/AdditionalFeeTableList/ClaimType';
import FeeType from 'components/AdditionalFeeTableList/FeeType';
import Subtype from 'components/AdditionalFeeTableList/Subtype';
import ViolateZone from 'components/AdditionalFeeTableList/ViolateZone';
import AdditionalFeeStatus from 'components/AdditionalFeeTableList/AdditionalFeeStatus';
import UploadAttachments, { initAttachments, makeupAttachments } from 'components/UploadAttachments';
import { ATTACHMENT_MAX_COUNT } from 'view/AdditionalFee/NewFee';
import { I18n } from 'react-redux-i18n';
import { updateDocumentTitle, toggleErrorDialog } from 'actions';
import {
    useGetAdditionalFeeDetailQuery,
    useUpdateAdditionalFeeDetailMutation,
    useRefundAdditionalFeeMutation,
    useGetFeeAttachmentsMutation,
} from 'redux/hook/useAdditionalFee';
import ViolateRental from '../AdditionalFee/ViolateRental';
import { DEFAULT_VIOLATE_ZONE } from '../AdditionalFee/NewFee';

import './additional-fee-detail.scss';

const COMMENTS = 'comments';
const STATUS = 'status';
const VIOLATE_ZONE = 'violate_zone';


const AdditionalFeeDetail = () => {
    const dispatch = useDispatch();

    const i18n = useSelector(state => state.i18n);
    const { expenseId } = useParams();
    const {
        data: feeDetail,
        error: feeDetailError,
        refetch: getAdditionalFeeDetail,
    } = useGetAdditionalFeeDetailQuery(expenseId);
    const [updateAdditionalFeeDetail] = useUpdateAdditionalFeeDetailMutation();
    const [refundAdditionalFee] = useRefundAdditionalFeeMutation();
    const [getFeeAttachments] = useGetFeeAttachmentsMutation();
    const formRef = useRef();
    const [isEdit, setIsEdit] = useState(false);
    const [comments, setComments] = useState();
    const [status, setStatus] = useState();
    const [violateZone, setViolateZone] = useState();
    const [attachments, setAttachments] = useState(initAttachments(ATTACHMENT_MAX_COUNT));

    const isAllowEditAdvanced = (isEdit && NO_CLAIM_FEE_EDIT_STATUS_GROUP.indexOf(feeDetail?.status) !== -1);

    useEffect(() => {
        dispatch(updateDocumentTitle('additional_fee.fee_detail_title'));
    }, [dispatch, i18n]);

    const handleFormChange = e => {
        const { name, value } = e.target;
        switch (name) {
            case COMMENTS:
                setComments(value);
                break;
            case STATUS:
                setStatus(value);
                break;
            case VIOLATE_ZONE:
                setViolateZone(value);
                break;
            default:
        }
    };

    const handleFeeUpdate = async (params) => {
        try {
            const { error } = await updateAdditionalFeeDetail(params);
            if (error) {
                throw error;
            }
            else {
                getAdditionalFeeDetail(expenseId);
                setIsEdit(false);
            }
        }
        catch (error) {
            const { code } = error?.data || {};

            const message = (code !== undefined && code > 0) ? I18n.t(`additional_fee.create_error_message_${ code }`) : I18n.t('general_error_message');

            dispatch(toggleErrorDialog(message));
        }

    };
    const handleFeeRefund = async (params) => {
        try {
            const { error } = await refundAdditionalFee(params);
            if (error) {
                throw error;
            }
            else {
                getAdditionalFeeDetail(expenseId);
            }
        }
        catch (error) {
            const { code } = error?.data || {};

            const message = (code !== undefined) ? I18n.t(`rental.refund_failed`, { code }) : I18n.t('general_error_message');

            dispatch(toggleErrorDialog(message));
        }
    };

    const handleFeeEdit = () => {
        const isAllowNoClaimChangeTags = NO_CLAIM_FEE_EDIT_STATUS_GROUP.indexOf(feeDetail.status) === -1;
        const formData = serialize(formRef.current, { hash: true });
        let params={
            expense_id: expenseId,
            comments,
        };
        if (!isAllowNoClaimChangeTags) {
            params={
                ...params,
                status: Number(status),
                violate_time: convertToUTC(formData.violate_time) || undefined,
                violate_zone: Number(violateZone),
            }
        }
        if (feeDetail.status === FEE_STATUS_PROCESSING &&
            !feeDetail.rental_id) {
                params={
                    ...params,
                    plate_no: feeDetail.plate_no,
                    rental_id: formData.rental_id,
                };
        }

        return handleFeeUpdate(params);
    };
    const getFeeStatusOpts = () => {
        return NO_CLAIM_FEE_EDIT_STATUS_GROUP.map(value => ({
            value: value,
            name: I18n.t(`additional_fee.fee_status_map.${ value }`),
        }));
    };

    const getViolateZoneOpts = () => {
        return ([
            DEFAULT_VIOLATE_ZONE,
            ...VIOLATE_ZONE_GROUP,
        ].map(value => {
            return value !== DEFAULT_VIOLATE_ZONE ? {
                name: I18n.t(`additional_fee.violate_zone_map.${ value }`),
                value,
            } : {
                name: '',
                value: DEFAULT_VIOLATE_ZONE,
            }
        }));
    };

    const reloadAttachments = async () => {
        const { error, data } = await getFeeAttachments({ id: expenseId });
        if (error) {
            dispatch(toggleErrorDialog( I18n.t('general_error_message')));
            return;
        }
        else {
            setAttachments(data);
        }
    };

    useEffect(() => {
        if (!isEdit) {
            setComments(feeDetail?.comments || '');
            setStatus(feeDetail?.status);
            setViolateZone(feeDetail?.violate_zone);
            setAttachments(makeupAttachments(ATTACHMENT_MAX_COUNT, feeDetail?.attachments));
        }
    }, [isEdit, feeDetail]);

    return (
        <AuthView className="additionalFeeDetail">
            {
                feeDetailError ?
                (<ErrorMessage />) : (
                    <>
                        <NavigationBar title={ expenseId } sticky>
                            <FeeFunctionGroup
                                isEdit={ isEdit }
                                feeDetail={ feeDetail }
                                onUpdate={ handleFeeUpdate }
                                onRefund={ handleFeeRefund }
                                onEditUpdate={ handleFeeEdit }
                                onChangEdit={ (status) => setIsEdit(status) }
                            />
                        </NavigationBar>
                        <section className="detail-block">
                            <FlexGroup end>
                                {
                                    isEdit ? null : (
                                        <Refresh
                                            time={ moment(feeDetail?.updatedTime) }
                                            onClick={ () => { getAdditionalFeeDetail(expenseId) } }
                                        />
                                    )
                                }
                            </FlexGroup>
                        </section>
                        <Form
                            innerRef={ formRef }
                            className="detail-form"
                            inline
                            onChange={ handleFormChange }
                            stopSubmitByEnter
                        >
                            <FlexGroup className="cards" alignStart>

                                <Card className="column">
                                    <CardBody>
                                        <FormGroup className="fee-detail" title={ I18n.t('additional_fee.fee_info') }>
                                            <Input
                                                type={ TYPE_DATETIME }
                                                caption="customer.create_time"
                                                value={ feeDetail?.create_time }
                                                viewMode
                                            />
                                            <ControlTemplate caption="additional_fee.claim_type">
                                                <ClaimType type={ feeDetail?.claim_type } badge />
                                            </ControlTemplate>
                                            <ControlTemplate caption="additional_fee.fee_type">
                                                <FeeType type={ feeDetail?.fee_type } />
                                            </ControlTemplate>
                                            <ControlTemplate caption="additional_fee.subtype">
                                                <Subtype type={ feeDetail?.subtype } />
                                            </ControlTemplate>
                                            {
                                                isAllowEditAdvanced ? (
                                                    <Input
                                                        name={ STATUS }
                                                        type={ TYPE_SELECT }
                                                        caption="additional_fee.status"
                                                        value={ getFeeStatusOpts() }
                                                        selected={ status }
                                                        required={ isAllowEditAdvanced }
                                                    />
                                                ) : (
                                                    <ControlTemplate caption="additional_fee.status">
                                                        <AdditionalFeeStatus type={ feeDetail?.status } badge />
                                                        {
                                                            feeDetail?.claim_type === CLAIM_TYPE_CLAIM ? (
                                                                <>
                                                                    {
                                                                         (feeDetail?.status === FEE_STATUS_PAID ||  feeDetail?.status === FEE_STATUS_FAILED) ? (
                                                                            <span className="status-related-time">
                                                                                <Datetime time={ feeDetail?.status === FEE_STATUS_PAID ? (feeDetail?.pay_time) : (feeDetail?.pay_failed_time) } />
                                                                            </span>
                                                                         ) : null
                                                                    }
                                                                </>
                                                            ) : null
                                                        }
                                                    </ControlTemplate>
                                                )
                                            }
                                            <Input
                                                type={ TYPE_TEXT }
                                                caption={ `rental.payment_channel.${ feeDetail?.payment_channel ?? PAYMENT_CHANNEL_TAPPAY }` }
                                                value={ feeDetail?.order_id }
                                                viewMode
                                            />
                                            <Input
                                                type={ TYPE_TEXT }
                                                caption="rental.invoice_no"
                                                value={ feeDetail?.invoice_no }
                                                viewMode
                                            />
                                            <Input
                                                type={ TYPE_NUMBER }
                                                caption="rental.price"
                                                value={ feeDetail?.amount }
                                                viewMode
                                            />
                                            {
                                                isEdit ? null : (
                                                    <Input
                                                        type={ TYPE_DATETIME }
                                                        caption="additional_fee.violate_time"
                                                        value={ feeDetail?.violate_time }
                                                        viewMode
                                                    />
                                                )
                                            }
                                            {
                                                isAllowEditAdvanced ? (
                                                    <Input
                                                        name={ VIOLATE_ZONE }
                                                        type={ TYPE_SELECT }
                                                        caption="additional_fee.violate_zone"
                                                        value={ getViolateZoneOpts() }
                                                        selected={ violateZone }
                                                        required={ isAllowEditAdvanced }
                                                    />
                                                ) : (
                                                    <ControlTemplate caption="additional_fee.violate_zone">
                                                        <ViolateZone type={ feeDetail?.violate_zone } />
                                                    </ControlTemplate>
                                                )
                                            }
                                            <Input
                                                type={ TYPE_TEXT }
                                                caption="additional_fee.ticket_no"
                                                value={ feeDetail?.ticket_no }
                                                viewMode
                                            />
                                            <Input
                                                type={ TYPE_TEXT }
                                                caption="additional_fee.zendesk_no"
                                                value={ feeDetail?.zendesk_no }
                                                viewMode
                                            />
                                        </FormGroup>
                                        <FormGroup
                                            className="attachment-wrapper"
                                            title={ I18n.t('attachments') }
                                            withBadge={ <span className="subtitle">{ I18n.t(`additional_fee.${ feeDetail?.claim_type === CLAIM_TYPE_NO_CLAIM ? 'attachments_interal' : 'attachments_external' }`)}</span>  }
                                        >
                                            <UploadAttachments
                                                viewMode
                                                list={ attachments }
                                                title={ file => file?.name }
                                                isUploaded
                                                onReload={ reloadAttachments }
                                            />
                                        </FormGroup>
                                        <FormGroup
                                            className="comment-wrapper"
                                            title={ I18n.t('additional_fee.comments') }
                                            withBadge={ <span className="subtitle">{ I18n.t('additional_fee.comments_ps')}</span>  }
                                        >
                                            <Input
                                                className="comments"
                                                name={ COMMENTS }
                                                type={ TYPE_TEXTAREA }
                                                rows={ 4 }
                                                value={ comments }
                                                withCaption={ false }
                                                viewMode={ !isEdit }
                                            />
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                                <Card className="column">
                                    <CardBody>
                                        {
                                            isAllowEditAdvanced ? (
                                                <FormGroup
                                                    className="search-rental"
                                                    title={ I18n.t('additional_fee.search_title') }
                                                >
                                                    <ViolateRental
                                                        claimType={ feeDetail?.claim_type }
                                                        isUpdate={ isAllowEditAdvanced }
                                                        limitedData={ feeDetail }
                                                    />
                                                </FormGroup>
                                            ) : (
                                                <FormGroup className="rental-detail" title={ I18n.t('additional_fee.information') }>
                                                    <Input
                                                        type={ TYPE_TEXT }
                                                        caption="rental.rental_no"
                                                        value={ feeDetail?.rental_id || '--' }
                                                        viewMode
                                                    />
                                                    <Input
                                                        type={ TYPE_TEXT }
                                                        caption="customer.customer_no"
                                                        value={ feeDetail?.customer_id || '--' }
                                                        viewMode
                                                    />
                                                    <Input
                                                        type={ TYPE_TEXT }
                                                        caption="scooter.plate"
                                                        value={ feeDetail?.plate_no || '--' }
                                                        viewMode
                                                    />
                                                    <Input
                                                        type={ TYPE_DATETIME }
                                                        caption="start_time"
                                                        value={ feeDetail?.start_time }
                                                        viewMode
                                                    />
                                                    <Input
                                                        type={ TYPE_DATETIME }
                                                        caption="end_time"
                                                        value={ feeDetail?.end_time }
                                                        viewMode
                                                    />
                                                    <Input
                                                        type={ TYPE_TEXT }
                                                        caption="rental.photo"
                                                        value={ feeDetail?.return_photo_url ? (
                                                            <RentalPhotoAttachment url={ feeDetail?.return_photo_url } />
                                                        ) : '--'  }
                                                        viewMode
                                                    />
                                                </FormGroup>
                                            )
                                        }

                                    </CardBody>
                                </Card>
                            </FlexGroup>
                        </Form>
                    </>
                )
            }

        </AuthView>
    );
};

export default AdditionalFeeDetail;
