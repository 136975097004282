import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import Datetime from 'components/Datetime';
import Button from 'components/Form/Button';
import Cell from 'components/ListView/Cell';
import { togglePurchaseRefundModal } from 'actions';
import { PURCHASE_ACTIVE, PURCHASE_INCOMING } from 'constants/customer';

class ListRow extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.shape({}).isRequired,
    }

    handleKeyPress = e => {
        e.key === 'Enter' && e.preventDefault();
    };

    handleRefund = (data) => {
        const { id: user_gov_addi_purchase_id, price } = data;
        const { dispatch } = this.props;
        dispatch(togglePurchaseRefundModal({ user_gov_addi_purchase_id, price }));
    }

    renderField({ data, name, width }) {
        let el;
        switch (name) {
        case 'discount_value':
            el = <Translate value="customer.discount_content" count={ data[name] } />;
            break;
        case 'state':
            el = <Translate value={ `customer.purchase_state_${ data.state }` } />;
            break;
        case 'pay_time':
        case 'valid_from':
        case 'valid_to':
            el = <Datetime time={ data[name] } />;
            break;
        case 'refund_amount':
            el = data.refund_amount ? data[name] : '--';
            break;
        case 'refund_btn':
            el = (data.state === PURCHASE_ACTIVE || data.state === PURCHASE_INCOMING) && (
                <div onKeyPress={ this.handleKeyPress }>
                    <Button
                        outline
                        type="button"
                        onClick={ () => this.handleRefund(data) }
                    >
                        <Translate value="rental.refund" />
                    </Button>
                </div>
            );
            break;
        default:
            el = data[name];
            break;
        }

        return (
            <Cell
                key={ `${ data.id }-${ name }` }
                name={ name }
                style={ {
                    width,
                } }
            >
                { el }
            </Cell>
        );
    }

    render() {
        const { columns, data } = this.props;

        return columns.map(({ key, width }, i) => {
            return this.renderField({ data, width, name: key, });
        });
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(ListRow);
