import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import AuthView from 'components/AuthView';
import FlexGroup from 'components/FlexGroup';
import { getDefaultPageSize } from 'components/Pagination/PageSizeSelect';
import { convertToUTC } from 'helpers/time-handler';
import CustomChoiceGroup, { SEARCH_BY_PLATE } from './Filter/ChoiceGroup';
import CustomMainTypeFilter from 'components/Ticket/MainType';
import CustomSubTypeFilter from 'components/Ticket/SubType';
import CustomStatusFilter from './Filter/Status';
import CustomPriorityFilter from './Filter/Priority';
import CustomAssigneeFilter from './Filter/Assignee';
import CustomTicketFlowFilter from './Filter/TicketFlow';
import CustomCreatedTimeFilter, { defaultRange } from './Filter/CreatedTime';
import VipScooterFilter from 'components/Filter/VipScooterFilter';
import {
    updateDocumentTitle,
    fetchTicketList,
    clearTicketList,
} from 'actions';
import List, {
    FILTER_PAGE_INDEX,
    FILTER_PAGE_SIZE,
    FILTER_SORT,
} from './List';
import {
    GROUP_STATUS_EXCLUDE_CLOSED,
} from 'constants/ticket';
import './ticket.scss';

const FILTER_KEYWORD = 'keyword';
const FILTER_KEYWORD_TYPE = 'keyword_type';
const FILTER_TICKET_MAIN_TYPE = 'tracker_id';
const FILTER_TICKET_SUB_TYPE = 'tracker_subtype_id';
const FILTER_STATUS = 'status_id';
const FILTER_PRIORITY = 'severity_id';
const FILTER_ASSIGNEE = 'assigned_to_id';
const FILTER_TICKET_FLOW = 'franchisee_author_assignee';
const FILTER_CREATE_TIME = 'create_time';
const FILTER_VIP_SCOOTER = 'service_id';

class TicketList extends Component {
    static propTypes = {
        defaultTicketFlowList: ImmutablePropTypes.listOf(PropTypes.shape({
            text: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        })).isRequired,
    }

    constructor(props) {
        super(props);

        this.updatedTime = Date.now();
        this.searchType = SEARCH_BY_PLATE;
        this.keyword = undefined;

        const { start } = this.getDefaultPeriod();

        this.state = {
            selectedIdList: [],
            queryPayload: {
                filter: {
                    [this.searchType]: undefined,
                    [FILTER_TICKET_MAIN_TYPE]: undefined,
                    [FILTER_TICKET_SUB_TYPE]: undefined,
                    [FILTER_STATUS]: undefined,
                    [FILTER_PRIORITY]: undefined,
                    [FILTER_ASSIGNEE]: undefined,
                    [FILTER_TICKET_FLOW]: undefined,
                    [FILTER_VIP_SCOOTER]: undefined,
                    [FILTER_CREATE_TIME]: undefined,
                    create_from: undefined,
                    craate_to: undefined,
                },
                [FILTER_PAGE_INDEX]: 1,
                [FILTER_PAGE_SIZE]: getDefaultPageSize().value,
                [FILTER_SORT]: {},
            },
            withDefaultStatusFilter: true,
            fromTime: moment(start),
            toTime: moment(),
        };
    }

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { pathname } = location;

        dispatch(updateDocumentTitle(`routes.${ pathname }`));
    }

    setKeyword = value => {
        this.keyword = value;
    }

    getDefaultPeriod = () => {
        return {
            start: moment().subtract(...defaultRange).format('YYYY-MM-DD'),
            end: moment().startOf('day').add(1, 'd'),
        };
    }

    handleQueryPayloadChanged = name => value => {
        const { queryPayload } = this.state;
        let { filter } = queryPayload;
        let withDefaultStatusFilter = true;
        // search keyword without default filter
        if (this.keyword) {
            withDefaultStatusFilter = false;
        }

        queryPayload[FILTER_PAGE_INDEX] = 1;

        switch (name) {
        case FILTER_TICKET_MAIN_TYPE:
        case FILTER_TICKET_SUB_TYPE:
        case FILTER_STATUS:
        case FILTER_PRIORITY:
        case FILTER_ASSIGNEE:
        case FILTER_VIP_SCOOTER:
        case FILTER_TICKET_FLOW:
            filter = {
                ...filter,
                [name]: value.inputSelect,
            };
            break;

        case FILTER_PAGE_INDEX:
            queryPayload[name] = value;
            break;

        case FILTER_PAGE_SIZE:
            queryPayload[name] = value.value;
            break;

        case FILTER_CREATE_TIME:
            const { from, to } = value;

            filter.create_from = (from ? convertToUTC(from) : undefined);
            filter.create_to = (to ? convertToUTC(to) : undefined);
            break;

        case FILTER_KEYWORD_TYPE:
        case FILTER_KEYWORD:
            filter = { [this.searchType]: this.keyword };
            break;
        case FILTER_SORT:
            let { field } = value;
            const { order } = value;

            if (field && order) {
                queryPayload[FILTER_SORT] = `${ field },${ order }`;
            }
            else {
                queryPayload[FILTER_SORT] = undefined;
            }
            break;
        default:
            console.warn('Not a defined parameter name', name, value);
            break;
        }

        let period = {};
        period = this.getDefaultPeriod();
        const { create_from, create_to } = filter;

        this.setState(prevState => ({
            selectedIdList: [],
            queryPayload: {
                ...prevState.queryPayload,
                filter,
            },
            withDefaultStatusFilter,
            fromTime: create_from ? undefined : period.start,
            toTime: create_to ? undefined : moment(),
        }), this.fetchData);

    }

    changeSearchType = e => {
        const { queryPayload } = this.state;
        const value = e.currentTarget.value;
        this.searchType = value;
        queryPayload.filter = {};

        if (this.keyword) {
            this.handleQueryPayloadChanged(FILTER_KEYWORD_TYPE)();
        }
    }

    fetchData = async () => {
        const { dispatch } = this.props;

        const { queryPayload, withDefaultStatusFilter } = this.state;
        const { filter, ...exceptFilter } = queryPayload;
        const {
            ticket_id,
            status_id,
            franchisee_author_assignee,
            create_from,
            create_to,
            ...rest
        } = filter;
        const updatedTicketId = ticket_id && Number(ticket_id);
        let statusFilter = status_id;

        // default status filter
        if (withDefaultStatusFilter &&
            (!status_id || status_id.length === 0)
        ) {
            statusFilter = GROUP_STATUS_EXCLUDE_CLOSED;
        }

        let ticketFlowFilter = franchisee_author_assignee;

        // default ticket flow filter
        if (!franchisee_author_assignee || franchisee_author_assignee.length === 0) {
            const { defaultTicketFlowList } = this.props;
            ticketFlowFilter = defaultTicketFlowList.toJS().map(ticketFlow => ticketFlow.value);
        }
        let period = this.getDefaultPeriod();

        const newPayload = {
            ticket_id: Number.isNaN(updatedTicketId) ? 0 : updatedTicketId,
            status_id: statusFilter,
            franchisee_author_assignee: ticketFlowFilter,
            create_from: convertToUTC(create_from || period.start),
            create_to: convertToUTC(create_to || period.end),
            ...rest,
            ...exceptFilter,
        };

        const res = await dispatch(fetchTicketList(newPayload));
        const { error } = res;

        if (error) {
            dispatch(clearTicketList());
        }

        this.updatedTime = Date.now();

        return res;
    }

    handleSelect = list => {
        this.setState({
            selectedIdList: list,
        });
    }

    render() {
        const { selectedIdList, queryPayload, fromTime, toTime } = this.state;
        const { filter } = queryPayload;

        return (
            <AuthView className="ticket-list">
                <CustomChoiceGroup
                    defaultChecked={ this.searchType }
                    onChangeType={ this.changeSearchType }
                    onSubmit={ this.handleQueryPayloadChanged(FILTER_KEYWORD) }
                    onChange={ this.setKeyword }
                    value={ this.keyword }
                >

                    <FlexGroup start className="filters">
                        <CustomMainTypeFilter
                            selected={ filter[FILTER_TICKET_MAIN_TYPE] }
                            onChange={ this.handleQueryPayloadChanged(FILTER_TICKET_MAIN_TYPE) }
                        />
                        <CustomSubTypeFilter
                            selected={ filter[FILTER_TICKET_SUB_TYPE] }
                            onChange={ this.handleQueryPayloadChanged(FILTER_TICKET_SUB_TYPE) }
                        />
                        <CustomStatusFilter
                            selected={ filter[FILTER_STATUS] }
                            onChange={ this.handleQueryPayloadChanged(FILTER_STATUS) }
                        />
                        <CustomPriorityFilter
                            selected={ filter[FILTER_PRIORITY] }
                            onChange={ this.handleQueryPayloadChanged(FILTER_PRIORITY) }
                        />
                        <CustomCreatedTimeFilter
                            onChange={ this.handleQueryPayloadChanged(FILTER_CREATE_TIME) }
                            defaultFrom={ filter.create_from }
                            defaultTo={ filter.create_to }
                        />
                        <CustomAssigneeFilter
                            selected={ filter[FILTER_ASSIGNEE] }
                            onChange={ this.handleQueryPayloadChanged(FILTER_ASSIGNEE) }
                        />
                        <CustomTicketFlowFilter
                            selected={ filter[FILTER_TICKET_FLOW] }
                            onChange={ this.handleQueryPayloadChanged(FILTER_TICKET_FLOW) }
                        />
                        <VipScooterFilter
                            defaultSelected={ filter[FILTER_VIP_SCOOTER] }
                            onChange={ this.handleQueryPayloadChanged(FILTER_VIP_SCOOTER) }
                        />
                    </FlexGroup>
                </CustomChoiceGroup>
                <List
                    pageSize={ queryPayload.size }
                    onFetch={ this.fetchData }
                    onSelect={ this.handleSelect }
                    onChange={ this.handleQueryPayloadChanged }
                    selectedIdList={ selectedIdList }
                    fromTime={ fromTime }
                    toTime={ toTime }
                />

            </AuthView>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    defaultTicketFlowList: state.ticket.get('defaultTicketFlowList'),
}))(TicketList);
