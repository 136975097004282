import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import FlexGroup from 'components/FlexGroup';
import {
    CITY_OTHERS,
    CITY_DOTS,
} from 'constants/scooter';
import {
    getServicesCaption,
} from 'helpers/dashboard';
import {
    reduceCities
} from 'helpers/util';
import Slider from 'react-slick';
import ServiceLevel from 'components/ServiceLevel/ServiceLevel';
import OverallServiceLevel from './OverallServiceLevel/OverallServiceLevel';
import OverallModel from './OverallModel/OverallModel';
import OverallTickets from './OverallTickets/OverallTickets';
import SliderArrow from './SliderArrow/SliderArrow';

import 'slick-carousel/slick/slick.css';

const SLIDER_CONFIG = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 30000,
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        }
    ],
    className: 'slider',
    prevArrow: <SliderArrow />,
    nextArrow: <SliderArrow />,
};


class Overall extends React.Component {
    static propTypes = {
        serviceLevelData: PropTypes.arrayOf(PropTypes.shape({})),
        overallSLData: PropTypes.shape({}),
        scooterModelsData: PropTypes.arrayOf(PropTypes.shape({})),
    };
    static defaultProps = {
        serviceLevelData: [],
        overallSLData: {},
        scooterModelsData: [],
    };

    getServicesCaptionString = (item) => {
        const { cities } = this.props;
        const citiesObject = reduceCities(cities.toJS());
        return getServicesCaption(item, citiesObject);
    }


    render() {
        const { serviceLevelData, overallSLData, scooterModelsData, cities } = this.props;
        const citiesObject = reduceCities(cities.toJS());
        return (
            <FlexGroup alignStart className="dashboard-overall">
                <div className="services-block">
                    <Slider { ...SLIDER_CONFIG }>
                        {
                            serviceLevelData && serviceLevelData.length ? (
                                serviceLevelData.map( item => (
                                    item.city_id !== CITY_OTHERS && item.city_id !== CITY_DOTS && (
                                        <ServiceLevel
                                            title={ this.getServicesCaptionString(item) }
                                            chartTitle="dashboard.service_level"
                                            data={ item }
                                            key={ `${item.service_type}${item.city_id}` }
                                        />)
                                ))
                            ) : null
                        }

                    </Slider>
                </div>
                <OverallTickets
                    title={ I18n.t('dashboard.tickets_status') }
                    data={ overallSLData.list }
                    listCaption={ item => getServicesCaption(item, citiesObject) }
                />
                <FlexGroup className="right-column">
                    <OverallServiceLevel
                        title={ I18n.t('dashboard.overall_service_level') }
                        data={ overallSLData }
                        listCaption={ item => getServicesCaption(item, citiesObject) }
                    />
                    <OverallModel
                        title={ I18n.t('dashboard.overall_scooter_model') }
                        data={ scooterModelsData }
                    />
                </FlexGroup>
            </FlexGroup>
        );
    }
}

export default connect(state => ({
    cities: state.cities.get('cities')
}))(Overall);
