export const FEE_TYPE_FINE = 0;
export const FEE_TYPE_DISPATCH = 1;
export const FEE_TYPE_REPAIR = 2;
export const FEE_TYPE_WITHHOLDING = 3;

export const PAYMENT_STATUS_UNPAID = 11;
export const PAYMENT_STATUS_PAID = 21;
export const PAYMENT_STATUS_REFUND = 31;
export const PAYMENT_STATUS_CANCEL = 41;
export const PAYMENT_STATUS_FAILED = 12;

export const ERROR_RENTAL_NOT_FOUND = 1;
export const ERROR_ZENDESK_NO_EMPTY = 2;
export const ERROR_FEE_TYPE_ERROR = 3;
export const ERROR_AMOUNT_OUT_OF_RANGE = 4;
export const ERROR_RENTAL_STATUS_ERROR = 9;


export const ERROR_EXPENSE_NOT_FOUND = 5;
export const ERROR_EXPENSE_STATUS_ERROR = 6;
