import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { NavLink } from 'react-router-dom';
import Datetime from 'components/Datetime';
import AuthFeature from 'components/AuthFeature';
import Cell from 'components/ListView/Cell';
import buildActualPath from 'helpers/build-actual-path';
import { MISSION_DETAIL } from 'constants/routes';
import {
    AUTH_VIEW_MISSION_DETAIL,
} from 'constants/permission';

class ListRow extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.shape({}).isRequired,
    }

    renderField({ data, name, width }) {
        let el;

        switch (name) {
        case 'title':
            const path = buildActualPath(MISSION_DETAIL, { missionId: data.mission_id });
            el = (
                <AuthFeature
                    requiredList={ [AUTH_VIEW_MISSION_DETAIL] }
                    fallback={ () => data.title }
                >
                    <NavLink to={ path }>
                        { data.title }
                    </NavLink>
                </AuthFeature>
            );
            break;
        case 'state':
            el = <Translate value={ `customer.mission_state_${ data.state }` } />;
            break;
        case 'criteria_type':
            el = <Translate value={ `mission.criteria_type_${ data.criteria_type }` } />;
            break;
        case 'valid_from':
        case 'valid_to':
        case 'start_time':
        case 'finish_time':
            el = <Datetime time={ data[name] } />;
            break;
        case 'progress_value':
        default:
            el = data[name];
            break;
        }

        return (
            <Cell
                key={ `${ data.id }-${ name }` }
                name={ name }
                style={ {
                    width,
                } }
            >
                { el }
            </Cell>
        );
    }

    render() {
        const { columns, data } = this.props;

        return columns.map(({ key, width }, i) => {
            return this.renderField({ data, width, name: key, });
        });
    }
}

export default ListRow;
