import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import RingSpinner from 'components/Spinner/Ring';
import './button.scss';

class Component extends React.Component {

    static propTypes = {
        type: PropTypes.string,
        className: PropTypes.string,
        children: PropTypes.node.isRequired,
        color: PropTypes.string,
        outline: PropTypes.bool,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        stopTriggerByTab: PropTypes.bool,
    };
    static defaultProps = {
        type: 'button',
        className: undefined,
        color: undefined,
        outline: false,
        disabled: false,
        onClick: () => {},
        stopTriggerByTab: false,
    };
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
        this.ref = React.createRef();
    }
    render() {
        const { loading } = this.state;
        const {
            type,
            className,
            color,
            outline,
            disabled,
            onClick,
            children,
            stopTriggerByTab,
        } = this.props;
        const styles = classNames({
            'button': true,
            'loading': loading,
            [className]: !!className,
        });
        const handleClick = () => {
            const ret = onClick();
            if (ret instanceof Promise) {
                this.setState({
                    loading: true,
                });
                ret.then(
                    response => {
                        this.ref.current !== null && this.setState({
                            loading: false,
                        });
                    }
                );
            }
        };
        return (
            <Button
                innerRef={ this.ref }
                type={ type }
                className={ styles }
                color={ color }
                outline={ outline }
                disabled={ disabled || loading }
                onClick={ handleClick }
                tabIndex={
                    stopTriggerByTab ? -1 : undefined
                }
            >
                { children }
                {
                    !disabled && loading ? <RingSpinner small /> : null
                }
            </Button>
        );
    }
}

export default Component;
