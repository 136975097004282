import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import FlexGroup from 'components/FlexGroup';
import DotsOverallServiceLevel from './DotsOverallServiceLevel/DotsOverallServiceLevel';
import DotsDetail from './DotsDetail/DotsDetail';
import InfoCard from 'components/InfoCard';
import MapChart from 'components/Chart/MapChart';

const DOTS_MAP_WIDTH = '100%';
const DOTS_MAP_HEIGHT = '100%';

class Dots extends React.Component {
    static propTypes = {
        dotsOverall: PropTypes.shape({}),
        dotsList: PropTypes.arrayOf(PropTypes.shape({})),
    };
    static defaultProps = {
        dotsOverall: {},
        dotsList: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedStation: undefined,
        };
    }

    changeStation = selectedStation => e => {
        this.setState({
            selectedStation,
        });
    }

    render() {
        const { dotsOverall, dotsList } = this.props;
        const { selectedStation } = this.state;

        return (
            <FlexGroup alignStart className="dashboard-dots">
                <InfoCard className="dots-map">
                    {
                        (dotsList && dotsList.length) ? (
                            <MapChart
                                width={ DOTS_MAP_WIDTH }
                                height={ DOTS_MAP_HEIGHT }
                                markerData={ dotsList }
                                onClick={ this.changeStation }
                                selected={ selectedStation }
                            />
                        ) : null
                    }
                </InfoCard>
                <DotsOverallServiceLevel
                    title={ I18n.t('dashboard.overall_service_level') }
                    overall={ dotsOverall }
                />
                <DotsDetail
                    list={ dotsList }
                    selected={ selectedStation }
                    onClick={ this.changeStation }
                />
            </FlexGroup>
        );
    }
}
export default Dots;
