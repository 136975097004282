/**
 * Action - Regular Maintenance
 */
import api from 'api';

import {
    FETCH_RM_SCOOTER_LIST,
} from 'constants/action-type';

import { asyncInterface } from './util';


export const fetchRMScooterList = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchRMScooterList(params),
        type: FETCH_RM_SCOOTER_LIST,
        dispatch,
    });
};
