import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DrawingManager } from '@react-google-maps/api';


class ToolDrawingManager extends PureComponent {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        onOverlayComplete: PropTypes.func.isRequired,
    };

    handleLoad = instance => {
        this.instance = instance;
    }

    handleOverlayComplete = e => {
        const { onOverlayComplete } = this.props;

        onOverlayComplete(this.instance, e);
    }

    render() {
        const { show } = this.props;
        const options = {
            drawingControlOptions: {
                position: 1,
                drawingModes: ['rectangle']
            },
            rectangleOptions: {
                fillColor: '#0069D2',
                fillOpacity: 0.3,
                strokeColor: '#0069D2',
                strokeWeight: 1,
                zIndex: 1,
            },
        };

        return (
            show ? (
                <DrawingManager
                    onLoad={ this.handleLoad }
                    onOverlayComplete={ this.handleOverlayComplete }
                    options={ options }
                />
            ) : null
        );
    }
};

export default ToolDrawingManager;
