/**
 * Observer
 */
const observer = (base, callback) => new Proxy(base, {
    get(target, property) {
        return target[property];
    },

    set(target, property, value) {
        if (property !== 'length') {
            target[property] = value;

            callback(property, property, value);
        }

        return true;
    },

    deleteProperty(target, prop) {
        let result = false;

        if (Reflect.has(target, prop)) {
            Reflect.deleteProperty(target, prop);
            callback(target, prop);
            result = true;
        }

        return result;
    },
});

export default observer;
