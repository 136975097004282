import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-spring'

class AnimatedTable extends React.Component {
    static propTypes = {
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        headerHeight: PropTypes.number.isRequired,
        headerRenderer: PropTypes.func.isRequired,
        rowClassName: PropTypes.func.isRequired,
        rowRenderer: PropTypes.func.isRequired,
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        dndProps: PropTypes.shape({}),
    }

    static defaultProps = {
        dndProps: {}
    }

    renderHeader() {
        const { headerRenderer, columns, headerHeight } = this.props;
        const headerStyle = {
            height: headerHeight
        };

        return (
            <div className="header" style={ headerStyle }>
                { headerRenderer({ columns }) }
            </div>
        );
    }

    renderBody() {
        const { data, rowClassName, rowRenderer: Row, dndProps, columns } = this.props;

        return (
            <Transition
                native
                items={ data }
                keys={ item => item.id }
                initial={ null }
                from={ { rowHeight: 0, opacity: 0 } }
                enter={ ({ transform, rowHeight, boxShadow }) => ({ transform, rowHeight, boxShadow, opacity: 1 }) }
                update={ ({ transform, boxShadow }) => ({ transform, boxShadow }) }
                leave={ { rowHeight: 0, opacity: 0 } }
            >
                { ({ transform, opacity, rowHeight, boxShadow }, _, { item }, index) => {
                    return (
                        <Row
                            index={ item }
                            key={ item.id }
                            rowClassName={ rowClassName({ index }) }
                            transform={ transform }
                            boxShadow={ boxShadow }
                            rowHeight={ rowHeight }
                            opacity={ opacity }
                            columns={ columns }
                            rowData={ item }
                            dndProps={ dndProps }
                        />
                    );
                } }
            </Transition>
        );
    }

    render() {
        const { width, height } = this.props;
        const tableStyle = {
            width,
            height
        };

        return (
            <div className="table table-striped" style={ tableStyle }>
                { this.renderHeader() }
                { this.renderBody() }
            </div>
        );
    }
}

export default AnimatedTable;
