import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { getBounds } from 'helpers/gmap-utils';
import addServiceZone from '../../components/Map/Layers/service-zone';
import GoogleMap, { VIP_SELECT } from 'components/Map';
import FlexGroup from 'components/FlexGroup';
import NewTicket from 'components/NewTicket';
import { Refresh, TotalCount } from 'components/Pagination';
import { Translate, I18n } from 'react-redux-i18n';
import ActionPanel from 'components/ActionPanel';
import TotalSelection from 'components/TotalSelection';
import buildActualPath from 'helpers/build-actual-path';
import { Button } from 'reactstrap';
import {
    CENTER_TO_CURRENT_LOCATION,
    VMS,
} from 'components/Map/Toolbox';
import { selectScooter, toggleErrorDialog } from 'actions';
import AuthFeature from 'components/AuthFeature';
import {
    AUTH_CREATE_TICKET,
    AUTH_VIEW_SCOOTER_DETAIL,
} from 'constants/permission';
import permissionHandler from 'helpers/permission-handler';
import getAuthedToolBoxList from 'components/Map/util/get-authed-toolbox';
import {
    SCOOTER_PROFILE,
} from 'constants/routes';
import { filterCorp } from 'helpers/corp-warning';

const defaultToolbox = [CENTER_TO_CURRENT_LOCATION, VMS];

class MapView extends React.PureComponent {
    static propTypes = {
        list: ImmutablePropTypes.listOf(PropTypes.shape({
            scooter_id: PropTypes.string,
            model_code: PropTypes.number,
            lng: PropTypes.number,
            lat: PropTypes.number,
            service_id: PropTypes.string,
            service_type: PropTypes.number,
        })),
        __responseTime: PropTypes.shape({}),
        onFetch: PropTypes.func.isRequired,
        resetBounds: PropTypes.number.isRequired,
        selectedIdList: ImmutablePropTypes.list.isRequired,
        selectedVipLayerList: ImmutablePropTypes.list.isRequired,
        vips: PropTypes.oneOfType([
            PropTypes.object,
        ]),
    };

    static defaultProps = {
        list: [],
        __responseTime: undefined,
        vips: List([]),
    };

    constructor(props) {
        super(props);
        this.service_ids = [];
        this.vipLayer = VIP_SELECT;
        this.state = {
            hiddenActionPanel: true,
            createTicket: false,
            isConditional: false,
            corpName: [],
        };
    }

    componentDidUpdate(prevProps) {
        const { dispatch, resetBounds, selectedVipLayerList } = this.props;
        const prevResetBounds = prevProps.resetBounds;
        const prevVipList = prevProps.selectedVipLayerList;
        const vipList = selectedVipLayerList.toJS();
        if (prevVipList !== selectedVipLayerList) {
            if (vipList.length !== 0) {
                const serviceIdArray = [];
                vipList.forEach(({ service_ids }) => {
                    if (!serviceIdArray.includes(...service_ids)) {
                        serviceIdArray.push(...service_ids);
                    }
                    this.service_ids = [...serviceIdArray];
                });
            }
            else {
                this.service_ids = [];
            }
            // init vipList will default as vipList.length !== 0 and service_ids.length === 0,
            // this criteria should not execute
            if (vipList.length === 0 || (vipList.length !== 0 && vipList[0].service_ids.length !== 0)) {
                addServiceZone({
                    map: this.map,
                    params: { service_id: this.service_ids },
                    selected: true,
                    vipLayer: this.vipLayer
                });
            }
        };
        if (prevResetBounds !== resetBounds) {
            dispatch(selectScooter([]));
            this.mapFitBounds();
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(selectScooter([]));
    }

    fetchData = () => {
        const { onFetch } = this.props;

        return onFetch();
    }

    mapFitBounds = () => {
        const list = this.getScooterList();

        if (this.map) {
            if (list.length === 1) {
                const { lat, lng } = list[0];
                this.map.setCenter({
                    lat,
                    lng,
                });
                this.map.setZoom(15);
            }
            else if (list.length > 1) {
                const bounds = getBounds(list, this.maps);
                this.map.fitBounds(bounds);
            }
        }
    }

    handleGoogleApi = ({ map, maps }) => {
        this.map = map;
        this.maps = maps;
        this.mapFitBounds();
    }

    afterNewTicket = (hasToggle = true) => {
        const { dispatch } = this.props;

        dispatch(selectScooter([]));
        hasToggle && this.toggleNewTicket()();
    }

    handleScooterSelected = (newSelectedIdList, { selected }) => {
        const { dispatch, selectedIdList, vips } = this.props;
        const scooterSet = new Set(selectedIdList.toJS());
        newSelectedIdList.forEach(id => {
            if (selected) {
                scooterSet.add(id);
            }
            else {
                scooterSet.delete(id);
            }
        });

        const corpServiceId = filterCorp({
            dataList: this.getScooterList(),
            updatedDataList: Array.from(scooterSet),
            key: 'id',
            filterKey: 'service_id'
        });
        const filterdCorpName = vips.toJS().filter((item) => {
            return item.service_ids.some(element => corpServiceId.includes(element));
        }).map(({ corporate_name }) => corporate_name);

        this.setState({ corpName: filterdCorpName });

        dispatch(selectScooter(Array.from(scooterSet)));
    }

    getScooterList = () => {
        const { list } = this.props;
        let realList = list.toJS();

        realList = realList.map(({ scooter_id, model_code, lat, lng, service_id, service_type, visible }) => ({
            id: scooter_id,
            model_code,
            lat,
            lng,
            service_id,
            service_type,
            visible,
        }));

        return realList;
    }

    clearAllSelected = () => {
        const { dispatch } = this.props;

        dispatch(selectScooter([]));
    }

    toggleNewTicket = (constraint = false, isConditional = false) => () => {
        const { dispatch, selectedIdList } = this.props;
        if (constraint && selectedIdList.size > 200) {
            dispatch(toggleErrorDialog(I18n.t('scooter.number_constraint_of_scooter')));
            return;
        }

        const { createTicket } = this.state;
        this.setState({
            createTicket: !createTicket,
            isConditional,
        });
    }

    toggleClose = (constraint = false) => {
        const { dispatch, selectedIdList } = this.props;
        if (constraint && selectedIdList.size > 200) {
            dispatch(toggleErrorDialog(I18n.t('scooter.number_constraint_of_scooter')));
            return;
        }

        const { hiddenActionPanel } = this.state;
        this.setState({
            hiddenActionPanel: !hiddenActionPanel,
        });
    }

    afterDidAction = () => {
        this.clearAllSelected();
        this.toggleClose();
    }

    render() {
        const { list, selectedIdList, __responseTime } = this.props;
        const { hiddenActionPanel, createTicket, isConditional, corpName } = this.state;
        const hasScooterSelected = selectedIdList.size > 0;
        const toolboxList = getAuthedToolBoxList([...defaultToolbox]);
        return (
            <React.Fragment>
                {
                    !hiddenActionPanel ?
                        (
                            <ActionPanel
                                scooterIdList={ selectedIdList.toJS() }
                                onDidAction={ this.afterDidAction }
                                onClose={ this.toggleClose }
                                corporate={ corpName }
                            />
                        ) :
                        null
                }
                <FlexGroup>
                    <FlexGroup start gap>
                        <Button
                            color="primary"
                            type="button"
                            disabled={ !hasScooterSelected }
                            onClick={ () => this.toggleClose(true) }
                        >
                            <Translate value="do_action" />
                        </Button>
                        <AuthFeature
                            requiredList={ [ AUTH_CREATE_TICKET ] }
                        >
                            <Button
                                outline
                                color="primary"
                                type="button"
                                disabled={ !hasScooterSelected }
                                onClick={ this.toggleNewTicket(true) }
                            >
                                <Translate value="ticket.new" />
                            </Button>
                            <Button
                                outline
                                className="btn-conditional-ticket"
                                color="primary"
                                type="button"
                                disabled={ !hasScooterSelected }
                                onClick={ this.toggleNewTicket(true, true) }
                            >
                                <Translate value="ticket.conditional" />
                            </Button>
                        </AuthFeature>
                        <Button
                            color="secondary"
                            onClick={ this.clearAllSelected }
                            disabled={ selectedIdList.size === 0 }
                        >
                            <Translate value="clear_all" />
                        </Button>
                        <TotalCount count={ list.size } />
                        {
                            selectedIdList.size === 1 &&
                            permissionHandler({ requiredList: [AUTH_VIEW_SCOOTER_DETAIL] }) ?
                                (
                                    <NavLink to={ buildActualPath(SCOOTER_PROFILE,
                                        { scooterId: (selectedIdList.toJS())[0] }) }
                                    >
                                        <Translate value="total_selection" />
                                        <span>{ `: ${ selectedIdList.size }` }</span>
                                    </NavLink>
                                ) : (
                                    <TotalSelection count={ selectedIdList.size } />
                                )
                        }
                    </FlexGroup>
                    <FlexGroup end gap>
                        <Refresh time={ __responseTime } onClick={ this.fetchData } disabled={ hasScooterSelected } />
                    </FlexGroup>
                </FlexGroup>
                <NewTicket
                    isConditional={ isConditional }
                    show={ createTicket }
                    corporate={ corpName }
                    scooterIdList={ selectedIdList.toJS() }
                    onCreated={ this.afterNewTicket }
                    onCancelled={ this.toggleNewTicket() }
                />
                <GoogleMap
                    className="map-view"
                    onInternalAPI={ this.handleGoogleApi }
                    toolbox={ toolboxList }
                    onScooterSelected={ this.handleScooterSelected }
                    list={ this.getScooterList() }
                    searchBox
                    drawingManager
                    currentLocation
                    vipLayer={ this.vipLayer }
                />
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    list: state.scooter.getIn(['allList', 'list']),
    __responseTime: state.scooter.getIn(['allList', '__responseTime']),
    selectedVipLayerList: state.map.get('selectedVipLayerList'),
    selectedIdList: state.map.get('selectedIdList'),
    vips: state.map.get('vips'),
}))(MapView);
