import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import moment from 'moment';
import AuthView from 'components/AuthView';
import FlexGroup from 'components/FlexGroup';
import { I18n } from 'react-redux-i18n';
import { Refresh } from 'components/Pagination';
import NavigationBar from 'components/NavigationBar';
import SegmentControl from 'components/SegmentControl';
import Overall from './Overall';
import Dots from './Dots';
import Empty from './Empty';
import { updateDocumentTitle } from 'actions';
import {
    DASH_OVERALL,
    DASH_DOTS,
} from 'constants/dashboard';

import './dashboard.scss';
import { useDispatch, useSelector } from "react-redux";
// import { useGetStatisticsQuery } from "redux/services/dashboardApi";
import { useGetStatisticsQuery } from 'redux/hook/useDashboard';

const DASHBOARD_LIST = [
    {
        name: I18n.t(`dashboard.type_${ DASH_OVERALL }`),
        value: DASH_OVERALL,
    },
    {
        name: I18n.t(`dashboard.type_${ DASH_DOTS }`),
        value: DASH_DOTS,
    },
];

const DASHBOARD_HIDE_BREAK = 1024;

const Dashboard = () => {
    const dispatch = useDispatch();
    const i18n = useSelector(state => state.i18n);
    const location = useLocation();
    const [dashTypeId, setDashTypeId] = useState(DASHBOARD_LIST[0].value);
    const [width, setWidth] = useState(window.innerWidth);

    const resizeDashboard = () => {
        setWidth(window.innerWidth);
    }

    const debounceResize = debounce(resizeDashboard, 100, { trailing: true });

    const {
        data: dashboardData,
        refetch: refetchGetStatistics,
    } = useGetStatisticsQuery();

    useEffect(() => {
        const { pathname } = location;
        dispatch(updateDocumentTitle(`routes.${ pathname }`));
        window.addEventListener('resize', debounceResize, false);
        return (() => {
            window.removeEventListener('resize', debounceResize, false);
        });
    }, [dispatch, i18n, debounceResize, location]);

    useEffect(() => {
        refetchGetStatistics();
    }, [width, refetchGetStatistics]);

    const switchDashboard = type => e => {
        if (type.value !== dashTypeId) {
            setDashTypeId(type.value);
        }
    }

    const getUpdateTimeString = updatedTime => {
        const time = updatedTime || new Date();
        return moment(time);
    }


    const renderDashboard = () => {
        let el;
        if (dashboardData) {
            if (width <= DASHBOARD_HIDE_BREAK) {
                return el;
            }
            switch (dashTypeId) {
            case DASH_DOTS:
                const { dots } = dashboardData;
                const { overall: dotsOverall, list } = dots;
                el = (
                    <Dots
                        dotsOverall={ dotsOverall }
                        dotsList={ list }
                    />
                );
                break;
            case DASH_OVERALL:
            default:
                const { scooterModels, overall } = dashboardData;
                const { regions } = overall;
                el = (
                    <Overall
                        serviceLevelData={ regions.list }
                        overallSLData={ regions }
                        scooterModelsData={ scooterModels }
                    />);
                break;
            }
        }
        return el;
    }

    const elContent = renderDashboard();
    const time = getUpdateTimeString(dashboardData?.updatedTime);

    return (
        <AuthView className="dashboard">
            <NavigationBar
                hideBack
                title={ I18n.t('dashboard.document_title') }
            >
                <div className="type-select">
                    <SegmentControl
                        list={ DASHBOARD_LIST }
                        onClick={ switchDashboard }
                        selected={ dashTypeId }
                    />
                </div>
                <div className="refresh-area">
                    <Refresh time={ time } onClick={ refetchGetStatistics } />
                </div>
            </NavigationBar>
            <FlexGroup alignStart className="dashboard-container">
                { elContent }
                <Empty />
            </FlexGroup>
        </AuthView>
    )
}

export default Dashboard;

