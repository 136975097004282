import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './utils';

const GET_VIOLATION_DETAIL= '/violate_rentals/';
const VIOLATION_TRANSFER = '/transfer';

export const violationApi = createApi({
    reducerPath: 'violationApi',
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        getViolationDetail: builder.query({
            query: (customerId) => ({
                url: `${ GET_VIOLATION_DETAIL }${ customerId }${ VIOLATION_TRANSFER }`,
            }),
        }),
    })
});

export const {
    useGetViolationDetailQuery,
} = violationApi;
