import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Datetime from 'react-datetime';
import { I18n } from 'react-redux-i18n';

const DEFAULT_DATEFORMAT = 'datetime_format.date';
const DEFAULT_TIMEFORMAT = 'datetime_format.time';

const EndDatetime = ({
    initialViewDate = moment().endOf('day'),
    onChange,
    dateFormat,
    timeFormat,
    ...props
}) => {
    const fullFormat = `${ I18n.t(dateFormat) } ${ I18n.t(timeFormat) }`;
    const elDate = useRef(null);

    const handleChangeDatetime = useCallback(
      (datetime) => {
        const isSelectedValid = !!(moment(datetime, fullFormat, true) !== 'Invalid date');

        onChange(datetime);

        if (isSelectedValid && moment(datetime).isValid()) {
            elDate && elDate.current.setViewDate(moment(datetime));
        }
        else {
            if (datetime === '') {
                const endViewDate =  moment().endOf('day');
                elDate && elDate.current.setViewDate(endViewDate);
            }
        }
      },
      [onChange, fullFormat, elDate],
    )


    return (
        <Datetime
            dateFormat={ dateFormat }
            timeFormat={ timeFormat }
            closeOnSelect={ true }
            ref={ elDate }
            initialViewDate={ initialViewDate }
            { ...props }
            onChange={ handleChangeDatetime }
        />
    );
};

EndDatetime.propTypes = {
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    onChange: PropTypes.func,
    initialViewDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(moment),
        PropTypes.instanceOf(Date),
    ]),

};
EndDatetime.defaultProps = {
    dateFormat: I18n.t(DEFAULT_DATEFORMAT),
    timeFormat: I18n.t(DEFAULT_TIMEFORMAT),
    onChange: () => {},
    initialViewDate: moment().endOf('day'),
};

export default EndDatetime;
