import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuthView from 'components/AuthView';
import SearchField from 'components/SearchField';
import MultiselectFilter from 'components/Filter/MultiselectFilter';
import DatetimeRangeFilter from 'components/Filter/DatetimeRangeFilter';
import FlexGroup from 'components/FlexGroup';
import ChoiceGroup from 'components/ChoiceGroup';
import { getDefaultPageSize } from 'components/Pagination/PageSizeSelect';
import { updateDocumentTitle, fetchCouponList } from 'actions';
import { Translate, I18n } from 'react-redux-i18n';
import { convertToUTC } from 'helpers/time-handler';
import {
    statusList,
    typeList,
    STATUS_DRAFT,
    STATUS_PUBLISH,
    STATUS_LIVE,
} from 'constants/coupon';
import List from './List';
import './coupon.scss';

const FILTER_SEARCH_TYPE = 'search_type';
const FILTER_TITLE = 'title';
const FILTER_STATUS = 'status';
const FILTER_TYPE = 'discount_type';
const FILTER_CONVERT_TIME = 'convert_time';
const FILTER_CODE_LIKE = 'coupon_code_like';

class Coupon extends Component {
    constructor(props) {
        super(props);

        this.searchType = FILTER_TITLE;
        this.elDatetimeFrom = React.createRef();
        this.elDatetimeTo = React.createRef();

        this.state = {
            appliedDateRange: false,
            queryPayload: {
                filter: {
                    convert_from: undefined,
                    convert_to: undefined,
                },
                sort: undefined,
                pageSize: getDefaultPageSize().value,
                pageIndex: 1,
            },
            withDefaultStatusFilter: true
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(updateDocumentTitle('coupon.document_title'));
    }

    fetchData = () => {
        const { queryPayload, withDefaultStatusFilter } = this.state;
        const { filter, sort, pageSize, pageIndex, } = queryPayload;
        const { convert_from, convert_to, status } = filter;
        const { dispatch } = this.props;
        let statusFilter = status;

        if (withDefaultStatusFilter &&
            (!status || status.length === 0)
        ) {
            statusFilter = [ STATUS_DRAFT, STATUS_PUBLISH, STATUS_LIVE ];
        }
        return dispatch(fetchCouponList({
            ...filter,
            status: statusFilter,
            convert_from: convert_from && convertToUTC(convert_from),
            convert_to: convert_to && convertToUTC(convert_to),
            sort,
            page: pageIndex,
            size: pageSize,
        }));
    }

    handleFilterChanged = filterName => payload => {
        const { queryPayload } = this.state;
        let { filter } = queryPayload;
        const updatedState = {};
        queryPayload.pageIndex = 1;

        switch (filterName) {
        case FILTER_SEARCH_TYPE:
            filter = {
                [FILTER_TITLE]: undefined,
                [FILTER_CODE_LIKE]: undefined,
                [this.searchType]: payload || undefined,
            };
            this.keyword = payload;
            updatedState.appliedDateRange = false;
            break;
        case FILTER_STATUS:
        case FILTER_TYPE:
            filter[filterName] = payload.inputSelect;
            break;
        case FILTER_CONVERT_TIME:
            const { from, to } = payload;
            filter.convert_from = from;
            filter.convert_to = to;
            updatedState.appliedDateRange = !!(from && to);
            break;
        default:
            // Skip it. Do nothing
        }

        let withDefaultStatusFilter = true;
        // search keyword without default filter
        if (this.keyword) {
            withDefaultStatusFilter = false;
        }

        this.setState({
            queryPayload: {
                ...queryPayload,
                filter,
            },
            withDefaultStatusFilter,
        });

        if (Object.keys(updatedState).length > 0) {
            this.setState(updatedState, () => {
                this.fetchData();
            });
        }
        else {
            this.fetchData();
        }
    }

    handleListSort = ({ field, order }) => {
        const { queryPayload } = this.state;
        queryPayload.pageIndex = 1;
        if (field && order) {
            queryPayload.sort = `${field},${order}`;
        }
        else {
            queryPayload.sort = undefined;
        }

        this.fetchData();
    }

    handlePageSelect = page => {
        const { queryPayload } = this.state;
        queryPayload.pageIndex = page;
        this.fetchData();
    }

    handlePageSizeChange = selection => {
        const { queryPayload } = this.state;
        queryPayload.pageIndex = 1;
        queryPayload.pageSize = selection.value;
        this.fetchData();
    }

    handleSearchTypeChange = e => {
        const { queryPayload } = this.state;
        const { filter } = queryPayload;
        const value = filter[FILTER_TITLE] || filter[FILTER_CODE_LIKE] || '';
        filter[FILTER_TITLE] = undefined;
        filter[FILTER_CODE_LIKE] = undefined;
        queryPayload.pageIndex = 1;
        this.searchType = e.currentTarget.value;
        filter[this.searchType] = value === '' ? undefined : value;
        this.fetchData();
    }

    renderFilters() {
        const { appliedDateRange, queryPayload } = this.state;

        return (
            <FlexGroup start className="filters">
                <DatetimeRangeFilter
                    title={ I18n.t('coupon.convert_time') }
                    defaultFrom={ queryPayload.filter.convert_from }
                    defaultTo={ queryPayload.filter.convert_to }
                    onApply={ this.handleFilterChanged(FILTER_CONVERT_TIME) }
                    applied={ appliedDateRange }
                />
                <MultiselectFilter
                    title={ I18n.t('coupon.status') }
                    options={
                        statusList.map(statusName => ({
                            text: I18n.t(`coupon.status_${ statusName }`),
                            value: statusName,
                        }))
                    }
                    defaultSelected={ queryPayload.filter.status }
                    onChange={ this.handleFilterChanged(FILTER_STATUS) }
                />
                <MultiselectFilter
                    title={ I18n.t('coupon.type') }
                    options={
                        typeList.map(typeName => ({
                            text: I18n.t(`coupon.type_${ typeName }`),
                            value: typeName,
                        }))
                    }
                    defaultSelected={ queryPayload.filter.discount_type }
                    onChange={ this.handleFilterChanged(FILTER_TYPE) }
                />
            </FlexGroup>
        );
    }

    render() {
        const { queryPayload } = this.state;
        return (
            <AuthView className="coupon coupon-list">
                <FlexGroup start className="search-section">
                    <Translate className="search-for-caption" value="search_for" />
                    <ChoiceGroup
                        defaultChecked={ this.searchType }
                        options={ [
                            {
                                id: FILTER_TITLE,
                                name: I18n.t('coupon.title'),
                                value: FILTER_TITLE,
                            }, {
                                id: FILTER_CODE_LIKE,
                                name: I18n.t('coupon.coupon_code'),
                                value: FILTER_CODE_LIKE,
                            }
                        ] }
                        onChange={ this.handleSearchTypeChange }
                    />
                    <SearchField
                        placeholder={ I18n.t('coupon.search_box_placeholder') }
                        onSubmit={ this.handleFilterChanged(FILTER_SEARCH_TYPE) }
                        value={ this.keyword }
                    />
                    { this.renderFilters() }
                </FlexGroup>
                <List
                    onSort={ this.handleListSort }
                    onSelect={ this.handlePageSelect }
                    onChange={ this.handlePageSizeChange }
                    pageSize={ queryPayload.pageSize }
                    onFetch={ this.fetchData }
                />
            </AuthView>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(Coupon);
