import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import AuthView from 'components/AuthView';
import { updateDocumentTitle } from 'actions';
import { getDefaultPageSize } from 'components/Pagination/PageSizeSelect';
import { convertToUTC } from 'helpers/time-handler';
import { FEE_STATUS_PROCESSING } from 'constants/additional-fee';
import Filter, {
    getDefaultPeriod,
    SEARCH,
    SEARCH_BY_RENTAL,
    SEARCH_BY_CUSTOMER,
    SEARCH_BY_PLATE,
    SEARCH_BY_FINE_NO,
    CLAIM_TYPE,
    FEE_TYPE,
    FEE_SUBTYPE,
    STATUS,
    CREATE_TIME
} from './Filter';
import List from './List';
// import { useGetAdditionalFeeQuery } from "redux/services/additionalFeeApi";
import { useGetAdditionalFeeQuery } from 'redux/hook/useAdditionalFee';

import './additional-fee.scss';



const AdditionalFee = () => {
    const getQueryString = useCallback((payload) => {
        const { filter, ...params } = payload;
        const { create_from, create_to} = filter;
        const checkFilter = Object.values(filter).every(item => item === undefined || item.length === 0);
        const { start, end } = getDefaultPeriod();
        return {
            ...filter,
            create_from: convertToUTC(create_from ? create_from : start),
            create_to: convertToUTC(create_to ? create_to : end) ,
            [STATUS]: checkFilter ? [FEE_STATUS_PROCESSING] : filter[STATUS],
            ...params
        };
    },[]);

    const location = useLocation();
    const dispatch = useDispatch();
    const i18n = useSelector(state => state.i18n);
    const { start } = getDefaultPeriod();
    const [searchType, setSearchType] = useState(SEARCH_BY_RENTAL);
    const [keyword, setKeyword] = useState();
    const [queryPayload, setQueryPayload] = useState({
        filter: {
            [SEARCH]: undefined,
            [SEARCH_BY_RENTAL]: undefined,
            [SEARCH_BY_CUSTOMER]: undefined,
            [SEARCH_BY_PLATE]: undefined,
            [SEARCH_BY_FINE_NO]: undefined,
            [CLAIM_TYPE]: undefined,
            [FEE_TYPE]: undefined,
            [FEE_SUBTYPE]: undefined,
            [STATUS]: undefined,
            create_from: undefined,
            create_to: undefined,
        },
        sort: undefined,
        size: getDefaultPageSize().value,
        page: 1,
    });
    const [appliedDateRange, setAppliedDateRange] = useState(false);
    const [fromTime, setFromTime] = useState(moment(start));
    const [toTime, setToTime] = useState(moment());

    const [queryString, setQueryString] = useState(getQueryString(queryPayload));

    useEffect(() => {
        const newQueryString = getQueryString(queryPayload);
        setQueryString(newQueryString);
    },[queryPayload, getQueryString]);

    const {
        data,
        refetch: getAdditionalFee,
    } = useGetAdditionalFeeQuery(queryString, {
        pollingInterval: 60000,
    });

    useEffect(() => {
        const { pathname } = location;
        dispatch(updateDocumentTitle(`routes.${ pathname }`));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

    }, [i18n]);

    const setFromAndToTime = (filter = queryPayload.filter) => {
        const { create_from, create_to } = filter;
        setFromTime(create_from ? undefined : start);
        setToTime(create_to ? undefined : moment());
    };
    const handleApplyFilter = type => value => {
        let { filter } = queryPayload;

        switch (type) {
        case SEARCH:
            const keyword = value === '' ? undefined : value;
            filter = {
                [SEARCH_BY_RENTAL]: undefined,
                [SEARCH_BY_CUSTOMER]: undefined,
                [SEARCH_BY_PLATE]: undefined,
                [SEARCH_BY_FINE_NO]: undefined,
                [searchType]: keyword,
            };
            setKeyword(keyword)
            setAppliedDateRange(false);
            break;
        case FEE_TYPE:
        case FEE_SUBTYPE:
        case CLAIM_TYPE:
        case STATUS:
            filter[type] = value.inputSelect;
            break;
        case CREATE_TIME:
            const { from, to } = value;
            filter.create_from = from;
            filter.create_to = to;

            setAppliedDateRange(!!(from && to));
            break;
        default:
            filter[type] = value;
        }
        setFromAndToTime(filter);
        setQueryPayload({
            ...queryPayload,
            filter,
            page: 1,
        });

    };

    const handleSearchTypeChange = e => {
        setSearchType(e.target.value);
    };

    const handlePageSelect = page => {
        setFromAndToTime();
        setQueryPayload({
            ...queryPayload,
            page: page,
        });
    };

    const handlePageSizeChange = pageSize => {
        setFromAndToTime();
        setQueryPayload({
            ...queryPayload,
            page: 1,
            size: pageSize,
        });
    }

    const handleSort = ({ field, order }) => {
        setFromAndToTime();
        setQueryPayload({
            ...queryPayload,
            page: 1,
            sort: (field && order) ? `${field},${order}` : undefined,
        });

    };

    const fetchAdditionalFeeList = useCallback(() => {
        getAdditionalFee(queryString);
    },[getAdditionalFee, queryString]);

    useEffect(() => {
        fetchAdditionalFeeList();
    },[queryString, fetchAdditionalFeeList]);

    return (
        <AuthView className="additional-fee">
            <Filter
                defaultChecked={ searchType }
                onChoiceChange={ handleSearchTypeChange }
                applyFilter={ handleApplyFilter }
                value={ keyword }
                onChangeKeyword={ setKeyword }
                appliedDateRange={ appliedDateRange }
                filter={ queryPayload.filter }
            />
            <List
                onSort={ handleSort }
                onSelect={ handlePageSelect }
                onFetch={ fetchAdditionalFeeList }
                onChange={ handlePageSizeChange }
                pageSize={ queryPayload.size }
                fromTime={ fromTime }
                toTime={ toTime }
                data={ data }
            />
        </AuthView>
    );
};

export default AdditionalFee;
