
// simple input
export const TYPE_TEXT = 'text';
export const TYPE_EMAIL = 'email';
export const TYPE_FILE = 'file';
export const TYPE_HIDDEN = 'hidden';
export const TYPE_NUMBER = 'number';
export const TYPE_PASSWORD = 'password';
export const TYPE_URL = 'url';
export const TYPE_TEXTAREA = 'textarea';

// group
export const TYPE_RADIO = 'radio';
export const TYPE_CHECKBOX = 'checkbox';

// customize
export const TYPE_DATETIME = 'datetime';
export const TYPE_SELECT = 'select';
export const TYPE_SEARCH_DROPDOWNLIST = 'search_select';
