import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Label, Input as LabelInput } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import RingSpinner from 'components/Spinner/Ring';
import FlexGroup from 'components/FlexGroup';
import Modal from 'components/Modal';
import {
    RPC_LOCK,
    RPC_UNLOCK,
    RPC_OPEN_TRUNK,
    RPC_FIND_SCOOTER,
} from 'constants/scooter';
import { sendRemoteCommand, toggleErrorDialog } from 'actions';

import './remote-control.scss';

const Icon = ({ className, children }) => <span className={ `icon ${ className }` } />;

Icon.propTypes = {
    className: PropTypes.string,
};
Icon.defaultProps = {
    className: '',
};

const typeMap = {
    [RPC_LOCK]: {
        icon: 'rpc-lock',
        titleCode: 'scooter.lock',
        action: id => sendRemoteCommand(id, RPC_LOCK),
    },
    [RPC_UNLOCK]: {
        icon: 'rpc-unlock',
        titleCode: 'scooter.unlock',
        action: id => sendRemoteCommand(id, RPC_UNLOCK),
    },
    [RPC_OPEN_TRUNK]: {
        icon: 'rpc-opencart',
        titleCode: 'scooter.open_trunk',
        action: id => sendRemoteCommand(id, RPC_OPEN_TRUNK),
    },
    [RPC_FIND_SCOOTER]: {
        icon: 'rpc-search-location',
        titleCode: 'scooter.find',
        action: id => sendRemoteCommand(id, RPC_FIND_SCOOTER),
    },
};

class RemoteControlButton extends Component {
    static propTypes = {
        scooterId: PropTypes.string.isRequired,
        type: PropTypes.oneOf([
            RPC_LOCK,
            RPC_UNLOCK,
            RPC_OPEN_TRUNK,
            RPC_FIND_SCOOTER,
        ]).isRequired,
        disabled: PropTypes.bool,
        onFinished: PropTypes.func,
    };

    static defaultProps = {
        onFinished: () => {},
        disabled: false,
    };

    constructor(props) {
        super(props);

        const { type } = props;

        this.state = {
            isCalling: false,
            pauseWarningOpen: false,
            checkWarning: true,
            isPauseFeeCalling: false
        };

        this.attrs = typeMap[type];
    }

    handleClick = () => {
        const { dispatch, scooterId, onFinished, type } = this.props;
        const { action } = this.attrs;

        this.setState({
            isCalling: true,
        });

        dispatch(action(scooterId))
            .catch((error) => {
                // Do something
            })
            .then(res => {
                const { error } = res;
                if (error && type === RPC_FIND_SCOOTER && error.response.status === 403 ) {
                    dispatch(toggleErrorDialog(I18n.t('no_access_right'), () => {
                        this.setState({
                            isCalling: false,
                        });
                    }));

                }
                else if (error && type === RPC_UNLOCK && error.response.status === 409 ) {
                    this.setState({
                        pauseWarningOpen: true
                    });
                }
                else {
                    this.setState({
                        isCalling: false,
                    });
                    onFinished(res);
                }

            });
    }

    toggleCheckWarning = () => {
        const { checkWarning } = this.state;
        this.setState({
            checkWarning: !checkWarning,
        });
    }

    handlePauseFeeClick = () => {
        const { checkWarning } = this.state;
        const { dispatch, scooterId, onFinished } = this.props;

        this.setState({
            isPauseFeeCalling: true,
        });

        dispatch(sendRemoteCommand(scooterId, RPC_UNLOCK, checkWarning))
            .catch((error) => {
                // Do something
            })
            .then(res => {
                const { error } = res;
                if (error) {
                    dispatch(toggleErrorDialog(I18n.t('general_error_message'), () => {
                        this.setState({
                            isPauseFeeCalling: false,
                        });
                    }));
                }
                else {
                    this.setState({
                        isPauseFeeCalling: false,
                        pauseWarningOpen: false,
                    });
                    onFinished(res);
                }
            });
    }

    render() {
        const { icon, titleCode } = this.attrs;
        const { isCalling, pauseWarningOpen, checkWarning, isPauseFeeCalling } = this.state;
        const { disabled } = this.props;
        const cls = classNames({
            'btn-remote-control': true,
            'calling': isCalling,
            [icon]: icon,
        });
        return (
            <>
                <div className="btn-remote-control-wrapper">
                    <Button className={ cls } onClick={ this.handleClick } disabled={ disabled || isCalling }>
                        <RingSpinner size="small" />
                    </Button>
                    <span className="btn-title">
                        <Translate value={ titleCode } />
                    </span>
                </div>
                {
                    pauseWarningOpen && (
                        <Modal className="action-panel" title={I18n.t('scooter.unlock')} onClose={ () => this.setState({ pauseWarningOpen: false, isCalling: false }) }>
                            <div className="warning-message">
                                { I18n.t('scooter.pause_description') }
                            </div>
                            <FlexGroup spaceBetween>
                                <div className="warning-panel">
                                    <Label check>
                                        <LabelInput
                                            type="checkbox"
                                            name="pause_fee_check"
                                            onChange={ this.toggleCheckWarning }
                                            value={ !checkWarning }
                                            defaultChecked={ !checkWarning }
                                        />
                                        <span>
                                            <Translate value="know" />
                                        </span>
                                    </Label>
                                    <Translate
                                        className="error"
                                        value={ `${I18n.t('warning')}: ${ I18n.t('scooter.pause_fee_warning') } ` }
                                        tag="span"
                                    />
                                </div>
                                <Button
                                    type="button"
                                    color="primary"
                                    disabled={ checkWarning || isPauseFeeCalling }
                                    onClick={ this.handlePauseFeeClick }
                                >
                                    <Translate value="confirm" />
                                </Button>
                            </FlexGroup>
                        </Modal>
                    )
                }
            </>
        );
    }
}

export default connect(state => ({}))(RemoteControlButton);
