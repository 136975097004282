import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import preferenceHandler from 'helpers/preference-handler';
import './page-size-select.scss';

const PAGE_SIZE_KEY = 'page-size';
const pageSizeOptions = [
    { value: 50, label: 50 },
    { value: 100, label: 100, idDefault: true },
    { value: 200, label: 200 },
];

export const getDefaultPageSize = () => {
    const preferPageSize = preferenceHandler.get(PAGE_SIZE_KEY);
    return preferPageSize || pageSizeOptions.find(({ idDefault }) => idDefault);
};

const PageSizeSelect = ({
    onChange,
}) => {
    const [selectedPageSize, setSelectedPageSize] = useState(getDefaultPageSize());

    const handleChange = option => {
        preferenceHandler.set(PAGE_SIZE_KEY, option);

        setSelectedPageSize(option);

        onChange(option);
    };

    return (
        <div className="page-size-select-wrapper">
            <Select
                className="page-size-select"
                options={ pageSizeOptions }
                value={ selectedPageSize }
                onChange={ handleChange }
            />
        </div>
    );
};
PageSizeSelect.propTypes = {
    onChange: PropTypes.func,

};
PageSizeSelect.defaultProps = {
    onChange: () => {},
};

export default PageSizeSelect;
