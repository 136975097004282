import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import Plate from 'components/Scooter/Plate';
import Cell from 'components/ListView/Cell';
import TicketNo from 'components/Ticket/TicketNo';

class ListRow extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.shape({
            id: PropTypes.number,
            plate: PropTypes.string,
            tracker_id: PropTypes.number,
            tracker_subtype_id: PropTypes.number,
        }).isRequired,
    }

    renderField({ data, name, width }) {
        let el;
        const value = typeof data[name] === 'string' && data[name].trim() === ''
            ? ' ' : data[name];

        switch (name) {
        case 'id':
            el = (
                <TicketNo id={ data.id } />
            );
            break;
        case 'plate_no':
            el = (
                <Plate
                    id={ data.scooter_id }
                    plateNo={ value }
                    onClick={ e => {
                        window.scrollTo({
                            top: 0,
                        });
                    } }
                    modal={ false }
                />
            );
            break;
        case 'tracker_id':
            el = <Translate value={ `ticket.main_type_${ data.tracker_id }` } />;
            break;
        case 'tracker_subtype_id':
            el = <Translate value={ `ticket.sub_type_${ data.tracker_subtype_id }` } />;
            break;
        case 'severity_id':
            el = <Translate value={ `ticket.severity_${ data.severity_id }` } />;
            break;
        default:
            el = value;
            break;
        }
        return (
            <Cell key={ `${ data.id }-${ name }` } name={ name } style={ { width } }>
                { el }
            </Cell>
        );
    }

    render() {
        const { columns, data } = this.props;
        return columns.map(({ key, width }) => this.renderField({ data, width, name: key, }));
    }
}

export default ListRow;
