/**
 * Reducer - Admin
 */
import { Map, List, } from 'immutable';
import {
    FETCH_FLEET_ADMIN_USERS,
    FETCH_FRANCHISEE_LIST,
    FETCH_USER_ROLES,
} from 'constants/action-type';
import creater from './util/creater';

const initialState = Map({
    list: Map({
        data_list: List([])
    }),
    franchiseeList: List([]),
    userRoles: List([]),
});

const actionsMap = {
    [FETCH_FLEET_ADMIN_USERS]: (state, action) => {
        const { page_index, page_count, total, data_list, __responseTime } = action.data;

        return state.merge({
            list: Map({
                page_index,
                page_count,
                total,
                data_list: List(data_list),
                __responseTime,
            }),
        });
    },
    [FETCH_FRANCHISEE_LIST]: (state, action) => {
        return state.merge({
            franchiseeList: List(action.data)
        });
    },
    [FETCH_USER_ROLES]: (state, action) => {
        return state.merge({
            userRoles: List(action.data)
        });
    },
};

export default creater(actionsMap, initialState);
