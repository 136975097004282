import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import classNames from 'classnames';
import { animateArrayValue } from 'helpers/dashboard';
import {
    CHART_CONFIG_DEFAULT,
    CHART_DURATION_DEFAULT,
    CHART_FONT_STYLE_MEDIUM,
} from 'constants/chart';

class BarChart extends React.Component {
    static propTypes = {
        barData: PropTypes.arrayOf(PropTypes.shape({
            xValue: PropTypes.arrayOf(PropTypes.string).isRequired,
            yValue: PropTypes.arrayOf(PropTypes.number).isRequired,
            color: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.arrayOf(PropTypes.string),
            ]),
            threshold: PropTypes.shape({
                value: PropTypes.number,
                unitByPercent: PropTypes.bool,
            }),
        })).isRequired,
        width: PropTypes.number,
        height: PropTypes.number,
        layoutConfig: PropTypes.shape({}),
        renderLabel: PropTypes.func,
        autoResize: PropTypes.bool,
        duration: PropTypes.number,
    };

    static defaultProps = {
        width: 0,
        height: 0,
        layoutConfig: {},
        renderLabel: value => value,
        autoResize: false,
        duration: CHART_DURATION_DEFAULT,
    }

    constructor(props) {
        super(props);
        this.animateTimer = [];
        this.state = {
            value: props.barData,
        };
    }

    componentDidUpdate(prevProps) {
        const { barData } = this.props;
        if (prevProps.barData !== barData) {
            this.setState({
                value: barData,
            });

            this.animateBarData(prevProps.barData, barData);
        }
    }

    componentWillUnmount() {
        if (this.animateTimer && this.animateTimer.length) {
            this.animateTimer.forEach( timer => {
                if (timer) {
                    timer.kill();
                }
            });
        }
    }

    animateBarData = (prevBarData, newBarData) => {
        const { duration } = this.props;
        newBarData.forEach( ({ yValue }, index) => {
            let timer;
            timer = animateArrayValue(prevBarData[index].yValue, yValue, duration, this.updateBarData, index);
            this.animateTimer.push(timer);
        });
    }

    updateBarData = (result, index) => {
        const { value } = this.state;
        value[index] = {
            ...value[index],
            yValue: result,
        };
        this.setState({
            value: [ ...value ],
        });
    }

    render() {
        const { value } = this.state;
        const { width, height, layoutConfig, renderLabel, autoResize } = this.props;
        const data = value.map(({ xValue, yValue, color, threshold, dataItem, name }) => {
            const zeroHasHeight = yValue.map(value => value + 1);
            return {
                x: xValue,
                y: zeroHasHeight,
                marker: {
                    color: color,
                },
                text: yValue.map((value, index) => {
                    return renderLabel(value, threshold, dataItem[index]);
                }),
                textposition: 'outside',
                textfont: {
                    size: 14,
                    family: CHART_FONT_STYLE_MEDIUM,
                },
                hoverinfo: name ? 'name' : 'none',
                name,
                type: 'bar',
                cliponaxis: false,
            };
        });

        const style = classNames({
            'barchart': true,
            'auto-resize': autoResize,
        });

        return (
            <>
                <Plot
                    data={ data }
                    className={ style }
                    useResizeHandler={ autoResize }
                    layout={ {
                        autosize: autoResize,
                        paper_bgcolor: 'transparent',
                        plot_bgcolor: 'transparent',
                        barmode: 'group',
                        xaxis: {
                            linecolor: '#EBEBEB',
                            linewidth: 1,
                            showgrid: false,
                            fixedrange: true,
                            ticklen: 5,
                            tickcolor: 'transparent',
                            tickfont: {
                                family: CHART_FONT_STYLE_MEDIUM,
                                color: '#323237',
                                size: 16
                            },
                        },
                        yaxis: {
                            zeroline: false,
                            showgrid: false,
                            showticklabels: false,
                            fixedrange: true,
                        },
                        hoverlabel: {
                            font: {
                                family: CHART_FONT_STYLE_MEDIUM,
                            },
                        },
                        hovermode: 'closest',
                        width: autoResize && !width ? undefined : width,
                        height: autoResize && !height ? undefined : height,
                        bargap: 0.14,
                        bargroupgap: 0.3,
                        showlegend: false,
                        ...layoutConfig
                    } }
                    config={ CHART_CONFIG_DEFAULT }
                />
            </>
        );
    };
};

export default BarChart;
