import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import Datetime from 'components/Datetime';
import Cell from 'components/ListView/Cell';
import { NavLink } from 'react-router-dom';
import { CUSTOMER_DETAIL } from 'constants/routes';
import CopyToClipBoard from 'components/CopyToClipBoard';
import buildActualPath from 'helpers/build-actual-path';
import { fullname } from 'view/Customer/util';


class ListRow extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.shape({
            id: PropTypes.string.isRequired,
            plan: PropTypes.number.isRequired,
            email: PropTypes.string,
            attributes: PropTypes.string,
            phone: PropTypes.string,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            birthday: PropTypes.string,
        }).isRequired,
    };

    renderField({ data, name, width }) {
        let el = data[name];

        switch (name) {
        case 'id':
            el = (
                <CopyToClipBoard>
                    <NavLink to={ buildActualPath(CUSTOMER_DETAIL, { customerId: data.id }) }>{ data.id }</NavLink>
                </CopyToClipBoard>
            );
            break;
        case 'first_name':
            el = fullname(data.first_name, data.last_name);
            break;
        case 'birthday':
            el = <Datetime time={ data[name] } format={ I18n.t('datetime_format.date') } fallback={ data[name] } />;

            break;
        default:
            el = data[name];
            break;
        }

        return (
            <Cell
                key={ `${ data.id }-${ name }` }
                name={ name }
                style={ {
                    width,
                } }
            >
                { el }
            </Cell>
        );
    }

    render() {
        const { columns, data } = this.props;

        return columns.map(({ key, width }, i) => {
            return this.renderField({ data, width, name: key, });
        });
    }
}

export default ListRow;
