import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import AuthView from 'components/AuthView';
import SearchField from 'components/SearchField';
import FlexGroup from 'components/FlexGroup';
import { getDefaultPageSize } from 'components/Pagination/PageSizeSelect';
import { convertToUTC } from 'helpers/time-handler';
import { fetchPromotionList, updateDocumentTitle } from 'actions';
import PromotionList, {
    FILTER_PAGE_INDEX,
    FILTER_PAGE_SIZE,
    FILTER_SORT,
} from './PromotionList';
import StatusFilter from './filters/StatusFilter';
import './promotion.scss';

const FILTER_TITLE = 'title';
const FILTER_STATUS = 'status';
const FILTER_TYPE = 'type';
const FILTER_SCHEDULE_TIME = 'schedule_time';

class PromotionManagement extends React.Component {

    searchText = ''

    state = {
        queryPayload: {
            filter: {
                [FILTER_TITLE]: undefined,
                [FILTER_STATUS]: undefined,
                [FILTER_TYPE]: undefined,
                [FILTER_SCHEDULE_TIME]: undefined,
            },
            [FILTER_PAGE_INDEX]: 1,
            [FILTER_PAGE_SIZE]: getDefaultPageSize().value,
            [FILTER_SORT]: {},
        },
    }

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { pathname } = location;

        dispatch(updateDocumentTitle(`routes.${ pathname }`));
    }

    componentDidUpdate(prevProps, prevState) {
        const { queryPayload: prevQueryPayload } = prevState;
        const { queryPayload } = this.state;

        if (prevQueryPayload !== queryPayload) {
            this.fetchPromotionList();
        }

    }

    setSearchText = text => {
        this.searchText = text;
    }

    fetchPromotionList = async () => {
        const { dispatch } = this.props;
        const { queryPayload } = this.state;
        const { filter, ...rest } = queryPayload;
        const payload = {
            ...filter,
            ...rest,
        };

        await dispatch(fetchPromotionList(payload));
    }

    handleQueryPayloadChanged = name => value => {
        const { queryPayload } = this.state;
        let { filter } = queryPayload;
        queryPayload[FILTER_PAGE_INDEX] = 1;

        switch (name) {
        case FILTER_STATUS:
        case FILTER_TYPE:
            filter = {
                ...filter,
                [name]: value.inputSelect,
            };
            break;
        case FILTER_PAGE_INDEX:
            queryPayload[name] = value;
            break;
        case FILTER_PAGE_SIZE:
            queryPayload[name] = value.value;
            break;
        case FILTER_SCHEDULE_TIME:
            const { from, to } = value;
            filter.schedule_from = from ? convertToUTC(from) : undefined;
            filter.schedule_to = to ? convertToUTC(to) : undefined;
            break;
        case FILTER_TITLE:
            filter = { [FILTER_TITLE]: this.searchText || undefined };
            break;
        case FILTER_SORT:
            let { field } = value;
            const { order } = value;

            if (field && order) {
                queryPayload[FILTER_SORT] = `${ field },${ order }`;
            }
            else {
                queryPayload[FILTER_SORT] = undefined;
            }
            break;
        default:
            break;
        }

        queryPayload.filter = filter;
        this.setState({
            queryPayload: { ...queryPayload }
        });
    }

    render() {
        const { queryPayload } = this.state;
        const { filter } = queryPayload;

        return (
            <AuthView className="promotions">
                <FlexGroup start>
                    <SearchField
                        placeholder={ I18n.t('promotion.search_for') }
                        onChange={ this.setSearchText }
                        onSubmit={ this.handleQueryPayloadChanged(FILTER_TITLE) }
                        value={ this.searchText }
                    />
                    <StatusFilter
                        selected={ filter[FILTER_STATUS] }
                        onChange={ this.handleQueryPayloadChanged(FILTER_STATUS) }
                    />
                </FlexGroup>
                <PromotionList
                    pageSize={ queryPayload.size }
                    onFetch={ this.fetchPromotionList }
                    onChange={ this.handleQueryPayloadChanged }
                />
            </AuthView>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(PromotionManagement);
