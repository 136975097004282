import React from 'react';
import PropTypes from 'prop-types';
import ControlTemplate from './ControlTemplate';
import Datetime from 'components/Datetime';
import { Translate } from 'react-redux-i18n';

const Component = ({ isAssociated, associatedTime }) => {

    return (
        <ControlTemplate large captionKey="customer.associated_gopocket" required={ false }>
            {
                isAssociated === undefined ? '--' : <Translate value={ `${ isAssociated ? 'yes' : 'no' }` } />
            }
            {
                isAssociated ? (<Datetime className="verified-input" time={ associatedTime } />) : null
            }
        </ControlTemplate>
    );
};

Component.propTypes = {
    isAssociated: PropTypes.bool,
    associatedTime: PropTypes.string,
};

Component.defaultProps = {
    isAssociated: undefined,
    associatedTime: '',
};

export default Component;
