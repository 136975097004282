import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import FlexGroup from 'components/FlexGroup';
import Modal from 'components/Modal';
import Button from 'components/Form/Button';

export default function ConfirmModal(props) {
    const { onSave, onClose } = props;

    return (
        <Modal title={ I18n.t('ad.confirm_order_change') } onClose={ onClose }>
            <FlexGroup start>
                <Translate tag="p" value="ad.confirm_order_change_describe" />
            </FlexGroup>
            <FlexGroup gap end>
                <Button outline onClick={ onClose }>
                    <Translate value="ad.continuely_edit" />
                </Button>
                <Button color="primary" onClick={ onSave }>
                    <Translate value="yes" />
                </Button>
            </FlexGroup>
        </Modal>
    );
}

ConfirmModal.propTypes = {
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};