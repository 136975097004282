import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FlexGroup from 'components/FlexGroup';

import './segment-control.scss';

const SegmentControl = ({ className, list, onClick, selected }) => {

    const style = classNames({
        'segment-ctrl': true,
        [className]: true,
    });

    const renderItem = (item, index) => {
        const itemStyle = classNames({
            'item': true,
            'active': item.value === selected,
        });
        return (
            <li className={ itemStyle } onClick={ onClick(item) } key={ `${index}${item.id}` }>{ item.name }</li>
        );

    };

    return (
        <FlexGroup tag="ul" start className={ style }>
            {
                (list && list.length) && list.map( (item, index) => renderItem( item, index ))
            }
        </FlexGroup>
    );
};

SegmentControl.propTypes = {
    className: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.shape({})),
    onClick: PropTypes.func,
    selected: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

SegmentControl.defaultProps = {
    className: '',
    list: [],
    onClick: () => {},
    selected: undefined,
};


export default SegmentControl;
