const ListHeader = [{
    name: 'title',
    value: 'promotion.title',
    sortable: true,
    width: 200,
}, {
    name: 'type',
    value: 'promotion.type',
    sortable: true,
    width: 110,
}, {
    name: 'status',
    value: 'promotion.status',
    sortable: true,
    width: 100,
}, {
    name: 'schedule_time',
    value: 'promotion.schedule_time',
    sortable: true,
    width: 110,
}];

export default ListHeader;
