import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CustomChoiceGroup, { SEARCH_BY_PLATE, SEARCH_BY_TICKET } from 'view/Ticket/Filter/ChoiceGroup';
import CustomTicketFlowFilter from 'view/Ticket/Filter/TicketFlow';
import CustomPriorityFilter from 'view/Ticket/Filter/Priority';
import FlexGroup from 'components/FlexGroup';

export const MODE_LIST = 'list';
export const MODE_MAP = 'map';
const FILTER_KEYWORD = 'keyword';
const FILTER_TICKET_FLOW = 'franchisee_author_assignee';
const FILTER_PRIORITY = 'severity_id';

// Filter options before applied will be cleaned by auto refresh,
// so use PureComponent here to prevent update by shouldComponentUpdate
class Toolbar extends React.PureComponent {

    static propTypes = {
        onSearch: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            searchType: SEARCH_BY_PLATE,
            queryPayload: {
                [FILTER_TICKET_FLOW]: undefined,
                [FILTER_PRIORITY]: undefined,
            }
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { onSearch } = this.props;
        const { queryPayload: prevQueryPayload } = prevState;
        const { queryPayload } = this.state;
        if (prevQueryPayload !== queryPayload) {
            onSearch(queryPayload);
        }
    }

    changeSearchType = e => {
        const searchType = e.currentTarget.value;
        this.setState({ searchType }, this.handleSubmit);
    }

    setKeyword = value => {
        this.keyword = value;
    }

    handleQueryPayloadChanged = name => value => {
        switch (name) {
        case FILTER_PRIORITY:
        case FILTER_TICKET_FLOW:
            this.setState(prevState => ({
                queryPayload: {
                    ...prevState.queryPayload,
                    [name]: value.inputSelect,
                },
            }));
            break;
        case FILTER_KEYWORD:
            this.keyword = value;
            break;
        default:
            console.warn('Not a defined parameter name', name, value);
            break;
        }
    }

    handleSubmit = keyword => {
        const { searchType } = this.state;

        const partialPayload = {
            [SEARCH_BY_PLATE]: undefined,
            [SEARCH_BY_TICKET]: undefined,
            [searchType]: this.keyword ?? undefined,
        };

        this.setState( { queryPayload: {
            ...partialPayload,
            [FILTER_TICKET_FLOW]: undefined,
            [FILTER_PRIORITY]: undefined,
        } } );
    }

    render() {
        const { searchType, queryPayload } = this.state;
        return (
            <React.Fragment>
                <FlexGroup alignEnd>
                    <CustomChoiceGroup
                        defaultChecked={ searchType }
                        onChangeType={ this.changeSearchType }
                        onSubmit={ this.handleSubmit }
                        onChange={ this.handleQueryPayloadChanged(FILTER_KEYWORD) }
                        value={ this.keyword }
                    >
                        <CustomTicketFlowFilter
                            selected={ queryPayload[FILTER_TICKET_FLOW] }
                            onChange={ this.handleQueryPayloadChanged(FILTER_TICKET_FLOW) }
                        />
                        <CustomPriorityFilter
                            selected={ queryPayload[FILTER_PRIORITY] }
                            onChange={ this.handleQueryPayloadChanged(FILTER_PRIORITY) }
                        />
                    </CustomChoiceGroup>
                </FlexGroup>
            </React.Fragment>
        );
    }
};

export default connect(state => ({
    i18n: state.i18n,
}))(Toolbar);
