import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-redux-i18n';
import { Collapse, Card, CardBody } from 'reactstrap';
import './collapse-banner.scss';


class CollapseBanner extends Component {
    static propTypes = {
        captionKey: PropTypes.string.isRequired,
        onFetch: PropTypes.func,
        className: PropTypes.string,
        header: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
        ]),
        open: PropTypes.bool,
    };

    static defaultProps = {
        onFetch: () => Promise.resolve(),
        className: '',
        header: null,
        open: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            open: props.open,
            loading: false,
        };
    }

    handleClick = e => {
        const { onFetch } = this.props;
        const { open } = this.state;
        const goOpen = !open;

        this.setState({
            loading: goOpen,
        });

        const promise = !open ? onFetch(e) : Promise.resolve();

        return promise.then(() => {
            this.setState({
                loading: false,
                open: goOpen,
            });
        });
    }

    renderHeader = () => {
        const { header } = this.props;

        return (typeof header === 'function' ? header() : header);
    }

    render() {
        const { children, captionKey, className } = this.props;
        const { open, loading } = this.state;
        const bannerCls = classNames({
            'collapse-banner': true,
            [className]: !!className,
            loading,
        });
        const iconCls = classNames({
            'collapse-icon': true,
            'open': open,
            'close': !open,
        });

        return (
            <section className={ bannerCls }>
                <div
                    className="summary"
                    onClick={ this.handleClick }
                >
                    <Translate value={ captionKey } />
                    <div className={ iconCls } />
                </div>
                <Collapse isOpen={ open }>
                    <Card>
                        <CardBody>
                            { this.renderHeader() }
                            { children }
                        </CardBody>
                    </Card>
                </Collapse>
            </section>
        );
    }
};

export default CollapseBanner;
