import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardBody, Badge, FormGroup, Label, Input } from 'reactstrap';
import { fetchCouponList } from 'actions';
import { Translate } from 'react-redux-i18n';
import { thousandsSeparator } from 'helpers/util';
import {
    STATUS_DISABLE,
    STATUS_EXPIRY,
    STATUS_LIVE,
    STATUS_PUBLISH,
    TYPE_TIME,
    TYPE_PRICE,
    TYPE_COUPON_SINGLE,
    TYPE_COUPON_BULK,
    TYPE_RANDOM_USER,
    TYPE_RANDOM_UPPER_ENG,
    TYPE_RANDOM_UPPER_EXCLUSIVE,
    TYPE_RANDOM_DIGIT,
    TYPE_RANDOM_DIGIT_EXCLUSIVE,
    TYPE_RANDOM_MIXED,
} from 'constants/coupon';
import ControlTemplate from './ControlTemplate';
import FlexGroup from 'components/FlexGroup';
const LOWER_CODE_LIMIT = 5;

const RANDOM_TYPE = [
    TYPE_RANDOM_UPPER_ENG,
    TYPE_RANDOM_UPPER_EXCLUSIVE,
    TYPE_RANDOM_DIGIT,
    TYPE_RANDOM_DIGIT_EXCLUSIVE,
    TYPE_RANDOM_MIXED,
];

class DetailsSection extends Component {
    static propTypes = {
        couponBody: PropTypes.shape({}),
        viewOnly: PropTypes.bool,
    };

    static defaultProps = {
        couponBody: {},
        viewOnly: false,
    };

    constructor(props) {
        super(props);

        const { couponBody } = props;
        this.state = {
            hadChecked: false,
            isCouponExisting: false,
            discountType: couponBody.discount_type,
            couponType: couponBody.event_type,
            eventTotalCount: Number(couponBody.event_total_count),
            randomType: couponBody.random_code_type,
            totalLimit: Number(couponBody.total_limit),
            redeemLimit: Number(couponBody.redeem_limit),
            discountValue: Number(couponBody.discount_value),
            isNewUserOnly: Boolean(couponBody.is_new_user_only),
        };

        this.elEventCount = React.createRef();
        this.elTotPeople = React.createRef();
        this.elPerPerson = React.createRef();
        this.elDiscount = React.createRef();
        this.elCode = React.createRef();
        this.elCodePrefix = React.createRef();
        this.elCodeLength = React.createRef();
        this.elNewUser = React.createRef();
    }

    componentDidMount() {
    }

    handleDiscountTypeChanged = e => {
        this.setState({
            discountType: Number(e.currentTarget.value),
        });
    }

    handleCouponTypeChanged = e => {
        this.setState({
            couponType: Number(e.currentTarget.value),
        }, () => {
            const { couponType } = this.state;
            if (couponType === TYPE_COUPON_BULK) {
                this.elCodePrefix.current.setCustomValidity('');
                this.handleCheckBulkCoupon();
            }
            else {
                this.handleCheckCoupon(this.elCode.current);
            }
        });
    }

    handleRandomTypeChanged = e => {
        this.setState({
            randomType: Number(e.currentTarget.value),
        });
    }

    handleIsNewUserChanged = e => {
        const elNewUser = this.elNewUser;
        const isNewUserOnly = Boolean(elNewUser.current.value);
        this.setState({
            isNewUserOnly,
        });
    }

    handleCheckBulkCoupon = () => {
        this.elCodePrefix.current.value = this.elCodePrefix.current.value.trim().toUpperCase();
        const isEnoughLength =
        (this.elCodePrefix.current.value.length + Number(this.elCodeLength.current.value) > LOWER_CODE_LIMIT)
        && (this.elCodeLength.current.value !== '');
        const codeLengthZero = Number(this.elCodeLength.current.value) === 0;
        this.setState({
            hadChecked: true,
            isCouponExisting: !isEnoughLength || codeLengthZero,
        });
    }

    handleCheckCoupon = e => {
        const { dispatch, couponBody } = this.props;
        const el = e.currentTarget ?? e;
        const code = el.value.trim().toUpperCase();
        const isEnoughLength = encodeURI(code).length > LOWER_CODE_LIMIT;
        if (!isEnoughLength) {
            this.elCode.current.setCustomValidity('Not long enough');
        }
        else {
            this.elCode.current.setCustomValidity('');
        }

        if (isEnoughLength && couponBody.coupon_code !== code) {
            dispatch(fetchCouponList({ coupon_code: code })).then(({ data }) => {
                const isExisting = data.total > 0;
                this.setState({
                    hadChecked: true,
                    isCouponExisting: isExisting,
                });

                if (isExisting) {
                    this.elCode.current.setCustomValidity('Has already used');
                }
                else {
                    this.elCode.current.setCustomValidity('');
                }
            });
        }
        else {
            this.setState({
                hadChecked: !isEnoughLength,
                isCouponExisting: !isEnoughLength,
            });
        }

        el.value = code;
    }

    handelNumbersChanged = () => {
        const elPerPerson = this.elPerPerson;
        const perPerson = Number(elPerPerson.current.value);
        const eventCount = this.elEventCount.current ? Number(this.elEventCount.current.value) : 0;
        const totPeople = Number(this.elTotPeople.current.value);
        const discount = Number(this.elDiscount.current.value);

        this.setState({
            totalLimit: totPeople,
            redeemLimit: perPerson,
            discountValue: discount,
            eventTotalCount: eventCount,
        });
    }

    renderAccountType() {
        const { accountType, couponBody, viewOnly } = this.props;
        const { support_accounts } = couponBody;
        let el;

        if (accountType) {
            const AccountType = ({ className, value }) => (
                <span className={ className }>
                    <Translate value={ `customer.plan_${ value }` } />
                </span>
            );
            const displayType = accountType.reduce((arr, { id, plan_id, name }) => {
                if ((support_accounts || []).indexOf(id) > -1) {
                    arr.push(
                        <AccountType key={ id } className="type-badge" value={ plan_id } />
                    );
                }

                return arr;
            }, []);

            el = (viewOnly ?
                (<p className="display-type">{ displayType }</p>) :
                (
                    accountType.map(({ id, plan_id, name }, i) => (
                        <FormGroup check key={ id }>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    className="supportAccount"
                                    name="support_accounts"
                                    required={ !support_accounts && i === 0 }
                                    onChange={ this.handleAccountTypeChanged }
                                    value={ id }
                                    defaultChecked={ (support_accounts || []).indexOf(id) > -1 }
                                />
                                <AccountType key={ id } className="account-type" value={ plan_id } />
                            </Label>
                        </FormGroup>
                    ))
                )
            );
        }

        return el;
    }

    renderCouponType() {
        const { viewOnly } = this.props;
        const { couponType = TYPE_COUPON_SINGLE } = this.state;

        return (
            viewOnly ?
                (
                    <ControlTemplate large={ viewOnly } captionKey="coupon_type">
                        <p className="display-type">
                            <Translate className="type-badge" value={ `coupon.type_coupon_${ couponType }` } />
                        </p>
                    </ControlTemplate>
                ) :
                (
                    <ControlTemplate large={ viewOnly } captionKey="coupon_type">
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="event_type"
                                    value={ TYPE_COUPON_SINGLE }
                                    defaultChecked={ couponType === TYPE_COUPON_SINGLE }
                                    onChange={ this.handleCouponTypeChanged }
                                />
                                <Translate value={ `coupon.type_coupon_${ TYPE_COUPON_SINGLE }` } />
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="event_type"
                                    value={ TYPE_COUPON_BULK }
                                    defaultChecked={ couponType === TYPE_COUPON_BULK }
                                    onChange={ this.handleCouponTypeChanged }
                                />
                                <Translate value={ `coupon.type_coupon_${ TYPE_COUPON_BULK }` } />
                            </Label>
                        </FormGroup>
                    </ControlTemplate>
                )
        );
    }

    renderRandomType() {
        const { viewOnly } = this.props;
        const { randomType = TYPE_RANDOM_UPPER_ENG } = this.state;

        return (
            viewOnly ?
                (
                    <ControlTemplate large={ viewOnly } captionKey="random_type">
                        <p className="display-type">
                            <Translate className="type-badge" value={ `coupon.type_random_${ randomType }` } />
                        </p>
                    </ControlTemplate>
                ) :
                (
                    <ControlTemplate large={ viewOnly } captionKey="random_type">
                        {
                            RANDOM_TYPE.map(item => {
                                return (
                                    <FormGroup check key={ item }>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="random_code_type"
                                                value={ item }
                                                defaultChecked={
                                                    randomType === TYPE_RANDOM_USER ?
                                                        item === TYPE_RANDOM_UPPER_ENG :
                                                        randomType === item
                                                }
                                                onChange={ this.handleRandomTypeChanged }
                                            />
                                            <Translate value={ `coupon.type_random_${ item }` } />
                                        </Label>
                                    </FormGroup>
                                );
                            })
                        }
                    </ControlTemplate>
                )
        );
    }

    renderDiscountType() {
        const { viewOnly } = this.props;
        const { discountType = TYPE_PRICE, } = this.state;

        return (
            viewOnly ?
                (
                    <ControlTemplate large={ viewOnly } captionKey="type">
                        <p className="display-type">
                            <Translate className="type-badge" value={ `coupon.type_${ discountType }` } />
                        </p>
                    </ControlTemplate>
                ) :
                (
                    <ControlTemplate large={ viewOnly } captionKey="type">
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="discount_type"
                                    value={ TYPE_TIME }
                                    defaultChecked={ discountType === TYPE_TIME }
                                    onChange={ this.handleDiscountTypeChanged }
                                />
                                <Translate value={ `coupon.type_${ TYPE_TIME }` } />
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="discount_type"
                                    value={ TYPE_PRICE }
                                    defaultChecked={ discountType === TYPE_PRICE }
                                    onChange={ this.handleDiscountTypeChanged }
                                />
                                <Translate value={ `coupon.type_${ TYPE_PRICE }` } />
                            </Label>
                        </FormGroup>
                    </ControlTemplate>
                )
        );
    }

    renderStatus() {
        const { couponBody } = this.props;
        const { status } = couponBody;
        let color = 'dark';

        switch (status) {
        case STATUS_PUBLISH:
        case STATUS_LIVE:
            color = 'success';
            break;
        case STATUS_DISABLE:
        case STATUS_EXPIRY:
            color = 'danger';
            break;
        default:
            // Do nothing
            break;
        }


        return (
            <FlexGroup start alignStart>
                <Translate value="coupon.details" className="caption-detail" tag="h2" />
                {
                    status !== undefined ?
                        (
                            <Badge color={ color }>
                                <Translate value={ `coupon.status_${ status }` } />
                            </Badge>
                        ) :
                        null
                }
            </FlexGroup>
        );
    }

    renderNewUser() {
        const { viewOnly } = this.props;
        const { isNewUserOnly } = this.state;
        let el;

        el = (viewOnly ? (
            <Translate value={ isNewUserOnly ? 'yes' : 'no' } />
        ) : (
            <FormGroup check>
                <Label check>
                    <Input
                        innerRef={ this.elNewUser }
                        type="checkbox"
                        className="newUserOnly"
                        name="is_new_user_only"
                        onChange={ this.handleIsNewUserChanged }
                        value={ isNewUserOnly }
                        defaultChecked={ isNewUserOnly }
                    />
                    <span>
                        <Translate value="yes" />
                    </span>
                </Label>
            </FormGroup>
        )
        );
        return el;
    }

    renderPromoCode() {
        const { viewOnly, couponBody } = this.props;
        const { couponType = TYPE_COUPON_SINGLE, hadChecked, isCouponExisting } = this.state;
        const checkingClass = (isCouponExisting ? 'fas fa-times' : 'fas fa-check');
        return (
            couponType === TYPE_COUPON_SINGLE ? (
                <ControlTemplate withUnit large={ viewOnly } captionKey="coupon_code">
                    <Input
                        type="text"
                        name="coupon_code"
                        autoComplete="off"
                        required
                        innerRef={ this.elCode }
                        defaultValue={ couponBody.coupon_code }
                        onChange={ this.handleCheckCoupon }
                    />
                    <span className={ hadChecked ? checkingClass : '' } />
                </ControlTemplate>
            ) : (
                <ControlTemplate withUnit large={ viewOnly } captionKey="coupon_code">
                    <Input
                        type="text"
                        name="prefix"
                        autoComplete="off"
                        required
                        innerRef={ this.elCodePrefix }
                        defaultValue={ couponBody.prefix }
                        onChange={ this.handleCheckBulkCoupon }
                    />
                    <Translate className="promo-text" value="+" />
                    <Input
                        type="number"
                        min="1"
                        step="1"
                        name="random_code_length"
                        autoComplete="off"
                        required
                        innerRef={ this.elCodeLength }
                        defaultValue={ couponBody.random_code_length }
                        onChange={ this.handleCheckBulkCoupon }
                    />
                    <Translate className="promo-text" value="digits" />
                    <span className={ hadChecked ? checkingClass : '' } />
                </ControlTemplate>
            )
        );
    }

    render() {
        const { couponBody, viewOnly } = this.props;
        const { discountType = TYPE_PRICE, couponType } = this.state;
        const { discountValue = 0, totalLimit = 0, redeemLimit = 0, eventTotalCount = 0 } = this.state;
        const totalCoupon = couponType === TYPE_COUPON_BULK ?
            eventTotalCount * totalLimit * redeemLimit :
            totalLimit * redeemLimit;
        return (
            <>
                <Card>
                    <CardBody>
                        <Input type="hidden" name="status" value={ couponBody.status } />
                        { this.renderStatus() }
                        <ControlTemplate large captionKey="title">
                            <Input
                                type="text"
                                name="title"
                                autoComplete="off"
                                required
                                defaultValue={ couponBody.title }
                            />
                        </ControlTemplate>
                        <ControlTemplate large captionKey="description">
                            <Input
                                type="text"
                                name="description"
                                autoComplete="off"
                                required
                                defaultValue={ couponBody.description }
                            />
                        </ControlTemplate>
                        { this.renderCouponType() }
                        { this.renderPromoCode() }
                        { couponType === TYPE_COUPON_BULK && (
                            <ControlTemplate large={ viewOnly } captionKey="number_of_event">
                                <Input
                                    innerRef={ this.elEventCount }
                                    type="number"
                                    name="event_total_count"
                                    min="1"
                                    step="1"
                                    max={ Number.MAX_SAFE_INTEGER }
                                    required
                                    onChange={ this.handelNumbersChanged }
                                    defaultValue={ couponBody.event_total_count || null }
                                />
                            </ControlTemplate>
                        ) }
                        { couponType === TYPE_COUPON_BULK && this.renderRandomType() }
                        { this.renderDiscountType() }
                        <ControlTemplate withUnit large={ viewOnly } captionKey="discount">
                            <Input
                                innerRef={ this.elDiscount }
                                type="number"
                                min="1"
                                step="1"
                                name="discount_value"
                                onChange={ this.handelNumbersChanged }
                                required
                                defaultValue={ couponBody.discount_value || '' }
                            />
                            <Translate value={ `coupon.discount_${ discountType }` } />
                        </ControlTemplate>
                        <ControlTemplate large={ viewOnly } captionKey="number_of_people">
                            <Input
                                type="number"
                                min="1"
                                step="1"
                                max={ Number.MAX_SAFE_INTEGER }
                                required
                                innerRef={ this.elTotPeople }
                                onChange={ this.handelNumbersChanged }
                                defaultValue={ couponBody.total_limit || '' }
                            />
                        </ControlTemplate>
                        <ControlTemplate large={ viewOnly } captionKey="number_per_person">
                            <Input
                                type="number"
                                name="redeem_limit"
                                id="redeem_limit"
                                min="1"
                                step="1"
                                required
                                innerRef={ this.elPerPerson }
                                onChange={ this.handelNumbersChanged }
                                defaultValue={ couponBody.redeem_limit || '' }
                            />
                        </ControlTemplate>
                        <ControlTemplate large={ viewOnly } captionKey="new_user_only" required={ false }>
                            { this.renderNewUser() }
                        </ControlTemplate>
                        <section className="total-section">
                            <ControlTemplate captionKey="total_coupons" required={ false }>
                                <div className="total">
                                    <input type="hidden" name="total_limit" value={ totalLimit || 0 } />
                                    <span>{ thousandsSeparator(totalLimit) }</span>
                                    <span>x</span>
                                    <span>{ thousandsSeparator(redeemLimit) }</span>
                                    { couponType === TYPE_COUPON_BULK &&
                                        <>
                                            <span>x</span>
                                            <span>{ thousandsSeparator(eventTotalCount) }</span>
                                        </>
                                    }
                                    <span>=</span>
                                    <span className="emphasis">{ thousandsSeparator(totalCoupon) }</span>
                                </div>
                            </ControlTemplate>
                            <ControlTemplate captionKey="total_cost" required={ false }>
                                <div className="total">
                                    <span>{ thousandsSeparator(discountValue) }</span>
                                    <span>x</span>
                                    <span>{ thousandsSeparator(totalCoupon) }</span>
                                    <span>=</span>
                                    <span className="emphasis">
                                        { thousandsSeparator(discountValue * totalCoupon) }
                                    </span>
                                    <Translate value={ `coupon.discount_${ discountType }` } />
                                </div>
                            </ControlTemplate>
                        </section>
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default connect(state => ({
}))(DetailsSection);
