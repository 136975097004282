import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import FlexGroup from '../FlexGroup';
import './navigation-bar.scss';

const NavigationBar = ({ children, sticky, history, title, to, className, customAction }) => {
    const handleBackTo = () => {
        if (to) {
            history.push(to);
        }
        else {
            history.goBack();
        }
        customAction();
    };
    const navigationCls = classNames({
        'navigation-bar': true,
        'sticky-bar': sticky,
        [className]: !!className
    });

    return (
        <nav className={ navigationCls }>
            <FlexGroup start>
                <button type="button" className="icon fas fa-arrow-left" onClick={ handleBackTo } />
                <h2 className="navigation-title">{ title }</h2>
            </FlexGroup>
            { children }
        </nav>
    );
};

NavigationBar.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    to: PropTypes.string,
    sticky: PropTypes.bool,
    className: PropTypes.string,
    customAction: PropTypes.func,
};


NavigationBar.defaultProps = {
    title: '',
    to: '',
    sticky: false,
    className: '',
    customAction: () => {},
};

export default withRouter(NavigationBar);
