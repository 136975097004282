import { Map, List } from 'immutable';
import creater from './util/creater';
import {
    FETCH_PROMOTION_LIST,
    FETCH_ONE_PROMOTION,
    UPDATE_ONE_PROMOTION,
    CLEAN_ONE_PROMOTION,
} from 'constants/action-type';

const initialState = Map({
    promotionList: Map({}),
    onePromotion: Map({}),
});

const actionMap = {
    [FETCH_PROMOTION_LIST](state, action) {
        const { page_index, page_count, total, data_list, __responseTime } = action.data;

        return state.merge({
            promotionList: Map({
                page_index,
                page_count,
                total,
                data_list: List(data_list),
                __responseTime,
            })
        });
    },

    [FETCH_ONE_PROMOTION](state, action) {
        return state.merge({
            onePromotion: Map(action.data),
        });
    },

    [UPDATE_ONE_PROMOTION](state, action) {
        return state.merge({
            onePromotion: Map(action.data),
        });
    },

    [CLEAN_ONE_PROMOTION](state) {
        return state.merge({
            onePromotion: Map({}),
        });
    },

};

export default creater(actionMap, initialState);
