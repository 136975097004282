import { createFleetApiInstance, getAPIkey } from '../util';

const rental = {
    fetchRentalHistory: (params = {}) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get('/hist_rentals', { params });
    },
    fetchRentalDetail: id => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get(`/hist_rentals/${id}`);
    },
    fetchRefundCoupon: () => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get('/hist_rentals/refund_coupon');
    },
    fetchReturnPhotoUrl: id => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get(`/hist_rentals/${ id }/return_photo_url`);
    },
    refund: params => {
        const { id } = params;
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).post(`/hist_rentals/${ id }/refund`, params);
    },
    updateSubtotal: ({ id, amount }) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).patch(`/hist_rentals/${ id }/subtotal`, {
            sub_total: amount,
        });
    },
};

export default rental;
