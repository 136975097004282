import React from 'react';
import PropTypes from 'prop-types';

import './search-result-wrap.scss';

const searchResultWrap = ({ show, children, message }) => {

    const notFound = () => {
        const noMatch = 'No Match';
        const warningMsg = message || noMatch;
        return (
            <div className="no-match">
                <h2>{ warningMsg }</h2>
            </div>
        );
    };

    return show ? children : notFound();
};

searchResultWrap.propTypes = {
    show: PropTypes.bool.isRequired,
    message: PropTypes.string,
};

searchResultWrap.defaultProps = {
    show: false,
    message: '',
};

export default searchResultWrap;
