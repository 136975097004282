import React, { useEffect } from 'react';
import AuthView from 'components/AuthView';
import Button from 'components/Form/Button';
import FlexGroup from 'components/FlexGroup';
import { useDispatch, useSelector } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import ServiceLevel from 'components/ServiceLevel/ServiceLevel';
import TVOverallServiceLevel from './TVOverallServiceLevel/TVOverallServiceLevel';
import TVOverallScooterModel from './TVOverallScooterModel/TVOverallScooterModel';
import {
    updateDocumentTitle,
} from 'actions';
import { getServicesCaption } from 'helpers/dashboard';
import { reduceCities } from 'helpers/util';
import { SERVICE_TYPE_FREE_FLOATING } from 'constants/scooter';
import { CHART_FONT_STYLE_BOLD } from 'constants/chart';
import { DASH_COLOR_WHITE } from 'constants/dashboard';
import { TV_MONITOR_SCALE } from 'constants/tvMonitor';

import './tv-monitor.scss';
import { useLocation } from "react-router-dom";
// import { useGetStatisticsQuery } from "redux/services/dashboardApi";
import { useGetStatisticsQuery } from 'redux/hook/useDashboard';

const DATA_REFRESH_INTERVAL = 5 * 60 * 1000;
const SERVICE_LEVEL_SIZE = +( 145.7 / TV_MONITOR_SCALE ).toFixed(1);
const SERVICE_LEVEL_THICK = +( 12.7 / TV_MONITOR_SCALE ).toFixed(1);

const TVMonitor = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const citiesList = useSelector(state => state.cities.get('cities').toJS());

    const {
        data: monitorData,
        isLoading,
    } = useGetStatisticsQuery(undefined, {
        pollingInterval: DATA_REFRESH_INTERVAL,
    });

    useEffect(() => {
        const { pathname } = location;
        dispatch(updateDocumentTitle(`routes.${ pathname }`));

    }, [dispatch, location]);

    const gaugeStyles = {
        safety: {
            color: DASH_COLOR_WHITE,
        },
    };

    const labelStyle = {
        titleStyle: `"
            font-size: ${ 39.8 / TV_MONITOR_SCALE }px;
            font-weight: bold;
            font-family: ${ CHART_FONT_STYLE_BOLD };
        "`,
        unitStyle: `"
            font-size: ${ 28.7 / TV_MONITOR_SCALE };
            font-weight: bold;
            font-family: ${ CHART_FONT_STYLE_BOLD };
        "`,
    };

    const fullscreen = () => {
        const elem = document.getElementById('root');
        let promise;

        promise = elem.webkitRequestFullscreen();

        if (elem.requestFullscreen) {
            promise = elem.requestFullscreen();
        }
        else if (elem.msRequestFullscreen) {
            promise = elem.msRequestFullscreen();
        }
        else if (elem.mozRequestFullScreen) {
            promise = elem.mozRequestFullScreen();
        }
        else if (elem.webkitRequestFullscreen) {
            promise = elem.webkitRequestFullscreen();
        }
        return promise.catch(err => {
            console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
    }

    const getCityCaption = (city) => {
        const citiesObject = reduceCities(citiesList);
        return getServicesCaption(city, citiesObject);
    }

    const getFreeFloatingCities = (list) => {
        return (list && list.length) &&
                list.filter(({ service_type }) => (service_type === SERVICE_TYPE_FREE_FLOATING));
    }

    const cities = monitorData?.tvMonitor?.overallList || getFreeFloatingCities(monitorData?.tvMonitor?.overallList) || [];

    return (
        <AuthView className="tv-monitor">
            <Button
                type="button"
                className="btn btn-fullscreen"
                onClick={ fullscreen }
                outline
            >
                <Translate value="tv_monitor.to_fullscreen" />
            </Button>
            <FlexGroup spaceBetween alignStart>
                <FlexGroup className="col-left" alignStart>
                    <Translate
                        className="block-title"
                        value="dashboard.service_level"
                        tag="h1"
                    />
                    {
                        cities && (
                            <FlexGroup className="service-level">
                                {
                                    cities.map(city => (
                                        <ServiceLevel
                                            title={ getCityCaption(city) }
                                            chartTitle="dashboard.service_level"
                                            data={ city }
                                            key={ `${city.service_type}${city.city_id}` }
                                            width={ SERVICE_LEVEL_SIZE }
                                            height={ SERVICE_LEVEL_SIZE }
                                            thick={ SERVICE_LEVEL_THICK }
                                            gaugeStyles={ gaugeStyles }
                                            labelStyle={ labelStyle }
                                            animateInfo={ {
                                                size: 35 / TV_MONITOR_SCALE
                                            } }
                                        />
                                    ))
                                }
                            </FlexGroup>
                        )
                    }
                </FlexGroup>
                <FlexGroup className="col-right">
                    {
                        isLoading === false && (
                            <>
                                <TVOverallServiceLevel
                                    title={ I18n.t('dashboard.overall_service_level') }
                                    overall={ monitorData?.tvMonitor?.overallFreeFloating }
                                    list={ monitorData?.tvMonitor?.overallList }
                                    listCaption={ getCityCaption }
                                />
                                <TVOverallScooterModel
                                    title={ I18n.t('dashboard.overall_scooter_model') }
                                    data={ monitorData?.scooterModels }
                                />
                            </>
                        )
                    }
                </FlexGroup>
            </FlexGroup>
        </AuthView>
    )
}

export default TVMonitor;

