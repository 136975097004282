import React from 'react';
import { Translate } from 'react-redux-i18n';
import moment from 'moment';
import PropTypes from 'prop-types';
import './period.scss';

const Period = ({ fromTime, toTime }) => {
    let el = null;

    if (fromTime && toTime) {
        el = (
            <div className="period">
                <Translate className="periods-title" value="rental.record_periods" />
                <span>{ moment(fromTime).format('YYYY-MM-DD') }</span>
                <span className="divider">-</span>
                <span>{ moment(toTime).format('YYYY-MM-DD') }</span>
            </div>
        );
    }

    return el;
};

Period.propTypes = {
    fromTime: PropTypes.oneOfType([
        PropTypes.instanceOf(moment),
        PropTypes.string,
    ]),
    toTime: PropTypes.oneOfType([
        PropTypes.instanceOf(moment),
        PropTypes.string,
    ]),
};

Period.defaultProps = {
    fromTime: undefined,
    toTime: undefined,
};

export default Period;
