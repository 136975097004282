import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from 'reactstrap';
import './copy-to-clip-board.scss';

class CopyToClipBoard extends React.Component {
    static propTypes = {
        hide: PropTypes.bool,
    };
    static defaultProps = {
        hide: false,
    };
    constructor(props) {
        super(props);
        this.state = {
            showTip: false,
        };
        this.ref = React.createRef();
    };
    showTips = () => {
        this.setState({
            showTip: true,
        });
        setTimeout(
            () => this.setState({
                showTip: false,
            }), 400
        );
    };
    render() {
        const { showTip } = this.state;
        const { hide, children } = this.props;
        const onClick = e => {
            e.stopPropagation();
            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNodeContents(e.target.parentElement);
            selection.removeAllRanges();
            selection.addRange(range);
            const copied = document.execCommand('copy');
            selection.removeAllRanges();
            if (copied) {
                this.showTips();
            }
        };

        const btnClass = classNames({
            'far': true,
            'fa-copy': true,
            'btn-copy': true,
            'hide': hide,
        });

        return (
            <div className="copy-to-clip-board" ref={ this.ref }>
                <div className="copy-to-clip-board-display">
                    <span ref={ this.ref }>
                        { children }
                    </span>
                    <span
                        className={ btnClass }
                        onClick={ onClick }
                    />
                </div>
                <Tooltip placement="top" isOpen={ showTip } target={ this.ref }>
                    copied
                </Tooltip>
            </div>
        );
    }
};

export default CopyToClipBoard;
