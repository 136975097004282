import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Badge, Label, FormGroup, Input as ReactInput } from 'reactstrap';
import Input from 'components/Form/Input';
import FlexGroup from 'components/FlexGroup';
import {
    TYPE_TEXT,
    TYPE_DATETIME,
    TYPE_URL,
    TYPE_FILE
} from 'components/Form/Input/input-type';
import Datetime from 'components/Datetime';
import { Refresh } from 'components/Pagination';
import { Translate, I18n } from 'react-redux-i18n';
import {
    STATUS_DISABLE,
    STATUS_EXPIRY,
    STATUS_LIVE,
    STATUS_PUBLISH,
    LAUNCH_MODE_INAPP_WEBVIEW,
    LAUNCH_MODE_DEFAULT_BROWSER
} from 'constants/ad';
import { empty } from 'helpers/util';
import ControlTemplate from './ControlTemplate';
import moment from 'moment';

class DetailsSection extends Component {
    static propTypes = {
        data: PropTypes.shape({}),
        viewOnly: PropTypes.bool,
        onFileImport: PropTypes.func,
        onFetch: PropTypes.func,
        elFileInput: PropTypes.oneOfType([
            PropTypes.shape(),
            PropTypes.node,
        ]),
    };
    static defaultProps = {
        data: {},
        viewOnly: false,
        onFileImport: () => {},
        onFetch: () => {},
        elFileInput: undefined,

    };
    constructor(props) {
        super(props);
        this.elValidFrom = React.createRef();
        this.elValidTo = React.createRef();
        this.elThumb = React.createRef();
        const { data } = props;
        this.launchType = data.url_launch_mode;
    };
    importFile = id => e => {
        const { onFileImport } = this.props;
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = e => {
            this.elThumb.current.src = URL.createObjectURL(file);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
        onFileImport(file);
    };

    validateDatetime = datetime => {
        let validity = 'ad.invalid_date';
        if (datetime.isValid && datetime.isValid()) {
            validity = '';
        }
        return validity;
    }

    handleLaunchTypeChanged = e => {
        this.launchType = e.currentTarget.value;
    };

    handleDatesChange = field => datetime => {
        const isFromChanged = field === 'from';
        const fromEl = this.elValidFrom.current;
        const toEl = this.elValidTo.current;
        const from = isFromChanged ? datetime : moment(fromEl.value);
        const to = isFromChanged ? moment(toEl.value) : datetime;
        let fromValidity = this.validateDatetime(from);
        let toValidity = this.validateDatetime(to);
        if (fromValidity === '' && toValidity === '') {
            if ( from > to ) {
                fromValidity = 'ad.wrong_sequence_date';
                toValidity = 'ad.wrong_sequence_date';
            }
        }
        fromEl.setCustomErrorMessage(I18n.t(fromValidity));
        toEl.setCustomErrorMessage(I18n.t(toValidity));
    }

    render() {
        const { data, viewOnly, onFetch, elFileInput } = this.props;
        const { id, title, description, web_url, img_url, valid_from, valid_to, __responseTime } = data;
        const Status = () => {
            const { data } = this.props;
            const { status } = data;
            let color = 'dark';

            switch (status) {
            case STATUS_PUBLISH:
            case STATUS_LIVE:
                color = 'success';
                break;
            case STATUS_DISABLE:
            case STATUS_EXPIRY:
                color = 'danger';
                break;
            default:
                // Do nothing
                break;
            }
            return (
                status !== undefined ?
                    (
                        <Badge color={ color }>
                            <Translate value={ `ad.status_${ status }` } />
                        </Badge>
                    ) :
                    null
            );
        };
        const renderPublishDetails = (data) => {
            const { publisher, publish_time } = data;
            return (
                publisher && publish_time ?
                    (
                        <Card>
                            <CardBody>
                                <ControlTemplate large required={ false } captionKey="published_by">
                                    <span className="publisher">{ publisher }</span>
                                </ControlTemplate>
                                <ControlTemplate large required={ false } captionKey="published_time">
                                    <Datetime time={ publish_time } />
                                </ControlTemplate>
                            </CardBody>
                        </Card>
                    ) :
                    null
            );
        };
        const renderVisualInput = ({ defaultValue }) => {
            return (
                <Input
                    type={ TYPE_FILE }
                    withCaption={ false }
                    accept="image/png"
                    required={ !defaultValue }
                    onKeyDown={ e => e.preventDefault() }
                    onPaste={ e => e.preventDefault() }
                    onChange={ this.importFile(id) }
                    viewMode={ viewOnly }
                    innerRef={ elFileInput }
                />
            );
        };
        const renderThumb = ({ defaultSrc }) => {
            return (
                <img className="thumb" src={ defaultSrc } alt="" ref={ this.elThumb } />
            );
        };
        const renderLaunchType = () => {
            const { viewOnly } = this.props;
            const launchType = this.launchType ? this.launchType : LAUNCH_MODE_INAPP_WEBVIEW;

            return (
                viewOnly ?
                    (
                        <p className="display-type">
                            <Translate className="type-badge" value={ `ad.type_launch_${ launchType }` } />
                        </p>
                    ) :
                    (
                        <div className="radio-form-group">
                            <FormGroup check>
                                <Label check>
                                    <ReactInput
                                        type="radio"
                                        name="url_launch_mode"
                                        value={ LAUNCH_MODE_INAPP_WEBVIEW }
                                        defaultChecked={ launchType === LAUNCH_MODE_INAPP_WEBVIEW }
                                        onChange={ this.handleLaunchTypeChanged }
                                    />
                                    <Translate value={ `ad.type_launch_${ LAUNCH_MODE_INAPP_WEBVIEW }` } />
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <ReactInput
                                        type="radio"
                                        name="url_launch_mode"
                                        value={ LAUNCH_MODE_DEFAULT_BROWSER }
                                        defaultChecked={ launchType === LAUNCH_MODE_DEFAULT_BROWSER }
                                        onChange={ this.handleLaunchTypeChanged }
                                    />
                                    <Translate value={ `ad.type_launch_${ LAUNCH_MODE_DEFAULT_BROWSER }` } />
                                </Label>
                            </FormGroup>
                        </div>
                    )
            );
        };

        return (
            <>
                <FlexGroup spaceBetween>
                    <FlexGroup>
                        <Translate value="ad.details" className="caption" tag="h2" />
                        <Status />
                    </FlexGroup>
                    { !empty(data) && (
                        <Refresh time={ __responseTime } onClick={ onFetch } />
                    ) }
                </FlexGroup>
                {renderPublishDetails(data)}
                <Card>
                    <CardBody>
                        <ControlTemplate large captionKey="title" required={ !viewOnly }>
                            <Input
                                type={ TYPE_TEXT }
                                name="title"
                                withCaption={ false }
                                required={ !viewOnly }
                                value={ title }
                                viewMode={ viewOnly }
                            />
                        </ControlTemplate>
                        <ControlTemplate large captionKey="description" required={ !viewOnly }>
                            <Input
                                type={ TYPE_TEXT }
                                name="description"
                                withCaption={ false }
                                required
                                value={ description }
                                viewMode={ viewOnly }
                            />
                        </ControlTemplate>
                        <ControlTemplate large captionKey="launch_mode" required={ !viewOnly }>
                            { renderLaunchType() }
                        </ControlTemplate>
                        <ControlTemplate captionKey="valid_from" required={ !viewOnly }>
                            <Input
                                type={ TYPE_DATETIME }
                                innerRef={ this.elValidFrom }
                                withCaption={ false }
                                dateFormat={ I18n.t('datetime_format.date') }
                                timeFormat={ I18n.t('datetime_format.time') }
                                name="valid_from"
                                required
                                value={
                                    valid_from ?
                                        moment(valid_from).format(I18n.t('datetime_format.long')) :
                                        undefined
                                }
                                onChange={ this.handleDatesChange('from') }
                                closeOnSelect
                                viewMode={ viewOnly }
                            />
                        </ControlTemplate>
                        <ControlTemplate captionKey="valid_to" required={ !viewOnly }>
                            <Input
                                type={ TYPE_DATETIME }
                                innerRef={ this.elValidTo }
                                withCaption={ false }
                                dateFormat={ I18n.t('datetime_format.date') }
                                timeFormat={ I18n.t('datetime_format.time') }
                                name="valid_to"
                                required
                                value={
                                    valid_to ?
                                        moment(valid_to).format(I18n.t('datetime_format.long')) :
                                        undefined
                                }
                                onChange={ this.handleDatesChange('to') }
                                closeOnSelect
                                viewMode={ viewOnly }
                                isEnd
                            />
                        </ControlTemplate>

                        <ControlTemplate large captionKey="link" required={ !viewOnly }>
                            <Input
                                type={ TYPE_URL }
                                name="web_url"
                                withCaption={ false }
                                required
                                value={ web_url }
                                viewMode={ viewOnly }
                            />
                        </ControlTemplate>

                        <ControlTemplate large captionKey="visual" required={ !viewOnly }>
                            { !viewOnly && renderVisualInput({ defaultValue: img_url }) }
                            { renderThumb({ defaultSrc: img_url }) }
                        </ControlTemplate>
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default DetailsSection;
