/**
 * Reducer - Async Handler
 */
import { Map, } from 'immutable';
import { ASYNC_START, ERROR, ASYNC_FINISHED } from 'constants/action-type';
import creater from './creater';

const initialState = Map({
    asyncStart: false,
    asyncError: undefined,
});

const actionsMap = {
    [ASYNC_START]: state => {
        return state.merge({
            asyncStart: true,
        });
    },

    [ERROR]: (state, action) => {
        return state.merge({
            asyncError: action.error,
        });
    },

    [ASYNC_FINISHED]: state => {
        return state.merge({
            asyncStart: false,
        });
    },
};

export default creater(actionsMap, initialState);
