import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './utils';

const GET_SCOOTERS= '/scooters';

export const scooterApi = createApi({
    reducerPath: 'scooterApi',
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        getFirmwareVersion: builder.query({
            query: ({ params: { id } }) => ({
                url: `${ GET_SCOOTERS }/${ id }/firmware_version`,
            }),
            forceRefetch: () => true,
        }),
    })
});

export const {
    useLazyGetFirmwareVersionQuery,
} = scooterApi;
