import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import isEqual from 'react-fast-compare';
import { animated } from 'react-spring';
import debounce from 'lodash/debounce';
import { LIST_ROW } from './type';

const OrderableListRow = (Field) => {
    class ListRow extends React.Component {
        static displayName = 'OrderableListRow'

        static propTypes = {
            rowClassName: PropTypes.string,
            rowHeight: PropTypes.shape({}).isRequired,
            opacity: PropTypes.shape({}).isRequired,
            transform: PropTypes.shape({}).isRequired,
            boxShadow: PropTypes.shape({}).isRequired,
            columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
            rowData: PropTypes.shape({}).isRequired,
            dndProps: PropTypes.shape({}).isRequired,
            connectDropTarget: PropTypes.func.isRequired,
            connectDragSource: PropTypes.func.isRequired,
            connectDragPreview: PropTypes.func.isRequired,
            isDragging: PropTypes.bool.isRequired,
        }

        static defaultProps = {
            rowClassName: undefined,
        }

        rowRef = React.createRef()

        constructor() {
            super();
            /**
             * Due to moveRow function have been calling several times during animation transition
             * that would cause oderableListView works not correctly,
             * so use debounce function makes moveRow only called once at first.
             */
            this.debouncedMoveRow = debounce(this.moveRow, 50, { leading: true, trailing: false });
        }

        shouldComponentUpdate(nextProps) {
            return !isEqual(this.props, nextProps);
        }

        moveRow = (item, id, index) => {
            const { dndProps } = this.props;
            const { moveRow } = dndProps;

            moveRow(id, index);
            item.index = index;
        }

        setRef = node => {
            const { connectDragSource, connectDropTarget, connectDragPreview } = this.props;
            this.rowRef.current = node;
            connectDragSource(connectDropTarget(node));
            connectDragPreview(getEmptyImage());
        }

        render() {
            const { rowClassName, rowHeight, opacity, transform, boxShadow, columns, rowData, isDragging } = this.props;
            const className = classNames('list-row', rowClassName);
            let zIndex;

            if (isDragging) {
                zIndex = 2;
            }

            const rowStyle = {
                height: rowHeight,
                opacity,
                transform: transform.interpolate((y, scale) => `translate3d(0,${y}px, 0) scale(${scale})`),
                boxShadow: boxShadow.interpolate(shadow => `0 0 ${shadow}px 0 rgba(0, 0, 0, 0.2)`),
                zIndex,
            };

            return (
                <animated.div ref={ this.setRef } className={ className } style={ rowStyle }>
                    { columns.map(({ key, width }) => (
                        <Field key={ key } width={ width } id={ rowData.id } name={ key } value={ rowData[key] } />
                    )) }
                </animated.div>
            );
        }
    }

    return DragSource(
        LIST_ROW,
        {
            beginDrag(props) {
                const { rowData, index, dndProps } = props;
                const { id } = rowData;
                const { startMovingRow } = dndProps;

                startMovingRow(id);

                return {
                    type: LIST_ROW,
                    id,
                    index,
                };
            },
            endDrag(props) {
                const { dndProps } = props;
                const { rowDidMove } = dndProps;
                rowDidMove();
            }
        },
        (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
            isDragging: monitor.isDragging(),
        }),
    )(DropTarget(
        LIST_ROW,
        {
            hover(props, monitor, component) {
                const { rowData, dndProps } = props;
                const { findRow } = dndProps;
                const draggingItem = monitor.getItem();
                const hoverIndex = findRow(rowData.id).index;
                const draggingIndex = draggingItem.index;

                if (draggingIndex === hoverIndex) {
                    return;
                }

                const { rowRef } = component;
                const hoverBoundingRect = rowRef.current?.getBoundingClientRect();
                const hoverY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 5;
                const clientOffset = monitor.getClientOffset();
                const hoverClientY = clientOffset.y - hoverBoundingRect.top;

                if (draggingIndex < hoverIndex && hoverClientY < hoverY) {
                    return;
                }

                if (draggingIndex > hoverIndex && hoverClientY > hoverY * 4) {
                    return;
                }

                const { debouncedMoveRow } = component;
                debouncedMoveRow(draggingItem, draggingItem.id, hoverIndex);
            }
        },
        connect => ({
            connectDropTarget: connect.dropTarget(),
        }),
    )(ListRow));
};

export default OrderableListRow;
