import {
    DASH_OVERALL,
    DASH_DOTS,
    DASH_TICKET_SWAP,
    DASH_TICKET_MAINTAIN,
    DASH_TICKET_DISPATCH,
    DASH_TICKET_OTHER,
    DASH_TICKET_NONE,
} from 'constants/dashboard';

const dashboard = {
    'document_title': 'Dashboard',
    [`type_${ DASH_OVERALL }`]: 'Overall',
    [`type_${ DASH_DOTS }`]: 'DOTS',
    'service_level': 'Service Level',
    'tickets_status': 'Tickets Status',
    'total_scooters': 'Total Scooters',
    'total': 'Total',
    'overall_service_level': 'Overall Service Level',
    'service_type_dots': 'DOTS',
    'overall_scooter_model': 'Scooter Model',
    [`tickets_category_${ DASH_TICKET_SWAP }`]: 'Swap',
    [`tickets_category_${ DASH_TICKET_MAINTAIN }`]: 'Maintenance',
    [`tickets_category_${ DASH_TICKET_DISPATCH }`]: 'Dispatch',
    [`tickets_category_${ DASH_TICKET_OTHER }`]: 'Others',
    [`tickets_category_${ DASH_TICKET_NONE }`]: 'None',
    'level': 'LEVEL',
    'empty_warning': 'Please ensure the width of browser keeps over <span class="point">1024px</span>',
};

export default dashboard;
