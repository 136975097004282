import React, { Component } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { Card, CardBody } from 'reactstrap';
import AuthFeature from 'components/AuthFeature';
import {
    AUTH_EDIT_CUSTOMER_DOCUMENT_INFO,
} from 'constants/permission';
import {
    APPROVAL_APPROVE_ID,
    APPROVAL_REVOKE_ID,
    APPROVAL_APPROVE_LIGHT_DUTY,
    APPROVAL_APPROVE_HEAVY_DUTY,
    APPROVAL_REVOKE_DRIVER_LICENSE,
    APPROVAL_KEEP_CURRENT_VALUE,
} from 'constants/customer';
import Field, { FIELD_TYPE } from './Field';

class QualificationForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            requireIDandBirthdate: false,
            requireIDFormatCheck: true,
        };
    }

    handleNationalityChanged = e => {
        this.setState({
            requireIDFormatCheck: !!e.currentTarget.value,
        });
    }

    handleApprovalChanged(isApproval) {
        this.setState({
            requireIDandBirthdate: isApproval,
        });
    }

    render() {
        const { requireIDandBirthdate, requireIDFormatCheck } = this.state;
        const editing = true;

        return (
            <AuthFeature requiredList={ [AUTH_EDIT_CUSTOMER_DOCUMENT_INFO] }>
                <Card className="verification-sec">
                    <CardBody>
                        <Translate value="customer.edited_verification" tag="h3" />
                        <Field
                            type={ FIELD_TYPE.RADIO }
                            name="nationality"
                            title="customer.nationality"
                            value="1"
                            options={ [
                                {
                                    value: '1',
                                    text: I18n.t('customer.resident'),
                                    onChange: this.handleNationalityChanged,
                                },
                                {
                                    value: '',
                                    text: I18n.t('customer.foreigner'),
                                    onChange: this.handleNationalityChanged,
                                },
                            ] }
                            editing={ editing }
                        />
                        <Field
                            type={ FIELD_TYPE.INPUT }
                            name="new_id"
                            editing={ editing }
                            pattern={ requireIDFormatCheck ? '[A-Za-z0-9]{10}' : undefined }
                            title="New ID"
                            required={ requireIDandBirthdate }
                        />
                        <Field
                            type={ FIELD_TYPE.DATETIME }
                            name="birth"
                            title="Date of birth"
                            editing={ editing }
                            required={ requireIDandBirthdate }
                        />
                        <Field
                            type={ FIELD_TYPE.RADIO }
                            name="id_approve"
                            title="customer.id_approve"
                            value={ APPROVAL_KEEP_CURRENT_VALUE }
                            options={ [
                                {
                                    value: APPROVAL_APPROVE_ID,
                                    text: I18n.t(`customer.approval_id_${APPROVAL_APPROVE_ID}`),
                                    onChange: this.handleApprovalChanged.bind(this, true),
                                },
                                {
                                    value: APPROVAL_REVOKE_ID,
                                    text: I18n.t(`customer.approval_id_${APPROVAL_REVOKE_ID}`),
                                    onChange: this.handleApprovalChanged.bind(this, false),
                                },
                                {
                                    value: APPROVAL_KEEP_CURRENT_VALUE,
                                    text: I18n.t(
                                        `customer.approval_${APPROVAL_KEEP_CURRENT_VALUE}`
                                    ),
                                    onChange: this.handleApprovalChanged.bind(this, false),
                                },
                            ] }
                            editing={ editing }
                        />
                        <Field
                            type={ FIELD_TYPE.RADIO }
                            name="license_approve"
                            title="customer.license_approve"
                            value={ APPROVAL_KEEP_CURRENT_VALUE }
                            options={ [
                                {
                                    value: APPROVAL_APPROVE_LIGHT_DUTY,
                                    text: I18n.t(`customer.approval_license_${ APPROVAL_APPROVE_LIGHT_DUTY }`),
                                    onChange: this.handleApprovalChanged.bind(this, true),
                                },
                                {
                                    value: APPROVAL_APPROVE_HEAVY_DUTY,
                                    text: I18n.t(`customer.approval_license_${ APPROVAL_APPROVE_HEAVY_DUTY }`),
                                    onChange: this.handleApprovalChanged.bind(this, true),
                                },
                                {
                                    value: APPROVAL_REVOKE_DRIVER_LICENSE,
                                    text: I18n.t(`customer.approval_license_${ APPROVAL_REVOKE_DRIVER_LICENSE }`),
                                    onChange: this.handleApprovalChanged.bind(this, false),
                                },
                                {
                                    value: APPROVAL_KEEP_CURRENT_VALUE,
                                    text: I18n.t(
                                        `customer.approval_${ APPROVAL_KEEP_CURRENT_VALUE }`
                                    ),
                                    onChange: this.handleApprovalChanged.bind(this, false),
                                },
                            ] }
                            editing={ editing }
                        />
                    </CardBody>
                </Card>
            </AuthFeature>
        );
    }
}

export default QualificationForm;
