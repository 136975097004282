import { createAmazonApiInstance } from '../util';

const general = {
    uploadFile: ({ url, file, contentType }) => {
        return createAmazonApiInstance(url, {
            'Content-Type': contentType ?? file.type,
        }).put('', file);
    },
};

export default general;
