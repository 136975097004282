import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlexGroup from 'components/FlexGroup';
import Input, {
    TYPE_TEXT,
    TYPE_EMAIL,
    TYPE_SELECT,
} from 'components/Form/Input';
import {
    FACTOR_PHONE,
    FACTOR_EMAIL,
    FACTOR_ID,
    VERIFY_OPTIONS,
    FACTOR_CONFIG,
} from './';

class TwoFactor extends Component {
    static propTypes = {
        onChange: PropTypes.func,
    };

    static defaultProps = {
        onChange: () => {},
    };

    constructor(props) {
        super(props);
        this.state = {
            lastFactor: FACTOR_ID,
            phone: undefined,
            email: undefined,
            idNumber: undefined,
        };
    }

    handleFactorChange = field => e => {
        const { onChange } = this.props;
        const currentState = onChange(this.state, field)(e);
        this.setState(currentState);
    };

    getFactorOptions = () => {
        return VERIFY_OPTIONS.filter(({ value }) => (value !== FACTOR_PHONE));
    };

    handleLastFactorChange = e => {
        const { value } = e.currentTarget;
        this.setState({
            phone: this.state.phone,
            email: undefined,
            idNumber: undefined,
            lastFactor: value,
        });
    };
    renderLastFactor = () => {
        const { lastFactor, email, idNumber } = this.state;
        let el = null;
        switch(lastFactor) {
            case FACTOR_EMAIL:
                el = (
                    <Input
                        type={ TYPE_EMAIL }
                        name={ FACTOR_EMAIL }
                        value={ email }
                        caption="customer.email"
                        onChange={ this.handleFactorChange(TYPE_EMAIL) }
                        required={ lastFactor === FACTOR_EMAIL }
                    />
                );
                break;
            case FACTOR_ID:
                el = (
                    <Input
                        type={ TYPE_TEXT }
                        name={ FACTOR_ID }
                        value={ idNumber }
                        maxLength={ FACTOR_CONFIG[FACTOR_ID].maxLength }
                        onChange={ this.handleFactorChange(FACTOR_ID) }
                        caption="factor_auth.id_no"
                        required={ lastFactor === FACTOR_ID }
                    />
                );
                break;
            default:
        }
        return el;
    };


    render () {
        const { lastFactor, phone } = this.state;
        const factorOptions = this.getFactorOptions();
        return (
            <>
                <Input
                    type={ TYPE_TEXT }
                    name={ FACTOR_PHONE }
                    value={ phone }
                    caption="customer.mobile"
                    onChange={ this.handleFactorChange(FACTOR_PHONE) }
                    maxLength={ FACTOR_CONFIG[FACTOR_PHONE].maxLength }
                    required
                />
                <FlexGroup className="last-factor-group">
                    <Input
                        type={ TYPE_SELECT }
                        caption="factor_auth.data"
                        selected={ lastFactor }
                        value={ factorOptions }
                        onChange={ this.handleLastFactorChange }
                        required
                    />
                    { this.renderLastFactor() }
                </FlexGroup>
            </>
        );
    };
};

export default TwoFactor;
