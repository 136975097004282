import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FlexGroup from 'components/FlexGroup';

import './single-bar.scss';

const SingleBar = ({ values, labels, colors, barMaxHeight, barWidth, zeroHeight, className, renderLabel }) => {

    const style = classNames({
        'single-bar': true,
        [className]: true,
    });

    const totalValue = values.reduce((sum, value) => sum + value );

    const getBarHeight = (value) => {
        const height = ( barMaxHeight * ( value / totalValue )).toFixed(2);
        return !value ? zeroHeight : height;
    };

    return (
        <FlexGroup spaceBetween className={ style }>
            {
                values.map( (value, index) => {
                    const colorStyle = colors.length ? (colors[index] || colors[0]) : '';
                    const barStyle = {
                        'height': getBarHeight(value) + 'px',
                        'width': barWidth,
                        'backgroundColor': colorStyle,
                    };
                    return (
                        <FlexGroup alignEnd className="single-bar-item" key={ `${ colors[index] }${value}` }>
                            <div className="bar" style={ barStyle } />
                            <FlexGroup className="text">
                                {
                                    renderLabel(value, labels[index], colorStyle)
                                }
                            </FlexGroup>
                        </FlexGroup>
                    );
                })
            }
        </FlexGroup>
    );
};

SingleBar.propTypes = {
    values: PropTypes.arrayOf(PropTypes.number).isRequired,
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    colors: PropTypes.arrayOf(PropTypes.string),
    barMaxHeight: PropTypes.number,
    barWidth: PropTypes.string,
    zeroHeight: PropTypes.number,
    className: PropTypes.string,
    renderLabel: PropTypes.func,
};

SingleBar.defaultProps = {
    colors: ['#7c8c8f'],
    barMaxHeight: 82,
    barWidth: '20px',
    zeroHeight: 1,
    className: '',
    renderLabel: () => {},
};


export default SingleBar;
