import React from 'react';
import PropTypes from 'prop-types';
import ChoiceGroup from 'components/ChoiceGroup';
import SearchField from 'components/SearchField';

const ChoiceGroupField = ({ ...props }) => {
    const { renderTitle, defaultChecked, placeholder, options, value, onChoiceChange, onSubmit, onChange } = props;

    return (
        <div className="choice-group-field">
            {
                renderTitle()
            }
            <ChoiceGroup
                defaultChecked={ defaultChecked }
                options={ options }
                onChange={ onChoiceChange }
            />
            <SearchField
                placeholder={ placeholder }
                onChange={ onChange }
                onSubmit={ onSubmit }
                value={ value }
            />
        </div>
    );
};
ChoiceGroupField.propTypes = {
    renderTitle: PropTypes.func,
    defaultChecked: PropTypes.node,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({})),
    onChoiceChange: PropTypes.func,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
};
ChoiceGroupField.defaultProps = {
    renderTitle: () => {},
    defaultChecked: '',
    placeholder: undefined,
    value: undefined,
    options: [],
    onChoiceChange: () => {},
    onChange: () => {},
    onSubmit: () => {},
};

export default ChoiceGroupField;
