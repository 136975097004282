import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import MultiselectFilter from 'components/Filter/MultiselectFilter';
import ChoiceGroupField from 'components/ChoiceGroup/ChoiceGroupField';
import FlexGroup from 'components/FlexGroup';
import DatetimeRangeFilter from 'components/Filter/DatetimeRangeFilter';
import moment from 'moment';

import {
    CLAIM_TYPE_GROUP,

    FEE_TYPE_GROUP,

    FEE_STATUS_UNPAID,
    FEE_STATUS_FAILED,
    FEE_STATUS_PAID,
    FEE_STATUS_REFUND,
    FEE_STATUS_CANCEL,
    FEE_STATUS_PROCESSING,
    FEE_STATUS_PROCESS_SUCCESS,
    FEE_STATUS_PROCESS_FAILED,
    FEE_STATUS_ABSORBED_BY_INTR,

    FEE_SUBTYPE_VIOLATE_ILLEGAL_PARKING,
    FEE_SUBTYPE_VIOLATE_NON_ILLEGAL_PARKING,
    FEE_SUBTYPE_CHARGE_PRIVATE_PARKING,
    FEE_SUBTYPE_CHARGE_TOWING_YARD,
    FEE_SUBTYPE_TOWING_ILLEGAL_PARKING,
    FEE_SUBTYPE_TOWING_TOWING_YARD,
    FEE_SUBTYPE_TOWING_OUTSIDE,
    FEE_SUBTYPE_TOWING_TRAFFIC_ACCIDENT,
    FEE_SUBTYPE_TOWING_DRUNK_DRIVING,
    FEE_SUBTYPE_REPAIR_CAR_DAMAGE,
    FEE_SUBTYPE_REPAIR_SUSPECTED_DAMAGE,
} from 'constants/additional-fee';

export const SEARCH = 'search';
export const SEARCH_BY_RENTAL = 'rental_id';
export const SEARCH_BY_CUSTOMER = 'customer_id';
export const SEARCH_BY_PLATE = 'plate_no';
export const SEARCH_BY_FINE_NO = 'ticket_no';
export const CLAIM_TYPE = 'claim_type';
export const FEE_TYPE = 'fee_type';
export const FEE_SUBTYPE = 'subtype';
export const STATUS = 'status';
export const CREATE_TIME = 'create_time';
export const defaultRange = [90, 'days'];

export const getClaimTypeOptions = () => [
    ...CLAIM_TYPE_GROUP,
].map(
    value => ({
        text: I18n.t(`additional_fee.claim_type_map.${ value }`),
        value,
    })
);

export const getFeeTypeOptions = () => [
    ...FEE_TYPE_GROUP,
].map(
    value => ({
        text: I18n.t(`additional_fee.fee_type_map.${ value }`),
        value,
    })
);

export const getFeeStatusOptions = () => [
    FEE_STATUS_UNPAID,
    FEE_STATUS_FAILED,
    FEE_STATUS_PAID,
    FEE_STATUS_REFUND,
    FEE_STATUS_CANCEL,
    FEE_STATUS_PROCESSING,
    FEE_STATUS_PROCESS_SUCCESS,
    FEE_STATUS_PROCESS_FAILED,
    FEE_STATUS_ABSORBED_BY_INTR,
].map(
    value => ({
        text: I18n.t(`additional_fee.fee_status_map.${ value }`),
        value,
    })
);

export const getSubtypeOptions = () => [
    FEE_SUBTYPE_VIOLATE_ILLEGAL_PARKING,
    FEE_SUBTYPE_VIOLATE_NON_ILLEGAL_PARKING,
    FEE_SUBTYPE_CHARGE_PRIVATE_PARKING,
    FEE_SUBTYPE_CHARGE_TOWING_YARD,
    FEE_SUBTYPE_TOWING_ILLEGAL_PARKING,
    FEE_SUBTYPE_TOWING_TOWING_YARD,
    FEE_SUBTYPE_TOWING_OUTSIDE,
    FEE_SUBTYPE_TOWING_TRAFFIC_ACCIDENT,
    FEE_SUBTYPE_TOWING_DRUNK_DRIVING,
    FEE_SUBTYPE_REPAIR_CAR_DAMAGE,
    FEE_SUBTYPE_REPAIR_SUSPECTED_DAMAGE,
].map(
    value => ({
        text: I18n.t(`additional_fee.fee_subtype_map.${ value }`),
        value,
    })
);

export const getDefaultPeriod = () => {
    return {
        start: moment().subtract(...defaultRange).format('YYYY-MM-DD'),
        end: moment().startOf('day').add(1, 'd'),
    };
};

const AdditionalFeeFilter = ({
    defaultChecked,
    onChoiceChange,
    onChangeKeyword,
    applyFilter,
    value,
    appliedDateRange,
    filter,
}) => {
    const searchType = [
        {
            id: SEARCH_BY_RENTAL,
            name: I18n.t('rental.rental_no'),
            value: SEARCH_BY_RENTAL,
        }, {
            id: SEARCH_BY_CUSTOMER,
            name: I18n.t('customer.customer_no'),
            value: SEARCH_BY_CUSTOMER,
        }, {
            id: SEARCH_BY_PLATE,
            name: I18n.t('scooter.plate'),
            value: SEARCH_BY_PLATE,
        }, {
            id: SEARCH_BY_FINE_NO,
            name: I18n.t('additional_fee.ticket_no'),
            value: SEARCH_BY_FINE_NO,
        }
    ];

    return (
        <FlexGroup start className="choice-group-field">
            <ChoiceGroupField
                renderTitle={ () => <Translate className="search-for-caption" value="search_for" /> }
                defaultChecked={ defaultChecked }
                options={ searchType }
                onChoiceChange={ onChoiceChange }
                onChange={ onChangeKeyword }
                onSubmit={ applyFilter(SEARCH) }
                value={ value }
            />
            <section className="filters">
                <MultiselectFilter
                    title={ I18n.t('additional_fee.claim_type') }
                    options={ getClaimTypeOptions() }
                    defaultSelected={ filter[CLAIM_TYPE] }
                    onChange={ applyFilter(CLAIM_TYPE) }
                />
                <MultiselectFilter
                    title={ I18n.t('additional_fee.fee_type') }
                    options={ getFeeTypeOptions() }
                    defaultSelected={ filter[FEE_TYPE] }
                    onChange={ applyFilter(FEE_TYPE) }
                />
                <MultiselectFilter
                    title={ I18n.t('additional_fee.subtype') }
                    options={ getSubtypeOptions() }
                    defaultSelected={ filter[FEE_SUBTYPE] }
                    onChange={ applyFilter(FEE_SUBTYPE) }
                />
                <MultiselectFilter
                    title={ I18n.t('additional_fee.status') }
                    options={ getFeeStatusOptions() }
                    defaultSelected={ filter[STATUS] }
                    onChange={ applyFilter(STATUS) }
                />
                <DatetimeRangeFilter
                    range={ defaultRange }
                    title={ I18n.t('customer.create_time') }
                    defaultFrom={ filter.create_from }
                    defaultTo={ filter.create_to }
                    onApply={ applyFilter(CREATE_TIME) }
                    applied={ appliedDateRange }
                />
            </section>
        </FlexGroup>
    );
};

AdditionalFeeFilter.propTypes = {
    defaultChecked: PropTypes.node,
    onChoiceChange: PropTypes.func,
    onChangeKeyword: PropTypes.func,
    applyFilter: PropTypes.func,
    value: PropTypes.string,
    appliedDateRange: PropTypes.bool,
    filter: PropTypes.shape(),
};

AdditionalFeeFilter.defaultProps = {
    defaultChecked: '',
    applyFilter: () => {},
    onChoiceChange: () => {},
    onChangeKeyword: () => {},
    value: undefined,
    appliedDateRange: false,
    filter: undefined,
};

export default AdditionalFeeFilter;
