import {
    ADDITIONAL_FEE_FULL,
    ADDITIONAL_FEE_CUSTOMER,
} from './index';
import allHeader from './column/all';
import customerHeader from './column/customer';

const ListHeader = type => {
    let columns = [];

    switch (type) {
    case ADDITIONAL_FEE_CUSTOMER:
        columns = customerHeader;
        break;
    case ADDITIONAL_FEE_FULL:
        columns = allHeader;
        break;
    default:
        // do nothing
        break;
    }

    return columns;
};

export default ListHeader;
