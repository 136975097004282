import React from 'react';
import PropTypes from 'prop-types';
import { Label, Input } from 'reactstrap';
import { Translate } from 'react-redux-i18n';
// import { TYPE_TEXT } from 'components/Form/Input';


const Component = ({ captionKey, type, name, value, required, onChange, onInvalid }) => {
    return (
        <React.Fragment>
            <Label className="control-group">
                <Translate value={ captionKey } className={ `caption ${ required ? 'required' : '' }` } tag="h5" />
                <div className="control-wrapper">
                    <Input
                        type={ type }
                        name={ name }
                        required
                        value={ value || '' }
                        autoComplete="off"
                        onChange={ onChange }
                        onInvalid={ (e) => {
                            e.stopPropagation();
                        } }
                    />
                </div>
            </Label>
        </React.Fragment>
    );
};

Component.propTypes = {
    captionKey: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onInvalid: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.shape({
            // translation key
            name: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.node,
                PropTypes.bool
            ]),
        })),
    ]),
};


Component.defaultProps = {
    captionKey: '',
    required: true,
    type: 'text',
    name: '',
    onChange: () => {},
    onInvalid: () => {},
    value: undefined,
};

export default Component;
