import React, { Component } from 'react';
import Smart from 'components/Smart';
import { fetchPermission } from 'actions';
import account from 'helpers/account';

class RefreshPermission extends Component {
    handlePermission = () => {
        if (!account.exists()) {
            return Promise.resolve();
        }

        const { dispatch } = this.props;
        const id_token = account.getID() || {};

        return dispatch(fetchPermission(id_token));
    }

    render() {
        return (
            <Smart
                fetch={ this.handlePermission }
                refreshTime={ 3600 }
                tag=""
                seamless
                background
            />
        );
    }
}

export default RefreshPermission;
