import { useCallback, useState, useEffect } from 'react';
import isEqual from 'react-fast-compare';
import watermark from 'watermarkjs';
import getImgWithWatermark, { getViolationWatermarkImg, getWatermarkImg } from 'helpers/doc-watermark';

/*
useWaterMarkImgByDataString(, {
    imgType: // image type, ex.image/jpeg
    docType: // document type
    isViolation: // decide watermark image
})
*/
export const useWaterMarkImgByDataString = (file, config) => {
    const [imgString, setImgString] = useState(file);
    const [options, setOptions] = useState(config);
    const [img, setImg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const getImage = useCallback(
        () => {
            if (imgString) {
                const { imgType, docType, isViolation } = options;
                setIsLoading(true);
                setIsError(false);
                const imgFile = (!imgType || !imgString) ? null : `data:${ imgType };base64, ${ imgString }`;
                const watermark = isViolation ? getViolationWatermarkImg(docType) : getWatermarkImg(docType);
                getImgWithWatermark(docType, imgFile, watermark)
                .then(result => {
                    setImg(result);
                    setIsLoading(false);
                }).catch(error => {
                    console.error('Error for Document of Customer:', error);
                    setIsError(true);
                });
            }

        },
        [imgString, options],
    );


    const clearPrevious = () => {
        setIsLoading(true);
        setImg('');
        setIsError(false);
    };


    useEffect(() => {
        if(!isEqual(config, options)) {
            setOptions(config);
        }
    }, [config, options]);

    useEffect(() => {
        return () => {
            setImg('');
            watermark.destroy();
        };
    }, [setImg]);

    useEffect(() => {
        if(!isEqual(imgString, file)) {
            clearPrevious();
            setImgString(file);
        }
    }, [imgString, file, setImgString]);

    useEffect(() => {
        getImage();
    }, [imgString, getImage]);


    return {
        img,
        isError,
        isLoading,
    };
};

