import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

const Component = ({ caption, children }) => (
    <div className="control-group view-mode">
        <Translate className="caption" value={ caption } tag="h5" />
        <div className="control-wrapper">
            <span className="view-control">{ children }</span>
        </div>
    </div>
);
Component.propTypes = {
    caption: PropTypes.string,
};


Component.defaultProps = {
    caption: '',
};

export default Component;
