import { createFleetApiInstanceWithToken } from '../util';

const asset = {
    fetchAssetList(params) {
        return createFleetApiInstanceWithToken().get('/assets', { params });
    },
    connectedDevices(formData) {
        return createFleetApiInstanceWithToken().post('/assets/connected_devices', formData);
    },
};

export default asset;
