import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Label, Input as LabelInput } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import serialize from 'form-serialize';
import Form from 'components/Form';
import Input, { TYPE_NUMBER } from 'components/Form/Input';
import Button from 'components/Form/Button';
import Modal from 'components/Modal';
import FlexGroup from 'components/FlexGroup';
import ButtonGroup from 'components/ButtonGroup';
import { toggleErrorDialog } from 'actions/general';
import { forceReturn, fetchSingleScooter } from 'actions/scooter';
import AuthFeature from 'components/AuthFeature';
import { AUTH_FORCE_RETURN_SCOOTER } from 'constants/permission';


class ForceReturn extends Component {
    static propTypes = {
        id: PropTypes.string,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        id: undefined,
        disabled: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            inProgress: false,
            openDialog: false,
            checkWarning: true,
            errorMessage: '',
            returnPrice: null,
        };
    }

    handleSubmitReturn = e => {
        e.preventDefault();

        const { dispatch, id, disabled } = this.props;
        const { checkWarning } = this.state;
        const { price } = serialize(e.currentTarget, { hash: true });

        if (!disabled) {
            this.setState({
                inProgress: true,
            });

            dispatch(forceReturn(id, { price: Number(price), check_warning: checkWarning })).then(({ error }) => {
                if (error) {
                    const { response } = error;
                    let message = I18n.t('scooter.force_return_error_message', { code: response.data.code });
                    // will always get erorr from LINEpay and show particular message
                    if (response.data.code === 3000 || response.data.code === 3005 ||
                            response.data.code === 3003) {
                        if (response.data.code === 3005) {
                            dispatch(fetchSingleScooter(id));
                            message = I18n.t('scooter.force_return_error_by_linepay_message');
                        }
                        else if (response.data.code === 3000) {
                            message = I18n.t('scooter.force_return_3000_error_message');
                        }
                        this.toggleDialog({
                            returnPrice: null,
                        });
                        return dispatch(toggleErrorDialog(message));
                    }
                    const errorMessage = response.data.codes.map(code => {
                        return I18n.t(`scooter.force_return_${code}_error_message`);
                    }).join(',\n');
                    this.setState({
                        errorMessage,
                    });
                }
                else {
                    this.toggleDialog({
                        errorMessage: '',
                        returnPrice: null
                    });
                }
            }).then(() => {
                this.setState({
                    inProgress: false,
                    checkWarning: true,
                });
            });
        }
    }

    handleOpenDialog = () => {
        const { disabled } = this.props;

        if (!disabled) {
            this.toggleDialog();
        }
    }

    handlePriceChange = (e) => {
        e.preventDefault();
        this.setState({ returnPrice: e.target.value });
    }

    handleClose = () => {
        this.toggleDialog({
            errorMessage: '',
            returnPrice: null,
            checkWarning: true,
        });
    }

    toggleDialog = (otherState = {}) => {
        const { openDialog } = this.state;

        this.setState({
            openDialog: !openDialog,
            ...otherState,
        });
    }

    toggleCheckWarning = () => {
        const { checkWarning } = this.state;
        this.setState({
            checkWarning: !checkWarning,
        });
    }

    render() {
        const { id, disabled } = this.props;
        const { openDialog, inProgress, checkWarning, errorMessage, returnPrice } = this.state;
        const clearCheckWarning = !checkWarning;
        return (
            <AuthFeature requiredList={ [AUTH_FORCE_RETURN_SCOOTER] }>
                <Button
                    color="outline-primary"
                    type="button"
                    onClick={ this.handleOpenDialog }
                    disabled={ !id || disabled }
                >
                    <Translate value="scooter.force_return" />
                </Button>
                {
                    openDialog ? (
                        <Modal
                            title={ I18n.t('scooter.force_return') }
                            onClose={ this.handleClose }
                            disabledCloseOnBg
                        >
                            <Form onSubmit={ this.handleSubmitReturn } inProgress={ inProgress }>
                                <Input
                                    type={ TYPE_NUMBER }
                                    caption={ I18n.t('scooter.input_rental_fee') }
                                    name="price"
                                    value={ returnPrice || '' }
                                    min={ 0 }
                                    required
                                    onChange={ this.handlePriceChange }
                                />
                                {
                                    errorMessage !== '' ? (
                                        <FlexGroup spaceBetween>
                                            <div className="warning-panel">
                                                <Label check>
                                                    <LabelInput
                                                        type="checkbox"
                                                        name="force_return_check"
                                                        onChange={ this.toggleCheckWarning }
                                                        value={ clearCheckWarning }
                                                        defaultChecked={ clearCheckWarning }
                                                    />
                                                    <span>
                                                        <Translate value="know" />
                                                    </span>
                                                </Label>
                                                <Translate
                                                    className="error"
                                                    value={ `${I18n.t('warning')}: ${ errorMessage } ` }
                                                    tag="span"
                                                />
                                            </div>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                disabled={ !clearCheckWarning || inProgress }
                                            >
                                                <Translate value="confirm" />
                                            </Button>
                                        </FlexGroup>
                                    ) : (
                                        <ButtonGroup>
                                            <Button type="submit" color="primary">
                                                <Translate value="submit" />
                                            </Button>
                                        </ButtonGroup>
                                    )
                                }
                            </Form>
                        </Modal>
                    ) : null
                }
            </AuthFeature>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(ForceReturn);
