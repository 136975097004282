import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { EDIT_SYSTEM_COUPON } from 'constants/routes';
import Cell from 'components/ListView/Cell';
import buildActualPath from 'helpers/build-actual-path';

class SystemCouponListRow extends React.Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            status: PropTypes.number.isRequired,
            discount_type: PropTypes.number.isRequired,
            discount_value: PropTypes.number.isRequired,
            promote_type: PropTypes.number.isRequired,
        }).isRequired,
    };

    renderField({ data, name, width }) {
        let el;

        switch (name) {
        case 'title':
            el = (
                <NavLink to={ buildActualPath(EDIT_SYSTEM_COUPON, { couponId: data.id }) }>{ data.title }</NavLink>
            );
            break;
        case 'status':
            el = <Translate value={ `coupon.status_${ data[name] }` } />;
            break;
        case 'discount_type':
            el = <Translate value={ `coupon.type_${ data[name] }` } />;
            break;
        case 'promote_type':
            el = <Translate value={ `coupon.promote_type_${ data[name] }` } />;
            break;
        case 'discount_value':
            el = (
                <React.Fragment>
                    <span className="discount-value">{ data.discount_value }</span>
                    <Translate value={ `coupon.discount_${ data.discount_type }` } />
                </React.Fragment>
            );
            break;
        default:
            el = data[name];
            break;
        }

        return (
            <Cell
                key={ `${ data.id }-${ name }` }
                name={ name }
                style={ {
                    width,
                } }
            >
                { el }
            </Cell>
        );
    }

    render() {
        const { columns, data } = this.props;

        return columns.map(({ key, width }, i) => (
            this.renderField({ data, width, name: key, })
        ));
    }
}

export default SystemCouponListRow;
