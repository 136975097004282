/**
 * Reducer - Regular Maintenance
 */
import { Map, List, } from 'immutable';
import {
    FETCH_RM_SCOOTER_LIST,
} from 'constants/action-type';
import creater from './util/creater';

const initialState = Map({
    list: Map({
        data_list: List([]),
    }),
});

const actionsMap = {
    [FETCH_RM_SCOOTER_LIST]: (state, action) => {
        const { page_index, page_count, total, data_list, __responseTime } = action.data;

        return state.merge({
            list: Map({
                page_index,
                page_count,
                total,
                __responseTime,
                data_list: List(data_list),
            }),
        });
    }
};

export default creater(actionsMap, initialState);
