import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Smart from 'components/Smart';
import MissionMap from 'view/Ticket/MissionMap';


class MapView extends Component {

    static propTypes = {
        assignments: ImmutablePropTypes.map.isRequired,
        fetchData: PropTypes.func.isRequired,
        onScooterSelected: PropTypes.func.isRequired,
        resetBounds: PropTypes.number.isRequired,
    }

    getTicketList = data_list => {
        return data_list.reduce((totalTicketList, { tickets }) => ([
            ...totalTicketList,
            ...tickets.map(({ id, scooter_id, ...rest }) => ({ id: scooter_id, scooter_id, ...rest }))
        ]), []);
    }

    fetchData = () => {
        const { assignments, fetchData } = this.props;
        const list = assignments.get('data_list').toJS();
        const hasSelected = list.some(({ selected }) => selected);
        let promise;

        if (!hasSelected) {
            promise = fetchData();
        }

        return promise;
    }

    render() {
        const { assignments, onScooterSelected, resetBounds } = this.props;
        const data_list = assignments.get('data_list').toJS();

        return (
            <Smart
                fetch={ this.fetchData }
                refreshTime={ 0 }
                className="main-content-container"
                pauseRefresh
                seamless
            >
                <MissionMap
                    zoom={ 15 }
                    list={ this.getTicketList(data_list) }
                    resetBounds={ resetBounds }
                    onScooterSelected={ onScooterSelected }
                    searchBox
                    drawingManager
                    currentLocation
                    showVipLayer
                />
            </Smart>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    assignments: state.ticket.get('assignments'),
}))(MapView);
