import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import AuthView from 'components/AuthView';
import NavigationBar from 'components/NavigationBar';
import FlexGroup from 'components/FlexGroup';
import Smart from 'components/Smart';
import {
    updateDocumentTitle,
    fetchOrderableAdList,
    orderAdList,
    updateAdOrder,
    toggleErrorDialog,
} from 'actions';
import AdOrderList from './AdOrderList';
import ConfirmModal from './ConfirmModal';
import './ad-order.scss';

class AdOrder extends React.Component {
    state = {
        openModal: false,
        wasMoved: false,
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(updateDocumentTitle('ad.order_document_title'));
    }

    fetchOrderableAdList = () => {
        const { dispatch } = this.props;
        this.setState({ wasMoved: false });
        return dispatch(fetchOrderableAdList());
    }

    handleMoveAd = (currentIndex, nextIndex) => {
        const { dispatch } = this.props;
        const { wasMoved } = this.state;

        if (!wasMoved) {
            this.setState({ wasMoved: true });
        }

        return dispatch(orderAdList(currentIndex, nextIndex));
    }

    toggleConfirmModal = () => {
        this.setState(state => ({
            openModal: !state.openModal,
        }));
    }

    save = async () => {
        const { dispatch } = this.props;
        try {
            const { error } = await dispatch(updateAdOrder());
            this.setState({ wasMoved: false });
            this.toggleConfirmModal();

            if (error) {
                throw error;
            }
        }
        catch (error) {
            const { status } = error.response;
            if (status !== 401) {
                dispatch(toggleErrorDialog(I18n.t(`ad.ad_order_error_${ status }`)));
                dispatch(fetchOrderableAdList());
            }
        }
    }

    render() {
        const { openModal, wasMoved } = this.state;

        return (
            <AuthView className="ad-order">
                <NavigationBar sticky title={ I18n.t('ad.change_order') }>
                    <FlexGroup end gap>
                        <Button type="button" outline onClick={ this.fetchOrderableAdList } disabled={ !wasMoved }>
                            <Translate value="restore" />
                        </Button>
                        <Button color="primary" onClick={ this.toggleConfirmModal } disabled={ !wasMoved }>
                            <Translate value="save" />
                        </Button>
                    </FlexGroup>
                </NavigationBar>
                <Translate tag="div" className="brief-intro" value="ad.brief_intro" />
                <Smart className="main-content-container" fetch={ this.fetchOrderableAdList } pauseRefresh seamless>
                    <AdOrderList onMoveAd={ this.handleMoveAd } />
                </Smart>
                { openModal && (
                    <ConfirmModal onSave={ this.save } onClose={ this.toggleConfirmModal } />
                ) }
            </AuthView>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(AdOrder);
