import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import Form from 'components/Form';
import Input, { TYPE_TEXT } from 'components/Form/Input';
import UpdInput from './UpdCtrlInput';
import { Col, FormGroup, Label } from 'reactstrap';
import Button from 'components/Form/Button';
import Modal from 'components/Modal';
import ButtonGroup from 'components/ButtonGroup';
import CollapseBanner from 'components/CollapseBanner';
import { toggleErrorDialog } from 'actions/general';
import {
    clearSingleScooterGTU,
    fetchSingleScooterGTU,
    clearSearchedGTUInfo,
    fetchSearchedGTUList,
    replaceGTU,
    fetchSingleScooter
} from 'actions/scooter';
import AuthFeature from 'components/AuthFeature';
import { AUTH_UPDATE_SCOOTER_GTU, AUTH_VIEW_GTU_LIST } from 'constants/permission';

import './gtu-replace.scss';
import { TYPE_NUMBER } from '../Form/Input';

// can not over 15 bits
const IMEI_MAX_BITS = 999999999999999;

class gtuReplace extends Component {
    static propTypes = {
        id: PropTypes.string,
        plate: PropTypes.string,
        scooterGTUData: PropTypes.shape({
            gtu_id: PropTypes.string,
            scooter_id: PropTypes.string,
            scooter_plate: PropTypes.string,
            sim_info: PropTypes.string,
            gtu_imei: PropTypes.number,
            certificate_subject: PropTypes.string,
            certificate_valid_from: PropTypes.string,
            certificate_valid_to: PropTypes.string,
            certificate_issuer: PropTypes.string,
        }),
        disabled: PropTypes.bool,
        searchedGTUList: PropTypes.shape(),
    };

    static defaultProps = {
        id: undefined,
        plate: undefined,
        disabled: false,
        scooterGTUData: {},
        searchedGTUList: {},
    };

    constructor(props) {
        super(props);
        this.elSearchIMEI = React.createRef();
        this.elForm = React.createRef();
        this.state = {
            inProgress: false,
            openDialog: false,
            openGTUInfo: true,
            updateUTCObj: {},
        };
    }

    shouldComponentUpdate(nexProps, nextState) {
        const { openDialog } = nextState;
        if (!openDialog) {
            const { dispatch } = this.props;
            dispatch(clearSearchedGTUInfo());
        }
        return true;
    }

    componentDidUpdate(prevProps) {
        const { searchedGTUList: prevSearchedGTUList } = prevProps;
        const { searchedGTUList } = this.props;
        if (searchedGTUList.gtu_imei && searchedGTUList !== prevSearchedGTUList) {
            this.setState({
                updateUTCObj: {
                    ...searchedGTUList,
                }
            });
        }
    }

    componentWillUnmount() {
        const { id, dispatch } = this.props;
        dispatch(clearSingleScooterGTU(id));
    }

    handleOpenDialog = () => {
        const { id, dispatch, searchedGTUList } = this.props;
        const { openDialog } = this.state;

        if (!openDialog) {
            dispatch(fetchSingleScooterGTU(id)).then(() => {
                this.toggleDialog({
                    updateUTCObj: searchedGTUList,
                });

            });
        }
        else {
            this.toggleDialog();
        }
    }
    handleCloseDialog = () => {
        this.toggleDialog({
            updateUTCObj: {},
        });
    };

    handleCancelled = () => {
        this.handleCloseDialog();
    }

    toggleDialog = (otherState = {}) => {
        const { openDialog } = this.state;

        this.setState({
            openDialog: !openDialog,
            ...otherState,
        });
    }

    handleSeachIMEIKeyDown = (e) => {
        if (e.keyCode === 13) {
            const isValid = this.elSearchIMEI.current.reportValidity();
            if (isValid) {
                this.onSearchIMEI();
                e.preventDefault();
            }
        }
    }

    handleUpdateUTCObjChange = type => e => {

        const { updateUTCObj } = this.state;
        if (type === 'gtu_imei') {
            this.useNewIMEI();
        }
        this.setState({
            updateUTCObj: {
                ...updateUTCObj,
                [type]: e.currentTarget.value,
            }
        });
    }

    onSearchIMEI = async () => {
        const { dispatch } = this.props;
        const gtu_imei = Number(this.elSearchIMEI.current.value);
        await dispatch(clearSearchedGTUInfo());

        if (gtu_imei) {
            dispatch(fetchSearchedGTUList(gtu_imei)).then(({ data, error }) => {

                if (error) {
                    this.toggleDialog();
                    dispatch(toggleErrorDialog(I18n.t('scooter.gtu_general_error_message'), () => {
                        this.toggleDialog();
                    }));
                    return;
                }

                let errorMessage = '';
                if (!data.data_list.length) {
                    errorMessage = I18n.t('scooter.no_searched_imei');
                }
                this.elSearchIMEI.current.setCustomErrorMessage(errorMessage);
                this.elSearchIMEI.current.reportValidity();
            });
        }

    }

    useNewIMEI = () => {
        this.elSearchIMEI.current.setCustomErrorMessage('');
    }

    handleSubmitReplace = (e) => {
        const isValid = this.elForm.current.reportValidity();
        if (isValid) {
            const { dispatch, id } = this.props;
            const { gtu_imei, ...restParams } = e.formData;

            this.toggleDialog({
                inProgress: true,
            });

            dispatch(replaceGTU( id, {
                ...restParams,
                gtu_imei: Number(gtu_imei),
            } )).then(({ error }) => {
                if (error) {
                    const { response } = error;
                    let message = I18n.t(`scooter.gtu_${ response.data.code }_error_message`);
                    dispatch(toggleErrorDialog(message, () => {
                        this.toggleDialog({
                            inProgress: false,
                        });
                    }));
                }
                else {
                    dispatch(fetchSingleScooter(id)).then(() => {
                        this.setState({
                            inProgress: false,
                        });
                    });
                }
            });
        }

    }

    toggleGTUInfoSection = () => {
        return Promise.resolve();
    }

    renderNoGTUModal = () => {
        const { plate } = this.props;
        return (
            <Modal
                className="gtu-replacement no-gtu"
                title={ I18n.t('scooter.no_gtu_data') }
                onClose={ this.toggleDialog }
            >
                <p><Translate value="scooter.has_no_gtu_data" plate={ plate } /></p>
            </Modal>
        );
    }

    renderInfoControlTempl = (captionKey, data, keyCategory = 'scooter' ) => {
        return (
            <FormGroup row>
                <Label sm={ 3 }>
                    <Translate value={ `${ keyCategory }.${ captionKey }` } className="field" />
                </Label>
                <Col sm={ 9 } className="data">
                    { data }
                </Col>
            </FormGroup>
        );
    }

    render() {
        const { openGTUInfo, openDialog, inProgress, updateUTCObj } = this.state;
        const { id, disabled, scooterGTUData } = this.props;
        const { gtu_id,
                scooter_plate,
                gtu_imei,
                sim_info,
                certificate_subject,
                certificate_valid_from,
                certificate_valid_to,
                certificate_issuer, } = scooterGTUData;

        return (
            <AuthFeature requiredList={ [AUTH_UPDATE_SCOOTER_GTU, AUTH_VIEW_GTU_LIST] }>
                <Button
                    color="outline-primary"
                    type="button"
                    onClick={ this.handleOpenDialog }
                    disabled={ !id || disabled }
                >
                    <Translate value="scooter.gtu_replacement" />
                </Button>
                {
                    openDialog && gtu_imei ? (
                        <Modal
                            className="gtu-replacement"
                            title={ I18n.t('scooter.gtu_replacement') }
                            onClose={ this.handleCloseDialog }
                        >
                            <CollapseBanner
                                className="gtu-info"
                                captionKey={ scooter_plate }
                                onFetch={ this.toggleGTUInfoSection }
                                open={ openGTUInfo }
                            >
                                <FormGroup className="gtu-info-group">
                                    <Translate value="scooter.gtu_info" tag="h3" />
                                    { this.renderInfoControlTempl('gtu_id', gtu_id) }
                                    { this.renderInfoControlTempl('gtu_imei', gtu_imei) }
                                    { this.renderInfoControlTempl('sim_phone_number', sim_info, 'asset') }
                                </FormGroup>
                                <FormGroup className="gtu-info-group">
                                    <Translate value="scooter.gtu_cert" tag="h3" />
                                    { this.renderInfoControlTempl('gtu_cert_subject', certificate_subject) }
                                    { this.renderInfoControlTempl('gtu_cert_valid_from', certificate_valid_from) }
                                    { this.renderInfoControlTempl('gtu_cert_valid_to', certificate_valid_to) }
                                    { this.renderInfoControlTempl('gtu_cert_issuer', certificate_issuer) }
                                </FormGroup>
                            </CollapseBanner>
                            <CollapseBanner
                                className="gtu-new-info"
                                captionKey={ I18n.t('scooter.gtu_new_info') }
                                onFetch={ this.toggleGTUInfoSection }
                            >
                                <Form
                                    innerRef={ this.elForm }
                                    inProgress={ inProgress }
                                    onSubmit={ this.handleSubmitReplace }
                                >
                                    <div className="imei-search">
                                        <Input
                                            type={ TYPE_NUMBER }
                                            caption="scooter.gtu_imei"
                                            name="gtu_imei"
                                            value={ updateUTCObj.gtu_imei || '' }
                                            innerRef={ this.elSearchIMEI }
                                            required
                                            min={ 1 }
                                            max={ IMEI_MAX_BITS }
                                            onKeyDown={ this.handleSeachIMEIKeyDown }
                                            onChange={ this.handleUpdateUTCObjChange('gtu_imei') }
                                        />
                                        <Button
                                            color="outline-primary"
                                            className="btn-go-search"
                                            onClick={ this.onSearchIMEI }
                                        >
                                            <Translate value="scooter.flash_fill" />
                                        </Button>
                                    </div>
                                    <Translate value="scooter.gtu_cert" tag="h3" />
                                    <UpdInput
                                        type={ TYPE_TEXT }
                                        captionKey="scooter.gtu_cert_subject"
                                        name="certificate_subject"
                                        value={ updateUTCObj.certificate_subject }
                                        required
                                        onChange={ this.handleUpdateUTCObjChange('certificate_subject') }
                                    />
                                    <UpdInput
                                        type={ TYPE_TEXT }
                                        captionKey="scooter.gtu_cert_valid_from"
                                        name="certificate_valid_from"
                                        value={ updateUTCObj.certificate_valid_from }
                                        required
                                        onChange={ this.handleUpdateUTCObjChange('certificate_valid_from') }
                                    />
                                    <UpdInput
                                        type={ TYPE_TEXT }
                                        captionKey="scooter.gtu_cert_valid_to"
                                        name="certificate_valid_to"
                                        value={ updateUTCObj.certificate_valid_to }
                                        required
                                        onChange={ this.handleUpdateUTCObjChange('certificate_valid_to') }
                                    />
                                    <UpdInput
                                        type={ TYPE_TEXT }
                                        captionKey="scooter.gtu_cert_issuer"
                                        name="certificate_issuer"
                                        value={ updateUTCObj.certificate_issuer }
                                        required
                                        onChange={ this.handleUpdateUTCObjChange('certificate_issuer') }
                                    />
                                    <ButtonGroup start>
                                        <Button
                                            type="button"
                                            className="btn-cancel"
                                            outline
                                            onClick={ this.handleCancelled }
                                        >
                                            <Translate value="cancel" />
                                        </Button>
                                        <Button type="submit" color="primary" onClick={ this.useNewIMEI }>
                                            <Translate value="replace" />
                                        </Button>
                                    </ButtonGroup>
                                </Form>
                            </CollapseBanner>
                        </Modal>
                    ) : null
                }
                {
                    openDialog && !inProgress && !gtu_imei ? (
                        this.renderNoGTUModal()
                    ) : null
                }
            </AuthFeature>
        );
    }
}

export default connect((state, ownProps) => ({
    i18n: state.i18n,
    scooterGTUData: state.scooter.get('scooter_GTU_data')[ownProps.id],
    searchedGTUList: state.scooter.get('searched_GTU_list').toJS(),
}))(gtuReplace);
