import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card, { CardItem } from 'components/Profile/Card';
import Datetime from 'components/Datetime';
import Verification from 'components/Customer/Verification';

import './customer-profile.scss';

class Profile extends Component {
    static propTypes = {
        phone: PropTypes.string.isRequired,
        id_no: PropTypes.string,
        id_verify: PropTypes.number,
        license_no: PropTypes.string,
        license_verify: PropTypes.number,
        birthday: PropTypes.string,
        email: PropTypes.string,
        total_orders: PropTypes.number,
        credit_card_no: PropTypes.string,
        join_date: PropTypes.string,
    };

    static defaultProps = {
        id_no: '',
        id_verify: 0,
        license_no: '',
        license_verify: 0,
        birthday: '',
        email: '',
        total_orders: 0,
        credit_card_no: '',
        join_date: '',
    };

    render() {
        const {
            phone,
            id_no,
            id_verify,
            license_no,
            license_verify,
            birthday,
            email,
            total_orders,
            credit_card_no,
            join_date,
        } = this.props;

        return (
            <article className="customer-profile">
                <div className="cards">
                    <Card title={ phone } className="customer-info">
                        <CardItem name="id_no">
                            { id_no }
                        </CardItem>
                        <CardItem name="id_verify">
                            <Verification value={ id_verify } />
                        </CardItem>
                        <CardItem name="license_no">
                            { license_no }
                        </CardItem>
                        <CardItem name="license_verify">
                            <Verification value={ license_verify } />
                        </CardItem>
                        <CardItem name="birthday">
                            { birthday }
                        </CardItem>
                        <CardItem name="email">
                            { email }
                        </CardItem>
                    </Card>
                    <Card title="" className="rental-info">
                        <CardItem name="total_orders">
                            { total_orders }
                        </CardItem>
                        <CardItem name="credit_card_no">
                            { credit_card_no }
                        </CardItem>
                        <CardItem name="join_date">
                            <Datetime time={ join_date } />
                        </CardItem>
                    </Card>
                </div>
            </article>
        );
    }
}

export default Profile;
