import { Map, List } from 'immutable';
import creater from './util/creater';
import {
    FETCH_ASSET_LIST,
    CONNECTED_DEVICES,
} from 'constants/action-type';

const initialState = Map({
    assetList: Map({}),
});

const actionMap = {
    [FETCH_ASSET_LIST](state, action) {
        const { page_index, page_count, total, data_list, __responseTime } = action.data;

        return state.merge({
            assetList: Map({
                page_index,
                page_count,
                total,
                data_list: List(data_list),
                __responseTime,
            })
        });
    },

    [CONNECTED_DEVICES](state) {
        return state;
    },
};

export default creater(actionMap, initialState);
