import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import isEqual from 'react-fast-compare';
import { GoogleMap, OverlayView } from '@react-google-maps/api';
import mobile from 'is-mobile';
import classNames from 'classnames';
import { I18n } from 'react-redux-i18n';
import Input from 'components/Form/Input';
import { withResizeDetector } from 'react-resize-detector';
import mapOptions from './gmap-options.json';
import { getGeolocation, clearGeolocation } from 'actions';
import Toolbox, {
    FULL_SCREEN,
    CENTER_TO_CURRENT_LOCATION,
    VMS,
    TOP_LEFT_ABOVE,
    VIP_LAYER_POSITION,
    VIP_LAYER_POSITION_TOP,
} from './Toolbox';
import GoStationCluster, { ScooterCluster, ScooterMarker } from './GmapMarker';
import Fullscreen from './Toolbox/Fullscreen';
import CurrentLocation from './Toolbox/CurrentLocation';
import VipLayer from './Toolbox/VipLayer';
import VMSButton from './Toolbox/VMS';
import DrawingManager from './Toolbox/DrawingManager';
import addServiceZone from './Layers/service-zone';
import setSearchBox from './Layers/search-box';
import Footer from './Layers/Footer';
import getMapBounds from './util/get-map-bounds';
import './map.scss';

const isMobile = mobile();

export const VIP_READ = 'VIP_READ';
export const VIP_SELECT = 'VIP_SELECT';


export * from './Toolbox';


function zoomControl(map, maps, isDetail) {
    const controlDiv = document.createElement('div');

    map.setOptions({
        disableDefaultUI: true
    });

    controlDiv.style.marginLeft = '-49px';
    controlDiv.style.marginTop = '34px';

    if (isDetail) {
        controlDiv.style.marginLeft = '10px';
        controlDiv.style.marginTop = '10px';
    }

    const controlWrapper = document.createElement('div');
    controlWrapper.className = 'controlWrapper';
    controlWrapper.style.boxShadow = 'rgb(0 0 0 / 30%) 0px 1px 4px -1px';
    controlDiv.appendChild(controlWrapper);

    const zoomInButton = document.createElement('button');
    zoomInButton.className = 'zoom-in-btn';
    controlWrapper.appendChild(zoomInButton);
    const divider = document.createElement('div');
    divider.className = 'divider';
    controlWrapper.appendChild(divider);

    const zoomOutButton = document.createElement('button');
    zoomOutButton.className = 'zoom-out-btn';
    controlWrapper.appendChild(zoomOutButton);

    zoomInButton.addEventListener('click', () => {
        map.setZoom(map.getZoom() + 1);
    });

    zoomOutButton.addEventListener('click', () => {
        map.setZoom(map.getZoom() - 1);
    });

    maps.event.addListener(map, 'zoom_changed', () => {
        if (map.getZoom() === 22) {
            zoomInButton.className = 'zoom-in-btn disabled';
        }
        else if (map.getZoom() === 0) {
            zoomOutButton.className = 'zoom-out-btn disabled';
        }
        else {
            zoomInButton.className = 'zoom-in-btn';
            zoomOutButton.className = 'zoom-out-btn';
        }
    })

    controlDiv.index = 1;
    map.controls[maps.ControlPosition.TOP_LEFT].push(controlDiv);
}

class ComposeGoogleMap extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        center: PropTypes.shape({
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
        innerRef: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.func,
        ]),
        options: PropTypes.shape({}),
        zoom: PropTypes.number,
        onInternalAPI: PropTypes.func,
        onChange: PropTypes.func,
        onScooterSelected: PropTypes.func,
        onTilesLoaded: PropTypes.func,
        sticky: PropTypes.bool,
        fitsWindowHeight: PropTypes.bool,
        toolbox: PropTypes.arrayOf(
            PropTypes.node
        ),
        currentLocation: PropTypes.bool,
        currentGeoLocation: PropTypes.shape({}),
        geoError: PropTypes.shape({}),
        searchBox: PropTypes.bool,
        drawingManager: PropTypes.bool,
        onOverlaycomplete: PropTypes.func,
        list: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            model_code: PropTypes.number,
            light: PropTypes.bool,
            selected: PropTypes.bool,
            visible: PropTypes.bool,
            lat: PropTypes.number,
            lng: PropTypes.number,
        })),
        disableSelectStyle: PropTypes.bool,
        updateTime: PropTypes.number,
        withoutScooterCluster: PropTypes.bool,
        vipLayer: PropTypes.string,
        myTask: PropTypes.bool,
        business_ids: PropTypes.arrayOf(PropTypes.string),
        isDetail: PropTypes.bool,
    }

    static defaultProps = {
        className: undefined,
        center: {
            lat: 25.04357,
            lng: 121.36333,
        },
        innerRef: React.createRef(),
        zoom: 15,
        options: {},
        onInternalAPI: () => {},
        onTilesLoaded: () => {},
        onChange: () => {},
        onScooterSelected: () => {},
        sticky: false,
        fitsWindowHeight: false,
        toolbox: [],
        currentLocation: false,
        currentGeoLocation: undefined,
        geoError: undefined,
        searchBox: false,
        drawingManager: false,
        onOverlaycomplete: () => {},
        list: [],
        disableSelectStyle: false,
        updateTime: 0,
        withoutScooterCluster: false,
        vipLayer: undefined,
        myTask: false,
        business_ids: [],
        isDetail: false,
    };

    constructor(props) {
        super(props);

        this.elSearchBox = React.createRef();
        this.realHeight = undefined;
        this.state = {
            time: 0,
        };
        this.posTimer = undefined;
        this.map = undefined;
        this.maps = undefined;

        this.scooterCluster = undefined;
    }

    componentDidMount() {
        const { fitsWindowHeight } = this.props;
        this.checkMapHeight();

        if (fitsWindowHeight) {
            window.addEventListener('scroll', this.handleScroll, false);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { list, updateTime, currentGeoLocation, business_ids } = this.props;
        const currList = fromJS(list);
        const nextList = fromJS(nextProps.list);
        return (
            !currList.equals(nextList) ||
            !fromJS(this.state).equals(fromJS(nextState)) ||
            !currentGeoLocation ||
            updateTime !== nextProps.updateTime ||
            !isEqual(business_ids, nextProps.business_ids)
        );
    }


    componentDidUpdate(prevProps = {}) {
        this.checkMapHeight();
    }

    componentWillUnmount() {
        const { dispatch, fitsWindowHeight, currentLocation } = this.props;

        if (fitsWindowHeight) {
            window.removeEventListener('scroll', this.handleScroll, false);
        }

        if (currentLocation) {
            dispatch(clearGeolocation());
            clearInterval(this.posTimer);
        }
    }

    handleScroll = () => {
        if (this.checkMapHeight()) {
            this.setState({
                time: Date.now(),
            });
        }
    }

    getRef = () => {
        const { innerRef } = this.props;

        innerRef.current = innerRef.current || {};

        if (!innerRef.current.getBoundingClientRect) {
            innerRef.current.getBoundingClientRect = () => ({});
        }

        return innerRef.current;
    }

    checkMapHeight = () => {
        const prevHeight = this.realHeight;
        const { fitsWindowHeight } = this.props;
        const { top = 0, bottom = 0, height = 0 } = this.getRef().getBoundingClientRect();

        if (fitsWindowHeight) {
            if (bottom > window.innerHeight) {
                const diff = bottom - window.innerHeight;

                this.realHeight = height - diff;
            }
            else {
                this.realHeight = window.innerHeight - top;
            }
        }

        const hasChanged = (prevHeight !== this.realHeight);

        return hasChanged;
    }

    handleChange = () => {
        const { onChange } = this.props;

        if (this.map) {
            const res = {
                mapBounds: getMapBounds(this.map),
            };

            onChange(res);
        }
    }

    handleOverlayComplete = (drawingManagerInstance, e) => {
        const rectangle = e.overlay;
        const { onOverlaycomplete, onScooterSelected, list } = this.props;

        onOverlaycomplete(drawingManagerInstance, e);

        onScooterSelected(list.filter(({ lat, lng }) => {
            const isContained = rectangle.getBounds().contains({ lat, lng });
            return isContained;
        }).map(({ id }) => id), { selected: true });

        // clear
        drawingManagerInstance.setDrawingMode(null);
        rectangle.setMap(null);
    }

    handleGoogleApiLoaded = map => {
        const { maps } = window.google;
        this.map = map;
        this.maps = maps;

        const { dispatch, onInternalAPI, currentLocation, vipLayer, isDetail } = this.props;
        zoomControl(this.map, this.maps, isDetail);

        if (currentLocation) {
            dispatch(getGeolocation());
        }

        !vipLayer && addServiceZone({ map, vipLayer });

        setSearchBox({
            map,
            maps,
            el: this.elSearchBox.current,
        });
        onInternalAPI({ map, maps });
    }

    getBoundaries = () => {
        let rectangle;
        const mapBounds = getMapBounds(this.map);

        if (this.maps && this.maps.Rectangle && mapBounds) {
            const { bounds } = mapBounds;
            const { nw, se } = bounds;

            rectangle = new this.maps.Rectangle({
                bounds: {
                    north: nw.lat,
                    south: se.lat,
                    east: se.lng,
                    west: nw.lng,
                },
            });
        }

        return rectangle;
    }

    getScooterList = () => {
        const { list, disableSelectStyle } = this.props;

        return list.map(item => ({
            ...item,
            disableSelectStyle,
        }));
    }

    renderLayer() {
        const { myTask, business_ids } = this.props;
        return (
            <Toolbox position={ myTask ? VIP_LAYER_POSITION_TOP : VIP_LAYER_POSITION }>
                <VipLayer business_ids={ myTask ? business_ids : [] } />
            </Toolbox>
        );
    }

    renderToolbox() {
        const { toolbox, innerRef, searchBox } = this.props;
        const toolboxMap = {
            [FULL_SCREEN]: Fullscreen,
            [CENTER_TO_CURRENT_LOCATION]: CurrentLocation,
            [VMS]: VMSButton,
        };
        return (
            toolbox.length > 0 ?
                (
                    <Toolbox position={ searchBox ? TOP_LEFT_ABOVE : undefined }>
                        {
                            toolbox.map(item => {
                                const CustomTag = toolboxMap[item];
                                return CustomTag ? (
                                    <CustomTag map={ this.map } target={ innerRef.current } key={ item } />
                                ) : item;
                            })
                        }
                    </Toolbox>
                ) : null
        );
    }

    renderSearchBox() {
        const { searchBox } = this.props;

        return (
            searchBox ? (
                <Input
                    type="text"
                    className="search-place-box"
                    innerRef={ this.elSearchBox }
                    placeholder={ I18n.t('search_a_place') }
                />
            ) : null
        );
    }

    renderScooterMarkers() {
        const { list, onScooterSelected } = this.props;
        return list.map(scooter => (
            <ScooterMarker
                key={ `scooter-marker-${ scooter.id }` }
                onClick={ onScooterSelected }
                { ...scooter }
            />
        ));

    }

    render() {
        const { center, options, innerRef, sticky, zoom,
                className, drawingManager, vipLayer, onTilesLoaded } = this.props;
        const { list, onScooterSelected, currentLocation, currentGeoLocation = {}, withoutScooterCluster } = this.props;
        const { coords = {} } = currentGeoLocation;
        const fullOptions = {
            ...mapOptions,
            ...options,
        };
        const { time } = this.state;
        const mapClass = classNames({
            'googlemap': true,
            sticky,
            [className]: !!className,
        });

        const inlineStyle = (
            isMobile ? {} : { height: this.realHeight, }
        );

        return (
            <div style={ inlineStyle } ref={ innerRef } className={ mapClass }>
                <GoogleMap
                    id="google-map"
                    onTilesLoaded={ onTilesLoaded }
                    center={ center }
                    zoom={ zoom }
                    onLoad={ this.handleGoogleApiLoaded }
                    onIdle={ this.handleChange }
                    options={ fullOptions }
                    updated={ time }
                >
                    {
                        currentLocation && coords.latitude && coords.longitude ? (
                            <OverlayView
                                position={ {
                                    lat: coords.latitude,
                                    lng: coords.longitude,
                                } }
                                mapPaneName={ OverlayView.OVERLAY_MOUSE_TARGET }
                            >
                                <div className="current-location" />
                            </OverlayView>
                        ) : null
                    }
                    { withoutScooterCluster ? (
                        this.renderScooterMarkers()
                    ) : (
                        <ScooterCluster list={ list } onClick={ onScooterSelected } />
                    ) }
                    <GoStationCluster />
                    <DrawingManager
                        show={ drawingManager }
                        onOverlayComplete={ this.handleOverlayComplete }
                    />
                </GoogleMap>
                { vipLayer === VIP_SELECT && this.renderLayer() }
                { this.renderToolbox() }
                { this.renderSearchBox() }
                <Footer />
            </div>
        );
    }
}

export default withResizeDetector(connect(state => ({
    currentGeoLocation: state.geolocation.get('currentLocation'),
    geoError: state.geolocation.get('error'),
}))(ComposeGoogleMap));
