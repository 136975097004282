/**
 * Action - Log
 */
import api from 'api';
import { asyncInterface } from './util';
import {
    FETCH_RENTAL_LOG,
    EXPORT_RENTAL_LOG,
    CLOSE_EXPORT,
} from 'constants/action-type';

export const fetchRentalLog = params => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchRentalLog(params),
        type: FETCH_RENTAL_LOG,
        dispatch,
    });
};

export const exportRentalLog = params => dispatch => {
    return asyncInterface({
        asyncAction: api.exportRentalLog(params),
        type: EXPORT_RENTAL_LOG,
        dispatch,
    });
};

export const closeRentalExport = () => {
    return {
        type: CLOSE_EXPORT,
    };
};
