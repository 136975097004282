import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Modal from 'components/Modal';
import RemoteControl from 'components/RemoteControl';
import Blocker from 'components/Blocker';
import RingSpinner from 'components/Spinner/Ring';
import AuthFeature from 'components/AuthFeature';
import permissionHandler, { ANY_ONE } from 'helpers/permission-handler';
import ButtonGroup from 'components/ButtonGroup';
import { Translate, I18n } from 'react-redux-i18n';
import {
    RPC_LOCK,
    RPC_UNLOCK,
    RPC_OPEN_TRUNK,
    RPC_FIND_SCOOTER,
    SCOOTER_ONLINE,
    SCOOTER_OFFLINE,
    ACTIVATE_DISABLE,
    ACTIVATE_ENABLE,
} from 'constants/scooter';
import {
    AUTH_DO_SCOOTER_ONLINE,
    AUTH_DO_SCOOTER_OFFLINE,
    AUTH_CONTROL_SCOOTER,
    AUTH_FIND_BCU_SCOOTER,
    AUTH_CONTROL_SCOOTER_WITH_TICKET,
} from 'constants/permission';
import { Button } from 'reactstrap';
import Form from 'components/Form';
import Input from 'components/Form/Input';
import WarningModal from 'components/WarningModal';
import { TYPE_RADIO } from 'components/Form/Input/input-type';
import {
    updateServiceState,
    fetchSingleScooter,
    fetchOperationUsers,
    toggleErrorDialog
} from 'actions';

import './action-panel.scss';


class ActionPanel extends Component {
    static propTypes = {
        scooterIdList: PropTypes.arrayOf(PropTypes.string).isRequired,
        onClose: PropTypes.func,
        onDidAction: PropTypes.func,
        corporate: PropTypes.arrayOf(PropTypes.string),
    };
    static defaultProps = {
        onClose: () => {},
        onDidAction: () => {},
        corporate: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            isUpdatingState: false,
            singleScooter: Map({}),
            defaultScooter: Map({}),
            warningOpen: false,
        };
    }

    componentDidMount() {
        const { dispatch, scooterIdList } = this.props;
        const needsingleScooter = () => (
            scooterIdList.length === 1 ?
                Promise.resolve(scooterIdList[0]) :
                Promise.reject()
        );
        const getsingleScooter = scooterId => {
            this.setState({
                isUpdatingState: true,
            });

            return dispatch(fetchSingleScooter(scooterId)).then(({ data }) => Map(data));
        };
        const setsingleScooter = scooter => {
            this.setState({
                singleScooter: scooter,
                defaultScooter: scooter,
                isUpdatingState: false,
            });
        };
        needsingleScooter()
            .then(getsingleScooter)
            .then(setsingleScooter)
            .catch(() => {
                // Doesn't need to fetch default scooter.
            });
        dispatch(fetchOperationUsers());
    }

    handleActivationChanged = value => {
        const { singleScooter } = this.state;

        this.setState({
            singleScooter: singleScooter.set('is_activate', Number(value) ? true : false),
        });
    }

    toggleWarningDialog = () => {
        const { warningOpen } = this.state;
        this.setState({ warningOpen: !warningOpen });
    }

    submit = e => {
        e.preventDefault();

        this.setState({
            isUpdatingState: true,
        });
        const { dispatch, scooterIdList, onDidAction } = this.props;
        const { singleScooter, defaultScooter } = this.state;
        const requestParamsCollection = {};
        const is_activate = singleScooter.get('is_activate');

        if (scooterIdList.length > 1) {
            if (is_activate !== undefined) {
                requestParamsCollection.ids = [...scooterIdList];
                requestParamsCollection.s = is_activate ? SCOOTER_ONLINE : SCOOTER_OFFLINE;
            }
        }
        else {
            if (is_activate !== defaultScooter.get('is_activate')) {
                requestParamsCollection.ids = [...scooterIdList];
                requestParamsCollection.s = is_activate ? SCOOTER_ONLINE : SCOOTER_OFFLINE;
            }
        }

        const empty = obj => Object.keys(obj).length === 0;

        const stateRequest = !empty(requestParamsCollection) ?
            dispatch(updateServiceState(requestParamsCollection)) :
            Promise.resolve();

        return stateRequest.then(response => {
            if ( response && response.data.failure_plate_list && response.data.failure_plate_list.length > 0) {
                dispatch(
                    toggleErrorDialog(
                        I18n.t(
                            'scooter.batch_update_service_state_error_message',
                            { plates: response.data.failure_plate_list.join(', ') }
                        )
                    )
                );
            }
        }).catch(() => {
        }).then(() => {
            onDidAction();
        });
    }

    renderRemoteControl() {
        const { scooterIdList, onDidAction } = this.props;
        const { singleScooter } = this.state;
        const scooterId = scooterIdList[0];
        return (
            scooterId && scooterIdList.length === 1 ?
                (
                    <AuthFeature requiredList={ [AUTH_CONTROL_SCOOTER, AUTH_CONTROL_SCOOTER_WITH_TICKET, AUTH_FIND_BCU_SCOOTER] } term={ ANY_ONE }>
                        <Form className="remote-control" onSubmit={ this.submit }>
                            <legend className="caption">
                                <Translate value="scooter.remote_control" />
                            </legend>
                            <div className="remote-control-wrapper">
                                {
                                    permissionHandler({ requiredList: [AUTH_FIND_BCU_SCOOTER] }) || (singleScooter.get('ecu_type') === 1) ? (
                                        <RemoteControl
                                            scooterId={ scooterId }
                                            type={ RPC_FIND_SCOOTER }
                                        />
                                    ) : null
                                }

                                <RemoteControl
                                    scooterId={ scooterId }
                                    type={ RPC_OPEN_TRUNK }
                                    onFinished={ onDidAction }
                                />
                                <RemoteControl
                                    scooterId={ scooterId }
                                    type={ RPC_UNLOCK }
                                    onFinished={ onDidAction }
                                />
                                <RemoteControl
                                    scooterId={ scooterId }
                                    type={ RPC_LOCK }
                                    onFinished={ onDidAction }
                                />
                            </div>
                        </Form>
                    </AuthFeature>
                ) :
                null
        );
    }

    renderActiveState(jsonsingleScooter) {
        const { scooterIdList } = this.props;
        const activationOptions = [{
            name: 'action_panel.online',
            value: SCOOTER_ONLINE,
            auth: AUTH_DO_SCOOTER_ONLINE,
            translate: true,
        }, {
            name: 'action_panel.offline',
            value: SCOOTER_OFFLINE,
            auth: AUTH_DO_SCOOTER_OFFLINE,
            translate: true,
        }];
        let selected;
        switch (jsonsingleScooter.is_activate) {
        case true:
            selected = SCOOTER_ONLINE;
            break;
        case false:
            selected = SCOOTER_OFFLINE;
            break;
        default:
        };
        const viewMode = !permissionHandler({
            requiredList: [AUTH_DO_SCOOTER_ONLINE, AUTH_DO_SCOOTER_OFFLINE],
            term: { ANY_ONE },
        });
        return (
            <Input
                name="activation"
                type={ TYPE_RADIO }
                caption="action_panel.active_status"
                value={ activationOptions }
                selected={ selected }
                onChange={ this.handleActivationChanged }
                viewMode={ viewMode }
                required={ scooterIdList.length === 1 }
            />
        );
    }


    render() {
        const { isUpdatingState, singleScooter, warningOpen } = this.state;
        const { scooterIdList, onClose } = this.props;
        const jsonsingleScooter = (singleScooter ? singleScooter.toJS() : {});
        let el = (
            <Modal className="action-panel" title={ I18n.t('action_panel.title') } onClose={ onClose }>
                <h1><Translate value="action_panel.no_scooter" /></h1>
            </Modal>
        );
        const { corporate } = this.props;

        if (scooterIdList.length > 0) {
            const showSection = (
                [ACTIVATE_DISABLE, ACTIVATE_ENABLE].indexOf(jsonsingleScooter.activate_state) > -1 ||
                scooterIdList.length > 1
            );

            el = (
                <Modal className="action-panel" title={ I18n.t('action_panel.title') } onClose={ onClose }>
                    {
                        isUpdatingState ?
                            (
                                <Blocker onClick={ e => e.stopPropagation() }>
                                    <RingSpinner />
                                </Blocker>
                            ) :
                            null
                    }
                    { showSection && (
                        <AuthFeature
                            requiredList={ [
                                AUTH_DO_SCOOTER_ONLINE,
                                AUTH_DO_SCOOTER_OFFLINE
                            ] }
                            term={ ANY_ONE }
                        >
                            <Form
                                className="section"
                                onSubmit={ corporate.length > 1 ?
                                    this.toggleWarningDialog : this.submit
                                }
                            >
                                { this.renderActiveState(jsonsingleScooter) }
                                <ButtonGroup>
                                    <Button color="primary">
                                        <Translate value="submit" />
                                    </Button>
                                </ButtonGroup>
                                <WarningModal
                                    show={ warningOpen }
                                    corporate={ corporate }
                                    onClose={ this.toggleWarningDialog }
                                    onSubmit={ this.submit }
                                    inProgress={ isUpdatingState }
                                />
                            </Form>
                        </AuthFeature>
                    ) }
                    { this.renderRemoteControl() }
                </Modal>
            );
        }

        return el;
    }
}

export default connect(state => ({
    i18n: state.i18n,
    opUsers: state.account.get('opUsers'),
}))(ActionPanel);
