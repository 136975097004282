import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'components/Datetime';
import Cell from 'components/ListView/Cell';
import CopyToClipBoard from 'components/CopyToClipBoard';

export default function AssetListRow({ columns, rowData }) {
    return columns.map(({ key, width }) => (
        <Field key={ key } data={ rowData } width={ width } name={ key } />
    ));
}

function Field({ data, name, width }) {
    let element;

    switch (name) {
    case 'create_time':
    case 'ssl_certificate_valid_to':
        element = <Datetime time={ data[name] } />;
        break;
    case 'vin':
    case 'subscribe_id':
    case 'gtu_imei':
    case 'sim_phone_number':
    case 'ssl_certificate_issuer':
        element = <CopyToClipBoard>{ data[name] }</CopyToClipBoard>;
        break;
    case 'plate':
    case 'ssl_certificate_subject':
    default:
        element = data[name];
    }

    return (
        <Cell key={ `${ data.id }-${ name }` } name={ name } style={ { width } }>
            { element }
        </Cell>
    );
}

Field.propTypes = {
    data: PropTypes.shape({
        vin: PropTypes.string,
        plate: PropTypes.string.isRequired,
        subscribe_id: PropTypes.string.isRequired,
        gtu_imei: PropTypes.string.isRequired,
        sim_phone_number: PropTypes.string.isRequired,
        ssl_certificate_issuer: PropTypes.string.isRequired,
        ssl_certificate_subject: PropTypes.string.isRequired,
        create_time: PropTypes.string.isRequired,
        ssl_certificate_valid_to: PropTypes.string.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
};
