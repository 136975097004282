import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './utils';
import qs from 'qs';
import api from 'api';

const GET_ADDITIONAL_FEE= '/expenses';
const GET_VIOLATE_RENTAL= `${GET_ADDITIONAL_FEE}/violate_rental`;
const GET_IMAGE_UPLOAD_URL= `${GET_ADDITIONAL_FEE}/image_upload_url`;
const CREATE_ADDITIONAL_FEE = GET_ADDITIONAL_FEE;
const ADDITIONAL_FEE_DETAIL = `${GET_ADDITIONAL_FEE}/`;

export const additionalFeeApi = createApi({
    reducerPath: 'additionalFeeApi',
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        getAdditionalFee: builder.query({
            query: ({ params }) => ({
                url: GET_ADDITIONAL_FEE,
                params
            }),
            transformResponse: (response) => {
                const { update_time, ...data } = response;
                return ({
                    ...data,
                    updatedTime: update_time ?? Date.now(),
                });
            },
        }),
        getViolateRental: builder.query({
            query: ({ params }) => ({
                url: GET_VIOLATE_RENTAL,
                params
            }),
            forceRefetch: () => true,
        }),
        uploadFeeAttachment: builder.mutation({
            queryFn: async ({ params }, _queryApi, _extraOptions, fetchWithBQ) => {
                const { files } = params;
                const fileNameList = files.map(item => item.name);
                const queryString = qs.stringify({ file_name: fileNameList }, { arrayFormat: 'repeat' });

                const fileUrlResult = await fetchWithBQ({
                    url: `${ GET_IMAGE_UPLOAD_URL }?${ queryString }`,
                    method: 'GET',
                });
                if (fileUrlResult.error) {
                    return { error: fileUrlResult.error };
                }
                const allUploadResult = await Promise.all(files.map(async (file, index) => {
                    const { url } = fileUrlResult.data[index];

                    const { status } = await api.uploadFile({
                        url,
                        file,
                        contentType: 'image/jpeg',
                    });

                    return status;
                }));
                const isError = ([...allUploadResult].filter(item => item !== 200)).length ? true : false;
                const data = fileUrlResult.data.map(({display_filename, storage_filename}) => ({
                    display_filename,
                    storage_filename,
                }));
                return {
                    data: isError ? undefined : data,
                };

            }
        }),
        createExpense: builder.mutation({
            query: ({ params }) => ({
                url: CREATE_ADDITIONAL_FEE,
                method: 'POST',
                body: params,
            }),
        }),
        getAdditionalFeeDetail: builder.query({
            query: (id) => ({
                url: `${ ADDITIONAL_FEE_DETAIL }${ id }`,
            }),
            transformResponse: (response) => {
                const { update_time, ...data } = response;
                return ({
                    ...data,
                    updatedTime: update_time ?? Date.now(),
                });
            },
        }),
        updateAdditionalFeeDetail: builder.mutation({
            query: ({ params: { expense_id, ...payload} }) => ({
                url: `${ ADDITIONAL_FEE_DETAIL }${ expense_id }`,
                method: 'PATCH',
                body: payload,
            }),
        }),
        refundAdditionalFee: builder.mutation({
            query: ({ params: { payment_id, expense_id } }) => ({
                url: `${GET_ADDITIONAL_FEE}/${ expense_id }/payments/${ payment_id }/refund`,
                method: 'POST',
            }),
        }),
        getFeeAttachments: builder.mutation({
            query: ({ params: { id } }) => ({
                url: `${ ADDITIONAL_FEE_DETAIL }${ id }/images`,
            }),
        }),
    })
});

export const {
    useGetAdditionalFeeQuery,
    useLazyGetViolateRentalQuery,
    useUploadFeeAttachmentMutation,
    useCreateExpenseMutation,
    useGetAdditionalFeeDetailQuery,
    useUpdateAdditionalFeeDetailMutation,
    useRefundAdditionalFeeMutation,
    useGetFeeAttachmentsMutation,
} = additionalFeeApi;
