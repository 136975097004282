import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'components/Datetime';
import AuthFeature from 'components/AuthFeature';
import Cell from 'components/ListView/Cell';
import { Translate } from 'react-redux-i18n';
import { NavLink } from 'react-router-dom';
import { EDIT_COUPON, EDIT_SYSTEM_COUPON } from 'constants/routes';
import {
    AUTH_VIEW_COUPON_DETAIL,
} from 'constants/permission';
import { TYPE_COUPON_GENERAL } from 'constants/coupon';
import buildActualPath from 'helpers/build-actual-path';

class ListRow extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.shape({
            coupon_id: PropTypes.string.isRequired,
            title: PropTypes.string,
            coupon_code: PropTypes.string,
            state: PropTypes.number,
            create_time: PropTypes.string,
            valid_from: PropTypes.string,
            valid_to: PropTypes.string,
            used_time: PropTypes.string,
        }).isRequired,
    };

    renderField({ data, name, width }) {
        let el;

        switch (name) {
        case 'title':
            const path = buildActualPath(
                data.coupon_type === TYPE_COUPON_GENERAL ? EDIT_COUPON : EDIT_SYSTEM_COUPON, {
                    couponId: data.coupon_id
                }
            );
            el = (
                <AuthFeature
                    requiredList={ [AUTH_VIEW_COUPON_DETAIL] }
                    fallback={ () => data.title }
                >
                    <NavLink to={ path }>
                        { data.title }
                    </NavLink>
                </AuthFeature>
            );
            break;
        case 'state':
            el = <Translate value={ `customer.coupon_state_${ data.state }` } />;
            break;
        case 'create_time':
        case 'valid_from':
        case 'valid_to':
        case 'used_time':
            el = <Datetime time={ data[name] } />;
            break;
        default:
            el = data[name];
            break;
        }

        return (
            <Cell
                key={ `${ data.id }-${ name }` }
                name={ name }
                style={ {
                    width,
                } }
            >
                { el }
            </Cell>
        );
    }

    render() {
        const { columns, data } = this.props;

        return columns.map(({ key, width }, i) => {
            return this.renderField({ data, width, name: key, });
        });
    }
}

export default ListRow;
