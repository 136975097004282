import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input, FormGroup, Label } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import ControlTemplate from './ControlTemplate';
import DocStatus from './DocStatus';
import Datetime from 'react-datetime';
import { FORBIDDEN_DRIVER_LICENSE_ISSUE } from "constants/customer";

export const FIELD_TYPE = {
    INPUT: 0,
    EMAIL: 1,
    BADGE_INPUT: 2,
    RADIO: 3,
    DATETIME: 4,
    TEXT_AREA: 5,
};

const Field = props => {
    const {
        type,
        name,
        value,
        options,
        pattern,
        maxLength,
        title,
        approved,
        required,
        editing
    } = props;
    let el;
    const style = classNames({
        'view-only': !editing,
        [`field-${ name }`]: true,
    });
    switch (type) {
    case FIELD_TYPE.INPUT:
        if (editing) {
            el = (
                <Input
                    className={ style }
                    type="text"
                    name={ name }
                    defaultValue={ value }
                    required={ required }
                    pattern={ pattern }
                    title={ title }
                    maxLength={ maxLength }
                />
            );
        }
        else {
            el = (
                <span className="verified-input">
                    { value }
                </span>
            );
        }
        break;
    case FIELD_TYPE.EMAIL:
        el = (
            editing ?
                (
                    <Input
                        className={ style }
                        type="email"
                        name={ name }
                        defaultValue={ value }
                        required={ required }
                    />
                ) : <span>{ value }</span>
        );
        break;
    case FIELD_TYPE.BADGE_INPUT:
        if (editing) {
            el = (
                <Input
                    className={ style }
                    type="text"
                    name={ name }
                    defaultValue={ value }
                />
            );
        }
        else {
            if (value !== undefined) {
                el = (
                    <React.Fragment>
                        <span className="verified-input">
                            { value }
                        </span>
                        <DocStatus status={ approved } />
                        {
                            approved === FORBIDDEN_DRIVER_LICENSE_ISSUE && (
                                <Translate className="verified-input" value="customer.forbidden_license" tag="time" />
                            )
                        }
                    </React.Fragment>
                );
            }
            else {
                el = (
                    <span className="verified-input" />
                );
            }
        }
        break;
    case FIELD_TYPE.RADIO:
        el = (
            <FormGroup check>
                {
                    options.map( option => (
                        <Label key={ option.value } check>
                            <Input
                                className={ style }
                                type="radio"
                                name={ name }
                                value={ option.value }
                                defaultChecked={ value === option.value }
                                onChange={ option.onChange }
                            />
                            <Translate value={ option.text } />
                        </Label>
                    ))
                }
            </FormGroup>
        );
        break;
    case FIELD_TYPE.DATETIME:
        el = editing ? (
            <CustomDatetime
                inputProps={ {
                    name: name,
                    autoComplete: 'off',
                    className: style,
                    required: required,
                    pattern: '\\d{4}-\\d{2}-\\d{2}',
                } }
                initialValue={ value }
            />
        ) : (
            <span className={ name }>
                { value }
            </span>
        );
        break;
    case FIELD_TYPE.TEXT_AREA:
        el = editing ? (
            <Input
                className={ style }
                type="textarea"
                name={ name }
                defaultValue={ value }
                required={ required }
                pattern={ pattern }
                title={ title }
                maxLength={ maxLength }
            />
        ) : (
            <span className={ name }>
                { value }
            </span>
        );
        break;
    default:
    }
    return el ? (
        <ControlTemplate large captionKey={ title } required={ required && editing }>
            { el }
        </ControlTemplate>
    ) : null;
};
Field.propTypes = {
    type: PropTypes.oneOf([
        FIELD_TYPE.INPUT,
        FIELD_TYPE.EMAIL,
        FIELD_TYPE.BADGE_INPUT,
        FIELD_TYPE.RADIO,
        FIELD_TYPE.DATETIME,
        FIELD_TYPE.TEXT_AREA,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.bool,
    ]),
    pattern: PropTypes.string,
    maxLength: PropTypes.number,
    title: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape()),
    approved: PropTypes.number,
    required: PropTypes.bool,
    editing: PropTypes.bool,
};
Field.defaultProps = {
    value: undefined,
    pattern: undefined,
    maxLength: undefined,
    title: undefined,
    options: [],
    approved: -1,
    required: false,
    editing: false,
};
export default Field;

const CustomDatetime = ({ ...props }) => {
    return (
        <Datetime
            dateFormat={ I18n.t('datetime_format.date') }
            timeFormat=""
            closeOnSelect={ true }
            renderInput={ props => <Input type="text" { ...props } /> }
            { ...props }
        />
    );
};
