import React from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import AuthView from 'components/AuthView';
import SearchField from 'components/SearchField';
import FlexGroup from 'components/FlexGroup';
import MultiselectFilter from 'components/Filter/MultiselectFilter';
import { getDefaultPageSize } from 'components/Pagination/PageSizeSelect';
import { fetchSystemCouponList, updateDocumentTitle } from 'actions';
import {
    statusListForSystemCupon as statusList,
    typeList,
    promotionTypeList,
    STATUS_DRAFT,
    STATUS_PUBLISH,
} from 'constants/coupon';
import SystemCouponList from './SystemCouponList';
import './system-coupon.scss';

const FILTER_SEARCH_TYPE = 'search_type';
const FILTER_STATUS = 'status';
const FILTER_TYPE = 'discount_type';
const FILTER_PROMOTE_TYPE = 'promote_type';
const FILTER_TITLE = 'title';

class SystemCoupon extends React.Component {

    state = {
        queryPayload: {
            filter: {},
            sort: undefined,
            pageSize: getDefaultPageSize().value,
            pageIndex: 1,
        },
    }

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { pathname } = location;

        dispatch(updateDocumentTitle(`routes.${ pathname }`));
    }

    handleSort = ({ field, order }) => {
        const { queryPayload } = this.state;
        queryPayload.pageIndex = 1;
        if (field && order) {
            queryPayload.sort = `${field},${order}`;
        }
        else {
            queryPayload.sort = undefined;
        }

        this.fetchData();
    }

    handleFilterChanged = filterName => payload => {
        const { queryPayload } = this.state;
        let { filter } = queryPayload;
        queryPayload.pageIndex = 1;

        switch (filterName) {
        case FILTER_SEARCH_TYPE:
            filter = {
                [FILTER_TITLE]: payload ?? undefined,
            };
            this.keyword = payload;
            break;
        case FILTER_STATUS:
        case FILTER_TYPE:
        case FILTER_PROMOTE_TYPE:
        default:
            filter[filterName] = payload.inputSelect;
            break;
        }

        this.setState({
            queryPayload: {
                ...queryPayload,
                filter,
            }
        }, this.fetchData);
    }

    handlePageSelect = page => {
        const { queryPayload } = this.state;
        queryPayload.pageIndex = page;
        this.fetchData();
    }

    handlePageSizeChange = selection => {
        const { queryPayload } = this.state;
        queryPayload.pageIndex = 1;
        queryPayload.pageSize = selection.value;
        this.fetchData();
    }

    fetchData = () => {
        const { queryPayload } = this.state;
        const { filter, sort, pageSize, pageIndex, } = queryPayload;
        let { status } = filter;
        if (!status || status.length === 0) {
            status = [STATUS_DRAFT, STATUS_PUBLISH];
        }

        const { dispatch } = this.props;
        return dispatch(fetchSystemCouponList({
            ...filter,
            status,
            sort,
            page: pageIndex,
            size: pageSize,
        }));

    }

    render() {
        const { queryPayload } = this.state;

        return (
            <AuthView className="coupon system-coupon-list">
                <FlexGroup start className="search-section">
                    <Translate className="search-for-caption" value="search_for" />
                    <SearchField
                        placeholder={ I18n.t('ad.search_box_placeholder') }
                        onSubmit={ this.handleFilterChanged(FILTER_SEARCH_TYPE) }
                        value={ this.keyword }
                    />
                    <FlexGroup start className="filters">
                        <MultiselectFilter
                            title={ I18n.t('coupon.status') }
                            options={
                                statusList.map(statusName => ({
                                    text: I18n.t(`coupon.status_${ statusName }`),
                                    value: statusName,
                                }))
                            }
                            defaultSelected={ queryPayload.filter.status }
                            onChange={ this.handleFilterChanged(FILTER_STATUS) }
                        />
                        <MultiselectFilter
                            title={ I18n.t('coupon.type') }
                            options={
                                typeList.map(typeName => ({
                                    text: I18n.t(`coupon.type_${ typeName }`),
                                    value: typeName,
                                }))
                            }
                            defaultSelected={ queryPayload.filter.discount_type }
                            onChange={ this.handleFilterChanged(FILTER_TYPE) }
                        />
                        <MultiselectFilter
                            title={ I18n.t('coupon.promote_type') }
                            options={
                                promotionTypeList.map(typeName => ({
                                    text: I18n.t(`coupon.promote_type_${ typeName }`),
                                    value: typeName,
                                }))
                            }
                            defaultSelected={ queryPayload.filter.promote_type }
                            onChange={ this.handleFilterChanged(FILTER_PROMOTE_TYPE) }
                        />
                    </FlexGroup>
                </FlexGroup>
                <SystemCouponList
                    pageSize={ queryPayload.pageSize }
                    onSort={ this.handleSort }
                    onFetch={ this.fetchData }
                    onSelect={ this.handlePageSelect }
                    onChange={ this.handlePageSizeChange }
                />
            </AuthView>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(SystemCoupon);
