import {
    GET_GEOLOCATION,
    CLEAR_GEOLOCATION,
    ERROR_GEOLOCATION,
} from 'constants/action-type';

const supportGeolocation = ('geolocation' in navigator);
const clearWatchId = () => {
    if (supportGeolocation) {
        navigator.geolocation.clearWatch(watchId);
    }
};
let watchId;


export const getGeolocation = () => dispatch => {
    let promise;

    if (supportGeolocation) {
        const successHandler = res => {
            return dispatch({
                type: GET_GEOLOCATION,
                data: res,
            });
        };
        const errorHandler = err => {
            clearWatchId();

            return dispatch({
                type: ERROR_GEOLOCATION,
                err,
            });
        };
        const options = {
            enableHighAccuracy: true,
            timeout: 60000,
        };
        // listen to current location
        watchId = watchId || navigator.geolocation.watchPosition(successHandler, errorHandler, options);

        promise = new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(res => {
                return resolve(successHandler(res));
            }, err => {
                return reject(errorHandler(err));
            }, {
                ...options,
                enableHighAccuracy: false,
            });
        });
    }
    else {
        promise = Promise.reject(new Error('not support geolocation'));
    }

    return promise;
};

export const clearGeolocation = () => dispatch => {
    clearWatchId();

    return Promise.resolve(dispatch({
        type: CLEAR_GEOLOCATION,
    }));
};
