import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import MultiselectFilter from 'components/Filter/MultiselectFilter';
import {
    GROUP_STATUS,
} from 'constants/ticket';

const CustomStatusFilter = ({ selected, onChange, withoutClosed }) => {
    const options = GROUP_STATUS.map(item => ({
        value: item,
        text: I18n.t(`ticket.status_${ item }`),
    }));

    if (withoutClosed) {
        options.splice(10, 1);
    }

    return (
        <MultiselectFilter
            title={ I18n.t('ticket.status') }
            options={ options }
            defaultSelected={ selected }
            onChange={ onChange }
        />
    );
};
CustomStatusFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.number),
    withoutClosed: PropTypes.bool
};
CustomStatusFilter.defaultProps = {
    selected: [],
    withoutClosed: false
};

export default CustomStatusFilter;
