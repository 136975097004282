import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import Smart from 'components/Smart';
import FlexGroup from 'components/FlexGroup';
import History, {
    RENTAL_HISTORY_FULL,
} from 'components/RentalHistory';
import moment from 'moment';
import Pagination, { Refresh, TotalCount } from 'components/Pagination';
import PageSizeSelect from 'components/Pagination/PageSizeSelect';
import { Translate } from 'react-redux-i18n';

const Period = ({ fromTime, toTime }) => {
    let el = null;

    if (fromTime && toTime) {
        el = (
            <div className="rental-period">
                <Translate className="record-periods" value="rental.record_periods" />
                <span>{ moment(fromTime).format('YYYY-MM-DD') }</span>
                <span className="divider">-</span>
                <span>{ moment(toTime).format('YYYY-MM-DD') }</span>
            </div>
        );
    }

    return el;
};
class rentalList extends Component {
    static propTypes = {
        history: ImmutablePropTypes.map.isRequired,
        onSort: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onFetch: PropTypes.func.isRequired,
        pageSize: PropTypes.number,
        fromTime: PropTypes.oneOfType([
            PropTypes.instanceOf(moment),
            PropTypes.string,
        ]),
        toTime: PropTypes.oneOfType([
            PropTypes.instanceOf(moment),
            PropTypes.string,
        ]),
    };

    static defaultProps = {
        fromTime: undefined,
        toTime: undefined,
        pageSize: undefined,
    };

    render() {
        const { history, onSort, onSelect, onChange, pageSize, onFetch, fromTime, toTime } = this.props;
        const { page_index, page_count, data_list, total, __responseTime } = (history && history.toJS()) || {};

        return (
            <React.Fragment>
                <FlexGroup spaceBetween className="pagination-control-sec">
                    <FlexGroup>
                        <Pagination
                            page={ page_index }
                            total={ page_count }
                            offset={ pageSize }
                            onSelect={ onSelect }
                        />
                        <div className="page-size-select">
                            <PageSizeSelect
                                onChange={ selection => onChange(selection.value) }
                            />
                        </div>
                        <TotalCount count={ total } />
                        <Period fromTime={ fromTime } toTime={ toTime } />
                    </FlexGroup>
                    <Refresh time={ __responseTime } onClick={ onFetch } />
                </FlexGroup>
                <Smart
                    seamless
                    fetch={ onFetch }
                    onUnmount={ () => {} }
                    refreshTime={ 60 }
                    className="list-view-wrap"
                >
                    <History
                        history={ data_list }
                        type={ RENTAL_HISTORY_FULL }
                        onSort={ onSort }
                        sortable
                    />
                </Smart>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    history: state.rental.get('list'),
}))(rentalList);
