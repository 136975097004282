import React from 'react';
import FlexGroup from 'components/FlexGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    CORPORATE_TYPE_NORMAL,
    CORPORATE_TYPE_BASIC,
    CORPORATE_TYPE_VIP,
} from 'constants/customer';
import { Translate, I18n } from 'react-redux-i18n';
import ChoiceGroupField from 'components/ChoiceGroup/ChoiceGroupField';
import MultiselectFilter from 'components/Filter/MultiselectFilter';
import DatetimeRangeFilter from 'components/Filter/DatetimeRangeFilter';
import { getPaymentStatusOptions } from 'components/Customer/PaymentStatus';
import { getRentalStatusOptions } from 'components/Scooter/RentalState';
export const SEARCH = 'search';
export const SEARCH_BY_RENTAL = 'rental_id';
export const SEARCH_BY_PLATE = 'plate_no';
export const SEARCH_BY_CUSTOMER = 'customer_id';
export const RENTAL_STATUS = 'rental_status';
export const PAYMENT_STATUS = 'payment_status';
export const CORPORATE_TYPE = 'corporate_type';
export const RESERVE_TIME = 'reserve_time';

export const defaultRange = [14, 'days'];

class rentalListFilter extends React.PureComponent {
    static propTypes = {
        defaultChecked: PropTypes.node,
        onChoiceChange: PropTypes.func,
        onChangeKeyword: PropTypes.func,
        applyFilter: PropTypes.func,
        value: PropTypes.string,
        appliedDateRange: PropTypes.bool,
        filter: PropTypes.shape(),
    };
    static defaultProps = {
        defaultChecked: '',
        applyFilter: () => {},
        onChoiceChange: () => {},
        onChangeKeyword: () => {},
        value: undefined,
        appliedDateRange: false,
        filter: undefined,
    };

    renderFilters() {
        const { applyFilter, appliedDateRange, filter } = this.props;
        const corporateTypeOptions = [
            CORPORATE_TYPE_NORMAL,
            CORPORATE_TYPE_BASIC,
            CORPORATE_TYPE_VIP,
        ].map(
            value => ({
                text: I18n.t(`customer.corporate_type_${ value }`),
                value,
            })
        );

        return (
            <section className="filters">
                <DatetimeRangeFilter
                    range={ defaultRange }
                    title={ I18n.t('reservation_time') }
                    defaultFrom={ filter.reserve_start_time }
                    defaultTo={ filter.reserve_end_time }
                    onChange={ this.handlePeriodChanged }
                    onApply={ applyFilter(RESERVE_TIME) }
                    applied={ appliedDateRange }
                />
                <MultiselectFilter
                    title={ I18n.t('scooter.rental_state') }
                    options={ getRentalStatusOptions() }
                    defaultSelected={ filter[RENTAL_STATUS] }
                    onChange={ applyFilter(RENTAL_STATUS) }
                />
                <MultiselectFilter
                    title={ I18n.t('payment_status') }
                    options={ getPaymentStatusOptions() }
                    defaultSelected={ filter[PAYMENT_STATUS] }
                    onChange={ applyFilter(PAYMENT_STATUS) }
                />
                <MultiselectFilter
                    title={ I18n.t('customer.corporate_type') }
                    options={ corporateTypeOptions }
                    defaultSelected={ filter[CORPORATE_TYPE] }
                    onChange={ applyFilter(CORPORATE_TYPE) }
                />
            </section>
        );
    }

    render() {
        const { defaultChecked, onChoiceChange, onChangeKeyword, applyFilter, value } = this.props;
        const fieldTypes = [
            {
                id: SEARCH_BY_RENTAL,
                name: I18n.t('rental.rental_no'),
                value: SEARCH_BY_RENTAL,
            }, {
                id: SEARCH_BY_PLATE,
                name: I18n.t('scooter.plate'),
                value: SEARCH_BY_PLATE,
            }, {
                id: SEARCH_BY_CUSTOMER,
                name: I18n.t('customer.customer_no'),
                value: SEARCH_BY_CUSTOMER,
            }
        ];
        return (
            <FlexGroup start className="choice-group-field">
                <ChoiceGroupField
                    renderTitle={ () => <Translate className="search-for-caption" value="search_for" /> }
                    defaultChecked={ defaultChecked }
                    options={ fieldTypes }
                    onChoiceChange={ onChoiceChange }
                    onChange={ onChangeKeyword }
                    onSubmit={ applyFilter(SEARCH) }
                    value={ value }
                />
                { this.renderFilters() }
            </FlexGroup>
        );
    }
}
export default connect(state => ({
    i18n: state.i18n,
}))(rentalListFilter);
