import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import Cell from './Cell';

const CheckboxCell = ({ onSelect, selected }) => (
    <Cell name="checkbox" onClick={ onSelect }>
        <Input type="checkbox" checked={ selected } onChange={ () => {} } />
    </Cell>
);
CheckboxCell.propTypes = {
    onSelect: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
};

export default CheckboxCell;
