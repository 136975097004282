import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import buildActualPath from 'helpers/build-actual-path';
import {
    TICKET_DETAIL,
} from 'constants/routes';

class RelatedTickets extends Component {
    static propTypes = {
        list: PropTypes.arrayOf(
            PropTypes.number,
        )
    };

    static defaultProps = {
        list: [],
    };

    renderTicket = (id) => {
        return <NavLink key={ id } to={ buildActualPath(TICKET_DETAIL, { ticketId: id }) }>{ id }</NavLink>;
    }

    render() {
        const { list } = this.props;

        return (
            <React.Fragment>
                {
                    list.length ?
                        (
                            <div className="related-tickets">
                                <span className="title">
                                    <Translate value="scooter.related_tickets" />
                                </span>
                                {
                                    list.map(ticketId => this.renderTicket(ticketId))
                                }
                            </div>
                        ) : null
                }
            </React.Fragment>
        );

    }
}

export default RelatedTickets;
