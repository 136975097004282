import React from 'react';
import PropTypes from 'prop-types';

export const Attributes = ({ attributes }) => (
    <section className="attributes">
        {
            attributes.map(attr => (
                <span key={ attr } className="attribute">{ attr }</span>
            ))
        }
    </section>
);
Attributes.propTypes = {
    attributes: PropTypes.arrayOf(PropTypes.string),
};
Attributes.defaultProps = {
    attributes: [],
};
