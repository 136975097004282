import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { NavLink } from 'react-router-dom';
import Datetime from 'components/Datetime';
import Cell from 'components/ListView/Cell';
import buildActualPath from 'helpers/build-actual-path';
import permissionHandler from 'helpers/permission-handler';
import { MISSION_DETAIL } from 'constants/routes';
import { AUTH_VIEW_MISSION_DETAIL } from 'constants/permission';

export default function MissionListRow({ columns, rowData }) {
    return columns.map(({ key, width }) => (
        <Field key={ key } data={ rowData } width={ width } name={ key } />
    ));
}

function Field({ data, name, width }) {
    let element;

    switch (name) {
    case 'title':
        const auth = permissionHandler({ requiredList: [AUTH_VIEW_MISSION_DETAIL] });
        element = auth ? (
            <NavLink to={ buildActualPath(MISSION_DETAIL, { missionId: data['id'] }) }>
                { data[name] }
            </NavLink>
        ) : data[name];
        break;
    case 'criteria_type':
        element = <Translate value={ `mission.criteria_type_${ data[name] }` } />;
        break;
    case 'status':
        element = <Translate value={ `mission.status_${ data[name] }` } />;
        break;
    case 'publish_time':
    case 'valid_from':
    case 'valid_to':
        element = <Datetime time={ data[name] } />;
        break;
    default:
        element = data[name];
    }

    return (
        <Cell key={ `${ data.id }-${ name }` } name={ name } style={ { width } }>
            { element }
        </Cell>
    );
}

Field.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        criteria_type: PropTypes.number.isRequired,
        status: PropTypes.number.isRequired,
        valid_from: PropTypes.string.isRequired,
        valid_to: PropTypes.string.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
};
