import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './ring.scss';

class Ring extends Component {
    static propTypes = {
        onMount: PropTypes.func,
        size: PropTypes.oneOf(['', 'small']),
        dark: PropTypes.bool,
    };

    static defaultProps = {
        size: '',
        dark: false,
        onMount: () => {},
    };

    constructor(props) {
        super(props);

        this.el = React.createRef();
    }

    componentDidMount() {
        const { onMount } = this.props;

        onMount(this.el);
    }

    render() {
        const { size, dark } = this.props;
        const style = classNames([
            'lds-ring',
            size,
            { 'dark': dark },
        ]);
        return (
            <div ref={ this.el } className={ style } />
        );
    }
};

export default Ring;
