/**
 * Action - Scooter
 */
import api from 'api';

import {
    FETCH_MY_TASK,
    CLEAR_MY_TASK,
    FETCH_SCOOTER_LIST,
    CLEAR_SCOOTER_LIST,
    FETCH_SCOOTER_HISTORY,
    CLEAR_SCOOTER_HISTORY,
    FETCH_SINGLE_SCOOTER,
    CLEAR_SINGLE_SCOOTER,
    REMOTE_CONTROL,
    BATCH_UPDATE_SERVICE_STATE,
    OPEN_SCOOTER_DETAIL_POPUP,
    FETCH_SCOOTER_RENTAL_HISTORY,
    CLEAR_SCOOTER_RENTAL_HISTORY,
    FORCE_RETURN,
    GTU_REPLACEMENT,
    FETCH_SINGLE_SCOOTER_GTU,
    CLEAR_SINGLE_SCOOTER_GTU,
    FETCH_SEARCHED_GTU_LIST,
    CLEAR_SEARCHED_GTU_INFO,
    FETCH_NEARBY_SCOOTERS,
    FETCH_ALL_SCOOTERS,
    CLEAR_ALL_SCOOTERS,
    FETCH_SCOOTER_RELATED_TICKETS,
    FETCH_SCOOTER_MODELS,
    CLEAR_SCOOTER_RELATED_TICKETS,
    FIRMWARE_VERSION,
    CLEAR_FIRMWARE_VERSION
} from 'constants/action-type';

import { asyncInterface } from './util';

export const fetchMyTask = (params = {}) => dispatch => {
    const request = () => {
        return api.fetchMyTask(params)().then(response => {
            response.data.data_list.forEach(task => {
                if (typeof task.location_lat !== 'number') {
                    task.location_lat = 0;
                }

                if (typeof task.location_lng !== 'number') {
                    task.location_lng = 0;
                }

            });

            return response;
        });
    };

    return asyncInterface({
        asyncAction: request,
        type: FETCH_MY_TASK,
        dispatch,
    });
};

export const clearMyTask = () => dispatch => dispatch({
    type: CLEAR_MY_TASK,
});

export const fetchScooterList = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchScooterList(params),
        type: FETCH_SCOOTER_LIST,
        dispatch,
    });
};

export const fetchScooterListByFinder = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchScooterListByFinder(params),
        type: FETCH_SCOOTER_LIST,
        dispatch,
    });
};

export const clearScooterList = () => dispatch => dispatch({
    type: CLEAR_SCOOTER_LIST,
});

export const fetchSingleScooter = scooterId => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchSingleScooter(scooterId),
        type: FETCH_SINGLE_SCOOTER,
        dispatch,
    });
};

export const openScooterDetailPopup = scooterId => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchSingleScooter(scooterId),
        type: FETCH_SINGLE_SCOOTER,
        dispatch,
    }).then(() => dispatch({
        type: OPEN_SCOOTER_DETAIL_POPUP,
        data: true,
    }));
};

export const closeScooterDetailPopup = () => dispatch => {
    clearSingleScooter();

    dispatch({
        type: OPEN_SCOOTER_DETAIL_POPUP,
        data: false,
    });
};

export const fetchScooterHistory = (scooterId, params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchScooterHistory(scooterId, params),
        type: FETCH_SCOOTER_HISTORY,
        dispatch,
    });
};

export const clearScooterHistory = () => dispatch => dispatch({
    type: CLEAR_SCOOTER_HISTORY,
});

export const clearSingleScooter = () => dispatch => dispatch({
    type: CLEAR_SINGLE_SCOOTER,
});

export const sendRemoteCommand = (scooterId, cmd, warn_pausing = true) => dispatch => {
    return asyncInterface({
        asyncAction: api.sendRemoteCommand(scooterId, cmd, warn_pausing),
        type: REMOTE_CONTROL,
        dispatch,
    });
};

export const updateServiceState = list => dispatch => {
    return asyncInterface({
        asyncAction: api.updateServiceState(list),
        type: BATCH_UPDATE_SERVICE_STATE,
        dispatch,
    });
};

export const fetchScooterRentalHistory = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchRentalHistory(params),
        type: FETCH_SCOOTER_RENTAL_HISTORY,
        dispatch,
    });
};

export const clearScooterRentalHistory = () => dispatch => dispatch({
    type: CLEAR_SCOOTER_RENTAL_HISTORY,
});

export const forceReturn = (scooterId, params) => dispatch => {
    return asyncInterface({
        asyncAction: api.forceReturn(scooterId, params),
        type: FORCE_RETURN,
        dispatch,
    });
};

export const replaceGTU = (scooterId, params) => dispatch => {
    return asyncInterface({
        asyncAction: api.replaceGTU(scooterId, params),
        type: GTU_REPLACEMENT,
        dispatch,
    });
};

export const fetchSingleScooterGTU = (scooter_id) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchGTUList({ scooter_id }),
        type: FETCH_SINGLE_SCOOTER_GTU,
        dispatch,
    });
};

export const clearSingleScooterGTU = (scooterId) => dispatch => dispatch({
    type: CLEAR_SINGLE_SCOOTER_GTU,
    data: scooterId,
});

export const fetchSearchedGTUList = (gtu_imei) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchGTUList({ gtu_imei }),
        type: FETCH_SEARCHED_GTU_LIST,
        dispatch,
        data: gtu_imei,
    });
};

export const clearSearchedGTUInfo = () => dispatch => dispatch({
    type: CLEAR_SEARCHED_GTU_INFO,
});

export const fetchNearbyScooters = ({ lat, lng }) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchNearbyScooters({ lat, lng }),
        type: FETCH_NEARBY_SCOOTERS,
        dispatch,
    });
};

export const clearNearbyScooters = () => dispatch => dispatch({
    type: FETCH_NEARBY_SCOOTERS,
    data: { data_list: [] },
});

export const fetchAllScooters = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchAllScooters(params),
        type: FETCH_ALL_SCOOTERS,
        dispatch,
    });
};

export const clearAllScooters = () => ({
    type: CLEAR_ALL_SCOOTERS,
    data: { data_list: [] },
});

export const fetchScooterRelatedTicket = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchMyTask(params),
        type: FETCH_SCOOTER_RELATED_TICKETS,
        dispatch,
    });
};

export const clearScooterRelatedTicket = (scooterId) => ({
    type: CLEAR_SCOOTER_RELATED_TICKETS,
    data: { scooterId },
});

export const fetchScooterModels = () => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchScooterModels(),
        type: FETCH_SCOOTER_MODELS,
        dispatch,
    });
};

export const fetchFirmwareVersion = (scooterId) => dispatch => {
    return asyncInterface({
        asyncAction: api.firmwareVersion(scooterId),
        type: FIRMWARE_VERSION,
        dispatch,
    });
};

export const clearFirmwareVersion = () => ({
    type: CLEAR_FIRMWARE_VERSION,
});
