const Columns = [{
    name: 'expense_id',
    value: 'additional_fee.request_no',
    width: 150,
}, {
    name: 'rental_id',
    value: 'rental.rental_no',
    width: 150,
}, {
    name: 'fee_type',
    value: 'additional_fee.fee_type',
    width: 100,
}, {
    name: 'amount',
    value: 'rental.price',
    width: 89,
}, {
    name: 'create_time',
    value: 'customer.create_time',
    width: 120,
}, {
    name: 'status',
    value: 'additional_fee.status',
    width: 89,
}];

export default Columns;
