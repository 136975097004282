import React from 'react';
import PropTypes from 'prop-types';
import Input, { TYPE_SEARCH_DROPDOWNLIST } from 'components/Form/Input';
import api from 'api';

export default class SystemCouponSelect extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        selected: PropTypes.shape({
            coupon_title: PropTypes.string,
            coupon_id: PropTypes.string,
        }),
        promoteType: PropTypes.arrayOf(PropTypes.number).isRequired,
        onChange: PropTypes.func.isRequired,
        viewMode: PropTypes.bool.isRequired,
        required: PropTypes.bool.isRequired,
    }

    static defaultProps = {
        selected: {},
    }

    state = {
        couponOptions: []
    }

    componentDidMount() {
        this.fetchAvailableSystemCoupons();
    }

    async fetchAvailableSystemCoupons() {
        const { selected, promoteType: promote_type } = this.props;
        let couponOptions;

        try {
            const { data } = await api.fetchAvailableSystemCoupons({ promote_type })();
            couponOptions = data.map(coupon => ({
                name: coupon.title,
                value: coupon.id,
            }));
            couponOptions.unshift({
                name: '',
                value: '',
            });
        }
        catch (error) {
            couponOptions = [{
                name: selected.coupon_title,
                value: selected.coupon_id,
            }];
        }

        this.setState({ couponOptions });
    }

    render() {
        const { couponOptions } = this.state;
        const { name, selected, onChange, viewMode, required } = this.props;

        return (
            <Input
                name={ name }
                type={ TYPE_SEARCH_DROPDOWNLIST }
                caption="promotion.coupon_name"
                value={ couponOptions }
                selected={ selected.coupon_id }
                onChange={ onChange }
                viewMode={ viewMode }
                required={ required }
            />
        );
    }
}