import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SearchField from 'components/SearchField';
import { Card, CardBody, Fade } from 'reactstrap';
import { Translate } from 'react-redux-i18n';
import Blocker from 'components/Blocker';
import { ReactComponent as Arrow } from 'assets/form-dropdown-arrow-focus.svg';
import './search-select.scss';

export const SEPARATOR = '$@#SEPARATOR';

class SearchSelect extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        selected: PropTypes.node,
        value: PropTypes.arrayOf(PropTypes.shape({
            // translation key
            name: PropTypes.string,
            value: PropTypes.node,
            translate: PropTypes.bool,
        })).isRequired,
        onChange: PropTypes.func,
        onInvalid: PropTypes.func,
        required: PropTypes.bool,
    }

    static defaultProps = {
        name: undefined,
        selected: [],
        onChange: () => {},
        onInvalid: () => {},
        required: false,
    }

    constructor(props) {
        super(props);

        const { selected } = props;
        this.state = {
            selected,
            open: false,
            keyword: '',
        };
    }

    handleToggleList = () => {
        const { open } = this.state;

        this.setState({
            open: !open,
        });
    }

    handleKeywordChange = keyword => {
        this.setState({
            keyword,
        });
    }

    handleOptionClick = ({ name, value }) => e => {
        this.setState({
            selected: value,
            open: false,
        });

        const { onChange } = this.props;
        onChange({ name, value });
    }

    render() {
        const { open, keyword, selected } = this.state;
        const { name, value, required, onInvalid } = this.props;
        const selectedName = (value.filter(item => item.value === selected)[0] || {}).name;
        const Options = () => {
            return value.map(({ name, value, translate }, index) => {
                if (name === SEPARATOR) {
                    return (
                        <li key="separator" className="separator" onClick={ this.handleToggleList } />
                    );
                }

                const isTranslation = (typeof translate === 'boolean' ? translate : true);
                const Text = () => (
                    isTranslation ?
                        <Translate value={ name } /> :
                        <span>{ name }</span>
                );

                return (
                    name.toUpperCase().indexOf(keyword.toUpperCase()) > -1 ?
                        (
                            <li className="option" key={ value } onClick={ this.handleOptionClick({ name, value }) }>
                                <Text />
                            </li>
                        ) :
                        null
                );
            });
        };
        const iconClass = classNames({
            arrow: true,
            up: open
        });
        const blockerClass = classNames({
            'hide': !open,
        });
        const collasibleClass = classNames({
            'collasible-panel': true,
        });

        return (
            <div className="search-select">
                <Blocker className={ blockerClass } onClick={ this.handleToggleList } />
                <input
                    type="text"
                    className="selected-id"
                    value={ selected === null ? undefined : selected }
                    name={ name }
                    required={ required }
                    onChange={ () => {} }
                    onInvalid={ onInvalid }
                    onKeyPress={ e => e.preventDefault() }
                />
                <button type="button" className="btn btn-secondary display-text" onClick={ this.handleToggleList }>
                    <span>{ selectedName }</span>
                    <Arrow className={ iconClass } />
                </button>
                <Fade
                    className={ collasibleClass }
                    in={ open }
                >
                    <Card>
                        <CardBody>
                            <SearchField
                                tag="div"
                                onChange={ this.handleKeywordChange }
                                value={ keyword }
                                stopTriggerByEnter
                            />
                            <ul className="option-container">
                                <Options />
                            </ul>
                        </CardBody>
                    </Card>
                </Fade>
            </div>
        );
    }
}
export default SearchSelect;
