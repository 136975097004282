/**
 * Action - Promotion
 */
import api from 'api';
import { asyncInterface } from './util';
import {
    FETCH_PROMOTION_LIST,
    FETCH_ONE_PROMOTION,
    UPDATE_ONE_PROMOTION,
    CLEAN_ONE_PROMOTION,
} from 'constants/action-type';

export const fetchPromotionList = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchPromotionList(params),
        type: FETCH_PROMOTION_LIST,
        dispatch,
    });
};

export const fetchOnePromotion = id => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchOnePromotion(id),
        type: FETCH_ONE_PROMOTION,
        dispatch,
    });
};

export const fetchUserListUploadUrl = id => {
    return api.fetchUserListUploadUrl({ id });
};

export const updateOnePromotion = (id, params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.updateOnePromotion(id, params),
        type: UPDATE_ONE_PROMOTION,
        dispatch,
    });
};

export const cleanOnePromotion = () => {
    return {
        type: CLEAN_ONE_PROMOTION,
    };
};

export const createOnePromotion = (params = {}) => {
    return api.createOnePromotion(params);
};
