import {
    APPROVAL_APPROVE_ID,
    APPROVAL_REVOKE_ID,
    APPROVAL_APPROVE_LIGHT_DUTY,
    APPROVAL_APPROVE_HEAVY_DUTY,
    APPROVAL_REVOKE_DRIVER_LICENSE,
    APPROVAL_KEEP_CURRENT_VALUE,
    VERIFICATION_NOT_YET,
    VERIFICATION_SUCCESS,
    VERIFICATION_DENIED_FRAUD,
    VERIFICATION_DENIED_UNSUPPORTED_ID_TYPE,
    VERIFICATION_DENIED_UNSUPPORTED_ID_COUNTRY,
    VERIFICATION_ERRO_NOT_READABLE_ID,
    VERIFICATION_VALIDITY_FALSE,
    VERIFICATION_VALIDITY_TRUE,
    VERIFICATION_RESULT_NOT_MATCH_OTHER_TYPE,
    VERIFICATION_INVALID_DOC,
    VERIFICATION_DUPLICATED_ID,
    LICENSE_TYPE_INVALID_LICENSE,
    LICENSE_TYPE_OTHER,
    LICENSE_TYPE_ORDINARY_HEAVY_DUTY_MOTORCYCLE,
    LICENSE_TYPE_LARGE_HEAVY_DUTY_MOTORCYCLE,
    COUPON_STATE_ACTIVE,
    COUPON_STATE_PAST,
    PLAN_BASIC,
    PLAN_CORPORATE,
    CORPORATE_TYPE_NORMAL,
    CORPORATE_TYPE_BASIC,
    CORPORATE_TYPE_VIP,
    EMAIL_VERIFIED,
    EMAIL_UNVERIFIED,
    EMAIL_FORCE_VERIFY,
    ERROR_NOT_FOUND,
    ERROR_ID_DUPLICATED,
    ERROR_EMAIL_DUPLICATED,
    ERROR_BANNED_LIGHT_DUTY,
    ERROR_FORMAT_ERROR,
    ERROR_PHONE_DUPLICATED,
    OVERALL_UNVERIFIED,
    OVERALL_ALLOWED,
    OVERALL_MANDATORY_VERIFY_EMAIL,
    OVERALL_NOT_MATCH_ID,
    OVERALL_DUPLICATED_ID,
    OVERALL_FAILED_VERIFICATION,
    OVERALL_RENT_SUSPENDED,
    OVERALL_NO_BINDING_CREDITCARD,
    OVERALL_EXPIRED_PAYMENT_REQUEST,
    OVERALL_OUTSTANDING_RENTAL_PAYMENT,
    IN_PROGRESS,
    ABORTED,
    ACHIEVED,
    EXPIRED,
    TIMES_LIMIT_TYPE_PER_DAY,
    TIMES_LIMIT_TYPE_PER_MONTH,
    EASY_CARD,
    MENGO,
    PURCHASE_CANCELED,
    PURCHASE_ACTIVE,
    PURCHASE_EXPIRED,
    PURCHASE_INCOMING,
    ERROR_CODE_REFUND_0,
    ERROR_CODE_REFUND_40001,
    ERROR_CODE_REFUND_40901,
    ERROR_CODE_REFUND_40902,
    ERROR_CODE_REFUND_40903,
    ERROR_CODE_REFUND_40904,
    GSH_PASS_STATE_ACTIVE,
    GSH_PASS_STATE_EXPIRED,
    ERROR_CODE_PASS_REFUND_41001,
    ERROR_CODE_PASS_REFUND_41002,
    ERROR_CODE_PASS_REFUND_41003,
    ERROR_CODE_PASS_REFUND_41004,
    ERROR_CODE_PASS_REFUND_41005,
} from 'constants/customer';

const customer = {
    'document_title': '使用者',
    'customer_finder': '客戶搜尋',
    'customer_profile': '使用者資訊',
    'personal_data': '個人資訊',
    'membership': '會籍',
    'documents_verification': '文件驗證',
    'edited_verification': '編輯驗證',
    'details': '詳細',
    'user_id': '用戶身份',
    'name': '名稱',
    'plan': '方案',
    'attributes': '屬性',
    'customer_no': '用戶編號',
    'new_id': '身分證',
    'birth': '出生日期',
    'id_no': '證件號碼',
    'id_approve': '證件驗證',
    'id_birth': '證件生日',
    'invalid_id_format': '無效的身份證號碼',
    'driving_license': '駕照(正面)',
    'driving_license_back': '駕照(反面)',
    'live_photo': 'Live Photo',
    'license_no': '駕照號碼',
    'license_approve': '駕照驗證',
    'license_birth': '駕照生日',
    'license_type': '駕照種類',
    'cross_check': '交互核對',
    'birthday': '出生日期',
    'first_name/last_name': '名字 / 姓氏',
    'first_name': '名字',
    'last_name': '姓氏',
    'mobile': '手機號碼',
    'email': '電子信箱',
    'join_date': '加入日期',
    'credit_card_no': '信用卡號(後4碼)',
    'associated_gopocket': '連動 Gogoro Account',
    'reward_point': 'Gogoro Rewards 餘額',
    'reward_point_unit': 'Gogoro Smart Points',
    'reward_point_expired_time': 'Gogoro Rewards 有效期限',
    'recent_history': '近期紀錄',
    'coupon': '折扣碼',
    'view_documents': '查看文件',
    'compensation': '補償券',
    'uploaded_doucments': '已上傳的檔案',
    'create_time': '建立時間',
    'valid_from': '有效開始時間',
    'valid_to': '有效結束時間',
    'used_time': '使用時間',
    'corporate_type': '用戶類別',
    [`approval_id_${ APPROVAL_APPROVE_ID }`]: '通過',
    [`approval_id_${ APPROVAL_REVOKE_ID }`]: '撤銷',
    [`approval_license_${ APPROVAL_APPROVE_LIGHT_DUTY }`]: '通過 (輕型或汽車)',
    [`approval_license_${ APPROVAL_APPROVE_HEAVY_DUTY }`]: '通過 (重型)',
    [`approval_license_${ APPROVAL_REVOKE_DRIVER_LICENSE }`]: '撤銷',
    [`approval_${ APPROVAL_KEEP_CURRENT_VALUE }`]: '保持現值',
    [`verification_${ VERIFICATION_NOT_YET }`]: '尚未上傳',
    [`verification_${ VERIFICATION_SUCCESS }`]: '成功',
    [`verification_${ VERIFICATION_DENIED_FRAUD }`]: '拒絕',
    [`verification_${ VERIFICATION_DENIED_UNSUPPORTED_ID_TYPE }`]: '不支援證件種類',
    [`verification_${ VERIFICATION_DENIED_UNSUPPORTED_ID_COUNTRY }`]: '不支援發證國家',
    [`verification_${ VERIFICATION_ERRO_NOT_READABLE_ID }`]: '無法辨識',
    [`verification_${ VERIFICATION_VALIDITY_FALSE }`]: '臉部辨識錯誤',
    [`verification_${ VERIFICATION_VALIDITY_TRUE }`]: '臉部辨識與證件照不符',
    [`verification_${ VERIFICATION_RESULT_NOT_MATCH_OTHER_TYPE }`]: '證件號碼不相符',
    [`verification_${ VERIFICATION_INVALID_DOC }`]: '無效證件',
    [`verification_${ VERIFICATION_DUPLICATED_ID }`]: '證件號碼重複',
    'verification_failed': '失敗',
    [`license_${ LICENSE_TYPE_INVALID_LICENSE }`]: '無效駕照',
    [`license_${ LICENSE_TYPE_OTHER }`]: '輕型機車或汽車',
    [`license_${ LICENSE_TYPE_ORDINARY_HEAVY_DUTY_MOTORCYCLE }`]: '普通重型機車',
    [`license_${ LICENSE_TYPE_LARGE_HEAVY_DUTY_MOTORCYCLE }`]: '大型重型機車',
    [`coupon_state_${ COUPON_STATE_ACTIVE }`]: '有效',
    [`coupon_state_${ COUPON_STATE_PAST }`]: '無效',
    [`plan_${ PLAN_BASIC }`]: '基本方案',
    [`plan_${ PLAN_CORPORATE }`]: '企業方案',
    'corporate': '企業',
    [`corporate_type_${ CORPORATE_TYPE_NORMAL }`]: '個人',
    [`corporate_type_${ CORPORATE_TYPE_BASIC }`]: '企業用戶',
    [`corporate_type_${ CORPORATE_TYPE_VIP }`]: '企業專屬',
    'contract': '企業方案',
    'corporate_name': '公司名稱',
    'trip_times_remaining_quota': '剩餘使用次數',
    'general': '企業用戶',
    'vip': '企業專屬',
    'contract_period': '合約期間',
    'subsidy_amount': '優惠額度',
    'subsidy_all': '全額補助',
    'subsidy_price': '每趟優惠 %{count} 元',
    'subsidy_percent': '每趟優惠 %{count} ％',
    'subsidy_percent_max': '每趟優惠 %{count} ％，最高 %{max} 元',
    'itinerary': '行程額度',
    'itinerary_all': '無次數上限',
    'itinerary_day': '每日最多 %{count} 趟',
    'itinerary_month': '每月最多 %{count} 趟',
    'email_status': '電子信箱狀態',
    [`email_status_${ EMAIL_VERIFIED }`]: '已驗證',
    [`email_status_${ EMAIL_UNVERIFIED }`]: '未驗證',
    [`email_status_${ EMAIL_FORCE_VERIFY }`]: '強制驗證',
    [`error_${ ERROR_NOT_FOUND }`]: '會員不存在',
    [`error_${ ERROR_ID_DUPLICATED }`]: 'ID 已存在',
    [`error_${ ERROR_EMAIL_DUPLICATED }`]: '電子信箱 已存在',
    [`error_${ ERROR_BANNED_LIGHT_DUTY }`]: '不允許通過輕型機車或汽車',
    [`error_${ ERROR_FORMAT_ERROR }`]: '無效的電話號碼',
    [`error_${ ERROR_PHONE_DUPLICATED }`]: '電話號碼 已存在',
    'overall_status': '租賃狀態',
    [`overall_${ OVERALL_UNVERIFIED }`]: '無法租賃。(任一) 證件未上傳',
    [`overall_${ OVERALL_ALLOWED }`]: '正常租賃',
    [`overall_${ OVERALL_MANDATORY_VERIFY_EMAIL }`]: '無法租賃。需強制驗證電子信箱',
    [`overall_${ OVERALL_NOT_MATCH_ID }`]: '無法租賃。證件號碼不相符',
    [`overall_${ OVERALL_DUPLICATED_ID }`]: '無法租賃。證件號碼重複',
    [`overall_${ OVERALL_FAILED_VERIFICATION }`]: '無法租賃。證件未通過審核',
    [`overall_${ OVERALL_RENT_SUSPENDED }`]: '強制停租',
    [`overall_${ OVERALL_NO_BINDING_CREDITCARD }`]: '無法租賃。未綁定信用卡',
    [`overall_${ OVERALL_EXPIRED_PAYMENT_REQUEST }`]: '無法租賃。尚有逾期未繳衍生費用',
    [`overall_${ OVERALL_OUTSTANDING_RENTAL_PAYMENT }`]: '無法租賃。尚有未繳騎乘費用',
    'release_rental_suspended': '解除停租',
    'lockby_rental_suspended': '強制停租',
    'enable_status_item': '修改項目',
    'status_item_default': '請選擇修改項目',
    'customer_status': '會員帳號',
    'modify_customer_status': '修改狀態',
    'status': '狀態',
    'enable_customer_status': '是否啟用此帳號',
    'suspended': '停用中',
    'nationality': '國籍',
    'resident': '本地',
    'foreigner': '外國',
    'mission': '任務',
    'start_time': '開始時間',
    'finish_time': '結束時間',
    'progress_value': '進度',
    'state': '狀態',
    [`mission_state_${ IN_PROGRESS }`]: '進行中',
    [`mission_state_${ ABORTED }`]: '放棄',
    [`mission_state_${ ACHIEVED }`]: '已完成',
    [`mission_state_${ EXPIRED }`]: '未完成',
    [`remaing_quota_unit_${ TIMES_LIMIT_TYPE_PER_DAY }`]: '／本日',
    [`remaing_quota_unit_${ TIMES_LIMIT_TYPE_PER_MONTH }`]: '／本月',
    'unlimited': '無次數上限',
    'purchase_history': '公共運輸定期票優惠',
    'purchase_code': '購買代碼',
    'discount_total_trip': '折扣總旅程數',
    'refund_amount': '退款金額',
    'pay_time': '購買時間',
    [`purchase_code_${ EASY_CARD }`]: '悠遊卡',
    [`purchase_code_${ MENGO }`]: 'MeN Go',
    [`purchase_state_${ PURCHASE_CANCELED }`]: '取消',
    [`purchase_state_${ PURCHASE_ACTIVE }`]: '啟動',
    [`purchase_state_${ PURCHASE_EXPIRED }`]: '過期',
    [`purchase_state_${ PURCHASE_INCOMING }`]: '即將到來',
    [`refund_error_${ ERROR_CODE_REFUND_40001 }`]: '退款金額錯誤',
    [`refund_error_${ ERROR_CODE_REFUND_40901 }`]: '購買紀錄不存在',
    [`refund_error_${ ERROR_CODE_REFUND_40902 }`]: '購買紀錄已退款',
    [`refund_error_${ ERROR_CODE_REFUND_40903 }`]: '購買紀錄無法退款',
    [`refund_error_${ ERROR_CODE_REFUND_40904 }`]: '此款項尚未請款，無法部分退款',
    [`refund_error_${ ERROR_CODE_REFUND_0 }`]: '哦哦，好像出了什麼問題',
    'discount': '優惠內容',
    'discount_content': '每次行程折扣 $%{count} 元，優惠期間內不限次數',
    'access_over_rate_limit': '你似乎重試太多次了，請稍後再試',
    'unpaid_request': '衍生費用',
    'forbidden_license': '法規限制的證件',
    'pass_history': '日租方案',
    'pass_state': '狀態',
    [`pass_state_${GSH_PASS_STATE_ACTIVE}`]: '生效中',
    [`pass_state_${GSH_PASS_STATE_EXPIRED}`]: '已到期',
    'pass_refund_title': '日租方案退費',
    [`pass_refund_error_${ ERROR_CODE_PASS_REFUND_41001 }`]: '方案退款金額錯誤',
    [`pass_refund_error_${ ERROR_CODE_PASS_REFUND_41002 }`]: '購買方案不存在',
    [`pass_refund_error_${ ERROR_CODE_PASS_REFUND_41003 }`]: '購買方案已退款',
    [`pass_refund_error_${ ERROR_CODE_PASS_REFUND_41004 }`]: '退款處理中',
    [`pass_refund_error_${ ERROR_CODE_PASS_REFUND_41005 }`]: '該用戶有未結束的租借，請確保行程已結束後再進行退款',

};

export default customer;
