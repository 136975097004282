import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Redirect, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { ROOT } from 'constants/routes';
import { login, updateDocumentTitle, toggleErrorDialog } from 'actions';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as FleetTitle } from 'assets/img-fleet-title.svg';

import './login-page.scss';

const { REACT_APP_CLIENT_ID } = process.env;

const Login = ({ isLoggedIn, dispatch }) => {
    const history = useHistory();
    const { redirectTo = ROOT } = queryString.parse(history.location.search);

    const [client, setClient] = useState(undefined);

    const handleLoggedInFailure = useCallback(() => {
        dispatch(toggleErrorDialog(I18n.t('login_failed')));
    }, [dispatch]);

    const handleLogin = useCallback(res => {
        dispatch(login(res)).catch(() => {
            handleLoggedInFailure();
        });
    }, [dispatch, handleLoggedInFailure]);

    useEffect(() => {
        dispatch(updateDocumentTitle('login'));
        if (window.google) {
            const userClient = window.google.accounts.oauth2.initCodeClient({
                client_id: REACT_APP_CLIENT_ID,
                scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
                ux_mode: 'popup',
                callback: handleLogin
            });
            setClient(userClient);
        }
    }, [dispatch, handleLogin]);

    return (
        <div className="login-page">
            { isLoggedIn ? <Redirect to={ decodeURIComponent(redirectTo) } /> : null }
            <FleetTitle className="fleet-title" />
            { client && (
                <Button color="primary" className="btn-google-login" onClick={ () => client.requestCode() }>
                    <span>Login with Google</span>
                </Button>
            ) }
        </div>
    );
}

Login.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
}

export default connect(state => ({
    isLoggedIn: state.account.get('isLoggedIn'),
}))(Login);
