import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Badge, FormGroup, Label, Input } from 'reactstrap';
import { Translate } from 'react-redux-i18n';
import {
    STATUS_DISABLE,
    STATUS_EXPIRY,
    STATUS_LIVE,
    STATUS_PUBLISH,
    TYPE_TIME,
    TYPE_PRICE,
    PROMOTE_TYPE_PROMOTION_EVENT,
    PROMOTE_TYPE_MISSION,
} from 'constants/coupon';
import ControlTemplate from './ControlTemplate';
import FlexGroup from 'components/FlexGroup';

class SystemCouponDetails extends Component {
    static propTypes = {
        couponBody: PropTypes.shape({}),
        viewOnly: PropTypes.bool,
    };

    static defaultProps = {
        couponBody: {},
        viewOnly: false,
    };

    constructor(props) {
        super(props);
        this.priceRadioRef = React.createRef();
        const { couponBody } = props;
        this.state = {
            discountType: couponBody.discount_type,
            promoteType: couponBody.promote_type ?? PROMOTE_TYPE_PROMOTION_EVENT,
        };
    }

    handleChanged = name => e => {
        const value = Number(e.currentTarget.value);
        if (name === 'promoteType' && value === PROMOTE_TYPE_MISSION) {
            this.setState({
                discountType: TYPE_PRICE
            });

            this.priceRadioRef.current.click();
        }

        this.setState({
            [name]: value,
        });
    }

    renderPromoteType() {
        const { viewOnly } = this.props;
        const { promoteType } = this.state;

        if (viewOnly) {
            return (
                <ControlTemplate large captionKey="promote_type">
                    <p className="display-type">
                        <Translate className="type-badge" value={ `coupon.promote_type_${ promoteType }` } />
                    </p>
                </ControlTemplate>
            );
        }

        return (
            <ControlTemplate large={ viewOnly } captionKey="promote_type">
                <FormGroup check>
                    <Label check>
                        <Input
                            type="radio"
                            name="promote_type"
                            value={ PROMOTE_TYPE_PROMOTION_EVENT }
                            defaultChecked={ promoteType === PROMOTE_TYPE_PROMOTION_EVENT }
                            onChange={ this.handleChanged('promoteType') }
                        />
                        <Translate value={ `coupon.promote_type_${ PROMOTE_TYPE_PROMOTION_EVENT }` } />
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="radio"
                            name="promote_type"
                            value={ PROMOTE_TYPE_MISSION }
                            defaultChecked={ promoteType === PROMOTE_TYPE_MISSION }
                            onChange={ this.handleChanged('promoteType') }
                        />
                        <Translate value={ `coupon.promote_type_${ PROMOTE_TYPE_MISSION }` } />
                    </Label>
                </FormGroup>
            </ControlTemplate>
        );
    }

    renderDiscountType() {
        const { viewOnly } = this.props;
        const { discountType = TYPE_PRICE, promoteType, } = this.state;

        return (
            viewOnly ?
                (
                    <ControlTemplate large={ viewOnly } captionKey="type">
                        <p className="display-type">
                            <Translate className="type-badge" value={ `coupon.type_${ discountType }` } />
                        </p>
                    </ControlTemplate>
                ) :
                (
                    <ControlTemplate large={ viewOnly } captionKey="type">
                        { !(promoteType === PROMOTE_TYPE_MISSION && discountType === TYPE_PRICE) && (
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="discount_type"
                                        value={ TYPE_TIME }
                                        defaultChecked={ discountType === TYPE_TIME }
                                        onChange={ this.handleChanged('discountType') }
                                    />
                                    <Translate value={ `coupon.type_${ TYPE_TIME }` } />
                                </Label>
                            </FormGroup>
                        ) }
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="discount_type"
                                    innerRef={ this.priceRadioRef }
                                    value={ TYPE_PRICE }
                                    defaultChecked={ discountType === TYPE_PRICE }
                                    onChange={ this.handleChanged('discountType') }
                                />
                                <Translate value={ `coupon.type_${ TYPE_PRICE }` } />
                            </Label>
                        </FormGroup>
                    </ControlTemplate>
                )
        );
    }

    renderStatus() {
        const { couponBody } = this.props;
        const { status } = couponBody;
        let color = 'dark';

        switch (status) {
        case STATUS_PUBLISH:
        case STATUS_LIVE:
            color = 'success';
            break;
        case STATUS_DISABLE:
        case STATUS_EXPIRY:
            color = 'danger';
            break;
        default:
            // Do nothing
            break;
        }


        return (
            <FlexGroup start alignStart>
                <Translate value="coupon.details" className="caption-detail" tag="h2" />
                {
                    status !== undefined ?
                        (
                            <Badge color={ color }>
                                <Translate value={ `coupon.status_${ status }` } />
                            </Badge>
                        ) :
                        null
                }
            </FlexGroup>
        );
    }

    render() {
        const { couponBody, viewOnly } = this.props;
        const { discountType = TYPE_PRICE } = this.state;

        return (
            <>
                <Card>
                    <CardBody>
                        <Input type="hidden" name="status" value={ couponBody.status } />
                        { this.renderStatus() }
                        <ControlTemplate large captionKey="title">
                            <Input
                                type="text"
                                name="title"
                                autoComplete="off"
                                required
                                defaultValue={ couponBody.title }
                            />
                        </ControlTemplate>
                        <ControlTemplate large captionKey="description">
                            <Input
                                type="text"
                                name="description"
                                autoComplete="off"
                                required
                                defaultValue={ couponBody.description }
                            />
                        </ControlTemplate>
                        { this.renderPromoteType() }
                        { this.renderDiscountType() }
                        <ControlTemplate withUnit large={ viewOnly } captionKey="discount">
                            <Input
                                type="number"
                                min="1"
                                step="1"
                                name="discount_value"
                                required
                                defaultValue={ couponBody.discount_value || '' }
                            />
                            <Translate value={ `coupon.discount_${ discountType }` } />
                        </ControlTemplate>
                        <ControlTemplate large={ viewOnly } captionKey="number_per_person">
                            <Input
                                type="number"
                                name="redeem_limit"
                                id="redeem_limit"
                                min="1"
                                step="1"
                                required
                                defaultValue={ couponBody.redeem_limit || '' }
                            />
                        </ControlTemplate>
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default SystemCouponDetails;
