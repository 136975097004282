/**
 * Reducer - Log
 */
import { Map } from 'immutable';
import {
    FETCH_RENTAL_LOG,
    EXPORT_RENTAL_LOG,
    CLOSE_EXPORT,
} from 'constants/action-type';
import creater from './util/creater';

const initialState = Map({
    exportFinished: false,
    rental: Map({
        total: 0,
        size: 0,
        page_index: 0,
        page_count: 0,
        data_list: [],
    }),
});

const actionsMap = {
    [FETCH_RENTAL_LOG]: (state, action) => {
        return state.merge({
            rental: Map(action.data),
        });
    },
    [EXPORT_RENTAL_LOG]: state => {
        return state.merge({
            exportFinished: true,
        });
    },
    [CLOSE_EXPORT]: state => {
        return state.merge({
            exportFinished: false,
        });
    }
};

export default creater(actionsMap, initialState);
