import React from 'react';
import PropTypes from 'prop-types';
import Cell from './Cell';

class ListHeader extends React.Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    };

    render() {
        const { items } = this.props;
        const visibleHeader = items.filter((field) => !field.hide);

        return  (
            <React.Fragment>
                { visibleHeader.map(( { key, title, width }, i) => {
                    return (
                        <Cell
                            key={ key }
                            name={ key }
                            style={ { width } }
                        >
                            <span>{ title }</span>
                        </Cell>
                    );
                }) }
            </React.Fragment>
        );
    };
};

export default ListHeader;
