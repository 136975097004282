import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';


const ErrorMessage = ({
    value,
}) => {
    return (
        <p className="error">{ I18n.t(value) || value }</p>
    );
};
ErrorMessage.propTypes = {
    value: PropTypes.string,
};

ErrorMessage.defaultProps = {
    value: 'Something went wrong.',
};

export default ErrorMessage;
