import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
    TICKET_DETAIL,
} from 'constants/routes';
import buildActualPath from 'helpers/build-actual-path';

const Component = (({ id }) => (
    <NavLink className="ticket-no-link" to={ buildActualPath(TICKET_DETAIL, { ticketId: id }) }>
        { id }
    </NavLink>
));

Component.propTypes = {
    id: PropTypes.number.isRequired,
};

export default Component;
