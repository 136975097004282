/**
 * Reduder - Coupon
 */
import { Map, List } from 'immutable';
import {
    FETCH_COUPON_LIST,
    FETCH_SYSTEM_COUPON_LIST,
    FETCH_ONE_COUPON,
    CREATE_COUPON,
    UPDATE_COUPON,
    FETCH_ACCOUNT_TYPE,
    FETCH_BULK_COUPON_CODE,
    FETCH_ONE_SYSTEM_COUPON,
    UPDATE_SYSTEM_COUPON,
    CREATE_SYSTEM_COUPON,
} from 'constants/action-type';
import creater from './util/creater';

const initialState = Map({
    list: undefined,
    systemCouponList: undefined,
    oneCoupon: {},
    oneSystemCoupon: {},
    accountType: undefined,
    couponCodeList: List([]),
});

const ONE_COUPON = 'oneCoupon';
const ONE_SYSTEM_COUPON = 'oneSystemCoupon';

const setCoupon = type => (state, data) => {
    let coupon = {};

    if (data) {
        coupon = {
            ...state.get(type),
            [data.id]: Map(data)
        };
    }

    return state.merge({
        [type]: coupon
    });
};

const actionsMap = {
    [FETCH_COUPON_LIST]: (state, action) => {
        return state.merge({
            list: Map(action.data),
        });
    },

    [FETCH_SYSTEM_COUPON_LIST](state, action) {
        return state.merge({
            systemCouponList: Map(action.data),
        });
    },

    [FETCH_ONE_COUPON]: (state, action) => setCoupon(ONE_COUPON)(state, action.data),

    [CREATE_COUPON]: (state, action) => setCoupon(ONE_COUPON)(state, action.data),

    [CREATE_SYSTEM_COUPON]: (state, action) => setCoupon(ONE_SYSTEM_COUPON)(state, action.data),

    [UPDATE_COUPON]: (state, action) => setCoupon(ONE_COUPON)(state, action.data),

    [FETCH_ONE_SYSTEM_COUPON]: (state, action) => setCoupon(ONE_SYSTEM_COUPON)(state, action.data),

    [UPDATE_SYSTEM_COUPON]: (state, action) => setCoupon(ONE_SYSTEM_COUPON)(state, action.data),

    [FETCH_ACCOUNT_TYPE]: (state, action) => {
        return state.merge({
            accountType: List(action.data),
        });
    },

    [FETCH_BULK_COUPON_CODE]: (state, action) => {
        const { promo_code } = action.data;
        return state.merge({
            couponCodeList: List(promo_code),
        });
    },
};

export default creater(actionsMap, initialState);
