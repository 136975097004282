import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { I18n } from 'react-redux-i18n';

class CustomSelect extends Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.arrayOf(PropTypes.shape({
            // translation key
            name: PropTypes.string,
            value: PropTypes.node,
        })),
        selected: PropTypes.node,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        name: undefined,
        value: undefined,
        selected: undefined,
        onChange: () => {},
    };

    constructor(props) {
        super(props);

        const { innerRef, value, selected } = props;

        this.elInput = innerRef || React.createRef();

        this.state = {
            value,
            selected,
        };
    }

    componentDidUpdate(prevProps) {
        const oldValue = prevProps.value;
        const { value } = this.props;
        if (oldValue !== value) {
            this.setState({ value });
        }
    }

    handleChange = e => {
        e.persist();
        e.preventDefault();
        const currentTarget = e.currentTarget;
        const { onChange } = this.props;

        this.setState({
            selected: e.currentTarget.value,
        }, () => {
            e.currentTarget = currentTarget;
            onChange(e);
        });
    }

    renderOptions() {
        const { value } = this.state;

        return value.map(item => {
            return (
                <option key={ item.value } value={ item.value }>
                    { I18n.t(item.name) }
                </option>
            );
        });
    }

    render() {
        const { name } = this.props;
        const { selected } = this.state;

        return (
            <Input { ...this.props } name={ name } value={ selected } onChange={ this.handleChange }>
                { this.renderOptions() }
            </Input>
        );
    }
}

export default CustomSelect;
