import React from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import AuthView from 'components/AuthView';
import AuthFeature from 'components/AuthFeature';
import FlexGroup from 'components/FlexGroup';
import ChoiceGroupField from 'components/ChoiceGroup/ChoiceGroupField';
import { getDefaultPageSize } from 'components/Pagination/PageSizeSelect';
import { AUTH_EDIT_ASSET } from 'constants/permission';
import { fetchAssetList, updateDocumentTitle, } from 'actions';
import AssetList, {
    FILTER_PAGE_INDEX,
    FILTER_PAGE_SIZE,
    FILTER_SORT,
} from './AssetList';
import ImportAssetModal from './ImportAssetModal';
import './asset.scss';

const SEARCH = 'search';
const SEARCH_BY_VIN = 'vin';
const SEARCH_BY_PLATE = 'plate';

class AssetManagement extends React.Component {

    state = {
        searchType: SEARCH_BY_PLATE,
        searchText: '',
        queryPayload: {
            filter: {},
            [FILTER_PAGE_INDEX]: 1,
            [FILTER_PAGE_SIZE]: getDefaultPageSize().value,
            [FILTER_SORT]: {},
        },
        showImportModal: false,
    }

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { pathname } = location;

        dispatch(updateDocumentTitle(`routes.${ pathname }`));
    }

    componentDidUpdate(prevProps, prevState) {
        const { queryPayload: prevQueryPayload } = prevState;
        const { queryPayload } = this.state;

        if (prevQueryPayload !== queryPayload) {
            this.fetchAssetList();
        }
    }

    setSearchText = text => {
        this.setState({ searchText: text });
    }

    setSearchType = e => {
        this.setState({ searchType: e.target.value });
    }

    fetchAssetList = async () => {
        const { dispatch } = this.props;
        const { queryPayload } = this.state;
        const { filter, ...rest } = queryPayload;
        const payload = {
            ...filter,
            ...rest,
        };

        await dispatch(fetchAssetList(payload));
    }

    handleQueryPayloadChanged = name => value => {
        const { queryPayload, searchType, searchText } = this.state;
        let { filter } = queryPayload;
        queryPayload[FILTER_PAGE_INDEX] = 1;

        switch (name) {
        case SEARCH:
            filter = {
                [searchType]: searchText ? searchText : undefined
            };
            break;
        case FILTER_PAGE_INDEX:
            queryPayload[name] = value;
            break;
        case FILTER_PAGE_SIZE:
            queryPayload[name] = value.value;
            break;
        case FILTER_SORT:
            let { field } = value;
            const { order } = value;

            if (field && order) {
                queryPayload[FILTER_SORT] = `${ field },${ order }`;
            }
            else {
                queryPayload[FILTER_SORT] = undefined;
            }
            break;
        default:
            break;
        }

        queryPayload.filter = filter;
        this.setState({
            queryPayload: { ...queryPayload }
        });
    }

    togggleImportAssetModal = () => {
        this.setState(state => ({
            showImportModal: !state.showImportModal
        }));
    }

    render() {
        const { searchType, searchText, queryPayload, showImportModal } = this.state;
        const searchOptions = [
            { id: SEARCH_BY_VIN, name: I18n.t('asset.vin'), value: SEARCH_BY_VIN },
            { id: SEARCH_BY_PLATE, name: I18n.t('scooter.plate'), value: SEARCH_BY_PLATE },
        ];

        return (
            <AuthView className="assets">
                <FlexGroup spaceBetween>
                    <ChoiceGroupField
                        renderTitle={ () => <Translate className="search-for-caption" value="search_for" /> }
                        defaultChecked={ searchType }
                        value={ searchText }
                        options={ searchOptions }
                        onChoiceChange={ this.setSearchType }
                        onChange={ this.setSearchText }
                        onSubmit={ this.handleQueryPayloadChanged(SEARCH) }
                    />
                    <AuthFeature requiredList={ [AUTH_EDIT_ASSET] }>
                        <Button color="primary" onClick={ this.togggleImportAssetModal }>
                            <Translate value="import" />
                        </Button>
                    </AuthFeature>
                </FlexGroup>
                <AssetList
                    pageSize={ queryPayload.size }
                    onFetch={ this.fetchAssetList }
                    onChange={ this.handleQueryPayloadChanged }
                />
                { showImportModal && (
                    <ImportAssetModal onClose={ this.togggleImportAssetModal } onDidImport={ this.fetchAssetList } />
                ) }
            </AuthView>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(AssetManagement);
