/**
 * Reducer - Scooter
 */
import { Map, List, } from 'immutable';
import {
    IMPORT_VIOLATION_LIST,
    GET_VIOLATION_DETAIL,
    CLEAR_VIOLATION_LIST,
} from 'constants/action-type';
import creater from './util/creater';

const initialState = Map({
    list: List([]),
    noFoundList: List([]),
    violationDetail: {},
});

const actionsMap = {
    [IMPORT_VIOLATION_LIST]: (state, action) => {
        const { data_list, no_rental_found_list } = action.data;
        return state.merge({
            list: List(data_list),
            noFoundList: List(no_rental_found_list)
        });
    },

    [GET_VIOLATION_DETAIL]: (state, action) => {
        return state.merge({
            violationDetail: action.data,
        });
    },

    [CLEAR_VIOLATION_LIST]: state => state.merge({
        list: initialState.get('list'),
        noFoundList: initialState.get('noFoundList')
    }),
};

export default creater(actionsMap, initialState);
