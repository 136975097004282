import { MODEL_B, MODEL_B_DOT, MODEL_BTG, MODEL_F, MODEL_P, MODEL_J, MODEL_D } from 'constants/scooter';

export default function getModelType(code) {
    if (code === 101) {
        return MODEL_BTG;
    }

    if (code === 102) {
        return MODEL_B_DOT;
    }

    if (Math.floor(code / 100) === 5) {
        return MODEL_D;
    }

    if (Math.floor(code / 100) === 4) {
        return MODEL_J;
    }

    if (Math.floor(code / 100) === 3) {
        return MODEL_P;
    }

    if (Math.floor(code / 100) === 2) {
        return MODEL_F;
    }

    if (Math.floor(code / 100) === 1) {
        return MODEL_B;
    }

    return MODEL_B;
}
