import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { getBounds } from 'helpers/gmap-utils';
import GoogleMap, { VIP_SELECT } from 'components/Map';
import {
    CENTER_TO_CURRENT_LOCATION,
    VMS,
} from 'components/Map/Toolbox';
import addServiceZone from '../../components/Map/Layers/service-zone';
import './mission-map.scss';
import getAuthedToolBoxList from 'components/Map/util/get-authed-toolbox';

const defaultToolbox = [CENTER_TO_CURRENT_LOCATION, VMS];

class MissionMap extends Component {
    static propTypes = {
        list: PropTypes.arrayOf(PropTypes.shape({
            scooter_id: PropTypes.string,
            plate_no: PropTypes.string,
            location_lng: PropTypes.number,
            location_lat: PropTypes.number,
        })),
        zoom: PropTypes.number,
        toolbox: PropTypes.arrayOf(PropTypes.node),
        searchBox: PropTypes.bool,
        drawingManager: PropTypes.bool,
        currentLocation: PropTypes.bool,
        disableSelectStyle: PropTypes.bool,
        onScooterSelected: PropTypes.func,
        onInternalAPI: PropTypes.func,
        resetBounds: PropTypes.number,
        withoutScooterCluster: PropTypes.bool,
        myTask: PropTypes.bool,
        showVipLayer: PropTypes.bool,
        selectedVipLayerList: ImmutablePropTypes.list.isRequired,
    };

    static defaultProps = {
        list: [],
        zoom: undefined,
        toolbox: [],
        searchBox: false,
        drawingManager: false,
        currentLocation: false,
        disableSelectStyle: false,
        onScooterSelected: () => {},
        onInternalAPI: () => {},
        resetBounds: 0,
        withoutScooterCluster: false,
        myTask: false,
        showVipLayer: false,
    };

    constructor(props) {
        super(props);
        this.service_ids = [];
        this.vipLayer = VIP_SELECT;
    }

    componentDidUpdate(prevProps) {
        const { resetBounds, selectedVipLayerList } = this.props;
        const prevResetBounds = prevProps.resetBounds;
        const uniqueScooterList = this.getUniqueScooterList();
        // add service zone
        const prevVipList = prevProps.selectedVipLayerList;
        const vipList = selectedVipLayerList.toJS();
        if (prevVipList !== selectedVipLayerList) {
            if (vipList.length !== 0) {
                const serviceIdArray = [];
                vipList.forEach(({ service_ids }) => {
                    if (!serviceIdArray.includes(...service_ids)) {
                        serviceIdArray.push(...service_ids);
                    }
                    this.service_ids = [...serviceIdArray];
                });
            }
            else {
                this.service_ids = [];
            }
            // init vipList will default as vipList.length !== 0 and service_ids.length === 0,
            // this criteria should not execute
            if (vipList.length === 0 || (vipList.length !== 0 && vipList[0].service_ids.length !== 0)) {
                addServiceZone({
                    map: this.map,
                    params: { service_id: this.service_ids },
                    selected: true,
                    vipLayer: this.vipLayer
                });
            }
        };
        // mapFitBounds couldn't good work when list length === 1
        if (prevResetBounds !== resetBounds) {
            if (uniqueScooterList.length > 1) {
                this.mapFitBounds();
            }
            else if (uniqueScooterList.length === 1) {
                this.map.setZoom(15);
            }
        }
    }

    mapFitBounds = () => {
        const { list } = this.props;

        if (this.map) {
            // map fit bounds
            let locations = list.map(({ location_lng, location_lat }) => ({
                lng: location_lng,
                lat: location_lat,
            }));

            if (locations.length > 0) {
                const bounds = getBounds(locations, this.maps);
                this.map.fitBounds(bounds);
            }
        }
    }

    handleGoogleApi = ({ map, maps }) => {
        this.map = map;
        this.maps = maps;

        const { onInternalAPI } = this.props;
        const uniqueScooterList = this.getUniqueScooterList();
        // mapFitBounds couldn't good work when list length === 1
        if (uniqueScooterList.length > 1) {
            this.mapFitBounds();
        }
        onInternalAPI({ map, maps });
    }

    getUniqueScooterList = () => {
        const { list } = this.props;
        return list.reduce((arr, ticket) => {
            const { scooter_id } = ticket;

            if (!arr.some(item => item.scooter_id === scooter_id)) {
                arr.push(ticket);
            }

            return arr;
        }, []).map(({ scooter_id, location_lng, location_lat, scooter_model_code, ...rest }) => ({
            id: scooter_id,
            model_code: scooter_model_code,
            lat: location_lat,
            lng: location_lng,
            ...rest,
        }));
    }

    render() {
        const {
            searchBox,
            drawingManager,
            currentLocation,
            toolbox,
            disableSelectStyle,
            myTask,
            showVipLayer,
        } = this.props;

        const { onScooterSelected, zoom, withoutScooterCluster } = this.props;
        const toolboxList = getAuthedToolBoxList([...defaultToolbox, ...toolbox]);
        const uniqueScooterList = this.getUniqueScooterList();
        const center = uniqueScooterList.length === 1 ? {
            lat: uniqueScooterList[0].lat,
            lng: uniqueScooterList[0].lng,
        } : undefined;

        return (
            <GoogleMap
                className="ticket-mission-map"
                onInternalAPI={ this.handleGoogleApi }
                zoom={ uniqueScooterList.length === 1 ? zoom : undefined }
                center={ center }
                toolbox={ toolboxList }
                searchBox={ searchBox }
                drawingManager={ drawingManager }
                currentLocation={ currentLocation }
                onScooterSelected={ onScooterSelected }
                list={ uniqueScooterList }
                disableSelectStyle={ disableSelectStyle }
                withoutScooterCluster={ withoutScooterCluster }
                vipLayer={ showVipLayer ? this.vipLayer : '' }
                myTask={ myTask }
            />
        );
    }
}

export default connect(state => ({
    selectedVipLayerList: state.map.get('selectedVipLayerList'),
}))(MissionMap);
