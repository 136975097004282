import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    AUTH_ENABLE_CUSTOMER,
    AUTH_RENT_SUSPEND,
} from 'constants/permission';
import permissionHandler from 'helpers/permission-handler';
import FlexGroup from 'components/FlexGroup';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import Input from 'components/Form/Input';
import {
    TYPE_RADIO,
    TYPE_SELECT,
} from 'components/Form/Input/input-type';
import {
    updateCustomerProfile,
} from 'actions';
import Modal from 'components/Modal';
import { Translate, I18n } from 'react-redux-i18n';

const ITEM_OPTIONS = [{
    name: 'customer.status_item_default',
    value: '',
    auth: [],
    opts: [],
}, {
    name: 'customer.customer_status',
    value: 'enabled',
    auth: [ AUTH_ENABLE_CUSTOMER ],
    opts: [{
        name: 'enable',
        value: true,
    }, {
        name: 'disable',
        value: false,
    }]
},{
    name: 'customer.overall_status',
    value: 'rent_suspend',
    auth: [ AUTH_RENT_SUSPEND ],
    opts: [{
        name: `customer.release_rental_suspended`,
        value: 0,
    }, {
        name: `customer.lockby_rental_suspended`,
        value: 1,
    }],
}];

class StatusModal extends React.Component {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        profile: PropTypes.shape({}).isRequired,
        onClose: PropTypes.func,
    };
    static defaultProps = {
        onClose: () => {},
    };
    constructor(props) {
        super(props);
        this.state = {
            statusItem: '',
            status: undefined,
            statusOpts: [],

        };
    }

    closeModel = () => {
        const { onClose } = this.props;

        this.setState({
            statusItem: '',
            status: undefined,
            statusOpts: [],

        }, () => {
            onClose();
        });
    };

    handleSave = () => {
        const { dispatch, profile } = this.props;
        const { id } = profile;
        const { statusItem, status } = this.state;
        let promise = Promise.resolve({});

        if (status !== profile[statusItem]) {
            promise = dispatch(updateCustomerProfile(id, { [statusItem]: status }));
        }

        return promise.then(res => {
            this.closeModel();

            return res;
        });
    };
    handleItemChange = (e) => {
        const { value } = e.currentTarget;
        const { opts } = ITEM_OPTIONS.filter(item => item.value === value)[0];

        this.setState({
            statusItem: value,
            status: undefined,
            statusOpts: opts,
        });
    };

    handleStatusChange = ([status]) => {
        this.setState({
            status,
        });
    };

    checkAuthedItemOptions = () => {
        const authedOptions = ITEM_OPTIONS.filter(({ auth }) => {
            return permissionHandler({ requiredList: auth })
        });

        return authedOptions;
    };

    render() {
        const { show } = this.props;
        const { statusItem, status, statusOpts } = this.state;
        const authedItemOptions = this.checkAuthedItemOptions();
        return (
            <>{
                show ? (
                    <Modal
                        title={ I18n.t('customer.modify_customer_status') }
                        onClose={ this.closeModel }
                    >
                        <Form tag="div" inline>
                            <Input
                                name="status"
                                type={ TYPE_SELECT }
                                caption="customer.enable_status_item"
                                selected={ statusItem }
                                value={ authedItemOptions }
                                onChange={ this.handleItemChange }
                                required
                            />
                            <FlexGroup className="customer-status-control">
                                {
                                    statusOpts.length ? (
                                        <Input
                                            name="enabled"
                                            type={ TYPE_RADIO }
                                            caption="customer.status"
                                            selected={ [status] }
                                            value={ statusOpts }
                                            onChange={ this.handleStatusChange }
                                            required
                                        />
                                    ) : null
                                }
                            </FlexGroup>
                            <FlexGroup start>
                                <Button color="primary" onClick={ this.handleSave }>
                                    <Translate value="save" />
                                </Button>
                            </FlexGroup>
                        </Form>
                    </Modal>
                ) : null
            }</>
        );
    }
}

export default connect((state, ownProps) => ({
    i18n: state.i18n,
}))(StatusModal);
