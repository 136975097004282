/**
 * Type Array
 */
const typeArray = (val, checker, typeConverter) => {
    const realVal = Array.isArray(val) ? val : [val];
    const converter = n => checker(n) ? typeConverter(n) : undefined;
    let retValue = realVal.map(n => {
        if (Array.isArray(n)) {
            return typeArray(n, checker, typeConverter);
        }
        else {
            return converter(n);
        }
    });

    return retValue;
};

export default typeArray;
