import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

import './card.scss';

class Card extends React.Component {
    static propTypes = {
        title: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ]),
        className: PropTypes.string,
    };
    static defaultProps = {
        title: '',
        className: '',
    }
    render() {
        const { title, className, children } = this.props;
        return (
            <div className={ `card ${className}` }>
                <h2>
                    { title }
                </h2>
                { children }
            </div>
        );
    }
}
export default Card;

const Section = ({ children, ...props }) => <section className="profile-line" { ...props }>{ children }</section>;
const Caption = ({ children }) => (
    <h5 className="profile-caption">
        { children }
        <span>:</span>
    </h5>
);

export const CardItem = ({ name, children, ...props }) => (
    <Section { ...props }>
        <Caption>
            <Translate value={ name } />
        </Caption>
        <div className="profile-item">
            { children }
        </div>
    </Section>
);
CardItem.propTypes = {
    name: PropTypes.string,
};
CardItem.defaultProps = {
    name: '',
};
