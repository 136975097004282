import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'components/Datetime';
import Cell from 'components/ListView/Cell';
import { Translate } from 'react-redux-i18n';
import { NavLink } from 'react-router-dom';
import { EDIT_AD } from 'constants/routes';
import buildActualPath from 'helpers/build-actual-path';

class ListRow extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            status: PropTypes.number.isRequired,
            valid_from: PropTypes.string,
            valid_to: PropTypes.string,
            publish_time: PropTypes.string,
        }).isRequired,
    };

    renderField({ data, name, width }) {
        let el;
        const { id, title, status } = data;

        switch (name) {
        case 'title':
            el = (
                <NavLink to={ buildActualPath(EDIT_AD, { adId: id }) }>{ title }</NavLink>
            );
            break;
        case 'status':
            el = <Translate value={ `ad.status_${ status }` } />;
            break;
        case 'valid_from':
        case 'valid_to':
        case 'publish_time':
            el = <Datetime time={ data[name] } />;
            break;
        default:
            el = data[name];
            break;
        }

        return (
            <Cell
                key={ `${ data.id }-${ name }` }
                name={ name }
                style={ {
                    width,
                } }
            >
                { el }
            </Cell>
        );
    }

    render() {
        const { columns, data } = this.props;

        return columns.map(({ key, width }, i) => {
            return this.renderField({ data, width, name: key, });
        });
    }
}

export default ListRow;
