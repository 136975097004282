import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import AuthView from 'components/AuthView';
import NavigationBar from 'components/NavigationBar';
import FlexGroup from 'components/FlexGroup';
import Smart from 'components/Smart';
import {
    updateDocumentTitle,
    fetchOrderableMissionList,
    orderMissionList,
    updateMissionOrder,
    toggleErrorDialog,
} from 'actions';
import MissionOrderList from './MissionOrderList';
import ConfirmModal from './ConfirmModal';
import './mission-order.scss';

class MissionOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            wasMoved: false,
        };

    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(updateDocumentTitle('mission.order_document_title'));
    }

    fetchOrderableMissionList = () => {
        const { dispatch } = this.props;
        this.setState({
            wasMoved: false,
        });
        return dispatch(fetchOrderableMissionList());
    };

    handleMoveMission = (currentIndex, nextIndex) => {
        const { dispatch } = this.props;
        const { wasMoved } = this.state;

        if(!wasMoved){
            this.setState({ wasMoved: true });
        }
        return dispatch(orderMissionList(currentIndex, nextIndex));
    };

    toggleConfirmModal = () => {
        this.setState(({ openModal }) => ({
            openModal: !openModal,
        }));
    };

    save = async () => {
        const { dispatch } = this.props;
        try {
            const { error } = await dispatch(updateMissionOrder());
            this.setState({ wasMoved: false });
            this.toggleConfirmModal();

            if (error) {
                throw error;
            }

        }
        catch (error) {
            const { status } = error.response;
            if (status !== 401) {
                dispatch(toggleErrorDialog(
                    I18n.t(`mission.mission_order_error_${ status }`)));
                dispatch(fetchOrderableMissionList());
            }
        }
    };

    render() {
        const { openModal, wasMoved } = this.state;
        return (
            <AuthView className="mission-order">
                <NavigationBar sticky title={ I18n.t('mission.change_order')}>
                    <FlexGroup end gap>
                        <Button type="button" outline onClick={ this.fetchOrderableMissionList } disabled={ !wasMoved } >
                            <Translate value="restore" />
                        </Button>
                        <Button color="primary" onClick={ this.toggleConfirmModal } disabled={ !wasMoved }>
                            <Translate value="save" />
                        </Button>
                    </FlexGroup>
                </NavigationBar>
                <Translate tag="div" className="brief-intro" value="mission.brief_intro" />
                <Smart
                    className="main-content-container"
                    fetch={ this.fetchOrderableMissionList }
                    pauseRefresh seamless
                >
                    <MissionOrderList onMoveMission={ this.handleMoveMission } />
                </Smart>
                {
                    openModal && <ConfirmModal
                        onSave={ this.save }
                        onClose={ this.toggleConfirmModal }
                    />
                }
            </AuthView>
        );
    }
};

export default connect(state => ({
    i18n: state.i18n,
}))(MissionOrder);
