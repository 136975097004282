import { createFleetApiInstanceWithToken } from '../util';

const admin = {
    fetchFleetUsers(params = {}) {
        return createFleetApiInstanceWithToken().get('/admin/fleet_users', { params });
    },
    fetchUserRoles(params = {}) {
        return createFleetApiInstanceWithToken().get('/admin/fleet_user_roles', { params });
    },
    updateFleetAdminUser(id, params = {}) {
        return createFleetApiInstanceWithToken().patch(`/admin/fleet_users/${ id }`, params);
    },
    updateFleetManyAdminUsers(params = {}) {
        return createFleetApiInstanceWithToken().patch('/admin/fleet_users', params);
    },
    newFleetUser(params = {}) {
        return createFleetApiInstanceWithToken().post('/admin/fleet_users', params);
    },
};

export default admin;
