import { useState, useCallback } from 'react';
import { store as rtkStore } from "redux/store";
import { scooterApi } from "redux/services/scooterApi";

export const useLazyGetFirmwareVersionQuery = (options) => {
    const { getFirmwareVersion } = scooterApi.endpoints;
    const [result, setResult] = useState();
    const fetch = useCallback(async (params) => {
        const response =  await rtkStore.dispatch(getFirmwareVersion.initiate({ params }, options));
        setResult(response);
        return response;
    }, [options, getFirmwareVersion]);


    return [
        fetch,
        result,
    ];
};
