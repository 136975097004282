import {
    HEADER_FORMAT_INVALID,
    CONTENT_CANNOT_EMPTY,
    FILE_INVALID,
    DATA_DUPLICATE,
    SCOOTER_MODEL_ID_NOT_FOUND,
    SCOOTER_NOT_FOUND,
    SCOOTER_NOT_DISABLED,
    PLATE_NOT_EQUALS_VIN,
} from 'constants/asset';

const asset = {
    vin: 'VIN',
    plate: '車牌',
    subscribe_id: 'Subscribe ID',
    gtu_imei: 'GTU IMEI',
    sim_phone_number: 'ICCID',
    ssl_certificate_issuer: 'SSL certificate issuer',
    ssl_certificate_subject: 'SSL certificate subject',
    ssl_certificate_valid_to: 'SSL certificate valid to',
    create_time: '建立時間',
    file_type: '選項',
    upload_scooter_data: '新增資產',
    update_scooter_plate: '更新車牌',
    browse_file: '瀏覽檔案',
    scooter_model_name: '型號',
    [`error_message_${ HEADER_FORMAT_INVALID }`]: '無效的標頭格式',
    [`error_message_${ CONTENT_CANNOT_EMPTY }`]: '檔案內容不能為空',
    [`error_message_${ FILE_INVALID }`]: '無效的檔案',
    [`error_message_${ DATA_DUPLICATE }`]: 'VIN %{vin}: 無效的資料，',
    [`error_message_${ SCOOTER_MODEL_ID_NOT_FOUND }`]: 'VIN %{vin}: 車輛型號不存在',
    [`error_message_${ SCOOTER_NOT_FOUND }`]: 'VIN %{vin}: 車輛不存在',
    [`error_message_${ SCOOTER_NOT_DISABLED }`]: 'VIN %{vin}: 請先將車輛下線，再進行更新',
    [`error_message_${ PLATE_NOT_EQUALS_VIN }`]: 'VIN %{vin}: 車牌無法更新',
};

export default asset;
