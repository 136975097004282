import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './info-card.scss';

const InfoCard = ({ children, title, className }) => {
    const style = classNames({
        'info-card': true,
        [className]: true,
    });
    return (
        <div className={ style }>
            { title ? (
                <h2 className="title">{ title }</h2>
            ) : null }
            { children }
        </div>
    );
};

InfoCard.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
};

InfoCard.defaultProps = {
    title: '',
    className: '',
};

export default InfoCard;
