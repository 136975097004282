import {
    PUT_FACTOR_AUTH,
    CLEAR_FACTOR_AUTH,
    CLEAR_AND_PUT_AUTH,
} from 'constants/action-type';


export const putFactorAuth = (factor) => dispatch => dispatch({
    type: PUT_FACTOR_AUTH,
    data: factor,
});

export const clearFactorAuth = () => dispatch => dispatch({
    type: CLEAR_FACTOR_AUTH,
});

export const clearAndPutFactorAuth = (factor) => dispatch => dispatch({
    type: CLEAR_AND_PUT_AUTH,
    data: factor,
});
