/**
 * Reducer - Scooter
 */
import { Map, List, } from 'immutable';
import {
    FETCH_MY_TASK,
    CLEAR_MY_TASK,
    FETCH_SCOOTER_LIST,
    CLEAR_SCOOTER_LIST,
    FETCH_SCOOTER_HISTORY,
    CLEAR_SCOOTER_HISTORY,
    FETCH_SINGLE_SCOOTER,
    CLEAR_SINGLE_SCOOTER,
    REMOTE_CONTROL,
    BATCH_UPDATE_SERVICE_STATE,
    OPEN_SCOOTER_DETAIL_POPUP,
    FORCE_RETURN,
    GTU_REPLACEMENT,
    FETCH_SINGLE_SCOOTER_GTU,
    CLEAR_SINGLE_SCOOTER_GTU,
    FETCH_SEARCHED_GTU_LIST,
    CLEAR_SEARCHED_GTU_INFO,
    FETCH_NEARBY_SCOOTERS,
    CLEAR_NEARBY_SCOOTERS,
    FETCH_ALL_SCOOTERS,
    CLEAR_ALL_SCOOTERS,
    FETCH_SCOOTER_RELATED_TICKETS,
    CLEAR_SCOOTER_RELATED_TICKETS,
    FETCH_SCOOTER_MODELS,
    FIRMWARE_VERSION,
    CLEAR_FIRMWARE_VERSION,
} from 'constants/action-type';
import creater from './util/creater';

const initialState = Map({
    tasks: Map({
        data_list: List([]),
    }),
    list: Map({
        data_list: List([]),
    }),
    allList: Map({
        list: List([]),
    }),
    nearbyList: List([]),
    userTasks: List([]),
    history: Map({
        data: [],
        cursor_after: undefined,
    }),
    singleScooter: {},
    scooter_GTU_data: {},
    searched_GTU_list: Map([]),
    openScooterPopup: false,
    relatedTickets: {},
    scooterModels: List([]),
    firmware_version: {},
});
const setSingleScooter = (state, data) => {
    let singleScooter;

    if (data) {
        singleScooter = {
            ...state.get('singleScooter'),
            [data.scooter_id]: Map(data)
        };
    }
    else {
        singleScooter = {};
    }
    return state.merge({
        singleScooter: singleScooter
    });
};

const renewList = (state, updateList) => {
    const list = state.getIn(['list', 'data_list']).toJS();
    let newList = list;

    updateList.forEach(updateItem => {
        const index = list.findIndex(({ scooter_id }) => scooter_id === updateItem.scooter_id);
        if (index > -1) {
            updateItem.soc = newList[index].soc;
            newList.splice(index, 1, updateItem);
        }
    });
    return newList;
};

const actionsMap = {
    [FETCH_MY_TASK]: (state, action) => {
        const { page_index, page_count, total, data_list, __responseTime } = action.data;

        return state.merge({
            tasks: Map({
                page_index,
                page_count,
                total,
                __responseTime,
                data_list: List(data_list),
            }),
        });
    },

    [CLEAR_MY_TASK]: state => state.merge({
        tasks: initialState.get('tasks'),
    }),

    [FETCH_SCOOTER_LIST]: (state, action) => {
        const { page_index, page_count, total, data_list, __responseTime } = action.data;

        return state.merge({
            list: Map({
                page_index,
                page_count,
                total,
                __responseTime,
                data_list: List(data_list),
            }),
        });
    },

    [CLEAR_SCOOTER_LIST]: state => state.merge({
        list: initialState.get('list'),
    }),

    [FETCH_SCOOTER_HISTORY]: (state, action) => {
        const history = state.get('history').toJS();
        const prevData = history.data || [];
        const { data = [], cursor_after } = action.data;

        return state.merge({
            history: Map({
                data: [...prevData, ...data],
                cursor_after,
            }),
        });
    },

    [FETCH_SINGLE_SCOOTER]: (state, action) => setSingleScooter(state, action.data),

    [CLEAR_SCOOTER_HISTORY]: state => state.merge({
        history: initialState.get('history'),
    }),

    [CLEAR_SINGLE_SCOOTER]: state => setSingleScooter(state),

    [REMOTE_CONTROL]: (state, action) => {
        const { data } = action;
        const { scooter_id } = data;
        const singleScooter = state.get('singleScooter');
        const list = state.get('list');
        const updatedDataList = renewList(state, [data]);

        return state.merge({
            list: list.merge({
                data_list: List(updatedDataList),
            }),
            singleScooter: {
                ...singleScooter,
                [scooter_id]: Map(data)
            }
        });
    },
    [BATCH_UPDATE_SERVICE_STATE]: (state, action) => {
        const { data } = action;
        const { scooter_list } = data;
        const list = state.get('list');
        const updatedDataList = renewList(state, scooter_list);
        let singleScooter = {};

        if (scooter_list.length === 1) {
            singleScooter = {
                ...state.get('singleScooter'),
                [scooter_list[0].scooter_id]: Map(scooter_list[0])
            };
        }
        return state.merge({
            list: list.merge({
                data_list: List(updatedDataList),
            }),
            singleScooter,
        });
    },
    [OPEN_SCOOTER_DETAIL_POPUP]: (state, action) => {
        return state.merge({
            openScooterPopup: action.data,
        });
    },

    [FORCE_RETURN]: (state, action) => setSingleScooter(state, action.data),

    [GTU_REPLACEMENT]: (state, action) => {
        const { scooter_id } = action.data;
        return state.merge({
            scooter_GTU_data: {
                [scooter_id]: Map(action.data),
            }
        });
    },

    [FETCH_SINGLE_SCOOTER_GTU]: (state, action) => {
        const { data_list } = action.data;
        const { scooter_id } = data_list[0];
        return state.merge({
            scooter_GTU_data: {
                [scooter_id]: data_list[0],
            }
        });
    },

    [CLEAR_SINGLE_SCOOTER_GTU]: state => state.merge({
        scooter_GTU_data: {},
    }),

    [FETCH_SEARCHED_GTU_LIST]: (state, action) => {
        const { data_list } = action.data;
        return state.merge({
            searched_GTU_list: Map(data_list[0] || {})
        });
    },

    [CLEAR_SEARCHED_GTU_INFO]: state => {
        return state.merge({
            searched_GTU_list: Map({})
        });
    },

    [FETCH_NEARBY_SCOOTERS]: (state, action) => {
        const { data_list } = action.data;

        return state.merge({
            nearbyList: List(data_list.map(item => {
                item.__isFloating = true;
                return item;
            })),
        });
    },

    [CLEAR_NEARBY_SCOOTERS]: state => state.merge({
        nearbyList: List([]),
    }),

    [FETCH_ALL_SCOOTERS]: (state, action) => {
        return state.merge({
            allList: Map({
                list: List(action.data),
                __responseTime: action.data.__responseTime,
            }),
        });
    },

    [CLEAR_ALL_SCOOTERS]: state => {
        const __responseTime = state.getIn(['allList', '__responseTime']);

        return state.merge({
            allList: Map({
                list: List([]),
                __responseTime,
            }),
        });
    },

    [FETCH_SCOOTER_RELATED_TICKETS]: (state, action) => {
        const { data_list } = action.data;

        let newRelatedTickets;

        if (data_list.length) {
            const scooterId = data_list[0].scooter_id;
            let tickets = data_list.map(({ id }) => id);
            newRelatedTickets = {
                ...state.get('relatedTickets'),
                [scooterId]: tickets,
            };
        }
        else {
            newRelatedTickets = {};
        }

        return state.merge({
            relatedTickets: newRelatedTickets,
        });
    },

    [CLEAR_SCOOTER_RELATED_TICKETS]: (state, action) => {
        let newRelatedTickets;
        newRelatedTickets = {
            ...newRelatedTickets,
            [action.scooterId]: [],
        };
        return state.merge({
            relatedTickets: newRelatedTickets,
        });
    },
    [FETCH_SCOOTER_MODELS]: (state, action) => {
        return state.merge({
            scooterModels: List(action.data.map(modelObj => modelObj.scooter_model))
        });
    },
    [FIRMWARE_VERSION]: (state, action) => {
        return state.merge({
            firmware_version: action.data
        });
    },
    [CLEAR_FIRMWARE_VERSION]: state => state.merge({
        firmware_version: {},
    }),
};

export default creater(actionsMap, initialState);
