import React from 'react';
import { connect } from 'react-redux';
import AuthView from 'components/AuthView';
import { getDefaultPageSize } from 'components/Pagination/PageSizeSelect';
import SearchField from 'components/SearchField';
import MultiselectFilter from 'components/Filter/MultiselectFilter';
import DatetimeRangeFilter from 'components/Filter/DatetimeRangeFilter';
import FlexGroup from 'components/FlexGroup';
import List from './List';
import { Translate, I18n } from 'react-redux-i18n';
import { convertToUTC } from 'helpers/time-handler';
import {
    STATUS_DISABLE,
    STATUS_DRAFT,
    STATUS_PUBLISH,
    STATUS_LIVE,
    STATUS_EXPIRY,
} from 'constants/ad';
import {
    updateDocumentTitle,
    fetchAdList,
    clearAdList,
} from 'actions';

import './ad.scss';

const FILTER_TITLE = 'title';
const FILTER_STATUS = 'status';
const FILTER_VALID_PERIOD = 'valid_period';
const FILTER_PUBLISH_TIME = 'publish_time';

class Ad extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            appliedValidDateRange: false,
            appliedPublishDateRange: false,
            queryPayload: {
                filter: {
                    [FILTER_TITLE]: '',
                },
                sort: {},
                pageSize: getDefaultPageSize().value,
                pageIndex: 1,
            },
        };
    }

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { pathname } = location;
        dispatch(updateDocumentTitle(`routes.${ pathname }`));
        this.fetchData();
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearAdList());
    }

    fetchData = () => {
        const { dispatch } = this.props;
        const { queryPayload } = this.state;
        const { filter, sort, pageSize, pageIndex } = queryPayload;
        const { publish_time_from, publish_time_to, valid_from, valid_to } = filter;

        return dispatch(fetchAdList({
            ...filter,
            publish_time_from: publish_time_from && convertToUTC(publish_time_from),
            publish_time_to: publish_time_to && convertToUTC(publish_time_to),
            valid_from: valid_from && convertToUTC(valid_from),
            valid_to: valid_to && convertToUTC(valid_to),
            sort,
            page: pageIndex,
            size: pageSize,
        }));
    }

    handleListSort = ({ field, order }) => {
        const { queryPayload } = this.state;

        queryPayload.pageIndex = 1;
        if (field && order) {
            queryPayload.sort = `${field},${order}`;
        }
        else {
            queryPayload.sort = undefined;
        }
        this.fetchData();
    }

    handleFilterChange = type => value => {
        const { queryPayload } = this.state;
        queryPayload.pageIndex = 1;
        let { filter } = queryPayload;
        const updatedState = {};

        switch (type) {
        case FILTER_TITLE:
            filter = {
                [FILTER_TITLE]: value === '' ? undefined : value,
            };
            updatedState.appliedValidDateRange = false;
            updatedState.appliedPublishDateRange = false;
            break;

        case FILTER_STATUS:
            filter[FILTER_STATUS] = value.inputSelect;
            break;

        case FILTER_VALID_PERIOD:
            filter.valid_from = value.from;
            filter.valid_to = value.to;
            updatedState.appliedValidDateRange = !!(value.from && value.to);
            break;

        case FILTER_PUBLISH_TIME:
            filter.publish_time_from = value.from;
            filter.publish_time_to = value.to;
            updatedState.appliedPublishDateRange = !!(value.from && value.to);
            break;

        default:
        }

        this.setState({
            queryPayload: {
                ...queryPayload,
                filter,
            }
        });

        if (Object.keys(updatedState).length > 0) {
            this.setState(updatedState, () => {
                this.fetchData();
            });
        }
        else {
            this.fetchData();
        }
    };

    handlePageSelect = value => {
        const { queryPayload } = this.state;
        queryPayload.pageIndex = value;
        this.fetchData();
    }

    handlePageSizeChange = pageSizeOption => {
        const { queryPayload } = this.state;

        queryPayload.pageIndex = 1;
        queryPayload.pageSize = pageSizeOption.value;
        this.fetchData();
    }

    render() {
        const { queryPayload } = this.state;
        const { filter } = queryPayload;
        const { appliedValidDateRange, appliedPublishDateRange } = this.state;
        const renderFilters = () => {
            const statusOptions = [
                STATUS_DISABLE,
                STATUS_DRAFT,
                STATUS_PUBLISH,
                STATUS_LIVE,
                STATUS_EXPIRY,
            ].map(opt => ({
                value: opt,
                text: I18n.t(`ad.status_${opt}`),
            }));

            return (
                <FlexGroup start className="filters">
                    <DatetimeRangeFilter
                        title={ I18n.t('ad.valid_period') }
                        defaultFrom={ filter.valid_from }
                        defaultTo={ filter.valid_to }
                        onApply={ this.handleFilterChange(FILTER_VALID_PERIOD) }
                        applied={ appliedValidDateRange }
                    />
                    <DatetimeRangeFilter
                        title={ I18n.t('ad.publish_period') }
                        defaultFrom={ filter.publish_time_from }
                        defaultTo={ filter.publish_time_to }
                        onApply={ this.handleFilterChange(FILTER_PUBLISH_TIME) }
                        applied={ appliedPublishDateRange }
                    />
                    <MultiselectFilter
                        title={ I18n.t('ad.status') }
                        options={ statusOptions }
                        defaultSelected={ filter[FILTER_STATUS] }
                        onChange={ this.handleFilterChange(FILTER_STATUS) }
                    />
                </FlexGroup>
            );
        };

        return (
            <AuthView className="ad ad-list">
                <FlexGroup start className="search-section">
                    <Translate className="search-for-caption" value="search_for" />
                    <SearchField
                        placeholder={ I18n.t('ad.search_box_placeholder') }
                        onSubmit={ this.handleFilterChange(FILTER_TITLE) }
                        value={ filter[FILTER_TITLE] }
                    />
                    { renderFilters() }
                </FlexGroup>
                <List
                    onSort={ this.handleListSort }
                    onSelect={ this.handlePageSelect }
                    onChange={ this.handlePageSizeChange }
                    pageSize={ queryPayload.pageSize }
                    onFetch={ this.fetchData }
                />
            </AuthView>
        );
    }
};

export default connect(state => ({
    i18n: state.i18n,
}))(Ad);
