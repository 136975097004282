import {
    STATUS_DISABLE,
    STATUS_DRAFT,
    STATUS_PUBLISH,
    STATUS_LIVE,
    STATUS_EXPIRY,
    LAUNCH_MODE_INAPP_WEBVIEW,
    LAUNCH_MODE_DEFAULT_BROWSER,
    AD_ORDER_STATUS_ERROR,
} from 'constants/ad';

const ad = {
    'new_document_title': '新增廣告',
    'order_document_title': '廣告排序',
    [`status_${ STATUS_DISABLE }`]: '停用',
    [`status_${ STATUS_DRAFT }`]: '草案',
    [`status_${ STATUS_PUBLISH }`]: '已發佈',
    [`status_${ STATUS_LIVE }`]: '上線',
    [`status_${ STATUS_EXPIRY }`]: '過期',
    [`type_launch_${ LAUNCH_MODE_INAPP_WEBVIEW }`]: '應用程式內嵌網頁',
    [`type_launch_${ LAUNCH_MODE_DEFAULT_BROWSER }`]: '裝置預設瀏覽器',
    'new_ad': '新增廣告',
    'id': '編號',
    'title': '標題',
    'status': '狀態',
    'description': '描述',
    'launch_mode': '開啟模式',
    'link': '連結',
    'visual': '圖片',
    'publish_time': '發佈時間',
    'publish_period': '發佈期間',
    'valid_period': '有效期間',
    'valid_from': '有效開始時間',
    'valid_to': '有效結束時間',
    'search_box_placeholder': '輸入標題',
    'details': '詳細資料',
    'save': '儲存',
    'invalid_date': '錯誤日期格式',
    'wrong_sequence_date': '迄時需在起日之後',
    'wrong_api_call': '哦哦，好像出了什麼問題',
    'publish': '發佈',
    'withdraw': '撤回',
    'published_by': '發佈人',
    'published_time': '發佈時間',
    'change_order': '廣告排序',
    'brief_intro': '請拖曳表格列來改變 AD 順序',
    'confirm_order_change': '確認調整',
    'confirm_order_change_describe': '確定套用本次調整嗎？',
    'continuely_edit': '繼續編緝',
    [`ad_order_error_${ AD_ORDER_STATUS_ERROR }`]: '廣告狀態已變更，請重新排序',
};

export default ad;
