const Columns = [{
    name: 'rental_id',
    value: 'rental.rental_no',
    width: 150,
}, {
    name: 'customer_id',
    value: 'customer.customer_no',
    width: 150,
}, {
    name: 'reserve_time',
    value: 'reservation_time',
    width: 140,
}, {
    name: 'start_time',
    value: 'start_time',
    width: 140,
}, {
    name: 'end_time',
    value: 'end_time',
    width: 140,
}, {
    name: 'duration',
    value: 'duration',
    width: 160,
}, {
    name: 'rental_status',
    value: 'scooter.rental_state',
    width: 100,
}, {
    name: 'price',
    value: 'price',
    width: 89,
}, {
    name: 'payment_status',
    value: 'payment_status',
    width: 120,
}, {
    name: 'corporate_type',
    value: 'customer.corporate_type',
    width: 120,
}, {
    name: 'distance',
    value: 'distance',
    width: 120,
}];

export default Columns;
