import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { Translate } from 'react-redux-i18n';
import {
    FEE_STATUS_UNPAID,
    FEE_STATUS_FAILED,
    FEE_STATUS_PAID,
    FEE_STATUS_REFUND,
    FEE_STATUS_CANCEL,
    FEE_STATUS_PROCESSING,
    FEE_STATUS_PROCESS_SUCCESS,
    FEE_STATUS_PROCESS_FAILED,
    FEE_STATUS_ABSORBED_BY_INTR,
} from 'constants/additional-fee';

const COLOR_MAP = {
    [FEE_STATUS_UNPAID]: 'warning',
    [FEE_STATUS_FAILED]: 'danger',
    [FEE_STATUS_PAID]: 'success',
    [FEE_STATUS_REFUND]: 'dark',
    [FEE_STATUS_CANCEL]: 'dark',
    [FEE_STATUS_PROCESSING]: 'warning',
    [FEE_STATUS_PROCESS_SUCCESS]: 'success',
    [FEE_STATUS_PROCESS_FAILED]: 'danger',
    [FEE_STATUS_ABSORBED_BY_INTR]: 'dark',
};

const AdditionalFeeStatus = ({ type, className, badge }) => {
    let el;
    let color = 'dark';

    switch (type) {
    case FEE_STATUS_UNPAID:
    case FEE_STATUS_FAILED:
    case FEE_STATUS_PAID:
    case FEE_STATUS_REFUND:
    case FEE_STATUS_CANCEL:
    case FEE_STATUS_PROCESSING:
    case FEE_STATUS_PROCESS_SUCCESS:
    case FEE_STATUS_PROCESS_FAILED:
    case FEE_STATUS_ABSORBED_BY_INTR:
        el = <Translate value={ `additional_fee.fee_status_map.${ type }` } />;
        color = COLOR_MAP[type];
        break;
    default:
        el = <span>--</span>;
    };

    if (badge) {
        el = (
            <Badge color={ color } className={ className }>
                { el }
            </Badge>
        );
    }

    return el;
};
AdditionalFeeStatus.propTypes = {
    type: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    className: PropTypes.string,
    badge: PropTypes.bool,
};
AdditionalFeeStatus.defaultProps = {
    type: undefined,
    className: undefined,
    badge: false,
};
export default AdditionalFeeStatus;
