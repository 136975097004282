import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import mime from 'mime';
import { RefreshButton } from 'components/Pagination';

const IMAGE = 'IMAGE_TYPE';
const VIDEO = 'VIDEO_TYPE';

const checkType = (mediaType) => {
    if (/image/.test(mediaType)) {
        return IMAGE;
    }

    if (/video/.test(mediaType)) {
        return VIDEO;
    }
}
const getElement = (type) => {
    let el;
    switch (type) {
    case IMAGE:
        el = 'img';
        break;
    case VIDEO:
        el = 'video';
        break;
    default:
        el = 'img';
        break;
    }
    return el;
}

const Media = ({ src, mediaType, allowReload, onReload }) => {
    const [isError, setIsError] = useState(false);
    const ext = src.split('.').pop().split(/#|\?/)[0];
    const mimeType = mediaType ?? mime.getType(ext);
    const type = checkType(mimeType);
    const MediaFile = getElement(type);

    const onMediaFileError = e => {
        e.target.onerror = null;
        !isError && setIsError(true);
    };

    useEffect(() => {
        setIsError(false);
    }, [src]);

    return (
        <>
            {
                (allowReload && isError) ? (
                    <RefreshButton disabled={ !isError } onClick={ onReload } />
                ) : (
                    <MediaFile
                        className="media"
                        autoPlay
                        controls
                        src={ src }
                        alt=""
                        onError={ onMediaFileError }
                    />
                )
            }
        </>
    );
};
Media.propTypes = {
    src: PropTypes.string.isRequired,
    mediaType: PropTypes.string,
    allowReload: PropTypes.bool,
    onReload: PropTypes.func,
};
Media.defaultProps = {
    mediaType: undefined,
    allowReload: false,
    onReload: () => {},
}

export default Media;
