/**
 * Reduder - Rental
 */
import { Map, List } from 'immutable';
import {
    FETCH_PAYMENT_REQUEST_LIST,
    FETCH_PAYMENT_REQUEST_DETAIL,
    FETCH_PAYMENT_RENTAL_HISTORY,
    CLEAR_PAYMENT_RENTAL_HISTORY,
} from 'constants/action-type';
import creater from './util/creater';

const initialState = Map({
    list: Map({
        page_index: 1,
        page_count: 0,
        data_list: List([]),
    }),
    detail: {},
    history: undefined
});

const setDetail = (state, data) => {
    let detail;
    if (data) {
        detail = {
            ...state.get('detail'),
            [data.expense_id]: Map(data)
        };
    }
    else {
        detail = {};
    }
    return state.merge({
        detail: detail
    });
};

const actionsMap = {
    [FETCH_PAYMENT_REQUEST_LIST]: (state, action) => {
        const { data_list, page_index, page_count, total, __responseTime } = action.data;
        return state.merge({
            list: Map({
                data_list: List(data_list),
                page_index,
                page_count,
                total,
                __responseTime,
            }),
        });
    },
    [FETCH_PAYMENT_REQUEST_DETAIL]: (state, action) => setDetail(state, action.data),
    [FETCH_PAYMENT_RENTAL_HISTORY]: (state, action) => {
        return state.merge({
            history: Map(action.data),
        });
    },
    [CLEAR_PAYMENT_RENTAL_HISTORY]: state => state.merge({
        history: initialState.get('history'),
    }),
};

export default creater(actionsMap, initialState);
