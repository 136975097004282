/**
 * Reducer - General
 */
import { Map } from 'immutable';
import {
    GET_GEOLOCATION,
    CLEAR_GEOLOCATION,
    ERROR_GEOLOCATION,
} from 'constants/action-type';
import creater from './util/creater';

const initialState = Map({
    currentLocation: undefined,
    error: undefined,
});

const actionsMap = {
    [GET_GEOLOCATION]: (state, action) => {
        return state.merge({
            currentLocation: action.data,
            error: initialState.get('error'),
        });
    },

    [CLEAR_GEOLOCATION]: () => {
        return initialState;
    },

    [ERROR_GEOLOCATION]: (state, action) => {
        return state.merge({
            error: action.err,
        });
    }
};

export default creater(actionsMap, initialState);
