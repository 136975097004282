import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';

class UploadFile extends Component {
    static propTypes = {
        accept: PropTypes.string,
        innerRef: PropTypes.shape(),
        name: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
        onInvalid: PropTypes.func,
        required: PropTypes.bool,
        autoComplete: PropTypes.string,
    };

    static defaultProps = {
        accept: undefined,
        innerRef: undefined,
        name: undefined,
        value: '',
        onChange: () => {},
        onInvalid: () => {},
        required: false,
        autoComplete: 'off',
    };

    constructor(props) {
        super(props);

        const { innerRef } = props;
        this.elInput = innerRef || React.createRef();
    }

    handleChange = e => {
        const { onChange } = this.props;

        onChange && onChange(e);
    }

    handleClick = e => {
        this.elInput.current.click();
    }

    render() {
        const { accept, name, required, onInvalid, value, autoComplete } = this.props;

        return (
            <div className="file-uploader">
                <Input innerRef={ this.elInput } type="file" accept={ accept } onChange={ this.handleChange } />
                <Button type="button" className="btn-browse" outline onClick={ this.handleClick }>
                    <Translate value="browse_files" />
                </Button>
                <Input
                    type="text"
                    className="file-name form-control"
                    name={ name }
                    value={ value }
                    required={ required }
                    placeholder={ I18n.t('choose_files') }
                    onInvalid={ onInvalid }
                    onClick={ this.handleClick }
                    onFocus={ e => e.target.blur() }
                    onChange={ () => {} }
                    autoComplete={ autoComplete }
                />
            </div>
        );
    }
}

export default UploadFile;
