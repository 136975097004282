import watermark from 'watermarkjs';

export const addWaterMark = (sourceImage, sourceWatermark, getPosition, callback) => (type) => {
    const getWaterMarkScale = (targetCanvas, waterMarkCanvas) => {
        const ratioHeight = targetCanvas.height / waterMarkCanvas.height;
        const ratioWidth = targetCanvas.width / waterMarkCanvas.width;
        const scale = ratioWidth < ratioHeight ? ratioWidth : ratioHeight;

        return scale;

    };
    return watermark([sourceImage, sourceWatermark], { type: 'image/jpeg' })
        .dataUrl(function (img, watermarkImage) {
            let context = img.getContext('2d');
            const scale = getWaterMarkScale(img, watermarkImage);
            const pos = getPosition(type, scale, img, watermarkImage);
            context.globalAlpha = 0.4;
            context.save();
            context.drawImage(
                watermarkImage,
                pos.x,
                pos.y,
                watermarkImage.width * scale,
                watermarkImage.height * scale,
            );
            context.restore();
            return img;
        })
        .then((img) => {
            watermark.destroy();
            return callback(img);
        });
};
