import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import mobile from 'is-mobile';
import { Translate } from 'react-redux-i18n';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Button } from 'reactstrap';
import AuthFeature from 'components/AuthFeature';
import FlexGroup from 'components/FlexGroup';
import Pagination, { TotalCount, Refresh } from 'components/Pagination';
import PageSizeSelect from 'components/Pagination/PageSizeSelect';
import Smart from 'components/Smart';
import ListView from 'components/ListView';
import { AUTH_EDIT_MISSION } from 'constants/permission';
import { NEW_MISSION, ORDER_MISSION } from 'constants/routes';
import MissionListRow from './MissionListRow';
import missionHeaderConfig from './missionHeaderConfig';

export const FILTER_PAGE_INDEX = 'page';
export const FILTER_PAGE_SIZE = 'size';
export const FILTER_SORT = 'sort';

class MissionList extends React.Component {

    static propTypes = {
        pageSize: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.arrayOf(PropTypes.number),
        ]).isRequired,
        list: PropTypes.shape({
            page_index: PropTypes.number,
            page_count: PropTypes.number,
            data_list: ImmutablePropTypes.list,
        }).isRequired,
        onFetch: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    render() {
        const { onFetch, onChange, list, pageSize } = this.props;
        const { page_index, page_count, data_list, total, __responseTime } = list.toJS();

        return (
            <>
                <FlexGroup>
                    <FlexGroup start gap>
                        <AuthFeature requiredList={ [AUTH_EDIT_MISSION] }>
                            <NavLink to={ NEW_MISSION }>
                                <Button color="primary">
                                    <Translate value="mission.new_mission" />
                                </Button>
                            </NavLink>
                        </AuthFeature>
                        <AuthFeature requiredList={ [AUTH_EDIT_MISSION] }>
                            <NavLink to={ ORDER_MISSION }>
                                <Button outline>
                                    <Translate value="mission.change_order" />
                                </Button>
                            </NavLink>
                        </AuthFeature>
                        <Pagination
                            page={ page_index }
                            total={ page_count }
                            offset={ pageSize }
                            onSelect={ onChange(FILTER_PAGE_INDEX) }
                        />
                        <PageSizeSelect
                            onChange={ onChange(FILTER_PAGE_SIZE) }
                        />
                        <TotalCount count={ total } />
                    </FlexGroup>
                    <FlexGroup end>
                        <Refresh onClick={ onFetch } time={ __responseTime } />
                    </FlexGroup>
                </FlexGroup>
                <Smart
                    fetch={ onFetch }
                    className="main-content-container"
                    seamless
                    pauseRefresh
                >
                    <section className="mission-list-table">
                        <ListView
                            header={ missionHeaderConfig }
                            selectable={ false }
                            dataKey="id"
                            fixed={ mobile() }
                            renderListRow={ MissionListRow }
                            list={ data_list }
                            onSort={ onChange(FILTER_SORT) }
                        />
                    </section>
                </Smart>
            </>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    list: state.mission.get('missionList'),
}))(MissionList);
