import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Badge, Input, Label } from 'reactstrap';

import {
    MAIN_TYPE_SWAP,
    MAIN_TYPE_DISPATCH,
    MAIN_TYPE_MAINTENANCE,
    MAIN_TYPE_OTHERS,
    MAIN_TYPE_GROUP
} from 'constants/ticket';

export default function AssignmentList(props) {
    const { onAssigneeSelect, selectedAssignees, list } =  props;

    return (
        <ul className="assignment-list">
            { list.map(d => (
                <Assignee
                    key={ `${d.tracker_id} - ${d.assigned_to_id}` }
                    tracker_id={ d.tracker_id }
                    assigned_to_id={ d.assigned_to_id }
                    assigned_to_name={ d.assigned_to_name }
                    onSelect={ onAssigneeSelect }
                    selected={ selectedAssignees.getIn([d.tracker_id, d.assigned_to_id]) ?? false }
                    count={ d.count }
                />
            )) }
        </ul>
    );
}

AssignmentList.propTypes = {
    onAssigneeSelect: PropTypes.func.isRequired,
    selectedAssignees: ImmutablePropTypes.map.isRequired,
    list: PropTypes.arrayOf(PropTypes.shape({
        tracker_id: PropTypes.number.isRequired,
        assigned_to_id: PropTypes.string.isRequired,
        assigned_to_name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        tickets: PropTypes.arrayOf(PropTypes.shape({}))
    })).isRequired
};

function Assignee(props) {
    const { assigned_to_id, assigned_to_name, tracker_id, onSelect, selected, count } = props;
    const prefix = ((() => {
        switch (tracker_id) {
        case MAIN_TYPE_SWAP:
            return 'SP';
        case MAIN_TYPE_DISPATCH:
            return 'DP';
        case MAIN_TYPE_MAINTENANCE:
            return 'MT';
        case MAIN_TYPE_OTHERS:
        default:
            return 'OTH';
        }
    })());

    function handleClicked() {
        onSelect({
            tracker_id,
            assigned_to_id
        });
    }

    return (
        <li>
            <Label className="assignee">
                <Input type="checkbox" onChange={ handleClicked } checked={ selected } />
                <span className="assignee-name">
                    <b>{ prefix }</b>
                    { ' - ' }
                    { assigned_to_name }
                </span>
                <Badge className="count">{ count }</Badge>
            </Label>
        </li>
    );
}

Assignee.propTypes = {
    tracker_id: PropTypes.oneOf(MAIN_TYPE_GROUP).isRequired,
    assigned_to_id: PropTypes.string.isRequired,
    assigned_to_name: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    count: PropTypes.number.isRequired
};