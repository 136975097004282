import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './flex-group.scss';

export const GAP_LARGE = 'large';

const FlexGroup = ({
    tag,
    className,
    start,
    end,
    middle,
    center,
    spaceBetween,
    alignStart,
    alignEnd,
    alignStretch,
    alignCenter,
    row,
    children,
    gap
}) => {
    const Tag = tag;
    const style = classNames({
        'flex-group': true,
        'start': start,
        'end': end,
        // vertical
        'middle': middle,
        // horizon
        'center': center,
        'space-between': spaceBetween,
        'align-start': alignStart,
        'align-end': alignEnd,
        'align-stretch': alignStretch,
        'align-center': alignCenter,
        'row': row,
        'gap': gap !== undefined,
        'gap-large': gap === GAP_LARGE,
        [className]: className,
    });

    const updateClassName = () => {
        let childList = children;

        if (Array.isArray(childList) && gap) {
            childList = childList.map((child, i) => {
                if (!child) {
                    return undefined;
                }

                const { props } = child;

                const className = classNames(
                    props.className,
                    'flex-child',
                );

                const newProps = {
                    className,
                    key: i,
                };

                return React.cloneElement(child, newProps);
            });
        }

        return childList;
    };

    return (
        <Tag className={ style }>
            { updateClassName() }
        </Tag>
    );
};
FlexGroup.propTypes = {
    tag: PropTypes.string,
    className: PropTypes.string,
    start: PropTypes.bool,
    end: PropTypes.bool,
    middle: PropTypes.bool,
    center: PropTypes.bool,
    spaceBetween: PropTypes.bool,
    alignStart: PropTypes.bool,
    alignEnd: PropTypes.bool,
    alignStretch: PropTypes.bool,
    alignCenter: PropTypes.bool,
    row: PropTypes.bool,
    gap: PropTypes.oneOf([true, GAP_LARGE]),
    children: PropTypes.node,
};
FlexGroup.defaultProps = {
    tag: 'div',
    className: undefined,
    start: false,
    end: false,
    middle: false,
    center: false,
    spaceBetween: false,
    alignStart: false,
    alignEnd: false,
    alignStretch: false,
    alignCenter: false,
    row: false,
    gap: undefined,
    children: null,
};
export default FlexGroup;
