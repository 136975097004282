import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import FlexGroup from 'components/FlexGroup';
import { updateDocumentTitle } from 'actions';
import Details from './FormSection/Details';
import { Translate } from 'react-redux-i18n';
import AuthView from 'components/AuthView';
import {
    useGetViolationDetailQuery,
} from 'redux/hook/useViolation';

import './violation-detail.scss';

const ViolationDetail = () => {
    const dispatch = useDispatch();
    const i18n = useSelector(state => state.i18n);

    const { rentalId } = useParams();

    const {
        data: violationDetail,
    } = useGetViolationDetailQuery(rentalId);


    useEffect(() => {
        dispatch(updateDocumentTitle('violation.traffic_violation'));
    }, [dispatch, i18n]);

    return (
        <AuthView className="violation-detail">
            <div className="violation-bar">
                <FlexGroup start>
                    <h2 className="violation-title">{ rentalId }</h2>
                </FlexGroup>
                <FlexGroup end>
                    <Button
                        disabled={ violationDetail?.id_scan_image_type === undefined }
                        color="primary"
                        onClick={ () => window.print() }
                    >
                        <Translate value="violation.print" />
                    </Button>
                </FlexGroup>
            </div>
            <section className="form-container contentToPrint">
                <Details data={ violationDetail } />
            </section>
        </AuthView>
    );
};

export default ViolationDetail;

