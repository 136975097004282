import { useState, useEffect, useCallback } from 'react';
import { store as rtkStore } from "redux/store";
import { violationApi } from "redux/services/violationApi";
import isEqual from 'react-fast-compare';

export const useGetViolationDetailQuery = (params, config) => {
    const [resp, setResp] = useState(undefined);
    const { getViolationDetail } = violationApi.endpoints;
    const [fetchConfig, setFetchConfig] = useState(config);
    const fetch = useCallback(
        async () => {
            const response = await rtkStore.dispatch(getViolationDetail.initiate(params, {
                forceRefetch: true,
                ...fetchConfig,
            }));
            setResp(response);
        },
        [params, fetchConfig, getViolationDetail],
    );

    useEffect(() => {
        if(!isEqual(config, fetchConfig)) {
            setFetchConfig(config);
        }
    }, [config, fetchConfig]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return  {
        ...resp,
        refetch: fetch,
    };
};
