const ListHeader = [{
    name: 'id',
    value: 'id',
    width: 280,
}, {
    name: 'price',
    value: 'price',
    width: 80,
}, {
    name: 'discount_value',
    value: 'customer.discount',
    width: 246,
}, {
    name: 'valid_from',
    value: 'customer.valid_from',
    width: 180,
}, {
    name: 'valid_to',
    value: 'customer.valid_to',
    width: 180,
}, {
    name: 'pay_time',
    value: 'customer.pay_time',
    width: 180,
}, {
    name: 'state',
    value: 'customer.state',
    width: 110,
}, {
    name: 'refund_amount',
    value: 'customer.refund_amount',
    width: 110,
}, {
    name: 'refund_btn',
    value: 'rental.refund',
    width: 162,
}];

export default ListHeader;
