import {
    STATUS_DRAFT,
    STATUS_SCHEDULED,
    STATUS_PROCESSING,
    STATUS_FINISHING,
    STATUS_FAILED,

    TYPE_SEND_NOTIFICATION,
    TYPE_SEND_COUPON_AND_NOTIFICATION,

    USER_IDS,
    USER_PHONES,

    // error code
    TITLE_NOT_VALID,
    TYPE_NOT_VALID,
    SCHEDULE_TIME_NOT_VALID,
    COUPON_ID_NOT_VALID,
    NOTIFICATION_TITLE_NOT_VALID,
    NOTIFICATION_BODY_NOT_VALID,
    NOTIFICATION_SOUND_NOT_VALID,
    NOTIFICATION_OPEN_URL_NOT_VALID,
    USER_FILE_LIST_NOT_VALID,
    USER_FILE_LIST_USER_ID_NOT_EXISTS,
    NOTIFICATION_COUPON_DEFAULT_NOT_VALID,
    CAN_NOT_CHANGE_STATUS,
    CAN_NOT_MODIFY_CONTENT,
    USER_FILE_LIST_HEADER_NOT_VALID,
    USER_FILE_LIST_CONTENT_CANNOT_EMPTY,
    USER_FILE_LIST_USER_ID_CANNOT_EMPTY,
    USER_FILE_LIST_USER_ID_FORMAT_NOT_VALID,
    USER_FILE_LIST_PHONE_CANNOT_EMPTY,
    USER_FILE_LIST_PHONE_FORMAT_NOT_VALID,
    USER_LIST_TYPE_IS_NOT_VALID,
} from 'constants/promotion';

const promotion = {
    title: 'Title',
    promotion_id: 'Promotion ID',
    type: 'Type',
    status: 'Status',
    schedule_time: 'Schedule Time',
    search_for: 'Search title',
    [`type_${ TYPE_SEND_NOTIFICATION }`]: 'Push notification',
    [`type_${ TYPE_SEND_COUPON_AND_NOTIFICATION }`]: 'Send coupon',
    [`status_${ STATUS_DRAFT }`]: 'Draft',
    [`status_${ STATUS_SCHEDULED }`]: 'Scheduled',
    [`status_${ STATUS_PROCESSING }`]: 'Processing',
    [`status_${ STATUS_FINISHING }`]: 'Finished',
    [`status_${ STATUS_FAILED }`]: 'Exception',
    new_promotion: 'New Promotion',
    edit_promotion_info: 'Edit Promotion',
    details: 'Details',
    coupon_name: 'Coupon Name',
    notification_title: 'Notification Title',
    notification_body: 'Notification Body',
    notification_sound: 'Notificaiotn Sound',
    notification_open_url: 'Notification Open URL',
    user_list_file: 'Import User ID',
    notification_coupon_default: 'Using Default Notification',
    publish: 'Publish',
    withdraw: 'Withdraw',
    view_title: 'Promotion Detail',
    user_list_type: 'Import type',
    [`import_user_list_type_${ USER_IDS }`]: 'Import user ID',
    [`import_user_list_type_${ USER_PHONES }`]: 'Import user phone',
    [`user_list_type_${ USER_IDS }`]: 'User ID',
    [`user_list_type_${ USER_PHONES }`]: 'User phone',
    result: 'Promote result',
    [`error_message_${ TITLE_NOT_VALID }`]: 'Title is not valid',
    [`error_message_${ TYPE_NOT_VALID }`]: 'Type is not valid',
    [`error_message_${ SCHEDULE_TIME_NOT_VALID }`]: 'Schedule time is not valid',
    [`error_message_${ COUPON_ID_NOT_VALID }`]: 'Coupon ID is not valid',
    [`error_message_${ NOTIFICATION_TITLE_NOT_VALID }`]: 'Notification title is not valid',
    [`error_message_${ NOTIFICATION_BODY_NOT_VALID }`]: 'Notification body is not valid',
    [`error_message_${ NOTIFICATION_SOUND_NOT_VALID }`]: 'Notification sound is not valid',
    [`error_message_${ NOTIFICATION_OPEN_URL_NOT_VALID }`]: 'Notification open url is not valid',
    [`error_message_${ USER_FILE_LIST_NOT_VALID }`]: 'User file list is not valid',
    [`error_message_${ USER_FILE_LIST_USER_ID_NOT_EXISTS }`]: 'User ID not exist',
    [`error_message_${ CAN_NOT_CHANGE_STATUS }`]: 'Can\'t change status',
    [`error_message_${ CAN_NOT_MODIFY_CONTENT }`]: 'Can\'t modify content, when status is scheduled',
    [`error_message_${ NOTIFICATION_COUPON_DEFAULT_NOT_VALID }`]: 'Notification coupon default is not valid',
    [`error_message_${ USER_FILE_LIST_HEADER_NOT_VALID }`]: 'User file list header is not valid',
    [`error_message_${ USER_FILE_LIST_CONTENT_CANNOT_EMPTY }`]: 'User file list cannot be empty',
    [`error_message_${ USER_FILE_LIST_USER_ID_CANNOT_EMPTY }`]: 'User file list user ID cannot be empty',
    [`error_message_${ USER_FILE_LIST_USER_ID_FORMAT_NOT_VALID }`]: 'User file list user ID not valid',
    [`error_message_${ USER_FILE_LIST_PHONE_CANNOT_EMPTY }`]: 'User file list phone cannot be empty',
    [`error_message_${ USER_FILE_LIST_PHONE_FORMAT_NOT_VALID }`]: 'User file list phone not valid',
    [`error_message_${ USER_LIST_TYPE_IS_NOT_VALID }`]: 'Import type not valid',
};

export default promotion;
