import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Translate } from 'react-redux-i18n';
import { ReactComponent as ViolationGroup }  from 'assets/group.svg';
import FlexGroup from 'components/FlexGroup';

class EmptyResult extends Component {
    static propTypes = {
        onClick: PropTypes.func,
    };

    static defaultProps = {
        onClick: () => {},
    };

    render() {
        const { onClick } = this.props;
        return (
            <FlexGroup center middle className="empty-result">
                <div>
                    <ViolationGroup />
                    <div className="info">
                        <Translate value="violation.import_info" />
                    </div>
                    <div>
                        <Button color="primary" onClick={ onClick }>
                            <Translate value="import" />
                        </Button>
                    </div>
                </div>
            </FlexGroup>
        );
    }
}

export default EmptyResult;
