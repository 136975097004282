import {
    ERROR_CODE_40001,
    ERROR_CODE_40002,
    ERROR_CODE_40003,
    ERROR_CODE_40004,
} from 'constants/violation';

const violation = {
    'traffic_violation': '交通違規',
    'violator': '違規人',
    'violate_time': '違規時間',
    'detail': '詳細',
    'violate_detail': '罰單詳細資訊',
    'violate_in_rental': '轉罰',
    'import_violation_list': '匯入罰單',
    'import_info': '請匯入罰單資訊檔案 (.csv)',
    'print': '列印',
    'id': '證件',
    [`import_error_${ERROR_CODE_40001}`]: 'Header format not valid.',
    [`import_error_${ERROR_CODE_40002}`]: 'Content cannot be empty.',
    [`import_error_${ERROR_CODE_40003}`]: 'Time format not valid.',
    [`import_error_${ERROR_CODE_40004}`]: 'File invalid.',
};

export default violation;
