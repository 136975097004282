const ListHeader = [{
    name: 'title',
    value: 'coupon.title',
    sortable: true,
    width: 285,
}, {
    name: 'status',
    value: 'coupon.status',
    sortable: true,
    width: 109,
}, {
    name: 'discount_type',
    value: 'coupon.type',
    sortable: true,
    width: 109,
}, {
    name: 'discount_value',
    value: 'coupon.discount',
    sortable: true,
    width: 139,
}, {
    name: 'promote_type',
    value: 'coupon.promote_type',
    sortable: true,
    width: 109,
}];

export default ListHeader;
