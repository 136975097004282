import {
    LOGIN,
    LOGOUT,
    DELETE_FRF_COOKIE,
    REFRESH_PERMISSION,
    FETCH_OP_USERS,
} from 'constants/action-type';
import api from 'api';
import { asyncInterface } from './util';
import account from '../helpers/account';

export const login = ({ code }) => dispatch => {
    return api.fetchTokenByCode(code)
        .then(({ data }) => data)
        .then(profile => {
            const { id_token } = profile;
            return api.fetchPermission(id_token)().then(({ data }) => ({
                profile,
                permission: data,
            }));
        })
        .then(payload => {
            return Promise.resolve(dispatch({
                type: LOGIN,
                data: payload,
            }));
        });
};

export const fetchNewToken = () => dispatch => {
    return api.fetchNewToken().then(({ data }) => {
        const { profile, expires_in, id_token } = data;
        const res = { ...profile, expires_in };
        account.setID(id_token);
        account.set(res);
        account.setProfile(res);
        account.setExpiresAt(res);
        return Promise.resolve({ data });
    });
};

export const fetchPermission = token => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchPermission(token),
        type: REFRESH_PERMISSION,
        dispatch,
    });
};

export const fetchOperationUsers = ticketId => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchOperationUsers({ ticket_id: ticketId }),
        type: FETCH_OP_USERS,
        dispatch,
    });
};

export const deleteCookie = () => dispatch => {
    return api.deleteCookie()()
        .then(() => {
            return Promise.resolve(dispatch({
                type: DELETE_FRF_COOKIE,
            }));
        });
};

export const logout = () => dispatch => {
    return Promise.resolve(dispatch({
        type: LOGOUT,
    }));
};
