import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'components/Modal';
import { useSelector } from "react-redux";
import { I18n, Translate } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import iconTrash from 'assets/icon-trash.svg';
import Media from 'components/Media';
import {
    TYPE_FILE,
} from 'components/Form/Input/input-type';
import './upload-attachments.scss';

export const initAttachments = counts => Array(counts).fill('');
export const makeupAttachments = (counts, files) => {
    const list = initAttachments(counts);
    return list.reduce((acc, cur, i) => {
        acc.push(files ? (files[i] || cur) : cur);
        return acc;
    },[]);
};

const UploadAttachments = ({
    list,
    isUploaded,
    title,
    onUpdate,
    onDelete,
    viewMode,
    accept,
    disabled,
    namePrefix,
    onReload,
}) => {
    const i18n = useSelector(state => state.i18n);
    const [currentFile, setCurrentFile] = useState();
    const [showModal, setShowModal] = useState(false);
    const [currentFileName, setCurrentFileName] = useState('');

    const handleFileChange = index => e => {
        onUpdate(index, e);
    };

    const closePreview = () => {
        setShowModal(false);
        setCurrentFile(undefined);
        setShowModal(undefined);
    };
    const handleDelete = (index) => e => {
        if (!isUploaded && !viewMode) {
            onDelete(index);
        }
        return ;
    };
    const previewMedia = index => async e => {
        if (!isUploaded) {
            const file = list[index];
            const currentTitle = (typeof title === 'string') ? (title || '') : title((file) || '');
            const blob = await new Blob([file]);
            const src = URL.createObjectURL(blob);

            setCurrentFile(src);
            setCurrentFileName(currentTitle);
            setShowModal(true);
        }
        else {
            const { display_filename, url} = list[index];
            setCurrentFile(url);
            setCurrentFileName(display_filename);
            setShowModal(true);
        }

    };
    const fileWrapperStyle = item => classNames({
        'file-wrapper': true,
        'left': !item,
    });

    const reloadCurrentFile = e => {
        onReload();

    };

    useEffect(() => { }, [i18n]);

    useEffect(() => {
        if (viewMode && currentFileName.length) {
            const [file] = list.filter(item => item['display_filename'] === currentFileName);
            const { display_filename, url } = file || {};
            setCurrentFile(url);
            setCurrentFileName(display_filename);
        }

    }, [currentFileName, list, viewMode]);


    return (
        <div className="upload-attachment">
            <ul className="upload-attachment-list">
                {
                    list.map((item, index) => (
                        <li
                            key={ `${ item?.name }-${ index }` }
                            className="upload-attachment-item"
                        >
                            <span className="file-no">{ `${ I18n.t('attachments') } ${ index + 1 }` }</span>
                            <div className={ fileWrapperStyle(item) }>
                                {
                                    viewMode ? null : (
                                        <label className="file-uploader btn">
                                            <input
                                                type={ TYPE_FILE }
                                                name={ `${ namePrefix }${ index }` }
                                                className="file-input"
                                                accept={ accept }
                                                disabled={ disabled }
                                                onChange={ handleFileChange(index) }
                                            />
                                            <Translate value={ item ? 'reupload' : 'choose_file' } />
                                        </label>
                                    )
                                }
                                {
                                    item ? (
                                        <div className={ item ? 'file-name' : 'file-name--empty'} onClick={ previewMedia(index) }>
                                            <span>{ isUploaded ? item.display_filename : item.name }</span>
                                        </div>
                                    ) : (
                                        <div className={ item ? 'file-name' : 'file-name--empty'}>
                                            { I18n.t('no_file_uploaded') }
                                        </div>
                                    )
                                }
                                {
                                    item && !viewMode ? (
                                        <Button className="btn-delete" onClick={ handleDelete(index) }>
                                            <img src={ iconTrash } alt="Delete" />
                                        </Button>
                                    ) : null
                                }
                            </div>

                        </li>
                    ))
                }
            </ul>

            {
                showModal ? (
                    <Modal
                        className="upload-attachment-modal"
                        title={ currentFileName }
                        onClose={ closePreview }
                    >
                        <Media src={ currentFile }  allowReload onReload={ reloadCurrentFile } />
                    </Modal>
                ) : null
            }
        </div>
    );
};

UploadAttachments.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
    ).isRequired,
    isUploaded: PropTypes.bool,
    title: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
    ]),
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    viewMode: PropTypes.bool,
    accept: PropTypes.string,
    disabled: PropTypes.bool,
    namePrefix: PropTypes.string,
    onReload: PropTypes.func,
};

UploadAttachments.defaultProps = {
    isUploaded: false,
    title: '',
    onUpdate: () => {},
    onDelete: () => {},
    viewMode: false,
    accept: '*/*',
    disabled: false,
    namePrefix: 'attachments',
    onReload: () => {},
};

export default UploadAttachments;
