import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import permissionHandler, { MATCH_ALL, ANY_ONE } from 'helpers/permission-handler';

class AuthFeature extends Component {
    static propTypes = {
        isLoggedIn: PropTypes.bool.isRequired,
        permission: ImmutablePropTypes.map,
        requiredList: PropTypes.arrayOf(PropTypes.string).isRequired,
        term: PropTypes.oneOf([MATCH_ALL, ANY_ONE]),
        fallback: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.element,
        ]),
    };

    static defaultProps = {
        permission: undefined,
        term: MATCH_ALL,
        fallback: undefined,
    };

    renderAlternative() {
        const { fallback } = this.props;
        let el = fallback || <span />;

        if (typeof fallback === 'function') {
            el = fallback();
        }

        return el;
    }

    render() {
        const { children, isLoggedIn, permission, requiredList, term } = this.props;

        return (
            <>
                {
                    permissionHandler({
                        requiredList,
                        term,
                        permissionList: permission,
                        hasLoggedIn: isLoggedIn,
                    }) ?
                        children :
                        this.renderAlternative()
                }
            </>
        );
    }
}

export default connect(state => ({
    isLoggedIn: state.account.get('isLoggedIn'),
    permission: state.account.get('permission'),
}))(AuthFeature);
