import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import MultiselectFilter from 'components/Filter/MultiselectFilter';
import { convertOpUsers } from 'components/NewTicket';
import {
    fetchOperationUsers,
} from 'actions';

class CustomAssignee extends Component {
    static propTypes = {
        opUsers: ImmutablePropTypes.list.isRequired,
        onChange: PropTypes.func.isRequired,
        selected: PropTypes.arrayOf(PropTypes.node),
    };

    static defaultProps = {
        selected: [],
    };

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(fetchOperationUsers());
    }

    render() {
        const { opUsers, selected, onChange } = this.props;

        return (
            <MultiselectFilter
                title={ I18n.t('ticket.assignee') }
                options={ convertOpUsers(opUsers) }
                defaultSelected={ selected }
                onChange={ onChange }
                searchable
            />
        );
    }
};

export default connect(state => ({
    opUsers: state.account.get('opUsers'),
}))(CustomAssignee);
