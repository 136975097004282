import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DocumentEvents from 'react-document-events';
import Datetime from 'components/Datetime';
import BatteryCapacity from 'components/BatteryCapacity';
import ScooterStatus from 'components/Scooter/ScooterStatus';
import TrunkStatus from 'components/Scooter/TrunkStatus';
import Location from 'components/Scooter/Location';
import RentalAction from 'components/Scooter/RentalAction';
import RingSpinner from 'components/Spinner/Ring';

import './history-list.scss';

class HistoryList extends Component {
    static propTypes = {
        list: PropTypes.arrayOf(
            PropTypes.shape({
                type_event: PropTypes.number,
                type_id: PropTypes.string,
                status: PropTypes.number,
                bat_soc: PropTypes.number,
                lat: PropTypes.number,
                lng: PropTypes.number,
                trunk_status: PropTypes.number,
                update_time: PropTypes.string,
            })
        ),
        hasNext: PropTypes.bool,
        onLoadMore: PropTypes.func,
    };

    static defaultProps = {
        list: [],
        hasNext: false,
        onLoadMore: () => {},
    };

    constructor(props) {
        super(props);

        this.elLoadMore = null;
    }

    handleLoadMore = () => {
        const { hasNext, onLoadMore } = this.props;
        const scrollTop = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
        const winHeight = window.innerHeight;

        if (hasNext && this.elLoadMore.current.offsetTop < (scrollTop + winHeight)) {
            onLoadMore();
        }
    }

    render() {
        const { list, asyncStart } = this.props;
        const ListItem = ({ status, bat_soc, trunk_status, update_time, current_location, type_event }) => (
            <li className="list-item">
                <Datetime className="cell updated-time" time={ update_time } />
                <RentalAction className={ `cell event-type event-${ type_event }` } status={ type_event } />
                <div className="details">
                    <BatteryCapacity className="cell" capacity={ bat_soc } />
                    <ScooterStatus className="cell" status={ status } />
                    <TrunkStatus className="cell" status={ trunk_status } />
                    <Location className="cell location" location={ current_location } />
                </div>
            </li>
        );
        const spinnerStyle = classNames({
            'inactive': !asyncStart,
        });

        return (
            <section className="list-wrapper">
                <DocumentEvents onScroll={ this.handleLoadMore } />
                <ul className="history-list">
                    {
                        list.map((item, i) => <ListItem key={ item.type_id } { ...item } />)
                    }
                </ul>
                <RingSpinner className={ spinnerStyle } onMount={ el => this.elLoadMore = el } />
            </section>
        );
    }
}

export default connect(state => ({
    asyncStart: state.asyncHandler.get('asyncStart'),
}))(HistoryList);
