import { Map } from 'immutable';
import {
    PUT_FACTOR_AUTH,
    CLEAR_FACTOR_AUTH,
    CLEAR_AND_PUT_AUTH,
} from 'constants/action-type';
import creater from './util/creater';

const initialState = Map({
    items: Map({}),
});


const actionsMap = {
    [CLEAR_AND_PUT_AUTH]: (state, action) => {
        const { data } = action;
        return state.merge({
            items: Map({
                ...initialState.get('items').toJS(),
                ...data,
            }),
        });
    },
    [PUT_FACTOR_AUTH]: (state, action) => {
        const { data } = action;
        return state.merge({
            items: Map({
                ...state.get('items').toJS(),
                ...data,
            }),
        });
    },
    [CLEAR_FACTOR_AUTH]: (state, action) => {
        return state.merge({
            items: initialState.get('items'),
        });
    },
};

export default creater(actionsMap, initialState);
