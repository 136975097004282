import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import Cell from 'components/OrderableListView/Cell';
import OrderableListRow from 'components/OrderableListView/OrderableListRow';
import Datetime from 'components/Datetime';
import { EDIT_AD } from 'constants/routes';
import { STATUS_LIVE } from 'constants/ad';
import buildActualPath from 'helpers/build-actual-path';

function AdOrderField({ id, value, name, width }) {
    let element;

    switch (name) {
    case 'title':
        element = (
            <NavLink to={ buildActualPath(EDIT_AD, { adId: id }) }>{ value }</NavLink>
        );
        break;
    case 'status':
        const status = value;
        const className = status === STATUS_LIVE ? 'green' : '';
        element = <Translate className={ className } value={ `ad.status_${ status }` } />;
        break;
    case 'valid_from':
    case 'valid_to':
    case 'publish_time':
        element = <Datetime time={ value } />;
        break;
    default:
        element = value;
        break;
    }

    return (
        <Cell key={ `${ id }-${ name }` } name={ name } style={ { width } }>
            { element }
        </Cell>
    );
}

AdOrderField.propTypes = {
    id: PropTypes.node.isRequired,
    value: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
};

const AdOrderListRow = OrderableListRow(memo(AdOrderField));

export default AdOrderListRow;
