import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'components/Modal';
import ScooterProfile from 'components/Scooter/Profile';
import PropTypes from 'prop-types';
import { closeScooterDetailPopup } from 'actions';

import './popup-scooter-detail.scss';

class PopupScooterDetail extends Component {
    static propTypes = {
        openScooterPopup: PropTypes.bool.isRequired,
        profile: PropTypes.shape({}),
    };

    static defaultProps = {
        profile: undefined,
    }

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(closeScooterDetailPopup());
    }

    render() {
        const { openScooterPopup, profile } = this.props;

        return (
            openScooterPopup && profile ?
                (
                    <Modal className="popup-scooter-detail" onClose={ this.handleClose }>
                        <ScooterProfile item={ profile } />
                    </Modal>
                ) :
                null
        );
    }
}

export default withRouter(connect((state, ownProps) => ({
    openScooterPopup: state.scooter.get('openScooterPopup'),
}))(PopupScooterDetail));
