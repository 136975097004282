/**
 * Ad related
 */
export const STATUS_DISABLE = 0;
export const STATUS_DRAFT = 1;
export const STATUS_PUBLISH = 2;
export const STATUS_LIVE = 3;
export const STATUS_EXPIRY = 4;

export const LAUNCH_MODE_INAPP_WEBVIEW = 0;
export const LAUNCH_MODE_DEFAULT_BROWSER = 1;

// error codes
export const AD_ORDER_STATUS_ERROR = 409;
