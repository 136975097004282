import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import {
    FEE_TYPE_FINE,
    FEE_TYPE_WITHHOLDING,
    FEE_TYPE_DISPATCH,
    FEE_TYPE_REPAIR,
} from 'constants/additional-fee';

const FeeType = ({ type, className }) => {
    let el;
    switch (type) {
    case FEE_TYPE_FINE:
    case FEE_TYPE_DISPATCH:
    case FEE_TYPE_REPAIR:
    case FEE_TYPE_WITHHOLDING:
        el = <Translate value={ `additional_fee.fee_type_map.${ type }` } />;
        break;
    default:
        el = <span>--</span>;
    };
    return (
        <div className={ className }>
            { el }
        </div>
    );
};
FeeType.propTypes = {
    type: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    className: PropTypes.string,
};
FeeType.defaultProps = {
    type: undefined,
    className: undefined,
};
export default FeeType;
