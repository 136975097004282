import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import mobile from 'is-mobile';
import FlexGroup from 'components/FlexGroup';
import Pagination, { Refresh, TotalCount } from 'components/Pagination';
import PageSizeSelect from 'components/Pagination/PageSizeSelect';
import Smart from 'components/Smart';
import ListView from 'components/ListView';
import assetHeaderConfig from './assetHeaderConfig';
import AssetListRow from './AssetListRow';

export const FILTER_PAGE_INDEX = 'page';
export const FILTER_PAGE_SIZE = 'size';
export const FILTER_SORT = 'sort';

export default connect(state => ({
    i18n: state.i18n,
    list: state.asset.get('assetList'),
}))(AssetList);

function AssetList(props) {
    const { onFetch, onChange, list, pageSize } = props;
    const { page_index, page_count, data_list, total, __responseTime } = list.toJS();

    return (
        <>
            <FlexGroup>
                <FlexGroup start gap>
                    <Pagination
                        page={ page_index }
                        total={ page_count }
                        offset={ pageSize }
                        onSelect={ onChange(FILTER_PAGE_INDEX) }
                    />
                    <PageSizeSelect onChange={ onChange(FILTER_PAGE_SIZE) } />
                    <TotalCount count={ total } />
                </FlexGroup>
                <FlexGroup end gap>
                    <Refresh time={ __responseTime } onClick={ onFetch } />
                </FlexGroup>
            </FlexGroup>
            <Smart
                fetch={ onFetch }
                className="main-content-container"
                seamless
                pauseRefresh
            >
                <section className="asset-list-table">
                    <ListView
                        header={ assetHeaderConfig }
                        selectable={ false }
                        dataKey="subscribe_id"
                        fixed={ mobile() }
                        renderListRow={ AssetListRow }
                        list={ data_list }
                        onSort={ onChange(FILTER_SORT) }
                    />
                </section>
            </Smart>
        </>
    );
}

AssetList.propTypes = {
    pageSize: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number),
    ]).isRequired,
    list: PropTypes.shape({
        page_index: PropTypes.number,
        page_count: PropTypes.number,
        data_list: ImmutablePropTypes.list,
    }).isRequired,
    onFetch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};
