import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { logout, deleteCookie } from 'actions';
import { getLocaleMap, defaultLanguage } from 'locale';
import { Translate, setLocale } from 'react-redux-i18n';
import Blocker from 'components/Blocker';
import './avatar.scss';

class Avatar extends Component {
    static propTypes = {
        isLoggedIn: PropTypes.bool,
        profile: ImmutablePropTypes.map,
        i18n: PropTypes.shape({}).isRequired,
    };
    static defaultProps = {
        isLoggedIn: false,
        profile: null,
    };

    constructor(props) {
        super(props);

        const { i18n } = props;
        const { locale } = i18n;
        this.locale = locale;
        this.state = {
            open: false,
        };
    }

    handleLogout = () => {
        const { dispatch } = this.props;
        dispatch(logout());
        dispatch(deleteCookie());
    }

    handleLocaleChange = locale => {
        const { dispatch } = this.props;
        this.locale = locale;
        localStorage.setItem('locale', this.locale);
        dispatch(setLocale(this.locale));
    }

    toggleProfileCard = () => {
        const { open } = this.state;

        this.setState({
            open: !open,
        });
    }

    renderProfileCard({ picture, name, email }) {
        const Locale = ({ locale, onChange }) => {
            const { i18n } = this.props;
            const { translations } = i18n;
            const localeMap = getLocaleMap();
            const currentLocal = localeMap[locale] || defaultLanguage;
            const locales = [localeMap['zh-TW'], localeMap['en-US']];
            const length = locales.length;
            const langButtons = [];

            for (let i = 0; i < length; i++) {
                const locale = locales[i].locale;
                const buttonClassName = classNames({
                    'lang-button': true,
                    'active': currentLocal.locale === locale,
                });
                langButtons.push(
                    <Button
                        key={ locale }
                        className={ buttonClassName }
                        onClick={ () => onChange(locale) }
                    >
                        { translations[locale].lang }
                    </Button>
                );
                if (i !== length - 1) {
                    langButtons.push(' ｜ ');
                }
            }

            return <div className="lang-buttons">{ langButtons }</div>;
        };
        const { open } = this.state;
        const panelClass = classNames({
            'collasible-panel': true,
            'show': open,
        });

        return (
            <Blocker className={ panelClass } onClick={ this.toggleProfileCard }>
                <div className="panel-body" onClick={ e => e.stopPropagation() }>
                    <img className="avatar large" src={ picture } alt={ name } />
                    <figcaption>
                        <p className="info name" title={ name }>{ name }</p>
                        <p className="info email">{ email }</p>
                    </figcaption>
                    <Locale locale={ this.locale } onChange={ this.handleLocaleChange } />
                    <Button onClick={ this.handleLogout }>
                        <Translate value="logout" className="logout-text" />
                    </Button>
                </div>
            </Blocker>
        );
    }

    render() {
        const { isLoggedIn, profile } = this.props;
        const profileInfo = isLoggedIn && profile.toJS();
        let el = null;

        if (profileInfo) {
            const { picture, name } = profileInfo;

            el = (
                <div className="account-profile" onClick={ this.toggleProfileCard }>
                    <img className="avatar" src={ picture } alt={ name } />
                    { this.renderProfileCard(profileInfo) }
                </div>
            );
        }

        return el;
    }
};

export default connect(state => ({
    isLoggedIn: state.account.get('isLoggedIn'),
    profile: state.account.get('profile'),
    i18n: state.i18n,
}))(Avatar);
