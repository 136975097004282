import { createFleetApiInstanceWithToken } from '../util';

const promotion = {
    fetchPromotionList(params = {}) {
        return createFleetApiInstanceWithToken().get('/promotions/events', { params });
    },

    fetchOnePromotion(id) {
        return createFleetApiInstanceWithToken().get(`/promotions/events/${ id }`);
    },

    downloadUserListFile(id, params = {}) {
        return createFleetApiInstanceWithToken()
            .get(`/promotions/events/${ id }/user_list_download_url`, { params });
    },

    fetchUserListUploadUrl(params = {}) {
        return createFleetApiInstanceWithToken().get('/promotions/events/user_list_upload_url', { params });
    },

    updateOnePromotion(id, params = {}) {
        return createFleetApiInstanceWithToken().patch(`/promotions/events/${ id }`, params);
    },

    createOnePromotion(params = {}) {
        return createFleetApiInstanceWithToken().post('/promotions/events', params);
    },
};

export default promotion;
