const ListHeader = [{
    name: 'rental_id',
    value: 'log.rental_id',
    width: 80,
    wrap: true,
}, {
    name: 'type',
    value: 'log.type',
    width: 83,
}, {
    name: 'modified_by_user_account',
    value: 'log.modified_by_user_account',
    width: 140,
}, {
    name: 'description',
    value: 'log.description',
    width: 300,
}, {
    name: 'create_time',
    value: 'log.create_time',
    sortable: true,
    width: 100,
}];

export default ListHeader;
