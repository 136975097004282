import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FlexGroup from 'components/FlexGroup';
import InfoCard from 'components/InfoCard';
import DashInfo from 'components/DashInfo';
import Gauge from 'components/Chart/Gauge';
import SingleBar from 'components/Chart/SingleBar/SingleBar';
import { Translate, I18n } from 'react-redux-i18n';
import {
    showServiceLevelWarning,
    getTicketCounts,
    getServiceLevelGaugeLabel,
} from 'helpers/dashboard';
import {
    DASH_COLOR_YELLOW,
    DASH_COLOR_BLUE,
    TICKETS_CATEGORY_BY_ORDER,
} from 'constants/dashboard';

import './dots-overall-service-level.scss';

const DOTS_OVERALL_SL_CHART_SIZE = 105;

const DotsOverallServiceLevel = ({ title, className, overall }) => {

    const CardStyle = classNames({
        'dots-overall-SL': true,
        [className]: true,
    });

    const chartStyle = classNames({
        'title': true,
        'warning': showServiceLevelWarning(overall.service_level),
    });

    const renderOverallInfo = () => {
        return (
            <div className="info">
                <h2 className="title">{ title }</h2>
                <DashInfo
                    title={ I18n.t('scooter.activation.on') }
                    value={ overall.online }
                />
                <DashInfo
                    title={ I18n.t('scooter.activation.off') }
                    value={ overall.offline }
                />
                <DashInfo
                    title={ I18n.t('dashboard.total_scooters') }
                    value={ overall.operating }
                />
            </div>
        );
    };

    const getBardata = item => {
        let label = [];
        let count = [];
        let color = [];

        TICKETS_CATEGORY_BY_ORDER.forEach( category => {
            label.push(`dashboard.tickets_category_${category.id}`);
            color.push(category.style);
            count.push(getTicketCounts(category.id, item));
        });

        return {
            label,
            count,
            color,
        };
    };

    const renderSingleBarLabel = (value, label, color ) => {
        const colorStyle = color.replace('#', '');
        const style = classNames({
            'name': true,
            [`name-${colorStyle}`]: true,
        });
        return (
            <>
                <Translate className={ style } value={ label } />
                <span className="value">{ value }</span>
            </>
        );
    };

    const { color, label, count } = getBardata(overall);

    return (
        <FlexGroup className={ CardStyle }>
            <InfoCard>
                {
                    renderOverallInfo()
                }
                <div className="chart">
                    <div className="service-level">
                        <Gauge
                            width={ DOTS_OVERALL_SL_CHART_SIZE }
                            height={ DOTS_OVERALL_SL_CHART_SIZE }
                            gaugeValue={ overall.service_level || 0 }
                            color={ showServiceLevelWarning(overall.service_level) ?
                                DASH_COLOR_YELLOW :
                                DASH_COLOR_BLUE }
                            renderLabel={ getServiceLevelGaugeLabel() }
                        />
                        <Translate className={ chartStyle } value="dashboard.service_level" />
                    </div>
                </div>

            </InfoCard>
            <InfoCard className="tickets-status">
                <Translate className="title" value="dashboard.tickets_status" tag="h2" />
                <SingleBar
                    values={ count }
                    colors={ color }
                    labels={ label }
                    renderLabel={ renderSingleBarLabel }
                    className="ticket-status-list"
                />
            </InfoCard>
        </FlexGroup>
    );
};

DotsOverallServiceLevel.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    overall: PropTypes.shape({}),
};

DotsOverallServiceLevel.defaultProps = {
    title: '',
    className: '',
    overall: {},
};

export default DotsOverallServiceLevel;
