import React, { Component } from 'react';
import PropTypes from 'prop-types';
import mobile from 'is-mobile';
import { ToolButton } from './index';

const isMobile = mobile();

class Fullscreen extends Component {
    static propTypes = {
        target: PropTypes.shape(),
    };

    static defaultProps = {
        target: undefined,
    };

    handleFullscreen = () => {
        const { target } = this.props;

        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
        else {
            target.requestFullscreen();
        }
    }

    render() {
        const { target } = this.props;
        const buttonIconStyle = (
            document.fullscreenElement ?
                'fas fa-compress' :
                'fas fa-expand'
        );

        return (
            target && !isMobile ? (
                <ToolButton onClick={ this.handleFullscreen }>
                    <span className={ buttonIconStyle } />
                </ToolButton>
            ) : null
        );
    }
}

export default Fullscreen;
