import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RingSpinner from 'components/Spinner/Ring';
import AuthView from 'components/AuthView';
import Profile from 'components/Scooter/Profile';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';
import { fetchSingleScooter, clearSingleScooter, fetchScooterHistory, clearScooterHistory,
    updateDocumentTitle } from 'actions';
import Filter from './Filter';
import HistoryList from './HistoryList';
import { I18n } from 'react-redux-i18n';

import './scooter-history.scss';


const dtFormat = I18n.t('datetime_format.long');
const utcOffset = moment().utcOffset();
const PAGE_OFFSET = 10;

class ScooterHistory extends Component {
    static propTypes = {
        asyncStart: PropTypes.bool.isRequired,
        history: ImmutablePropTypes.map.isRequired,
        singleScooter: ImmutablePropTypes.map,
        startDatetime: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.instanceOf(moment)
        ]),
        endDatetime: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.instanceOf(moment)
        ]),
    };

    static defaultProps = {
        singleScooter: undefined,
        startDatetime: moment().add(-10, 'days'),
        endDatetime: moment(),
    };

    constructor(props) {
        super(props);

        const { startDatetime, endDatetime } = props;

        this.startDatetime = startDatetime ? moment(startDatetime) : moment().add(-10, 'days');
        this.endDatetime = endDatetime ? moment(endDatetime) : moment();
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(updateDocumentTitle('Scooter History'));
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        const { match } = this.props;

        if (prevProps.match.params.scooterId !== match.params.scooterId) {
            this.fetchData();
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(clearSingleScooter());
        this.clearHistory();
    }

    fetchData = () => {
        const { dispatch, match } = this.props;
        const { scooterId } = match.params;

        dispatch(fetchSingleScooter(scooterId));

        this.fetchHistory({ start: this.startDatetime, end: this.endDatetime });
    }

    clearHistory = () => {
        const { dispatch } = this.props;
        return dispatch(clearScooterHistory());
    }

    fetchHistory = ({ start, end, next = '' }) => {
        const { dispatch, match } = this.props;
        const { scooterId } = match.params;
        let params = {
            start_time: this.convertToUTC(start),
            end_time: this.convertToUTC(end),
            page_offset: PAGE_OFFSET,
        };

        if (next) {
            params.page_next = next;
        }

        return dispatch(fetchScooterHistory(scooterId, params));
    }

    convertToUTC = m => moment(m).add(-utcOffset, 'minutes').format(dtFormat) + 'Z'


    handleGoogleApi = ({ map, maps }) => {
        const { singleScooter } = this.props;
        const { plate, current_location } = singleScooter.toJS() || {};
        const { lng, lat } = current_location;
        const marker = new maps.Marker({
            position: {
                lat, lng,
            },
            animation: maps.Animation.BOUNCE,
            title: plate,
        });

        marker.setMap(map);
    }
    handleRemoteCommand = cmd => {
        this.remoteCommand(cmd);
    }
    handelApplyFilter = payload => {
        const { startDatetime, endDatetime } = payload;

        this.startDatetime = moment(startDatetime);
        this.endDatetime = moment(endDatetime);

        this.clearHistory();
        this.fetchHistory({ start: startDatetime, end: endDatetime });
    }

    handleLoadMore = e => {
        const { asyncStart, history } = this.props;
        let promise = Promise.resolve();

        if (!asyncStart) {
            promise = this.fetchHistory({
                start: this.startDatetime,
                end: this.endDatetime,
                next: history.get('cursor_after'),
            });
        }

        return promise;
    }

    render() {
        const { history, singleScooter, asyncStart } = this.props;
        const spinnerStyle = classNames({
            'spinner-container': true,
            hide: !asyncStart,
        });
        const Spinner = () => (
            <div className={ spinnerStyle }>
                <RingSpinner />
            </div>
        );
        const HistoryWrapper = ({ className, children }) => (
            <AuthView className={ classNames(['scooter-history', className]) }>
                { children }
            </AuthView>
        );
        let el;

        if (singleScooter) {
            const jsonSingleScooter = singleScooter.toJS();

            el = (
                <HistoryWrapper>
                    <Profile item={ jsonSingleScooter } />
                    <Filter
                        startDatetime={ this.startDatetime }
                        endDatetime={ this.endDatetime }
                        onApplyChanges={ this.handelApplyFilter }
                    />
                    <HistoryList
                        list={ history.get('data') }
                        hasNext={ !!history.get('cursor_after') }
                        onLoadMore={ this.handleLoadMore }
                    />
                </HistoryWrapper>
            );
        }
        else if (!singleScooter) {
            el = (
                <HistoryWrapper className="not-exists">
                    <h1>Not Exists</h1>
                </HistoryWrapper>
            );
        }
        else {
            el = (
                <HistoryWrapper>
                    <Spinner />
                </HistoryWrapper>
            );
        }

        return el;
    }
}

export default connect((state, ownProps) => ({
    singleScooter: state.scooter.get('singleScooter')[ownProps.match.params.scooterId],
    history: state.scooter.get('history'),
    asyncStart: state.asyncHandler.get('asyncStart'),
}))(ScooterHistory);
