import React from 'react';
import PropTypes from 'prop-types';
import mime from 'mime';
import { I18n } from 'react-redux-i18n';
import Modal from 'components/Modal';
import videoPlaceHolder from 'assets/video-placeholder.png';
import './attachment.scss';

const IMAGE = 'IMAGE_TYPE';
const VIDEO = 'VIDEO_TYPE';

class Attachment extends React.PureComponent {
    static propTypes = {
        src: PropTypes.string.isRequired,
        mediaType: PropTypes.string
    }

    static defaultProps = {
        mediaType: undefined
    }

    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }

    get ext() {
        const { src } = this.props;
        return src.split('.').pop().split(/#|\?/)[0];
    }

    togglePreview = () => {
        this.setState(state => ({
            show: !state.show
        }));
    }

    checkType(mediaType) {
        if (/image/.test(mediaType)) {
            return IMAGE;
        }

        if (/video/.test(mediaType)) {
            return VIDEO;
        }
    }


    render() {
        const { src, mediaType } = this.props;
        const { show } = this.state;
        const mimeType = mediaType ?? mime.getType(this.ext);
        const type = this.checkType(mimeType);
        let Media;
        switch (type) {
        case IMAGE:
            Media = 'img';
            break;
        case VIDEO:
            Media = 'video';
            break;
        default:
            Media = 'img';
            break;
        }

        return (
            <div className="attachment-container">
                <img
                    className="attachment"
                    onClick={ this.togglePreview }
                    src={ type === VIDEO ? videoPlaceHolder : src }
                    alt=""
                />
                { show && (
                    <Modal className="preview-modal" title={ I18n.t('preview') } onClose={ this.togglePreview }>
                        <Media
                            className="preview"
                            autoPlay
                            controls
                            src={ src }
                            alt=""
                        />
                    </Modal>
                ) }
            </div>
        );
    }
}

export default Attachment;
