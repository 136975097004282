import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { CardBody, Card } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import Select from 'react-select';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Form/Button';
import Form from 'components/Form';
import Input, {
    TYPE_NUMBER,
} from 'components/Form/Input';
import {
    REFUND_BY_MONEY,
    REFUND_BY_COUPON,
} from 'constants/rental';
import {
    fetchRefundCoupon,
} from 'actions';
import ControlTemplate from './ControlTemplate';


const CouponSelectField = ({ coupons, onChange }) => {
    const options = coupons.map(
        ({ coupon_id, title }) => ({
            value: coupon_id,
            label: title,
        })
    );
    const handleChange = opt => {
        onChange(opt.value);
    };

    return (
        <ControlTemplate caption="rental.compensation">
            <Select
                options={ options }
                onChange={ handleChange }
            />
        </ControlTemplate>
    );
};
CouponSelectField.propTypes = {
    coupons: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onChange: PropTypes.func.isRequired,
};


class RefundModal extends Component {
    static propTypes = {
        type: PropTypes.oneOf([
            REFUND_BY_MONEY,
            REFUND_BY_COUPON,
        ]).isRequired,
        show: PropTypes.bool.isRequired,
        charged: PropTypes.number,
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        couponList: ImmutablePropTypes.list.isRequired,
    };

    static defaultProps = {
        charged: 0,
        onClose: () => {},
        onSubmit: () => {},
    };

    constructor(props) {
        super(props);

        this.elPrice = React.createRef();
        this.couponId = undefined;
        this.state = {
            inProgress: false,
            refundValue: 0,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.show && props.charged !== state.refundValue) {
            return {
                refundValue: props.charged
            };
        }

        return null;
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fetchRefundCoupon());
    }

    handleCouponChagne = couponId => {
        this.couponId = couponId;
    }

    handleSubmit = () => {
        const { onSubmit } = this.props;
        const price = (this.elPrice.current || {}).value;

        this.setState({
            inProgress: true,
            refundValue: price,
        }, () => {
            onSubmit({
                price: price ? Number(price) : undefined,
                coupon_id: this.couponId,
            }).then(() => {
                this.setState({
                    inProgress: false,
                });
            });
        });

    }

    render() {
        const { inProgress, refundValue } = this.state;
        const { type, onClose, couponList, charged, show } = this.props;
        const coupons = couponList.toJS();

        return show ? (
            <Modal
                className="view-documents-modal"
                title={ I18n.t('rental.refund') }
                disabledCloseOnBg
                onClose={ onClose }
            >
                <Form inline onSubmit={ this.handleSubmit } inProgress={ inProgress } stopSubmitByEnter>
                    <Card>
                        <CardBody>
                            <Input
                                type={ TYPE_NUMBER }
                                caption="rental.total_charged"
                                value={ charged }
                                viewMode
                            />
                            {
                                type === REFUND_BY_MONEY ? (
                                    <Input
                                        type={ TYPE_NUMBER }
                                        innerRef={ this.elPrice }
                                        caption="rental.price"
                                        min="0"
                                        max={ charged }
                                        value={ refundValue }
                                        required
                                    />
                                ) : (
                                    <CouponSelectField coupons={ coupons } onChange={ this.handleCouponChagne } />
                                )
                            }
                        </CardBody>
                    </Card>
                    <ButtonGroup>
                        <Button
                            type="submit"
                            color="primary"
                        >
                            <Translate value="apply" />
                        </Button>
                    </ButtonGroup>
                </Form>
            </Modal>
        ) : null;
    }
}

export default connect(state => ({
    couponList: state.rental.get('coupon_list'),
}))(RefundModal);
