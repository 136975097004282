/**
 * Coupon related
 */
export const STATUS_DISABLE = 0;
export const STATUS_DRAFT = 1;
export const STATUS_PUBLISH = 2;
export const STATUS_LIVE = 3;
export const STATUS_EXPIRY = 4;

export const statusList = [
    STATUS_DISABLE,
    STATUS_DRAFT,
    STATUS_PUBLISH,
    STATUS_LIVE,
    STATUS_EXPIRY,
];

export const statusListForSystemCupon = [
    STATUS_DRAFT,
    STATUS_PUBLISH,
];

export const TYPE_TIME = 0;
export const TYPE_PRICE = 1;

export const typeList = [
    TYPE_TIME,
    TYPE_PRICE,
];

export const PROMOTE_TYPE_PROMOTION_EVENT = 0;
export const PROMOTE_TYPE_MISSION = 1;

export const promotionTypeList = [
    PROMOTE_TYPE_PROMOTION_EVENT,
    PROMOTE_TYPE_MISSION,
];

export const TYPE_COUPON_SINGLE = 0;
export const TYPE_COUPON_BULK = 1;

export const TYPE_COUPON_GENERAL = 0;
export const TYPE_COUPON_SYSTEM = 1;

export const TYPE_RANDOM_USER = 0;
export const TYPE_RANDOM_UPPER_ENG = 1;
export const TYPE_RANDOM_UPPER_EXCLUSIVE = 2;
export const TYPE_RANDOM_DIGIT = 3;
export const TYPE_RANDOM_DIGIT_EXCLUSIVE = 4;
export const TYPE_RANDOM_MIXED = 5;

export const CREATE_PENDING = 0;
export const CREATE_PROCESSING = 1;
export const CREATE_DONE = 2;
export const CREATE_ERROR = 3;
export const DELETE_PENDING = 4;
export const DELETE_PROCESSING = 5;
export const DELETE_DONE = 6;
export const DELETE_ERROR = 7;
export const DISABLE_PENDING = 8;
export const DISABLE_PROCESSING = 9;
export const DISABLE_DONE = 10;
export const DISABLE_ERROR = 11;

export const ERROR_CODE_0 = 0;
export const ERROR_CODE_1 = 1;
export const ERROR_CODE_2 = 2;
export const ERROR_CODE_3 = 3;
export const ERROR_CODE_4 = 4;
export const ERROR_CODE_5 = 5;
export const ERROR_CODE_6 = 6;
export const ERROR_CODE_7 = 7;
export const ERROR_CODE_8 = 8;
export const ERROR_CODE_9 = 9;
export const ERROR_CODE_10 = 10;
export const ERROR_CODE_11 = 11;
export const ERROR_CODE_12 = 12;
export const ERROR_CODE_13 = 13;
export const ERROR_CODE_14 = 14;
export const ERROR_CODE_15 = 15;
export const ERROR_CODE_16 = 16;
export const ERROR_CODE_17 = 17;
export const ERROR_CODE_18 = 18;
export const ERROR_CODE_19 = 19;
export const ERROR_CODE_20 = 20;
export const ERROR_CODE_21 = 21;
export const ERROR_CODE_22 = 22;
export const ERROR_CODE_23 = 23;

export const BULK_RESULT_CODE_SUCCESS = 0;
export const BULK_RESULT_CODE_ERROR = 1;
export const BULK_RESULT_CODE_DUPLICATE = 2;
