import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Marker, MarkerClusterer } from '@react-google-maps/api';
import {
    MODEL_B,
    MODEL_B_DOT,
    MODEL_BTG,
    MODEL_F,
    SERVICE_TYPE_VIP,
    MODEL_P,
    MODEL_J,
    MODEL_D,
} from 'constants/scooter';
import getModelType from 'helpers/getModelType';
import { Translate } from 'react-redux-i18n';
import Button from 'components/Form/Button';
import Location from 'components/Scooter/Location';
import { showMapContent } from 'actions';
import ImmutablePropTypes from 'react-immutable-proptypes';

import scooterClusterIcon from 'assets/map/cluster/icon-mp-scooter@3x.png';
import goStationClusterIcon from 'assets/map/cluster/icon-mp-gostation@3x.png';

import activedModelB from 'assets/map/pin-scooter-simple-active-b@3x.png';
import activedModelBdot from 'assets/map/pin-scooter-simple-active-b-dot@3x.png';
import activedModelBkoi from 'assets/map/pin-scooter-simple-primary-b-koi@3x.png';
import activedModelF from 'assets/map/pin-scooter-simple-active-f@3x.png';
import activedModelP from 'assets/map/pin-scooter-simple-active-p@3x.png';
import activedModelJ from 'assets/map/pin-scooter-simple-active-j@3x.png';
import activedModelD from 'assets/map/pin-scooter-simple-active-d@2x.png';

import primaryModelB from 'assets/map/pin-scooter-simple-primary-b@3x.png';
import primaryModelBdot from 'assets/map/pin-scooter-simple-primary-b-dot@3x.png';
import primaryModelBkoi from 'assets/map/pin-scooter-simple-secondary-b-koi@3x.png';
import primaryModelF from 'assets/map/pin-scooter-simple-primary-f@2x.png';
import primaryModelP from 'assets/map/pin-scooter-simple-primary-p@3x.png';
import primaryModelJ from 'assets/map/pin-scooter-simple-primary-j@3x.png';
import primaryModelD from 'assets/map/pin-scooter-simple-primary-d@2x.png';

import secondaryModelB from 'assets/map/pin-scooter-simple-secondary-b@3x.png';
import secondaryModelBdot from 'assets/map/pin-scooter-simple-secondary-b-dot@3x.png';
import secondaryModelBkoi from 'assets/map/pin-scooter-simple-active-b-koi@3x.png';
import secondaryModelF from 'assets/map/pin-scooter-simple-secondary-f@2x.png';
import secondaryModelP from 'assets/map/pin-scooter-simple-secondary-p@3x.png';
import secondaryModelJ from 'assets/map/pin-scooter-simple-secondary-j@3x.png';
import secondaryModelD from 'assets/map/pin-scooter-simple-secondary-d@2x.png';

import goStationSmallIcon from 'assets/map/icon-gostation-small@3x.png';
import goStationLargeIcon from 'assets/map/icon-gostation-large@3x.png';

import primaryModelBVip from 'assets/map/vip/pin-scooter-simple-primary-b-vip@3x.png';
import secondaryModelBVip from 'assets/map/vip/pin-scooter-simple-secondary-b-vip@3x.png';
import activeModelBVip from 'assets/map/vip/pin-scooter-simple-active-b-vip@3x.png';
import primaryModelFVip from 'assets/map/vip/pin-scooter-simple-primary-f-vip@2x.png';
import secondaryModelFVip from 'assets/map/vip/pin-scooter-simple-secondary-f-vip@2x.png';
import activeModelFVip from 'assets/map/vip/pin-scooter-simple-active-f-vip@3x.png';
import primaryModelBKoiVip from 'assets/map/vip/pin-scooter-simple-primary-koi-vip@3x.png';
import secondaryModelBKoiVip from 'assets/map/vip/pin-scooter-simple-secondary-koi-vip@3x.png';
import activeModelBKoiVip from 'assets/map/vip/pin-scooter-simple-active-koi-vip@3x.png';
import primaryModelPVip from 'assets/map/vip/pin-scooter-simple-primary-p-vip@3x.png';
import secondaryModelPVip from 'assets/map/vip/pin-scooter-simple-secondary-p-vip@3x.png';
import activeModelPVip from 'assets/map/vip/pin-scooter-simple-active-p-vip@3x.png';
import primaryModelJVip from 'assets/map/vip/pin-scooter-simple-primary-j-vip@3x.png';
import secondaryModelJVip from 'assets/map/vip/pin-scooter-simple-secondary-j-vip@3x.png';
import activeModelJVip from 'assets/map/vip/pin-scooter-simple-active-j-vip@3x.png';
import primaryModelDVip from 'assets/map/vip/pin-scooter-simple-primary-d-vip@2x.png';
import secondaryModelDVip from 'assets/map/vip/pin-scooter-simple-secondary-d-vip@2x.png';
import activeModelDVip from 'assets/map/vip/pin-scooter-simple-active-d-vip@2x.png';

export const MAX_ZOOM = 13;

export const getScooterIcon = ({
    model_code,
    selected,
    light,
    service_type,
}) => {
    const { Size } = window.google.maps || {};

    function getMarkerIcon(modelType, service_type) {
        switch (modelType) {
        case MODEL_F:
            return {
                primary: service_type === SERVICE_TYPE_VIP ? primaryModelFVip : primaryModelF,
                secondary: service_type === SERVICE_TYPE_VIP ? secondaryModelFVip : secondaryModelF,
                actived: service_type === SERVICE_TYPE_VIP ? activeModelFVip : activedModelF,
            };
        case MODEL_B_DOT:
            return {
                primary: primaryModelBdot,
                secondary: secondaryModelBdot,
                actived: activedModelBdot,
            };
        case MODEL_BTG:
            return {
                primary: service_type === SERVICE_TYPE_VIP ? primaryModelBKoiVip : primaryModelBkoi,
                secondary: service_type === SERVICE_TYPE_VIP ? secondaryModelBKoiVip : secondaryModelBkoi,
                actived: service_type === SERVICE_TYPE_VIP ? activeModelBKoiVip : activedModelBkoi,
            };
        case MODEL_P:
            return {
                primary: service_type === SERVICE_TYPE_VIP ? primaryModelPVip : primaryModelP,
                secondary: service_type === SERVICE_TYPE_VIP ? secondaryModelPVip : secondaryModelP,
                actived: service_type === SERVICE_TYPE_VIP ? activeModelPVip : activedModelP,
            };
        case MODEL_J:
            return {
                primary: service_type === SERVICE_TYPE_VIP ? primaryModelJVip : primaryModelJ,
                secondary: service_type === SERVICE_TYPE_VIP ? secondaryModelJVip : secondaryModelJ,
                actived: service_type === SERVICE_TYPE_VIP ? activeModelJVip : activedModelJ,
            };
        case MODEL_D:
            return {
                primary: service_type === SERVICE_TYPE_VIP ? primaryModelDVip : primaryModelD,
                secondary: service_type === SERVICE_TYPE_VIP ? secondaryModelDVip : secondaryModelD,
                actived: service_type === SERVICE_TYPE_VIP ? activeModelDVip : activedModelD,
            };
        case MODEL_B:
        default:
            return {
                primary: service_type === SERVICE_TYPE_VIP ? primaryModelBVip : primaryModelB,
                secondary: service_type === SERVICE_TYPE_VIP ? secondaryModelBVip : secondaryModelB,
                actived: service_type === SERVICE_TYPE_VIP ? activeModelBVip : activedModelB,
            };
        }
    }
    const modelType = getModelType(model_code);
    const { primary, secondary, actived } = getMarkerIcon(modelType, service_type);

    let icon = primary;

    if (light) {
        icon = secondary;
    }

    if (selected) {
        icon = actived;
    }

    return {
        url: icon,
        scaledSize: service_type === SERVICE_TYPE_VIP ? new Size(25, 40) : new Size(25, 30),
    };
};

class ScooterMarkerItem extends React.PureComponent {
    static propTypes = {
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        model_code: PropTypes.number.isRequired,
        light: PropTypes.bool,
        lat: PropTypes.number,
        lng: PropTypes.number,
        selected: PropTypes.bool.isRequired,
        clusterer: PropTypes.shape(),
        onClick: PropTypes.func.isRequired,
        service_type: PropTypes.number,
    };

    static defaultProps = {
        clusterer: undefined,
        light: false,
        lat: 0,
        lng: 0,
        service_type: null,
    };

    handleSelect = () => {
        const { id, onClick, selected } = this.props;

        onClick([id], { selected: !selected });
    }

    render() {
        let { clusterer, model_code, id, lat, lng, service_type, light, selected } = this.props;

        if (!lat) {
            lat = 0;
        }

        if (!lng) {
            lng = 0;
        }

        const icon = getScooterIcon({
            model_code,
            selected,
            light,
            service_type,
        });

        return (
            <Marker
                key={ `scooter-marker-${ id }` }
                position={ { lat, lng } }
                clusterer={ clusterer }
                icon={ icon }
                onMouseDown={ (e) => e.domEvent.preventDefault() }
                onClick={ this.handleSelect }
                zIndex={ selected ? 10001 : 100 }
            />
        );
    }
}

export const ScooterMarker = connect((state, { id }) => ({
    selected: !!state.map.get('selectedIdMap').get(id),
}))(ScooterMarkerItem);

export class ScooterCluster extends React.PureComponent {
    static propTypes = {
        list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        onClick: () => {},
    };

    render() {
        const { list, onClick } = this.props;
        const clusterOptions = {
            minimumClusterSize: 1,
            maxZoom: MAX_ZOOM,
            styles: [{
                textColor: '#fff',
                url: scooterClusterIcon,
                height: 52,
                width: 40,
            }]
        };

        return !list.length ? null : (
            <MarkerClusterer clusterClass="cluster" options={ clusterOptions }>
                {
                    clusterer => list.map(item => {
                        const { id } = item;

                        return (
                            <ScooterMarker
                                key={ `scooter-marker-${ id }` }
                                onClick={ onClick }
                                clusterer={ clusterer }
                                { ...item }
                            />
                        );
                    })
                }
            </MarkerClusterer>
        );
    }
};

export class GoStationMarker extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        battery_count: PropTypes.number.isRequired,
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
        selected: PropTypes.bool.isRequired,
        clusterer: PropTypes.shape(),
        onClick: PropTypes.func.isRequired,
    };

    static defaultProps = {
        clusterer: undefined,
    };

    render() {
        const { clusterer, id, lat, lng, battery_count, onClick, selected } = this.props;
        const { maps } = window.google;
        let icon = goStationSmallIcon;
        const size = (
            selected ?
                new maps.Size(56, 64) :
                new maps.Size(32, 32)
        );

        if (selected) {
            icon = goStationLargeIcon;
        }

        const symbolBattery = battery_count > 0 ? 'f' : '0';
        const symbolSelected = selected ? 'f' : '0';
        const color = `#${ symbolBattery }0${ symbolSelected }`;

        return (
            <Marker
                key={ `gostation-marker-${ id }` }
                position={ { lat, lng } }
                clusterer={ clusterer }
                label={ {
                    color,
                    text: battery_count.toString(),
                    anchor: new maps.Point(0, 100),
                } }
                icon={ {
                    url: icon,
                    scaledSize: size,
                } }
                onClick={ onClick }
                onMouseDown={ (e) => e.domEvent.preventDefault() }
            />
        );
    }
}

class GoStationCluster extends React.PureComponent {
    static propTypes = {
        list: ImmutablePropTypes.list.isRequired,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        onClick: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedId: undefined,
        };
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(showMapContent());
    }

    handleClick = item => e => {
        const { dispatch, list } = this.props;
        const { selectedId } = this.state;
        const selectedVMS = list.find(({ id }) => id === item.id);
        const { lat, lng, loc_name, address } = selectedVMS;
        const updatedId = item.id === selectedId ? undefined : item.id;


        this.setState({
            selectedId: updatedId,
        });

        let el = null;

        if (updatedId) {
            el = (
                <React.Fragment>
                    <h6 className="vm-name">
                        { loc_name }
                    </h6>
                    <Location className="vm-address" location={ { lat, lng } }>
                        { address }
                    </Location>
                    <Button className="btn-close" onClick={ this.handleClose }>
                        <Translate value="close" />
                    </Button>
                </React.Fragment>
            );
        }

        dispatch(showMapContent(el));
    };

    handleClose = () => {
        const { dispatch } = this.props;

        dispatch(showMapContent());

        this.setState({
            selectedId: undefined,
        });
    }

    render() {
        const { list } = this.props;
        const { selectedId } = this.state;
        const clusterOptions = {
            minimumClusterSize: 1,
            maxZoom: MAX_ZOOM,
            styles: [{
                textColor: '#fff',
                url: goStationClusterIcon,
                height: 52,
                width: 40,
            }]
        };

        return list.size === 0 ? null : (
            <MarkerClusterer clusterClass="cluster" options={ clusterOptions }>
                {
                    clusterer => list.map(item => {
                        const { id } = item;

                        return (
                            <GoStationMarker
                                key={ `gostation-marker-${ id }` }
                                onClick={ this.handleClick(item) }
                                selected={ id === selectedId }
                                clusterer={ clusterer }
                                { ...item }
                            />
                        );
                    })
                }
            </MarkerClusterer>
        );
    }
};

export default connect(state => ({
    list: state.map.get('vms'),
}))(GoStationCluster);
