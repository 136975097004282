const ListHeader = [{
    name: 'plate',
    value: 'maintenance.plate',
    sortable: true,
    width: 148,
}, {
    name: 'model',
    value: 'scooter.model',
    sortable: true,
    width: 83,
}, {
    name: 'city',
    value: 'scooter.city',
    sortable: true,
    width: 90,
}, {
    name: 'is_activate',
    value: 'scooter.service',
    sortable: true,
    width: 94,
}, {
    name: 'rental_state',
    value: 'scooter.rental_state',
    sortable: true,
    width: 85,
}, {
    name: 'total_mileage',
    value: 'maintenance.total_mileage',
    sortable: true,
    width: 100,
}, {
    name: 'maintain_mileage',
    value: 'maintenance.last_regular_maintenance',
    sortable: true,
    width: 124,
}, {
    name: 'diff_mileage',
    value: 'maintenance.maintenance_countdown_distance',
    sortable: true,
    width: 120,
}, {
    name: 'maintain_date',
    value: 'maintenance.last_regular_maintenance_date',
    sortable: true,
    width: 110,
}, {
    name: 'corporate_name',
    value: 'scooter.corporate',
    sortable: false,
    width: 120,
}];

export default ListHeader;
