const validationError = {
    'patternMismatch': '格式不正確',
    'rangeOverflow': '數值太高',
    'rangeUnderflow': '數值太低',
    'tooLong': '輸入過長',
    'tooShort': '輸入過短',
    'typeMismatch': '格式不正確',
    'valueMissing': '此為必填欄位',
    'stepMismatch': '請輸入有效值',
    'generalInvalid': '請確認內容',
    'wrong_dates_order': '日期順序錯誤',
    'out_of_duration': '不得超過 %{num} %{unit}',
    'require_both_dates': '需選擇兩個日期 (起/迄)',
    'require_both_numbers': '需輸入兩個數字 (起/迄)',
    'wrong_order_of_numbers': '`迄`數需大於`起`數',
    'stepMismatchDecimal': '只支援至小數點後第 %{digits} 位',
    'stepOrdinal': {
        '1': '',
        '2': '',
        '3': '',
        'th': '',
    },
    'futureDate': '不可輸入未來日期',
    'totalMileage': '不可超過目前總里程',
};

export default validationError;
