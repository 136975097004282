import api from 'api';
import { asyncInterface } from './util';
import {
    FETCH_ASSET_LIST,
    CONNECTED_DEVICES,
} from 'constants/action-type';

export const fetchAssetList = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchAssetList(params),
        type: FETCH_ASSET_LIST,
        dispatch,
    });
};

export const connectedDevices = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.connectedDevices(params),
        type: CONNECTED_DEVICES,
        dispatch,
    });
};