import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

const Component = ({ value, className }) => (
    <div className={ className }>
        { I18n.t(`scooter.tag_map.${ value }`) }
    </div>
);

Component.propTypes = {
    value: PropTypes.number,
    className: PropTypes.string,
};

Component.defaultProps = {
    value: 0,
    className: '',
};

export default Component;
