import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoCard from 'components/InfoCard';
import FlexGroup from 'components/FlexGroup';
import { Translate, I18n } from 'react-redux-i18n';
import PieChart from 'components/Chart/PieChart';
import {
    getDashScooterModelStyle,
    getDashScooterModelColorList,
    getScooterModelPieLabel,
    getScooterModelPieSubtitle,
} from 'helpers/dashboard';

import './overall-scooter-model.scss';

const MODEL_CHART_SIZE = 270;

class  OverallModel extends React.PureComponent {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,
            online: PropTypes.number,
            offline: PropTypes.number,
        })).isRequired,
        title: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        title: '',
        className: '',
    };


    getPieValues = (list) => {
        let result = [];
        list.forEach(item => {
            result = result.concat([
                item.online,
                item.offline,
            ]);
        });
        return result;
    }


    renderModelCount = model => {
        const styleName = getDashScooterModelStyle(model.type);
        return (
            <FlexGroup className={ `model-${styleName}` } key={ model.type }>
                <div className="caption">{ model.type }</div>
                <FlexGroup className="activation">
                    <FlexGroup className="content">
                        <Translate className="label-online" value="scooter.activation.on" />
                        <span>{ model.online }</span>
                    </FlexGroup>
                    <FlexGroup className="content">
                        <Translate className="label-offline" value="scooter.activation.off" />
                        <span>{ model.offline }</span>
                    </FlexGroup>
                </FlexGroup>
            </FlexGroup>
        );
    }

    render() {
        const { data, title, className } = this.props;
        const pieColors = getDashScooterModelColorList(data);
        const pieValue = this.getPieValues(data);

        const CardStyle = classNames({
            'overall-model': true,
            [className]: true,
        });


        return (
            <InfoCard className={ CardStyle }>
                <div className="chart">
                    <h2 className="title">{ title }</h2>
                    <PieChart
                        pieValues={ pieValue }
                        pieColors={ pieColors }
                        subTitle={ I18n.t('dashboard.total_scooters') }
                        className="model-pie-chart"
                        autoResize={ true }
                        width={ MODEL_CHART_SIZE }
                        renderLabel={ getScooterModelPieLabel() }
                        renderSubtitle={ getScooterModelPieSubtitle() }
                    />
                </div>
                <FlexGroup start className="model-list">
                    {
                        data.length && data.map(item => this.renderModelCount(item))
                    }
                </FlexGroup>
            </InfoCard>
        );
    }

};

export default OverallModel;
