/**
 * To get map bounds with buffer
 */
const getMapBounds = map => {
    if (!map) {
        return undefined;
    }

    const bounds = map.getBounds();

    if (!bounds) {
        return undefined;
    }

    const hundredMeter = 0.000900900901;
    const { east, north, west, south } = bounds.toJSON();
    const bufferEast = east - hundredMeter;
    const bufferNorth = north + hundredMeter;
    const bufferWest = west + hundredMeter;
    const bufferSouth = south - hundredMeter;

    return {
        bounds: {
            east: bufferEast,
            north: bufferNorth,
            west: bufferWest,
            south: bufferSouth,
            center: bounds.getCenter().toJSON(),
            nw: {
                lat: bufferNorth,
                lng: bufferWest,
            },
            se: {
                lat: bufferSouth,
                lng: bufferEast,
            },
        },
        zoom: map.getZoom(),
    };
};

export default getMapBounds;
