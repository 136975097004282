import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import AuthView from 'components/AuthView';
import SearchField from 'components/SearchField';
import FlexGroup from 'components/FlexGroup';
import { getDefaultPageSize } from 'components/Pagination/PageSizeSelect';
import { convertToUTC } from 'helpers/time-handler';
import {
    fetchMissionList,
    updateDocumentTitle,
} from 'actions';
import StatusFilter from './filters/StatusFilter';
import ValidTimeFilter from './filters/ValidTimeFilter';
import CriteriaTypeFilter from './filters/CriteriaTypeFilter';
import MissionList, {
    FILTER_PAGE_INDEX,
    FILTER_PAGE_SIZE,
    FILTER_SORT,
} from './MissionList';
import './mission.scss';

const FILTER_TITLE = 'title';
const FILTER_STATUS = 'status';
const FILTER_VALID_FROM = 'valid_from';
const FILTER_VALID_TO = 'valid_to';
const FILTER_VALID_TIME = 'valid_time';
const FILTER_CRITERIA_TYPE = 'criteria_type';

class MissionManagement extends React.Component {

    searchText = ''

    state = {
        queryPayload: {
            filter: {
                [FILTER_TITLE]: undefined,
                [FILTER_STATUS]: [],
                [FILTER_CRITERIA_TYPE]: [],
                [FILTER_VALID_FROM]: undefined,
                [FILTER_VALID_TO]: undefined,
            },
            [FILTER_PAGE_INDEX]: 1,
            [FILTER_PAGE_SIZE]: getDefaultPageSize().value,
            [FILTER_SORT]: {},
        },
    }

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { pathname } = location;

        dispatch(updateDocumentTitle(`routes.${ pathname }`));
    }

    componentDidUpdate(prevProps, prevState) {
        const { queryPayload: prevQueryPayload } = prevState;
        const { queryPayload } = this.state;

        if (prevQueryPayload !== queryPayload) {
            this.fetchMissionList();
        }
    }

    setSearchText = text => {
        this.searchText = text;
    }

    fetchMissionList = async () => {
        const { dispatch } = this.props;
        const { queryPayload } = this.state;
        const { filter, ...rest } = queryPayload;
        const payload = {
            ...filter,
            ...rest,
        };

        await dispatch(fetchMissionList(payload));
    }

    handleQueryPayloadChanged = name => value => {
        const { queryPayload } = this.state;
        let { filter } = queryPayload;
        queryPayload[FILTER_PAGE_INDEX] = 1;

        switch (name) {
        case FILTER_TITLE:
            filter = {
                [FILTER_TITLE]: this.searchText || undefined
            };
            break;
        case FILTER_STATUS:
        case FILTER_CRITERIA_TYPE:
            filter = {
                ...filter,
                [name]: value.inputSelect,
            };
            break;
        case FILTER_VALID_TIME:
            const { from, to } = value;
            filter = {
                ...filter,
                [FILTER_VALID_FROM]: from ? convertToUTC(from) : undefined,
                [FILTER_VALID_TO]: to ? convertToUTC(to) : undefined,
            };
            break;
        case FILTER_PAGE_INDEX:
            queryPayload[name] = value;
            break;
        case FILTER_PAGE_SIZE:
            queryPayload[name] = value.value;
            break;
        case FILTER_SORT:
            let { field } = value;
            const { order } = value;

            if (field && order) {
                queryPayload[FILTER_SORT] = `${ field },${ order }`;
            }
            else {
                queryPayload[FILTER_SORT] = undefined;
            }
            break;
        default:
            break;
        }

        queryPayload.filter = filter;
        this.setState({
            queryPayload: { ...queryPayload }
        });
    }

    render() {
        const { queryPayload } = this.state;
        const { filter } = queryPayload;

        return (
            <AuthView className="missions">
                <FlexGroup start>
                    <SearchField
                        placeholder={ I18n.t('mission.search_for') }
                        onChange={ this.setSearchText }
                        onSubmit={ this.handleQueryPayloadChanged(FILTER_TITLE) }
                        value={ this.searchText }
                    />
                    <StatusFilter
                        selected={ filter[FILTER_STATUS] }
                        onChange={ this.handleQueryPayloadChanged(FILTER_STATUS) }
                    />
                    <ValidTimeFilter
                        validFromTime={ filter[FILTER_VALID_FROM] }
                        validToTime={ filter[FILTER_VALID_TO] }
                        onChange={ this.handleQueryPayloadChanged(FILTER_VALID_TIME) }
                    />
                    <CriteriaTypeFilter
                        selected={ filter[FILTER_CRITERIA_TYPE] }
                        onChange={ this.handleQueryPayloadChanged(FILTER_CRITERIA_TYPE) }
                    />
                </FlexGroup>
                <MissionList
                    pageSize={ queryPayload.size }
                    onFetch={ this.fetchMissionList }
                    onChange={ this.handleQueryPayloadChanged }
                />
            </AuthView>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(MissionManagement);
