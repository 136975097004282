import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import FlexGroup from 'components/FlexGroup';
import ChoiceGroupField from 'components/ChoiceGroup/ChoiceGroupField';

export const SEARCH_BY_PLATE = 'plate_no';
export const SEARCH_BY_TICKET = 'ticket_id';

const CustomChoiceGroupField = ({ children, defaultChecked, value, onChangeType, onSubmit, onChange }) => {
    const fieldTypes = [
        {
            id: SEARCH_BY_PLATE,
            name: I18n.t('scooter.plate'),
            value: SEARCH_BY_PLATE,
        }, {
            id: SEARCH_BY_TICKET,
            name: I18n.t('ticket.ticket_no'),
            value: SEARCH_BY_TICKET,
        }
    ];

    return (
        <FlexGroup start className="Filter choice-group-field">
            <ChoiceGroupField
                renderTitle={ () => <Translate value="search_for" /> }
                defaultChecked={ defaultChecked }
                options={ fieldTypes }
                value={ value }
                onChoiceChange={ onChangeType }
                onSubmit={ onSubmit }
                onChange={ onChange }
            />
            { children }
        </FlexGroup>
    );
};

CustomChoiceGroupField.propTypes = {
    defaultChecked: PropTypes.string.isRequired,
    onChangeType: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

CustomChoiceGroupField.defaultProps = {
    value: undefined,
};

export default CustomChoiceGroupField;
