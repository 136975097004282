import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import DatetimeRangeFilter from 'components/Filter/DatetimeRangeFilter';

export default function ValidTimeFilter({ onChange, validFromTime, validToTime, }) {
    const applied = (
        validFromTime && moment(validFromTime).isValid() &&
        validToTime && moment(validToTime).isValid()
    );

    return (
        <DatetimeRangeFilter
            title={ I18n.t('mission.valid_time') }
            onApply={ onChange }
            applied={ applied }
        />
    );
}

ValidTimeFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    validFromTime: PropTypes.string,
    validToTime: PropTypes.string,
};
ValidTimeFilter.defaultProps = {
    validFromTime: undefined,
    validToTime: undefined,
};