/**
 * Reduder - Map
 */
import { Map, List } from 'immutable';
import {
    FETCH_ZONES,
    FETCH_VMS,
    FOOTER_CONTENT,
    SELECT_SCOOTER,
    GET_VIP_LAYER,
    SELECT_VIP_LAYER,
    CLEAR_VIP_LAYER,
} from 'constants/action-type';
import creater from './util/creater';

const initialState = Map({
    zones: Map({
        data_size: 0,
        data: List([]),
    }),
    vms: List([]),
    footerContent: undefined,
    selectedIdList: List([]),
    selectedIdMap: Map(),
    vips: List([]),
    selectedVipLayerList: List([]),
});

const actionsMap = {
    [FETCH_ZONES]: (state, action) => {
        return state.merge({
            zones: Map(action.data),
        });
    },

    [FETCH_VMS]: (state, action) => {
        return state.merge({
            vms: List(action.data),
        });
    },

    [FOOTER_CONTENT]: (state, action) => {
        return state.merge({
            footerContent: action.data,
        });
    },

    [SELECT_SCOOTER]: (state, action) => {
        const { data } = action;

        return state.merge({
            selectedIdList: List(data),
            selectedIdMap: Map(data.reduce((obj, scooterId) => {
                obj[scooterId] = true;

                return obj;
            }, {})),
        });
    },

    [SELECT_VIP_LAYER]: (state, action) => {
        const { data } = action;

        return state.merge({
            selectedVipLayerList: List(data),
        });
    },

    [CLEAR_VIP_LAYER]: (state) => {
        return state.merge({
            selectedVipLayerList: List([]),
        });
    },

    [GET_VIP_LAYER]: (state, action) => {
        const { data } = action;
        return state.merge({
            vips: List(data),
        });
    },
};

export default creater(actionsMap, initialState);
