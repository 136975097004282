export const headerConfig = [{
    name: 'id',
    value: 'ticket.ticket_no',
    sortable: true,
    width: 100,
}, {
    name: 'plate_no',
    value: 'scooter.plate',
    sortable: true,
    width: 130,
}, {
    name: 'tracker_id',
    value: 'ticket.main_type',
    sortable: true,
    width: 110,
}, {
    name: 'tracker_subtype_id',
    value: 'ticket.sub_type',
    sortable: true,
    width: 200,
}, {
    name: 'severity_id',
    value: 'ticket.priority',
    sortable: true,
    width: 80,
}, ];
