import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

const { REACT_APP_API_BASE_URL } = process.env;

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: REACT_APP_API_BASE_URL,
        credentials: 'include',
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            return headers;
        },
        paramsSerializer: (params) => qs.stringify(params)
    }),
    endpoints: (builder) => ({
        googleAuth: builder.query({
            query: (body) => {
                return ({
                    url: '/fleet_users/google_auth/',
                    method: 'POST',
                    body
                })
            }
        }),
        deleteCookie: builder.mutation({
            query: () => {
                return ({
                    url: '/fleet_users/frf_cookie',
                    method: 'DELETE',
                })
            }
        })
    })
});

export const {
    useGoogleAuthQuery,
    useDeleteCookieMutation,
} = authApi;
