import { combineReducers } from 'redux';
import general from './general';
import asyncHandler from './util/async-handler';
import storeCreater from './util/store-creater';
import scooter from './scooter';
import account from './account';
import customer from './customer';
import rental from './rental';
import coupon from './coupon';
import ad from './ad';
import ticket from './ticket';
import map from './map';
import geolocation from './geolocation';
import log from './log';
import admin from './admin';
import promotion from './promotion';
import violation from './violation';
import mission from './mission';
import asset from './asset';
import dashboard from './dashboard';
import maintenance from './maintenance';
import paymentrequest from './paymentrequest';
import factorAuth from './factorauth';
import cities from './cities';
import { i18nReducer } from 'react-redux-i18n';


export default combineReducers({
    general,
    asyncHandler,
    scooter,
    account,
    customer,
    rental,
    coupon,
    ad,
    ticket,
    map,
    geolocation,
    log,
    admin,
    promotion,
    violation,
    mission,
    asset,
    dashboard,
    maintenance,
    paymentrequest,
    cities,
    factorAuth,
    i18n: i18nReducer,
});

export const createStore = storeCreater;
