import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import classNames from 'classnames';
import RingSpinner from 'components/Spinner/Ring';
import api from 'api';
import { toggleErrorDialog } from 'actions';
import getImgWithWatermark from 'helpers/doc-watermark';
import {
    ID_SCAN_IMAGE,
    DRIVER_SCAN_IMAGE_FRONT,
    DRIVER_SCAN_IMAGE_BACK,
} from 'constants/customer';
import waterMarkImgForId from 'assets/watermark-idcard.svg';
import waterMarkImgDriverFront from 'assets/watermark-driver-front.svg';
import waterMarkImgDriverBack from 'assets/watermark-driver-back.svg';

import './document.scss';

const LIVE_PHOTO = 'livePhoto';
const LIVE_PHOTO_DURATION = 600;

class CustomerDoc extends React.Component {
    static propTypes = {
        idProvider: PropTypes.number,
        licenseProvider: PropTypes.number,
        idScanImageLink: PropTypes.string,
        driverScanFrontImageLink: PropTypes.string,
        driverScanBackImageLink: PropTypes.string,
        livePhotoListLink: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        idProvider: 0,
        licenseProvider: 0,
        idScanImageLink: undefined,
        driverScanFrontImageLink: undefined,
        driverScanBackImageLink: undefined,
        livePhotoListLink: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            isActive: ID_SCAN_IMAGE,
            activeImage: undefined,
            currentLivePhotoCount: 0,
        };
        this[ID_SCAN_IMAGE] = [];
        this[DRIVER_SCAN_IMAGE_FRONT] = [];
        this[DRIVER_SCAN_IMAGE_BACK] = [];
        this[LIVE_PHOTO] = [];

        this.livePhotoTimer = undefined;
    }

    componentDidMount() {
        this.getDocuemntImages();
    }

    componentWillUnmount() {
        clearInterval(this.livePhotoTimer);
    }

    forbidSaveDocImg = (e) => {
        e.preventDefault();
    }

    handleClick = (type = ID_SCAN_IMAGE) => e => {
        const { currentLivePhotoCount } = this.state;
        const activeImage = (type === LIVE_PHOTO) ? this[type][currentLivePhotoCount] : this[type][0];
        this.setState({
            isActive: type,
            activeImage: activeImage,
        });
    }

    setLivePhotoTimer = () => {
        this.livePhotoTimer = setInterval(() => {
            const { isActive, currentLivePhotoCount } = this.state;

            if (isActive === LIVE_PHOTO) {
                const count = (currentLivePhotoCount === this[LIVE_PHOTO].length - 1) ? 0 : currentLivePhotoCount + 1;

                this.setState({
                    activeImage: this[LIVE_PHOTO][count],
                    currentLivePhotoCount: count,
                });
            }

        }, LIVE_PHOTO_DURATION);
    }

    getDocuemntImages = () => {
        const {
            idScanImageLink,
            driverScanFrontImageLink,
            driverScanBackImageLink,
            livePhotoListLink,
            dispatch,
            idProvider,
            licenseProvider,
        } = this.props;
        return Promise.all([
            this.fetchDocumentSrc(ID_SCAN_IMAGE, idScanImageLink, idProvider),
            this.fetchDocumentSrc(DRIVER_SCAN_IMAGE_FRONT, driverScanFrontImageLink, licenseProvider),
            this.fetchDocumentSrc(DRIVER_SCAN_IMAGE_BACK, driverScanBackImageLink, licenseProvider),
            ...this.fetchLivePhotoSrc(LIVE_PHOTO, livePhotoListLink, idProvider),
        ]).then( data => {
            const { isActive } = this.state;
            this.handleClick(isActive)();
            this.setLivePhotoTimer();

        }).catch(error => {
            console.error('Error for Document of Customer:', error);
            dispatch(toggleErrorDialog(I18n.t('general_error')));
        });
    }

    getWatermarkImg = type => {
        let img;
        switch (type) {
        case DRIVER_SCAN_IMAGE_BACK:
            img = waterMarkImgDriverBack;
            break;
        case DRIVER_SCAN_IMAGE_FRONT:
            img = waterMarkImgDriverFront;
            break;
        case ID_SCAN_IMAGE:
            img = waterMarkImgForId;
            break;
        default:
        }
        return img;
    };

    addWatermark = img => type => {
        const watermark = this.getWatermarkImg(type);
        if ( img ) {
            return getImgWithWatermark(type, img, watermark);
        }
    }

    fetchCustomerDocument = (link, provider) => {
        return api.fetchCustomerDocument({ doc_link: link, doc_provider: provider })
            .then( ({ status, data }) => {
                if (status === 200) {
                    let img = (data) ? `data:${ data['content-type'] };base64, ${ data['encoded-image'] }` : null;
                    return img;
                }
            });
    }

    fetchDocumentSrc = (type, link, provider) => {
        if (link) {
            return this.fetchCustomerDocument(link, provider).then(img => {
                return this.addWatermark(img)(type)
                    .then((img) => {
                        this[type][0] = img;
                        return img;
                    });
            });
        }
        else {
            this[type][0] = null;
            return null;
        }
    }

    fetchLivePhotoSrc = (type, linkList, provider) => {
        const { currentLivePhotoCount } = this.state;
        if (linkList && linkList.length) {
            return (
                linkList.map((link, index) => {
                    return this.fetchCustomerDocument(link, provider).then(img => {
                        this[type][index] = img;
                        return img;
                    });
                })
            );
        }
        else {
            this[type][currentLivePhotoCount] = null;
            return [null];
        }

    }

    render() {
        const LinkImage = ({ src, alt, className }) => (
            <div className="link-image">
                <img src={ src } alt={ alt } className={ className } />
            </div>
        );

        const renderLinkImage = () => {
            const { isActive, activeImage } = this.state;
            let content;
            switch (isActive) {
            case LIVE_PHOTO:
                content =  (
                    <LinkImage className="livePhoto" src={ activeImage } alt="" />
                );
                break;
            default:
                content = (
                    <LinkImage src={ activeImage } alt="" />
                );
            }
            return content;
        };
        const tabStyle = target => {
            const { isActive } = this.state;
            return (classNames({
                'tab': true,
                'isActive': target === isActive,
            }));
        };

        const { activeImage } = this.state;

        return (
            <div
                className="customer-document"
                onContextMenu={ this.forbidSaveDocImg }
            >
                <div className="tabs">
                    <label
                        className={ tabStyle(ID_SCAN_IMAGE) }
                        onClick={ this.handleClick(ID_SCAN_IMAGE) }
                    >
                        <Translate value="id" />
                    </label>
                    <label
                        className={ tabStyle(DRIVER_SCAN_IMAGE_FRONT) }
                        onClick={ this.handleClick(DRIVER_SCAN_IMAGE_FRONT) }
                    >
                        <Translate value="customer.driving_license" />
                    </label>
                    <label
                        className={ tabStyle(DRIVER_SCAN_IMAGE_BACK) }
                        onClick={ this.handleClick(DRIVER_SCAN_IMAGE_BACK) }
                    >
                        <Translate value="customer.driving_license_back" />
                    </label>
                    <label
                        className={ tabStyle(LIVE_PHOTO) }
                        onClick={ this.handleClick(LIVE_PHOTO) }
                    >
                        <Translate value="customer.live_photo" />
                    </label>
                </div>
                <div className="tab-box">
                    {
                        (!activeImage && activeImage !== null) ?
                            <RingSpinner /> :
                            renderLinkImage()
                    }
                </div>
            </div>
        );
    }
}

export default connect((state, ownProps) => ({
    i18n: state.i18n,
}))(CustomerDoc);
