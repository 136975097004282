const validationError = {
    'patternMismatch': 'Format does\'t match',
    'rangeOverflow': 'Value is overflow',
    'rangeUnderflow': 'Value is underflow',
    'tooLong': 'Too long',
    'tooShort': 'Too shart',
    'typeMismatch': 'Format does\'t match',
    'valueMissing': 'Required field',
    'stepMismatch': 'Please enter a valid value',
    'generalInvalid': 'Please check this content of field',
    'wrong_dates_order': 'Dates in wrong order',
    'out_of_duration': 'The duration can not over %{num} %{unit}',
    'require_both_dates': 'Need to pick up 2 dates (from/to)',
    'require_both_numbers': 'Need to input 2 numbers (from/to)',
    'wrong_order_of_numbers': '`To` has to greater than `From`',
    'stepMismatchDecimal': 'Support the %{digits}%{ordinal} place after the decimal point',
    'stepOrdinal': {
        '1': 'st',
        '2': 'nd',
        '3': 'rd',
        'th': 'th',
    },
    'futureDate': 'Date cannot be in the future',
    'totalMileage': 'Value cannot exceed current mileage',
};

export default validationError;
