import React from 'react';
import { Translate } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FlexGroup from 'components/FlexGroup';
import { Button } from 'reactstrap';
import Input, { TYPE_FILE, } from 'components/Form/Input';
import { exportFile } from 'helpers/util';
import api from 'api';
import './userlist-input-file.scss';

export default class UserListInputFile extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        caption: PropTypes.string,
        viewMode: PropTypes.bool,
        required: PropTypes.bool,
        fileType: PropTypes.number,
        fileName: PropTypes.string,
        value: PropTypes.string,
        id: PropTypes.string,
        onChange: PropTypes.func,
    }

    static defaultProps = {
        name: undefined,
        caption: undefined,
        viewMode: false,
        required: false,
        value: undefined,
        id: undefined,
        fileType: undefined,
        fileName: undefined,
        onChange: undefined,
    }

    handleDownload = async () => {
        const { id, fileName, fileType: file_type } = this.props;
        const { data } = await api.downloadUserListFile(id, { file_type })();
        const { url } = data;
        exportFile({
            source: url,
            name: fileName
        });
    }

    render() {
        const { name, viewMode, required, value, caption, onChange } = this.props;
        const captionClass = classNames('caption', { 'required': !viewMode });

        return (
            <FlexGroup className="userlist-input-file" row start alignStart>
                <h5 className={ captionClass }>{ caption }</h5>
                { !viewMode ? (
                    <Input
                        accept=".csv"
                        name={ name }
                        type={ TYPE_FILE }
                        value={ value }
                        viewMode={ false }
                        onChange={ onChange }
                        required={ required }
                    />
                ) : (
                    <Button type="button" color="primary" onClick={ this.handleDownload }>
                        <Translate value="download" />
                    </Button>
                ) }
            </FlexGroup>
        );
    }
}