import React from 'react';
import PropTypes from 'prop-types';
import Blocker from 'components/Blocker';
import { Button } from 'reactstrap';
import iconClose from 'assets/icon-header-cancel.svg';

import './modal.scss';

const Component = ({
    // Content
    children,
    // Specific style
    className,
    // title of this modal
    title,
    // Disable close modal by clicking on background (Default: false)
    disabledCloseOnBg,
    disableCloseBtn,
    // *Event*: close modal
    onClose,
    onContextMenu,
}) => {
    const handleClose = e => {
        e.preventDefault();
        e.stopPropagation();

        onClose(e);
    };

    return (
        <Blocker
            onClick={ disabledCloseOnBg ? () => {} : handleClose }
            className={ className }
        >
            <div className="modal-box" onContextMenu={ onContextMenu }>
                <h2 className="modal-title">
                    { title }
                    { !disableCloseBtn && (
                        <Button className="btn-close" onClick={ handleClose }>
                            <img src={ iconClose } alt="Close This Modal" />
                        </Button>
                    ) }
                </h2>
                <div className="modal-body">
                    { children }
                </div>
            </div>
        </Blocker>
    );
};

Component.propTypes = {
    onContextMenu: PropTypes.func,
    onClose: PropTypes.func,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({})
    ]),
    className: PropTypes.string,
    disabledCloseOnBg: PropTypes.bool,
    disableCloseBtn: PropTypes.bool,
};

Component.defaultProps = {
    onContextMenu: () => {},
    onClose: () => {},
    title: '',
    className: '',
    disabledCloseOnBg: false,
    disableCloseBtn: false,
};

export default Component;
