import {
    MAIN_TYPE_SWAP,
    MAIN_TYPE_DISPATCH,
    MAIN_TYPE_MAINTENANCE,
    MAIN_TYPE_OTHERS,

    SUB_TYPE_101,
    SUB_TYPE_102,
    SUB_TYPE_201,
    SUB_TYPE_202,
    SUB_TYPE_203,
    SUB_TYPE_204,
    SUB_TYPE_205,
    SUB_TYPE_206,
    SUB_TYPE_207,
    SUB_TYPE_213,
    SUB_TYPE_301,
    SUB_TYPE_302,
    SUB_TYPE_303,
    SUB_TYPE_304,
    SUB_TYPE_305,
    SUB_TYPE_401,
    SUB_TYPE_402,
    SUB_TYPE_403,
    SUB_TYPE_404,
    SUB_TYPE_405,
    SUB_TYPE_406,
    SUB_TYPE_407,
    SUB_TYPE_408,
    SUB_TYPE_409,
    SUB_TYPE_410,
    SUB_TYPE_411,
    SUB_TYPE_412,
    SUB_TYPE_413,
    SUB_TYPE_414,
    SUB_TYPE_415,
    SUB_TYPE_416,
    SUB_TYPE_417,
    SUB_TYPE_418,
    SUB_TYPE_419,
    SUB_TYPE_420,
    SUB_TYPE_421,

    STATUS_OPEN,
    STATUS_RECEIVED,
    STATUS_CHECKED,
    STATUS_IN_PROGRESS,
    STATUS_RESOLVED,
    STATUS_IN_QC,
    STATUS_DONE_QC,
    STATUS_BLOCKED_OTHERS,
    STATUS_BLOCKED_SHORT_OF_MATERIAL,
    STATUS_Blocked_TECH,
    STATUS_CLOSED,
    STATUS_CLOSED_NEW_TICKET,

    SEVERITY_LOW,
    SEVERITY_MEDIUM,
    SEVERITY_HIGH,
} from 'constants/ticket';

const ticket = {
    'new': '新增工單',
    'conditional': '條件式開單',
    'tickets': '工單',
    'scooters': '車輛',
    'main_title': '工單管理',
    'view_title': '工單資訊',
    'scooter_title': '車輛管理',
    'my_ticket': '我的工單',
    'ticket': '工單',

    'main_type': '類型',
    [`main_type_${ MAIN_TYPE_SWAP }`]: '換電',
    [`main_type_${ MAIN_TYPE_DISPATCH }`]: '調度',
    [`main_type_${ MAIN_TYPE_MAINTENANCE }`]: '維修',
    [`main_type_${ MAIN_TYPE_OTHERS }`]: '其它',
    'sub_type': '子類型',
    [`sub_type_${ SUB_TYPE_101 }`]: '低電換電',
    [`sub_type_${ SUB_TYPE_102 }`]: '浮動換電',
    [`sub_type_${ SUB_TYPE_201 }`]: '維修調度',
    [`sub_type_${ SUB_TYPE_202 }`]: '完修調度',
    [`sub_type_${ SUB_TYPE_203 }`]: '道路投放',
    [`sub_type_${ SUB_TYPE_204 }`]: '專案調度',
    [`sub_type_${ SUB_TYPE_205 }`]: '界外調度',
    [`sub_type_${ SUB_TYPE_206 }`]: '冷熱調度',
    [`sub_type_${ SUB_TYPE_207 }`]: '拖吊場調度',
    [`sub_type_${ SUB_TYPE_213 }`]: '新車投放',
    [`sub_type_${ SUB_TYPE_301 }`]: '一般維修',
    [`sub_type_${ SUB_TYPE_302 }`]: '小型維修',
    [`sub_type_${ SUB_TYPE_303 }`]: '大型維修',
    [`sub_type_${ SUB_TYPE_304 }`]: '例行保養',
    [`sub_type_${ SUB_TYPE_305 }`]: '中型維修',
    [`sub_type_${ SUB_TYPE_401 }`]: '百配',
    [`sub_type_${ SUB_TYPE_402 }`]: '卡彈',
    [`sub_type_${ SUB_TYPE_403 }`]: '協尋車輛',
    [`sub_type_${ SUB_TYPE_404 }`]: '拖吊',
    [`sub_type_${ SUB_TYPE_405 }`]: 'Shutdown',
    [`sub_type_${ SUB_TYPE_406 }`]: '遺失物',
    [`sub_type_${ SUB_TYPE_407 }`]: '移車',
    [`sub_type_${ SUB_TYPE_408 }`]: '關車廂',
    [`sub_type_${ SUB_TYPE_409 }`]: '鎖後照鏡',
    [`sub_type_${ SUB_TYPE_410 }`]: '修充電板',
    [`sub_type_${ SUB_TYPE_411 }`]: 'SOP檢查',
    [`sub_type_${ SUB_TYPE_412 }`]: 'RD',
    [`sub_type_${ SUB_TYPE_413 }`]: 'MKT',
    [`sub_type_${ SUB_TYPE_414 }`]: '行照變更',
    [`sub_type_${ SUB_TYPE_415 }`]: '主管交辦',
    [`sub_type_${ SUB_TYPE_416 }`]: 'CS釐清',
    [`sub_type_${ SUB_TYPE_417 }`]: '多任務',
    [`sub_type_${ SUB_TYPE_418 }`]: '監控',
    [`sub_type_${ SUB_TYPE_419 }`]: '特殊換電',
    [`sub_type_${ SUB_TYPE_420 }`]: '特殊調度',
    [`sub_type_${ SUB_TYPE_421 }`]: '清潔消毒',

    'status': '狀態',
    [`status_${ STATUS_OPEN }`]: '任務指派',
    [`status_${ STATUS_RECEIVED }`]: '收車完畢',
    [`status_${ STATUS_CHECKED }`]: '初檢完成',
    [`status_${ STATUS_IN_PROGRESS }`]: '任務執行',
    [`status_${ STATUS_RESOLVED }`]: '維修完畢',
    [`status_${ STATUS_IN_QC }`]: '開始 QC',
    [`status_${ STATUS_DONE_QC }`]: '結束 QC',
    [`status_${ STATUS_BLOCKED_OTHERS }`]: '任務受阻',
    [`status_${ STATUS_BLOCKED_SHORT_OF_MATERIAL }`]: '任務受阻-待料',
    [`status_${ STATUS_Blocked_TECH }`]: '任務受阻-技術',
    [`status_${ STATUS_CLOSED }`]: '任務完成',
    [`status_${ STATUS_CLOSED_NEW_TICKET }`]: '任務完成-另開新單',

    [`severity_${ SEVERITY_LOW }`]: '低',
    [`severity_${ SEVERITY_MEDIUM }`]: '中',
    [`severity_${ SEVERITY_HIGH }`]: '高',

    'assignee': '指派',
    'due_time': '到期日',
    'import_plate_list': '匯入條件式檔案',
    'priority': '優先權',
    'create_time': '開立時間',
    'description': '描述',
    'destination': '目的地',
    'dms_no': 'DMS No.',
    'mega_center': '維修中心',
    'update_time': '更新時間',
    'reporter': '建立人',
    'general_error_message': '哦喔! 好像發生問題了，請再試一次',
    'specific_tickets_error_message': '哦喔! (%{tickets}) 好像發生問題了，請再試一次',
    'unclosed_tickets_error_message': '切換狀態"任務完成"已成功，此車輛有未完成的工單，仍無法上線',
    'please_check_scooter_error_message': '請檢查車輛狀況',
    'conditional_modal_title': '條件式開單',
    'conditional_no_intersection_error_message': '查無交集車輛',
    'conditional_scooters_overflow_error_message': '條件式檔案內車輛數量不在1~5000內',
    'conditional_scooters_empty_error_message': '條件式檔案為空白',

    'conditional_result_imported_scooters_amount': '匯入車輛總數:',
    'conditional_result_unqualified_scooters_amount': '不符開立資格數:',
    'conditional_result_tickets_amount': '開立工單數:',
    'conditional_result_repeated_scooters': '重複車輛數:',

    'batch_edit': '批次修改',
    'comment': '留言',
    'whether_modify_description': '修改描述',
    'ticket_no': '工單編號',
    'display_map': '顯示地圖',
    'edit_ticket_info': '編輯工單資訊',

    'details': '詳細資料',
    'log': '歷程記錄',

    'log_sentence': '<strong>%{field}</strong> 從 <em>%{from}</em> 改成 <em>%{to}</em>',
    'number_constraint_of_ticket': '選擇的工單請勿多於 200 張',
    'flow': '工單流程',

    'maintenance_subtitle': '有無定保',
    'maintenance_mileage': '定保里程數',
    'maintenance_date': '定保日',
    'maintenance_title': '定期保養',
};

export default ticket;
