import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, CardBody } from 'reactstrap';
import { I18n } from 'react-redux-i18n';
import ControlTemplate from './ControlTemplate';
import PaymentStatus from 'components/Customer/PaymentStatus';
import PaymentMethod from 'components/Customer/PaymentMethod';
import Location from 'components/Scooter/Location';
import { convertDistance, convertDuration } from 'helpers/util';
import BatteryCapacity from 'components/BatteryCapacity';
import RentalPhotoAttachment from 'components/RentalPhotoAttachment';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import Input, {
    TYPE_TEXT,
    TYPE_DATETIME,
} from 'components/Form/Input';
import {
    PLAN_BASIC,
    PLAN_CORPORATE,
    CORPORATE_TYPE_NORMAL,
    CORPORATE_TYPE_BASIC,
    CORPORATE_TYPE_VIP,
} from 'constants/customer';
import { PAYMENT_CHANNEL_TAPPAY } from 'constants/rental';

class DetailsSection extends Component {
    static propTypes = {
        data: PropTypes.shape({}),
    };
    static defaultProps = {
        data: {},
    };

    render() {
        const { data } = this.props;
        const {
            payment_status,
            payment_method,
            customer_id,
            plate_no,
            model,
            model_display_name,
            credit_card_no,
            rental_id,
            payment_channel,
            order_id,
            invoice_no,
            corporate_type,
            // corporate
            sub_total,
            discount,
            mercury_discount,
            charged,
            lbs_discount,
            lbs_promotion_title,
            coupon_title,
            refund_price,
            refund_coupon_title,
            reserve_time,
            start_time,
            end_time,
            trip_start_lng,
            trip_start_lat,
            trip_end_lng,
            trip_end_lat,
            trip_start_soc,
            trip_end_soc,
            duration,
            distance,
            allowance,
            battery_swap_count,
            is_rental_photo_exist,
            mercury_title,
            gov_addi_purchase_discount,
            points_discount,
            refund_points,
            refund_sub_total,
            ecu_type,
            pause_duration,
            ride_duration,
            // Day pass
            user_pass_id,
            user_pass_valid_from,
            user_pass_valid_to,
            user_pass_accumulate_distance,
            user_pass_over_time_duration,
            user_pass_over_mileage_distance,
            __responseTime,
        } = data;

        const passOverStyle = classNames({
            'over-pass': user_pass_id && !!user_pass_over_time_duration,
        });

        const passOverMileageStyle = classNames({
            'over-pass': !!user_pass_over_mileage_distance,
        });

        const planType = [
            CORPORATE_TYPE_BASIC,
            CORPORATE_TYPE_VIP,
        ].includes(corporate_type) ? I18n.t(`customer.plan_${PLAN_CORPORATE}`) : I18n.t(`customer.plan_${PLAN_BASIC}`);
        const corporateType = [
            CORPORATE_TYPE_NORMAL,
            CORPORATE_TYPE_BASIC,
            CORPORATE_TYPE_VIP,
        ].indexOf(corporate_type) >= 0 ? I18n.t(`customer.corporate_type_${corporate_type}`) : undefined;
        const NetSocChangeField = ({ start, end }) => {
            const isStartDefined = typeof start === 'number';
            const isEndDefined =  typeof end === 'number';
            const el = isStartDefined && isEndDefined ? (
                <ControlTemplate caption="rental.net_soc_change">
                    <BatteryCapacity capacity={ end - start } ecuType={ ecu_type } />
                </ControlTemplate>
            ) : null;

            return el;
        };
        const DiscountField = ({ value, coupon }) => {
            const isValueDefined = typeof value === 'number';
            const isCouponDefined = typeof coupon === 'string';

            return (isValueDefined && isCouponDefined) ? (
                <CustomInput
                    caption="rental.discount"
                    value={ `${ value } (${ coupon })` }
                />
            ) : null;
        };
        const RefundField = ({ total, coupon, reward, price }) => {
            const isPriceDefined = typeof price === 'number';
            const isCouponDefined = typeof coupon === 'string';
            const hasReward = (reward > 0);
            let refundDetail = null;

            if (hasReward) {
                refundDetail = (
                    <CustomInput
                        caption="rental.refunded"
                        value={ `${ total } (${ price }; ${ I18n.t('rental.gogoro_rewards') }: ${ reward })` }
                    />
                );
            }
            else if (isPriceDefined) {
                refundDetail = (
                    <CustomInput
                        caption="rental.refunded"
                        value={ price }
                    />
                );
            }
            else if (isCouponDefined) {
                refundDetail = (
                    <CustomInput
                        caption="rental.compensated"
                        value={ coupon }
                    />
                );
            }

            return refundDetail;
        };

        const CardTemplate = ({ children, className, title }) => (
            <Card>
                <CardBody>
                    <Form tag="section" inline>
                        <FormGroup className={ className } title={ title }>
                            { children }
                        </FormGroup>
                    </Form>
                </CardBody>
            </Card>
        );
        const CustomInput = ({ caption, value, type = TYPE_TEXT, assignedStyle, unitCaption }) => (
            <Input
                assignedStyle={ assignedStyle }
                type={ type }
                caption={ caption }
                value={ [undefined, null].indexOf(value) === -1 ? value : '--' }
                unitCaption={ unitCaption}
                viewMode
            />
        );


        return (
            <React.Fragment>
                <section className="cards">
                    <div className="column">
                        <CardTemplate className="id-information-sec" title={ I18n.t('rental.information') }>
                            <ControlTemplate caption="payment_status">
                                <PaymentStatus badge status={ payment_status } />
                            </ControlTemplate>
                            <CustomInput
                                caption="customer.customer_no"
                                value={ customer_id }
                            />
                            <CustomInput
                                caption="scooter.plate"
                                value={ plate_no }
                            />
                            <CustomInput
                                caption="scooter.model"
                                value={ model ? `${ model } (${ model_display_name || '--' })` : undefined }
                            />
                            <CustomInput
                                caption="credit_card_no"
                                value={ credit_card_no }
                            />
                            <ControlTemplate caption="payment_method">
                                <PaymentMethod status={ payment_method } />
                            </ControlTemplate>
                            <CustomInput
                                caption="rental.rental_no"
                                value={ rental_id }
                            />
                            <CustomInput
                                caption={ I18n.t(`rental.payment_channel.${ payment_channel ?? PAYMENT_CHANNEL_TAPPAY}`) }
                                value={ order_id }
                            />
                            <CustomInput
                                caption="rental.invoice_no"
                                value={ invoice_no }
                            />
                        </CardTemplate>
                        <CardTemplate className="plan-sec" title={ I18n.t('customer.plan') }>
                            <CustomInput
                                caption="customer.plan"
                                value={ planType }
                            />
                            <CustomInput
                                caption="customer.corporate_type"
                                value={ corporateType }
                            />
                        </CardTemplate>
                        <CardTemplate className="bill-sec" title={ I18n.t('rental.billing') }>
                            <CustomInput
                                caption="rental.subtotal"
                                value={ sub_total }
                            />
                            {
                                (lbs_discount && lbs_promotion_title) && (
                                    <CustomInput
                                        caption="rental.area_discount"
                                        value={ `${lbs_discount} (${lbs_promotion_title})` }
                                    />
                                )
                            }
                            <DiscountField value={ discount } coupon={ coupon_title } />
                            { typeof allowance === 'number' && (
                                <CustomInput
                                    caption="rental.allowance"
                                    value={ allowance }
                                />
                            ) }
                            { mercury_title && (
                                <CustomInput
                                    caption={ mercury_title }
                                    value={ mercury_discount }
                                />
                            ) }
                            <CustomInput
                                caption="rental.charged"
                                value={ charged }
                            />
                            <RefundField
                                total={ refund_sub_total }
                                coupon={ refund_coupon_title }
                                price={ refund_price }
                                reward={ refund_points }
                            />
                            { gov_addi_purchase_discount && (
                                <CustomInput
                                    caption="rental.purchase_discount"
                                    value={ gov_addi_purchase_discount }
                                />
                            ) }
                            <CustomInput
                                caption="rental.gogoro_rewards"
                                value={ points_discount }
                            />
                        </CardTemplate>
                        {
                            user_pass_id ? (
                                <CardTemplate className="day-pass-sec" title={ I18n.t('rental.daypass') }>
                                    <CustomInput
                                        type={ TYPE_DATETIME }
                                        caption="rental.daypass_valid_from"
                                        value={ user_pass_valid_from }
                                    />
                                    <CustomInput
                                        assignedStyle={ passOverStyle }
                                        type={ TYPE_DATETIME }
                                        caption="rental.daypass_valid_to"
                                        value={ user_pass_valid_to }
                                    />
                                    <CustomInput
                                        assignedStyle="update-over-data"
                                        type={ TYPE_DATETIME }
                                        caption="data_update_time"
                                        value={ __responseTime }
                                    />
                                    <CustomInput
                                        assignedStyle={ passOverStyle }
                                        caption="rental.daypass_over_time"
                                        value={ convertDuration(user_pass_over_time_duration) }
                                    />
                                    <CustomInput
                                        assignedStyle={ passOverMileageStyle }
                                        caption="rental.daypass_over_mileage"
                                        value={ convertDistance(user_pass_over_mileage_distance) }
                                        unitCaption={ `/ (${ I18n.t('rental.daypass_acc_distance') }: ${ convertDistance(user_pass_accumulate_distance) })` }
                                    />
                                </CardTemplate>
                            ) : null
                        }
                    </div>
                    <div className="column">
                        <CardTemplate className="travel-sec" title={ I18n.t('rental.time') }>
                            <CustomInput
                                type={ TYPE_DATETIME }
                                caption="reservation_time"
                                value={ reserve_time }
                            />
                            <CustomInput
                                type={ TYPE_DATETIME }
                                caption="start_time"
                                value={ start_time }
                            />
                            <CustomInput
                                type={ TYPE_DATETIME }
                                caption="end_time"
                                value={ end_time }
                            />
                        </CardTemplate>
                        <CardTemplate title={ I18n.t('from') }>
                            <ControlTemplate caption="scooter.battery">
                                <BatteryCapacity capacity={ trip_start_soc } ecuType={ ecu_type } />
                            </ControlTemplate>
                            <ControlTemplate caption="scooter.location">
                                <Location location={ {
                                    lat: trip_start_lat, lng: trip_start_lng,
                                } }
                                />
                            </ControlTemplate>
                        </CardTemplate>
                        <CardTemplate title={ I18n.t('to') }>
                            <ControlTemplate caption="scooter.battery">
                                <BatteryCapacity capacity={ trip_end_soc } ecuType={ ecu_type } />
                            </ControlTemplate>
                            <ControlTemplate caption="scooter.location">
                                <Location location={ {
                                    lat: trip_end_lat, lng: trip_end_lng,
                                } }
                                />
                            </ControlTemplate>
                        </CardTemplate>
                        <CardTemplate title={ I18n.t('rental.trip') }>
                            <CustomInput
                                caption="duration"
                                value={ convertDuration(duration) }
                            />
                            <CustomInput
                                caption="distance"
                                value={ convertDistance(distance) }
                            />
                            <NetSocChangeField start={ trip_start_soc } end={ trip_end_soc } />
                            <CustomInput
                                caption="rental.battery_swap_count"
                                value={ battery_swap_count }
                            />
                            <CustomInput
                                caption="rental.photo"
                                value={ is_rental_photo_exist ? (
                                    <RentalPhotoAttachment rentalId={ rental_id } />
                                ) : undefined  }
                            />
                            <CustomInput
                                caption="rental.pause_duration"
                                value={ convertDuration(pause_duration) }
                            />
                            <CustomInput
                                caption="rental.ride_duration"
                                value={ convertDuration(ride_duration) }
                            />
                        </CardTemplate>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default DetailsSection;
