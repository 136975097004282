import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoCard from 'components/InfoCard';
import DashInfo from 'components/DashInfo';
import Gauge from 'components/Chart/Gauge';
import AnimatedText from 'components/AnimatedText/AnimatedText';
import TimeCounter from '../TimeCounter/TimeCounter';
import { Translate, I18n } from 'react-redux-i18n';
import { showServiceLevelWarning, getServiceLevelGaugeLabel } from 'helpers/dashboard';
import {
    DASH_COLOR_YELLOW,
    DASH_COLOR_WHITE,
} from 'constants/dashboard';
import { CHART_FONT_STYLE_BOLD } from 'constants/chart';
import { SERVICE_TYPE_FREE_FLOATING, CITY_OTHERS } from 'constants/scooter';
import { TV_MONITOR_SCALE } from 'constants/tvMonitor';

import './tv-overall-service-level.scss';

const OVERALL_SL_CHART_SIZE = +( 390 / TV_MONITOR_SCALE ).toFixed(1);
const OVERALL_SERVICE_LEVEL_THICK = +( 30 / TV_MONITOR_SCALE ).toFixed(1);

const TITLE_STYLE = `"
    font-size: ${ 84 / TV_MONITOR_SCALE }px;
    font-weight: bold;
    font-family: ${ CHART_FONT_STYLE_BOLD };
"`;
const UNIT_STYLE = `"
        font-size: ${ 60 / TV_MONITOR_SCALE };
        font-weight: bold;
        font-family: ${ CHART_FONT_STYLE_BOLD };
    "`;

const TIME_COUNT_INTERVAL = 1;

const TVOverallServiceLevel = ({ title, className, overall, list, listCaption }) => {

    const cardStyle = classNames({
        'tv-overall-SL': true,
        [className]: true,
    });

    const chartStyle = classNames({
        'title': true,
        'warning': showServiceLevelWarning(overall.service_level),
    });

    const getListCaption = item => {
        const caption = ( listCaption && typeof listCaption === 'function') ? listCaption(item) : listCaption;
        return I18n.t(caption);
    };

    const renderInfoList = item => {
        const {
            service_level,
            city_id,
        } = item;
        const caption = getListCaption(item) ;
        return (
            <DashInfo
                title={ caption }
                value={ service_level }
                hasPercentage
                showWarning={ showServiceLevelWarning(service_level) }
                key={ `${service_level}${city_id}` }
            >
                <AnimatedText
                    value={ service_level }
                    size={ 60 / TV_MONITOR_SCALE }
                />
            </DashInfo>
        );
    };

    const getFreeFloatingWithoutOthers = cities => {
        return cities.filter(({ city_id, service_type }) => (
            service_type === SERVICE_TYPE_FREE_FLOATING &&
            city_id !== CITY_OTHERS
        ));
    };
    const cities = getFreeFloatingWithoutOthers(list);

    return (
        <InfoCard className={ cardStyle }>
            <div className="info">
                <h2 className="title">{ title }</h2>
                {
                    (cities && cities.length) && cities.map(item => renderInfoList(item))
                }
            </div>
            <div className="chart">
                <div className="service-level-pie">
                    <Gauge
                        width={ OVERALL_SL_CHART_SIZE }
                        height={ OVERALL_SL_CHART_SIZE }
                        gaugeValue={ overall.service_level || 0 }
                        renderLabel={ getServiceLevelGaugeLabel(TITLE_STYLE, UNIT_STYLE) }
                        thick={ OVERALL_SERVICE_LEVEL_THICK }
                        color={ showServiceLevelWarning(overall.service_level) ? DASH_COLOR_YELLOW : DASH_COLOR_WHITE }
                    />
                    <Translate className={ chartStyle } value="dashboard.service_level" />
                </div>
                <TimeCounter refreshTime={ TIME_COUNT_INTERVAL } />
            </div>


        </InfoCard>
    );
};

TVOverallServiceLevel.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    overall: PropTypes.shape({}),
    list: PropTypes.arrayOf(PropTypes.shape({})),
    listCaption: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
    ]),
};

TVOverallServiceLevel.defaultProps = {
    title: '',
    className: '',
    overall: {},
    list: [],
    listCaption: '',
};

export default TVOverallServiceLevel;
