export const fullname = (first, last) => {
    let names = [];
    if (first) {
        names.push(first);
    }
    if (last) {
        names.push(last);
    }
    return names.join(' ');
};
