import React from 'react';
import PropTypes from 'prop-types';

import './blocker.scss';

const Component = ({ children, className, onClick }) => {
    const blockClassName = 'blocker';
    const handleClick = e => {
        if (e.target === e.currentTarget) {
            onClick(e);
        }
    };

    return (
        <div className={ `${ blockClassName } ${ className }` } onClick={ handleClick }>
            { children }
        </div>
    );
};

Component.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
};

Component.defaultProps = {
    onClick: () => {},
    className: '',
};

export default Component;
