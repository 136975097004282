import React from 'react';
import { connect } from 'react-redux';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import serialize from 'form-serialize';
import { Button } from 'reactstrap';
import Form from 'components/Form';
import Input, {
    TYPE_SELECT,
} from 'components/Form/Input';
import permissionHandler from 'helpers/permission-handler';
import { toogleNavigation } from 'actions';
import {
    MY_TICKET,
    SCOOTER_LIST,
    TICKET_LIST,
} from 'constants/routes';
import {
    AUTH_GET_TICKET_LIST,
    AUTH_GET_TICKET_SELF_LIST,
    AUTH_VIEW_SCOOTER_LIST
} from 'constants/permission';
import './popup-navigation.scss';

class PopupNavigation extends React.Component {
    static propTypes = {
        showNavigation: PropTypes.bool.isRequired
    }

    constructor() {
        super();
        this.state = {
            redirectTo: undefined
        };
    }

    componentDidUpdate() {
        const { redirectTo } = this.state;
        const { dispatch } = this.props;

        if (redirectTo) {
            this.setState({
                redirectTo: undefined
            });
            dispatch(toogleNavigation());
        }
    }

    handleSubmit = (e) => {
        const { redirectTo } = serialize(e.currentTarget, { hash: true });
        this.setState({
            redirectTo
        });
    }

    render() {
        const { showNavigation } = this.props;
        const { redirectTo } = this.state;
        const options = [];
        if (permissionHandler({ requiredList: [AUTH_GET_TICKET_LIST] })) {
            options.push({
                name: I18n.t(`routes.${ TICKET_LIST }`),
                value: TICKET_LIST
            });
        }

        if (permissionHandler({ requiredList: [AUTH_VIEW_SCOOTER_LIST] })) {
            options.push({
                name: I18n.t(`routes.${ SCOOTER_LIST }`),
                value: SCOOTER_LIST
            });
        }

        if (permissionHandler({ requiredList: [AUTH_GET_TICKET_SELF_LIST] })) {
            options.push({
                name: I18n.t(`routes.${ MY_TICKET }`),
                value: MY_TICKET
            });
        }

        return showNavigation && (
            <Modal title={ I18n.t('without_permission_error_message') } disableCloseBtn disabledCloseOnBg>
                <Form className="navigation-form" inline onSubmit={ this.handleSubmit }>
                    <Input
                        type={ TYPE_SELECT }
                        name="redirectTo"
                        caption="jump_to"
                        value={ options }
                        selected={ options[0].value }
                    />
                    <Button type="submit" color="primary">
                        <Translate value="yes" />
                    </Button>
                </Form>
                { redirectTo && (
                    <Redirect to={ redirectTo } />
                ) }
            </Modal>
        );
    }
}

export default connect(state => ({
    showNavigation: state.general.get('showNavigation')
}))(PopupNavigation);
