import {
    STATUS_DISABLED,
    STATUS_DRAFT,
    STATUS_PUBLISHED,
    STATUS_LIVED,
    STATUS_EXPIRED,

    TRIP_COUNT,
    TRIP_DISTANCE,
    BATTERY_SWAP_COUNT,

    IN_APP_WEBVIEW,
    OS_DEFAULT_WEB_BROWSER,

    TITLE_NOT_VALID,
    WEB_URL_NOT_VALID,
    URL_LAUNCH_MODE_NOT_VALID,
    CRITERIA_TYPE_NOT_VALID,
    CRITERIA_VALUE_NOT_VALID,
    VALID_FROM_TO_NOT_VALID,
    REWARD_COUPON_ID_NOT_VALID,
    CANNOT_CHANGE_STATUS,
    STATUS_IS_PUBLISHED,
    MISSION_ORDER_STATUS_ERROR,
} from 'constants/mission';

const mission = {
    title: '標題',
    status: '狀態',
    search_for: '搜尋標題',
    rule_link: '規則連結網址',
    url_launch_mode: '開啟模式',
    valid_time: '有效時間',
    valid_from: '有效開始時間',
    valid_to: '有效結束時間',
    create_time: '建立時間',
    criteria_type: '任務條件',
    criteria_value: '任務達成目標',
    edit_mission_info: '編輯任務',
    details: '詳細資料',
    publish: '發佈',
    withdraw: '撤銷',
    view_title: '任務資訊',
    publish_time: '發佈時間',
    publisher: '發佈人',
    [`status_${ STATUS_DISABLED }`]: '停用',
    [`status_${ STATUS_DRAFT }`]: '草案',
    [`status_${ STATUS_PUBLISHED }`]: '已發佈',
    [`status_${ STATUS_LIVED }`]: '上線',
    [`status_${ STATUS_EXPIRED }`]: '過期',
    [`criteria_type_${ TRIP_COUNT }`]: '騎乘次數',
    [`criteria_type_${ TRIP_DISTANCE }`]: '騎乘距離',
    [`criteria_type_${ BATTERY_SWAP_COUNT }`]: '低電量換電次數',
    [`url_launch_mode_${ IN_APP_WEBVIEW }`]: '應用程式內嵌網頁',
    [`url_launch_mode_${ OS_DEFAULT_WEB_BROWSER }`]: '裝置預設瀏覽器',
    [`error_message_${ TITLE_NOT_VALID }`]: '無效的標題',
    [`error_message_${ WEB_URL_NOT_VALID }`]: '無效的規則連結網址',
    [`error_message_${ URL_LAUNCH_MODE_NOT_VALID }`]: '無效的開啟模式',
    [`error_message_${ CRITERIA_TYPE_NOT_VALID }`]: '無效的 Criteria',
    [`error_message_${ CRITERIA_VALUE_NOT_VALID }`]: '無效的 Criteria value',
    [`error_message_${ VALID_FROM_TO_NOT_VALID }`]: '無效的任務有效時間',
    [`error_message_${ REWARD_COUPON_ID_NOT_VALID }`]: '無效的系統折扣',
    [`error_message_${ CANNOT_CHANGE_STATUS }`]: '無法切換狀態',
    [`error_message_${ STATUS_IS_PUBLISHED }`]: '已發佈，無法修改內容',
    new_mission: '新增任務',
    change_order: '任務排序',
    order_document_title: '任務排序',
    brief_intro: '請拖曳表格列來改變任務順序',
    [`mission_order_error_${ MISSION_ORDER_STATUS_ERROR }`]:'任務狀態已變更，請重新排序',
    confirm_order_change: '確認調整',
    confirm_order_change_describe: '確定套用本次調整嗎？',
    continuely_edit: '繼續編緝',
};

export default mission;
