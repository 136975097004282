import React from 'react';
import PropTypes from 'prop-types';
import ListView from 'components/ListView';
import ListRow from './ListRow';
import headerConfigFor from './ListHeader';
import mobile from 'is-mobile';

export const ADDITIONAL_FEE_FULL = 0;
export const ADDITIONAL_FEE_CUSTOMER = 1;

const isMobile = mobile();
const ListRowGroup = ({ columns, rowData }) => (
    <ListRow
        key={ `additionalFee-${ rowData.id }` }
        columns={ columns }
        data={ rowData }
    />
);

ListRowGroup.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    rowData: PropTypes.shape({
        expense_id: PropTypes.string.isRequired,
        customer_id: PropTypes.string,
        rental_id: PropTypes.string,
        claim_type: PropTypes.number,
        fee_type: PropTypes.number,
        subtype: PropTypes.number,
        plate_no: PropTypes.string,
        ticket_no: PropTypes.string,
        amount: PropTypes.number,
        create_time: PropTypes.string,
        expired_time: PropTypes.string,
        status: PropTypes.number,
    }).isRequired,
};


const AdditionalFeeTableList = ({ type, onSort, history }) => {
    const columns = headerConfigFor(type);
    return (
        <ListView
            header={ columns }
            dataKey="expense_id"
            list={ history }
            renderListRow={ ListRowGroup }
            onSort={ onSort }
            fixed={ isMobile }
        />
    );
};
AdditionalFeeTableList.propTypes = {
    history: PropTypes.arrayOf(
        PropTypes.shape()
    ),
    onSort: PropTypes.func,
    type: PropTypes.oneOf([
        ADDITIONAL_FEE_FULL,
        ADDITIONAL_FEE_CUSTOMER,
    ]),
};
AdditionalFeeTableList.defaultProps = {
    history: [],
    onSort: () => {},
    type: ADDITIONAL_FEE_FULL,
};

export default AdditionalFeeTableList;
