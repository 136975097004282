import React from 'react';
import AuthView from 'components/AuthView';
import permissionHandler from 'helpers/permission-handler';
import { Redirect } from 'react-router-dom';
import { DASHBOARD } from 'constants/routes';
import {
    AUTH_VIEW_DASHBOARD,
} from 'constants/permission';

function Welcome() {
    const goToDashboard = permissionHandler({ requiredList: [AUTH_VIEW_DASHBOARD] });
    return (
        <React.Fragment>
            {
                goToDashboard ?
                    <Redirect to={ DASHBOARD } /> :
                    (
                        <AuthView checkPermission={ false }>
                            <section>
                                <h1>歡迎</h1>
                                <h2>Welcome</h2>
                            </section>
                        </AuthView>
                    )
            }
        </React.Fragment>);
}

export default Welcome;
