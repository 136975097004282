import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import MultiselectFilter from 'components/Filter/MultiselectFilter';
import {
    MAIN_TYPE_GROUP,
} from 'constants/ticket';

const CustomTypeFilter = ({ selected, onChange }) => {
    const options = MAIN_TYPE_GROUP.map(type => ({
        value: type,
        text: I18n.t(`ticket.main_type_${ type }`),
    }));

    return (
        <MultiselectFilter
            title={ I18n.t('ticket.main_type') }
            options={ options }
            defaultSelected={ selected }
            onChange={ onChange }
        />
    );
};
CustomTypeFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.number),
};
CustomTypeFilter.defaultProps = {
    selected: [],
};

export default CustomTypeFilter;
