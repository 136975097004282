import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, } from 'immutable';
import { Input, Label } from 'reactstrap';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getVipLayer, selectVipLayer } from 'actions';
import { ToolButton } from './index';

class VipLayer extends Component {
    static propTypes = {
        vips: PropTypes.oneOfType([
            PropTypes.object,
        ]),
        business_ids: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        vips: List([]),
        business_ids: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            enabled: false,
            openVip: false,
            selectedLayer: null,
        };
        this.elVip = React.createRef();
    }

    async componentDidMount() {
        const { dispatch } = this.props;
        await dispatch(selectVipLayer([{ service_ids: [] }]));
        await this.showSelectVipLayer();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        const { business_ids } = this.props;
        const { selectedLayer } = this.state;
        if ((JSON.stringify(prevProps.business_ids) !== JSON.stringify(business_ids)) && selectedLayer !== null) {
            this.setState({ openVip: false }, async () => {
                await this.showSelectVipLayer();
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    toggleVipLayer = () => {
        const { dispatch, business_ids } = this.props;
        const { openVip } = this.state;
        if (!openVip && business_ids.length !== 0) {
            const service_id = [...new Set(business_ids)];
            return dispatch(getVipLayer({ service_id }))
                .then(() => {
                    this.setState(state => ({
                        openVip: !state.openVip
                    }));
                });
        }
        this.setState(state => ({
            openVip: !state.openVip
        }));
    };

    showSelectVipLayer = async () => {
        const { dispatch, business_ids } = this.props;
        const service_id = [...new Set(business_ids)];
        const { data } = await dispatch(getVipLayer({ service_id }));
        if (data) {
            let initialLayer = data[0];
            this.setState({ selectedLayer: [initialLayer] }, () => {
                const { selectedLayer } = this.state;
                dispatch(selectVipLayer(selectedLayer));
            });
        }
    }

    handleClickOutside = e => {
        const { openVip } = this.state;
        if (this.elVip.current !== null && !this.elVip.current.contains(e.target) && openVip) {
            this.setState({ openVip: false });
        }
    }

    handleChange = ({ service_ids, corporate_name }) => e => {
        const { dispatch } = this.props;
        if (e.currentTarget.checked) {
            this.setState(prevState => ({
                selectedLayer: [...prevState.selectedLayer, { service_ids, corporate_name }],
                openVip: true,
            }), () => {
                const { selectedLayer } = this.state;
                dispatch(selectVipLayer(selectedLayer));
            });
        }
        else {
            this.setState(prevState => {
                const list = prevState.selectedLayer
                    .filter(item => JSON.stringify(item.service_ids) !== JSON.stringify(service_ids));
                return {
                    selectedLayer: list,
                };
            }, () => {
                const { selectedLayer } = this.state;
                dispatch(selectVipLayer(selectedLayer));
            });
        }
    };

    renderVipOptions = () => {
        const { vips } = this.props;
        const { selectedLayer } = this.state;

        const vipOptions = vips.toJS();
        let defaultSelected = [];
        if (selectedLayer) {
            defaultSelected = selectedLayer.length === 1 && [selectedLayer[0].service_ids];
            if (selectedLayer.length !== 1 || selectedLayer[0].corporate_name !== 'GoShare') {
                defaultSelected = selectedLayer.map(({ service_ids }) => service_ids );
            }
        }

        return vipOptions.map(({ corporate_name, service_ids }) => {
            let checked = false;
            defaultSelected.forEach(item => {
                if (item.toString() === service_ids.toString()) {
                    checked = true;
                }
            });
            return (
                <Label className="filter-option" key={ service_ids }>
                    <Input
                        type="checkbox"
                        name={ corporate_name }
                        value={ service_ids }
                        defaultChecked={ checked }
                        onChange={
                            this.handleChange({ corporate_name, service_ids })
                        }
                    />
                    <div className="vip-text">{ corporate_name }</div>
                </Label>
            );
        });
    }

    render() {
        const { enabled, openVip } = this.state;
        const buttonIconStyle = classNames({
            'btn-vip': true,
            'enabled': enabled,
        });

        return (
            <div ref={ this.elVip }>
                <ToolButton className={ buttonIconStyle } onClick={ this.toggleVipLayer }>
                    <div />
                </ToolButton>
                { openVip && (
                    <div className="vip-panel">
                        <Translate className="vip-title" value="map_layer" />
                        <hr />
                        <div className="vip-item">
                            {
                                this.renderVipOptions()
                            }
                        </div>
                    </div>
                )
                }
            </div>
        );
    }
}

export default connect(state => ({
    locale: state.i18n.locale,
    vips: state.map.get('vips'),
}))(VipLayer);
