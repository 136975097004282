import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-redux-i18n';
import Toggle from 'react-toggle';
import FlexGroup from 'components/FlexGroup';
import './ticket-system.scss';

export const MODE_LIST = 'list';
export const MODE_MAP = 'map';

const MapToggle = ({ showMap, onToggle }) => {

    const defaultViewMode = showMap ? MODE_MAP : MODE_LIST;

    const mapIconCls = classNames({
        'map-toggle': true,
        'fas fa-list': defaultViewMode === MODE_MAP,
        'far fa-map': defaultViewMode === MODE_LIST,
    });
    return (
        <>
            <FlexGroup className="web-button">
                <Translate className="toggle-text" value="ticket.display_map" />
                <Toggle
                    checked={ showMap }
                    icons={ false }
                    onChange={ onToggle }
                />
            </FlexGroup>
            <div className="mobile-button">
                <button
                    type="button"
                    className={ mapIconCls }
                    onClick={ onToggle }
                />
            </div>
        </>
    );
};

MapToggle.propTypes = {
    showMap: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired
};

export default MapToggle;
