import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import {
    FEE_SUBTYPE_VIOLATE_ILLEGAL_PARKING,
    FEE_SUBTYPE_VIOLATE_NON_ILLEGAL_PARKING,
    FEE_SUBTYPE_CHARGE_PRIVATE_PARKING,
    FEE_SUBTYPE_CHARGE_TOWING_YARD,
    FEE_SUBTYPE_TOWING_ILLEGAL_PARKING,
    FEE_SUBTYPE_TOWING_TOWING_YARD,
    FEE_SUBTYPE_TOWING_OUTSIDE,
    FEE_SUBTYPE_TOWING_TRAFFIC_ACCIDENT,
    FEE_SUBTYPE_TOWING_DRUNK_DRIVING,
    FEE_SUBTYPE_REPAIR_CAR_DAMAGE,
    FEE_SUBTYPE_REPAIR_SUSPECTED_DAMAGE,
} from 'constants/additional-fee';

const Subtype = ({ type, className }) => {
    let el;
    switch (type) {
    case FEE_SUBTYPE_VIOLATE_ILLEGAL_PARKING:
    case FEE_SUBTYPE_VIOLATE_NON_ILLEGAL_PARKING:
    case FEE_SUBTYPE_CHARGE_PRIVATE_PARKING:
    case FEE_SUBTYPE_CHARGE_TOWING_YARD:
    case FEE_SUBTYPE_TOWING_ILLEGAL_PARKING:
    case FEE_SUBTYPE_TOWING_TOWING_YARD:
    case FEE_SUBTYPE_TOWING_OUTSIDE:
    case FEE_SUBTYPE_TOWING_TRAFFIC_ACCIDENT:
    case FEE_SUBTYPE_TOWING_DRUNK_DRIVING:
    case FEE_SUBTYPE_REPAIR_CAR_DAMAGE:
    case FEE_SUBTYPE_REPAIR_SUSPECTED_DAMAGE:
        el = <Translate value={ `additional_fee.fee_subtype_map.${ type }` } />;
        break;
    default:
        el = <span>--</span>;
    };
    return (
        <div className={ className }>
            { el }
        </div>
    );
};
Subtype.propTypes = {
    type: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    className: PropTypes.string,
};
Subtype.defaultProps = {
    type: undefined,
    className: undefined,
};
export default Subtype;
