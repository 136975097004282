import React from 'react';
import * as d3  from 'd3';
import debounce from 'lodash/debounce';
import mapShape from './taiwan.geojson';
import PropTypes from 'prop-types';
import activeDotsPin from 'assets/map/dashboard-dots-pin.svg';
import DotsPin from 'assets/map/pin-dot-small.svg';

const MAP_STYLE_CONFIG = {
    background: '#06f3f9',
    borderWidth: 10,
    borderColor: '#9BFAFC',
    linejoin: 'round',
    landColor: '#7DF9FC',
};

// Note: if pin size change (one of status), all clipPath setting string need to be update
const MAP_MARKER_CONFIG = {
    width: 31,
    height: 31,
    clipPathShape: 'circle(16px at 16px 16px) fill-box',
    activePin: {
        width: 48,
        height: 75,
        transBase: {
            x: 1260,
            y: 339,
        },
        clipPathShape: `M-1241.389-264.922v-1.686a1.686,1.686,0,0,1,
        1.686-1.686h1.687v-22.865a22.383,22.383,0,0,1-20.88-22.332,
        22.378,22.378,0,0,1,22.378-22.379,22.379,22.379,0,0,1,22.38,
        22.38,22.384,22.384,0,0,1-20.507,22.3v22.892h1.688a1.686,
        1.686,0,0,1,1.686,1.686v1.686Zm27.25-48.566h0Z`,
    },
    hoverPin: {
        scaleW: 1.1,
        // note: hoverScaleH = hoverScaleW * activePin.width / activePin.height
        scaleH: 2.43,
        transBase: {
            x: 0,
            y: 0,
        },
    },
    style: 'cursor: pointer;',
};

const PROJECTION_CONFIG = {
    mercatorScale: 70000,
    center: [121.67, 25.21],
    height: '100%',
};

const DOTS_OTHERS_UUID = '00000000-0000-0000-0000-000000000000';

class MapChart extends React.Component {
    static propTypes = {
        markerData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        width: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        height: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        onClick: PropTypes.func,
        selected: PropTypes.shape({}),
    }

    static defaultProps = {
        width: '',
        height: '',
        onClick: () => {},
        selected: undefined,
    }

    constructor(props) {
        super(props);
        this.elMap = React.createRef();
        this.elWrapper = React.createRef();
        this.elTooltip = React.createRef();
        this.elLandPath = undefined;
        this.debounceResize = debounce(this.resizeMap, 100, { trailing: true });
    }

    componentDidMount() {
        this.createMap();
        window.addEventListener('resize', this.debounceResize, false);

    }

    componentDidUpdate(prevProps) {
        const { selected } = this.props;

        if ((!prevProps.selected && selected?.zone_id) ||
            (prevProps.selected && prevProps.selected.zone_id !== selected.zone_id)) {

            this.updateActivePin(selected);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debounceResize, false);

    }

    createPins = (projection) => {
        const { markerData } = this.props;
        const data = markerData.filter( item => (item.dots_lat && item.dots_lng) || (item.zone === DOTS_OTHERS_UUID));

        const svg = d3.select(this.elMap.current);
        let pins = [];
        if (data && data.length) {

            pins =  svg.selectAll('circle')
                .data(data)
                .enter()
                .append('image')
                .attr('xlink:href', DotsPin)
                .attr('x', (d) => {
                    return projection([d.dots_lng, d.dots_lat])[0];
                })
                .attr('y', (d) => {
                    return projection([d.dots_lng, d.dots_lat])[1];
                })
                .attr('class', 'dots-pin')
                .attr('style', MAP_MARKER_CONFIG.style);

            this.handlePinStyle(pins);

            pins
                .on('mouseover', this.handleDotsPinMouseover)
                .on('mouseout', this.handleDotsPinMouseout)
                .on('click', this.handleDotsPinClick);
        }

        return pins;
    }

    createPinClipPath = () => {
        const { clipPathShape: activePath } = MAP_MARKER_CONFIG.activePin;
        const svg = d3.select(this.elMap.current);
        const defs = svg.append('defs');
        const activeClipPath = defs.append('clipPath');
        activeClipPath.attr('id', 'activePinClipPath');
        activeClipPath.append('path')
            .attr('transform', 'translate(1220 341)')
            .attr('d', activePath);
    }


    createMap = async() => {
        const { width, height } = this.elWrapper.current.getBoundingClientRect();
        const { borderWidth, landColor, borderColor, linejoin } = MAP_STYLE_CONFIG;
        const landData = await d3.json(mapShape);
        const svg = d3.select(this.elMap.current);
        const { mercatorScale, center } = PROJECTION_CONFIG;

        const projection = d3.geoMercator()
            .center(center)
            .scale(mercatorScale)
            .translate([ width / 2, height / 2.5 ]);

        const path = d3.geoPath().projection(projection);

        this.elLandPath = svg.attr('width', width)
            .attr('height', PROJECTION_CONFIG.height)
            .attr('viewBox', `0 0 ${width} ${height}`)
            .selectAll('path')
            .data(landData.features)
            .enter().append('path');

        this.elLandPath
            .attr('fill', landColor)
            .attr('stroke', borderColor)
            .attr('stroke-width', borderWidth)
            .attr('stroke-linejoin', linejoin)
            .attr('d', path);

        this.createPins(projection);
        this.createPinClipPath();
    }

    resizeMap = () => {
        if (!this.elWrapper.current) {
            return;
        }
        const { width } = this.elWrapper.current.getBoundingClientRect();
        const svg = d3.select(this.elMap.current);

        return svg.attr('width', width)
            .attr('height', PROJECTION_CONFIG.height);
    }

    toggleElTooltip = (isShow = false) => {
        const opacity = isShow ? 1 : 0;

        d3.select(this.elTooltip.current)
            .transition()
            .duration(200)
            .style('opacity', opacity);
    }

    moveToFront = (elPin) => {
        elPin.raise();
    }

    getOutofStation = () => {
        const { markerData } = this.props;

        return (markerData.filter( item => item.zone_id === DOTS_OTHERS_UUID))[0];
    };

    updateActivePin = (selected) => {
        const dots = d3.select(this.elMap.current).selectAll('.dots-pin');
        const pin = dots.filter( item => item.zone_id === selected.zone_id);

        dots.attr('xlink:href', DotsPin);
        this.handlePinStyle(dots);

        pin.attr('xlink:href', activeDotsPin);
        this.handlePinStyle(pin, 'active');
        this.moveToFront(pin);

        this.toggleElTooltip(true);
    }

    handleMapClick = e => {
        const { onClick } = this.props;
        const style = e.target.classList;
        const others = this.getOutofStation();

        if (!style.contains('dots-pin')) {
            onClick(others)(e);
        }
    };

    handleDotsPinMouseover = (e, d) => {
        const pin = d3.select(e.target);
        const { selected } = this.props;
        const styleDebounce = debounce( this.handlePinStyle, 60, { trailing: true });
        this.moveToFront(pin);

        if (d?.zone_id !== selected?.zone_id) {
            pin.attr('xlink:href', activeDotsPin);
            styleDebounce(pin, 'hover');
        }

    }

    handleDotsPinMouseout = (e, d) => {
        const pin = d3.select(e.target);
        const { selected } = this.props;
        const styleDebounce = debounce( this.handlePinStyle, 60, { trailing: true });

        if (d?.zone_id !== selected?.zone_id) {
            pin.attr('xlink:href', DotsPin);
            styleDebounce(pin);
        }

    }

    handleDotsPinClick = (e, d) => {
        const { onClick } = this.props;

        onClick(d)(e);
    };

    styleActiveClipPath = pin => {
        const svg = d3.select(this.elMap.current);
        const elPinPath = svg.select('#activePinClipPath');
        const { transBase } = MAP_MARKER_CONFIG.activePin;
        if (!pin.empty()) {
            const pinX = +pin.attr('x');
            const pinY = +pin.attr('y');
            const { x, y } = transBase;
            const clipPathX = +x + pinX;
            const clipPathY = +y + pinY;
            elPinPath.select('path').attr('transform', `translate(${ clipPathX } ${ clipPathY })`);
        }
    }

    handlePinStyle = (pins, styleType = 'original') => {
        const { width: activeWidth, height: activeHeight } = MAP_MARKER_CONFIG.activePin;
        const { scaleW, scaleH } = MAP_MARKER_CONFIG.hoverPin;
        let width;
        let height;
        let transX;
        let transY;
        let clipPath;

        switch (styleType) {
        case 'active':
            width = activeWidth;
            height = activeHeight;
            transX = `-${activeWidth / 2}`;
            transY = `-${activeHeight - MAP_MARKER_CONFIG.height * 0.5}`;
            clipPath = 'url(#activePinClipPath)';
            this.styleActiveClipPath(pins);
            break;
        case 'hover':
            transX = `-${MAP_MARKER_CONFIG.width * scaleW / 2}`;
            transY = `-${MAP_MARKER_CONFIG.height * (scaleH - 0.75 )}`;
            width = MAP_MARKER_CONFIG.width * scaleW;
            height = MAP_MARKER_CONFIG.height * scaleH;
            clipPath = undefined;
            break;
        case 'original':
        default:
            transX = `-${MAP_MARKER_CONFIG.width / 2}`;
            transY = `-${MAP_MARKER_CONFIG.height / 2}`;
            width = MAP_MARKER_CONFIG.width;
            height = MAP_MARKER_CONFIG.height;
            clipPath = MAP_MARKER_CONFIG.clipPathShape;
        }
        return pins
            .attr('clip-path', clipPath)
            .attr('transform', `translate(${transX}, ${transY})`)
            .attr('width', width)
            .attr('height', height);
    };

    render() {
        const { width, height, selected } = this.props;
        const { background } = MAP_STYLE_CONFIG;
        const wrapperStyle = {
            width,
            height,
            background,
            position: 'relative',
            margin: '0 auto',
        };
        return (
            <div ref={ this.elWrapper } style={ wrapperStyle }>
                <svg ref={ this.elMap } onClick={ this.handleMapClick } />
                {
                    (selected && selected.zone_id !== DOTS_OTHERS_UUID) ? (
                        <div className="tooltip" ref={ this.elTooltip }>
                            {
                                selected && (
                                    <h5 className="name">{ selected.name }</h5>
                                )
                            }
                        </div>
                    ) : null
                }

            </div>
        );
    };
};

export default MapChart;
