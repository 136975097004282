import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card, { CardItem } from 'components/Profile/Card';
import BatteryCapacity from 'components/BatteryCapacity';
import RentalState from 'components/Scooter/RentalState';
import ScooterStatus from 'components/Scooter/ScooterStatus';
import RelatedTickets from 'components/Scooter/RelatedTickets';
import TrunkStatus from 'components/Scooter/TrunkStatus';
import Location from 'components/Scooter/Location';
import ForceReturn from 'components/Scooter/ForceReturn';
import GTUReplace from 'components/GTUReplace';
import ParkingCheck from 'components/ParkingCheck';
import Datetime from 'components/Datetime';
import ActionPanel from 'components/ActionPanel';
import GoogleMap, { VIP_READ } from 'components/Map';
import NewTicket from 'components/NewTicket';
import FlexGroup from 'components/FlexGroup';
import { Button } from 'reactstrap';
import { Translate } from 'react-redux-i18n';
import { convertDistance } from 'helpers/util';
import permissionHandler from 'helpers/permission-handler';
import { clearSingleScooter } from 'actions';
import {
    CENTER_TO_CURRENT_LOCATION,
    VMS,
} from 'components/Map/Toolbox';
import { Map } from 'immutable';
import {
    RENTAL_RIDING,
} from 'constants/rental';
import AuthFeature from 'components/AuthFeature';
import { AUTH_CREATE_TICKET, AUTH_GET_TICKET_SELF_LIST } from 'constants/permission';
import getAuthedToolBoxList from 'components/Map/util/get-authed-toolbox';
import {
    fetchSingleScooter,
    fetchScooterRelatedTicket,
    clearScooterRelatedTicket,
} from 'actions';
import { GROUP_STATUS_EXCLUDE_CLOSED } from 'constants/ticket.js';
import addServiceZone from 'components/Map/Layers/service-zone';
import './scooter-profile.scss';
import FirmwareVersion from 'components/FirmwareVersion';

class Profile extends Component {
    static propTypes = {
        item: PropTypes.shape({
            scooter_id: PropTypes.string,
            plate: PropTypes.string,
            gtu_fw_version: PropTypes.string,
            gtu_imei: PropTypes.number,
            is_activate: PropTypes.bool,
            last_connection_date: PropTypes.string,
            last_rental_end_time: PropTypes.string,
            model: PropTypes.string,
            query_date_time: PropTypes.string,
            rental_state: PropTypes.number,
            scooter_fw_version: PropTypes.string,
            scooter_state: PropTypes.number,
            soc1: PropTypes.number,
            soc2: PropTypes.number,
            tag: PropTypes.number,
            total_mileage: PropTypes.number,
            trunk_state: PropTypes.number,
            current_location: PropTypes.shape({
                lat: PropTypes.number,
                lng: PropTypes.number,
            }),
            tracker_soc: PropTypes.number,
            csq: PropTypes.number,
            satellites: PropTypes.number,
            service_id: PropTypes.string,
            corporate_name: PropTypes.string,
        }),
        scooterRelatedTickets: PropTypes.arrayOf(
            PropTypes.number
        ),
    };

    static defaultProps = {
        item: {},
        scooterRelatedTickets: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            hiddenActionPanel: true,
            createTicket: false,
        };
        this.marker = undefined;
        this.vipLayer = VIP_READ;
    }

    componentDidMount() {
        this.fetchRelatedTicket();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const stateChanged = !Map(nextState).equals(Map(this.state));
        const { item, scooterRelatedTickets } = this.props;
        const { current_location: nextLoc, ...nextRestProps } = nextProps.item;
        const { current_location: loc, ...restProps } = item;
        const propsChanged = !(Map(nextRestProps).equals(Map(restProps)) && Map(nextLoc).equals(Map(loc)));
        const relatedTicketChanged = nextProps.scooterRelatedTickets !== scooterRelatedTickets;
        return  stateChanged || propsChanged || relatedTicketChanged;
    }

    componentDidUpdate(prevProps) {
        const { item } = this.props;
        if (item !== prevProps.item) {
            this.fetchRelatedTicket();
        }
        if (item.service_id !== prevProps.service_id) {
            addServiceZone({
                map: this.map,
                params: { service_id: item.service_id },
                selected: true,
                vipLayer: this.vipLayer
            });
        }
    }

    componentWillUnmount() {
        const { dispatch, item } = this.props;
        dispatch(clearSingleScooter());
        dispatch(clearScooterRelatedTicket(item.scooter_id));
    }

    toggleClose = () => {
        const { hiddenActionPanel } = this.state;
        this.setState({
            hiddenActionPanel: !hiddenActionPanel,
        });
    }

    toggleNewTicket = () => {
        const { createTicket } = this.state;
        this.setState({
            createTicket: !createTicket,
        });
    }

    afterNewTicket = () => {
        const { dispatch, item } = this.props;
        const { scooter_id } = item;
        this.toggleNewTicket();

        return dispatch(fetchSingleScooter(scooter_id));
    }

    fetchRelatedTicket = () => {
        const { dispatch, item } = this.props;
        const { plate, scooter_id } = item;

        if (!permissionHandler({ requiredList: [AUTH_GET_TICKET_SELF_LIST] })) {
            return ;
        }

        dispatch(clearScooterRelatedTicket(scooter_id));
        return dispatch(fetchScooterRelatedTicket({
            // always call first page
            page: 1,
            // always use max size of per page
            size: 200,
            plate_no: plate,
            status_id: GROUP_STATUS_EXCLUDE_CLOSED,
        }));
    }

    handleGoogleApi = ({ map, maps }) => {
        this.map = map;
        this.maps = maps;
        const { item } = this.props;
        const { service_id } = item;
        addServiceZone({
            map: this.map,
            params: { service_id },
            selected: true,
            vipLayer: this.vipLayer
        });
    }

    render() {
        const { item, children, scooterRelatedTickets } = this.props;
        const {
            scooter_id,
            soc,
            plate,
            tracker_id,
            tracker_subtype_id,
            model,
            model_display_name,
            is_activate,
            rental_state,
            last_rental_end_time,
            total_mileage,
            scooter_state,
            trunk_state,
            last_connection_date,
            current_location,
            tracker_soc,
            remaining_mileage,
            csq,
            satellites,
            corporate_name,
            ecu_type,
        } = item;
        const { hiddenActionPanel, createTicket } = this.state;
        const toolboxList = getAuthedToolBoxList([CENTER_TO_CURRENT_LOCATION, VMS]);

        return (
            <article className="scooter-profile">
                <div className="info-wrapper">
                    <Card title={ plate }>
                        <CardItem name="scooter.rental_state">
                            <RentalState className="profile-item" status={ rental_state } />
                        </CardItem>
                        <CardItem name="scooter.total_mileage">
                            <PlainDetail>
                                { total_mileage !== undefined ? convertDistance(total_mileage) : undefined }
                            </PlainDetail>
                        </CardItem>
                        <CardItem name="scooter.model">
                            <PlainDetail>{ model ? `${ model } (${ model_display_name || '--' })` : undefined }</PlainDetail>
                        </CardItem>
                        <CardItem name="scooter.activity">
                            <PlainDetail>
                                { is_activate !== undefined ? (
                                    <Translate value={ `scooter.activation.${ is_activate ? 'on' : 'off' }` } />
                                ) : undefined }
                            </PlainDetail>
                        </CardItem>
                        <CardItem name="scooter.battery">
                            <BatteryCapacity className="profile-item" capacity={ soc } ecuType={ ecu_type } />
                        </CardItem>
                        <CardItem name="scooter.tracker_soc">
                            <BatteryCapacity className="profile-item" capacity={ tracker_soc } ecuType={ ecu_type } isGTU />
                        </CardItem>
                        <CardItem name="scooter.csq">
                            <IntegerDetail>{ csq }</IntegerDetail>
                        </CardItem>
                        <CardItem name="scooter.satellites">
                            <IntegerDetail>{ satellites }</IntegerDetail>
                        </CardItem>
                        <CardItem name="scooter.remaining_mileage">
                            <PlainDetail>
                                { remaining_mileage !== undefined ? convertDistance(remaining_mileage) : undefined }
                            </PlainDetail>
                        </CardItem>
                        <CardItem name="scooter.scooter_state">
                            <ScooterStatus className="profile-item" status={ scooter_state } />
                        </CardItem>
                        <CardItem name="scooter.location">
                            <Location className="profile-item" location={ current_location } />
                        </CardItem>
                        <CardItem name="scooter.trunk_state">
                            <TrunkStatus className="profile-item" status={ trunk_state } />
                        </CardItem>
                        <CardItem name="ticket.main_type">
                            <PlainDetail>
                                { tracker_id ? (
                                    <Translate value={ `ticket.main_type_${ tracker_id }` } />
                                ) : '--' }
                            </PlainDetail>
                        </CardItem>
                        <CardItem name="ticket.sub_type">
                            <PlainDetail>
                                { tracker_subtype_id ? (
                                    <Translate value={ `ticket.sub_type_${ tracker_subtype_id }` } />
                                ) : '--' }
                            </PlainDetail>
                        </CardItem>
                        <CardItem name="scooter.last_rental_end_time">
                            <Datetime className="profile-item" time={ last_rental_end_time } />
                        </CardItem>
                        <CardItem name="scooter.last_connection_date">
                            <Datetime className="profile-item" time={ last_connection_date } />
                        </CardItem>
                        <CardItem name="scooter.corporate">
                            <PlainDetail>{ corporate_name ? corporate_name : 'GoShare' }</PlainDetail>
                        </CardItem>
                    </Card>
                    <section className="scooter-location">
                        { scooter_id !== undefined && (
                            <GoogleMap
                                zoom={ 15 }
                                toolbox={ toolboxList }
                                onInternalAPI={ this.handleGoogleApi }
                                center={ {
                                    lat: current_location.lat,
                                    lng: current_location.lng,
                                } }
                                list={ [{
                                    id: scooter_id,
                                    lat: current_location?.lat,
                                    lng: current_location?.lng,
                                    model,
                                    ...item,
                                }] }
                                currentLocation
                                withoutScooterCluster
                                vipLayer={ this.vipLayer }
                                isDetail
                            />
                        ) }
                    </section>
                </div>
                <FlexGroup className="button-group" gap start>
                    <Button color="primary" onClick={ this.toggleClose } disabled={ !scooter_id }>
                        <Translate value="do_action" />
                    </Button>
                    <AuthFeature
                        requiredList={ [
                            AUTH_CREATE_TICKET,
                        ] }
                    >
                        <Button
                            outline
                            color="primary"
                            type="button"
                            onClick={ this.toggleNewTicket }
                            disabled={ !scooter_id }
                        >
                            <Translate value="ticket.new" />
                        </Button>
                    </AuthFeature>
                    <ForceReturn id={ scooter_id } disabled={ RENTAL_RIDING !== rental_state } />
                    <GTUReplace id={ scooter_id } plate={ plate } />
                    <FirmwareVersion id={ scooter_id } plate={ plate } ecuType={ ecu_type } />
                    <ParkingCheck item={ item } disabled={ !scooter_id } />
                    <RelatedTickets list={ scooterRelatedTickets } />
                </FlexGroup>

                { children }

                {
                    !hiddenActionPanel ?
                        (
                            <ActionPanel
                                scooterIdList={ [scooter_id] }
                                onDidAction={ this.toggleClose }
                                onClose={ this.toggleClose }
                            />
                        ) :
                        null
                }
                <NewTicket
                    show={ createTicket }
                    scooterIdList={ [scooter_id] }
                    onCreated={ this.afterNewTicket }
                    onCancelled={ this.toggleNewTicket }
                />
            </article>
        );
    }
}

export default connect((state, ownProps) => ({
    scooterRelatedTickets: state.scooter.get('relatedTickets')[ownProps.item.scooter_id],
}))(Profile);

const PlainDetail = ({ children }) => <div className="profile-item">{ children }</div>;
export const IntegerDetail = ({ children }) => (
    <PlainDetail>
        { Number.isInteger(children) ? children : '--' }
    </PlainDetail>);
