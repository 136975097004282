import {
    DASH_OVERALL,
    DASH_DOTS,
    DASH_TICKET_SWAP,
    DASH_TICKET_MAINTAIN,
    DASH_TICKET_DISPATCH,
    DASH_TICKET_OTHER,
    DASH_TICKET_NONE,
} from 'constants/dashboard';

const dashboard = {
    'document_title': '儀表板',
    [`type_${ DASH_OVERALL }`]: 'Overall',
    [`type_${ DASH_DOTS }`]: 'DOTS',
    'service_level': '服務達成率',
    'tickets_status': '工單狀態',
    'total_scooters': '車輛總數',
    'total': '總數',
    'overall_service_level': '整體服務達成率',
    'service_type_dots': 'DOTS',
    'overall_scooter_model': '車輛型號統計',
    [`tickets_category_${ DASH_TICKET_SWAP }`]: '換電',
    [`tickets_category_${ DASH_TICKET_MAINTAIN }`]: '維修',
    [`tickets_category_${ DASH_TICKET_DISPATCH }`]: '調度',
    [`tickets_category_${ DASH_TICKET_OTHER }`]: '其它',
    [`tickets_category_${ DASH_TICKET_NONE }`]: 'None',
    'level': 'LEVEL',
    'empty_warning': '請確保資訊寬度在 <span class="point">1024px</span> 以上，以顯示正常資訊',
};

export default dashboard;
