/**
 * Action - Cities
 */
 import api from 'api';
 import { asyncInterface } from './util';
 import {
    FETCH_CITIES,
 } from 'constants/action-type';

 export const fetchCities = acceptLang => dispatch => {
     return asyncInterface({
         asyncAction: api.fetchCities(acceptLang),
         type: FETCH_CITIES,
         dispatch,
     });
 };
