import {
    CLAIM_TYPE_NO_CLAIM,
    CLAIM_TYPE_CLAIM,

    FEE_TYPE_FINE,
    FEE_TYPE_WITHHOLDING,
    FEE_TYPE_DISPATCH,
    FEE_TYPE_REPAIR,

    FEE_STATUS_UNPAID,
    FEE_STATUS_FAILED,
    FEE_STATUS_PAID,
    FEE_STATUS_REFUND,
    FEE_STATUS_CANCEL,
    FEE_STATUS_PROCESSING,
    FEE_STATUS_PROCESS_SUCCESS,
    FEE_STATUS_PROCESS_FAILED,
    FEE_STATUS_ABSORBED_BY_INTR,

    FEE_SUBTYPE_VIOLATE_ILLEGAL_PARKING,
    FEE_SUBTYPE_VIOLATE_NON_ILLEGAL_PARKING,
    FEE_SUBTYPE_CHARGE_PRIVATE_PARKING,
    FEE_SUBTYPE_CHARGE_TOWING_YARD,
    FEE_SUBTYPE_TOWING_ILLEGAL_PARKING,
    FEE_SUBTYPE_TOWING_TOWING_YARD,
    FEE_SUBTYPE_TOWING_OUTSIDE,
    FEE_SUBTYPE_TOWING_TRAFFIC_ACCIDENT,
    FEE_SUBTYPE_TOWING_DRUNK_DRIVING,
    FEE_SUBTYPE_REPAIR_CAR_DAMAGE,
    FEE_SUBTYPE_REPAIR_SUSPECTED_DAMAGE,

    VIOLATE_ZONE_KLU,
    VIOLATE_ZONE_TPE,
    VIOLATE_ZONE_TPH,
    VIOLATE_ZONE_TYC,
    VIOLATE_ZONE_HSC,
    VIOLATE_ZONE_HSH,
    VIOLATE_ZONE_MAL,
    VIOLATE_ZONE_TXG,
    VIOLATE_ZONE_CWH,
    VIOLATE_ZONE_NTO,
    VIOLATE_ZONE_YLH,
    VIOLATE_ZONE_CYI,
    VIOLATE_ZONE_CHY,
    VIOLATE_ZONE_TNN,
    VIOLATE_ZONE_KHH,
    VIOLATE_ZONE_IUH,
    VIOLATE_ZONE_TTT,
    VIOLATE_ZONE_HWA,
    VIOLATE_ZONE_ILN,
    VIOLATE_ZONE_PEH,
    VIOLATE_ZONE_KMN,
    VIOLATE_ZONE_LNN,

    ERROR_RENTAL_NOT_FOUND,
    ERROR_ZENDESK_NO_EMPTY,
    ERROR_FEE_TYPE_ERROR,
    ERROR_AMOUNT_OUT_OF_RANGE,
    ERROR_RENTAL_STATUS_ERROR,
    ERROR_RENTAL_PLATE_NOT_MATCH,
    ERROR_RENTAL_PLATE_NOT_FOUND,
    ERROR_RENTAL_SUBTYPE_ERROR,
    ERROR_RENTAL_PLATE_EMPTY,
    ERROR_VIOLATE_TIME_EMPTY,
    ERROR_TICKET_NO_EMPTY,
    ERROR_ATTACHMENT_DATA_ERROR,

    ERROR_EXPENSE_NOT_FOUND,
    ERROR_EXPENSE_STATUS_ERROR,

} from 'constants/additional-fee';

import commonTrans from './';

const claimType = {
    [CLAIM_TYPE_NO_CLAIM]: 'No Claim',
    [CLAIM_TYPE_CLAIM]: 'Claim',
};

const feeType = {
    [FEE_TYPE_FINE]: 'Ticket',
    [FEE_TYPE_WITHHOLDING]: 'Others',
    [FEE_TYPE_DISPATCH]: 'Dispatch',
    [FEE_TYPE_REPAIR]: 'Repair',
};

const feeStatus = {
    [FEE_STATUS_UNPAID]: 'Unpaid',
    [FEE_STATUS_PAID]: 'Success',
    [FEE_STATUS_REFUND]: 'Refunded',
    [FEE_STATUS_CANCEL]: 'Revoked',
    [FEE_STATUS_FAILED]: 'Failed',
    [FEE_STATUS_PROCESSING]: 'Under Processing',
    [FEE_STATUS_PROCESS_SUCCESS]: 'Result-Success',
    [FEE_STATUS_PROCESS_FAILED]: 'Result-Failed',
    [FEE_STATUS_ABSORBED_BY_INTR]: 'Absorbed Internally',
};

const feeSubtype = {
    [FEE_SUBTYPE_VIOLATE_ILLEGAL_PARKING]: 'Violation-Illegal Parking',
    [FEE_SUBTYPE_VIOLATE_NON_ILLEGAL_PARKING]: 'Violation-NON Illegal Parking',
    [FEE_SUBTYPE_CHARGE_PRIVATE_PARKING]: 'Private Parking Fee',
    [FEE_SUBTYPE_CHARGE_TOWING_YARD]: 'Towing Service Fee',
    [FEE_SUBTYPE_TOWING_ILLEGAL_PARKING]: 'Towing for Illegal Parking',
    [FEE_SUBTYPE_TOWING_TOWING_YARD]: 'Towing by Towing Yard',
    [FEE_SUBTYPE_TOWING_OUTSIDE]: 'Towing for Outside of Operating Scope',
    [FEE_SUBTYPE_TOWING_TRAFFIC_ACCIDENT]: 'Towing for Traffic Accident',
    [FEE_SUBTYPE_TOWING_DRUNK_DRIVING]: 'Towing for Drunk Driving',
    [FEE_SUBTYPE_REPAIR_CAR_DAMAGE]: 'Repair Fee for Car Damage',
    [FEE_SUBTYPE_REPAIR_SUSPECTED_DAMAGE]: 'Repair Fee for Car Damage related',
};

const violateZone = {
    [VIOLATE_ZONE_TPE]: 'Taipei City',
    [VIOLATE_ZONE_TPH]: 'New Taipei City',
    [VIOLATE_ZONE_TYC]: 'Taoyuan City',
    [VIOLATE_ZONE_TXG]: 'Taichung City',
    [VIOLATE_ZONE_TNN]: 'Tainan City',
    [VIOLATE_ZONE_KHH]: 'Kaohsiung City',
    [VIOLATE_ZONE_YLH]: 'Yunlin County',
    [VIOLATE_ZONE_HSC]: 'Hsinchu City',
    [VIOLATE_ZONE_HSH]: 'Hsinchu County',
    [VIOLATE_ZONE_KLU]: 'Keelung City',
    [VIOLATE_ZONE_MAL]: 'Miaoli County',
    [VIOLATE_ZONE_CWH]: 'Changhua County',
    [VIOLATE_ZONE_NTO]: 'Nantou County',
    [VIOLATE_ZONE_CHY]: 'Chiayi County',
    [VIOLATE_ZONE_CYI]: 'Chiayi City',
    [VIOLATE_ZONE_IUH]: 'Pingtung County',
    [VIOLATE_ZONE_ILN]: 'Yilan County',
    [VIOLATE_ZONE_HWA]: 'Hualien County',
    [VIOLATE_ZONE_TTT]: 'Taitung County',
    [VIOLATE_ZONE_PEH]: 'Penghu County',
    [VIOLATE_ZONE_KMN]: 'Kinmen County',
    [VIOLATE_ZONE_LNN]: 'Lienchiang County',
};

const additional_fee = {
    'claim_type_map': claimType,
    'fee_type_map': feeType,
    'fee_status_map': feeStatus,
    'claim_type': 'Claim Type',
    'fee_type': 'Fee Type',
    'status': 'Status',
    'ticket_no': 'Ticket No.',
    'new': 'New Fee',
    'request_no': 'Fee No.',
    'fee_subtype_map': feeSubtype,
    'subtype': 'Subtype',
    'details': 'Details',
    'claim_type_warning': '(*User will be promptly notified this charge after this submission.)',
    'violate_zone': 'Incident Location',
    'violate_zone_map': violateZone,
    'zendesk_no': 'Zendesk No.',
    'comments': 'Comment',
    'comments_ps': '(Only for internal records)',
    'attachments_interal': '(Up to 3 files allowed and only for internal records)',
    'attachments_external': '(Up to 3 files allowed and will be sent to the user)',
    'file_size_8MB_warning': 'The file size should be smaller than 8 MB.',
    'information': 'Rental Information',
    'search_title': 'Rental Result',
    'violate_time': 'Incident Time',
    'violate_search_hint': 'If you modify the Rental No., Plate No., and Incident Time, the search results will be cleared, and you will need to search again.',
    'violate_search_init_msg': 'Please search rental records.',
    'btn_search_rental': 'Search',
    'btn_view_rental': 'View',
    [`create_error_message_${ ERROR_RENTAL_NOT_FOUND }`]: 'The Rental No. is not found.',
    [`create_error_message_${ ERROR_ZENDESK_NO_EMPTY }`]: commonTrans.general_error,
    [`create_error_message_${ ERROR_FEE_TYPE_ERROR }`]: 'Invalid amount format',
    [`create_error_message_${ ERROR_AMOUNT_OUT_OF_RANGE }`]: 'The amount exceeds the allowable range.',
    [`create_error_message_${ ERROR_RENTAL_STATUS_ERROR }`]: commonTrans.general_error,
    [`create_error_message_${ ERROR_RENTAL_PLATE_NOT_MATCH }`]: 'Not matching with the Plate No. in the record',
    [`create_error_message_${ ERROR_RENTAL_PLATE_NOT_FOUND }`]: 'The Plate No. is not found',
    [`create_error_message_${ ERROR_RENTAL_SUBTYPE_ERROR }`]: commonTrans.general_error,
    [`create_error_message_${ ERROR_RENTAL_PLATE_EMPTY }`]: commonTrans.general_error,
    [`create_error_message_${ ERROR_VIOLATE_TIME_EMPTY }`]: commonTrans.general_error,
    [`create_error_message_${ ERROR_TICKET_NO_EMPTY }`]: commonTrans.general_error,
    [`create_error_message_${ ERROR_ATTACHMENT_DATA_ERROR }`]: commonTrans.general_error,
    [`create_error_message_${ ERROR_EXPENSE_NOT_FOUND }`]: commonTrans.general_error,
    [`create_error_message_${ ERROR_EXPENSE_STATUS_ERROR }`]: commonTrans.general_error,
    'fee_detail_title': 'Fee Details',
    'fee_info': 'Fee Information',
    'revoke_fee': 'Revoke',
    'revoke_warning': 'Are you sure you want to revoke this additional fee?',
    'absorbed_fee': 'Absorbed Internally',
    'absorbed_warning': 'Are you sure you want to change this fee to be absorbed internally?',
    'confirm_save_claim_fee': 'This fee is is a claim fee. After saving, the information and attachments will be sent to GoShare users for fee retrieval. Please check the required information again.',
    'confirm_save_claim_fee_title': 'Please Confirm the Information',
    'only_allow_jpe': 'Allow upload jpg/jpeg image file only.'
};

export default additional_fee;
