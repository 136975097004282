import { createFleetApiInstance, getAPIkey } from '../util';

const coupon = {
    fetchCouponList: (params = {}) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get('/coupons', { params });
    },

    fetchSystemCouponList(params = {}) {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get('/coupons/system', { params });
    },

    fetchOneCoupon: id => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get(`/coupons/${ id }`);
    },

    fetchOneSystemCoupon(id) {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get(`/coupons/system/${ id }`);
    },

    createCoupon: payload => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).post('/coupons', payload);
    },

    createSystemCoupon(payload) {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).post('/coupons/system/', payload);
    },

    updateCoupon: (id, payload) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).patch(`/coupons/${ id }`, payload);
    },

    updateSystemCoupon(id, payload) {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).patch(`/coupons/system/${ id }`, payload);
    },

    fetchAccountType: () => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get('/accounts');
    },

    fetchBulkCouponCode: (id) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get(`/coupons/${ id }/promo_code`);
    },

    fetchAvailableSystemCoupons(params) {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get('/coupons/system/available', { params });
    },
};

export default coupon;
