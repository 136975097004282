import {
    MY_TICKET,
    SCOOTER_LIST,
    SCOOTER_MAP,
    SCOOTER_SEARCH,
    RENTAL_MANAGEMENT,
    LOGIN,
    COUPON_HOME,
    SYSTEM_COUPON,
    AD_MANAGEMENT,
    CUSTOMER_FINDER,
    LOG_RENTAL,
    TICKET_LIST,
    TICKET_ASSIGNMENT,
    ADMIN,
    PROMOTION_MANAGEMENT,
    MISSION_MANAGEMENT,
    ASSET_MANAGEMENT,
    DASHBOARD,
    TV_MONITOR,
    REGULAR_MAINTENANCE,
    ADDITIONAL_FEE,
} from 'constants/routes';
import {
    GROUP_OPERATION,
    GROUP_CUSTOMER,
    GROUP_MARKETING,
    GROUP_AUDIT,
    GROUP_TICKET,
    GROUP_ADMIN,
    GROUP_PROMOTION,
    GROUP_TRAFFIC,
    GROUP_DASHBOARD,
    GROUP_TV,
} from 'constants/routes-with-auth';

const routes = {
    [MY_TICKET]: '我的工單',
    [SCOOTER_LIST]: '車輛管理',
    [SCOOTER_MAP]: '車輛地圖',
    [SCOOTER_SEARCH]: '車輛搜尋',
    [RENTAL_MANAGEMENT]: '租賃管理',
    [LOGIN]: '登入',
    [COUPON_HOME]: '折扣碼',
    [SYSTEM_COUPON]: '系統折扣',
    [AD_MANAGEMENT]: '廣告',
    [CUSTOMER_FINDER]: '客戶搜尋',
    [LOG_RENTAL]: '租賃記錄',
    [TICKET_LIST]: '工單管理',
    [TICKET_ASSIGNMENT]: '工單分配',
    [ADMIN]: '使用者管理',
    [PROMOTION_MANAGEMENT]: '促銷管理',
    [ADDITIONAL_FEE]: '衍生費用',
    [MISSION_MANAGEMENT]: '任務管理',
    [ASSET_MANAGEMENT]: '資產管理',
    [DASHBOARD]: '總覽',
    [TV_MONITOR]: '即時監控',
    [REGULAR_MAINTENANCE]: '定期保養',
    [`category_${ GROUP_OPERATION }`]: '營運',
    [`category_${ GROUP_DASHBOARD }`]: '儀表板',
    [`category_${ GROUP_TV }`]: '監控',
    [`category_${ GROUP_CUSTOMER }`]: '客服',
    [`category_${ GROUP_MARKETING }`]: '行銷',
    [`category_${ GROUP_AUDIT }`]: '稽核',
    [`category_${ GROUP_TICKET }`]: '任務',
    [`category_${ GROUP_TICKET }`]: '工單系統',
    [`category_${ GROUP_ADMIN }`]: '管理員',
    [`category_${ GROUP_PROMOTION }`]: '促銷',
    [`category_${ GROUP_TRAFFIC }`]: '衍生費用管理',
};

export default routes;
