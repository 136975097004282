import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';

const CardTemplate = ({ children, className, title }) => {

    return (
        <Card>
            <CardBody>
                <Form tag="section" inline>
                    <FormGroup className={ className } title={ title }>
                        { children }
                    </FormGroup>
                </Form>
            </CardBody>
        </Card>
    );
};

CardTemplate.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    title: PropTypes.string,
};
CardTemplate.defaultProps = {
    children: null,
    className: '',
    title: '',
};
export default CardTemplate;
