import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import DatetimeRangeFilter from 'components/Filter/DatetimeRangeFilter';

export const defaultRange = [60, 'days'];

const CustomCreatedTime = ({ onChange, defaultFrom, defaultTo, }) => {
    const applied = (
        defaultFrom &&
        defaultTo &&
        moment(defaultFrom).isValid() &&
        moment(defaultTo).isValid()
    );

    return (
        <DatetimeRangeFilter
            range={ defaultRange }
            title={ I18n.t('ticket.create_time') }
            onApply={ onChange }
            defaultFrom={ defaultFrom }
            defaultTo={ defaultTo }
            applied={ applied }
        />
    );
};
CustomCreatedTime.propTypes = {
    onChange: PropTypes.func.isRequired,
    defaultFrom: PropTypes.string,
    defaultTo: PropTypes.string,
};
CustomCreatedTime.defaultProps = {
    defaultFrom: undefined,
    defaultTo: undefined,
};

export default CustomCreatedTime;
