import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Filter from 'components/Filter';
import SearchField from 'components/SearchField';
import { SEPARATOR } from 'components/Form/Input/SearchSelect';
import { Input, Label } from 'reactstrap';
import usePrevious from 'hooks/usePrevious';

const MultiselectFilter = ({
    defaultSelected,
    title,
    options,
    onChange,
    searchable,
    single,
}) => {
    const maxCount = 3;
    const [selectedOptionMap, setSelectedOptionMap] = useState({});
    const [open, setOpen] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [inputSelect, setInputSelect] = useState({});
    const [applied, setApplied] = useState(inputSelect.length>0);
    const [filterValue, setFilterValue] = useState('');
    const prevApplied = usePrevious(applied);
    const prevInputSelect = usePrevious(inputSelect);

    const handleFilterReset = () => {
        setOpen(false);
        setKeyword('');
        setSelectedOptionMap({});
        setApplied(false);
        setInputSelect({});
        onChange({ inputSelect: [] });
    };

    const handleFilterApply = () => {
        const inputSelect = Object.values(selectedOptionMap).filter(i => i !== undefined);
        setOpen(false);
        setKeyword('');
        setInputSelect(inputSelect);
        setApplied(inputSelect.length>0);
        onChange({
            inputSelect: inputSelect.map((item) => (item?.value !== undefined) ? item.value : ''),
        });
    };
    const handleFilterToggle = () => {
        setKeyword('');
        setOpen(!open);
    }
    const handleKeywordChange = keyword => {
        setKeyword(keyword);
    }
    const handleChange = ({ text, value }) => e => {

        if (e.currentTarget.checked) {
            if (single) {
                setSelectedOptionMap([]);
            }
            setSelectedOptionMap({
                ...selectedOptionMap,
                [value]: { text, value },
            });
        }
        else {
            setSelectedOptionMap({
                ...selectedOptionMap,
                [value]: undefined,
            });
        }
    };

    const renderSearchField = () => {
        let el;

        if (searchable) {
            el = (
                <SearchField
                    stopTriggerByEnter
                    tag="div"
                    onChange={ handleKeywordChange }
                    value={ keyword }
                />
            );
        }

        return el;
    };

    const InputOptions = () => {
        return options.map(({ text, value }) => {
            if (text === SEPARATOR) {
                return null;
            }

            return (
                text.toUpperCase().indexOf(keyword.toUpperCase()) > -1 ?
                    (
                        <Label className="filter-option" key={ value }>
                            <Input
                                type={ single ? 'radio' : 'checkbox' }
                                name={ single ? title : undefined }
                                value={ value }
                                defaultChecked={
                                    selectedOptionMap[value.toString()] !== undefined
                                }
                                onChange={ handleChange({ text, value }) }
                            />
                            <span>{ text }</span>
                        </Label>
                    ) :
                    null
            );
        });
    };
    useEffect(() => {
        if (!defaultSelected.length) {
            setOpen(false);
            setKeyword('');
            setSelectedOptionMap({});
            setApplied(false);
            setInputSelect({});
        }
        else {
            const mapObject = options.reduce((obj, item) => {
                const { value } = item;
                if (Array.isArray(value)) {
                    defaultSelected.forEach(element => {
                        if (element.toString() === value.toString()) {
                            obj[value.toString()] = item;
                        }
                    });
                }
                else if (defaultSelected.indexOf(value) > -1) {
                    obj[value] = item;
                }

                return obj;
            }, {});
            const inputSelect = Object.values(mapObject).filter(i => i !== undefined);
            setSelectedOptionMap(mapObject);
            setInputSelect(inputSelect);
            setApplied(true);
        }

    }, [defaultSelected, options]);

    useEffect(() => {
        let filterValueArry = [];
        if (!applied || prevApplied !== applied || prevInputSelect !== inputSelect) {
            for (let i = 0; i < Math.min(maxCount, inputSelect.length); i++) {
                if (inputSelect[i].value !== undefined) {
                    filterValueArry.push(inputSelect[i].text);
                }

            }
            if (inputSelect.length > maxCount) {
                filterValueArry.push('...');
            }

            setFilterValue(filterValueArry.length ? `${ title }: ${ filterValueArry.toString() }` : title);

        }
    }, [applied, inputSelect, prevApplied, title, prevInputSelect]);


    return (
        <Filter
            className="multiselect-filter"
            value={ filterValue }
            open={ open }
            applied={ applied }
            onToggle={ handleFilterToggle }
            onReset={ handleFilterReset }
            onApply={ handleFilterApply }
        >
            { renderSearchField() }
            <div className="option-container">
                <InputOptions />
            </div>
        </Filter>
    );
};
MultiselectFilter.propTypes = {
    defaultSelected: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.array,
        PropTypes.string,
    ])),
    title: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.node,
        text: PropTypes.string,
    })).isRequired,
    onChange: PropTypes.func,
    searchable: PropTypes.bool,
    single: PropTypes.bool,
};

MultiselectFilter.defaultProps = {
    defaultSelected: [],
    title: '',
    onChange: () => {},
    searchable: false,
    single: false,
};


export default MultiselectFilter;
