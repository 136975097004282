import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import {
    CORPORATE_TYPE_NORMAL,
    CORPORATE_TYPE_BASIC,
    CORPORATE_TYPE_VIP,
} from 'constants/customer';

export const corporateTypeOptions = [
    CORPORATE_TYPE_NORMAL,
    CORPORATE_TYPE_BASIC,
    CORPORATE_TYPE_VIP
].map(
    value => ({
        text: I18n.t(`customer.corporate_type_${ value }`),
        value,
    })
);

const CorporateType = ({ type, className }) => {
    let el;
    switch (type) {
    case CORPORATE_TYPE_NORMAL:
    case CORPORATE_TYPE_BASIC:
    case CORPORATE_TYPE_VIP:
        el = <Translate value={ `customer.corporate_type_${ type }` } />;
        break;
    default:
        el = <span>--</span>;
    };
    return (
        <div className={ className }>
            { el }
        </div>
    );
};
CorporateType.propTypes = {
    type: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    className: PropTypes.string,
};
CorporateType.defaultProps = {
    type: undefined,
    className: undefined,
};
export default CorporateType;
