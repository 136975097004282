import {
    SERVICE_LEVEL_THRESHOLD,
    DASH_SCOOTER_MODEL_GROUP_P,
    DASH_SCOOTER_MODEL_GROUP_B,
    DASH_SCOOTER_MODEL_GROUP_F,
    DASH_SCOOTER_MODEL_GROUP_J,
    DASH_SCOOTER_MODEL_GROUP_D,
    DASH_SCOOTER_MODEL_COLOR_ON_P,
    DASH_SCOOTER_MODEL_COLOR_OFF_P,
    DASH_SCOOTER_MODEL_COLOR_ON_B,
    DASH_SCOOTER_MODEL_COLOR_OFF_B,
    DASH_SCOOTER_MODEL_COLOR_ON_F,
    DASH_SCOOTER_MODEL_COLOR_OFF_F,
    DASH_SCOOTER_MODEL_COLOR_ON_J,
    DASH_SCOOTER_MODEL_COLOR_OFF_J,
    DASH_SCOOTER_MODEL_COLOR_ON_J_DARK,
    DASH_SCOOTER_MODEL_COLOR_OFF_J_DARK,
    DASH_SCOOTER_MODEL_COLOR_ON_D,
    DASH_SCOOTER_MODEL_COLOR_OFF_D,
    DASH_TICKET_NONE,
    DASH_TICKET_SWAP,
    DASH_TICKET_DISPATCH,
    DASH_TICKET_MAINTAIN,
    DASH_TICKET_OTHER,
    TICKETS_CATEGORY_BY_ORDER,
} from 'constants/dashboard';

import {
    SERVICE_TYPE_FREE_FLOATING,
    SERVICE_TYPE_DOTS,
} from 'constants/scooter';

import {
    PIE_CHART_THICK_DEFAULT,
    CHART_FONT_STYLE_BOLD,
    CHART_FONT_STYLE_MEDIUM,
} from 'constants/chart';
import { gsap } from 'gsap';
import { I18n } from 'react-redux-i18n';

export const animateValue = (start, end, duration, callback = null) => {
    const target = { value: start };
    return gsap.to(target, {
        duration,
        value: end,
        roundProps: 'value',
        onUpdate: () => {
            callback && callback(target.value);
        },
    });

};

export const animateArrayValue = (startArray, endArray, duration, callback = null, i) => {

    const target = (startArray.length && startArray.length === endArray) ?
        startArray :
        endArray.map((item, i) => {
            return startArray[i] || 0;
        });
    return gsap.to(target, {
        endArray,
        duration,
        ease: 'power4.out',
        onUpdate() {
            const result = target.map(item => Math.floor(item));
            callback && callback(result, i);
        }
    });

};

export const getThresholdByTicketCategory = category => {
    const target = TICKETS_CATEGORY_BY_ORDER.filter( item => item.id === category );
    return target[0]?.threshold ;
};

export const showTicketStatusWarning = ( counts, threshold, city = {}) => {
    const { value, unitByPercent } = threshold;
    const { operating = 1 } = city;
    let target;
    let result;

    if (unitByPercent) {
        target = +(counts / operating).toFixed(4);
        result = (threshold.value &&
                typeof threshold.value === 'number' &&
                threshold.value >= 0 &&
                typeof city.operating === 'number' &&
                city.operating > 0) ?
            target > value : false;
    }
    else {
        target = counts;
        result = (threshold.value &&
                typeof threshold.value === 'number' &&
                threshold.value >= 0 ) ?
            target > value : false;
    }

    return result;
};

export const showTicketCountsWarningByCategory = (category, counts, city) => {
    const threshold = getThresholdByTicketCategory(category);

    return showTicketStatusWarning(counts, threshold, city);
};

export const showServiceLevelWarning = value => value ? value < SERVICE_LEVEL_THRESHOLD : true;

export const getDashScooterModelColorList = (list, isDarkTheme = false) => {
    let result = [];
    list.forEach(item => {
        switch (item.type) {
        case DASH_SCOOTER_MODEL_GROUP_J:
            const modelJTheme = isDarkTheme ?
                [DASH_SCOOTER_MODEL_COLOR_ON_J_DARK, DASH_SCOOTER_MODEL_COLOR_OFF_J_DARK] :
                [DASH_SCOOTER_MODEL_COLOR_ON_J, DASH_SCOOTER_MODEL_COLOR_OFF_J];

            result = result.concat(modelJTheme);
            break;
        case DASH_SCOOTER_MODEL_GROUP_P:
            result = result.concat([
                DASH_SCOOTER_MODEL_COLOR_ON_P,
                DASH_SCOOTER_MODEL_COLOR_OFF_P,
            ]);
            break;
        case DASH_SCOOTER_MODEL_GROUP_F:
            result = result.concat([
                DASH_SCOOTER_MODEL_COLOR_ON_F,
                DASH_SCOOTER_MODEL_COLOR_OFF_F,
            ]);
            break;
        case DASH_SCOOTER_MODEL_GROUP_D:
            result = result.concat([
                DASH_SCOOTER_MODEL_COLOR_ON_D,
                DASH_SCOOTER_MODEL_COLOR_OFF_D,
            ]);
            break;
        case DASH_SCOOTER_MODEL_GROUP_B:
        default:
            result = result.concat([
                DASH_SCOOTER_MODEL_COLOR_ON_B,
                DASH_SCOOTER_MODEL_COLOR_OFF_B,
            ]);

        }
    });

    return result;
};

export const getDashScooterModelStyle = type => {
    let style;
    switch (type) {
    case DASH_SCOOTER_MODEL_GROUP_J:
        style = DASH_SCOOTER_MODEL_COLOR_ON_J;
        break;
    case DASH_SCOOTER_MODEL_GROUP_P:
        style = DASH_SCOOTER_MODEL_COLOR_ON_P;
        break;
    case DASH_SCOOTER_MODEL_GROUP_F:
        style = DASH_SCOOTER_MODEL_COLOR_ON_F;
        break;
    case DASH_SCOOTER_MODEL_GROUP_D:
        style = DASH_SCOOTER_MODEL_COLOR_ON_D;
        break;
    case DASH_SCOOTER_MODEL_GROUP_B:
    default:
        style = DASH_SCOOTER_MODEL_COLOR_ON_B;
    }
    return style.substr(1);
};

export const getPieChartHole = (width, thick = PIE_CHART_THICK_DEFAULT) => +((width - 2 * thick) / width).toFixed(2);

export const getTicketCounts = (categoryId, item) => {
    let counts;
    switch (categoryId) {
    case DASH_TICKET_SWAP:
        counts = item.swap;
        break;
    case DASH_TICKET_MAINTAIN:
        counts = item.maintain;
        break;
    case DASH_TICKET_DISPATCH:
        counts = item.dispatch;
        break;
    case DASH_TICKET_OTHER:
        counts = item.other;
        break;
    case DASH_TICKET_NONE:
        counts = item.none;
        break;
    default:
        counts = 0;
    }
    return counts;
};

export const getServiceLevelGaugeLabel = (
    titleStyle = `"
        font-size: 28px;
        font-weight: bold;
        font-family: ${ CHART_FONT_STYLE_BOLD };
    "`,
    unitStyle = `"
        font-size: 20px;
        font-weight: bold;
        font-family: ${ CHART_FONT_STYLE_BOLD };
    "`,
) => value => {
    return `<b style=${ titleStyle }>${ value }</b><b style=${ unitStyle }>%</b>`;
};

export const getServicesCaption = (item, cities) => {
    let caption;
    const {
        service_type,
        city_id,
    } = item;

    switch (service_type) {
    case SERVICE_TYPE_DOTS:
        caption = I18n.t('dashboard.service_type_dots');
        break;
    case SERVICE_TYPE_FREE_FLOATING:
        caption = cities[city_id];
        break;
    default:
        caption = '';
    }
    return caption;
};

export const getScooterModelPieLabel = (
    labelStyle = `"
        font-weight: bold;
        font-family: ${ CHART_FONT_STYLE_BOLD };
    "`,
) => sumValue => {
    return `<b style=${ labelStyle }>${ sumValue }</b>`;
};

export const getScooterModelPieSubtitle = (
    subtitleStyle = `"
        font-size: 16px;
        font-weight: 500;
        font-family: ${ CHART_FONT_STYLE_MEDIUM };
    "`,
) => subTitle => {
    return `<span style=${ subtitleStyle }>${subTitle}</span>`;
};
