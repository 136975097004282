const ListHeader = [{
    name: 'vin',
    value: 'asset.vin',
    width: 140,
}, {
    name: 'plate',
    value: 'asset.plate',
    width: 150,
}, {
    name: 'scooter_model_name',
    value: 'asset.scooter_model_name',
    width: 60,
}, {
    name: 'subscribe_id',
    value: 'asset.subscribe_id',
    width: 130,
}, {
    name: 'gtu_imei',
    value: 'asset.gtu_imei',
    width: 110,
}, {
    name: 'sim_phone_number',
    value: 'asset.sim_phone_number',
    width: 150,
}, {
    name: 'ssl_certificate_issuer',
    value: 'asset.ssl_certificate_issuer',
    width: 100,
}, {
    name: 'ssl_certificate_subject',
    value: 'asset.ssl_certificate_subject',
    width: 310,
}, {
    name: 'ssl_certificate_valid_to',
    value: 'asset.ssl_certificate_valid_to',
    width: 130,
}, {
    name: 'create_time',
    value: 'asset.create_time',
    sortable: true,
    width: 130,
}];

export default ListHeader;
