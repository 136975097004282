import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { fetchUserRoles } from 'actions';
import MultiselectFilter from './MultiselectFilter';

class UserRoleFilter extends React.Component {
    static propTypes = {
        userRoles: ImmutablePropTypes.list.isRequired,
        defaultSelected: PropTypes.arrayOf(PropTypes.string),
        onChange: PropTypes.func.isRequired,
    }

    static defaultProps = {
        defaultSelected: []
    }

    componentDidMount() {
        const { dispatch, userRoles } = this.props;

        if (userRoles.size === 0) {
            dispatch(fetchUserRoles());
        }
    }

    render() {
        const { userRoles, defaultSelected, onChange } = this.props;
        const userRoleOptions = userRoles.toJS().map(userRole => ({
            text: userRole.name,
            value: userRole.id,
        }));

        return (
            <MultiselectFilter
                title={ I18n.t('admin.role') }
                options={ userRoleOptions }
                defaultSelected={ defaultSelected }
                onChange={ onChange }
            />
        );
    }
}

export default connect(state => ({
    userRoles: state.admin.get('userRoles'),
}))(UserRoleFilter);
