import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { checkRouteStatus } from 'helpers/ticket-system';
import debounce from 'lodash/debounce';
import FlexGroup from 'components/FlexGroup';
import { Refresh } from 'components/Pagination';
import AuthView from 'components/AuthView';
import { getDefaultPageSize } from 'components/Pagination/PageSizeSelect';
import { GROUP_STATUS_EXCLUDE_CLOSED } from 'constants/ticket.js';
import MapToggle from './MapToggle';
import MyTickets from './MyTickets';
import DetailSection from './Details';
import TicketMap from './TicketMap';
import {
    fetchMyTask,
    fetchSingleScooter,
    clearNearbyScooters,
    clearSingleScooter,
    toogleNavigation,
    fetchOneTicket,
    fetchOperationUsers,
} from 'actions';
import './ticket-system.scss';

class TicketSystem extends Component {
    static propTypes = {
        tasks: PropTypes.shape({
            page_index: PropTypes.number,
            page_count: PropTypes.number,
            data_list: ImmutablePropTypes.list,
        }).isRequired,
        oneTicket: ImmutablePropTypes.map.isRequired,
        singleScooter: PropTypes.shape({}),
    };
    static defaultProps = {
        singleScooter: null,
    };
    constructor(props) {
        super(props);
        this.state = {
            showMap: true,
            resetBounds: 0,
            offsetWidth: document.body.offsetWidth,
            waitRetrieve: true,
        };
        this.queryPayload = {
            size: getDefaultPageSize().value,
            status_id: GROUP_STATUS_EXCLUDE_CLOSED,
        };
        this.debounceResize = debounce(this.handleResize, 100, { trailing: true });
    };

    componentDidMount() {
        const { match } = this.props;
        const { params } = match;
        if (Object.keys(params).length === 0) {
            this.fetchData();
        }
        window.addEventListener('resize', this.debounceResize);
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearNearbyScooters());
        window.removeEventListener('resize', this.debounceResize);
    }

    handleResize = () => {
        this.setState({ offsetWidth: document.body.offsetWidth });
    };

    toggleWaitRetrieve = (newRetrieve = false) => {
        this.setState({ waitRetrieve: newRetrieve });
    };

    toggleMap = () => {
        const { showMap } = this.state;
        this.setState({
            showMap: !showMap,
        });
    }

    fetchTicket = (params) => {
        if (params['ticketId']) {
            const { dispatch } = this.props;
            const ticketId = params['ticketId'];
            return Promise.all([
                dispatch(fetchOneTicket(ticketId)),
                dispatch(fetchOperationUsers(ticketId))
            ]);
        }
    }

    fetchScooter = (params) => {
        if (params['scooterId']) {
            const { dispatch } = this.props;
            const scooterId = params['scooterId'];
            return dispatch(fetchSingleScooter(scooterId)).then(data => {
                if (data.error?.response.status === 403) {
                    dispatch(clearSingleScooter());
                    dispatch(toogleNavigation());
                }
            });
        }
    }

    fetchMyTask = (isResetBounds = false, queryPayload = this.queryPayload) => {
        const { dispatch } = this.props;
        const { ticket_id } = queryPayload;
        const updatedTicketId = ticket_id && Number(ticket_id);
        return dispatch(fetchMyTask({
            ...queryPayload,
            ticket_id: Number.isNaN(updatedTicketId) ? 0 : updatedTicketId,
        })).then(() => {
            this.queryPayload = queryPayload;
            const updState = {};
            updState.waitRetrieve = true;
            if (isResetBounds) {
                updState.resetBounds = Date.now();
            }
            this.setState(updState);
        });
    }

    fetchData = () => {
        const { match } = this.props;
        const { params } = match;
        if (checkRouteStatus(params)) {
            this.fetchMyTask(true, this.queryPayload);
        }
        else if (params['scooterId']) {
            this.fetchScooter(params);
        }
        else {
            this.fetchTicket(params);
        }
    }

    backToAction = () => {
        const { dispatch } = this.props;
        dispatch(clearNearbyScooters());
        this.queryPayload = {
            size: getDefaultPageSize().value,
            status_id: GROUP_STATUS_EXCLUDE_CLOSED,
        };
        this.setState({
            resetBounds: Date.now(),
        });
    }

    switchResponseTime = (params) => {
        const { oneTicket, tasks, singleScooter } = this.props;
        let { __responseTime } = tasks.toJS();
        if (!checkRouteStatus(params)) {
            if (singleScooter && Object.keys(params).includes('scooterId')) {
                ({ __responseTime } = singleScooter.toJS());
            }
            else {
                ({ __responseTime } = oneTicket.toJS());
            }
        }
        return __responseTime;
    }

    render() {
        const { match } = this.props;
        const { showMap, resetBounds, offsetWidth, waitRetrieve } = this.state;
        const { params } = match;
        const style = classNames({
            'ticket-management': true,
            'map-mode': !checkRouteStatus(params) ? true : showMap,
        });

        const mapControlStyle = classNames({
            'map-control': true,
            'my-task-map-control': checkRouteStatus(params),
            'special-map-control': checkRouteStatus(params) && offsetWidth <= 480,
            'ticket-detail-map-control': params['ticketId'],
        });

        const responseTime = this.switchResponseTime(params);

        return (
            <AuthView className={ style }>
                {
                    !checkRouteStatus(params) ?
                        <>
                            <DetailSection
                                params={ params }
                                onBack={ this.backToAction }
                                onTicket={ this.fetchTicket }
                                onScooter={ this.fetchScooter }
                                onFetch={ this.fetchMyTask }
                                waitRetrieve={ waitRetrieve }
                            />
                        </>
                        :
                        <MyTickets onFetch={ this.fetchMyTask } showMap={ showMap } />
                }
                <FlexGroup className={ mapControlStyle }>
                    <FlexGroup end>
                        {
                            checkRouteStatus(params) &&
                            (
                                <MapToggle showMap={ showMap } onToggle={ this.toggleMap } />
                            )
                        }
                    </FlexGroup>
                    <FlexGroup className="refresh-button" end>
                        <Refresh
                            time={ responseTime }
                            timeClass="web-button"
                            onClick={ this.fetchData }
                        />
                    </FlexGroup>
                </FlexGroup>
                <TicketMap
                    params={ params }
                    showMap={ showMap }
                    resetBounds={ resetBounds }
                    onFetchTask={ this.fetchMyTask }
                    onTilesLoaded={ this.toggleWaitRetrieve }
                />
            </AuthView>
        );
    };
}

export default connect((state, ownProps) => ({
    tasks: state.scooter.get('tasks'),
    oneTicket: state.ticket.get('oneTicket'),
    singleScooter: state.scooter.get('singleScooter')[ownProps.match.params.scooterId],
    i18n: state.i18n,
}))(TicketSystem);
