import React from 'react';
import PropTypes from 'prop-types';
import Cell from 'components/ListView/Cell';
import permissionHandler from 'helpers/permission-handler';
import {
    AUTH_EDIT_FLEET_USER,
} from 'constants/permission';

export default function AdminListRow({ columns, data }) {
    return columns.map(({ key, width }) => (
        <Field key={ key } data={ data } width={ width } name={ key } />
    ));
}

function Field({ data, width, name }) {
    const value = typeof data[name] === 'string' && data[name].trim() === ''
        ? ' ' : data[name];
    let el;

    switch (name) {
    case 'account':
        el = permissionHandler({ requiredList: [AUTH_EDIT_FLEET_USER] }) ? (
            <span className="account-field" onClick={ data.handleAccountFieldClick }>{ value }</span>
        ) : value;
        break;
    default:
        el = value;
        break;
    }

    return (
        <Cell key={ `${ data.id }-${ name }` } name={ name } style={ { width } }>
            { el }
        </Cell>
    );
}

Field.propTypes = {
    data: PropTypes.shape({}).isRequired,
    width: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
};
