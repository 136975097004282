import React from 'react';
import PropTypes from 'prop-types';

export const createMapSearchUrl = ({ lat, lng }) => (
    `https://www.google.com/maps/search/?api=1&query=${ lat },${ lng }`
);

const Component = ({ location, children, className }) => {
    const makeShortCoords = (lat, lng) => {
        return {
            lat: Number.parseFloat(lat).toFixed(5),
            lng: Number.parseFloat(lng).toFixed(5),
        };
    };
    const { lat, lng } = location || {};
    const shortCoords = makeShortCoords(lat, lng);

    const isLatDefined = lat !== undefined && lat !== null;
    const isLngDefined = lng !== undefined && lng !== null;

    return isLatDefined && isLngDefined ? (
        <div className={ className }>
            <span key={ `${ lat }-${ lng }-marker` } className="icon fas fa-map-marker-alt" />
            <a
                key={ `${ lat }-${ lng }-link` }
                className="link-to-map"
                href={ createMapSearchUrl({ lat, lng }) }
                target="_blank"
                rel="noopener noreferrer"
            >
                { children || `${ shortCoords.lat },${ shortCoords.lng }` }
            </a>
        </div>
    ) : null;
};

Component.propTypes = {
    location: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
    className: PropTypes.string,
};

Component.defaultProps = {
    location: undefined,
    className: '',
};

export default Component;
