import React from 'react';
import PropTypes from 'prop-types';
import ListView from 'components/ListView';
import ListRow from './ListRow';
import ListHeader from './ListHeader';

const Component = ({ sortable, onSort, list }) => {
    return (
        <ListView
            header={ ListHeader }
            list={ list }
            onSort={ onSort }
            rowHeight={ 56 }
            renderListRow={ ({ columns, rowData }) => {
                return (
                    <ListRow
                        key={ `passHistory-${ rowData.user_pass_id }` }
                        columns={ columns }
                        data={ rowData }
                    />
                ) ;
            } }
            dataKey="user_pass_id"
        />
    );
};

Component.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape()
    ),
    sortable: PropTypes.bool,
    onSort: PropTypes.func,
};

Component.defaultProps = {
    list: [],
    sortable: false,
    onSort: () => {},
};

export default Component;
