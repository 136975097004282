import { NEW_MISSION, NEW_AD, NEW_COUPON, NEW_PROMOTION } from 'constants/routes';
import { USER_IDS } from 'constants/promotion';

const copyMission = (origin) => {
    const { id,
            title,
            web_url,
            url_launch_mode,
            criteria_type,
            criteria_value,
            reward_coupon_id,
            reward_coupon_title,
        } = origin;
    return {
        id,
        title,
        web_url,
        url_launch_mode,
        criteria_type,
        criteria_value,
        reward_coupon_title,
        reward_coupon_id,
    };
};

const copyAd = (origin) => {
    const { id, title, description, url_launch_mode, web_url, img_url, img_file_name  } = origin;
    return {
        id,
        title,
        description,
        url_launch_mode,
        web_url,
        img_url,
        img_file_name
    };
};

const commonHammer = (copiedObject) => {
    return copiedObject;
};

const copyCoupon = (origin) => {
    return {
        ...origin,
        convert_from: undefined,
        convert_to: undefined,
        valid_from: undefined,
        valid_to: undefined,
        valid_duration: undefined,
    };
};

const copyPromotion = (origin) => {
    return {
        ...origin,
        create_time: undefined,
        update_time: undefined,
        schedule_time: undefined,
        user_list_file: undefined,
        user_list_type: USER_IDS,
        process_result_file: undefined,
        process_state: undefined,
        publisher: undefined,
        publish_time: undefined,
    }
};

export const COPY_TYPE_MISSION = 'mission';
export const COPY_TYPE_AD = 'ad';
export const COPY_TYPE_COUPON = 'coupon';
export const COPY_TYPE_PROMOTION = 'promotion';

export const COPY_CONFIG = {
    [COPY_TYPE_MISSION]: {
        redirect: NEW_MISSION,
        method: copyMission,
        copyHammer: commonHammer,
    },
    [COPY_TYPE_AD]: {
        redirect: NEW_AD,
        method: copyAd,
        copyHammer: commonHammer,
    },
    [COPY_TYPE_COUPON]: {
        redirect: NEW_COUPON,
        method: copyCoupon,
        copyHammer: commonHammer,
    },
    [COPY_TYPE_PROMOTION]: {
        redirect: NEW_PROMOTION,
        method: copyPromotion,
        copyHammer: commonHammer,
    }
};

export const setFastCopied = (type, obj) => {
    sessionStorage.setItem(`${ type }FastCopied`, JSON.stringify(obj));
    return;
};

export const getFastCopied = (type) => {
    const string = sessionStorage.getItem(`${ type }FastCopied`);
    return JSON.parse(string);
};

export const clearFastCopied = (type) => {
    sessionStorage.removeItem(`${ type }FastCopied`);
    return;
};
export const hasFastCopied = (type) => {
    return (getFastCopied(type) !== null);
};

export const getCopiedObject = (type, init = {}) => {
    const copiedState = getFastCopied(type);
    const { id, ...props } = copiedState || {};
    const { copyHammer } = COPY_CONFIG[type];
    if (!id) {
        return null;
    }
    else {
        return copyHammer({
            ...init,
            ...props,
            status: undefined,
        });
    }

};

const createFile = async(url) => {
    const fileName = url.split('?')[0].split('/').at(-1);
    const metadata = {
        type: `image/${ fileName.split('.').at(-1)}`,
    };
    let response = await fetch(url, {
        mode: 'cors',
        cache: 'no-store'
    });
    let data = await response.blob();

    return new File([data], fileName, metadata);
};

export const copiedImgUrlToFile = async(image, input) => {
    if (image) {
        const imgFile = await createFile(image);
        const dt = new DataTransfer();
        const event = new Event('change', {
            bubbles: true,
        });

        dt.items.add(imgFile);
        if (input.current) {
            input.current.files = dt.files;
            input.current.dispatchEvent(event);
        }
    }
    return;

};
