import { Map, List } from 'immutable';
import creater from './util/creater';
import {
    FETCH_MISSION_LIST,
    FETCH_ONE_MISSION,
    CLEAN_ONE_MISSION,
    UPDATE_ONE_MISSION,
    FETCH_ORDERABLE_MISSION_LIST,
    ORDER_MISSION_LIST,
    UPDATE_MISSION_ORDER,
} from 'constants/action-type';

const initialState = Map({
    missionList: Map({}),
    oneMission: Map({}),
    orderableList: Map({
        data_list: List([]),
    })
});

const actionMap = {
    [FETCH_MISSION_LIST](state, action) {
        const { page_index, page_count, total, data_list, __responseTime } = action.data;

        return state.merge({
            missionList: Map({
                page_index,
                page_count,
                total,
                data_list: List(data_list),
                __responseTime,
            })
        });
    },

    [FETCH_ONE_MISSION](state, action) {
        return state.merge({
            oneMission: Map(action.data),
        });
    },

    [UPDATE_ONE_MISSION](state, action) {
        return state.merge({
            oneMission: Map(action.data),
        });
    },

    [CLEAN_ONE_MISSION](state) {
        return state.merge({
            oneMission: Map({}),
        });
    },
    [FETCH_ORDERABLE_MISSION_LIST]: reOrderMissionList,
    [UPDATE_MISSION_ORDER]: reOrderMissionList,
    [ORDER_MISSION_LIST]: (state, action) => {
        const { currentIndex, nextIndex } = action.data;
        const data_list = state.getIn(['orderableList', 'data_list']);
        const currentMission = data_list.get(currentIndex);
        const newDataList = data_list
            .delete(currentIndex)
            .insert(nextIndex, currentMission)
            .map((mission, index) => ({...mission, order: index}));

            return state.merge({
                orderableList: Map({
                    data_list: newDataList
                })
            });
    }

};

function reOrderMissionList(state, action) {
    const { data_list } = action.data;
    data_list.sort((a,b) => (a.order - b.order));

    return state.merge({
        orderableList: Map({
            data_list: List(data_list.map((item, index) => ({ ...item, order: index }))),
        }),
    });
}

export default creater(actionMap, initialState);
