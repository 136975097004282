import React from 'react';
import PropTypes from 'prop-types';
import PageItem from './PageItem';
import classNames from 'classnames';
import { Translate, I18n } from 'react-redux-i18n';
import FlexGroup from 'components/FlexGroup';
import Datetime from 'components/Datetime';
import { thousandsSeparator } from 'helpers/util';

import './pagination.scss';


const PREV_PAGE_WINDOW = 'PREV_PAGE_WINDOW';
const PREV_PAGE = 'PREV_PAGE';
const NEXT_PAGE = 'NEXT_PAGE';
const NEXT_PAGE_WINDOW = 'NEXT_PAGE_WINDOW';

const Pagination = ({
    className,
    itemClass,
    page,
    total,
    size,
    onSelect,
    hidePageControl,
    center,
}) => {
    const createPageWindow = (page, total, pagePerWin) => {
        let expansion = pagePerWin / 2;
        let win = []; let min = 0; let max = -1;
        // 0 pages
        if (page <= 0 || total <= 0 || pagePerWin <= 0) {
            min = 0;
            max = -1;
        }
        // in first page window
        else if (page < 1 + expansion) {
            min = 1;
            max = pagePerWin;
        }
        // in last page window
        else if (page + expansion > total) {
            min = total - pagePerWin + 1;
            max = total;
        }
        // in other page window
        else {
            min = page - Math.ceil(expansion) + 1;
            max = page + Math.floor(expansion);
        }
        for (let i = min; i <= max; i++) {
            win.push(i);
        }
        return win;
    };

    const pagePerWin = total < size ? total : size;
    const pageWindow = createPageWindow(page, total, pagePerWin);
    const customClass = classNames({
        'pagination': true,
        'justify-content-center': center,
        [className]: className,
    });
    const Pages = ({ selected, itemClass, window, onSelect }) => window.map((page, i) => (
        <PageItem
            key={ `page-${page}` }
            className={ classNames({
                'active': selected === page,
                [itemClass]: itemClass,
            }) }
            onClick={ () => onSelect(page) }
        >
            <span className="page-link num-page">{ page }</span>
        </PageItem>
    ) );

    const NavPageItem = ({ type, children, onClick, }) => {
        let disabled = true;
        let goToPage = page;
        let customClassName;

        switch (type) {
        case PREV_PAGE_WINDOW:
            // in first page window
            disabled = page <= pagePerWin && page <= 1;
            goToPage = (page - pagePerWin > 0 ? page - pagePerWin : 1);
            customClassName = classNames({
                'prev-page-win': true,
                'disabled': disabled,
                [itemClass]: itemClass,
            });
            break;
        case PREV_PAGE:
            // in first page
            disabled = page === 1;
            goToPage = page - 1;
            customClassName = classNames({
                'prev-page': true,
                'disabled': disabled,
                [itemClass]: itemClass,
            });
            break;
        case NEXT_PAGE:
            // in last page
            disabled = page === total;
            goToPage = page + 1;
            customClassName = classNames({
                'next-page': true,
                'disabled': disabled,
                [itemClass]: itemClass,
            });
            break;
        case NEXT_PAGE_WINDOW:
            // in last page window
            disabled = (page >= total - pagePerWin + 1) && page >= total;
            goToPage = (page + pagePerWin > total ? total : page + pagePerWin);
            customClassName = classNames({
                'next-page-win': true,
                'disabled': disabled,
                [itemClass]: itemClass,
            });
            break;
        default:
            break;
        }
        return (
            <PageItem className={ customClassName } onClick={ disabled ? null : () => onClick(goToPage) }>
                { children }
            </PageItem>
        );
    };

    return (<>
        {
            (page > 0 && total > 1 && size > 1)? (
                <nav>
                    <ul className={ customClass }>
                        {
                            !hidePageControl[0] ?
                                (
                                    <NavPageItem type={ PREV_PAGE_WINDOW } onClick={ onSelect }>
                                        <span className="page-link icon fas fa-angle-double-left" />
                                    </NavPageItem>
                                ) : null
                        }
                        {
                            !hidePageControl[1] ?
                                (
                                    <NavPageItem type={ PREV_PAGE } onClick={ onSelect }>
                                        <span className="page-link icon fas fa-angle-left" />
                                    </NavPageItem>
                                ) : null
                        }
                        <Pages selected={ page } window={ pageWindow } onSelect={ onSelect } itemClass={ itemClass } />
                        {
                            !hidePageControl[2] ?
                                (
                                    <NavPageItem type={ NEXT_PAGE } onClick={ onSelect }>
                                        <span className="page-link icon fas fa-angle-right" />
                                    </NavPageItem>
                                ) : null
                        }
                        {
                            !hidePageControl[3] ?
                                (
                                    <NavPageItem type={ NEXT_PAGE_WINDOW } onClick={ onSelect }>
                                        <span className="page-link icon fas fa-angle-double-right" />
                                    </NavPageItem>
                                ) : null
                        }
                    </ul>
                </nav>
            ) : null
        }
    </>);
};
Pagination.propTypes = {
    className: PropTypes.string,
    itemClass: PropTypes.string,
    // current page
    page: PropTypes.number,
    // total pages
    total: PropTypes.number,
    // pages per window. eg size=5, the first page window would be [1,2,3,4,5]
    size: PropTypes.number,
    onSelect: PropTypes.func,
    hidePageControl: PropTypes.arrayOf(PropTypes.bool),
    center: PropTypes.bool,
};
Pagination.defaultProps = {
    page: 0,
    total: 0,
    size: 6,
    className: '',
    itemClass: '',
    onSelect: () => {},
    hidePageControl: [false, true, true, false],
    center: true,
};

export const UpdateTime = ({ time, className }) =>
    time ?
        (
            <p className={ `last-updated ${className}` }>
                <Translate value="scooter.last_udpated" />
                <span>: </span>
                <Datetime className="profile-item" time={ time } />
            </p>
        ) : null;
UpdateTime.propTypes = {
    time: PropTypes.node,
    className: PropTypes.string,
};
UpdateTime.defaultProps = {
    time: undefined,
    className: '',
};

export const RefreshButton = ({ onClick, disabled }) => {
    return (
        <button className="btn-refresh" disabled={ disabled } onClick={ onClick } type="button">
            <span className="fas fa-redo" />
        </button>
    );
};
RefreshButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};
RefreshButton.defaultProps = {
    onClick: () => {},
    disabled: false,
};

export function Refresh({ onClick, disabled, time, timeClass }) {
    return (
        <FlexGroup gap className="refresh">
            { time && (
                <UpdateTime className={ timeClass } time={ time.format(I18n.t('datetime_format.long')) } />
            ) }
            { onClick && (
                <RefreshButton onClick={ onClick } disabled={ disabled } />
            ) }
        </FlexGroup>
    );
}

Refresh.propTypes = {
    time: PropTypes.shape(),
    timeClass: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

Refresh.defaultProps = {
    time: undefined,
    timeClass: '',
    onClick: undefined,
    disabled: false,
};

export const TotalCount = ({ page, offset, count }) => {
    let totalCount = null;
    if (page && offset) {
        const end = page * offset;
        const start = end - offset;
        totalCount = (
            <p className="total-count">
                <Translate className="label" value="total_count" />
                { `${ start }~${ end } / ${ thousandsSeparator(count) }` }
            </p>
        );
    }

    if (count !== undefined) {
        totalCount = (
            <p className="total-count">
                <Translate className="label" value="total_count" />
                <span>{ thousandsSeparator(count) }</span>
            </p>
        );
    }
    return totalCount;
};
TotalCount.propTypes = {
    count: PropTypes.number,
    page: PropTypes.number,
    offset: PropTypes.number,
};
TotalCount.defaultProps = {
    count: undefined,
    page: 0,
    offset: 0,
};

export default Pagination;
