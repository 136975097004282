import React from 'react';
import { connect } from 'react-redux';
import AuthView from 'components/AuthView';
import {
    updateDocumentTitle,
    fetchRentalHistory,
    clearRentalHistory,
} from 'actions';
import { convertToUTC } from 'helpers/time-handler';
import moment from 'moment';
import { getDefaultPageSize } from 'components/Pagination/PageSizeSelect';
import Filter, {
    defaultRange,
    SEARCH,
    SEARCH_BY_RENTAL,
    SEARCH_BY_PLATE,
    SEARCH_BY_CUSTOMER,
    RENTAL_STATUS,
    PAYMENT_STATUS,
    CORPORATE_TYPE,
    RESERVE_TIME,
} from './Filter';
import List from './List';

import './rental-management.scss';

class RentalManagement extends React.Component {
    constructor(props) {
        super(props);

        this.searchType = SEARCH_BY_RENTAL;
        const { start } = this.getDefaultPeriod();

        this.state = {
            appliedDateRange: false,
            fromTime: moment(start),
            toTime: moment(),
            queryPayload: {
                filter: {
                    [SEARCH]: undefined,
                    [SEARCH_BY_RENTAL]: undefined,
                    [SEARCH_BY_PLATE]: undefined,
                    [SEARCH_BY_CUSTOMER]: undefined,
                    [RENTAL_STATUS]: undefined,
                    [PAYMENT_STATUS]: undefined,
                    [CORPORATE_TYPE]: undefined,
                    reserve_start_time: undefined,
                    reserve_end_time: undefined,
                },
                sort: {},
                size: getDefaultPageSize().value,
                page: 1,
            },
        };
    }

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { pathname } = location;

        dispatch(updateDocumentTitle(`routes.${ pathname }`));
    }

    componentDidUpdate(prevProps, prevState) {
        const { queryPayload } = this.state;

        if (prevState.queryPayload !== queryPayload) {
            this.fetchHistory();
        }

    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearRentalHistory());
    }

    getDefaultPeriod = () => {
        return {
            start: moment().subtract(...defaultRange).format('YYYY-MM-DD'),
            end: moment().startOf('day').add(1, 'd'),
        };
    }

    updateQueryPayload = (obj, otherStates) => {
        let period = {};
        period = this.getDefaultPeriod();

        const { queryPayload } = this.state;
        const { filter } = queryPayload;
        const { reserve_start_time, reserve_end_time } = filter;
        this.setState({
            fromTime: reserve_start_time ? undefined : period.start,
            toTime: reserve_end_time ? undefined : moment(),
            queryPayload: {
                ...queryPayload,
                ...obj,
            },
            ...otherStates,
        });
    }

    fetchHistory = () => {
        let period = this.getDefaultPeriod();

        const { dispatch } = this.props;
        const { queryPayload } = this.state;
        const { filter, sort, size, page } = queryPayload;
        const { reserve_start_time, reserve_end_time } = filter;
        const updatedFilter = {
            ...filter,
            reserve_start_time: convertToUTC(reserve_start_time || period.start),
            reserve_end_time: convertToUTC(reserve_end_time || period.end),
        };

        return dispatch(fetchRentalHistory({
            ...updatedFilter,
            sort,
            page,
            size,
        }));
    }

    handleSort = ({ field, order }) => {
        this.updateQueryPayload({
            page: 1,
            sort: (field && order) ? `${field},${order}` : undefined,
        });

    }

    handleSearchTypeChange = e => {
        this.searchType = e.target.value;

    };

    handleApplyFilter = type => value => {
        const { queryPayload } = this.state;
        let { filter } = queryPayload;
        const updatedState = {};

        switch (type) {
        case SEARCH:
            this.keyword = value === '' ? undefined : value;
            filter = {
                [SEARCH_BY_RENTAL]: undefined,
                [SEARCH_BY_PLATE]: undefined,
                [SEARCH_BY_CUSTOMER]: undefined,
                [this.searchType]: this.keyword,
            };

            updatedState.appliedDateRange = false;
            break;
        case RENTAL_STATUS:
        case PAYMENT_STATUS:
        case CORPORATE_TYPE:
            filter[type] = value.inputSelect;
            break;
        case RESERVE_TIME:
            const { from, to } = value;
            filter.reserve_start_time = from;
            filter.reserve_end_time = to;

            updatedState.appliedDateRange = !!(from && to);
            break;
        default:
            filter[type] = value;
        }

        queryPayload.filter = { ...filter };
        this.updateQueryPayload({
            page: 1,
            filter: { ...filter },

        }, updatedState);

    }

    handlePageSelect = page => {
        this.updateQueryPayload({
            page: page,
        });
    }

    handlePageSizeChange = pageSize => {
        this.updateQueryPayload({
            page: 1,
            size: pageSize,
        });
    }

    setKeyword = value => {
        this.keyword = value;
    }

    render() {
        const { fromTime, toTime, appliedDateRange, queryPayload } = this.state;
        const { filter, size: pageSize } = queryPayload;

        return (
            <AuthView className="rental-management">
                <Filter
                    defaultChecked={ this.searchType }
                    onChoiceChange={ this.handleSearchTypeChange }
                    applyFilter={ this.handleApplyFilter }
                    value={ this.keyword }
                    onChangeKeyword={ this.setKeyword }
                    appliedDateRange={ appliedDateRange }
                    filter={ filter }
                />

                <List
                    onSort={ this.handleSort }
                    onSelect={ this.handlePageSelect }
                    onChange={ this.handlePageSizeChange }
                    pageSize={ pageSize }
                    onFetch={ this.fetchHistory }
                    fromTime={ fromTime }
                    toTime={ toTime }
                />
            </AuthView>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
}))(RentalManagement);
