import { useState, useEffect, useCallback, useRef } from 'react';
import { store as rtkStore } from "redux/store";
import { dashboardApi } from "redux/services/dashboardApi";
import isEqual from 'react-fast-compare';
import { addListener } from '@reduxjs/toolkit';

export const useGetStatisticsQuery = (params, config) => {
    const [resp, setResp] = useState(undefined);
    const { getStatistics } = dashboardApi.endpoints;
    const [fetchConfig, setFetchConfig] = useState(config);
    const subRef = useRef();
    const fetch = useCallback(
      async () => {
        const { pollingInterval } = fetchConfig || 0;
        const pollingConfig = pollingInterval ? {
            subscribe: true,
            subscriptionOptions: {
                pollingInterval,
            },
        } : {};

        subRef?.current?.unsubscribe();
        subRef.current = rtkStore.dispatch(getStatistics.initiate({ params }, {
            forceRefetch: true,
            ...fetchConfig,
            ...pollingConfig,
        }));
        const response = await subRef.current;
        setResp(response);

      },
      [params, fetchConfig, getStatistics],
    );

    useEffect(() => {
        if(!isEqual(config, fetchConfig)) {
            setFetchConfig(config);
        }
    }, [config, fetchConfig]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    useEffect(() => {

        return () => {
            subRef?.current?.unsubscribe();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const unsubscribe = rtkStore.dispatch(
            addListener({
                predicate: () => true,
                effect: async (action, listenerApi) => {
                    const { type, payload } = action;
                    const isFullfilled = type.includes(dashboardApi.reducerPath) && type.includes('fulfilled');
                    if (isFullfilled) {
                        const { queryCacheKey } = subRef.current;
                        const cache = await listenerApi.getState()[dashboardApi.reducerPath].queries;
                        const prevTime = resp?.fulfilledTimeStamp;
                        const currentTime = cache[queryCacheKey].fulfilledTimeStamp;
                        const isError = payload?.error;
                        if (!isError && currentTime && prevTime && currentTime > prevTime) {
                            setResp({
                                ...cache[queryCacheKey],
                                isLoading: false,
                            });
                        }
                    }
                },
            })
        );
        return () => {
            (typeof unsubscribe === 'function') && unsubscribe();
        }
    }, [resp]);

    return  {
        ...resp,
        refetch: fetch,
    };
};
