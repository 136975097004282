import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoCard from 'components/InfoCard';
import FlexGroup from 'components/FlexGroup';
import { Translate } from 'react-redux-i18n';
import {
    getTicketCounts,
} from 'helpers/dashboard';
import {
    TICKETS_CATEGORY_BY_ORDER,
} from 'constants/dashboard';

import './dots-detail.scss';



const DotsDetail = ({ title, className, list, selected, onClick }) => {

    const CardStyle = classNames({
        'dots-detail': true,
        [className]: true,
    });

    const renderListHeader = () => {

        return (
            <FlexGroup tag="li" className="list-row header">
                <Translate className="caption" value="dashboard.service_type_dots" />
                {
                    list.map(item => {
                        const style = classNames({
                            'caption': true,
                            'selected': selected.zone_id === item.zone_id,
                        });
                        return (
                            <span
                                className={ style }
                                key={ item.zone_id }
                                onClick={ onClick(item) }
                            >
                                { item.name }
                                <b className="clickable-area" />
                            </span>
                        );
                    })
                }
            </FlexGroup>
        );
    };

    const renderOnlineCounts = () => {
        return (
            <FlexGroup tag="li" className="list-row">
                <Translate
                    className="caption"
                    value="scooter.activation.on"
                />
                {
                    list.map( item => (
                        <span
                            className="counts"
                            key={ item.zone_id }
                        >
                            { item.online }
                        </span>
                    ))
                }
            </FlexGroup>
        );
    };

    const renderTicketCountField = (category) => {

        return (
            <>
                {
                    list.map( item => {
                        const counts = getTicketCounts(category, item);
                        const style = classNames({
                            'counts': true,
                            'warning': false,
                        });
                        return (
                            <span
                                className={ style }
                                key={ `${ item.service_level }${ item.zone_id }` }
                            >
                                { counts }
                            </span>);
                    })
                }
            </>
        );
    };

    const renderTicketList = () => {
        return (
            <>
                {
                    TICKETS_CATEGORY_BY_ORDER.map( category => {
                        const style = category.style.substr(1);
                        return (
                            <FlexGroup tag="li" className="list-row" key={ category.id }>
                                <Translate
                                    className={ `caption ticket-${ style }` }
                                    value={ `dashboard.tickets_category_${category.id}` }
                                    key={ category.id }
                                />
                                { renderTicketCountField(category.id) }
                            </FlexGroup>
                        );
                    })
                }
            </>
        );
    };



    return (
        <>
            {
                list && list.length ? (
                    <InfoCard className={ CardStyle }>
                        <FlexGroup
                            tag="ul"
                            className="list"
                        >
                            { renderListHeader() }
                            { renderOnlineCounts() }
                            { renderTicketList() }
                        </FlexGroup>
                    </InfoCard>
                ) : null
            }
        </>
    );
};

DotsDetail.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.shape({})),
    selected: PropTypes.shape({}),
    onClick: PropTypes.func,
};

DotsDetail.defaultProps = {
    title: '',
    className: '',
    list: [],
    selected: {},
    onClick: () => {},
};

export default DotsDetail;
