import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, } from 'immutable';
import { I18n } from 'react-redux-i18n';
import MultiselectFilter from './MultiselectFilter';
import {
    getVipLayer,
} from 'actions';

class VipScooterFilter extends React.Component {
    static propTypes = {
        defaultSelected: PropTypes.arrayOf(PropTypes.array),
        onChange: PropTypes.func.isRequired,
        vips: PropTypes.oneOfType([
            PropTypes.object,
        ]),
    }

    static defaultProps = {
        defaultSelected: [],
        vips: List([]),
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getVipLayer());
    }

    render() {
        const { defaultSelected, onChange, vips } = this.props;
        const vipOptions = vips.toJS().map(({ service_ids, corporate_name }) => ({
            value: service_ids,
            text: corporate_name
        }));
        return (
            <MultiselectFilter
                title={ I18n.t('scooter.corporate') }
                options={ vipOptions }
                defaultSelected={ defaultSelected }
                onChange={ onChange }
            />
        );
    }
}

export default connect(state => ({
    vips: state.map.get('vips'),
}))(VipScooterFilter);
