import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-redux-i18n';
import {
    CLAIM_TYPE_NO_CLAIM,
    CLAIM_TYPE_CLAIM,
} from 'constants/additional-fee';

import './claim-type.scss';

const COLOR_CONFIG = {
    [CLAIM_TYPE_CLAIM]: 'danger',
    [CLAIM_TYPE_NO_CLAIM]: 'dark',
};

const FeeType = ({ type, className, badge }) => {
    const bagdeStyle = COLOR_CONFIG[type];
    const style = classNames({
        'claim-type': true,
        [className]: className ? true : false,
        [bagdeStyle]: badge,
    });
    let el;
    switch (type) {
    case CLAIM_TYPE_CLAIM:
    case CLAIM_TYPE_NO_CLAIM:
        el = <Translate value={ `additional_fee.claim_type_map.${ type }` } />;
        break;
    default:
        el = <span>--</span>;
    };

    return (
        <div className={ style }>
            { el }
        </div>
    );
};
FeeType.propTypes = {
    type: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    className: PropTypes.string,
    badge: PropTypes.bool,
};
FeeType.defaultProps = {
    type: undefined,
    className: undefined,
    badge: false,
};
export default FeeType;
