/**
 * Reducer - Cities
 */
 import { Map, List } from 'immutable';
 import {
    FETCH_CITIES,
 } from 'constants/action-type';
 import creater from './util/creater';

 const initialState = Map({
     cities: List([]),
 });

 const actionsMap = {
     [FETCH_CITIES]: (state, action) => {
         return state.merge({
            cities: List(action.data),
        });
     },
 };

 export default creater(actionsMap, initialState);
