/**
 * Action - mission
 */
import api from 'api';
import { asyncInterface } from './util';
import {
    FETCH_MISSION_LIST,
    CLEAN_ONE_MISSION,
    FETCH_ONE_MISSION,
    UPDATE_ONE_MISSION,
    FETCH_ORDERABLE_MISSION_LIST,
    ORDER_MISSION_LIST,
    UPDATE_MISSION_ORDER,
} from 'constants/action-type';

export const fetchMissionList = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchMissionList(params),
        type: FETCH_MISSION_LIST,
        dispatch,
    });
};

export const cleanOneMission = () => {
    return {
        type: CLEAN_ONE_MISSION,
    };
};

export const fetchOneMission = id => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchOneMission(id),
        type: FETCH_ONE_MISSION,
        dispatch,
    });
};

export const updateOneMission = (id, params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.updateOneMission(id, params),
        type: UPDATE_ONE_MISSION,
        dispatch,
    });
};

export const createOneMission = (params = {}) => {
    return api.createOneMission(params);
};

export const fetchOrderableMissionList = () => dispatch => {
    const params = { filter_by_order: true };
    return asyncInterface({
        type: FETCH_ORDERABLE_MISSION_LIST,
        asyncAction: api.fetchMissionList(params),
        dispatch,
    });
};

export const orderMissionList = (currentIndex, nextIndex) => {
    return {
        type: ORDER_MISSION_LIST,
        data: { currentIndex, nextIndex }
    };
};

export const updateMissionOrder = () => (dispatch, getState) => {
    const missions = getState().mission
                        .getIn(['orderableList', 'data_list'])
                        .toJS()
                        .map(({id, order}) => ({id, order}));
    return asyncInterface({
        type: UPDATE_MISSION_ORDER,
        asyncAction: api.updateMissionOrder(missions),
        dispatch,
    });
};
