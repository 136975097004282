import React from 'react';
import moment from 'moment';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Button, Badge } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import {
    updateDocumentTitle,
    cleanOneMission,
    fetchOneMission,
    updateOneMission,
    toggleErrorDialog,
    createOneMission,
} from 'actions';
import AuthFeature from 'components/AuthFeature';
import AuthView from 'components/AuthView';
import Form from 'components/Form';
import NavigationBar from 'components/NavigationBar';
import Smart from 'components/Smart';
import FlexGroup from 'components/FlexGroup';
import { Refresh } from 'components/Pagination';
import SystemCouponSelect from 'components/SystemCouponSelect/SystemCouponSelect';
import Input, {
    TYPE_RADIO,
    TYPE_TEXT,
    TYPE_URL,
    TYPE_DATETIME,
    TYPE_NUMBER,
} from 'components/Form/Input';
import {
    STATUS_DISABLED,
    STATUS_DRAFT,
    STATUS_PUBLISHED,
    STATUS_LIVED,
    STATUS_EXPIRED,
    URL_LAUNCH_MODE_GROUP,
    IN_APP_WEBVIEW,
    CRITERIA_TYPE_GROUP,
    TRIP_COUNT,
    TRIP_DISTANCE,
} from 'constants/mission';
import { PROMOTE_TYPE_MISSION } from 'constants/coupon';
import {
    MISSION_DETAIL,
} from 'constants/routes';

import {
    AUTH_EDIT_MISSION,
    AUTH_PUBLISH_MISSION,
    AUTH_WITHDRAW_MISSION,
} from 'constants/permission';
import { convertToUTC } from 'helpers/time-handler';
import { empty } from 'helpers/util';
import buildActualPath from 'helpers/build-actual-path';
import FastCopy from 'components/FastCopy';
import { COPY_TYPE_MISSION, getCopiedObject, hasFastCopied, clearFastCopied } from 'helpers/fast-copy';
import './mission-form.scss';

const STATE_ID = 'id';
const STATE_TITLE = 'title';
const STATE_WEB_URL = 'web_url';
const STATE_URL_LAUNCH_MODE = 'url_launch_mode';
const STATE_CRITERIA_TYPE = 'criteria_type';
const STATE_CRITERIA_VALUE = 'criteria_value';
const STATE_STATUS = 'status';
const STATE_VALID_FROM = 'valid_from';
const STATE_VALID_TO = 'valid_to';
const STATE_REWARD_COUPON_ID = 'reward_coupon_id';
const STATE_REWARD_COUPON_TITLE = 'reward_coupon_title';
const STATE_CREATE_TIME = 'create_time';
const STATE_UPDATE_TIME = 'update_time';
const STATE_PUBLISH_TIME = 'publish_time';
const STATE_PUBLISHER = 'publisher';

class MissionForm extends React.Component {

    static propTypes = {
        oneMission: ImmutablePropTypes.map.isRequired,
    }

    constructor(props) {
        super(props);

        const { missionId } = props.match.params;

        this.state = {
            createMode: missionId === undefined,
            viewMode: missionId !== undefined,
            updating: false,
            mission: {
                [STATE_ID]: '',
                [STATE_TITLE]: '',
                [STATE_WEB_URL]: '',
                [STATE_URL_LAUNCH_MODE]: IN_APP_WEBVIEW,
                [STATE_CRITERIA_TYPE]: TRIP_COUNT,
                [STATE_CRITERIA_VALUE]: 0,
                [STATE_STATUS]: undefined,
                [STATE_VALID_FROM]: '',
                [STATE_VALID_TO]: '',
                [STATE_REWARD_COUPON_ID]: '',
                [STATE_REWARD_COUPON_TITLE]: '',
                [STATE_CREATE_TIME]: '',
                [STATE_UPDATE_TIME]: '',
                [STATE_PUBLISH_TIME]: '',
                [STATE_PUBLISHER]: '',
            }
        };
    }

    componentDidMount() {
        const { createMode } = this.state;
        const { dispatch } = this.props;

        const key = createMode ? 'mission.new_mission' : 'mission.view_title';
        dispatch(updateDocumentTitle(key));
    }

    componentDidUpdate(prevProps) {
        const { oneMission: prevOneMission } = prevProps;
        const { oneMission, dispatch } = this.props;
        const { createMode, mission } = this.state;
        const hasCopied = hasFastCopied(COPY_TYPE_MISSION);
        const copiedMission = getCopiedObject(COPY_TYPE_MISSION, mission);

        if (prevOneMission !== oneMission) {
            if (createMode && hasCopied) {
                this.setState({
                    ...this.state,
                    mission: copiedMission,
                }, () => {
                    clearFastCopied(COPY_TYPE_MISSION);
                });
            }
            else {
                this.setMissionDetailState();
            }
            const key = createMode ? 'mission.new_mission' : 'mission.view_title';
            dispatch(updateDocumentTitle(key));
        }

    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(cleanOneMission());
    }

    setMissionDetailState() {
        const { oneMission } = this.props;
        const {
            valid_from,
            valid_to,
            create_time,
            update_time,
            publish_time,
            ...restDetail
        } = oneMission.toJS();

        this.setState({
            mission: {
                [STATE_VALID_FROM]: valid_from && moment(valid_from),
                [STATE_VALID_TO]: valid_to && moment(valid_to),
                [STATE_CREATE_TIME]: create_time && moment(create_time),
                [STATE_UPDATE_TIME]: update_time && moment(update_time),
                [STATE_PUBLISH_TIME]: publish_time && moment(publish_time),
                ...restDetail
            },
        });
    }

    openViewMode = () => {
        this.setState({
            viewMode: true
        });
        this.setMissionDetailState();
    }

    toggleViewMode = () => {
        this.setState(state => ({
            viewMode: !state.viewMode
        }));
    }

    toggleUpdating = () => {
        this.setState(state => ({
            updating: !state.updating,
        }));
    }

    fetchData = () => {
        const { viewMode, createMode } = this.state;
        if (!viewMode) {
            return ;
        }

        if (createMode) {
            return ;
        }

        const { dispatch, match } = this.props;
        const { missionId } = match.params;

        return dispatch(fetchOneMission(missionId));
    }

    handleChange = name => value => {
        switch (name) {
        case STATE_TITLE:
        case STATE_WEB_URL:
        case STATE_CRITERIA_VALUE:
            value = value.target.value;
            break;
        case STATE_REWARD_COUPON_ID:
            ({ value } = value);
            break;
        case STATE_URL_LAUNCH_MODE:
        case STATE_CRITERIA_TYPE:
            [value] = value;
            break;
        case STATE_VALID_FROM:
        case STATE_VALID_TO:
        default:
            break;
        }

        this.setState(state => {
            const { mission } = state;

            return {
                mission: {
                    ...mission,
                    [name]: value,
                }
            };
        });
    }

    handleStatusChanged = status => async () => {
        const { updating } = this.state;
        if (updating) {
            return;
        }

        const { oneMission, dispatch } = this.props;
        try {
            const missionDetails = oneMission.toJS();
            const { id } = missionDetails;
            this.toggleUpdating();
            const { error } = await dispatch(updateOneMission(id, { status }));

            if (error) {
                throw error;
            }
        }
        catch (error) {
            this.handleError(error);
        }
        finally {
            this.toggleUpdating();
        }
    }

    handleCreateSubmit = async e => {
        const { dispatch, history } = this.props;
        const { updating } = this.state;
        if (updating) {
            return;
        }

        let {
            url_launch_mode,
            criteria_type,
            criteria_value,
            valid_from,
            valid_to,
            ...restFormData
        } = e.formData;
        url_launch_mode = Number(url_launch_mode);
        criteria_type = Number(criteria_type);
        criteria_value = Number(criteria_value);
        valid_from = convertToUTC(valid_from);
        valid_to = convertToUTC(valid_to);

        try {
            this.toggleUpdating();
            const formData = {
                url_launch_mode,
                criteria_type,
                criteria_value,
                valid_from,
                valid_to,
                ...restFormData,
            };
            const { error, data } = await dispatch(createOneMission(formData));

            if (error) {
                throw error;
            }

            history.replace(buildActualPath(MISSION_DETAIL, { missionId: data.id }));
        }
        catch (error) {
            this.handleError(error);
        }
        finally {
            this.toggleUpdating();
        }
    }

    handleUpdateSubmit = async e => {
        const { updating } = this.state;
        if (updating) {
            return;
        }

        const { oneMission, dispatch } = this.props;
        const missionDetails = oneMission.toJS();
        const { id: missionId } = missionDetails;
        let {
            url_launch_mode,
            criteria_type,
            criteria_value,
            valid_from,
            valid_to,
            ...restFormData
        } = e.formData;
        url_launch_mode = Number(url_launch_mode);
        criteria_type = Number(criteria_type);
        criteria_value = Number(criteria_value);
        valid_from = convertToUTC(valid_from);
        valid_to = convertToUTC(valid_to);

        const diffFormData = this.diff({
            url_launch_mode,
            criteria_type,
            criteria_value,
            valid_from,
            valid_to,
            ...restFormData,
        }, missionDetails);

        if (empty(diffFormData)) {
            return;
        }

        // modifing criteria_type should include criteria_type together
        if (diffFormData.criteria_type !== undefined && diffFormData.criteria_value === undefined) {
            const { mission } = this.state;
            const { criteria_value }  = mission;
            diffFormData.criteria_value = criteria_value;
        }

        try {
            this.toggleUpdating();
            const { error } = await dispatch(updateOneMission(missionId, diffFormData));
            if (error) {
                throw error;
            }

            this.toggleViewMode();
        }
        catch (error) {
            this.handleError(error);
        }
        finally {
            this.toggleUpdating();
        }
    }

    handleError(error) {
        const { dispatch } = this.props;
        const { code } = error.response?.data ?? {};
        const message = code !== undefined ?
            I18n.t(`mission.error_message_${ code }`) :
            I18n.t('ticket.general_error_message');
        dispatch(toggleErrorDialog(message));
    }

    diff(formData, missionDetails) {
        return Object.keys(formData).reduce((accu, key) => {
            const oldValue = missionDetails[key];
            const newValue = formData[key];
            const isDiff = (() => {
                switch (key) {
                case STATE_VALID_FROM:
                case STATE_VALID_TO:
                    return !moment(newValue).isSame(oldValue);
                default:
                    return oldValue !== newValue;
                }
            })();

            if (isDiff) {
                accu[key] = newValue;
            }

            return accu;
        }, Object.create(null));
    }

    renderTitle(status, __responseTime) {
        const { viewMode } = this.state;

        return (
            <FlexGroup className="title-section" row spaceBetween>
                <FlexGroup start row alignCenter>
                    <Translate value="mission.details" className="details-title" tag="h4" />
                    { this.renderStatus(status) }
                </FlexGroup>
                { viewMode && (
                    <FlexGroup row end>
                        <Refresh onClick={ this.fetchData } time={ __responseTime } />
                    </FlexGroup>
                ) }
            </FlexGroup>
        );
    }

    renderStatus(status = STATUS_DRAFT) {
        let color;

        switch (status) {
        case STATUS_PUBLISHED:
        case STATUS_LIVED:
            color = 'success';
            break;
        case STATUS_DISABLED:
        case STATUS_EXPIRED:
            color = 'danger';
            break;
        case STATUS_DRAFT:
        default:
            color = 'dark';
            break;
        }
        return (
            <Badge color={ color }>
                <Translate value={ `mission.status_${ status }` } />
            </Badge>
        );
    }

    renderFunctionalButtons(status) {
        const { viewMode, createMode, updating } = this.state;
        const { oneMission } = this.props;
        const mission = oneMission.toJS();

        function ButtonsWrapper({ children }) {
            return (
                <FlexGroup end gap>
                    { children }
                </FlexGroup>
            );
        };

        if (!viewMode) {
            return (
                <ButtonsWrapper>
                    { !createMode ? (
                        <Button type="button" color="secondary" outline onClick={ this.openViewMode }>
                            <Translate value="cancel" />
                        </Button>
                    ) : <span /> }
                    <Button type="submit" color="primary" disabled={ updating }>
                        <Translate value="save" />
                    </Button>
                </ButtonsWrapper>
            );
        }

        switch (status) {
        case STATUS_DRAFT:
            return (
                <ButtonsWrapper>
                    <FastCopy type={ COPY_TYPE_MISSION } copyItem={ mission } />
                    <AuthFeature key="publish" requiredList={ [AUTH_PUBLISH_MISSION] }>
                        <Button type="button" onClick={ this.handleStatusChanged(STATUS_PUBLISHED) } outline>
                            <Translate value="mission.publish" />
                        </Button>
                    </AuthFeature>
                    <AuthFeature key="edit" requiredList={ [AUTH_EDIT_MISSION] }>
                        <Button type="button" color="primary" onClick={ this.toggleViewMode }>
                            <Translate value="mission.edit_mission_info" />
                        </Button>
                    </AuthFeature>
                </ButtonsWrapper>
            );
        case STATUS_PUBLISHED:
            return (
                <ButtonsWrapper>
                    <FastCopy type={ COPY_TYPE_MISSION } copyItem={ mission } />
                    <AuthFeature key="withdraw" requiredList={ [AUTH_PUBLISH_MISSION] }>
                        <Button type="button" color="danger" onClick={ this.handleStatusChanged(STATUS_DRAFT) }>
                            <Translate value="mission.withdraw" />
                        </Button>
                    </AuthFeature>
                </ButtonsWrapper>
            );
        case STATUS_LIVED:
            return (
                <ButtonsWrapper>
                    <FastCopy type={ COPY_TYPE_MISSION } copyItem={ mission } />
                    <AuthFeature key="withdraw" requiredList={ [AUTH_WITHDRAW_MISSION] }>
                        <Button type="button" color="danger" onClick={ this.handleStatusChanged(STATUS_DISABLED) }>
                            <Translate value="mission.withdraw" />
                        </Button>
                    </AuthFeature>
                </ButtonsWrapper>
            );
        case STATUS_DISABLED:
            return null;
        case STATUS_EXPIRED:
        default:
            return (
                <ButtonsWrapper>
                    <FastCopy type={ COPY_TYPE_MISSION } copyItem={ mission } />
                </ButtonsWrapper>
            );
        }
    }

    render() {
        const { oneMission } = this.props;
        const { title: pageTitle } = oneMission.toJS();
        const { viewMode, createMode, mission: missionDetails, updating } = this.state;

        const urlLaunchModeOptions = URL_LAUNCH_MODE_GROUP.map(mode => ({
            name: `mission.url_launch_mode_${ mode }`,
            value: mode,
            translate: true,
        }));
        const criteriaTypeOption = CRITERIA_TYPE_GROUP.map(type => ({
            name: `mission.criteria_type_${ type }`,
            value: type,
            translate: true,
        }));

        return (
            <AuthView>
                <Form
                    className="mission-form"
                    onSubmit={ createMode ? this.handleCreateSubmit : this.handleUpdateSubmit }
                    inProgress={ updating }
                    inline
                >
                    <NavigationBar sticky title={ pageTitle ?? I18n.t('mission.new_mission') }>
                        { this.renderFunctionalButtons(missionDetails.status) }
                    </NavigationBar>
                    <Smart
                        fetch={ this.fetchData }
                        pauseRefresh
                        seamless
                    >
                        { this.renderTitle(missionDetails.status, missionDetails.__responseTime) }
                        <FlexGroup className="mission-detail" alignStretch>
                            <Input
                                name={ STATE_TITLE }
                                type={ TYPE_TEXT }
                                caption="mission.title"
                                value={ missionDetails[STATE_TITLE] }
                                onChange={ this.handleChange(STATE_TITLE) }
                                viewMode={ viewMode }
                                required
                            />
                            <Input
                                name={ STATE_WEB_URL }
                                type={ TYPE_URL }
                                caption="mission.rule_link"
                                value={ missionDetails[STATE_WEB_URL] }
                                onChange={ this.handleChange(STATE_WEB_URL) }
                                viewMode={ viewMode }
                                required
                            />
                            <Input
                                name={ STATE_URL_LAUNCH_MODE }
                                type={ TYPE_RADIO }
                                caption="mission.url_launch_mode"
                                value={ urlLaunchModeOptions }
                                selected={ missionDetails[STATE_URL_LAUNCH_MODE] }
                                onChange={ this.handleChange(STATE_URL_LAUNCH_MODE) }
                                viewMode={ viewMode }
                                required
                            />
                            <Input
                                name={ STATE_CRITERIA_TYPE }
                                type={ TYPE_RADIO }
                                caption="mission.criteria_type"
                                value={ criteriaTypeOption }
                                selected={ missionDetails[STATE_CRITERIA_TYPE] }
                                onChange={ this.handleChange(STATE_CRITERIA_TYPE) }
                                viewMode={ viewMode }
                                required
                            />
                            <Input
                                className="small-input"
                                name={ STATE_CRITERIA_VALUE }
                                type={ TYPE_NUMBER }
                                caption="mission.criteria_value"
                                unitCaption={ missionDetails[STATE_CRITERIA_TYPE] ===  TRIP_DISTANCE ? 'km' : 'times' }
                                value={ missionDetails[STATE_CRITERIA_VALUE] }
                                onChange={ this.handleChange(STATE_CRITERIA_VALUE) }
                                viewMode={ viewMode }
                                required
                            />
                            <Input
                                className="small-input"
                                name={ STATE_VALID_FROM }
                                type={ TYPE_DATETIME }
                                caption="mission.valid_from"
                                dateFormat="YYYY-MM-DD"
                                timeFormat="HH:mm"
                                value={ missionDetails[STATE_VALID_FROM] }
                                onChange={ this.handleChange(STATE_VALID_FROM) }
                                viewMode={ viewMode }
                                required
                            />
                            <Input
                                className="small-input"
                                name={ STATE_VALID_TO }
                                type={ TYPE_DATETIME }
                                caption="mission.valid_to"
                                dateFormat="YYYY-MM-DD"
                                timeFormat="HH:mm"
                                value={ missionDetails[STATE_VALID_TO] }
                                onChange={ this.handleChange(STATE_VALID_TO) }
                                viewMode={ viewMode }
                                isEnd
                                required
                            />
                            <SystemCouponSelect
                                name={ STATE_REWARD_COUPON_ID }
                                selected={ {
                                    coupon_title: missionDetails[STATE_REWARD_COUPON_TITLE],
                                    coupon_id: missionDetails[STATE_REWARD_COUPON_ID]
                                } }
                                promoteType={ [PROMOTE_TYPE_MISSION] }
                                onChange={ this.handleChange(STATE_REWARD_COUPON_ID) }
                                viewMode={ viewMode }
                                required
                            />
                            { missionDetails[STATE_CREATE_TIME] && (
                                <Input
                                    name={ STATE_CREATE_TIME }
                                    type={ TYPE_DATETIME }
                                    caption="mission.create_time"
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat="HH:mm"
                                    value={ missionDetails[STATE_CREATE_TIME] }
                                    viewMode
                                />
                            ) }
                            { missionDetails[STATE_UPDATE_TIME] && (
                                <Input
                                    name={ STATE_UPDATE_TIME }
                                    type={ TYPE_DATETIME }
                                    caption="update_time"
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat="HH:mm"
                                    value={ missionDetails[STATE_UPDATE_TIME] }
                                    viewMode
                                />
                            ) }
                            { missionDetails[STATE_PUBLISH_TIME] && (
                                <Input
                                    name={ STATE_PUBLISH_TIME }
                                    type={ TYPE_DATETIME }
                                    caption="mission.publish_time"
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat="HH:mm"
                                    value={ missionDetails[STATE_PUBLISH_TIME] }
                                    viewMode
                                />
                            ) }
                            { missionDetails[STATE_PUBLISHER] && (
                                <Input
                                    name={ STATE_PUBLISHER }
                                    type={ TYPE_TEXT }
                                    caption="mission.publisher"
                                    value={ missionDetails[STATE_PUBLISHER] }
                                    viewMode
                                />
                            ) }
                        </FlexGroup>
                    </Smart>
                </Form>
            </AuthView>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    oneMission: state.mission.get('oneMission'),
}))(MissionForm);
