import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Filter from 'components/Filter';
import moment from 'moment';
import Datetime from 'react-datetime';
import { Translate, I18n } from 'react-redux-i18n';
import { FormGroup, Label } from 'reactstrap';
import usePrevious from 'hooks/usePrevious';
import './filter.scss';


const DatetimeRangeFilter = ({
    title,
    onApply,
    dateFormat,
    timeFormat,
    applied,
    range,
}) => {
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [inputFrom, setInputFrom] = useState();
    const [inputTo, setInputTo] = useState();
    const [toMin, setToMin] = useState(moment('1900-01-01'));
    const [toMax, setToMax] = useState(moment('2999-01-01'));

    const prevApplied = usePrevious(applied);

    const handleFilterToggle = () => {
        setOpen(!open);
    };

    const handleFilterReset = () => {
        onApply({
            from: undefined,
            to: undefined,
        });
        setOpen(false);
    };
    const handleFilterApply = () => {
        if (!errorMessage) {
            setOpen(false);
            setToMin(moment('1900-01-01'));
            setToMax(moment('2999-01-01'));

            onApply({
                from: inputFrom,
                to: inputTo,
            });
        }
    };

    const getTitle = () => {
        let displayTitle = title;

        if (!errorMessage && inputFrom && inputTo && applied) {
            const displayFrom = inputFrom ? moment(inputFrom).format(dateFormat) : '';
            const displayTo = inputTo ? moment(inputTo).format(dateFormat) : '';

            displayTitle = title ? `${ title }: ${ displayFrom }` : displayFrom;

            if (displayFrom !== displayTo) {
                displayTitle = displayTitle + ` - ${ displayTo }`;
            }
        }

        return displayTitle;
    };

    const handleFilterClickOutside = () => {
        setOpen(false);
    };

    const handleChange = field => inputValue => {
        const fullFormat = `${ dateFormat } ${ timeFormat }`;
        const currentState = {
            toMax,
            toMin,
            errorMessage,
            inputFrom,
            inputTo,
        };
        if (moment(inputValue, fullFormat, true).isValid()) {
            let updatedMin = field === 'inputFrom' ? moment(inputValue).subtract(1, 'days') : toMin;
            let updatedMax = toMax;
            let errorMessage = '';
            const updatedState = {
                ...currentState,
                toMin: updatedMin,
                [field]: moment(inputValue),
            };
            const { inputFrom, inputTo } = updatedState;

            updatedState.toMin = updatedMin;

            if (!inputFrom || !inputTo) {
                errorMessage = I18n.t('validation_error.require_both_dates');
            }
            else if (moment(inputTo).diff(moment(inputFrom)) < 0) {
                errorMessage = I18n.t('validation_error.wrong_dates_order');
            }
            else if (range && moment(inputTo).diff(moment(inputFrom), range[1]) > range[0]) {
                const unitText = I18n.t(range[1]);

                errorMessage = I18n.t('validation_error.out_of_duration', {
                    num: range[0],
                    unit: unitText,
                });
            }
            else {
                errorMessage = '';
            }

            if (field === 'inputFrom') {
                if (range) {
                    updatedMax = moment(inputValue).add(...range);
                }

                updatedState.toMax = updatedMax;

                const isEndTimeInRange = inputTo && moment(inputTo).isBetween(updatedMin, updatedMax);

                if (isEndTimeInRange === false) {
                    updatedState.inputTo = undefined;
                    errorMessage = I18n.t('validation_error.require_both_dates');
                }
            }

            updatedState.errorMessage = errorMessage;

            setToMin(updatedState.toMin);
            setToMax(updatedState.toMax);
            setErrorMessage(updatedState.errorMessage);
            setInputFrom(updatedState.inputFrom);
            setInputTo(updatedState.inputTo);
        }
    };

    const datetimeCleanWorkaround = (value) => (!value || value === '') ? { value: '' } : {};

    const onChangeRenderInput = onSet => e => {
        const { value } = e.target;
        onSet(value);
    };

    const DatetimeInput = inputProps => {
        const { onChange, ...props } = inputProps;
        return (
            <div className="input-wrap">
                <input
                    { ...props }
                    className="datetime-box"
                    onChange={ onChange }
                    onPaste={ e => e.preventDefault() }
                    required
                />
            </div>
        );
    };

    useEffect(() => {
        if (applied === false && prevApplied === true) {
            setInputFrom('');
            setInputTo('');
        }
    }, [applied, prevApplied]);



    return (
        <Filter
            className="datetime-range-filter"
            value={ getTitle() }
            errorMessage={ errorMessage }
            open={ open }
            applied={ applied }
            onToggle={ handleFilterToggle }
            onReset={ handleFilterReset }
            onApply={ handleFilterApply }
            onClickOutside={ handleFilterClickOutside }
        >
            <section className="form-groups">
                <FormGroup>
                    <Label for="from">
                        <Translate value="from" />
                    </Label>
                    <Datetime
                        value={ inputFrom }
                        className="datetime-picker"
                        dateFormat={ dateFormat }
                        timeFormat={ timeFormat }
                        renderInput={ DatetimeInput }
                        inputProps={ {
                            onChange: onChangeRenderInput(setInputFrom),
                            ...datetimeCleanWorkaround(inputFrom),
                        } }
                        open
                        onChange={ handleChange('inputFrom') }
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="to">
                        <Translate value="to" />
                    </Label>
                    <Datetime
                        value={ inputTo }
                        className="datetime-picker"
                        dateFormat={ dateFormat }
                        timeFormat={ timeFormat }
                        renderInput={ DatetimeInput }
                        inputProps={ {
                            onChange: onChangeRenderInput(setInputTo),
                            ...datetimeCleanWorkaround(inputTo),
                        } }
                        initialViewDate={ moment().endOf('day')}
                        open
                        isValidDate={ current => current.isBetween(toMin, toMax) }
                        onChange={ handleChange('inputTo') }
                    />
                </FormGroup>
            </section>
        </Filter>
    );
};
DatetimeRangeFilter.propTypes = {
    title: PropTypes.string,
    onApply: PropTypes.func,
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string,
    applied: PropTypes.bool,
    range: PropTypes.arrayOf(propValue => {
        if (!Array.isArray(propValue)) {
            return new Error('Is not an array');
        }

        const unitList = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];
        const [ num, unit ] = propValue;

        if (typeof num !== 'number') {
            return new Error('Wrong type');
        }

        if (unitList.indexOf(unit) === -1) {
            return new Error('Unsupported unit name');
        }

        return true;
    }),
};
DatetimeRangeFilter.defaultProps = {
    title: '',
    onApply: () => {},
    dateFormat: I18n.t('datetime_format.date'),
    timeFormat: I18n.t('datetime_format.time'),
    applied: false,
    range: undefined,
};
export default DatetimeRangeFilter;
