/**
 * Reduder - Rental
 */
import { Map, List } from 'immutable';
import {
    FETCH_RENTAL_HISTORY,
    CLEAR_RENTAL_HISTORY,
    FETCH_RENTAL_DETAIL,
    CLEAR_RENTAL_DETAIL,
    FETCH_REFUND_COUPON,
    CLEAR_REFUND_COUPON,
    REFUND,
} from 'constants/action-type';
import creater from './util/creater';

const initialState = Map({
    list: Map({
        page_index: 1,
        page_count: 0,
        data_list: List([]),
    }),
    detail: {},
    coupon_list: List([]),
});

const setDetail = (state, data) => {
    let detail;

    if (data) {
        detail = {
            ...state.get('detail'),
            [data.rental_id]: Map(data)
        };
    }
    else {
        detail = {};
    }
    return state.merge({
        detail: detail
    });
};

const actionsMap = {
    [FETCH_RENTAL_HISTORY]: (state, action) => {
        const { data_list, page_index, page_count, total, __responseTime } = action.data;
        return state.merge({
            list: Map({
                data_list: List(data_list),
                page_index,
                page_count,
                total,
                __responseTime,
            }),
        });
    },
    [CLEAR_RENTAL_HISTORY]: state => {
        return state.merge({
            list: initialState.get('list'),
        });
    },
    [FETCH_RENTAL_DETAIL]: (state, action) => setDetail(state, action.data),
    [CLEAR_RENTAL_DETAIL]: state => setDetail(state),
    [FETCH_REFUND_COUPON]: (state, action) => {
        return state.merge({
            coupon_list: List(action.data),
        });
    },
    [CLEAR_REFUND_COUPON]: state => {
        return state.merge({
            coupon_list: initialState.get('coupon_list'),
        });
    },
    [REFUND]: (state, action) => setDetail(state, action.data),
};

export default creater(actionsMap, initialState);
