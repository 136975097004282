import permissionHandler from 'helpers/permission-handler';
import {
    AUTH_EDIT_FLEET_USER_FRANCHISEE,
} from 'constants/permission';

const headerConfig = [{
    name: 'account',
    value: 'admin.account',
    sortable: true,
    width: 300
}, {
    name: 'name',
    value: 'admin.name',
    sortable: true,
    width: 250
}, {
    name: 'role_name',
    value: 'admin.role_name',
    sortable: true,
    width: 250
}, {
    name: 'franchisee_name',
    value: 'admin.franchisee_name',
    sortable: true,
    width: 250
}];

export function getHeaderConfig() {
    const config = [...headerConfig];
    if (!permissionHandler({ requiredList: [AUTH_EDIT_FLEET_USER_FRANCHISEE] })) {
        config.pop();
    }

    return config;
}
