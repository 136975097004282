import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import Datetime from 'components/Datetime';
import {
    TYPE_TEXT,
    TYPE_EMAIL,
    TYPE_FILE,
    TYPE_HIDDEN,
    TYPE_NUMBER,
    TYPE_PASSWORD,
    TYPE_URL,
    TYPE_TEXTAREA,
    TYPE_RADIO,
    TYPE_CHECKBOX,
    TYPE_DATETIME,
    TYPE_SELECT,
    TYPE_SEARCH_DROPDOWNLIST,
} from './input-type';

class ViewModeControl extends Component {
    static propTypes = {
        type: PropTypes.string,
        format: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.shape({
                // translation key
                name: PropTypes.string,
                value: PropTypes.oneOfType([
                    PropTypes.node,
                    PropTypes.bool,
                ]),
            })),
            PropTypes.instanceOf(moment)
        ]),
        selected: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.node),
        ]),
    };

    static defaultProps = {
        type: PropTypes.oneOf([
            TYPE_TEXT,
            TYPE_EMAIL,
            TYPE_FILE,
            TYPE_HIDDEN,
            TYPE_NUMBER,
            TYPE_PASSWORD,
            TYPE_URL,
            TYPE_TEXTAREA,
            TYPE_RADIO,
            TYPE_CHECKBOX,
            TYPE_DATETIME,
            TYPE_SELECT,
            TYPE_SEARCH_DROPDOWNLIST,
        ]),
        // use for `datetime`
        format: undefined,
        value: '',
        selected: undefined,
    };

    render() {
        const { type, value, selected, format } = this.props;
        const ViewControlWrapper = ({ children, className, tag = 'span' }) => {
            const Tag = tag;
            const wrapperClass = classNames({
                'view-control': true,
                [className]: !!className,
            });
            return <Tag className={ wrapperClass }>{ children }</Tag>;
        };
        const HiddenControl = ({ value }) => <ViewControlWrapper className="hide">{ value }</ViewControlWrapper>;
        const SelectionControl = ({ value, selected = [] }) => {
            const realSelected = Array.isArray(selected) ? [...selected] : [selected];
            const chosenSelections = value.filter(({ value }) => realSelected.indexOf(value) > -1);

            return (
                <ViewControlWrapper tag="p">
                    {
                        chosenSelections.map(({ value, name, translate }) => {
                            return (
                                <span key={ value } className={ `option option-${ value }` }>
                                    { translate ? I18n.t(name) : name }
                                </span>
                            );
                        })
                    }
                </ViewControlWrapper>
            );
        };

        let el;

        switch (type) {
        case TYPE_RADIO:
        case TYPE_CHECKBOX:
        case TYPE_SELECT:
        case TYPE_SEARCH_DROPDOWNLIST:
            el = <SelectionControl value={ value } selected={ selected } />;
            break;
        case TYPE_HIDDEN:
            el = <HiddenControl>{ value }</HiddenControl>;
            break;
        case TYPE_DATETIME:
            el = (
                <ViewControlWrapper>
                    <Datetime time={ value } format={ format } />
                </ViewControlWrapper>
            );
            break;
        case TYPE_TEXTAREA:
            el = (
                <ViewControlWrapper className="text-area" tag="pre">
                    { value }
                </ViewControlWrapper>
            );
            break;
        default:
            el = <ViewControlWrapper>{ value }</ViewControlWrapper>;
            break;
        }

        return el;
    }
}

export default ViewModeControl;
