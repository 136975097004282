import React, { Component } from 'react';
import Datetime from 'components/Datetime';
import PropTypes from 'prop-types';
import permissionHandler from 'helpers/permission-handler';
import FlexGroup from 'components/FlexGroup';
import AuthFeature from 'components/AuthFeature';
import { Refresh } from 'components/Pagination';
import {
    AUTH_READ_CUSTOMER_MEMBERSHIP_INFO,
    AUTH_READ_CUSTOMER_PERSONAL_INFO,
    AUTH_READ_CUSTOMER_DOCUMENT_INFO,
    AUTH_EDIT_CUSTOMER_PHONE,
} from 'constants/permission';
import { Card, CardBody } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import Field, { FIELD_TYPE } from './Field';
import EmailStatus from './EmailStatus';
import GoPocketStatus from './GoPocketStatus';
import OverallStatus from './OverallStatus';
import ControlTemplate from './ControlTemplate';
import QualificationForm from './QualificationForm';
import moment from 'moment';
import { fullname } from '../util';
import {
    LICENSE_TYPE_INVALID_LICENSE,
    LICENSE_TYPE_OTHER,
    LICENSE_TYPE_ORDINARY_HEAVY_DUTY_MOTORCYCLE,
    LICENSE_TYPE_LARGE_HEAVY_DUTY_MOTORCYCLE,
} from 'constants/customer';
import {
    CORPORATE_TYPE_NORMAL,
    CORPORATE_TYPE_BASIC,
    CORPORATE_TYPE_VIP,
} from 'constants/customer';

class DetailsSection extends Component {
    static propTypes = {
        data: PropTypes.shape({}),
        editing: PropTypes.bool,
        onRefresh: PropTypes.func,
    };
    static defaultProps = {
        data: {},
        editing: false,
        onRefresh: () => {},
    };
    convertDate = (value, isLimited) => {
        if (isLimited) {
            return value || undefined;
        }
        else {
            return value && value !== null ?
            moment(value).format(I18n.t('datetime_format.date')) : undefined;
        }
    };

    render() {
        const { data, editing, onRefresh } = this.props;
        const {
            first_name,
            last_name,
            phone,
            email,
            email_verify,
            credit_card_no,
            associated_gopocket,
            associated_time,
            reward_point,
            reward_point_expired_time,
            id_no,
            id_verify,
            id_birth,
            license_no,
            license_verify,
            license_birth,
            license_type,
            join_date,
            total_orders,
            attributes,
            allow_rental,
            enabled,
            allowance_info,
            corporate_name,
            corporate_email,
            corporate_email_verify,
            __responseTime,
            privacy_limited,
        } = data;
        const idBirth = this.convertDate(id_birth, privacy_limited);
        const licenseBirth = this.convertDate(license_birth, privacy_limited);
        const joinDate = join_date && join_date !== null ?
            moment(join_date).format(I18n.t('datetime_format.date')) : undefined;
        const licenseType = [
            LICENSE_TYPE_INVALID_LICENSE,
            LICENSE_TYPE_OTHER,
            LICENSE_TYPE_ORDINARY_HEAVY_DUTY_MOTORCYCLE,
            LICENSE_TYPE_LARGE_HEAVY_DUTY_MOTORCYCLE,
        ].indexOf(license_type) >= 0 ? I18n.t(`customer.license_${license_type}`) : undefined;
        const customerStatus = enabled ? I18n.t('enable') : I18n.t('disable');
        const trip_times_remaining_quota = allowance_info?.general?.trip_times_remaining_quota;
        const times_limit_type = allowance_info?.general?.times_limit_type;
        const times_limit_type_string = I18n.t(`customer.remaing_quota_unit_${ times_limit_type }`);
        let remaining_quota_string = trip_times_remaining_quota + times_limit_type_string;

        if (trip_times_remaining_quota === null) {
            remaining_quota_string = I18n.t('customer.unlimited');
        }

        const corporateTypes = (() => {
            const types = [];
            if (allowance_info?.vip) {
                types.push(CORPORATE_TYPE_VIP);
            }

            if (allowance_info?.general) {
                types.push(CORPORATE_TYPE_BASIC);
            }

            if (types.length === 0) {
                types.push(CORPORATE_TYPE_NORMAL);
            }

            return types;
        })();

        return (
            <React.Fragment>
                <FlexGroup spaceBetween>
                    <Translate value="customer.details" className="caption" tag="h2" />
                    <Refresh time={ __responseTime } onClick={ onRefresh  } />
                </FlexGroup>
                <section className="cards">
                    <section className="id-information-sec">
                        <AuthFeature requiredList={ [AUTH_READ_CUSTOMER_PERSONAL_INFO] }>
                            <Card>
                                <CardBody>
                                    <Translate className="personal-caption" value="customer.personal_data" tag="h3" />
                                    <Field
                                        type={ FIELD_TYPE.INPUT }
                                        name="name"
                                        title="customer.name"
                                        value={ fullname(first_name, last_name) }
                                    />
                                    <Field
                                        type={ FIELD_TYPE.INPUT }
                                        name="license_birth"
                                        title="customer.license_birth"
                                        value={ licenseBirth }
                                    />
                                    <Field
                                        type={ FIELD_TYPE.INPUT }
                                        name="phone"
                                        title="customer.mobile"
                                        editing={
                                            editing &&
                                            permissionHandler({ requiredList: [AUTH_EDIT_CUSTOMER_PHONE] })
                                        }
                                        value={ phone }
                                    />
                                    <Field
                                        type={ FIELD_TYPE.EMAIL }
                                        name="email"
                                        title="customer.email"
                                        value={ email }
                                        editing={ editing }
                                        required
                                    />
                                    <EmailStatus status={ email_verify } />
                                    <Field
                                        type={ FIELD_TYPE.INPUT }
                                        name="credit_card_no"
                                        title="customer.credit_card_no"
                                        value={ credit_card_no }
                                    />
                                    <GoPocketStatus
                                        isAssociated={ associated_gopocket }
                                        associatedTime={ associated_time }
                                    />
                                    <Field
                                        type={ FIELD_TYPE.INPUT }
                                        name="reward_point"
                                        title="customer.reward_point"
                                        value={ reward_point ? `${ reward_point } ${ I18n.t('customer.reward_point_unit') }` : reward_point }
                                    />
                                    <ControlTemplate
                                        name="reward_point_expired_time"
                                        large
                                        captionKey="customer.reward_point_expired_time"
                                        required={ false }>
                                        <Datetime time={ (reward_point > 0) ? reward_point_expired_time : null } />
                                    </ControlTemplate>
                                </CardBody>
                            </Card>
                        </AuthFeature>
                        { (allowance_info?.vip || allowance_info?.general) && (
                            <Card>
                                <CardBody>
                                    <Translate value="customer.corporate" tag="h3" />
                                    <Field
                                        type={ FIELD_TYPE.INPUT }
                                        name="corporate_type"
                                        title="customer.corporate_type"
                                        value={ corporateTypes.map(
                                            type => I18n.t(`customer.corporate_type_${ type }`)
                                        ).join('、') }
                                    />
                                    <Field
                                        type={ FIELD_TYPE.INPUT }
                                        name="corporate_name"
                                        title="customer.corporate_name"
                                        value={ corporate_name }
                                    />
                                    <Field
                                        type={ FIELD_TYPE.INPUT }
                                        name="corporate_email"
                                        title="customer.email"
                                        value={ corporate_email }
                                    />
                                    <EmailStatus status={ corporate_email_verify ? 0 : 1 } />
                                    { allowance_info.general !== null && (
                                        <Field
                                            type={ FIELD_TYPE.INPUT }
                                            name="trip_times_remaining_quota"
                                            title="customer.trip_times_remaining_quota"
                                            value={ remaining_quota_string }
                                        />
                                    ) }
                                </CardBody>
                            </Card>
                        ) }
                        <AuthFeature requiredList={ [AUTH_READ_CUSTOMER_MEMBERSHIP_INFO] }>
                            <Card>
                                <CardBody>
                                    <Translate value="customer.membership" tag="h3" />
                                    <Field
                                        type={ FIELD_TYPE.INPUT }
                                        name="join_date"
                                        title="customer.join_date"
                                        value={ joinDate }
                                    />
                                    <Field
                                        type={ FIELD_TYPE.INPUT }
                                        name="total_orders"
                                        title="total_orders"
                                        value={ total_orders }
                                    />
                                    <Field
                                        type={ FIELD_TYPE.TEXT_AREA }
                                        name="attributes"
                                        title="customer.attributes"
                                        value={ attributes }
                                        editing={ editing }
                                        maxLength={ 50 }
                                    />
                                </CardBody>
                            </Card>
                        </AuthFeature>
                    </section>
                    <section className="id-information-sec">
                        <AuthFeature requiredList={ [AUTH_READ_CUSTOMER_DOCUMENT_INFO] }>
                            <Card className="customer-document-info">
                                <CardBody>
                                    <Translate value="customer.documents_verification" tag="h3" />
                                    <Field
                                        type={ FIELD_TYPE.BADGE_INPUT }
                                        name="id_no"
                                        title="customer.id_no"
                                        value={ id_no }
                                        approved={ Number(id_verify) }
                                    />
                                    <Field
                                        type={ FIELD_TYPE.INPUT }
                                        name="id_birth"
                                        title="customer.id_birth"
                                        value={ idBirth }
                                    />
                                    <Field
                                        type={ FIELD_TYPE.BADGE_INPUT }
                                        name="license_no"
                                        title="customer.license_no"
                                        value={ license_no }
                                        approved={ Number(license_verify) }
                                    />
                                    <Field
                                        type={ FIELD_TYPE.INPUT }
                                        name="license_birth"
                                        title="customer.license_birth"
                                        value={ licenseBirth }
                                    />
                                    <Field
                                        type={ FIELD_TYPE.INPUT }
                                        name="license_type"
                                        title="customer.license_type"
                                        value={ licenseType }
                                    />
                                </CardBody>
                            </Card>
                            {
                                editing ? (<QualificationForm />) : null
                            }
                        </AuthFeature>
                        <AuthFeature requiredList={ [AUTH_READ_CUSTOMER_MEMBERSHIP_INFO] }>
                            <Card>
                                <CardBody>
                                    <Translate value="customer.status" tag="h3" />
                                    <Field
                                        type={ FIELD_TYPE.INPUT }
                                        name="enabled"
                                        title="customer.customer_status"
                                        value={ customerStatus }
                                    />
                                    <OverallStatus status={ allow_rental } />
                                </CardBody>
                            </Card>
                        </AuthFeature>
                    </section>
                </section>
            </React.Fragment>
        );
    }
}

export default DetailsSection;
