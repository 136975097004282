import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoCard from 'components/InfoCard';
import DashInfo from 'components/DashInfo';
import Gauge from 'components/Chart/Gauge';
import AnimatedText from 'components/AnimatedText/AnimatedText';
import { I18n, Translate } from 'react-redux-i18n';
import {
    showServiceLevelWarning,
    getServiceLevelGaugeLabel,
} from 'helpers/dashboard';

import {
    DASH_COLOR_YELLOW,
    DASH_COLOR_BLUE,
    SERVICE_LEVEL_CHART_THICK,
} from 'constants/dashboard';

import './single-service-level.scss';

const SINGLE_SL_CHART_SIZE = 105;

const ServiceLevel = ({
    title,
    className,
    data,
    chartTitle,
    width,
    height,
    thick,
    gaugeStyles,
    labelStyle,
    animateInfo,
}) => {

    const {
        service_level,
        online,
        offline,
        operating,
    } = data;

    const CardStyle = classNames({
        'single-SL': true,
        [className]: true,
    });

    const getGaugeStyle = () => {
        const { gaugeStyles: defaultStyles } = ServiceLevel.defaultProps;
        let style = {};
        Object.keys(defaultStyles).forEach(item => {
            style[item] = {
                ...defaultStyles[item],
                ...gaugeStyles?.[item],
            };
        });
        return style;
    };

    const getRenderedLabel = () => {
        const { titleStyle, unitStyle } = labelStyle;
        return getServiceLevelGaugeLabel(titleStyle, unitStyle);
    };

    const { warning, safety } = getGaugeStyle();
    const isWarning = showServiceLevelWarning(service_level);
    const safetyColorStyle = safety.color.replace('#', '');
    const chartStyle = classNames({
        'chart-title': true,
        'warning': isWarning,
        [safetyColorStyle]: true,
    });


    return (
        <InfoCard className={ CardStyle }>
            <div className="info">
                <h2 className="title">{ title }</h2>
                {
                    !animateInfo ?
                        (<>
                            <DashInfo title={ I18n.t('scooter.activation.on') } value={ online } />
                            <DashInfo title={ I18n.t('scooter.activation.off') } value={ offline } />
                            <DashInfo title={ I18n.t('dashboard.total') } value={ operating } />
                        </>) :
                        (<>
                            <DashInfo title={ I18n.t('scooter.activation.on') } value={ online }>
                                <AnimatedText
                                    value={ online }
                                    size={ animateInfo.size }
                                />
                            </DashInfo>
                            <DashInfo title={ I18n.t('scooter.activation.off') } value={ offline }>
                                <AnimatedText
                                    value={ offline }
                                    size={ animateInfo.size }
                                />
                            </DashInfo>
                            <DashInfo title={ I18n.t('dashboard.total') } value={ operating }>
                                <AnimatedText
                                    value={ operating }
                                    size={ animateInfo.size }
                                />
                            </DashInfo>
                        </>)
                }

            </div>
            <div className="chart">
                <Gauge
                    width={ width }
                    height={ height }
                    gaugeValue={ service_level }
                    color={ isWarning ? warning.color : safety.color }
                    thick={ thick }
                    renderLabel={ getRenderedLabel() }
                />
                {
                    chartTitle ?
                        (<Translate className={ chartStyle } value={ chartTitle } />) :
                        null
                }
            </div>
        </InfoCard>
    );
};

ServiceLevel.propTypes = {
    title: PropTypes.string,
    chartTitle: PropTypes.string,
    className: PropTypes.string,
    data: PropTypes.shape({
        service_level: PropTypes.number,
        online: PropTypes.number,
        offline: PropTypes.number,
        total: PropTypes.number,

    }),
    width: PropTypes.number,
    height: PropTypes.number,
    thick: PropTypes.number,
    gaugeStyles: PropTypes.shape({}),
    labelStyle: PropTypes.shape({}),
    animateInfo: PropTypes.shape({}),
};

ServiceLevel.defaultProps = {
    title: '',
    chartTitle: '',
    className: '',
    data: {
        service_level: 0,
        online: 0,
        offline: 0,
        total: 0,
    },
    width: SINGLE_SL_CHART_SIZE,
    height: SINGLE_SL_CHART_SIZE,
    thick: SERVICE_LEVEL_CHART_THICK,
    gaugeStyles: {
        safety: {
            color: DASH_COLOR_BLUE,
        },
        warning: {
            color: DASH_COLOR_YELLOW,
        },
    },
    labelStyle: {},
    animateInfo: undefined,
};

export default ServiceLevel;
