import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { NEW_COUPON } from 'constants/routes';
import { NavLink } from 'react-router-dom';
import AuthFeature from 'components/AuthFeature';
import { AUTH_EDIT_COUPON } from 'constants/permission';
import Smart from 'components/Smart';
import ListView from 'components/ListView';
import FlexGroup from 'components/FlexGroup';
import Pagination, { TotalCount, Refresh } from 'components/Pagination';
import PageSizeSelect from 'components/Pagination/PageSizeSelect';
import { Translate } from 'react-redux-i18n';
import mobile from 'is-mobile';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { headerConfig } from './ListHeader';
import ListRow from './ListRow';


const isMobile = mobile();

class CouponList extends Component {
    static propTypes = {
        list: ImmutablePropTypes.map,
        pageSize: PropTypes.number.isRequired,
        onSort: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onFetch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        list: null,
    };

    render() {
        const { list, onSort, onSelect, onChange, pageSize, onFetch } = this.props;
        const { page_index, page_count, data_list, total, __responseTime } = (list && list.toJS()) || {};
        const ListItem = ({ columns, rowData }) => (
            <ListRow
                key={ `ad-${ rowData.id }` }
                columns={ columns }
                data={ rowData }
            />
        );

        return (
            <React.Fragment>
                <FlexGroup className="functional-group">
                    <AuthFeature requiredList={ [AUTH_EDIT_COUPON] }>
                        <NavLink to={ NEW_COUPON }>
                            <Button outline color="primary">
                                <Translate value="coupon.new_coupon" />
                            </Button>
                        </NavLink>
                    </AuthFeature>
                    <FlexGroup className="pagination-status">
                        <FlexGroup className="pagination-control">
                            <Pagination
                                page={ page_index }
                                total={ page_count }
                                offset={ pageSize }
                                onSelect={ onSelect }
                            />
                            <PageSizeSelect
                                onChange={ onChange }
                            />
                            <TotalCount count={ total } />
                        </FlexGroup>
                        <FlexGroup className="update-status">
                            <Refresh onClick={ onFetch } time={ __responseTime } />
                        </FlexGroup>
                    </FlexGroup>
                </FlexGroup>
                <Smart
                    seamless
                    fetch={ onFetch }
                    onUnmount={ () => {} }
                    refreshTime={ 60 }
                    className="list-view-wrap"
                >
                    <ListView
                        header={ headerConfig }
                        onSort={ onSort }
                        list={ data_list }
                        renderListRow={ ListItem }
                        dataKey="id"
                        fixed={ isMobile }
                    />
                </Smart>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    list: state.coupon.get('list'),
}))(CouponList);
