import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import XSSTranslate from 'components/XSSTranslate';
import CollapseBanner from 'components/CollapseBanner';
import Datetime from 'components/Datetime';

class TicketLog extends Component {
    static propTypes = {
        logs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    }

    getRealFieldValue = (name, value) => {
        let realFieldValue = '';

        switch (name) {
        case 'status_id':
            realFieldValue = I18n.t(`ticket.status_${ value }`);
            break;
        case 'severity_id':
            realFieldValue = I18n.t(`ticket.severity_${ value }`);
            break;
        case 'due_date':
            realFieldValue = moment(value).format('YYYY-MM-DD HH:mm');
            break;
        case 'tracker_subtype_id':
            realFieldValue = I18n.t(`ticket.sub_type_${ value }`);
            break;
        case 'assigned_to_name':
        default:
            realFieldValue = value || I18n.t('empty');
        }

        return realFieldValue;
    }

    getRealFieldName = name => {
        let realFieldName = '';

        switch (name) {
        case 'status_id':
            realFieldName = 'status';
            break;
        case 'description':
            realFieldName = 'description';
            break;
        case 'assigned_to_name':
            realFieldName = 'assignee';
            break;
        case 'severity_id':
            realFieldName = 'priority';
            break;
        case 'due_date':
            realFieldName = 'due_time';
            break;
        case 'tracker_subtype_id':
            realFieldName = 'sub_type';
            break;
        case '1':
            realFieldName = 'destination';
            break;
        case '2':
            realFieldName = 'dms_no';
            break;
        case '3':
            realFieldName = 'mega_center';
            break;
        default:
            realFieldName = 'unknown';
        }

        return realFieldName;
    }

    toggleLogSection = () => {
        return Promise.resolve();
    }

    renderLogs(logs) {
        return logs.map(({ id, author, details, create_time }) => (
            <article key={ id } className="log">
                {
                    details.map(({ name, old_value, value }) => (
                        <XSSTranslate
                            key={ `${ id }-${ name }-${ old_value }-${ value }` }
                            value="ticket.log_sentence"
                            className="log-line"
                            field={ I18n.t(`ticket.${ this.getRealFieldName(name) }`) }
                            from={ this.getRealFieldValue(name, old_value) }
                            to={ this.getRealFieldValue(name, value) }
                            tag="p"
                        />
                    ))
                }
                <p className="log-footer">
                    <span className="author">{ author }</span>
                    <span><Datetime time={ create_time } format="YYYY-MM-DD HH:mm" /></span>
                </p>
            </article>
        ));
    }

    render() {
        const { logs } = this.props;

        return (
            <CollapseBanner
                captionKey="ticket.log"
                onFetch={ this.toggleLogSection }
            >
                {
                    this.renderLogs(logs)
                }
            </CollapseBanner>
        );
    }
}

export default TicketLog;
