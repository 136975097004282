import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuthView from 'components/AuthView';
import { getDefaultPageSize } from 'components/Pagination/PageSizeSelect';
import FilterBar from './FilterBar';
import {
    FILTER_SERVICE,
    FILTER_RENTAL_STATE,
    FILTER_MODEL,
    FILTER_CITY,
    FILTER_VIP_SCOOTER,
} from 'view/ScooterList/FilterBar';
import List from './List';
import {
    updateDocumentTitle,
    fetchOperationUsers,
    fetchRMScooterList,
} from 'actions';

import './regular-maintenance.scss';

class RegularMaintenance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIdList: [],
            queryPayload: {
                plate: undefined,
                filter: {
                    [FILTER_SERVICE]: undefined,
                    [FILTER_RENTAL_STATE]: undefined,
                    [FILTER_MODEL]: undefined,
                    [FILTER_CITY]: undefined,
                },
                sort: {},
                page: 1,
                size: getDefaultPageSize().value,
            },
        };
    }

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { pathname } = location;

        dispatch(fetchOperationUsers());
        dispatch(updateDocumentTitle(`routes.${ pathname }`));
    }

    handleFilterOn = (type, value) => {
        const { queryPayload } = this.state;
        const { filter } = queryPayload;

        switch (type) {
        case FILTER_SERVICE:
        case FILTER_RENTAL_STATE:
        case FILTER_MODEL:
        case FILTER_CITY:
        case FILTER_VIP_SCOOTER:
            filter[type] = value.inputSelect;
            break;
        default:
        };

        // rebind
        this.setState((prevState) => ({
            selectedIdList: [],
            queryPayload: {
                ...prevState.queryPayload,
                filter,
                page: 1,
            },
        }), this.fetchRMScooterList);

    };

    handleSubmit = value => {
        this.setState((prevState) => ({
            selectedIdList: [],
            queryPayload: {
                ...prevState.queryPayload,
                filter: {},
                plate: value === '' ? undefined : value,
                page: 1,
                sort: {},
            }
        }), this.fetchRMScooterList);

    }

    fetchRMScooterList = () => {
        const { dispatch } = this.props;
        const { queryPayload } = this.state;
        const { filter, ...params } = queryPayload;

        return dispatch(fetchRMScooterList({
            ...filter,
            ...params,
        }));
    };

    handleSelect = list => {
        this.setState({
            selectedIdList: list,
        });
    };

    handleChange = type => value => {
        const { queryPayload } = this.state;
        queryPayload.page = 1;
        queryPayload[type] = value;

        this.setState({
            selectedIdList: [],
            queryPayload: {
                ...queryPayload,
            }
        }, this.fetchRMScooterList);
    };

    render() {
        const { selectedIdList, queryPayload } = this.state;

        return (
            <AuthView className="regular-maintenance">
                <FilterBar
                    defaultSettings={ queryPayload }
                    onChange={ this.handleFilterOn }
                    onSubmit={ this.handleSubmit }
                />
                <List
                    pageSize={ queryPayload.size }
                    onFetch={ this.fetchRMScooterList }
                    onSelect={ this.handleSelect }
                    onChange={ this.handleChange }
                    selectedIdList={ selectedIdList }
                />
            </AuthView>
        );
    }
};

export default connect(state => ({
    i18n: state.i18n,
}))(RegularMaintenance);

