import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FlexGroup from 'components/FlexGroup';
import { Translate, I18n } from 'react-redux-i18n';
import PieChart from 'components/Chart/PieChart';
import AnimatedText from 'components/AnimatedText/AnimatedText';
import {
    CHART_FONT_STYLE_BOLD,
    CHART_FONT_STYLE_MEDIUM,
} from 'constants/chart';
import {
    getDashScooterModelStyle,
    getDashScooterModelColorList,
    getScooterModelPieLabel,
    getScooterModelPieSubtitle,
} from 'helpers/dashboard';
import { TV_MONITOR_SCALE } from 'constants/tvMonitor';
import { DASH_COLOR_WHITE } from 'constants/dashboard';

import './tv-overall-scooter-model.scss';

const TV_SCOOTER_MODEL_CHART_SIZE = 720 / TV_MONITOR_SCALE;
const TV_SCOOTER_MODEL_CHART_THICK = 60 / TV_MONITOR_SCALE;

const LABEL_STYLE = `"
    font-size: ${ 84 / TV_MONITOR_SCALE }px;
    font-weight: bold;
    font-family: ${ CHART_FONT_STYLE_BOLD };
"`;
const SUB_TITLE_STYLE = `"
    font-size: ${ 48 / TV_MONITOR_SCALE }px;
    font-weight: 500;
    font-family: ${ CHART_FONT_STYLE_MEDIUM };
"`;

class  TVOverallScooterModel extends React.PureComponent {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,
            online: PropTypes.number,
            offline: PropTypes.number,
        })).isRequired,
        title: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        title: '',
        className: '',
    };


    getPieValues = (list) => {
        let result = [];
        list.forEach(item => {
            result = result.concat([
                item.online,
                item.offline,
            ]);
        });
        return result;
    }


    renderModelCount = model => {
        const styleName = getDashScooterModelStyle(model.type);
        const size = 48 / TV_MONITOR_SCALE;
        return (
            <FlexGroup className={ `model-${styleName}` } key={ model.type }>
                <div className="caption">{ model.type }</div>
                <FlexGroup className="activation">
                    <FlexGroup className="content">
                        <Translate className="label-online" value="scooter.activation.on" />
                        <span>
                            <AnimatedText
                                value={ model.online }
                                size={ size }
                            />
                        </span>
                    </FlexGroup>
                    <FlexGroup className="content">
                        <Translate className="label-offline" value="scooter.activation.off" />
                        <span>
                            <AnimatedText
                                value={ model.offline }
                                size={ size }
                            />
                        </span>
                    </FlexGroup>
                </FlexGroup>
            </FlexGroup>
        );
    }

    render() {
        const { data, title, className } = this.props;
        const pieColors = getDashScooterModelColorList(data, true);
        const pieValue = this.getPieValues(data);

        const CardStyle = classNames({
            'tv-overall-scooter-model': true,
            [className]: true,
        });

        return (
            <FlexGroup spaceBetween alignStart className={ CardStyle }>
                <div className="chart">
                    <h2 className="title">{ title }</h2>
                    <PieChart
                        pieValues={ pieValue }
                        pieColors={ pieColors }
                        subTitle={ I18n.t('dashboard.total_scooters') }
                        className="model-pie-chart"
                        autoResize={ true }
                        width={ TV_SCOOTER_MODEL_CHART_SIZE }
                        thick={ TV_SCOOTER_MODEL_CHART_THICK }
                        labelColor={ DASH_COLOR_WHITE }
                        subTitleColor={ DASH_COLOR_WHITE }
                        renderLabel={ getScooterModelPieLabel(LABEL_STYLE) }
                        renderSubtitle={ getScooterModelPieSubtitle(SUB_TITLE_STYLE) }
                    />
                </div>
                <FlexGroup start className="model-list">
                    {
                        data.length && data.map(item => this.renderModelCount(item))
                    }
                </FlexGroup>
            </FlexGroup>
        );
    }

};

export default TVOverallScooterModel;
