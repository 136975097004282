import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import {
    TRUNK_CLOSED,
    TRUNK_OPEN,
    TRUNK_LOCKED,
} from 'constants/scooter';

const Component = ({ status, className }) => {
    const renderProperText = () => {
        let el;

        switch (status) {
        case TRUNK_CLOSED:
        case TRUNK_OPEN:
        case TRUNK_LOCKED:
            el = <Translate value={ `scooter.trunk_state_map.${ status }` } />;
            break;
        case undefined:
        case null:
            el = '--';
            break;
        default:
            el = <Translate value="unknown" />;
        }

        return el;
    };

    return (
        <div className={ className }>
            { renderProperText() }
        </div>
    );
};

Component.propTypes = {
    status: PropTypes.number,
    className: PropTypes.string,
};

Component.defaultProps = {
    status: undefined,
    className: '',
};

export default Component;
