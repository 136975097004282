import api from 'api';
import {
    IMPORT_VIOLATION_LIST,
    GET_VIOLATION_DETAIL,
    CLEAR_VIOLATION_LIST
} from 'constants/action-type';

import { asyncInterface } from './util';

export const importViolationList = (file = null) => dispatch => {
    return asyncInterface({
        asyncAction: api.importViolationList(file),
        type: IMPORT_VIOLATION_LIST,
        dispatch,
    });
};

export const getViolationDetail = (id) => dispatch => {
    return asyncInterface({
        asyncAction: api.getViolationDetail(id),
        type: GET_VIOLATION_DETAIL,
        dispatch,
    });
};

export const clearViolationList = () => dispatch => dispatch({
    type: CLEAR_VIOLATION_LIST,
});
