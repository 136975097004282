import { createSlice } from "@reduxjs/toolkit";
import account from 'helpers/account';
import store from 'store';
import {
    // login as originalLogin,
    // logout as originalLogout,
    openPleaseLoginDialog as originalOpenPleaseLoginDialog,
    // deleteCookie
} from 'actions';

const initialState = {
    isLogin: account.exists(),
    showPleaseLoginDialog: false,
};

const isAuthorizedAction = action => {
    return account.exists();
};
const isUnauthorizedAction = action => {
    return (action.type === 'auth/logout') || (!account.exists() && action.type.endsWith('rejected'));
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            const { profile, expires_in, id_token } = action.payload;
            const res = { ...profile, expires_in };
            account.setID(id_token);
            account.set(res);
            account.setProfile(res);
            account.setExpiresAt(res);
            // store.dispatch(originalLogin(action.payload));
            state.isLogin = true;
        },
        logout: (state, action) => {
            account.remove();
            account.setID('');
            account.setProfile(null);
            account.setExpiresAt(null);
            // store.dispatch(originalLogout());
            // store.dispatch(deleteCookie());
            state.isLogin = false;
        },
        openPleaseLoginDialog: (state) => {
            store.dispatch(originalOpenPleaseLoginDialog());
            state.showPleaseLoginDialog = true;
        }
    },
    extraReducers: (builder) => {
        builder
        .addMatcher(
            isAuthorizedAction,
            (state, action) => {
                return {
                    ...state,
                    isLogin: true
                };
            }
        )
        .addMatcher(
            isUnauthorizedAction,
            (state, action) => {
                return {
                    ...state,
                    isLogin: false,
                };
            }
        )
    }
})
export const { login, logout, openPleaseLoginDialog } = authSlice.actions;
export default authSlice.reducer;
