import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import serialize from 'form-serialize';
import Modal from 'components/Modal';
import Form from 'components/Form';
import Input from 'components/Form/Input';
import ButtonGroup from 'components/ButtonGroup';
import WarningModal from 'components/WarningModal';
import { convertToUTC } from 'helpers/time-handler';
import { convertOpUsers } from 'components/NewTicket';
import {
    TYPE_CHECKBOX,
    TYPE_TEXTAREA,
    TYPE_DATETIME,
    TYPE_SEARCH_DROPDOWNLIST,
} from 'components/Form/Input/input-type';
import {
    toggleErrorDialog,
    fetchOperationUsers,
    updateManyTickets,
} from 'actions';


class BatchEdit extends React.Component {
    static propTypes = {
        opUsers: ImmutablePropTypes.list.isRequired,
        ticketIdList: PropTypes.arrayOf(PropTypes.number).isRequired,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        corporate: PropTypes.arrayOf(PropTypes.string),
    }

    static defaultProps = {
        onCancel: () => {},
        onSubmit: () => {},
        corporate: [],
    }

    constructor(props) {
        super(props);
        this.descriptionRef = React.createRef();
        this.state = {
            warningOpen: false,
            warningFormData: {
                notes: '',
            },
            isSubmitting: false,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(fetchOperationUsers());
    }

    componentDidUpdate() {
        const { ticketIdList } = this.props;
        if (!ticketIdList.length) {
            this.setState({
                isSubmitting: false,
            });
        }
    }

    toggleWarningDialog = e => {
        const { warningOpen } = this.state;
        const formData = serialize(e.currentTarget, { hash: true });
        this.setState({ warningOpen: !warningOpen, warningFormData: formData });
    }

    handleSubmit = e => {
        e.preventDefault();
        const { warningFormData, isSubmitting } = this.state;
        const { dispatch, onSubmit, onCancel, ticketIdList = [], corporate } = this.props;
        let formData = corporate.length > 1 ? warningFormData :
            serialize(e.currentTarget, { hash: true });
        const needUpdate = Object.keys(formData).length > 0;
        const { whether_modify_description, description, due_date } = formData;

        if (isSubmitting) {
            return;
        }

        if (whether_modify_description === 'true') {
            formData.description = description || '';
            delete formData.whether_modify_description;
        }

        formData = {
            ...formData,
            id_list: needUpdate ? ticketIdList : undefined,
            due_date: due_date ? convertToUTC(due_date) : undefined,
        };

        if (needUpdate) {
            this.setState({
                isSubmitting: true,
            });
            dispatch(updateManyTickets(formData)).then(({ data = {}, error }) => {
                const { fail_ids } = data;

                if (error) {
                    if (error.response?.status !== 401) {
                        dispatch(toggleErrorDialog(I18n.t('ticket.general_error_message')));
                    }
                }
                else if (fail_ids && fail_ids.length > 0) {
                    dispatch(toggleErrorDialog(I18n.t(
                        'ticket.specific_tickets_error_message', {
                            tickets: fail_ids.join(', '),
                        }
                    )));
                }

                onSubmit();
            }).catch(console.error);
        }
        else {
            onCancel();
        }
    }

    handleModifyDescription = value => {
        this.descriptionRef.current.disabled = value.length === 0;
    }

    render() {
        const { opUsers, onCancel, ticketIdList = [], corporate } = this.props;
        const { warningOpen, warningFormData, isSubmitting } = this.state;
        const TicketForm = () => (
            <Form onSubmit={ corporate.length > 1 ?
                this.toggleWarningDialog : this.handleSubmit
            }
            >
                <Input
                    name="assigned_to_id"
                    type={ TYPE_SEARCH_DROPDOWNLIST }
                    caption="ticket.assignee"
                    value={ convertOpUsers(opUsers) }
                />
                <Input
                    name="due_date"
                    type={ TYPE_DATETIME }
                    caption="ticket.due_time"
                    value={ warningFormData.due_date }
                    dateFormat={ I18n.t('datetime_format.date') }
                    timeFormat={ I18n.t('datetime_format.time') }
                />
                <Input
                    name="notes"
                    type={ TYPE_TEXTAREA }
                    caption="ticket.comment"
                    value={ warningFormData.notes }
                    rows={ 4 }
                />
                <Input
                    name="whether_modify_description"
                    type={ TYPE_CHECKBOX }
                    caption="ticket.whether_modify_description"
                    value={ [{
                        name: 'yes',
                        value: true,
                        translate: true,
                    }] }
                    onChange={ this.handleModifyDescription  }
                />
                <Input
                    innerRef={ this.descriptionRef }
                    name="description"
                    type={ TYPE_TEXTAREA }
                    caption="ticket.description"
                    disabled={ true }
                    rows={ 4 }
                />
                <ButtonGroup>
                    <Button type="button" className="btn-cancel" outline onClick={ onCancel }>
                        <Translate value="cancel" />
                    </Button>
                    <Button color="primary" type="submit">
                        <Translate value="save" />
                    </Button>
                </ButtonGroup>
                <WarningModal
                    show={ warningOpen }
                    corporate={ corporate }
                    onClose={ () => this.setState({ warningOpen: false }) }
                    onSubmit={ this.handleSubmit }
                    inProgress={ isSubmitting }
                />
            </Form>
        );

        return (
            <Modal title={ I18n.t('ticket.batch_edit') } onClose={ onCancel }>
                <div className="new-ticket">
                    {
                        ticketIdList.length > 0 ?
                            <TicketForm /> :
                            <h1>No Ticket has been selected</h1>
                    }
                </div>
            </Modal>
        );
    }
}
export default connect(state => ({
    opUsers: state.account.get('opUsers'),
}))(BatchEdit);
