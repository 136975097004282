import { legacy_createStore as reduxCreateStore, compose } from 'redux';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import { getTranslations, defaultLanguage } from 'locale';

const storeCreater = (reducers, appliedMiddleware ) => {
    const translations = getTranslations();
    const composeEnhancers = (
        (process.env.NODE_ENV === 'development') &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ) || compose;
    const store = reduxCreateStore(reducers, composeEnhancers(appliedMiddleware));
    syncTranslationWithStore(store);
    store.dispatch(loadTranslations(translations));
    // localStore, chome/firefox/all browsers
    const browserLanguage = localStorage.getItem('locale') || navigator.language;
    const checkedLanguage = translations[browserLanguage] ? browserLanguage : defaultLanguage;
    store.dispatch(setLocale(checkedLanguage));
    return store;
};

export default storeCreater;
