import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { toggleErrorDialog } from 'actions';
import moment from 'moment';
import FormGroup from 'components/Form/FormGroup';
import RentalPhotoAttachment from 'components/RentalPhotoAttachment';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Translate, I18n } from 'react-redux-i18n';
import FlexGroup from 'components/FlexGroup';
import Input from 'components/Form/Input';
import { Button } from 'reactstrap';
import { convertToUTC } from 'helpers/time-handler';
import {
    TYPE_TEXT,
    TYPE_DATETIME,
} from 'components/Form/Input/input-type';
import { useLazyGetViolateRentalQuery } from 'redux/hook/useAdditionalFee';
import {
    CLAIM_TYPE_CLAIM,
} from 'constants/additional-fee';

import './violate-rental.scss';

const VIOLATE_TIME = 'violate_time';
const PLATE_NO = 'plate_no';
const RENTAL_ID = 'rental_id';

const SEARCH_BY_VIOLATION_INFO = 'search_by_violation';
const SEARCH_BY_RENTAL = 'search_by_rental';
const SEARCH_INTERVAL = 1000;

const ViolateRental = ({
    claimType,
    isUpdate,
    limitedData,
}) => {
    const dispatch = useDispatch();
    const i18n = useSelector(state => state.i18n);
    const [message, setMessage] = useState('additional_fee.violate_search_init_msg');
    const [isError, setIsError] = useState(false);
    const [rentalInfo, setRentalInfo] = useState({});
    const [rentalId, setRentalId] = useState('');
    const [plateNo, setPlateNo] = useState('');
    const [violateTime, setViolateTime] = useState(undefined);
    const [getViolateRental] = useLazyGetViolateRentalQuery();

    const clearSearchResult = () => {
        if (!isUpdate || !(isUpdate && limitedData?.rental_id)) {
            setRentalInfo({});
            setIsError(false);
            setMessage('additional_fee.violate_search_init_msg');
        }
    };

    const handlePlateNoChange = e => {
        setPlateNo(e.target.value);
        clearSearchResult();
    };
    const limitViolateTime = currentDate => {
        return currentDate.isBefore(Date.now());
    };
    const handleViolateTimeChange = date => {
        if (date.isValid && date.isValid()) {
            setViolateTime(date);
        }
        else {
            setViolateTime();
        }
        clearSearchResult();
    };
    const handleRentalIdChange = e => {
        setRentalId(e.target.value);
        clearSearchResult();

    };
    const messageStyle = classNames({
        'search-result-msg': true,
        'warning': isError,
    });

    const getSearchPayload = (searchType) => {
        switch (searchType) {
            case SEARCH_BY_RENTAL:
                const rental_id = rentalId || undefined;
                return (!rental_id ? undefined : {
                    rental_id,
                })
            case SEARCH_BY_VIOLATION_INFO:
            default:
                const plate_no = plateNo || undefined;
                const violate_time = violateTime ? convertToUTC(violateTime) : undefined;
                return (!plate_no && !violate_time) ?
                undefined : {
                    plate_no,
                    violate_time,
                };
        }
    };

    const handleSearch = searchType => async () => {
        const payload = getSearchPayload(searchType);
        setMessage('');
        setIsError(false);
        if (payload) {
            const { data, error } = await getViolateRental(payload);
            if (error) {
                dispatch(toggleErrorDialog(I18n.t('general_error_message')));
            }
            else {
                if (!data.rental_id) {
                    setMessage('no_match');
                    setIsError(true);
                }
                setRentalInfo(data);
            }
        }
    };


    useEffect(() => { }, [i18n]);

    useEffect(() => {
        if (isUpdate && limitedData) {
            setRentalInfo(limitedData);
            setPlateNo(limitedData?.plate_no || '');
            setViolateTime(limitedData?.violate_time ? moment(limitedData.violate_time) : '');
            setRentalId(limitedData?.rental_id || '');
        }
    }, [limitedData, isUpdate])


    return (
        <FlexGroup  className="violate-rental" start>
            <FlexGroup className="violate-rental-fields">
                <FlexGroup className="field-item-block">
                    <FlexGroup className="field-item-group">
                        <Input
                            name={ PLATE_NO }
                            type={ TYPE_TEXT }
                            caption="scooter.plate"
                            value={ plateNo }
                            onChange={ handlePlateNoChange }
                            required
                            disabled={ !!(isUpdate && limitedData?.plate_no) }
                        />
                        <Input
                            name={ VIOLATE_TIME }
                            type={ TYPE_DATETIME }
                            caption="additional_fee.violate_time"
                            dateFormat="YYYY-MM-DD"
                            timeFormat="HH:mm"
                            value={ violateTime }
                            onChange={ handleViolateTimeChange }
                            isValidDate={ limitViolateTime }
                            required
                        />
                    </FlexGroup>
                    <Button
                        type="button"
                        className="violate-btn btn-search"
                        outline
                        onClick={ debounce(handleSearch(SEARCH_BY_VIOLATION_INFO), SEARCH_INTERVAL) }
                        disabled={ !!(isUpdate && limitedData?.rental_id) }
                    >
                        <Translate value="additional_fee.btn_search_rental" />
                    </Button>
                </FlexGroup>
                <FlexGroup className="field-item-block">
                    <FlexGroup className="field-item-group">
                        <Input
                            name={ RENTAL_ID }
                            type={ TYPE_TEXT }
                            caption="rental.rental_no"
                            value={ rentalId }
                            onChange={ handleRentalIdChange }
                            required={ claimType === CLAIM_TYPE_CLAIM }
                            disabled={ !!(isUpdate && limitedData?.rental_id) }
                        />
                    </FlexGroup>
                    <Button
                        type="button"
                        className="violate-btn  btn-view"
                        outline
                        onClick={ debounce(handleSearch(SEARCH_BY_RENTAL), SEARCH_INTERVAL) }
                        disabled={ !!(isUpdate && limitedData?.rental_id) }
                    >
                        <Translate value="additional_fee.btn_view_rental" />
                    </Button>
                </FlexGroup>
            </FlexGroup>
            <FlexGroup className="result-block" end gap>
                <FormGroup
                    className="rental-info"
                    title={ I18n.t('additional_fee.information') }
                >
                    <Translate className="search-hint" value="additional_fee.violate_search_hint" tag="p" />
                    {
                        message ? (<Translate className={ messageStyle } value={ message } tag="p" />) : null
                    }
                    {
                        rentalInfo?.rental_id ? (
                            <>
                                <Input
                                    type={ TYPE_TEXT }
                                    caption="rental.rental_no"
                                    value={ rentalInfo?.rental_id || '--' }
                                    viewMode
                                />
                                <Input
                                    type={ TYPE_TEXT }
                                    caption="customer.customer_no"
                                    value={ rentalInfo?.customer_id || '--' }
                                    viewMode
                                />
                                <Input
                                    type={ TYPE_TEXT }
                                    caption="scooter.plate"
                                    value={ rentalInfo?.plate_no || '--' }
                                    viewMode
                                />
                                <Input
                                    type={ TYPE_DATETIME }
                                    caption="start_time"
                                    value={ rentalInfo?.start_time }
                                    viewMode
                                />
                                <Input
                                    type={ TYPE_DATETIME }
                                    caption="end_time"
                                    value={ rentalInfo?.end_time }
                                    viewMode
                                />
                                <Input
                                    type={ TYPE_TEXT }
                                    caption="rental.photo"
                                    value={ rentalInfo?.return_photo_url ? (
                                        <RentalPhotoAttachment url={ rentalInfo?.return_photo_url } />
                                    ) : '--'  }
                                    viewMode
                                />
                            </>
                        ) : null
                    }
                </FormGroup>
            </FlexGroup>
        </FlexGroup>
    );
};

ViolateRental.propTypes = {
    claimType: PropTypes.number,
    isUpdate: PropTypes.bool,
    limitedData: PropTypes.shape({}),
};
ViolateRental.defaultProps = {
    claimType: CLAIM_TYPE_CLAIM,
    isUpdate: false,
    limitedData: undefined,
};

export default ViolateRental;
