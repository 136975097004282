import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Datetime from 'components/Datetime';
import CopyToClipBoard from 'components/CopyToClipBoard';
import Cell from 'components/ListView/Cell';
import buildActualPath from 'helpers/build-actual-path';
import { VIOLATION_DETAIL } from '../../constants/routes';
import { I18n, Translate } from 'react-redux-i18n';

class ViolationListRow extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.shape({
            scooter_id: PropTypes.string,
            city: PropTypes.number,
            model: PropTypes.string,
            is_activate: PropTypes.bool,
            plate: PropTypes.string,
            soc: PropTypes.number,
            rental_state: PropTypes.number,
            last_rental_end_time: PropTypes.string,
            last_connection_date: PropTypes.string,
            total_mileage: PropTypes.number,
            tag: PropTypes.number,
            tag_description: PropTypes.string,
            tag_assignee: PropTypes.string,
            tracker_soc: PropTypes.number,
        }).isRequired,
    }

    getDuration = (now, time) => {
        const duration = Math.floor(now.diff(time, 'hours', true) * 10) / 10;
        return Math.max(duration, 0);
    }

    renderField({ data, name, width }) {
        let el;
        switch (name) {
        case 'violate_time':
        case 'start_time':
        case 'end_time':
            el = (<Datetime time={ data[name] } />);
            break;
        case 'rental_id':
        case 'customer_id':
            el = data[name] ? (
                <CopyToClipBoard>
                    { data[name] }
                </CopyToClipBoard>
            ) : '--';
            break;
        case 'full_name':
            el = '';
            break;
        case 'violate_in_rental':
            el = data[name] !== undefined ? <Translate value={ data[name] ? 'yes' : 'no' } /> : '--';
            break;
        case 'detail':
            el = data.rental_id ? (
                <Link target="_blank" to={ buildActualPath(VIOLATION_DETAIL, { rentalId: data.rental_id }) }>
                    { I18n.t('violation.detail') }
                </Link>
            ) : '--';
            break;
        default:
            el = data[name] ? data[name] : '--';
            break;
        }
        return (
            <Cell key={ `${ data.id }-${ name }` } name={ name } style={ { width } }>
                { el }
            </Cell>
        );
    }

    render() {
        const { columns, data } = this.props;

        return columns.map(({ key, width }) => this.renderField({ data, width, name: key, }));
    }
}

export default ViolationListRow;
