/**
 * Utilities
 */
import {
    ERROR,
    ASYNC_START,
    ASYNC_FINISHED,
} from 'constants/action-type';
import axios from 'axios';
import account from 'helpers/account';
import { fetchNewToken, logout, deleteCookie } from './account';
import { openPleaseLoginDialog } from './general';

export const checkToken = dispatch => {
    const expires_at = account.getExpiresAt();
    let promise = Promise.resolve();

    if (account.diff()) {
        promise = Promise.resolve(dispatch(openPleaseLoginDialog()));
    }
    else if (account.exists()) {
        if (Date.now() > expires_at || account.getID() === '') {
            promise = Promise.resolve(dispatch(fetchNewToken()));
            promise.then((res = {}) => {
                const { data } = res;

                if (data) {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${ data.id_token }`;
                }

                return res;
            })
                .catch(() => {
                    dispatch(logout());
                    dispatch(deleteCookie());
                });
        }
    }
    else {
        promise.then(() => {
            dispatch(logout());
        });
        promise = Promise.reject(new Error('Not Login Yet'));
    }

    return promise;
};

export const asyncError = err => {
    return {
        type: ERROR,
        error: err,
    };
};

export const asyncStart = () => {
    return {
        type: ASYNC_START,
    };
};

export const asyncFinished = () => {
    return {
        type: ASYNC_FINISHED,
    };
};

/**
 * Async action interface that wraps up the process.
 */
export const asyncInterface = ({ asyncAction, type, dispatch }) => {
    dispatch(asyncStart());

    return checkToken(dispatch).then(() => {
        return asyncAction();
    }).then(({ data }) => {
        dispatch(asyncFinished());

        return dispatch({
            type,
            data,
        });
    }).catch(err => {
        if (process.env.NODE_ENV !== 'production') {
            console.error(err);
        }
        dispatch(asyncFinished());
        if (err.response && err.response.status === 401) {
            dispatch(logout());
            dispatch(deleteCookie());
        }
        return dispatch(asyncError(err));
    });
};
