export const headerConfig = [{
    name: 'title',
    value: 'ad.title',
    sortable: true,
    width: 246,
}, {
    name: 'status',
    value: 'ad.status',
    sortable: true,
    width: 105,
}, {
    name: 'valid_from',
    value: 'ad.valid_from',
    sortable: true,
    width: 363,
}, {
    name: 'valid_to',
    value: 'ad.valid_to',
    sortable: true,
    width: 363,
}, {
    name: 'publish_time',
    value: 'ad.publish_time',
    sortable: true,
    width: 363,
}];

