import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import ImmutablePropTypes from 'react-immutable-proptypes';
import mobile from 'is-mobile';
import { NavLink } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import Smart from 'components/Smart';
import ListView from 'components/ListView';
import Pagination, { TotalCount, Refresh } from 'components/Pagination';
import PageSizeSelect from 'components/Pagination/PageSizeSelect';
import AuthFeature from 'components/AuthFeature';
import FlexGroup from 'components/FlexGroup';
import { NEW_SYSTEM_COUPON } from 'constants/routes';
import { AUTH_EDIT_COUPON } from 'constants/permission';
import SystemCouponListRow from './SystemCouponListRow';
import systemCouponListHeaderConfig from './systemCouponListHeaderConfig';

const isMobile = mobile();

class SystemCouponList extends React.Component {
    static propTypes = {
        list: ImmutablePropTypes.map,
        pageSize: PropTypes.number.isRequired,
        onSort: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onFetch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        list: null,
    };

    render() {
        const { list, onSort, onSelect, onChange, pageSize, onFetch } = this.props;
        const { page_index, page_count, data_list, total, __responseTime } = list?.toJS() ?? {};

        function ListItem({ columns, rowData }) {
            return (
                <SystemCouponListRow
                    key={ `coupon-system-${ rowData.id }` }
                    columns={ columns }
                    data={ rowData }
                />
            );
        }

        return (
            <React.Fragment>
                <FlexGroup className="functional-group">
                    <AuthFeature requiredList={ [AUTH_EDIT_COUPON] }>
                        <NavLink to={ NEW_SYSTEM_COUPON }>
                            <Button outline color="primary">
                                <Translate value="coupon.new_coupon" />
                            </Button>
                        </NavLink>
                    </AuthFeature>
                    <FlexGroup className="pagination-status">
                        <FlexGroup className="pagination-control">
                            <Pagination
                                page={ page_index }
                                total={ page_count }
                                offset={ pageSize }
                                onSelect={ onSelect }
                            />
                            <PageSizeSelect onChange={ onChange } />
                            <TotalCount count={ total } />
                        </FlexGroup>
                        <FlexGroup className="update-status">
                            <Refresh time={ __responseTime } onClick={ onFetch } />
                        </FlexGroup>
                    </FlexGroup>
                </FlexGroup>
                <Smart
                    className="list-view-wrap"
                    fetch={ onFetch }
                    pauseRefresh
                    seamless
                >
                    <ListView
                        dataKey="id"
                        header={ systemCouponListHeaderConfig }
                        onSort={ onSort }
                        list={ data_list }
                        renderListRow={ ListItem }
                        fixed={ isMobile }
                    />
                </Smart>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    list: state.coupon.get('systemCouponList'),
}))(SystemCouponList);
