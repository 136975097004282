/**
 * Reducer - Ad
 */
import { Map, List } from 'immutable';
import {
    FETCH_AD_LIST,
    CLEAR_AD_LIST,
    FETCH_SINGLE_AD,
    UPSERT_SINGLE_AD,
    CLEAR_SINGLE_AD,
    FETCH_ORDERABLE_AD_LIST,
    ORDER_AD_LIST,
    UPDATE_AD_ORDER,
} from 'constants/action-type';
import creater from './util/creater';

const initialState = Map({
    singleAd: {},
    list: Map({
        page_index: 1,
        page_count: 0,
        data_list: List([]),
    }),
    orderableList: Map({
        data_list: List([]),
    })
});
const setSingleAd = (state, data) => {
    let singleAd;

    if (data) {
        singleAd = {
            ...state.get('singleAd'),
            [data.id]: Map(data)
        };
    }
    else {
        singleAd = {};
    }
    return state.merge({
        singleAd: singleAd
    });
};

const actionsMap = {
    [FETCH_AD_LIST]: (state, action) => {
        const { page_index, page_count, data_list, total, __responseTime } = action.data;
        return state.merge({
            list: Map({
                data_list: List(data_list),
                page_index,
                page_count,
                total,
                __responseTime,
            }),
        });
    },
    [CLEAR_AD_LIST]: (state, action) => {
        return state.merge({
            list: initialState.get('list'),
        });
    },

    [FETCH_SINGLE_AD]: (state, action) => setSingleAd(state, action.data),
    [UPSERT_SINGLE_AD]: (state, action) => setSingleAd(state, action.data),
    [CLEAR_SINGLE_AD]: (state) => setSingleAd(state),
    [FETCH_ORDERABLE_AD_LIST]: reOrderAdList,
    [UPDATE_AD_ORDER]: reOrderAdList,
    [ORDER_AD_LIST]: (state, action) => {
        const { currentIndex, nextIndex } = action.data;
        const data_list = state.getIn(['orderableList', 'data_list']);
        const currentAd = data_list.get(currentIndex);
        const newDataList = data_list
            .delete(currentIndex)
            .insert(nextIndex, currentAd)
            .map((ad, index) => ({ ...ad, order: index }));

        return state.merge({
            orderableList: Map({
                data_list: newDataList
            })
        });
    }
};

function reOrderAdList(state, action) {
    const { data_list } = action.data;
    data_list.sort((a, b) => (
        a.order - b.order
    ));

    return state.merge({
        orderableList: Map({
            data_list: List(data_list.map((item, index) => ({ ...item, order: index }))),
        }),
    });
}

export default creater(actionsMap, initialState);
