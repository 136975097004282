import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import Modal from 'components/Modal';
import FlexGroup from 'components/FlexGroup';
import './please-login-dialog.scss';

class PleaseLoginDialog extends React.Component {
    static propTypes = {
        showPleaseLoginDialog: PropTypes.bool.isRequired,
    }

    render() {
        const { showPleaseLoginDialog } = this.props;

        return showPleaseLoginDialog && (
            <Modal className="please-login-dialog" title={ I18n.t('please_login') } disableCloseBtn disabledCloseOnBg>
                <Translate value="please_login_message" />
                <FlexGroup end>
                    <Button className="btn-login" color="primary" onClick={ () => window.location.reload() }>
                        <Translate value="login" />
                    </Button>
                </FlexGroup>
            </Modal>
        );
    }
}

export default connect(state => ({
    showPleaseLoginDialog: state.general.get('showPleaseLoginDialog'),
}))(PleaseLoginDialog);
