import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Datetime from 'react-datetime';
import { Col, Row, Button, Form, FormGroup, Label } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';

import 'react-datetime/css/react-datetime.css';

class Filter extends Component {
    static propTypes = {
        startDatetime: PropTypes.instanceOf(moment),
        endDatetime: PropTypes.instanceOf(moment),
        onApplyChanges: PropTypes.func.isRequired,
    }

    static defaultProps = {
        startDatetime: moment().add(-1, 'days'),
        endDatetime: moment(),
    }

    constructor(props) {
        super(props);

        this.startDt = props.startDatetime;
        this.endDt = props.endDatetime;
    }

    shouldComponentUpdate(nextProps) {
        const { startDatetime, endDatetime } = this.props;
        const currStartDt = startDatetime;
        const currEndDt = endDatetime;
        const nextStartDt = nextProps.startDatetime;
        const nextEndDt = nextProps.endDatetime;

        return (!currStartDt.isSame(nextStartDt)) || (!currEndDt.isSame(nextEndDt));
    }

    handleSubmit = e => {
        e.preventDefault();

        const { onApplyChanges } = this.props;
        const payload = {
            startDatetime: this.startDt,
            endDatetime: this.endDt,
        };

        onApplyChanges(payload);
    }

    render() {
        const { startDatetime, endDatetime } = this.props;
        const DatetimeInput = ({ value, onChange }) => (
            <Datetime
                defaultValue={ value }
                className="datatime"
                dateFormat={ I18n.t('datetime_format.date') }
                timeFormat={ I18n.t('datetime_format.time') }
                inputProps={ {
                    readOnly: true,
                } }
                onChange={ onChange }
            />
        );
        const dtFormat = I18n.t('datetime_format.long');
        const begin = startDatetime.format(dtFormat);
        const end = endDatetime.format(dtFormat);

        return (
            <Form className="history-filter" onSubmit={ this.handleSubmit }>
                <Row form>
                    <Col md={ 3 }>
                        <FormGroup>
                            <Label>
                                <Translate value="from" />
                            </Label>
                            <DatetimeInput value={ begin } onChange={ m => this.startDt = m } />
                        </FormGroup>
                    </Col>
                    <Col md={ 3 }>
                        <FormGroup>
                            <Label>
                                <Translate value="to" />
                            </Label>
                            <DatetimeInput value={ end } onChange={ m => this.endDt = m } />
                        </FormGroup>
                    </Col>
                    <Col md={ 2 }>
                        <Button>
                            <Translate value="go" />
                        </Button>
                    </Col>
                </Row>

            </Form>
        );
    }
}

export default Filter;
