import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import mobile from 'is-mobile';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Smart from 'components/Smart';
import FlexGroup from 'components/FlexGroup';
import ListView from 'components/ListView';
import { SORT_ORDER } from 'components/ListView/constants';
import Pagination, { Refresh, TotalCount } from 'components/Pagination';
import PageSizeSelect from 'components/Pagination/PageSizeSelect';
import { Translate } from 'react-redux-i18n';
import TotalSelection from 'components/TotalSelection';
import NewTicket from 'components/NewTicket';
import ActionPanel from 'components/ActionPanel';
import { Button } from 'reactstrap';
import headerConfig from './ListHeader';
import ListRow from './ListRow';
import AuthFeature from 'components/AuthFeature';
import { AUTH_CREATE_TICKET } from 'constants/permission';
import { filterCorp } from 'helpers/corp-warning';


class TotalList extends Component {
    static propTypes = {
        list: PropTypes.shape({
            page_index: PropTypes.number,
            page_count: PropTypes.number,
            data_list: ImmutablePropTypes.list,
        }).isRequired,
        pageSize: PropTypes.number.isRequired,
        onFetch: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        selectedIdList: PropTypes.arrayOf(PropTypes.string).isRequired,
    };

    constructor(props) {
        super(props);

        this.map = undefined;
        this.maps = undefined;
        this.mapEl = React.createRef();
        this.state = {
            createTicket: false,
            showActionPanel: false,
            isConditional: false,
            corpName: [],
        };
        this.enableHoverStyle = true;
        this.scrollThresholdTimer = undefined;
        this.mapSize = undefined;
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, false);

        if (this.mapEl.current) {
            this.mapSize = {
                width: this.mapEl.current.clientWidth,
                height: this.mapEl.current.clientHeight,
            };
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleScroll = () => {
        this.enableHoverStyle = false;

        // clear hover style
        const hoverMarkers = document.querySelectorAll('.hovered');

        hoverMarkers.forEach(el => {
            el.classList.remove('hovered');
        });

        clearTimeout(this.scrollThresholdTimer);
        this.scrollThresholdTimer = setTimeout(() => {
            this.enableHoverStyle = true;
        }, 100);
    }

    handleListSort = ({ field, order }) => {
        const { onChange } = this.props;
        let sort = undefined;

        if (field && order) {
            if (field === 'last_rental_end_time' || field === 'last_connection_date') {
                switch (order) {
                case SORT_ORDER.ASC:
                    order = SORT_ORDER.DESC;
                    break;
                case SORT_ORDER.DESC:
                    order = SORT_ORDER.ASC;
                    break;
                default:
                }
            }
            sort = `${ field },${ order }`;
        }

        onChange('sort')(sort);
    }

    handlePageSelect = page => {
        const { onChange } = this.props;

        onChange('page')(page);
    }

    handlePageSizeChange = size => {
        const { onChange } = this.props;

        onChange('size')(size);
    }

    afterNewTicket = (hasToggle = true) => {
        const { onSelect, onFetch } = this.props;
        onSelect([]);
        onFetch();
        hasToggle && this.toggleNewTicket()();
    }

    afterDidAction = () => {
        const { onSelect, onFetch } = this.props;
        onSelect([]);
        onFetch();
        this.toggleActionPanel();
    }

    toggleActionPanel = () => {
        const { showActionPanel } = this.state;
        this.setState({
            showActionPanel: !showActionPanel,
        });
    }

    toggleNewTicket = (isConditional = false) => () => {
        const { createTicket } = this.state;
        this.setState({
            createTicket: !createTicket,
            isConditional,
        });
    }

    setSelectById = (ids, selectAll) => {
        const { list, onSelect, selectedIdList } = this.props;
        const { data_list } = list.toJS();
        let updatedScooterList = [...selectedIdList];

        if (selectAll) {
            updatedScooterList = ids.length === data_list.length ? ids : [];
        }
        else {
            ids.forEach(id => {
                if (updatedScooterList.indexOf(id) === -1) {
                    updatedScooterList.push(id);
                }
                else {
                    const idx = updatedScooterList.indexOf(id);
                    updatedScooterList.splice(idx, 1);
                }
            });
        }
        const corporates = filterCorp({
            dataList: data_list,
            updatedDataList: updatedScooterList,
            key: 'scooter_id'
        });
        this.setState({ corpName: [...new Set(corporates)] });
        onSelect(updatedScooterList);
    }

    handleGoogleApi = ({ map, maps }) => {
        this.map = map;
        this.maps = maps;
    }

    handleOverlaycomplete = updatedIdSelectionList => {
        const { onSelect, selectedIdList } = this.props;
        const allList = new Set([...selectedIdList, ...updatedIdSelectionList]);

        onSelect(Array.from(allList));
    }

    render() {
        const { createTicket, showActionPanel, isConditional, corpName } = this.state;
        const { list, pageSize, onFetch, selectedIdList } = this.props;
        const { page_index, page_count, data_list, total, __responseTime } = list.toJS();
        const ListItem = ({ columns, rowData }) => (
            <ListRow
                key={ `scooter-${ rowData.scooter_id }` }
                columns={ columns }
                data={ rowData }
            />
        );
        const listCls = classNames([
            'list-view-wrap',
            'full',
        ]);


        return (
            <React.Fragment>
                <FlexGroup className="scooter-list-control-sec">
                    <FlexGroup start className="list-view-control">
                        <Button
                            className="btn-do-action"
                            color="primary"
                            type="button"
                            disabled={ selectedIdList.length === 0 }
                            onClick={ this.toggleActionPanel }
                        >
                            <Translate value="do_action" />
                        </Button>
                        <AuthFeature
                            requiredList={ [ AUTH_CREATE_TICKET ] }
                        >
                            <Button
                                outline
                                className="btn-new-ticket"
                                color="primary"
                                type="button"
                                disabled={ selectedIdList.length === 0 }
                                onClick={ this.toggleNewTicket() }
                            >
                                <Translate value="ticket.new" />
                            </Button>
                            <Button
                                outline
                                className="btn-conditional-ticket"
                                color="primary"
                                type="button"
                                disabled={ selectedIdList.length === 0 }
                                onClick={ this.toggleNewTicket(true) }
                            >
                                <Translate value="ticket.conditional" />
                            </Button>
                        </AuthFeature>
                        <div className="pagination-control-sec">
                            <Pagination
                                page={ page_index }
                                total={ page_count }
                                offset={ pageSize }
                                onSelect={ this.handlePageSelect }
                            />
                            <PageSizeSelect
                                onChange={ selection => this.handlePageSizeChange(selection.value) }
                            />
                            <TotalCount count={ total } />
                            <TotalSelection count={ selectedIdList.length } />
                        </div>
                    </FlexGroup>
                    <FlexGroup end>
                        <Refresh time={ __responseTime } onClick={ onFetch } />
                    </FlexGroup>
                </FlexGroup>
                <section className="scooter-list-table">
                    <div className={ listCls }>
                        <Smart
                            fetch={ onFetch }
                            pauseRefresh={ selectedIdList.length > 0 }
                            refreshTime={ 60 }
                            seamless={ true }
                            tag=""
                        >
                            <ListView
                                header={ headerConfig }
                                dataKey="scooter_id"
                                selectable={ true }
                                onSort={ this.handleListSort }
                                onSelect={ this.setSelectById }
                                defaultSelect={ selectedIdList }
                                list={ data_list }
                                renderListRow={ ListItem }
                                fixed={ mobile() }
                            />
                        </Smart>
                    </div>
                </section>
                <NewTicket
                    isConditional={ isConditional }
                    show={ createTicket }
                    scooterIdList={ selectedIdList }
                    corporate={ corpName }
                    onCreated={ this.afterNewTicket }
                    onCancelled={ this.toggleNewTicket() }
                />
                {
                    showActionPanel ?
                        (
                            <ActionPanel
                                scooterIdList={ selectedIdList }
                                onDidAction={ this.afterDidAction }
                                onClose={ this.toggleActionPanel }
                                corporate={ corpName }
                            />
                        ) :
                        null
                }
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    list: state.scooter.get('list'),
}))(TotalList);
