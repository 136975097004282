/**
 * Action - Dashboard
 */
import api from 'api';
import { asyncInterface } from './util';
import {
    FETCH_OVERALL_STATISTICS,
    FETCH_DOTS_STATISTICS,
    FETCH_TV_MONITOR_STATISTICS,
} from 'constants/action-type';

export const fetchOverallStat = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchStatistics(params),
        type: FETCH_OVERALL_STATISTICS,
        dispatch,
    });
};

export const fetchDotsStat = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchStatistics(params),
        type: FETCH_DOTS_STATISTICS,
        dispatch,
    });
};

export const fetchTVMonitorStat = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchStatistics(params),
        type: FETCH_TV_MONITOR_STATISTICS,
        dispatch,
    });
};
