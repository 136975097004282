import { createApi } from '@reduxjs/toolkit/query/react';
import { getDotsList, getDotsRegions, getFreeFloatingRegions, getOverallTotalStat, orderCities } from "reducers/dashboard";
import { baseQueryWithReauth } from './utils';
import { SERVICE_TYPE_FREE_FLOATING } from "constants/scooter";

export const DASHBOARD_STATISTICS = '/dashboards/fleet_statistics';

export const dashboardApi = createApi({
    reducerPath: 'dashboardApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getStatistics: builder.query({
            query: ({ params }) => ({
                url: DASHBOARD_STATISTICS,
                params
            }),
            forceRefetch: () => true,
            transformResponse: (response) => {
                const { update_time = '', cities = [], services = [], dots = [], overall : responseOverall = {} } = response ?? {};
                const overall = getOverallTotalStat(responseOverall);
                const freeFloating = getFreeFloatingRegions(cities);
                const dotsOverall = getDotsRegions(services);
                const dotsList = getDotsList(dots);
                const list = orderCities(freeFloating, dotsOverall);
                const { scooter_models } = responseOverall;
                const overallFreeFloating = services.filter(({ service_type }) => service_type === SERVICE_TYPE_FREE_FLOATING);
                return ({
                    updatedTime: update_time ?? Date.now(),
                    overall: {
                        regions: {
                            overall,
                            list
                        }
                    },
                    dots: {
                        overall: {
                            ...dotsOverall[0],
                        },
                        list: dotsList
                    },
                    tvMonitor: {
                        overallList: list,
                        overallFreeFloating: overallFreeFloating[0]
                    },
                    scooterModels: scooter_models
                });
            }
        })
    })
});

export const {
    useGetStatisticsQuery
} = dashboardApi;
