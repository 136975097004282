import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { Translate, I18n } from 'react-redux-i18n';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Form/Button';
import Form from 'components/Form';
import ModalWrapper from './ModalWrapper';

const RevokeFee = ({
    show,
    onSubmit,
    onClose,
}) => {
    const [inProgress, setInProgress] = useState(false);
    const handleSubmit = e => {
        setInProgress(true);
        onSubmit().then(() => {
            setInProgress(false);
        });
    };
    const handleClose = e => {
        onClose();
    };
    return (
        <>
            {
                show ? (
                    <ModalWrapper>
                        <Modal
                            className="revoke-modal"
                            title={ I18n.t('additional_fee.revoke_fee') }
                            disabledCloseOnBg
                            onClose={ handleClose }
                        >
                            <Form
                                inline
                                onSubmit={ handleSubmit }
                                inProgress={ inProgress }
                                stopSubmitByEnter
                            >
                                <Translate className="text-message" value="additional_fee.revoke_warning" />
                                <ButtonGroup>
                                    <Button
                                        type="button"
                                        className="btn-cancel"
                                        onClick={ onClose }
                                        outline
                                        stopTriggerByTab
                                    >
                                        <Translate value="cancel" />
                                    </Button>
                                    <Button type="submit" color="primary" stopTriggerByTab>
                                        <Translate value="submit" />
                                    </Button>
                                </ButtonGroup>
                            </Form>
                        </Modal>
                    </ModalWrapper>
                ) : null
            }
        </>
    );
};
RevokeFee.propTypes = {
    show: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
};
RevokeFee.defaultProps = {
    show: false,
    onSubmit: () => {},
    onClose: () => {},
};

export default RevokeFee;
