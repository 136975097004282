import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MyTaskList from './List';
import Toolbar from './Toolbar';
import { getDefaultPageSize } from 'components/Pagination/PageSizeSelect';
import { GROUP_STATUS_EXCLUDE_CLOSED } from 'constants/ticket.js';
import {
    updateDocumentTitle,
    clearVMS,
    clearNearbyScooters,
} from 'actions';

export const MODE_LIST = 'list';
export const MODE_MAP = 'map';


class MyTickets extends Component {
    static propTypes = {
        onFetch: PropTypes.func.isRequired,
        showMap: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.queryPayload = {
            plate: undefined,
            sort: {},
            page: 1,
            size: getDefaultPageSize().value,
            status_id: GROUP_STATUS_EXCLUDE_CLOSED,
        };
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(updateDocumentTitle('ticket.my_ticket'));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearVMS());
        dispatch(clearNearbyScooters());
    }

    handleListSort = ({ field, order }) => {
        const { onFetch } = this.props;
        if (field && order) {
            this.queryPayload.sort = `${field},${order}`;
        }
        else {
            this.queryPayload.sort = undefined;
        }

        this.queryPayload.page = 1;
        onFetch(true, this.queryPayload);
    }

    handlePageSelect = page => {
        const { onFetch } = this.props;
        this.queryPayload.page = page;
        onFetch(true, this.queryPayload);
    }

    handlePageSizeChange = selection => {
        const { onFetch } = this.props;
        if (selection !== this.queryPayload.size) {
            this.queryPayload.page = 1;
            this.queryPayload.size = selection;
            onFetch(true, this.queryPayload);
        }
    }

    handleSubmit = partialPayload => {
        const { onFetch } = this.props;
        this.queryPayload = {
            ...this.queryPayload,
            ...partialPayload,
        };
        onFetch(true, this.queryPayload);
    }

    render() {
        const { onFetch, showMap } = this.props;

        return (
            <>
                <Toolbar
                    defaultViewMode={ showMap ? MODE_MAP : MODE_LIST }
                    onSearch={ this.handleSubmit }
                />
                <div className="my-tickets">
                    <MyTaskList
                        pageSize={ this.queryPayload.size }
                        onSelect={ this.handlePageSelect }
                        onPageSizeChange={ this.handlePageSizeChange }
                        onFetch={ onFetch }
                        onSort={ this.handleListSort }
                        showMap={ showMap }
                    />
                </div>
            </>
        );
    };
}

export default connect(state => ({
    i18n: state.i18n,
}))(MyTickets);
