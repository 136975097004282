import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { getRentalStatusOptions } from 'components/Scooter/RentalState';
import FlexGroup from 'components/FlexGroup';
import SearchField from 'components/SearchField';
import MultiselectFilter from 'components/Filter/MultiselectFilter';
import ScooterModelFilter from 'components/Filter/ScooterModelFilter';
import VipScooterFilter from 'components/Filter/VipScooterFilter';
import NumberRangeFilter from 'components/Filter/NumberRangeFilter';
import CustomMainTypeFilter from 'components/Ticket/MainType';
import CustomSubTypeFilter from 'components/Ticket/SubType';
import {
    SCOOTER_OFFLINE,
    SCOOTER_ONLINE,
} from 'constants/scooter';

export const FILTER_SERVICE = 'is_activate';
export const FILTER_RENTAL_STATE = 'rental_state';
export const FILTER_BATTERY = 'soc';
export const FILTER_TRACKER_SOC = 'tracker_soc';
export const FILTER_MAIN_TYPE = 'tracker_id';
export const FILTER_SUB_TYPE = 'tracker_subtype_id';
export const FILTER_TAG_ASSIGNEE = 'tag_assignee';
export const FILTER_MODEL = 'model';
export const FILTER_CITY = 'city';
export const FILTER_SCOOTER_TICKET = 'filter_no_open_tickets_scooter';
export const FILTER_VIP_SCOOTER = 'service_id';


const Component = ({ defaultSettings, onSubmit, onChange, excludeList, cities }) => {
    const availabilityOptions = [
        SCOOTER_OFFLINE,
        SCOOTER_ONLINE,
    ].map(
        value => ({
            text: I18n.t(`scooter.availability_state_map.${ value }`),
            value,
        })
    );
    const handleChange = type => value => {
        onChange(type, value);
    };
    const { filter } = defaultSettings;
    const FilterWrapper = ({ hide = false, children }) => {
        if (hide) {
            return null;
        }
        else {
            return children;
        }
    };
    const ticket = filter[FILTER_SCOOTER_TICKET] ?? [];
    const citiesOptions = cities.toJS().map(({ id, name }) => {
        return {
            value: id,
            text: name
        }
    })

    return (
        <FlexGroup start>
            <SearchField
                placeholder={ I18n.t('search') }
                onSubmit={ onSubmit }
                value={ defaultSettings.plate }
            />
            <section className="filters">
                <FilterWrapper hide={ excludeList.includes(FILTER_SERVICE) }>
                    <MultiselectFilter
                        title={ I18n.t('scooter.service') }
                        options={ availabilityOptions }
                        defaultSelected={ filter[FILTER_SERVICE] }
                        onChange={ handleChange(FILTER_SERVICE) }
                    />
                </FilterWrapper>
                <FilterWrapper hide={ excludeList.includes(FILTER_RENTAL_STATE) }>
                    <MultiselectFilter
                        title={ I18n.t('scooter.rental_state') }
                        options={ getRentalStatusOptions(true) }
                        defaultSelected={ filter[FILTER_RENTAL_STATE] }
                        onChange={ handleChange(FILTER_RENTAL_STATE) }
                    />
                </FilterWrapper>
                <FilterWrapper hide={ excludeList.includes(FILTER_BATTERY) }>
                    <NumberRangeFilter
                        title={ I18n.t('scooter.battery') }
                        defaultFrom={ filter[`${ FILTER_BATTERY }_from`] }
                        defaultTo={ filter[`${ FILTER_BATTERY }_to`] }
                        onApply={ handleChange(FILTER_BATTERY) }
                        applied={
                            typeof filter[`${ FILTER_BATTERY }_from`] === 'number' &&
                            typeof filter[`${ FILTER_BATTERY }_to`] === 'number'
                        }
                    />
                </FilterWrapper>
                <FilterWrapper hide={ excludeList.includes(FILTER_TRACKER_SOC) }>
                    <NumberRangeFilter
                        title={ I18n.t('scooter.tracker_soc') }
                        defaultFrom={ filter[`${ FILTER_TRACKER_SOC }_from`] }
                        defaultTo={ filter[`${ FILTER_TRACKER_SOC }_to`] }
                        onApply={ handleChange(FILTER_TRACKER_SOC) }
                        applied={
                            typeof filter[`${ FILTER_TRACKER_SOC }_from`] === 'number' &&
                            typeof filter[`${ FILTER_TRACKER_SOC }_to`] === 'number'
                        }
                    />
                </FilterWrapper>
                <FilterWrapper hide={ excludeList.includes(FILTER_MAIN_TYPE) }>
                    <CustomMainTypeFilter
                        selected={ filter[FILTER_MAIN_TYPE] }
                        onChange={ handleChange(FILTER_MAIN_TYPE) }
                    />
                </FilterWrapper>
                <FilterWrapper hide={ excludeList.includes(FILTER_SUB_TYPE) }>
                    <CustomSubTypeFilter
                        selected={ filter[FILTER_SUB_TYPE] }
                        onChange={ handleChange(FILTER_SUB_TYPE) }
                    />
                </FilterWrapper>
                <FilterWrapper hide={ excludeList.includes(FILTER_SCOOTER_TICKET) }>
                    <MultiselectFilter
                        title={ I18n.t('ticket.ticket') }
                        options={ [{
                            text: I18n.t('have'),
                            value: 'false',
                        }, {
                            text: I18n.t('not_have'),
                            value: 'true',
                        }] }
                        defaultSelected={ ticket }
                        onChange={ handleChange(FILTER_SCOOTER_TICKET) }
                    />
                </FilterWrapper>
                <FilterWrapper hide={ excludeList.includes(FILTER_MODEL) }>
                    <ScooterModelFilter
                        defaultSelected={ filter[FILTER_MODEL] }
                        onChange={ handleChange(FILTER_MODEL) }
                    />
                </FilterWrapper>
                <FilterWrapper hide={ excludeList.includes(FILTER_CITY) }>
                    <MultiselectFilter
                        title={ I18n.t('scooter.city') }
                        options={ citiesOptions }
                        defaultSelected={ filter[FILTER_CITY] }
                        onChange={ handleChange(FILTER_CITY) }
                    />
                </FilterWrapper>
                <FilterWrapper hide={ excludeList.includes(FILTER_VIP_SCOOTER) }>
                    <VipScooterFilter
                        defaultSelected={ filter[FILTER_VIP_SCOOTER] }
                        onChange={ handleChange(FILTER_VIP_SCOOTER) }
                    />
                </FilterWrapper>
            </section>
        </FlexGroup>
    );
};
Component.propTypes = {
    defaultSettings: PropTypes.shape().isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    excludeList: PropTypes.arrayOf(PropTypes.oneOf([
        FILTER_SERVICE,
        FILTER_RENTAL_STATE,
        FILTER_BATTERY,
        FILTER_TRACKER_SOC,
        FILTER_MAIN_TYPE,
        FILTER_SUB_TYPE,
        FILTER_TAG_ASSIGNEE,
        FILTER_MODEL,
        FILTER_CITY,
        FILTER_SCOOTER_TICKET,
        FILTER_VIP_SCOOTER,
    ])),
};
Component.defaultProps = {
    excludeList: [],
};

export default connect(state => ({
    cities: state.cities.get('cities')
}))(Component);
