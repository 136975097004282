import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';
import { Translate } from 'react-redux-i18n';

function ControlTemplate({ captionKey, children, large, withUnit, required }) {
    return (
        <FormGroup row>
            <Label sm={ 2 }>
                <Translate value={ `coupon.${ captionKey }` } className={ `field ${ required ? 'required' : '' }` } />
            </Label>
            <Col sm={ large ? 10 : 3 } className={ withUnit ? 'with-unit' : '' }>
                { children }
            </Col>
        </FormGroup>
    );
};

ControlTemplate.propTypes = {
    captionKey: PropTypes.string,
    large: PropTypes.bool,
    withUnit: PropTypes.bool,
    required: PropTypes.bool,
};


ControlTemplate.defaultProps = {
    captionKey: '',
    large: false,
    withUnit: false,
    required: true,
};

export default ControlTemplate;
