import {
    MAIN_TYPE_SWAP,
    MAIN_TYPE_DISPATCH,
    MAIN_TYPE_MAINTENANCE,
    MAIN_TYPE_OTHERS,

    SUB_TYPE_101,
    SUB_TYPE_102,
    SUB_TYPE_201,
    SUB_TYPE_202,
    SUB_TYPE_203,
    SUB_TYPE_204,
    SUB_TYPE_205,
    SUB_TYPE_206,
    SUB_TYPE_207,
    SUB_TYPE_213,
    SUB_TYPE_301,
    SUB_TYPE_302,
    SUB_TYPE_303,
    SUB_TYPE_304,
    SUB_TYPE_305,
    SUB_TYPE_401,
    SUB_TYPE_402,
    SUB_TYPE_403,
    SUB_TYPE_404,
    SUB_TYPE_405,
    SUB_TYPE_406,
    SUB_TYPE_407,
    SUB_TYPE_408,
    SUB_TYPE_409,
    SUB_TYPE_410,
    SUB_TYPE_411,
    SUB_TYPE_412,
    SUB_TYPE_413,
    SUB_TYPE_414,
    SUB_TYPE_415,
    SUB_TYPE_416,
    SUB_TYPE_417,
    SUB_TYPE_418,
    SUB_TYPE_419,
    SUB_TYPE_420,
    SUB_TYPE_421,

    STATUS_OPEN,
    STATUS_RECEIVED,
    STATUS_CHECKED,
    STATUS_IN_PROGRESS,
    STATUS_RESOLVED,
    STATUS_IN_QC,
    STATUS_DONE_QC,
    STATUS_BLOCKED_OTHERS,
    STATUS_BLOCKED_SHORT_OF_MATERIAL,
    STATUS_Blocked_TECH,
    STATUS_CLOSED,
    STATUS_CLOSED_NEW_TICKET,

    SEVERITY_LOW,
    SEVERITY_MEDIUM,
    SEVERITY_HIGH,
} from 'constants/ticket';

const ticket = {
    'new': 'New Ticket',
    'conditional': 'Conditional',
    'tickets': 'Tickets',
    'scooters': 'Scooters',
    'main_title': 'Ticket',
    'view_title': 'View Ticket',
    'scooter_title': 'Scooter Management',
    'my_ticket': 'My Tickets',
    'ticket': 'Ticket',

    'main_type': 'Main Type',
    [`main_type_${ MAIN_TYPE_SWAP }`]: 'Swap',
    [`main_type_${ MAIN_TYPE_DISPATCH }`]: 'Dispatch',
    [`main_type_${ MAIN_TYPE_MAINTENANCE }`]: 'Maintenance',
    [`main_type_${ MAIN_TYPE_OTHERS }`]: 'Others',
    'sub_type': 'Sub Type',
    [`sub_type_${ SUB_TYPE_101 }`]: 'Swap (Low Battery)',
    [`sub_type_${ SUB_TYPE_102 }`]: 'Swap (Floating)',
    [`sub_type_${ SUB_TYPE_201 }`]: 'Dispatch (To Mega Center)',
    [`sub_type_${ SUB_TYPE_202 }`]: 'Dispatch (From Mega center)',
    [`sub_type_${ SUB_TYPE_203 }`]: 'Dispatch (Deployment)',
    [`sub_type_${ SUB_TYPE_204 }`]: 'Dispatch (on-demand)',
    [`sub_type_${ SUB_TYPE_205 }`]: 'Dispatch (Out-of-zone)',
    [`sub_type_${ SUB_TYPE_206 }`]: 'Dispatch (Hot/Cold zones)',
    [`sub_type_${ SUB_TYPE_207 }`]: 'Dispatch (From tow pounds)',
    [`sub_type_${ SUB_TYPE_213 }`]: 'Dispatch (新車投放)',
    [`sub_type_${ SUB_TYPE_301 }`]: 'Maintenance (General)',
    [`sub_type_${ SUB_TYPE_302 }`]: 'Maintenance (Light)',
    [`sub_type_${ SUB_TYPE_303 }`]: 'Maintenance (Heavy)',
    [`sub_type_${ SUB_TYPE_304 }`]: 'Maintenance (Regular)',
    [`sub_type_${ SUB_TYPE_305 }`]: 'Maintenance (Medium)',
    [`sub_type_${ SUB_TYPE_401 }`]: 'Others (Consumables)',
    [`sub_type_${ SUB_TYPE_402 }`]: 'Others (Batteries got stuck)',
    [`sub_type_${ SUB_TYPE_403 }`]: 'Others (Missing)',
    [`sub_type_${ SUB_TYPE_404 }`]: 'Others (Towed)',
    [`sub_type_${ SUB_TYPE_405 }`]: 'Others (Shutdown)',
    [`sub_type_${ SUB_TYPE_406 }`]: 'Others (Lost items)',
    [`sub_type_${ SUB_TYPE_407 }`]: 'Others (Move)',
    [`sub_type_${ SUB_TYPE_408 }`]: 'Others (Close trunk)',
    [`sub_type_${ SUB_TYPE_409 }`]: 'Others (Tighten the mirrors)',
    [`sub_type_${ SUB_TYPE_410 }`]: 'Others (Charger board)',
    [`sub_type_${ SUB_TYPE_411 }`]: 'Others (SOP檢查)',
    [`sub_type_${ SUB_TYPE_412 }`]: 'Others (RD)',
    [`sub_type_${ SUB_TYPE_413 }`]: 'Others (MKT)',
    [`sub_type_${ SUB_TYPE_414 }`]: 'Others (行照變更)',
    [`sub_type_${ SUB_TYPE_415 }`]: 'Others (主管交辦)',
    [`sub_type_${ SUB_TYPE_416 }`]: 'Others (CS釐清)',
    [`sub_type_${ SUB_TYPE_417 }`]: 'Others (多任務)',
    [`sub_type_${ SUB_TYPE_418 }`]: 'Others (監控)',
    [`sub_type_${ SUB_TYPE_419 }`]: 'Others (特殊換電)',
    [`sub_type_${ SUB_TYPE_420 }`]: 'Others (特殊調度)',
    [`sub_type_${ SUB_TYPE_421 }`]: 'Others (Disinfection)',

    'status': 'Status',
    [`status_${ STATUS_OPEN }`]: 'Open',
    [`status_${ STATUS_RECEIVED }`]: 'Received',
    [`status_${ STATUS_CHECKED }`]: 'Checked',
    [`status_${ STATUS_IN_PROGRESS }`]: 'In Progress',
    [`status_${ STATUS_RESOLVED }`]: 'Resolved',
    [`status_${ STATUS_IN_QC }`]: 'In QC',
    [`status_${ STATUS_DONE_QC }`]: 'Done QC',
    [`status_${ STATUS_BLOCKED_OTHERS }`]: 'Blocked',
    [`status_${ STATUS_BLOCKED_SHORT_OF_MATERIAL }`]: 'Blocked (Short of material)',
    [`status_${ STATUS_Blocked_TECH }`]: 'Blocked (Tech issue)',
    [`status_${ STATUS_CLOSED }`]: 'Closed',
    [`status_${ STATUS_CLOSED_NEW_TICKET }`]: 'Closed with new ticket',

    [`severity_${ SEVERITY_LOW }`]: 'Low',
    [`severity_${ SEVERITY_MEDIUM }`]: 'Medium',
    [`severity_${ SEVERITY_HIGH }`]: 'High',

    'assignee': 'Assignee',
    'due_time': 'Due Time',
    'import_plate_list': 'Import Conditional file',
    'priority': 'Priority',
    'create_time': 'Created Time',
    'description': 'Description',
    'destination': 'Destination',
    'dms_no': 'DMS No.',
    'mega_center': 'Mega Center',
    'update_time': 'Updated Time',
    'reporter': 'Reporter',
    'general_error_message': 'Oops! Something went wrong. Please try again.',
    'specific_tickets_error_message': 'Oops! Ticket No (%{tickets}) gets problem.',
    'unclosed_tickets_error_message': '切換狀態"任務完成"已成功，此車輛有未完成的工單，仍無法上線',
    'please_check_scooter_error_message': 'Please check scooter condition.',
    'conditional_modal_title': 'Conditional New Ticket',
    'conditional_no_intersection_error_message': 'NO intersection scooters',
    'conditional_scooters_overflow_error_message':
        'Number of scooters in imported conditional file is not within 1~5000',
    'conditional_scooters_empty_error_message': 'Imported conditional file is empty',

    'conditional_result_imported_scooters_amount': 'Total number of scooters:',
    'conditional_result_unqualified_scooters_amount': 'Not eligible:',
    'conditional_result_tickets_amount': 'Tickets counts:',
    'conditional_result_repeated_scooters': 'Repeated scooters:',

    'batch_edit': 'Batch Edit',
    'comment': 'Comment',
    'whether_modify_description': 'Modify descriptions',
    'ticket_no': 'Ticket No.',
    'display_map': 'Display Map',
    'edit_ticket_info': 'Edit Ticket',


    'details': 'Details',
    'log': 'Log',

    // map for logs
    'log_sentence': 'Changed <strong>%{field}</strong> from <em>%{from}</em> to <em>%{to}</em>',
    'number_constraint_of_ticket': 'Choose no more than 200 tickets.',
    'flow': 'Ticket Flow',

    'maintenance_subtitle': 'Completed',
    'maintenance_mileage': 'Mileage',
    'maintenance_date': 'Maintenance Date',
    'maintenance_title': 'Regular Maintenance',
};

export default ticket;
