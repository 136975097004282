/**
 * helper for setting a search box
 */
const setSearchBox = function({ map, maps, el }) {
    if (map && maps && el) {
        const searchBox = new maps.places.Autocomplete(el, {
            fields: [
                'geometry.location',
                'name',
                'place_id',
            ]
        });

        map.controls[maps.ControlPosition.TOP_RIGHT].push(el);

        const marker = new maps.Marker();
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        maps.event.addListener(searchBox, 'place_changed', () => {
            const place = searchBox.getPlace();

            // Clear out the old markers.
            marker.setMap(null);

            if (!place.geometry) {
                console.log('Returned place contains no geometry');
                return;
            }

            marker.setOptions({
                map,
                title: place.name,
                position: place.geometry.location,
            });

            map.panTo(place.geometry.location);
            map.setZoom(15);
        });
    }
};

export default setSearchBox;
