import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import MultiSelectFilter from 'components/Filter/MultiselectFilter';
import { TYPE_GROUP } from 'constants/promotion';

export default function TypeFilter({ onChange, selected }) {
    const options = TYPE_GROUP.map(type => ({
        value: type,
        text: I18n.t(`promotion.type_${ type }`),
    }));

    return (
        <MultiSelectFilter
            title={ I18n.t('promotion.type') }
            options={ options }
            defaultSelected={ selected }
            onChange={ onChange }
        />
    );
}

TypeFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.number),
};

TypeFilter.defaultProps = {
    selected: [],
};
