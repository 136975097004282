/**
 * convert into Array<Number>
 */
import typeArray from './index';

const arrayNumber = val => {
    const checker = n => n || n === 0;
    /* istanbul ignore next */
    const converter = n => checker(n) ? Number(n) : undefined;

    return typeArray(val, checker, converter);
};

export default arrayNumber;
