import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import Smart from 'components/Smart';
import headerConfig from './list/header';
import ListRow from './list/Row';
import ListView from 'components/ListView';
import FlexGroup from 'components/FlexGroup';
import Pagination, { TotalCount, Refresh } from 'components/Pagination';
import PageSizeSelect from 'components/Pagination/PageSizeSelect';
import { Translate } from 'react-redux-i18n';
import mobile from 'is-mobile';
import moment from 'moment';

const isMobile = mobile();

const Period = ({ fromTime, toTime }) => {
    let el = null;

    if (fromTime && toTime) {
        el = (
            <div className="rental-period">
                <Translate className="record-periods" value="rental.record_periods" />
                <span>{ moment(fromTime).format('YYYY-MM-DD') }</span>
                <span className="divider">-</span>
                <span>{ moment(toTime).format('YYYY-MM-DD') }</span>
            </div>
        );
    }

    return el;
};


class rentalAuditList extends Component {
    static propTypes = {
        rentalLog: ImmutablePropTypes.map.isRequired,
        onSort: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        pageSize: PropTypes.number.isRequired,
        onFetch: PropTypes.func.isRequired,
        fromTime: PropTypes.oneOfType([
            PropTypes.instanceOf(moment),
            PropTypes.string,
        ]),
        toTime: PropTypes.oneOfType([
            PropTypes.instanceOf(moment),
            PropTypes.string,
        ]),
    };

    static defaultProps = {
        fromTime: undefined,
        toTime: undefined,
    };

    render() {
        const { rentalLog, onSort, onSelect, onChange, pageSize, onFetch, fromTime, toTime } = this.props;
        const {
            page_index: pageIndex,
            page_count: pageCount,
            data_list: dataList,
            total,
            __responseTime,
        } = (rentalLog && rentalLog.toJS()) || {};
        const ListItem = ({ columns, rowData }) => (
            <ListRow
                key={ `log-${ rowData.id }` }
                columns={ columns }
                data={ rowData }
            />
        );

        return (
            <React.Fragment>
                <FlexGroup className="pagination-control-sec">
                    <FlexGroup start>
                        <Pagination
                            page={ pageIndex }
                            total={ pageCount }
                            offset={ pageSize }
                            onSelect={ onSelect }
                        />
                        <PageSizeSelect
                            onChange={ onChange }
                        />
                        <TotalCount count={ total } />
                        <Period fromTime={ fromTime } toTime={ toTime } />
                    </FlexGroup>
                    <FlexGroup end>
                        <Refresh time={ __responseTime } onClick={ onFetch } />
                    </FlexGroup>
                </FlexGroup>
                <Smart
                    fetch={ onFetch }
                    refreshTime={ 60 }
                    seamless={ true }
                    tag=""
                >
                    <ListView
                        header={ headerConfig }
                        onSort={ onSort }
                        list={ dataList }
                        renderListRow={ ListItem }
                        dataKey="auditlog_id"
                        fixed={ isMobile }
                    />
                </Smart>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    rentalLog: state.log.get('rental'),
}))(rentalAuditList);
