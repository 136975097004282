import React from 'react';
import { convertDistance } from 'helpers/util';

const RentalDetails = ({ data, className }) => {
    let el = <div className={ className } />;

    if (data) {
        const { phone, email, distance } = data;

        el = (
            <div className={ className }>
                <p>
                    <span className="icon fas fa-phone" />
                    { phone }
                </p>
                <p>
                    <span className="icon fas fa-envelope" />
                    <a href={ `mailto:${ email }` }>{ email }</a>
                </p>
                <p>
                    <span className="icon fas fa-tachometer-alt" title={ distance } />
                    { convertDistance(distance) }
                </p>
            </div>
        );
    }

    return el;
};

export default RentalDetails;
