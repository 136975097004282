import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { List, } from 'immutable';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { I18n } from 'react-redux-i18n';
import { overallListMapFitbound, listFitBound, checkRouteStatus, checkRouteDifferent } from 'helpers/ticket-system';
import GoogleMap, { VIP_READ, VIP_SELECT } from 'components/Map';
import MapFooter from '../MapFooter';
import getAuthedToolBoxList from 'components/Map/util/get-authed-toolbox';
import addServiceZone from 'components/Map/Layers/service-zone';
import {
    CENTER_TO_CURRENT_LOCATION,
    VMS,
} from 'components/Map/Toolbox';
import ButtonFetchNearByScooter from '../ButtonFetchNearByScooter';
import {
    fetchNearbyScooters,
    showMapContent,
    selectScooter,
    toggleErrorDialog,
    clearVipLayer
} from 'actions';
import './ticket-map.scss';
import { GoShareID } from 'constants/service-zones';

const defaultToolbox = [CENTER_TO_CURRENT_LOCATION, VMS];

class TicketMap extends Component {
    static propTypes = {
        params: PropTypes.shape({}).isRequired,
        resetBounds: PropTypes.number.isRequired,
        tasks: PropTypes.shape({
            page_index: PropTypes.number,
            page_count: PropTypes.number,
            data_list: ImmutablePropTypes.list,
        }).isRequired,
        oneTicket: ImmutablePropTypes.map.isRequired,
        singleScooter: PropTypes.shape({}),
        nearbyList: ImmutablePropTypes.list,
        currentGeoLocation: PropTypes.shape(),
        selectedVipLayerList: ImmutablePropTypes.list.isRequired,
        onFetchTask: PropTypes.func,
        onTilesLoaded: PropTypes.func,
        selected: ImmutablePropTypes.map.isRequired,
    };
    static defaultProps = {
        singleScooter: null,
        nearbyList: List([]),
        currentGeoLocation: undefined,
        onFetchTask: () => {},
        onTilesLoaded: () => {},
    };

    constructor(props) {
        super(props);
        this.vipLayer = VIP_SELECT;
        this.service_ids = [];
        this.defaultBusinessID = [];
        this.ticketList = [];
        this.state = {
            selectedScooter: undefined,
            // detail頁面只有在第一次load且有取得資料的時候才setScooterPosition
            detailFirstLoad: true,
        };
    }

    componentDidMount() {
        const { tasks } = this.props;
        const taskList = tasks.get('data_list');
        const dataList = taskList.toJS();
        const serviceIdArray = [...new Set(dataList.map(({ service_id }) => service_id))];
        this.defaultBusinessID = serviceIdArray;
    }

    async componentDidUpdate(prevProps, prevState) {
        const { showMap,
                tasks,
                params,
                singleScooter,
                oneTicket,
                selectedVipLayerList,
                resetBounds
        } = this.props;
        const prevResetBounds = prevProps.resetBounds;
        if ((prevResetBounds !== resetBounds && checkRouteStatus(params))
        || params !== prevProps.params || (showMap && prevProps.showMap !== showMap)) {
            this.mapFitBounds();
            window.addEventListener('resize', this.resizeFitBoundsEvent);
            this.closeFooter();
            // 回到MyTicket或變更routes時要設定回復為Firstload的狀態
            this.setState({ detailFirstLoad: true });
        }
        if ((prevProps.singleScooter !== singleScooter || prevProps.oneTicket !== oneTicket
            || checkRouteDifferent(prevProps.params, params))
            && !checkRouteStatus(params)) {
            window.removeEventListener('resize', this.resizeFitBoundsEvent);
            const { detailFirstLoad } = this.state;
            let serviceId = '';
            if (params['ticketId'] && oneTicket.size !== 0) {
                const {
                    service_id,
                    location_lat,
                    location_lng,
                } = oneTicket.toJS();
                serviceId = service_id;
                if (this.map && detailFirstLoad) {
                    this.setState({ detailFirstLoad: false },
                        this.setToScooterPosition(location_lat, location_lng)
                    );
                }
            }
            else {
                if (singleScooter) {
                    const { service_id, current_location } = singleScooter.toJS();
                    const { lat, lng } = current_location;
                    serviceId = service_id;
                    if (this.map && detailFirstLoad) {
                        this.setState({ detailFirstLoad: false },
                            this.setToScooterPosition(lat, lng)
                        );
                    }
                }
            }
            this.defaultBusinessID = [...new Set([serviceId])];
            if (serviceId !== '' && detailFirstLoad) {
                await addServiceZone({
                    map: this.map,
                    params: { service_id: serviceId },
                    selected: true,
                    vipLayer: this.vipLayer
                });
            }
        }
        const prevVipList = prevProps.selectedVipLayerList;
        const vipList = selectedVipLayerList.toJS();
        if (!_(prevVipList.toJS()).xorWith(vipList, _.isEqual).isEmpty()) {
            if (vipList.length !== 0) {
                const serviceIdArray = [];
                vipList.forEach(({ service_ids }) => {
                    if (!serviceIdArray.includes(...service_ids)) {
                        serviceIdArray.push(...service_ids);
                    }
                    this.service_ids = [...serviceIdArray];
                });
            }
            else {
                this.service_ids = [];
            }
            // init vipList will default as vipList.length !== 0 and service_ids.length === 0,
            // this criteria should not execute
            if (vipList.length === 0 || (vipList.length !== 0 && vipList[0].service_ids.length !== 0)) {
                addServiceZone({
                    map: this.map,
                    params: { service_id: this.service_ids },
                    selected: true,
                    vipLayer: this.vipLayer
                });
            }
        };
        if (tasks.toJS().data_list.length !== 0
            && prevProps.tasks !== tasks
            && checkRouteStatus(params)
        ) {
            const taskList = tasks.get('data_list');
            const dataList = taskList.toJS();
            const serviceIdArray = [...new Set(dataList.map(({ service_id }) => service_id))];
            this.defaultBusinessID = serviceIdArray;
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearVipLayer());
        window.removeEventListener('resize', this.resizeFitBoundsEvent);
    }

    resizeFitBoundsEvent = () => {
        const { selected } = this.props;
        if (selected.size === 0) {
            this.mapFitBounds();
        }
    }

    mapFitBounds = (list = null, panTo = true) => {
        let listData = list;
        if (listData === null) {
            const overallList = this.getMergedScooterList();
            listData = this.getUniqueScooterList(overallList);
            overallListMapFitbound(listData, this.map, this.maps);
        }
        else {
            listFitBound(listData, this.map, this.maps, panTo);
        }
    }

    handleGoogleApi = ({ map, maps }) => {
        this.map = map;
        this.maps = maps;
    }

    toggleMapFooter = selectedScooter => {
        const { dispatch } = this.props;

        if (selectedScooter) {
            this.defaultBusinessID = [...new Set([...this.defaultBusinessID, selectedScooter.service_id])];
            const el = (
                <MapFooter
                    selectedScooter={ selectedScooter }
                    onClose={ this.closeFooter }
                />
            );
            dispatch(showMapContent(el));
        }
        else {
            dispatch(showMapContent());
        }
    }

    closeFooter = () => {
        const { dispatch } = this.props;

        dispatch(showMapContent());
        dispatch(selectScooter([]));

        this.setState({
            selectedScooter: undefined,
        });
    }

    handleSelectScooter = ([currentScooterId]) => {
        const { dispatch } = this.props;
        const mergedList = this.getMergedScooterList();
        let currSelectedScooter = mergedList.filter(({ scooter_id }) => scooter_id === currentScooterId)[0];

        this.setState(() => {
            const { selectedScooter = {} } = this.state;
            const selectedScooterIdList = [];

            if (selectedScooter.scooter_id === currSelectedScooter?.scooter_id) {
                currSelectedScooter = undefined;
            }
            else {
                selectedScooterIdList.push(currSelectedScooter.scooter_id);
                this.map.setZoom(15);
                this.map.panTo({
                    lat: currSelectedScooter.location_lat,
                    lng: currSelectedScooter.location_lng,
                });
            };

            this.toggleMapFooter(currSelectedScooter);

            dispatch(selectScooter(selectedScooterIdList));
            return {
                selectedScooter: currSelectedScooter,
            };
        });
    }

    fetchNearbyScooters = () => {
        const { dispatch, currentGeoLocation } = this.props;
        let promise = Promise.resolve();

        if (currentGeoLocation) {
            const { coords } = currentGeoLocation;
            const { latitude, longitude } = coords;

            promise = dispatch(fetchNearbyScooters({
                lat: latitude,
                lng: longitude,
            })).then(({ data }) => {
                const { data_list = [] } = data;
                this.mapFitBounds(data_list.map(({ current_location = {} }) => ({
                    location_lat: current_location.lat,
                    location_lng: current_location.lng,
                })));
            }).catch(err => {
                dispatch(toggleErrorDialog(I18n.t('general_error')));
            });
        }

        return promise;
    }

    setToScooterPosition = (lat, lng) => {
        this.map.setCenter({
            lat,
            lng,
        });
        this.map.setZoom(15);
    }

    getOneScooterList = (params) => {
        const { oneTicket, singleScooter } = this.props;
        let result = [];
        if (singleScooter) {
            const { scooter_id, current_location, service_type, model_code } = singleScooter.toJS();
            const { lat, lng } = current_location;
            result = [{
                id: scooter_id,
                lat,
                lng,
                service_type,
                model_code,
                ...singleScooter.toJS(),
            }];
        }
        if (params['ticketId'] && oneTicket.size !== 0) {
            const {
                scooter_id,
                location_lat,
                location_lng,
                service_type,
                scooter_model_code,
            } = oneTicket.toJS();
            result = [{
                id: scooter_id,
                lat: location_lat,
                lng: location_lng,
                service_type,
                model_code: scooter_model_code,
                ...oneTicket.toJS(),
            }];
        }
        return result;
    };

    getMergedScooterList = () => {
        const { tasks, nearbyList } = this.props;
        const { data_list } = tasks.toJS();
        const uniqueScooterList = Object.values(data_list.reduce((scooterMap, scooter) => {
            const { scooter_id } = scooter;

            if (!scooterMap[scooter_id]) {
                scooterMap[scooter_id] = {
                    ...scooter,
                    id: scooter_id,
                };
            }

            return scooterMap;
        }, {}));
        // transform format as same as scooter list
        const transformedNearbyList = nearbyList.toJS().map(item => {
            const { plate, current_location, model_code } = item;

            return {
                ...item,
                plate_no: plate,
                scooter_model_code: model_code,
                location_lat: current_location.lat,
                location_lng: current_location.lng,
                light: true,
            };
        });
        return uniqueScooterList.concat(transformedNearbyList);
    }

    getUniqueScooterList = list => {
        const { selectedScooter = {} } = this.state;
        return list.reduce((arr, ticket) => {
            const { scooter_id } = ticket;

            if (!arr.some(item => item.scooter_id === scooter_id)) {
                arr.push(ticket);
            }

            return arr;
        }, []).map(({ scooter_id, location_lng, location_lat, scooter_model_code, ...rest }) => ({
            id: scooter_id,
            model_code: scooter_model_code,
            lat: location_lat,
            lng: location_lng,
            selected: selectedScooter.scooter_id === scooter_id,
            ...rest,
        }));
    }

    getTicketList = () => {
        const { params } = this.props;
        const overallList = this.getMergedScooterList();
        let ticketList = this.getUniqueScooterList(overallList);
        if (!checkRouteStatus(params)) {
            ticketList = this.getOneScooterList(params);
        };
        return ticketList;
    }

    render() {
        const { params, resetBounds, onTilesLoaded } = this.props;
        const myTicketTool = checkRouteStatus(params)
            && <ButtonFetchNearByScooter key="fetch-floating" onFetch={ this.fetchNearbyScooters } />;
        const toolboxList = getAuthedToolBoxList([...defaultToolbox, myTicketTool]);

        this.vipLayer = checkRouteStatus(params) ?
            VIP_SELECT : VIP_READ;

        const overallList = this.getMergedScooterList();

        const options_vip = this.getTicketList().length === 0
            || this.defaultBusinessID.length === 0 ?
            [GoShareID] : this.defaultBusinessID;

        const mapStyle = classNames({
            'map-view': true,
            'scooter-map-view': Object.keys(params).includes('scooterId')
        });

        return (
            <div className={ `ticket-system-map ${Object.keys(params).includes('scooterId') ? 'scooter-margin' : ''}` }>
                <GoogleMap
                    className={ mapStyle }
                    onScooterSelected={ checkRouteStatus(params) ? this.handleSelectScooter : () => {} }
                    onInternalAPI={ this.handleGoogleApi }
                    list={ this.getTicketList() }
                    resetBounds={ resetBounds }
                    toolbox={ toolboxList }
                    zoom={ overallList.length === 1 ? 15 : undefined }
                    currentLocation
                    withoutScooterCluster
                    vipLayer={ this.vipLayer }
                    business_ids={ options_vip }
                    myTask
                    onTilesLoaded={ onTilesLoaded }
                    isDetail
                />
            </div>
        );
    }
}

export default connect((state, ownProps) => ({
    tasks: state.scooter.get('tasks'),
    oneTicket: state.ticket.get('oneTicket'),
    singleScooter: state.scooter.get('singleScooter')[ownProps.params.scooterId],
    currentGeoLocation: state.geolocation.get('currentLocation'),
    nearbyList: state.scooter.get('nearbyList'),
    i18n: state.i18n,
    selectedVipLayerList: state.map.get('selectedVipLayerList'),
    selected: state.map.get('selectedIdMap'),
}))(TicketMap);
