const ListHeader = [{
    name: 'id',
    value: 'ticket.ticket_no',
    sortable: true,
    width: 80,
}, {
    name: 'plate_no',
    value: 'scooter.plate',
    sortable: true,
    width: 100,
}, {
    name: 'tracker_id',
    value: 'ticket.main_type',
    sortable: true,
    width: 110,
}, {
    name: 'tracker_subtype_id',
    value: 'ticket.sub_type',
    sortable: true,
    width: 120,
}, {
    name: 'status_id',
    value: 'ticket.status',
    sortable: true,
    width: 100,
}, {
    name: 'assigned_to_id',
    value: 'ticket.assignee',
    sortable: true,
    width: 330,
}, {
    name: 'severity_id',
    value: 'ticket.priority',
    sortable: true,
    width: 80,
}, {
    name: 'create_time',
    value: 'ticket.create_time',
    sortable: true,
    width: 110,
}, {
    name: 'update_time',
    value: 'ticket.update_time',
    sortable: true,
    width: 110,
}];

export default ListHeader;
