const ListHeader = [{
    name: 'title',
    value: 'mission.title',
    sortable: true,
    width: 200,
}, {
    name: 'status',
    value: 'mission.status',
    sortable: true,
    width: 100,
}, {
    name: 'criteria_type',
    value: 'mission.criteria_type',
    sortable: true,
    width: 110,
}, {
    name: 'publish_time',
    value: 'mission.publish_time',
    sortable: true,
    width: 110,
}, {
    name: 'valid_from',
    value: 'mission.valid_from',
    sortable: true,
    width: 110,
}, {
    name: 'valid_to',
    value: 'mission.valid_to',
    sortable: true,
    width: 110,
}];

export default ListHeader;
