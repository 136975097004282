import axios from 'axios';
import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import moment from 'moment';
import account from 'helpers/account';

const { REACT_APP_BASE_URL, REACT_APP_API_BASE_URL } = process.env;

const proxyWrapper = instance => (
    new Proxy(instance, {
        get: (target, name) => {
            const requestMethods = ['get', 'post', 'delete', 'patch', 'put'];
            const action = target[name];

            if (requestMethods.indexOf(name) > -1) {
                return (...props) => action.bind(target, ...props);
            }
            else {
                return action;
            }
        },
    })
);

export const createInstance = (basePath, headers = {}, config = {}) => {
    const instance = axios.create({
        headers,
        baseURL: basePath,
        withCredentials: true,
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        ...config,
    });
    instance.interceptors.response.use(response => {
        if (response.data) {
            response.data.__responseTime = moment();
        }

        return response;
    });

    return instance;
};

export const createFleetApiInstanceWithToken = (headers = {}) => {
    const realHeaders = {
        ...headers,
        'Authorization': getAPIkey(),
        'Content-Type': 'application/json',
    };

    return createFleetApiInstance(realHeaders);
};

export const createAmazonApiInstance = (basePath, headers = {}) => {
    const instance = createInstance(basePath, headers);
    instance.defaults.headers.common = {};

    return instance;
};

export const createFleetApiInstance = (headers = {}) => {
    const baseUrl = REACT_APP_API_BASE_URL;

    const xsrfToken = Cookies.get('XSRF-TOKEN');

    if (!xsrfToken) {
        resetXSRFToken();
    }

    const config = {
        xsrfCookieName: 'XSRF-TOKEN',
        xsrfHeaderName: 'X-XSRF-TOKEN',
    };

    const instance = createInstance(
        baseUrl,
        headers,
        config,
    );

    return proxyWrapper(instance);
};

const resetXSRFToken = () => {
    const domain = REACT_APP_BASE_URL;

    const xsrfToken = uuidv4();
    Cookies.set('XSRF-TOKEN', xsrfToken, { domain });
};

export const createOAuthInstance = () => {
    const baseUrl = `${REACT_APP_API_BASE_URL}/fleet_users/google_auth`;
    return createInstance(baseUrl);
};

export const getAPIkey = () => {
    const id_token = account.getID() || {};

    return `Bearer ${ id_token || '' }`;
};
