import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Card, CardBody, FormGroup, Label, Input } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import Datetime from 'react-datetime';
import EndDatetime from 'components/EndDatetime';
import ControlTemplate from './ControlTemplate';

export const TYPE_PERIOD = 'period';
export const TYPE_DURATION = 'duration';

const CustomDatetime = ({ ...props }) => (
    <Datetime
        dateFormat={ I18n.t('datetime_format.date') }
        timeFormat={ I18n.t('datetime_format.time') }
        closeOnSelect={ true }
        { ...props }
    />
);

class TimetableSection extends Component {
    static propTypes = {
        couponBody: PropTypes.shape({}),
        viewOnly: PropTypes.bool,
    };

    static defaultProps = {
        couponBody: {},
        viewOnly: false,
    };

    constructor(props) {
        super(props);
        const { couponBody } = props;
        const { valid_from, valid_to } = couponBody;

        this.dates = {};
        this.state = {
            validType: ((valid_from && valid_to) ||
                        (!couponBody.id && !valid_from && !valid_to) ? TYPE_PERIOD : TYPE_DURATION),
        };

        this.elValidFrom = React.createRef();
        this.elValidTo = React.createRef();
        this.elConvertFrom = React.createRef();
        this.elConvertTo = React.createRef();

    }

    componentDidMount() {
        const { couponBody } = this.props;
        const { valid_from, valid_to, convert_from, convert_to } = couponBody;

        this.dates = {
            valid_from: valid_from && moment(valid_from),
            valid_to: valid_to && moment(valid_to),
            convert_from: convert_from && moment(convert_from),
            convert_to: convert_to && moment(convert_to),
        };
    }

    handlevalidTypeChanged = e => {
        this.setState({
            validType: e.currentTarget.value,
        });
    }

    checkDateValidation = (el, datetime) => {
        const currTime = moment(datetime);
        const isValid = currTime.isValid();

        if (!isValid) {
            el.setCustomValidity(I18n.t('coupon.invalid_date'));
        }
        else {
            this.dates[el.name] = currTime;

            el.setCustomValidity('');
        }

        return isValid;
    }

    checkSequence = (fromTime, toTime) => {
        return (fromTime && toTime && toTime.diff(fromTime) > 0);
    }

    handleDatesChanged = ref => datetime => {
        this.checkDateValidation(ref.current, datetime);

        const { validType } = this.state;
        const { valid_from, valid_to, convert_from, convert_to } = this.dates;

        if (!this.checkSequence(convert_from, convert_to)) {
            this.elConvertTo.current.setCustomValidity(I18n.t('coupon.wrong_sequence_date'));
        }
        else {
            this.elConvertTo.current.setCustomValidity('');
        }

        if (validType === TYPE_PERIOD) {
            if (!this.checkSequence(valid_from, valid_to)) {
                this.elValidTo.current.setCustomValidity(I18n.t('coupon.wrong_sequence_date'));
            }
            else if (convert_to.diff(valid_to) > 0) {
                this.elConvertTo.current.setCustomValidity(I18n.t('coupon.wrong_redeem_dates'));
            }
            else if (convert_from.diff(valid_from) > 0) {
                this.elConvertFrom.current.setCustomValidity(I18n.t('coupon.wrong_redeem_dates'));
            }
            else {
                this.elConvertFrom.current.setCustomValidity('');
                this.elConvertTo.current.setCustomValidity('');
            }
        }

    }

    renderRedeemType() {
        const { viewOnly } = this.props;
        const { validType } = this.state;

        return (
            viewOnly ?
                (
                    <Col sm={ 3 }>
                        <Translate className="type-badge" value={ `coupon.redeem_type_${ validType }` } />
                    </Col>
                ) :
                (
                    <Col sm={ 3 }>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="valid_type"
                                    value={ TYPE_PERIOD }
                                    defaultChecked={ validType === TYPE_PERIOD }
                                    onChange={ this.handlevalidTypeChanged }
                                />
                                <Translate value="coupon.redeem_type_period" />
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="radio"
                                    name="valid_type"
                                    value={ TYPE_DURATION }
                                    defaultChecked={ validType === TYPE_DURATION }
                                    onChange={ this.handlevalidTypeChanged }
                                />
                                <Translate value="coupon.redeem_type_duration" />
                            </Label>
                        </FormGroup>
                    </Col>
                )
        );
    }

    render() {
        const { couponBody, viewOnly } = this.props;
        const { validType } = this.state;

        return (
            <>
                <Translate value="coupon.timetable" className="caption" tag="h2" />
                <Card className="coupon-timetable">
                    <CardBody>
                        <ControlTemplate large={ viewOnly } captionKey="redeem_from">
                            <CustomDatetime
                                inputProps={ {
                                    name: 'convert_from',
                                    required: true,
                                    autoComplete: 'off',
                                    ref: this.elConvertFrom,
                                } }
                                initialValue={
                                    couponBody.convert_from ?
                                        moment(couponBody.convert_from).format(I18n.t('datetime_format.long')) :
                                        undefined
                                }
                                onChange={ this.handleDatesChanged(this.elConvertFrom) }
                            />
                        </ControlTemplate>
                        <ControlTemplate large={ viewOnly } captionKey="redeem_to">
                            <EndDatetime
                                inputProps={ {
                                    name: 'convert_to',
                                    required: true,
                                    autoComplete: 'off',
                                    ref: this.elConvertTo,
                                } }
                                initialValue={
                                    couponBody.convert_to ?
                                        moment(couponBody.convert_to).format(I18n.t('datetime_format.long')) :
                                        undefined
                                }
                                initialViewDate={ couponBody.convert_to ? moment(couponBody.convert_to) : undefined }
                                onChange={ this.handleDatesChanged(this.elConvertTo) }
                            />
                        </ControlTemplate>
                        <FormGroup row>
                            <Label sm={ 2 }>
                                <Translate value="coupon.redeem_type" className="field required" />
                            </Label>
                            { this.renderRedeemType() }
                        </FormGroup>
                        {
                            validType === TYPE_PERIOD ?
                                (
                                    <>
                                        <ControlTemplate large={ viewOnly } captionKey="valid_from">
                                            <CustomDatetime
                                                inputProps={ {
                                                    name: 'valid_from',
                                                    required: true,
                                                    autoComplete: 'off',
                                                    ref: this.elValidFrom,
                                                } }
                                                initialValue={
                                                    couponBody.valid_from ?
                                                        moment(
                                                            couponBody.valid_from
                                                        ).format(I18n.t('datetime_format.long')) :
                                                        undefined
                                                }
                                                onChange={ this.handleDatesChanged(this.elValidFrom) }
                                            />
                                        </ControlTemplate>
                                        <ControlTemplate large={ viewOnly } captionKey="valid_to">
                                            <EndDatetime
                                                inputProps={ {
                                                    name: 'valid_to',
                                                    required: true,
                                                    autoComplete: 'off',
                                                    ref: this.elValidTo,
                                                } }
                                                initialValue={
                                                    couponBody.valid_to ?
                                                        moment(
                                                            couponBody.valid_to
                                                        ).format(I18n.t('datetime_format.long')) :
                                                        undefined
                                                }
                                                initialViewDate={ couponBody.valid_to ?
                                                    moment(couponBody.valid_to) : undefined }
                                                onChange={ this.handleDatesChanged(this.elValidTo) }
                                            />
                                        </ControlTemplate>
                                    </>
                                ) :
                                null
                        }
                        {
                            validType === TYPE_DURATION ?
                                (
                                    <ControlTemplate withUnit captionKey="valid_duration">
                                        <Input
                                            type="number"
                                            name="valid_duration"
                                            min="1"
                                            step="1"
                                            defaultValue={ couponBody.valid_duration }
                                            required
                                        />
                                        <Translate value="coupon.days" />
                                    </ControlTemplate>
                                ) :
                                null
                        }
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default TimetableSection;
