import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AuthView from 'components/AuthView';
import Profile from 'components/Scooter/Profile';
import {
    fetchScooterListByFinder, clearScooterList,
    fetchSingleScooter, clearSingleScooter,
    updateDocumentTitle
} from 'actions';
import SearchResultWrap from 'components/SearchResultWrap';
import { Input, Button, Form } from 'reactstrap';
import { Translate } from 'react-redux-i18n';

import './scooter-search.scss';

class ScooterSearch extends Component {
    static propTypes = {
        singleScooterMap: PropTypes.shape().isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            profile: undefined
        };
        this.plateInputEl = React.createRef();
    }

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { pathname } = location;

        dispatch(updateDocumentTitle(`routes.${ pathname }`));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearSingleScooter());
        dispatch(clearScooterList());
    }

    handleSubmit = e => {
        e.preventDefault();
        this.fetchScooterByPlate( this.plateInputEl.current.value );
    }

    fetchScooterByPlate = plate => {
        const { dispatch } = this.props;
        dispatch(
            fetchScooterListByFinder({ plate })
        ).then(action => {
            const scooter = action.data && action.data.data_list && action.data.data_list[0];
            const scooterId = scooter && scooter.scooter_id;
            if (scooterId) {
                dispatch(
                    fetchSingleScooter(scooterId)
                ).then(
                    ({ data }) => {
                        this.setState({
                            profile: data
                        });
                    }
                );
            }
            else {
                throw scooterId;
            }
        }).catch(
            () => dispatch(clearSingleScooter())
        );
    }

    render() {
        const { profile = {} } = this.state;
        const { singleScooterMap } = this.props;
        const currProfile = singleScooterMap[profile.scooter_id];


        return (
            <AuthView className="scooter-search">
                <Form className="filter" onSubmit={ this.handleSubmit }>
                    <Input className="search-text" type="text" innerRef={ this.plateInputEl } />
                    <Button type="submit">
                        <Translate value="search" />
                    </Button>
                </Form>
                <SearchResultWrap show={ currProfile }>
                    <Profile item={ currProfile && currProfile.toJS() } />
                </SearchResultWrap>
            </AuthView>
        );
    }
}

export default connect(state => ({
    singleScooterMap: state.scooter.get('singleScooter'),
    history: state.scooter.get('history'),
    i18n: state.i18n,
}))(ScooterSearch);
