import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-redux-i18n';
import CardTemplate from 'components/CardTemplate';
import CustomInput from 'components/CustomInput';
import { TYPE_DATETIME } from 'components/Form/Input';
import RentalPhotoAttachment from 'components/RentalPhotoAttachment';
import {
    ID_SCAN_IMAGE,
    DRIVER_SCAN_IMAGE_FRONT,
    DRIVER_SCAN_IMAGE_BACK,
} from 'constants/customer';
import { useWaterMarkImgByDataString } from 'hooks/useWaterMarkImg';

const DetailsSection = ({ data }) => {
    const [detail, setDetail] = useState(data);

    const { img: idImage } = useWaterMarkImgByDataString(detail?.id_scan_image_encoded, {
        imgType: detail?.id_scan_image_type,
        docType: ID_SCAN_IMAGE,
        isViolation: true,
    });

    const { img: dlImage } = useWaterMarkImgByDataString(detail?.license_scan_image_encoded, {
        imgType: detail?.license_scan_image_type,
        docType: DRIVER_SCAN_IMAGE_FRONT,
        isViolation: true,
    });

    const { img: dlImageBack } = useWaterMarkImgByDataString(detail?.license_back_scan_image_encoded, {
        imgType: detail?.license_back_scan_image_type,
        docType: DRIVER_SCAN_IMAGE_BACK,
        isViolation: true,
    });

    useEffect(() => {
        if (data && data?.customer_id) {
            setDetail(data);
        }
    }, [data]);


    return (
        <section className="cards">
            <div className="column">
                <CardTemplate title={ I18n.t('violation.violate_detail') }>
                    <div className="grid-template">
                        <CustomInput
                            caption="scooter.plate"
                            value={ detail?.plate_no }
                        />
                        <CustomInput
                            type={ TYPE_DATETIME }
                            caption="start_time"
                            value={ detail?.start_time || '' }
                        />
                        <CustomInput
                            type={ TYPE_DATETIME }
                            caption="end_time"
                            value={ detail?.end_time || '' }
                        />
                        <CustomInput
                            caption="violation.violator"
                            value=" "
                        />
                        <CustomInput
                            caption="customer.mobile"
                            value={ detail?.phone }
                        />
                        <CustomInput
                            caption="customer.email"
                            value={ detail?.email }
                        />
                        <div className="linkToHide">
                            <CustomInput
                                caption="rental.photo"
                                value={ detail?.return_photo_url ? (
                                    <RentalPhotoAttachment url={ detail?.return_photo_url } />
                                ) : undefined  }
                            />
                        </div>
                    </div>
                </CardTemplate>
                <CardTemplate title={ I18n.t('violation.id') }>
                    <div className="grid-template">
                        <div>
                            <Translate value="customer.new_id" tag="div" />
                            <img className="image" src={ idImage } alt="" />
                        </div>
                        <div className="second-image">
                            <Translate value="customer.driving_license" tag="div" />
                            <img src={ dlImage } alt="" />
                        </div>
                        <div className="third-image">
                            <Translate value="customer.driving_license_back" tag="div" />
                            <img className="image" src={ dlImageBack } alt="" />
                        </div>
                    </div>
                </CardTemplate>
            </div>
        </section>
    );
};

DetailsSection.propTypes = {
    data: PropTypes.shape({}),
};

DetailsSection.defaultProps = {
    data: {},
};

export default DetailsSection;



