import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { NavLink } from 'react-router-dom';
import Datetime from 'components/Datetime';
import Cell from 'components/ListView/Cell';
import buildActualPath from 'helpers/build-actual-path';
import { PROMOTION_DETAIL } from 'constants/routes';

export default function PromotionListRow({ columns, rowData }) {
    return columns.map(({ key, width }) => (
        <Field key={ key } data={ rowData } width={ width } name={ key } />
    ));
}

function Field({ data, name, width }) {
    let element;

    switch (name) {
    case 'type':
        element = <Translate value={ `promotion.type_${ data[name] }` } />;
        break;
    case 'status':
        element = <Translate value={ `promotion.status_${ data[name] }` } />;
        break;
    case 'schedule_time':
        element = <Datetime time={ data[name] } />;
        break;
    case 'title':
        element = (
            <NavLink to={ buildActualPath(PROMOTION_DETAIL, { promotionId: data['id'] }) }>
                { data[name] }
            </NavLink>
        );
        break;
    default:
        element = data[name];
        break;
    }

    return (
        <Cell key={ `${ data.id }-${ name }` } name={ name } style={ { width } }>
            { element }
        </Cell>
    );
}

Field.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        schedule_time: PropTypes.string.isRequired,
        status: PropTypes.number.isRequired,
        type: PropTypes.number.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
};