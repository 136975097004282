import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input } from 'reactstrap';
import { SORT_ORDER } from './constants';
import Cell from './Cell';

class ListHeader extends React.Component {
    static propTypes = {
        onSort: PropTypes.func,
        onSelectAll: PropTypes.func,
        items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        selectable: PropTypes.bool,
        selected: PropTypes.bool,
    };

    static defaultProps = {
        onSort: undefined,
        onSelectAll: undefined,
        selectable: false,
        selected: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            sort: {},
        };
    };

    handleSort = ({ field, order }) => e => {
        e.stopPropagation();

        const { onSort } = this.props;

        this.setState({
            sort: { [field]: order },
        });
        onSort({ field, order });
    };

    handleSelectAll = () => {
        const { selected, onSelectAll } = this.props;

        onSelectAll(!selected);
    }

    render() {
        const { items, selectable, selected } = this.props;
        const visibleHeader = items.filter((field) => !field.hide);

        const nextSort = field => {
            const { sort } = this.state;
            let nextOrder = SORT_ORDER.ASC;

            switch (sort[field]) {
            case SORT_ORDER.ASC:
                nextOrder = SORT_ORDER.DESC;
                break;
            case SORT_ORDER.DESC:
                nextOrder = undefined;
                break;
            default:
            }

            let nextSort = {};

            if (nextOrder) {
                nextSort = { field, order: nextOrder };
            };

            return nextSort;
        };

        return  (
            <React.Fragment>
                {
                    selectable ? (
                        <Cell name="checkbox" onClick={ this.handleSelectAll }>
                            <Input type="checkbox" checked={ selected } onChange={ () => {} } />
                        </Cell>
                    ) : null
                }
                {
                    visibleHeader.map(( { key, title, sortable, width }, i) => {
                        const { sort } = this.state;
                        const order = sort[key];
                        const thClassName = classNames({
                            sortable,
                        });
                        const sortClassName = classNames({
                            'sorting': true,
                            'asc': order === SORT_ORDER.ASC,
                            'desc': order === SORT_ORDER.DESC,
                        });

                        return (
                            <Cell
                                key={ key }
                                name={ key }
                                className={ thClassName }
                                onClick={ sortable ? this.handleSort(nextSort(key)) : null }
                                style={ { width } }
                            >
                                <span>{ title }</span>
                                { sortable ? <span className={ sortClassName } /> : null }
                            </Cell>
                        );
                    })
                }
            </React.Fragment>
        );
    };
};

export default ListHeader;
