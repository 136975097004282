/**
 * Action - Ticket
 */
import api from 'api';

import {
    FETCH_TICKET_LIST,
    FETCH_ONE_TICKET,
    CREATE_TICKETS,
    CLEAN_ONE_TICKET,
    UPDATE_ONE_TICKET,
    UPDATE_MANY_TICKET,
    FETCH_ASSIGNMENT,
    INIT_ASSIGNMENTS,
    FETCH_TICKET_FLOW_LIST
} from 'constants/action-type';

import { asyncInterface } from './util';

export const fetchTicketList = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchTicketList(params),
        type: FETCH_TICKET_LIST,
        dispatch,
    });
};

export const clearTicketList = () => {
    return {
        type: FETCH_TICKET_LIST,
        data: {}
    };
};

export const fetchOneTicket = id => dispatch => {
    const fetchData = () => Promise.all([
        api.fetchOneTicket(id)(),
        api.fetchJournal(id)(),
    ]).then(([ oneTicket, journal ]) => {
        const oneTicketData = {
            ...oneTicket.data,
            journal: journal.data,
        };

        return {
            data: oneTicketData,
        };
    });
    return asyncInterface({
        asyncAction: fetchData,
        type: FETCH_ONE_TICKET,
        dispatch,
    });
};

export const createTicket = (payload = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.createTicket(payload),
        type: CREATE_TICKETS,
        dispatch,
    });
};

export const cleanOneTicket = () => {
    return {
        type: CLEAN_ONE_TICKET
    };
};

export const updateOneTicket = (id, payload = {}) => dispatch => {
    const request = () => {
        return api.updateOneTicket(id, payload)()
            .then(({ data }) => {
                if (data.scooter_status_action_result === 0) {
                    dispatch({
                        type: UPDATE_ONE_TICKET,
                        data
                    });

                    throw new Error('ticket.unclosed_tickets_error_message');
                }

                return api.fetchJournal(id)().then(journal => ({
                    ...data,
                    journal: journal.data,
                }));
            })
            .then(data => ({
                data,
            }));
    };

    return asyncInterface({
        asyncAction: request,
        type: UPDATE_ONE_TICKET,
        dispatch,
    });
};

export const updateManyTickets = (payload = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.updateManyTickets(payload),
        type: UPDATE_MANY_TICKET,
        dispatch,
    });
};

export const fetchAttatchmentUrl = ext => {
    return api.fetchAttatchmentUrl(ext);
};

export const fetchAssignment = params => dispatch => {
    const request = () => {
        return api.fetchAssignment(params)().then(response => {
            response.data.data_list.forEach(({ tickets }) => {
                tickets.forEach(ticket => {
                    if (typeof ticket.location_lat !== 'number') {
                        ticket.location_lat = 0;
                    }

                    if (typeof ticket.location_lng !== 'number') {
                        ticket.location_lng = 0;
                    }
                });
            });

            return response;
        });
    };
    return asyncInterface({
        asyncAction: request,
        type: FETCH_ASSIGNMENT,
        dispatch
    });
};

export const initAssignments = () => {
    return {
        type: INIT_ASSIGNMENTS
    };
};

export const fetchTicketFlowList = () => (dispatch, getState) => {
    const myFranchiseeId = getState().account.getIn(['permission', 'franchiseeId']);
    const request = () => {
        return api.fetchFranchiseeList()().then(({ data: franchiseeList }) => {
            const data = {
                franchiseeList,
                myFranchiseeId
            };

            return { data };
        }).catch(() => ({ data: {
            franchiseeList: [],
            myFranchiseeId: '',
        } }));
    };

    return asyncInterface({
        asyncAction: request,
        type: FETCH_TICKET_FLOW_LIST,
        dispatch
    });
};
