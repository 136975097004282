import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class MapFooter extends React.Component {
    static propTypes = {
        content: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
    }

    static defaultProps = {
        content: undefined,
    };

    render() {
        const { content } = this.props;

        return (
            <footer className="map-footer">
                { content }
            </footer>
        );
    }
}

export default connect(state => ({
    content: state.map.get('footerContent'),
}))(MapFooter);
