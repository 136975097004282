import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import mobile from 'is-mobile';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Translate } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import AuthFeature from 'components/AuthFeature';
import Smart from 'components/Smart';
import ListView from 'components/ListView';
import FlexGroup from 'components/FlexGroup';
import Pagination, { Refresh, TotalCount } from 'components/Pagination';
import PageSizeSelect from 'components/Pagination/PageSizeSelect';
import BatchEdit from './BatchEdit';
import headerConfig from './List/header';
import ListRow from './List/Row';
import TotalSelection from 'components/TotalSelection';
import Period from 'components/Period';
import permissionHandler from 'helpers/permission-handler';
import {
    AUTH_BATCH_EDIT_TICKET,
} from 'constants/permission';
import { filterCorp } from 'helpers/corp-warning';

export const FILTER_PAGE_INDEX = 'page';
export const FILTER_PAGE_SIZE = 'size';
export const FILTER_SORT = 'sort';

class TotalList extends Component {

    static propTypes = {
        list: PropTypes.shape({
            page_index: PropTypes.number,
            page_count: PropTypes.number,
            data_list: ImmutablePropTypes.list,
        }).isRequired,
        pageSize: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.arrayOf(PropTypes.number),
        ]).isRequired,
        onFetch: PropTypes.func.isRequired,
        onSelect: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        selectedIdList: PropTypes.arrayOf(PropTypes.number).isRequired,
        fromTime: PropTypes.oneOfType([
            PropTypes.instanceOf(moment),
            PropTypes.string,
        ]),
        toTime: PropTypes.oneOfType([
            PropTypes.instanceOf(moment),
            PropTypes.string,
        ]),
    };

    static defaultProps = {
        fromTime: undefined,
        toTime: undefined,
    };

    constructor(props) {
        super(props);

        this.state = {
            showBatchEditPanel: false,
            corpName: [],
        };

    }

    handleSubmit = () => {
        const { onFetch, onSelect } = this.props;
        onFetch().then(() => {
            this.toggleBatchEditor();
            onSelect([]);
        });
    }

    toggleBatchEditor = () => {
        const { showBatchEditPanel } = this.state;
        this.setState({
            showBatchEditPanel: !showBatchEditPanel,
        });
    }

    handleSelectTicket = (ids, selectAll) => {
        const { list, selectedIdList, onSelect } = this.props;
        const { data_list } = list.toJS();
        let updatedScooterList = [...selectedIdList];

        if (selectAll) {
            updatedScooterList = ids.length === data_list.length ? ids : [];
        }
        else {
            ids.forEach(id => {
                if (updatedScooterList.indexOf(id) === -1) {
                    updatedScooterList.push(id);
                }
                else {
                    const idx = updatedScooterList.indexOf(id);
                    updatedScooterList.splice(idx, 1);
                }
            });
        }

        const corporates = filterCorp({
            dataList: data_list,
            updatedDataList: updatedScooterList,
            key: 'id'
        });
        this.setState({ corpName: [...new Set(corporates)] });

        onSelect(updatedScooterList);
    }

    render() {
        const { showBatchEditPanel, corpName } = this.state;
        const { onFetch, onChange, selectedIdList, list, pageSize, fromTime, toTime } = this.props;
        const { page_index, page_count, data_list, total, __responseTime } = list.toJS();
        const batchEditClass = classNames({
            'btn-batch-edit': true,
        });
        const ListItem = ({ columns, rowData }) => (
            <ListRow
                key={ `ticket-${ rowData.id }` }
                columns={ columns }
                data={ rowData }
            />
        );
        return (
            <React.Fragment>
                <FlexGroup>
                    <FlexGroup start gap>
                        <AuthFeature requiredList={ [AUTH_BATCH_EDIT_TICKET] }>
                            <Button
                                outline
                                color="primary"
                                className={ batchEditClass }
                                onClick={ this.toggleBatchEditor }
                                disabled={ selectedIdList.length === 0 }
                            >
                                <Translate value="ticket.batch_edit" />
                            </Button>
                        </AuthFeature>
                        <Pagination
                            page={ page_index }
                            total={ page_count }
                            offset={ pageSize }
                            onSelect={ onChange(FILTER_PAGE_INDEX) }
                        />
                        <PageSizeSelect
                            onChange={ onChange(FILTER_PAGE_SIZE) }
                        />
                        <TotalCount count={ total } />
                        <TotalSelection count={ selectedIdList.length } />
                        <Period fromTime={ fromTime } toTime={ toTime } />
                    </FlexGroup>
                    <FlexGroup end>
                        <Refresh time={ __responseTime } onClick={ onFetch } />
                    </FlexGroup>
                </FlexGroup>
                <Smart
                    fetch={ onFetch }
                    refreshTime={ 60 }
                    className="main-content-container"
                    seamless
                >
                    <section className="ticket-list-table">
                        <ListView
                            selectable={ permissionHandler({ requiredList: [AUTH_BATCH_EDIT_TICKET] }) }
                            header={ headerConfig }
                            defaultSelect={ selectedIdList }
                            onSort={ onChange(FILTER_SORT) }
                            onSelect={ this.handleSelectTicket }
                            list={ data_list }
                            renderListRow={ ListItem }
                            dataKey="id"
                            fixed={ mobile() }
                        />
                    </section>
                </Smart>
                {
                    showBatchEditPanel ?
                        (
                            <BatchEdit
                                ticketIdList={ selectedIdList }
                                onCancel={ this.toggleBatchEditor }
                                onSubmit={ this.handleSubmit }
                                corporate={ corpName }
                            />
                        ) :
                        null
                }
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    list: state.ticket.get('list'),
}))(TotalList);
