import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PageItem = ({ className, page, children, onClick }) => {
    const customClass = classNames({
        'page-item': true,
        [className]: className,
    });

    return (
        <li key={ `page-${page}` } className={ customClass } onClick={ onClick }>
            { children }
        </li>
    );
};

PageItem.propTypes = {
    className: PropTypes.string,
    page: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    onClick: PropTypes.func
};

PageItem.defaultProps = {
    className: '',
    page: 0,
    children: undefined,
    onClick: () => {},
};

export default PageItem;
