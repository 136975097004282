import { createFleetApiInstance, getAPIkey } from '../util';

const paymentrequest = {
    fetchPaymentRequestList: (params = {}) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get('/expenses', { params });
    },
    fetchPaymentRequestDetail: id => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get(`/expenses/${id}`);
    },
    fetchPaymentRentalHistory: id => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get(`/expenses/hist_rentals/${id}`);
    },
    newPaymentRequest: (params = {}) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).post('/expenses', params);
    },
    cancelPaymentRequest: (id) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).patch(`/expenses/${ id }`);
    },
    paymentRefund: id => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).post(`/expenses/${ id }/refund`);
    },
};

export default paymentrequest;
