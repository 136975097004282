import {
    RENTAL_PAYMENT_METHOD_CREDIT_CARD,
    RENTAL_PAYMENT_METHOD_LINE_PAY,
    RENTAL_PAYMENT_METHOD_APPLE_PAY,
} from 'constants/rental';
import PropTypes from 'prop-types';

const labels = {
    [RENTAL_PAYMENT_METHOD_CREDIT_CARD]: 'Credit Card',
    [RENTAL_PAYMENT_METHOD_LINE_PAY]: 'LINE Pay',
    [RENTAL_PAYMENT_METHOD_APPLE_PAY]: 'Apple Pay'
};

export default function PaymentMethod({ status }) {
    switch (status) {
    case RENTAL_PAYMENT_METHOD_CREDIT_CARD:
        return labels[RENTAL_PAYMENT_METHOD_CREDIT_CARD];
    case RENTAL_PAYMENT_METHOD_LINE_PAY:
        return labels[RENTAL_PAYMENT_METHOD_LINE_PAY];
    case RENTAL_PAYMENT_METHOD_APPLE_PAY:
        return labels[RENTAL_PAYMENT_METHOD_APPLE_PAY];
    default:
        return 'N/A';
    }
}

PaymentMethod.propTypes = {
    status: PropTypes.number.isRequired
};
