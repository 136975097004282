import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { List, } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { fetchVMS, clearVMS, showMapContent } from 'actions';
import { ToolButton } from './index';

class VMS extends Component {
    static propTypes = {
        locale: PropTypes.string.isRequired,
        vms: ImmutablePropTypes.list,
    };

    static defaultProps = {
        vms: List([])
    };

    constructor(props) {
        super(props);

        this.state = {
            enabled: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { vms } = this.props;
        if (!prevProps.vms.equals(vms) && vms.toJS().length === 0) {
            this.setState({
                enabled: false,
            });
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearVMS());
    }

    handldFetchVMS = () => {
        const { dispatch, locale } = this.props;
        const { enabled } = this.state;
        let promise = Promise.resolve({});

        if (enabled) {
            dispatch(clearVMS());
            dispatch(showMapContent());
        }
        else {
            promise = dispatch(fetchVMS(locale));
        }

        promise = promise.then(({ type }) => {
            if (type !== 'ERROR') {
                this.setState({
                    enabled: !enabled,
                });
            }
        });

        return promise;
    }

    render() {
        const { enabled } = this.state;
        const buttonIconStyle = classNames({
            'btn-vms': true,
            'enabled': enabled,
        });

        return (
            <ToolButton className={ buttonIconStyle } onClick={ this.handldFetchVMS }>
                <div />
            </ToolButton>
        );
    }
}

export default connect(state => ({
    locale: state.i18n.locale,
    vms: state.map.get('vms')
}))(VMS);
