import { createFleetApiInstance, getAPIkey } from '../util';

const customer = {
    fetchCustomers: (params = {}) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get('/customers', { params });
    },

    fetchCustomersByFinder: (params = {}) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get('/customers/finder', { params });
    },

    fetchCustomerProfile: (customerId, params = {}) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get(`/customers/${ customerId }`, { params });
    },

    updateCustomerProfile: (customerId, params) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).patch(`/customers/${ customerId }`, params);
    },

    fetchCustomerRentalHistory: (params = {}) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get('/hist_rentals', { params });
    },

    fetchCustomerCoupon: (customerId, params) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get(`/customers/${ customerId }/coupons`, { params });
    },

    fetchCustomerDocument: (params = {}) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get('/customers/doc_images', { params })();
    },

    fetchCompensationCoupon: () => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get('/customers/coupons');
    },

    updateCustomerCoupon: (customerId, payload) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).post(`/customers/${ customerId }/coupons`, payload);
    },

    fetchCustomerMission: (id, params = {}) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get(`/customers/${ id }/missions`, { params });
    },

    fetchCustomerPurchaseHistory: (id, params = {}) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get(`/customers/${ id }/gov_addi_purchases`, { params });
    },

    updatePurchaseRefund: (id, params) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).patch(`/customers/${ id }/gov_addi_purchases`, params);
    },

    fetchPaymentRequestHistory: (id, params = {}) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get(`/customers/${ id }/expenses`, { params });
    },

    fetchGSHPassHistory: (id, params = {}) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).get(`/customers/${ id }/travel_passes`, { params });
    },

    updateGSHPassRefund: (id, params) => {
        return createFleetApiInstance({
            'Authorization': getAPIkey(),
        }).patch(`/customers/${ id }/travel_passes`, params);
    },
};

export default customer;
