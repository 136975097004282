import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoCard from 'components/InfoCard';
import FlexGroup from 'components/FlexGroup';
import { Translate, I18n } from 'react-redux-i18n';
import BarChart from 'components/Chart/BarChart';
import {
    TICKETS_CATEGORY_BY_ORDER,
} from 'constants/dashboard';
import {
    getTicketCounts,
    showTicketStatusWarning,
    showTicketCountsWarningByCategory,
} from 'helpers/dashboard';
import {
    SERVICE_TYPE_DOTS,
    CITY_OTHERS,
} from 'constants/scooter';

import './overall-tickets-status.scss';


const BAR_CHART_WIDTH = 1006;
const BAR_CHART_HEIGHT = 335;

const BAR_CHART_LAYOUT_CONFIG = {
    margin: {
        l: 0,
        r: 0,
        b: 54,
        t: 13,
    },
};

class  OverallTickets extends React.Component {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({})),
        title: PropTypes.string,
        className: PropTypes.string,
        listCaption: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.string,
        ]),
    };

    static defaultProps = {
        data: [],
        title: '',
        className: '',
        listCaption: '',
    };


    getListCaption = item => {
        const { listCaption } = this.props;
        const caption = ( listCaption && typeof listCaption === 'function') ? listCaption(item) : listCaption;
        return I18n.t(caption);
    };

    getChartXValue = ( list ) => {
        let xArray = [];
        xArray = list && list.map(item => this.getListCaption(item));
        return xArray;
    }

    getDataWithoutDots = () => {
        const { data } = this.props;
        return data.filter( item => item.service_type !== SERVICE_TYPE_DOTS);
    }

    renderListCityHeader = () => {
        const dataWithoutDots = this.getDataWithoutDots();

        return (
            <FlexGroup tag="li" className="list-row header">
                <Translate className="caption" value="scooter.city" />
                {
                    dataWithoutDots.map(item => (
                        <span
                            className="caption"
                            key={ `${ item.service_level }${ item.city_id }` }
                        >
                            { this.getListCaption(item) }
                        </span>)
                    )
                }
            </FlexGroup>
        );
    };

    renderTicketCountField = (category) => {
        const dataWithoutDots = this.getDataWithoutDots();

        return (
            <>
                {
                    dataWithoutDots.map( item => {
                        const counts = getTicketCounts(category, item);
                        const style = classNames({
                            'counts': true,
                            'warning': item.city_id !== CITY_OTHERS ?
                                showTicketCountsWarningByCategory(category, counts, item)
                                : false,
                        });
                        return (
                            <span
                                className={ style }
                                key={ `${ item.service_level }${ item.city_id }` }
                            >
                                { counts }
                            </span>);
                    })
                }
            </>
        );
    };

    renderTicketList = () => {
        return (
            <>
                {
                    TICKETS_CATEGORY_BY_ORDER.map( category => {
                        const style = category.style.substr(1);
                        return (
                            <FlexGroup tag="li" className="list-row" key={ category.id }>
                                <Translate
                                    className={ `caption ticket-${ style }` }
                                    value={ `dashboard.tickets_category_${category.id}` }
                                    key={ category.id }
                                />
                                { this.renderTicketCountField(category.id) }
                            </FlexGroup>
                        );
                    })
                }
            </>
        );
    };

    formatBarChartData = () => {
        const { data } = this.props;
        const dataOnlyFreeFloating = data.filter(item => item.city_id > -1 && item.city_id !== CITY_OTHERS);
        const xValue = this.getChartXValue( dataOnlyFreeFloating );
        return TICKETS_CATEGORY_BY_ORDER.map( category => {
            const yValue = dataOnlyFreeFloating.map(item => getTicketCounts(category.id, item));
            const name = I18n.t(`dashboard.tickets_category_${category.id}`);
            return {
                xValue,
                yValue,
                color: category.style,
                threshold: category.threshold,
                dataItem: dataOnlyFreeFloating,
                name,
            };
        });
    }

    renderBarChartLabel = (value, threshold, xItem) => {

        return showTicketStatusWarning(value, threshold, xItem) ?
            `<span style="font-size: 14px;fill: #ff1923;">${value}</span>` :
            `<span style="font-size: 14px !important;">${value}</span>`;
    }

    render() {
        const { title, className } = this.props;
        const barData = this.formatBarChartData();
        const CardStyle = classNames({
            'overall-tickets': true,
            [className]: true,
        });

        return (
            <InfoCard className={ CardStyle } title={ title }>
                <div className="chart">
                    <BarChart
                        barData={ barData }
                        layoutConfig={ BAR_CHART_LAYOUT_CONFIG }
                        renderLabel={ this.renderBarChartLabel }
                        autoResize={ true }
                        height={ BAR_CHART_HEIGHT }
                        width={ BAR_CHART_WIDTH }
                    />
                </div>
                <FlexGroup
                    tag="ul"
                    className="list"
                >
                    { this.renderListCityHeader() }
                    { this.renderTicketList() }
                </FlexGroup>
            </InfoCard>
        );
    }

};

export default OverallTickets;
