import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AuthView from 'components/AuthView';
import {
    updateDocumentTitle,
    clearViolationList,
} from 'actions';
import List from './List';
import './violation.scss';

class Violation extends Component {
    static propTypes = {
        list: PropTypes.arrayOf(PropTypes.object).isRequired,
        noFoundList: PropTypes.arrayOf(PropTypes.object),
    };

    static defaultProps = {
        noFoundList: [],
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(updateDocumentTitle('violation.traffic_violation'));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearViolationList());
    }

    render() {
        const { list, noFoundList } = this.props;
        return (
            <AuthView className="violation-list">
                <List list={ [...list, ...noFoundList] } />
            </AuthView>
        );
    }
}

export default connect(state => {
    return ({
        i18n: state.i18n,
        list: state.violation.get('list').toJS(),
        noFoundList: state.violation.get('noFoundList').toJS(),
    });
})(Violation);
