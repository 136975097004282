/*
 * build a actual path
 */
const buildActualPath = (route = '', params = {}) => {
    const keys = Object.keys(params);

    return keys.reduce((path, key) => {
        path = (path || route).replace(`:${ key }`, params[key]);

        return path;
    }, '') || route;
};

export default buildActualPath;
