const ListHeader = [{
    name: 'plate_no',
    value: 'scooter.plate',
    width: 60,
}, {
    name: 'violate_time',
    value: 'violation.violate_time',
    width: 100,
}, {
    name: 'rental_id',
    value: 'log.rental_id',
    width: 120,
}, {
    name: 'customer_id',
    value: 'customer.customer_no',
    width: 120,
}, {
    name: 'full_name',
    value: 'violation.violator',
    width: 80,
}, {
    name: 'email',
    value: 'customer.email',
    width: 120,
}, {
    name: 'phone',
    value: 'customer.mobile',
    width: 90,
}, {
    name: 'start_time',
    value: 'start_time',
    width: 100,
}, {
    name: 'end_time',
    value: 'end_time',
    width: 100,
}, {
    name: 'violate_in_rental',
    value: 'violation.violate_in_rental',
    width: 60,
}, {
    name: 'detail',
    value: 'violation.violate_detail',
    width: 80,
}];

export default ListHeader;
