import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import serialize from 'form-serialize';
import Form from 'components/Form';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import FlexGroup from 'components/FlexGroup';
import WarningModal from 'components/WarningModal';
import { convertToUTC } from 'helpers/time-handler';
import permissionHandler from 'helpers/permission-handler';
import {
    TYPE_TEXT,
    TYPE_SELECT,
    TYPE_TEXTAREA,
    TYPE_CHECKBOX,
    TYPE_RADIO,
    TYPE_DATETIME,
    TYPE_SEARCH_DROPDOWNLIST,
    TYPE_FILE,
} from 'components/Form/Input/input-type';
import {
    MAIN_TYPE_DISPATCH,
    MAIN_TYPE_MAINTENANCE,
    SEVERITY_MEDIUM,
    GROUP_SEVERITY,
    MAIN_TYPE_SWAP,
    MAIN_TYPE_GROUP,
    SUB_TYPE_GROUP,
    CUSTOM_FIELD_DESTINATION,
    CUSTOM_FIELD_DMS_NO,
    CUSTOM_FIELD_MEGA_CENTRE
} from 'constants/ticket';
import { SCOOTER_OFFLINE } from 'constants/scooter';
import {
    AUTH_DO_SCOOTER_OFFLINE,
    AUTH_VIEW_RESTRICT_TICKET_ASSIGNEE,
} from 'constants/permission';

const ACCEPTED_FILE_TYPE = '.csv';

class SecondStep extends React.Component {
    static propTypes = {
        virtualUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        opUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        scooterIdList: PropTypes.arrayOf(PropTypes.string).isRequired,
        show: PropTypes.bool,
        mainType: PropTypes.oneOf(MAIN_TYPE_GROUP),
        onCancelled: PropTypes.func,
        onSubmit: PropTypes.func,
        isConditional: PropTypes.bool,
        corporate: PropTypes.arrayOf(PropTypes.string),
        inProgress: PropTypes.bool,
    };

    static defaultProps = {
        mainType: MAIN_TYPE_SWAP,
        show: true,
        onCancelled: () => {},
        onSubmit: () => {},
        isConditional: false,
        corporate: [],
        inProgress: false,
    };

    constructor(props) {
        super(props);
        this.elFile = React.createRef();
        this.elForm = React.createRef();
        this.state = {
            plate_no: [],
            conditional_file: undefined,
            activation: [SCOOTER_OFFLINE],
            severity_id: SEVERITY_MEDIUM,
            due_date: undefined,
            destination: undefined,
            dms_no: undefined,
            mega_centre: undefined,
            warningOpen: false,
            warningFormData: {},
        };
    }


    handleCustomFields = formData => {
        const { mainType } = this.props;
        const custom_fields = [];
        if (mainType === MAIN_TYPE_DISPATCH) {
            custom_fields.push({
                id: CUSTOM_FIELD_DESTINATION,
                value: formData.destination || ''
            });
        }

        if (mainType === MAIN_TYPE_MAINTENANCE) {
            custom_fields.push({
                id: CUSTOM_FIELD_DMS_NO,
                value: formData.dms_no || ''
            });
            custom_fields.push({
                id: CUSTOM_FIELD_MEGA_CENTRE,
                value: formData.mega_centre || ''
            });
        }

        delete formData.destination;
        delete formData.dms_no;
        delete formData.mega_centre;
        formData.custom_fields = custom_fields;
    }

    handleOfflineScooterList = (activation, scooterIdList) => {
        if (activation === '0') {
            return {
                s: SCOOTER_OFFLINE,
                ids: [...scooterIdList],
            };
        }

        return {};
    }

    formatFileString = (fileString) => {
        const rows = fileString.split('\n');
        return rows.map(str => (
            str.split(',')[0].trim().toUpperCase()
        )).filter(item => (
            item !== ''
        ));
    }

    changeConditionalFile = e => {
        const file = e.currentTarget.files[0];
        const reader = new FileReader();
        if (file) {
            reader.onload = () => {
                this.setState({
                    plate_no: this.formatFileString(reader.result),
                    conditional_file: file.name,
                }, () => {
                    this.elFile.current.setCustomErrorMessage('');
                });

            };
            reader.readAsText(file);
        }


    }

    handleChange = name => value => {
        let newState = {};
        switch (name) {
        case 'conditional_file':
            this.changeConditionalFile(value);
            break;
        case 'severity_id':
            newState[name] = value[0];
            break;
        case 'due_date':
        case 'activation':
            newState[name] = value;
            break;
        default:
            let e = value;
            newState[name] = e.target.value;
        }
        this.setState(newState);
    }

    handleSubmit = e => {
        const {
            plate_no,
            description,
            warningFormData,
        } = this.state;
        const {
            scooterIdList,
            onSubmit,
            mainType,
            virtualUsers,
            isConditional,
            corporate
        } = this.props;
        let formData = corporate.length > 1 ? warningFormData : serialize(e.currentTarget, { hash: true });
        const { activation, severity_id, tracker_subtype_id, due_date, assigned_to_id } = formData;
        const doOfflineScooterList = isConditional ? {} : this.handleOfflineScooterList(activation, scooterIdList);
        if (isConditional && !plate_no.length) {

            this.setState( {
                conditional_file: undefined,
            }, () => {
                this.elFile.current.setCustomErrorMessage(I18n.t('ticket.conditional_scooters_empty_error_message'));
                this.elForm.current.checkValidity();
            });
            return;

        }

        if ([MAIN_TYPE_DISPATCH, MAIN_TYPE_MAINTENANCE].includes(mainType)) {
            this.handleCustomFields(formData);
        }

        const setDefaultAssignee = permissionHandler({
            requiredList: [AUTH_VIEW_RESTRICT_TICKET_ASSIGNEE]
        });
        const { value: virtualUserId } = virtualUsers[0];

        formData = {
            ...formData,
            assigned_to_id: setDefaultAssignee ?  virtualUserId : assigned_to_id,
            severity_id: Number(severity_id),
            tracker_id: Number(mainType),
            tracker_subtype_id: Number(tracker_subtype_id),
            due_date: due_date ? convertToUTC(due_date) : undefined,
            description,
            plate_no: isConditional ? plate_no : undefined,
            conditional_file: undefined,
            scooter_state_action: isConditional && activation === '0' ? activation : undefined,
        };

        onSubmit(formData, doOfflineScooterList);
    }

    toggleWarningDialog = e => {
        const { warningOpen } = this.state;
        const formData = serialize(e.currentTarget, { hash: true });
        this.setState({ warningOpen: !warningOpen, warningFormData: formData });
    }

    render() {
        const {
            conditional_file,
            activation,
            severity_id,
            due_date,
            description,
            destination,
            dms_no,
            mega_centre,
            warningOpen
        } = this.state;
        const {
            show,
            virtualUsers,
            opUsers,
            mainType = MAIN_TYPE_SWAP,
            onCancelled,
            isConditional,
            corporate,
            inProgress,
        } = this.props;
        const subTypes = SUB_TYPE_GROUP[mainType];
        const subTypeOptions = subTypes.map(subType => ({
            name: `ticket.sub_type_${ subType }`,
            value: subType,
        }));
        const priorityOptions = GROUP_SEVERITY.map(priority => ({
            name: `ticket.severity_${ priority }`,
            value: priority,
        }));
        const { value: virtualUserId } = virtualUsers[0];
        const viewMode = permissionHandler({
            requiredList: [AUTH_VIEW_RESTRICT_TICKET_ASSIGNEE]
        });
        return (
            show ? (
                <Form
                    onSubmit={ corporate.length > 1 ?
                        this.toggleWarningDialog : this.handleSubmit
                    }
                    innerRef={ this.elForm }
                >
                    <Input
                        name="tracker_subtype_id"
                        type={ TYPE_SELECT }
                        caption="ticket.sub_type"
                        value={ subTypeOptions }
                        required
                    />
                    <Input
                        name="activation"
                        type={ TYPE_CHECKBOX }
                        caption="action_panel.active_status"
                        value={ [{
                            name: 'action_panel.offline',
                            value: SCOOTER_OFFLINE,
                            auth: AUTH_DO_SCOOTER_OFFLINE,
                            translate: true,
                        }] }
                        onChange={ this.handleChange('activation') }
                        selected={ activation }
                    />
                    <Input
                        name="assigned_to_id"
                        type={ TYPE_SEARCH_DROPDOWNLIST }
                        caption="ticket.assignee"
                        value={ opUsers }
                        selected={ viewMode ? virtualUserId : undefined }
                        viewMode={ viewMode }
                        required
                    />
                    <Input
                        name="severity_id"
                        type={ TYPE_RADIO }
                        caption="ticket.priority"
                        value={ priorityOptions }
                        selected={ severity_id }
                        onChange={ this.handleChange('severity_id') }
                        required
                    />
                    <Input
                        name="due_date"
                        type={ TYPE_DATETIME }
                        caption="ticket.due_time"
                        dateFormat={ I18n.t('datetime_format.date') }
                        timeFormat={ I18n.t('datetime_format.time') }
                        value={ due_date }
                        onChange={ this.handleChange('due_date') }
                    />
                    { isConditional && (
                        <Input
                            name="conditional_file"
                            type={ TYPE_FILE }
                            accept={ ACCEPTED_FILE_TYPE }
                            caption="ticket.import_plate_list"
                            required={ isConditional }
                            onKeyDown={ e => e.preventDefault() }
                            onPaste={ e => e.preventDefault() }
                            onChange={ this.handleChange('conditional_file') }
                            value={ conditional_file }
                            innerRef={ this.elFile }
                        />
                    ) }
                    { mainType === MAIN_TYPE_DISPATCH && (
                        <Input
                            name="destination"
                            type={ TYPE_TEXT }
                            caption="ticket.destination"
                            value={ destination }
                            onChange={ this.handleChange('destination') }
                        />
                    ) }
                    { mainType === MAIN_TYPE_MAINTENANCE && (
                        <Input
                            name="dms_no"
                            type={ TYPE_TEXT }
                            caption="ticket.dms_no"
                            value={ dms_no }
                            onChange={ this.handleChange('dms_no') }
                        />
                    ) }
                    { mainType === MAIN_TYPE_MAINTENANCE && (
                        <Input
                            name="mega_centre"
                            type={ TYPE_TEXT }
                            caption="ticket.mega_center"
                            value={ mega_centre }
                            onChange={ this.handleChange('mega_centre') }
                        />
                    ) }
                    <Input
                        name="description"
                        type={ TYPE_TEXTAREA }
                        caption="ticket.description"
                        rows={ 4 }
                        value={ description }
                        onChange={ this.handleChange('description') }
                    />

                    <FlexGroup start gap>
                        <Button type="button" className="btn-cancel" outline onClick={ onCancelled }>
                            <Translate value="cancel" />
                        </Button>
                        <Button color="primary" type="submit">
                            <Translate value="submit" />
                        </Button>
                    </FlexGroup>
                    <WarningModal
                        show={ warningOpen }
                        corporate={ corporate }
                        onClose={ this.toggleWarningDialog }
                        onSubmit={ this.handleSubmit }
                        inProgress={ inProgress }
                    />
                </Form>
            ) : null
        );
    }
}
export default SecondStep;
