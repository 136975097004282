import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import {
    SCOOTER_PROFILE,
} from 'constants/routes';
import { openScooterDetailPopup } from 'actions';
import buildActualPath from 'helpers/build-actual-path';
import AuthFeature from 'components/AuthFeature';
import {
    AUTH_VIEW_SCOOTER_DETAIL,
} from 'constants/permission';
import './plate.scss';

const Component = ({ dispatch, id, plateNo, className, onClick, modal }) => {
    const handleClick = e => {
        e.preventDefault();

        dispatch(openScooterDetailPopup(id)).then(() => {
            onClick();
        });
    };

    return (
        <div className={ `plate ${ className }` }>
            {
                modal ?
                    <Button color="link" onClick={ handleClick }>{ plateNo }</Button> :
                    (
                        <AuthFeature
                            requiredList={ [AUTH_VIEW_SCOOTER_DETAIL] }
                            fallback={ () => plateNo }
                        >
                            <NavLink to={ buildActualPath(SCOOTER_PROFILE, { scooterId: id }) }>
                                { plateNo }
                            </NavLink>
                        </AuthFeature>
                    )
            }
        </div>
    );
};

Component.propTypes = {
    id: PropTypes.string,
    plateNo: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    modal: PropTypes.bool,
};

Component.defaultProps = {
    id: '',
    plateNo: '',
    className: '',
    onClick: () => {},
    modal: true
};


export default connect(state => ({}))(Component);
