/**
 * Reducer - General
 */
import { Map } from 'immutable';
import {
    TOGGLE_MENU,
    UPDATE_DOCUMENT_TITLE,
    TOGGLE_ERROR_DIALOG,
    TOGGLE_NAVIGATION,
    OPEN_PLEASE_LOGIN_DIALOG,
} from 'constants/action-type';
import creater from './util/creater';

const initialState = Map({
    toggleMenu: false,
    showNavigation: false,
    showPleaseLoginDialog: false,
    title: '',
    err: undefined,
    errObj: undefined,
});

const actionsMap = {
    [TOGGLE_MENU]: state => {
        const isOpen = !state.get('toggleMenu');

        return state.merge({
            toggleMenu: isOpen,
        });
    },

    [UPDATE_DOCUMENT_TITLE]: (state, action) => {
        return state.merge({
            title: action.data.title,
        });
    },

    [TOGGLE_ERROR_DIALOG]: (state, action) => {
        const { message, onClose } = action.data || {};
        const err = (message ? new Error(message) : undefined);
        const errObj = (
            err ?
                { message, onClose } :
                undefined
        );

        return state.merge({
            err,
            errObj,
        });
    },

    [TOGGLE_NAVIGATION]: (state) => {
        const showNavigation = !state.get('showNavigation');
        return state.merge({
            showNavigation
        });
    },

    [OPEN_PLEASE_LOGIN_DIALOG]: state => {
        return state.merge({ showPleaseLoginDialog: true });
    },
};

export default creater(actionsMap, initialState);
