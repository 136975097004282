/**
 * Action - Coupon
 */
import api from 'api';
import {
    FETCH_COUPON_LIST,
    FETCH_ONE_COUPON,
    CREATE_COUPON,
    UPDATE_COUPON,
    FETCH_ACCOUNT_TYPE,
    FETCH_BULK_COUPON_CODE,
    FETCH_SYSTEM_COUPON_LIST,
    UPDATE_SYSTEM_COUPON,
    CREATE_SYSTEM_COUPON,
    FETCH_ONE_SYSTEM_COUPON
} from 'constants/action-type';
import { asyncInterface } from './util';

export const fetchCouponList = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchCouponList(params),
        type: FETCH_COUPON_LIST,
        dispatch,
    });
};

export const fetchSystemCouponList = (params = {}) => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchSystemCouponList(params),
        type: FETCH_SYSTEM_COUPON_LIST,
        dispatch,
    });
};

export const fetchOneCoupon = id => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchOneCoupon(id),
        type: FETCH_ONE_COUPON,
        dispatch,
    });
};

export const fetchOneSystemCoupon = id => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchOneSystemCoupon(id),
        type: FETCH_ONE_SYSTEM_COUPON,
        dispatch,
    });
};

export const clearOneCoupon = () => dispatch => {
    return Promise.resolve(dispatch({
        type: FETCH_ONE_COUPON,
    }));
};

export const createCoupon = payload => dispatch => {
    return asyncInterface({
        asyncAction: api.createCoupon(payload),
        type: CREATE_COUPON,
        dispatch,
    });
};

export const createSystemCoupon = payload => dispatch => {
    return asyncInterface({
        asyncAction: api.createSystemCoupon(payload),
        type: CREATE_SYSTEM_COUPON,
        dispatch,
    });
};

export const updateCoupon = (id, payload) => dispatch => {
    return asyncInterface({
        asyncAction: api.updateCoupon(id, payload),
        type: UPDATE_COUPON,
        dispatch,
    });
};

export const updateSystemCoupon = (id, payload) => dispatch => {
    return asyncInterface({
        asyncAction: api.updateSystemCoupon(id, payload),
        type: UPDATE_SYSTEM_COUPON,
        dispatch,
    });
};


export const fetchAccountType = () => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchAccountType(),
        type: FETCH_ACCOUNT_TYPE,
        dispatch,
    });
};

export const fetchBulkCouponCode = id => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchBulkCouponCode(id),
        type: FETCH_BULK_COUPON_CODE,
        dispatch,
    });
};
