/**
 * Scooter related constants
 */

export const TRUNK_CLOSED = 0;
export const TRUNK_OPEN = 1;
export const TRUNK_LOCKED = 2;

export const SCOOTER_STATE_UNLOCK = 0;
export const SCOOTER_STATE_ENGINE_ON = 1;
export const SCOOTER_STATE_LOCK = 2;
export const SCOOTER_STATE_SHUTDOWN = 6;

export const RPC_LOCK = 0;
export const RPC_UNLOCK = 1;
export const RPC_OPEN_TRUNK = 2;
export const RPC_FIND_SCOOTER = 3;

export const SCOOTER_OFFLINE = 0;
export const SCOOTER_ONLINE = 1;

export const TICKET_NONE = 0;
export const TICKET_SWAP = 1;
export const TICKET_DISPATCH = 2;
export const TICKET_MAINTAIN = 3;
export const TICKET_SWAP_F = 4;

// GTU Replace Error Code
export const GTU_ERROR_CODE_15000 = 15000;
export const GTU_ERROR_CODE_15001 = 15001;
export const GTU_ERROR_CODE_15002 = 15002;
export const GTU_ERROR_CODE_15003 = 15003;
export const GTU_ERROR_CODE_15004 = 15004;
export const GTU_ERROR_CODE_15005 = 15005;
export const GTU_ERROR_CODE_15006 = 15006;
export const GTU_ERROR_CODE_15007 = 15007;

// Force Return Error Code
export const FORCE_RETURN_ERROR_CODE_3000 = 3000;
export const FORCE_RETURN_ERROR_CODE_3001 = 3001;
export const FORCE_RETURN_ERROR_CODE_3006 = 3006;
export const FORCE_RETURN_ERROR_CODE_3007 = 3007;
export const FORCE_RETURN_ERROR_CODE_3008 = 3008;
export const FORCE_RETURN_ERROR_CODE_3009 = 3009;
export const FORCE_RETURN_ERROR_CODE_3010 = 3010;

// Firmware
export const FIRMWARE_ERROR_CODE_1400 = 1400;

// location
export const CITY_OTHERS = 999;
// define by front-end
export const CITY_DOTS = -1;

// activate
export const ACTIVATE_DISABLE = 0;
export const ACTIVATE_ENABLE = 1;
export const ACTIVATE_INTERNAL_TEST = 2;
export const ACTIVATE_DEMO = 3;

// model
export const MODEL_B = 'BSD'; // BYG BCW...
export const MODEL_B_DOT = 'BC2-DOT';
export const MODEL_BTG = 'BTG';
export const MODEL_F = 'FRA';
export const MODEL_P = 'PSD';
export const MODEL_J = 'JSD';
export const MODEL_D = 'DYS';

export const SERVICE_TYPE_FREE_FLOATING = 0;
export const SERVICE_TYPE_DOTS = 1;
export const SERVICE_TYPE_VIP = 2;


// ecu
export const CONTROL_UNIT_ECU = 1;
export const CONTROL_UNIT_BCU = 2;
export const ECU_TYPE_DEFAULT = CONTROL_UNIT_ECU;
