import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Input, {
    TYPE_TEXT,
} from 'components/Form/Input';

const CustomInput = ({ caption, value, type, className }) => (
    <Input
        className={ className }
        type={ type }
        caption={ caption }
        value={ [undefined, null].indexOf(value) === -1 ? value : '--' }
        viewMode
    />
);

CustomInput.propTypes = {
    caption: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf(moment),
    ]),
    type: PropTypes.string,
    className: PropTypes.string,
};
CustomInput.defaultProps = {
    caption: '',
    value: undefined,
    type: TYPE_TEXT,
    className: '',
};
export default CustomInput;
