// ticket related constants

// main type
export const MAIN_TYPE_SWAP = 1;
export const MAIN_TYPE_DISPATCH = 2;
export const MAIN_TYPE_MAINTENANCE = 3;
export const MAIN_TYPE_OTHERS = 4;

// main type groups
export const MAIN_TYPE_GROUP = [
    MAIN_TYPE_SWAP,
    MAIN_TYPE_OTHERS,
    MAIN_TYPE_DISPATCH,
    MAIN_TYPE_MAINTENANCE,
];

// sub type
//   for swap
export const SUB_TYPE_101 = 101;
export const SUB_TYPE_102 = 102;
//   for dispatch
export const SUB_TYPE_201 = 201;
export const SUB_TYPE_202 = 202;
export const SUB_TYPE_203 = 203;
export const SUB_TYPE_204 = 204;
export const SUB_TYPE_205 = 205;
export const SUB_TYPE_206 = 206;
export const SUB_TYPE_207 = 207;
export const SUB_TYPE_213 = 213;
//   for maintenance
export const SUB_TYPE_301 = 301;
export const SUB_TYPE_302 = 302;
export const SUB_TYPE_303 = 303;
export const SUB_TYPE_304 = 304;
export const SUB_TYPE_305 = 305;
//   for other
export const SUB_TYPE_401 = 401;
export const SUB_TYPE_402 = 402;
export const SUB_TYPE_403 = 403;
export const SUB_TYPE_404 = 404;
export const SUB_TYPE_405 = 405;
export const SUB_TYPE_406 = 406;
export const SUB_TYPE_407 = 407;
export const SUB_TYPE_408 = 408;
export const SUB_TYPE_409 = 409;
export const SUB_TYPE_410 = 410;
export const SUB_TYPE_411 = 411;
export const SUB_TYPE_412 = 412;
export const SUB_TYPE_413 = 413;
export const SUB_TYPE_414 = 414;
export const SUB_TYPE_415 = 415;
export const SUB_TYPE_416 = 416;
export const SUB_TYPE_417 = 417;
export const SUB_TYPE_418 = 418;
export const SUB_TYPE_419 = 419;
export const SUB_TYPE_420 = 420;
export const SUB_TYPE_421 = 421;

// sub type groups
export const SUB_TYPE_GROUP = {
    [MAIN_TYPE_SWAP]: [
        SUB_TYPE_101,
        SUB_TYPE_102,
    ],
    [MAIN_TYPE_OTHERS]: [
        SUB_TYPE_417,
        SUB_TYPE_411,
        SUB_TYPE_401,
        SUB_TYPE_409,
        SUB_TYPE_410,
        SUB_TYPE_408,
        SUB_TYPE_416,
        SUB_TYPE_407,
        SUB_TYPE_403,
        SUB_TYPE_404,
        SUB_TYPE_405,
        SUB_TYPE_406,
        SUB_TYPE_418,
        SUB_TYPE_415,
        SUB_TYPE_402,
        SUB_TYPE_412,
        SUB_TYPE_413,
        SUB_TYPE_419,
        SUB_TYPE_420,
        SUB_TYPE_414,
        SUB_TYPE_421,
    ],
    [MAIN_TYPE_DISPATCH]: [
        SUB_TYPE_201,
        SUB_TYPE_202,
        SUB_TYPE_203,
        SUB_TYPE_204,
        SUB_TYPE_205,
        SUB_TYPE_206,
        SUB_TYPE_207,
        SUB_TYPE_213,
    ],
    [MAIN_TYPE_MAINTENANCE]: [
        SUB_TYPE_301,
        SUB_TYPE_303,
        SUB_TYPE_305,
        SUB_TYPE_302,
    ],
};

export const SUB_TYPE_LIST = [
    ...SUB_TYPE_GROUP[MAIN_TYPE_SWAP],
    ...SUB_TYPE_GROUP[MAIN_TYPE_OTHERS],
    ...SUB_TYPE_GROUP[MAIN_TYPE_DISPATCH],
    ...SUB_TYPE_GROUP[MAIN_TYPE_MAINTENANCE],
];

// status
export const STATUS_OPEN = 1;
export const STATUS_RECEIVED = 2;
export const STATUS_CHECKED = 3;
export const STATUS_IN_PROGRESS = 4;
export const STATUS_RESOLVED = 5;
export const STATUS_IN_QC = 6;
export const STATUS_DONE_QC = 7;
export const STATUS_BLOCKED_OTHERS = 8;
export const STATUS_BLOCKED_SHORT_OF_MATERIAL = 9;
export const STATUS_Blocked_TECH = 10;
export const STATUS_CLOSED = 11;
export const STATUS_CLOSED_NEW_TICKET = 12;

export const GROUP_STATUS = [
    STATUS_OPEN,
    STATUS_RECEIVED,
    STATUS_CHECKED,
    STATUS_IN_PROGRESS,
    STATUS_RESOLVED,
    STATUS_IN_QC,
    STATUS_DONE_QC,
    STATUS_BLOCKED_OTHERS,
    STATUS_BLOCKED_SHORT_OF_MATERIAL,
    STATUS_Blocked_TECH,
    STATUS_CLOSED,
    STATUS_CLOSED_NEW_TICKET,
];

export const GROUP_STATUS_EXCLUDE_CLOSED = GROUP_STATUS.filter(status => status !== STATUS_CLOSED);

// severity
export const SEVERITY_LOW = 1;
export const SEVERITY_MEDIUM = 2;
export const SEVERITY_HIGH = 3;
export const GROUP_SEVERITY = [
    SEVERITY_LOW,
    SEVERITY_MEDIUM,
    SEVERITY_HIGH,
];

// custom fields
export const CUSTOM_FIELD_DESTINATION = 1;
export const CUSTOM_FIELD_DMS_NO = 2;
export const CUSTOM_FIELD_MEGA_CENTRE = 3;
