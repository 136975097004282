import React, { useEffect, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { clearFactorAuth } from 'actions';
import { useDispatch } from "react-redux";
import debounce from 'lodash/debounce';
import moment from 'moment';

const ALLOW_IDE_TIME = 20*60*1000;

const DEBOUNCE_INTERVAL = 500;


const FactorAuthIdleTimer = ({
    onIdle,
    disabled,
}) => {
    const [inProgress, setInProgress] = useState(false);
    const timer = useRef();
    const start = useRef();
    const debouncedMouseMove = useRef();
    const debouncedScroll = useRef();
    const dispatch = useDispatch();

    const resetTimer = useCallback(() => {
        if (!disabled) {
            if (timer.current) {
                clearTimeout(timer.current);
            }
            start.current = new Date();
            timer.current = setTimeout(() => {
                setInProgress(true);
            }, ALLOW_IDE_TIME);
        }

    }, [disabled]);

    const handleVisibilityChange = useCallback(() => {
        const now = new Date();
        let diff;
        let rest;
        if (!disabled && timer.current && document.visibilityState === 'visible') {
            diff = moment(now).diff(moment(start.current));
            rest = ALLOW_IDE_TIME - diff;
            if (rest <= 0) {
                resetTimer(0);
            }
        }

    }, [resetTimer, disabled]);

    const handleOnIdle = useCallback(async () => {
        await dispatch(clearFactorAuth());
        onIdle();
    },[dispatch, onIdle]);

    useEffect(() => {
        if (inProgress) {
            handleOnIdle().then(() => {
                setInProgress(false);
            });
        }
    }, [inProgress, handleOnIdle]);

    useEffect(() => {
        if (!disabled) {
            debouncedMouseMove.current = debounce(resetTimer, DEBOUNCE_INTERVAL);
            debouncedScroll.current = debounce(resetTimer, DEBOUNCE_INTERVAL);
            resetTimer();
            window.addEventListener('mousemove', debouncedMouseMove.current, true);
            window.addEventListener('keydown', resetTimer, true);
            window.addEventListener('click', resetTimer, true);
            window.addEventListener('scroll', debouncedScroll.current, true);
            document.addEventListener('visibilitychange', handleVisibilityChange, false);
        }
        else {
            clearTimeout(timer.current);
            window.removeEventListener('mousemove', debouncedMouseMove.current, true);
            window.removeEventListener('keydown', resetTimer, true);
            window.removeEventListener('click', resetTimer, true);
            window.removeEventListener('scroll', debouncedScroll.current, true);
            document.removeEventListener('visibilitychange', handleVisibilityChange, false);
        }

        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
                window.removeEventListener('mousemove', debouncedMouseMove.current, true);
                window.removeEventListener('keydown', resetTimer, true);
                window.removeEventListener('click', resetTimer, true);
                window.removeEventListener('scroll', debouncedScroll.current, true);
                document.removeEventListener('visibilitychange', handleVisibilityChange, false);
            }

        };
    }, [resetTimer, disabled, handleVisibilityChange]);

    return (
        <></>
    );
};

FactorAuthIdleTimer.propTypes = {
    onIdle: PropTypes.func,
    disabled: PropTypes.bool,
};
FactorAuthIdleTimer.defaultProps = {
    onIdle: () => {},
    disabled: true,
};

export default FactorAuthIdleTimer;
