import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import xss from 'xss';
import {
    CHART_CONFIG_DEFAULT,
    CHART_DURATION_DEFAULT,
    xssOptions,
} from 'constants/chart';
import { animateValue } from 'helpers/dashboard';
import {
    DASH_COLOR_BLUE,
    SERVICE_LEVEL_CHART_THICK,
} from 'constants/dashboard';
import { getPieChartHole } from 'helpers/dashboard';

class Gauge extends React.Component {
    static propTypes = {
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        gaugeValue: PropTypes.number.isRequired,
        color: PropTypes.string,
        thick: PropTypes.number,
        duration: PropTypes.number,
        renderLabel: PropTypes.func,
    };

    static defaultProps = {
        color: DASH_COLOR_BLUE,
        thick: undefined,
        duration: CHART_DURATION_DEFAULT,
        renderLabel: () => {},
    }

    constructor(props) {
        super(props);
        this.animateTimer = undefined;
        this.state = {
            value: props.gaugeValue,
        };
    }

    componentDidUpdate(prevProps) {
        const { gaugeValue, duration } = this.props;
        if (prevProps.gaugeValue !== gaugeValue) {
            this.animateTimer = animateValue(prevProps.gaugeValue, gaugeValue, duration, this.handleState);
        }
    }

    componentWillUnmount() {
        if (this.animateTimer && typeof this.animateTimer.kill === 'function') {
            this.animateTimer.kill();
        }
    }

    handleState = (value, callback) => {
        this.setState({
            value,
        }, callback);
    }

    render() {
        const { width, height, color, thick, renderLabel } = this.props;
        const { value } = this.state;
        const chartHole = getPieChartHole(width, thick || SERVICE_LEVEL_CHART_THICK);
        const labelText = xss(renderLabel(value), xssOptions);

        return (
            <div className="gauge">
                <Plot
                    data={ [
                        {
                            values: [value, (100 - value)],
                            marker: {
                                colors: [color, `${color}1A`]
                            },
                            textinfo: 'none',
                            hoverinfo: 'none',
                            hole: chartHole,
                            rotation: value <= 49 ? (value * 360 / 100) : 0,
                            type: 'pie',
                        }
                    ] }
                    layout={ {
                        paper_bgcolor: 'transparent',
                        annotations: [
                            {
                                font: {
                                    size: 28,
                                    color
                                },
                                showarrow: false,
                                text: labelText,
                                x: 0.5,
                                y: 0.5
                            }
                        ],
                        height,
                        width,
                        margin: {
                            l: 0,
                            r: 0,
                            b: 0,
                            t: 0,
                        },
                        showlegend: false,
                    } }
                    config={ CHART_CONFIG_DEFAULT }
                />
            </div>
        );
    };
};

export default Gauge;
