import {
    HEADER_FORMAT_INVALID,
    CONTENT_CANNOT_EMPTY,
    FILE_INVALID,
    DATA_DUPLICATE,
    SCOOTER_MODEL_ID_NOT_FOUND,
    SCOOTER_NOT_FOUND,
    SCOOTER_NOT_DISABLED,
    PLATE_NOT_EQUALS_VIN,
} from 'constants/asset';

const asset = {
    vin: 'VIN',
    plate: 'Plate No.',
    subscribe_id: 'Subscribe ID',
    gtu_imei: 'GTU IMEI',
    sim_phone_number: 'ICCID',
    ssl_certificate_issuer: 'SSL certificate issuer',
    ssl_certificate_subject: 'SSL certificate subject',
    ssl_certificate_valid_to: 'SSL certificate valid to',
    create_time: 'Created Time',
    file_type: 'Options',
    upload_scooter_data: 'New asset',
    update_scooter_plate: 'Update plate',
    browse_file: 'Browse file',
    scooter_model_name: 'Model',
    [`error_message_${ HEADER_FORMAT_INVALID }`]: 'Invalid header format',
    [`error_message_${ CONTENT_CANNOT_EMPTY }`]: 'Content cannot be empty',
    [`error_message_${ FILE_INVALID }`]: 'File invalid',
    [`error_message_${ DATA_DUPLICATE }`]: 'VIN %{vin}: invalid data',
    [`error_message_${ SCOOTER_MODEL_ID_NOT_FOUND }`]: 'VIN %{vin}: scooter model ID not found',
    [`error_message_${ SCOOTER_NOT_FOUND }`]: 'VIN %{vin}: Scooter not found',
    [`error_message_${ SCOOTER_NOT_DISABLED }`]: 'VIN %{vin}: please do offline the scooter, then update again',
    [`error_message_${ PLATE_NOT_EQUALS_VIN }`]: 'VIN %{vin}: plate cannot update',
};

export default asset;
