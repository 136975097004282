/**
 * Action - Ad
 */
import api from 'api';

import {
    FETCH_AD_LIST,
    CLEAR_AD_LIST,
    FETCH_SINGLE_AD,
    UPSERT_SINGLE_AD,
    CLEAR_SINGLE_AD,
    FETCH_ORDERABLE_AD_LIST,
    ORDER_AD_LIST,
    UPDATE_AD_ORDER,
} from 'constants/action-type';

import { asyncInterface } from './util';

export const fetchAdList = params => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchAdList({ params }),
        type: FETCH_AD_LIST,
        dispatch,
    });
};

export const clearAdList = () => dispatch => dispatch({
    type: CLEAR_AD_LIST,
});

export const fetchSingleAd = id => dispatch => {
    return asyncInterface({
        asyncAction: api.fetchSingleAd(id),
        type: FETCH_SINGLE_AD,
        dispatch,
    });
};

export const upsertSingleAd = ({ id, file, payload }) => dispatch => {
    const apiUpsertSingleAd = payload => {
        return id === undefined ?
            api.createSingleAd(payload) :
            api.updateSingleAd(id, payload);
    };
    const apiUpsertSingleAdWithFile = ({ file, payload }) => dispatch => {
        return api.fetchImgUploadUrl(id)().then(
            response => {
                return Promise.all([
                    apiUpsertSingleAd({ ...payload, 'img_file_name': response.data.id })(),
                    api.uploadFile({ url: response.data.url, file }),
                ]).then(
                    ([uperstSingleAdResponse]) => uperstSingleAdResponse
                );
            }
        );
    };

    const asyncAction = file ?
        apiUpsertSingleAdWithFile({ file, payload }) :
        apiUpsertSingleAd({ ...payload, 'img_file_name': id });

    return asyncInterface({
        asyncAction: asyncAction,
        type: UPSERT_SINGLE_AD,
        dispatch,
    });
};

export const clearSingleAd = () => dispatch => dispatch({
    type: CLEAR_SINGLE_AD,
});

export const fetchOrderableAdList = () => dispatch => {
    const params = { filter_by_order: true };

    return asyncInterface({
        type: FETCH_ORDERABLE_AD_LIST,
        asyncAction: api.fetchAdList({ params }),
        dispatch,
    });
};

export const orderAdList = (currentIndex, nextIndex) => {
    return {
        type: ORDER_AD_LIST,
        data: { currentIndex, nextIndex }
    };
};

export const updateAdOrder = () => (dispatch, getState) => {
    const ads = getState().ad
        .getIn(['orderableList', 'data_list'])
        .toJS()
        .map(({ id, order }) => ({ id, order }));

    return asyncInterface({
        type: UPDATE_AD_ORDER,
        asyncAction: api.updateAdOrder(ads),
        dispatch,
    });
};
