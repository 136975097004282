import React, { Component } from 'react';
import store from 'store';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18n } from 'react-redux-i18n';
import { Translate } from 'react-redux-i18n';
import AuthFeature from 'components/AuthFeature';
import { AUTH_EDIT_TICKET } from 'constants/permission';
import FormGroup from 'components/Form/FormGroup';
import FlexGroup from 'components/FlexGroup';
import Datetime from 'components/Datetime';
import Button from 'components/Form/Button';
import CustomView from 'components/Form/Input/CustomView';
import Attachment from 'components/Attachment/Attachment';
import { NavLink } from 'react-router-dom';
import buildActualPath from 'helpers/build-actual-path';
import {
    SCOOTER_PROFILE,
} from 'constants/routes';
import Input, {
    TYPE_HIDDEN,
    TYPE_TEXT,
    TYPE_TEXTAREA,
    TYPE_CHECKBOX,
    TYPE_FILE,
    TYPE_DATETIME,
    TYPE_SELECT,
    TYPE_SEARCH_DROPDOWNLIST,
} from 'components/Form/Input';
import {
    MAIN_TYPE_DISPATCH,
    MAIN_TYPE_MAINTENANCE,
    STATUS_CLOSED,
    SUB_TYPE_GROUP,
    GROUP_SEVERITY,
    CUSTOM_FIELD_DESTINATION,
    CUSTOM_FIELD_DMS_NO,
    CUSTOM_FIELD_MEGA_CENTRE,
} from 'constants/ticket';
import { SCOOTER_ONLINE } from 'constants/scooter';
import { convertOpUsers } from 'components/NewTicket';
import api from 'api';
import {
    fetchAttatchmentUrl,
    updateOneTicket,
} from 'actions';
import Log from './Log';

const ACCEPTED_MEDIA = [
    // image
    'png', 'jpg', 'jpeg',
    // video
    'mp4', 'mov'
].map(ext => `.${ext}`).join(',');

class TicketForm extends Component {
    static propTypes = {
        data: PropTypes.shape().isRequired,
        editMode: PropTypes.bool.isRequired,
        updating: PropTypes.bool.isRequired,
        onStartComment: PropTypes.func,
        onCompleteComment: PropTypes.func,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        onStartComment: () => {},
        onCompleteComment: () => {},
        onChange: () => {},
    };

    constructor(props) {
        super(props);


        this.elComment = React.createRef();
        this.elStatus = React.createRef();
        this.elDestination = React.createRef();
        this.elDms_no = React.createRef();
        this.elMega_centre = React.createRef();
        this.attachment = undefined;
        this.state = {
            updateMediaSrc: undefined,
            hasComment: false,
            isCloseStatus: false
        };
        this.custom_fieldsObj = {
            [CUSTOM_FIELD_DESTINATION]: '',
            [CUSTOM_FIELD_DMS_NO]: '',
            [CUSTOM_FIELD_MEGA_CENTRE]: ''
        };
    }

    componentDidMount() {
        this.setCustom_fieldObj();
    }

    componentDidUpdate(prevProps) {
        const { editMode } = this.props;
        if (editMode !== prevProps.editMode) {
            this.setCustom_fieldObj();
            // status might be change when editMode changes
            this.onChangeStatus();
        }
    }

    get currentStatusId() {
        const { data, editMode } = this.props;
        return Number(
            editMode && this.elStatus.current ? this.elStatus.current.value : data.status_id
        );
    }

    setCustom_fieldObj() {
        const { data } = this.props;
        const { custom_fields = [] } = data;
        const destination = custom_fields.find(field => field.id === CUSTOM_FIELD_DESTINATION);
        const dmsNo = custom_fields.find(field => field.id === CUSTOM_FIELD_DMS_NO);
        const megaCentre = custom_fields.find(field => field.id === CUSTOM_FIELD_MEGA_CENTRE);

        this.custom_fieldsObj = {
            [CUSTOM_FIELD_DESTINATION]: destination?.value,
            [CUSTOM_FIELD_DMS_NO]: dmsNo?.value,
            [CUSTOM_FIELD_MEGA_CENTRE]: megaCentre?.value
        };
        this.setState({});
    }

    handleCommentChange = e => {
        this.setState({
            hasComment: e.currentTarget.value.length,
        });
    }

    handleCustomFieldChange = () => {
        const destination = this.elDestination.current?.value;
        const dms_no = this.elDms_no.current?.value;
        const mega_centre = this.elMega_centre.current?.value;
        this.custom_fieldsObj = {
            [CUSTOM_FIELD_DESTINATION]: destination,
            [CUSTOM_FIELD_DMS_NO]: dms_no,
            [CUSTOM_FIELD_MEGA_CENTRE]: mega_centre
        };
    }

    handleMediaPreview = e => {
        this.attachment = e.currentTarget.files[0];
        e.currentTarget.value = '';
        if (!this.attachment) {
            return;
        }

        const blob = new Blob([this.attachment]);
        const src = URL.createObjectURL(blob);
        this.setState({
            updateMediaSrc: src,
            hasComment: true,
        });
    }

    handleSubmitComment = () => {
        const { dispatch } = store;
        const { data, onStartComment, onCompleteComment } = this.props;
        const {
            id,
        } = data;
        const payload = {
            notes: this.elComment.current.value,
            attachments: [],
        };
        const mediaHandler = () => {
            return new Promise((resolve, reject) => {
                if (this.attachment) {
                    const ext = this.attachment.name.split('.').pop();

                    dispatch(fetchAttatchmentUrl(ext))
                        .then(({ data }) => {
                            const { url, storage_filename } = data;
                            return api.uploadFile({ url, file: this.attachment })
                                .then(() => storage_filename);
                        })
                        .then(resolve)
                        .catch(reject);
                }
                else {
                    resolve();
                }
            });
        };

        onStartComment();

        return mediaHandler().then(filename => {
            const { notes } = payload;

            if (filename) {
                payload.attachments.push(filename);
            }

            if (notes || filename) {
                dispatch(updateOneTicket(id, payload)).then(() => {
                    this.attachment = undefined;
                    this.setState({
                        updateMediaSrc: undefined,
                        hasComment: false,
                    }, () => {
                        onCompleteComment();
                        this.elComment.current.value = '';
                    });
                });
            }
            else {
                onCompleteComment();
            }
        });
    }

    isCloseStatus = () => {
        return STATUS_CLOSED === this.currentStatusId;
    }

    onChangeStatus = () => {
        const isCloseStatus = this.isCloseStatus();
        if (!isCloseStatus) {
            const { onChange } = this.props;
            onChange('do_online', []);
        }

        this.setState({
            isCloseStatus
        });
    }

    render() {
        const { updateMediaSrc, hasComment, isCloseStatus } = this.state;
        const { data, editMode, onChange, updating } = this.props;
        const {
            assigned_to_id,
            assigned_to_editable,
            author,
            create_time,
            description,
            description_editable,
            due_date,
            due_date_editable,
            plate_no,
            plate_no_editable,
            severity_id,
            severity_editable,
            status_editable_list = [],
            status_editable,
            status_id,
            tracker_id,
            tracker_editable,
            tracker_subtype_id,
            tracker_subtype_editable,
            update_time,
            notes_editable,
            custom_fields = [],
            journal = {},
            opUsers,
            scooter_id,
            doOnline,
        } = data;
        const { comments = [], logs = [] } = journal;
        const statusOptions = status_editable_list.map(({ status_id }) => ({
            name: I18n.t(`ticket.status_${ status_id }`),
            value: status_id,
        }));
        const subTypeOptions = SUB_TYPE_GROUP[tracker_id]?.map(subTypeId => ({
            name: I18n.t(`ticket.sub_type_${ subTypeId }`),
            value: subTypeId,
        })) ?? [];
        const priortyOptions = GROUP_SEVERITY.map(severityId => ({
            name: I18n.t(`ticket.severity_${ severityId }`),
            value: severityId,
        }));
        const destinationEditable = custom_fields.find(field => field.id === CUSTOM_FIELD_DESTINATION)?.editable;
        const dmsNoEditable = custom_fields.find(field => field.id === CUSTOM_FIELD_DMS_NO)?.editable;
        const megaCentreEditable = custom_fields.find(field => field.id === CUSTOM_FIELD_MEGA_CENTRE)?.editable;
        const dateFormat = 'YYYY-MM-DD';
        const timeFormat = 'HH:mm';
        const fullTimeFormat = `${ dateFormat } ${ timeFormat }`;
        const fileUploadDisabled = !notes_editable || updating;

        return (
            <div className="editable-section">
                <FormGroup title={ I18n.t('ticket.details') }>
                    <FlexGroup className="basic-info">
                        <Input
                            caption="ticket.main_type"
                            value={ tracker_id ? I18n.t(`ticket.main_type_${ tracker_id }`) : undefined }
                            viewMode={ !tracker_editable }
                        />
                        <Input
                            type={ TYPE_SELECT }
                            name="tracker_subtype_id"
                            caption="ticket.sub_type"
                            value={ subTypeOptions }
                            selected={ tracker_subtype_id }
                            viewMode={ !(editMode && tracker_subtype_editable) }
                        />
                        <CustomView
                            caption="scooter.plate"
                            onEditValue={ plate_no }
                            viewMode={ !plate_no_editable }
                        >
                            { plate_no && status_id === STATUS_CLOSED && (
                                plate_no
                            ) }
                            { plate_no && status_id !== STATUS_CLOSED && (
                                <NavLink to={ buildActualPath(SCOOTER_PROFILE, { scooterId: scooter_id }) }>
                                    { plate_no }
                                </NavLink>
                            ) }
                        </CustomView>
                        <Input
                            type={ TYPE_SELECT }
                            innerRef={ this.elStatus }
                            name="status_id"
                            caption="ticket.status"
                            value={ statusOptions }
                            selected={ status_id }
                            viewMode={ !(editMode && status_editable) }
                            onChange={ this.onChangeStatus }
                        />
                        <Input
                            type={ TYPE_SEARCH_DROPDOWNLIST }
                            name="assigned_to_id"
                            caption="ticket.assignee"
                            value={ convertOpUsers(opUsers) }
                            selected={ assigned_to_id }
                            viewMode={ !(editMode && assigned_to_editable) }
                        />
                        <Input
                            type={ TYPE_SELECT }
                            name="severity_id"
                            caption="ticket.priority"
                            value={ priortyOptions }
                            selected={ severity_id }
                            viewMode={ !(editMode && severity_editable) }
                        />
                        <Input
                            type={ TYPE_DATETIME }
                            name="due_date"
                            caption="ticket.due_time"
                            dateFormat={ dateFormat }
                            timeFormat={ timeFormat }
                            value={ due_date }
                            viewMode={ !(editMode && due_date_editable) }
                            onChange={ m => onChange('due_date', m) }
                        />
                        { tracker_id === MAIN_TYPE_DISPATCH && (
                            <>
                                <Input
                                    type={ TYPE_HIDDEN }
                                    name="custom_fields[destination][id]"
                                    value={ CUSTOM_FIELD_DESTINATION }
                                />
                                <Input
                                    type={ TYPE_TEXT }
                                    name="custom_fields[destination][value]"
                                    caption="ticket.destination"
                                    value={ this.custom_fieldsObj[CUSTOM_FIELD_DESTINATION] }
                                    viewMode={ !(editMode && destinationEditable) }
                                    innerRef={ this.elDestination }
                                    onChange={ this.handleCustomFieldChange }
                                />
                            </>
                        ) }
                        { tracker_id === MAIN_TYPE_MAINTENANCE && (
                            <>
                                <Input
                                    type={ TYPE_HIDDEN }
                                    name="custom_fields[dms_no][id]"
                                    value={ CUSTOM_FIELD_DMS_NO }
                                />
                                <Input
                                    type={ TYPE_TEXT }
                                    name="custom_fields[dms_no][value]"
                                    caption="ticket.dms_no"
                                    value={ this.custom_fieldsObj[CUSTOM_FIELD_DMS_NO] }
                                    viewMode={ !(editMode && dmsNoEditable) }
                                    required={ isCloseStatus }
                                    innerRef={ this.elDms_no }
                                    onChange={ this.handleCustomFieldChange }
                                />
                            </>
                        ) }
                        { tracker_id === MAIN_TYPE_MAINTENANCE && (
                            <>
                                <Input
                                    type={ TYPE_HIDDEN }
                                    name="custom_fields[mega_center][id]"
                                    value={ CUSTOM_FIELD_MEGA_CENTRE }
                                />
                                <Input
                                    type={ TYPE_TEXT }
                                    name="custom_fields[mega_center][value]"
                                    caption="ticket.mega_center"
                                    value={ this.custom_fieldsObj[CUSTOM_FIELD_MEGA_CENTRE] }
                                    viewMode={ !(editMode && megaCentreEditable) }
                                    required={ isCloseStatus }
                                    innerRef={ this.elMega_centre }
                                    onChange={ this.handleCustomFieldChange }
                                />
                            </>
                        ) }
                        <Input
                            type={ TYPE_DATETIME }
                            caption="ticket.create_time"
                            dateFormat={ dateFormat }
                            timeFormat={ timeFormat }
                            value={ create_time }
                            viewMode
                        />
                        <Input
                            type={ TYPE_DATETIME }
                            caption="ticket.update_time"
                            dateFormat={ dateFormat }
                            timeFormat={ timeFormat }
                            value={ update_time }
                            viewMode
                        />
                        <Input
                            caption="ticket.reporter"
                            value={ author }
                            viewMode
                        />
                    </FlexGroup>
                </FormGroup>
                { editMode && this.currentStatusId === STATUS_CLOSED && (
                    <FormGroup title={ I18n.t('action_panel.active_status') }>
                        <Input
                            name="scooter_state_action"
                            type={ TYPE_CHECKBOX }
                            withCaption={ false }
                            selected={ doOnline }
                            value={ [{
                                name: 'action_panel.online',
                                value: SCOOTER_ONLINE,
                                translate: true,
                            }] }
                            onChange={ v => onChange('do_online', v) }
                        />
                    </FormGroup>
                ) }
                <FormGroup title={ I18n.t('ticket.description') }>
                    <Input
                        type={ TYPE_TEXTAREA }
                        className="description"
                        name="description"
                        value={ description }
                        viewMode={ !(editMode && description_editable) }
                        withCaption={ false }
                        onChange={ e => onChange('description', e.target.value) }
                    />
                </FormGroup>
                {
                    !editMode ? (
                        <FormGroup title={ I18n.t('ticket.comment') }>
                            <section className="comment-wrapper">
                                {
                                    comments.map(({ id, author, notes, attachments, create_time }) => {
                                        return (
                                            <article key={ id } className="comment">
                                                <p className="notes">
                                                    { notes }
                                                </p>
                                                {
                                                    attachments.map(({ id, url }) => (
                                                        <Attachment key={ id } src={ url } />
                                                    ))
                                                }
                                                <p className="comment-footer">
                                                    <span className="author">{ author }</span>
                                                    <span>
                                                        <Datetime time={ create_time } format={ fullTimeFormat } />
                                                    </span>
                                                </p>
                                            </article>
                                        );
                                    })
                                }
                            </section>
                            <section className="new-comment">
                                <div className="comment-container">
                                    <Input
                                        type={ TYPE_TEXTAREA }
                                        value={ this.elComment.current && this.elComment.current.value }
                                        innerRef={ this.elComment }
                                        name="notes"
                                        withCaption={ false }
                                        disabled={ !notes_editable || updating }
                                        onChange={ this.handleCommentChange }
                                    />
                                </div>
                                <FlexGroup className="attachment-and-buttons" spaceBetween alignStart>
                                    <div>
                                        { updateMediaSrc && (
                                            <Attachment src={ updateMediaSrc } mediaType={ this.attachment.type } />
                                        ) }
                                    </div>
                                    <FlexGroup row end>
                                        <AuthFeature requiredList={ [AUTH_EDIT_TICKET] }>
                                            <div
                                                className={ classNames('paperclip', { disabled: fileUploadDisabled }) }
                                            >
                                                <label className="icon fas fa-paperclip">
                                                    <input
                                                        type={ TYPE_FILE }
                                                        className="file-uploader"
                                                        accept={ ACCEPTED_MEDIA }
                                                        disabled={ !notes_editable || updating }
                                                        onChange={ this.handleMediaPreview }
                                                    />
                                                </label>
                                            </div>
                                        </AuthFeature>
                                        <Button
                                            type="button"
                                            color="primary"
                                            disabled={ !(notes_editable && hasComment) }
                                            onClick={ this.handleSubmitComment }
                                        >
                                            <Translate value="submit" />
                                        </Button>
                                    </FlexGroup>
                                </FlexGroup>
                            </section>
                        </FormGroup>
                    ) : null
                }
                {
                    !editMode ?
                        (
                            <Log logs={ logs } />
                        ) :
                        null
                }
            </div>
        );
    }
}

export default TicketForm;
