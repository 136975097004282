const actionPanel = {
    'title': 'Action',
    'active_status': 'Active Status',
    'online': 'Online',
    'offline': 'Offline',
    'todo': 'Todo',
    'none': 'NONE',
    'dispatch': 'DISPATCH',
    'swap': 'SWAP',
    'maintain': 'MAINTAIN',
    'swap_f': 'SWAP-FLOATING',
    'done': 'Done',
    'done&online': 'Done & Online',
    'no_scooter': 'No Scooters have selected',
};

export default actionPanel;
