import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate, I18n } from 'react-redux-i18n';
import {
    TYPE_FILE,
} from 'components/Form/Input/input-type';
import { IMPORT_LIMIT_5MB } from 'constants/file';
import { importViolationList } from 'actions';
import FlexGroup from 'components/FlexGroup';
import Form from 'components/Form';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import Modal from 'components/Modal';
import Blocker from 'components/Blocker';
import RingSpinner from 'components/Spinner/Ring';
import {
    toggleErrorDialog
} from 'actions';

class ImportFile extends React.Component {
    static propTypes = {
        asyncStart: PropTypes.bool.isRequired,
        onCancel: PropTypes.func,
    }

    static defaultProps = {
        onCancel: () => {},
    }

    constructor(props) {
        super(props);
        this.elFile = React.createRef();
        this.elForm = React.createRef();

        this.state = {
            violation_file: undefined,
            fileName: '',
        };
    }

    onImport = async (file) => {
        const { dispatch } = this.props;
        if (file.size > IMPORT_LIMIT_5MB) {
            return dispatch(toggleErrorDialog(I18n.t('file_size_5MB_warning')));
        }
        await dispatch(importViolationList(file)).then(({ type, error }) => {
            if (type === 'ERROR') {
                const { code } = error.response.data;
                let message = I18n.t(`violation.import_error_${code}`);

                dispatch(toggleErrorDialog(message));
            }
        });
    };

    handleSubmit = () => {
        const { onCancel } = this.props;
        const { violation_file } = this.state;
        if (!violation_file) {
            return;
        }
        else {
            const ret = this.onImport(violation_file);
            if (ret instanceof Promise) {
                ret.then(
                    () => {
                        onCancel();
                    }
                );
            }
        }
    };

    changeViolationFile = e => {
        const file = e.currentTarget.files[0];
        const reader = new FileReader();
        if (file) {
            reader.onload = () => {
                this.setState({
                    fileName: file.name,
                    violation_file: file
                }, () => {
                    this.elFile.current.setCustomErrorMessage('');
                });

            };
            reader.readAsText(file);
        }
    }

    handleChange = name => e => {
        let newState = {};
        switch (name) {
        case 'violation_file':
            this.changeViolationFile(e);
            break;
        default:
            newState[name] = e.target.value;
        }
        this.setState(newState);
    }

    render() {
        const { fileName } = this.state;
        const { onCancel, asyncStart } = this.props;
        const spinnerStyle = classNames({
            'spinner-container': true,
            hide: !asyncStart,
        });

        return (
            <Modal title={ I18n.t('import') } onClose={ onCancel }>
                <Form onSubmit={ this.handleSubmit } className="violation-import" innerRef={ this.elForm }>
                    <Input
                        name="import_violation"
                        type={ TYPE_FILE }
                        accept=".csv"
                        caption="violation.import_violation_list"
                        required
                        onKeyDown={ e => e.preventDefault() }
                        onPaste={ e => e.preventDefault() }
                        onChange={ this.handleChange('violation_file') }
                        value={ fileName }
                        innerRef={ this.elFile }
                    />
                    <FlexGroup end gap>
                        <Button type="button" className="btn-cancel" outline onClick={ onCancel }>
                            <Translate value="cancel" />
                        </Button>
                        <Button color="primary" type="submit">
                            <Translate value="submit" />
                        </Button>
                        <Blocker className={ spinnerStyle }>
                            <RingSpinner />
                        </Blocker>
                    </FlexGroup>
                </Form>
            </Modal>
        );
    }
}

export default connect(state => ({
    i18n: state.i18n,
    asyncStart: state.asyncHandler.get('asyncStart'),
}))(ImportFile);
