import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { CardBody, Card } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Form/Button';
import Form from 'components/Form';
import Input, {
    TYPE_NUMBER,
} from 'components/Form/Input';


class RefundModal extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        show: PropTypes.bool.isRequired,
        item: PropTypes.shape({}),
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        title: PropTypes.string,
    };

    static defaultProps = {
        item: { price: 0 },
        onClose: () => {},
        onSubmit: () => {},
        title: '',
    };

    constructor(props) {
        super(props);

        this.elPrice = React.createRef();
        this.couponId = undefined;
        this.state = {
            inProgress: false,
            refundValue: 0,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.item.price !== state.refundValue) {
            return {
                refundValue: props.item.price || 0
            };
        }

        return null;
    }

    handleSubmit = () => {
        const { onSubmit, item, id } = this.props;
        const price = (this.elPrice.current || {}).value;

        this.setState({
            inProgress: true,
            refundValue: price ? Number(price) : undefined,
        });

        onSubmit({
            [id]: item[id],
            price: price ? Number(price) : undefined,
        }).then(() => {
            this.setState({
                inProgress: false,
            });
        });
    }

    handleKeyPress = e => {
        e.key === 'Enter' && e.preventDefault();
    };

    render() {
        const { inProgress, refundValue } = this.state;
        const { onClose, item, show, title } = this.props;
        const { price } = item;

        return show ? (
            <Modal
                className="view-documents-modal"
                title={ title || I18n.t('rental.refund') }
                onClose={ onClose }
                disabledCloseOnBg
            >
                <Form
                    inline
                    onSubmit={ this.handleSubmit }
                    onKeyPress={ this.handleKeyPress }
                    inProgress={ inProgress }
                >
                    <Card>
                        <CardBody>
                            <Input
                                type={ TYPE_NUMBER }
                                caption="rental.total_charged"
                                value={ price || 0 }
                                viewMode
                            />
                            <Input
                                type={ TYPE_NUMBER }
                                innerRef={ this.elPrice }
                                caption="rental.price"
                                min="0"
                                step="1"
                                max={ price || 0 }
                                value={ refundValue }
                                required
                            />
                        </CardBody>
                    </Card>
                    <ButtonGroup>
                        <Button
                            type="submit"
                            color="primary"
                        >
                            <Translate value="apply" />
                        </Button>
                    </ButtonGroup>
                </Form>
            </Modal>
        ) : null;
    }
}

export default RefundModal;
