import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-redux-i18n';
import Modal from 'components/Modal';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import Input, { TYPE_RADIO, TYPE_FILE } from 'components/Form/Input';
import FlexGroup from 'components/FlexGroup';
import { UPLOAD_SCOOTER_DATA, UPDATE_SCOOTER_PLATE } from 'constants/asset';
import { IMPORT_LIMIT_5MB, CSV_FILE } from 'constants/file';
import { toggleErrorDialog, connectedDevices } from 'actions';
import { ROOT } from 'constants/routes';
import { NavLink } from 'react-router-dom';

class ImportAssetModal extends React.Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onDidImport: PropTypes.func.isRequired,
    };

    state = {
        fileType: UPLOAD_SCOOTER_DATA,
        file: undefined,
    }

    handleFileTypeChange = ([value]) => {
        this.setState({ fileType: value });
    }

    handleFileChange = e => {
        const { dispatch } = this.props;
        const file = e.target.files[0];

        if (!file) {
            return;
        }

        const ext = file.name.split('.').pop();

        if (ext !== CSV_FILE) {
            dispatch(toggleErrorDialog(I18n.t('file_type_csv_warning')));
            return;
        }

        if (file.size > IMPORT_LIMIT_5MB) {
            dispatch(toggleErrorDialog(I18n.t('file_size_5MB_warning')));
            return;
        }

        this.setState({ file });
    }

    handleSubmit = async e => {
        e.preventDefault();
        const { fileType: import_type, file } = this.state;
        const formData = new FormData();
        formData.append('import_type', import_type);
        formData.append('file', file);

        try {
            const { dispatch } = this.props;
            const { error } = await dispatch(connectedDevices(formData));
            if (error) {
                throw error;
            }

            const { onDidImport } = this.props;
            onDidImport();
        }
        catch (error) {
            this.hanldeError(error);
        }
        finally {
            const { onClose } = this.props;
            onClose();
        }
    }

    hanldeError(error) {
        const { dispatch } = this.props;
        const { code, error_message } = error.response?.data ?? {};
        const message = code !== undefined ?
            I18n.t(`asset.error_message_${ code }`, { vin: error_message }) :
            I18n.t('general_error_message');
        dispatch(toggleErrorDialog(message));
    }

    getAssetTemplateFile = () => {
        const url = `${ ROOT }asset_template.csv`;
        window.open(url, '_blank', 'noopener, noreferrer');
    };

    render() {
        const { fileType, file } = this.state;
        const { onClose } = this.props;
        const fileTypeOptions = [
            { name: 'asset.upload_scooter_data', value: UPLOAD_SCOOTER_DATA, translate: true },
            { name: 'asset.update_scooter_plate', value: UPDATE_SCOOTER_PLATE, translate: true },
        ];

        return (
            <Modal className="asset-import-modal" title={ I18n.t('import') } onClose={ onClose }>
                <Form onSubmit={ this.handleSubmit }>
                    <Input
                        className="import-type"
                        name="import_type"
                        type={ TYPE_RADIO }
                        caption="asset.file_type"
                        value={ fileTypeOptions }
                        selected={ fileType }
                        onChange={ this.handleFileTypeChange }
                        required
                    />
                    <FlexGroup className="download-section">
                        <NavLink to={ (fileType === UPLOAD_SCOOTER_DATA) ? `${ ROOT }asset-template.csv` : `${ ROOT }asset-update-plate-template.csv` } target="_blank" rel="noopener noreferrer">
                            <Translate value="download＿tmpl" />
                        </NavLink>
                    </FlexGroup>
                    <Input
                        accept=".csv"
                        name="file"
                        caption="asset.browse_file"
                        type={ TYPE_FILE }
                        value={ file?.name }
                        onChange={ this.handleFileChange }
                        required
                    />
                    <FlexGroup end>
                        <Button color="primary" type="submit">
                            <Translate value="submit" />
                        </Button>
                    </FlexGroup>
                </Form>
            </Modal>
        );
    }
}

export default connect()(ImportAssetModal);
