import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition, animated } from 'react-spring';


const AnimatedText = ({ value, className, transition, size }) => {

    const style = classNames({
        [className]: true,
    });
    const list = (value + '')?.split('') || [];

    const getAnimation = () => {
        return {
            from: {
                opacity: 0,
                transform: `translate3d(0,-${ size }px,0)`,
                fontWeight: 'inherit',
                display: 'inline-block',
            },
            enter: {
                opacity: 1,
                transform: 'translate3d(0,0px,0)',
                fontWeight: 'inherit',
                display: 'inline-block',
            },
            leave: {
                opacity: 0,
                width: 0,
                transform: `translate3d(0,-${ size }px,0)`,
                fontWeight: 'inherit',
                display: 'inline-block',
            },
        };
    };

    const animation = transition ? transition : getAnimation();

    return (
        <Transition
            items={ list }
            keys={ (item, index) => item + index }
            from={ animation?.from }
            enter={ animation?.enter }
            leave={ animation?.leave }

        >
            { (props, item) => <animated.div style={ props } className={ style }>{ item }</animated.div> }
        </Transition>
    );
};

AnimatedText.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    className: PropTypes.string,
    transition: PropTypes.shape({}),
    size: PropTypes.number,
};

AnimatedText.defaultProps = {
    value: undefined,
    className: '',
    transition: undefined,
    size: 0,
};

export default AnimatedText;
