import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import Modal from 'components/Modal';
import Button from 'components/Form/Button';
import FlexGroup from 'components/FlexGroup';
import {
    updateManyFleetAdminUsers,
    toggleErrorDialog,
} from 'actions';
import './remove-user.scss';

const INACTIVE = 0;

class RemoveUser extends React.Component {
    static propTypes = {
        users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        selectedIdList: PropTypes.arrayOf(PropTypes.string).isRequired,
        onClose: PropTypes.func.isRequired,
        onRemoved: PropTypes.func.isRequired,
    }

    getSelectedUserNameList() {
        const { users, selectedIdList } = this.props;

        return users.reduce((accu, user) => {
            if (selectedIdList.includes(user.id)) {
                accu.push(user.name);
            }

            return accu;
        }, []);
    }

    handleRemove = async () => {
        const { onRemoved, selectedIdList, dispatch } = this.props;
        const formData = {
            ids: selectedIdList,
            status: INACTIVE,
        };

        try {
            const { error } = await dispatch(updateManyFleetAdminUsers(formData));

            if (error) {
                throw error;
            }

            onRemoved();
        }
        catch {
            dispatch(toggleErrorDialog(I18n.t('ticket.general_error_message')));
        }
    }

    render() {
        const { onClose } = this.props;
        const selectedUserNameList = this.getSelectedUserNameList();

        return (
            <Modal className="remove-user" title={ I18n.t('admin.remove') } onClose={ onClose }>
                <div className="message">
                    { I18n.t('admin.confirm_remove') }
                    { selectedUserNameList.join('、') }
                    ?
                </div>
                <FlexGroup end gap>
                    <Button type="button" className="btn-cancel" onClick={ onClose } outline>
                        <Translate value="cancel" />
                    </Button>
                    <Button color="primary" onClick={ this.handleRemove }>
                        <Translate value="admin.remove" />
                    </Button>
                </FlexGroup>
            </Modal>
        );
    }
}

export default connect()(RemoveUser);
