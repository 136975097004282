import {
    STATUS_DRAFT,
    STATUS_SCHEDULED,
    STATUS_PROCESSING,
    STATUS_FINISHING,
    STATUS_FAILED,

    TYPE_SEND_NOTIFICATION,
    TYPE_SEND_COUPON_AND_NOTIFICATION,

    USER_IDS,
    USER_PHONES,

    // error code
    TITLE_NOT_VALID,
    TYPE_NOT_VALID,
    SCHEDULE_TIME_NOT_VALID,
    COUPON_ID_NOT_VALID,
    NOTIFICATION_TITLE_NOT_VALID,
    NOTIFICATION_BODY_NOT_VALID,
    NOTIFICATION_SOUND_NOT_VALID,
    NOTIFICATION_OPEN_URL_NOT_VALID,
    USER_FILE_LIST_NOT_VALID,
    USER_FILE_LIST_USER_ID_NOT_EXISTS,
    NOTIFICATION_COUPON_DEFAULT_NOT_VALID,
    CAN_NOT_CHANGE_STATUS,
    CAN_NOT_MODIFY_CONTENT,
    USER_FILE_LIST_HEADER_NOT_VALID,
    USER_FILE_LIST_CONTENT_CANNOT_EMPTY,
    USER_FILE_LIST_USER_ID_CANNOT_EMPTY,
    USER_FILE_LIST_USER_ID_FORMAT_NOT_VALID,
    USER_FILE_LIST_PHONE_CANNOT_EMPTY,
    USER_FILE_LIST_PHONE_FORMAT_NOT_VALID,
    USER_LIST_TYPE_IS_NOT_VALID,
} from 'constants/promotion';

const promotion = {
    title: '標題',
    promotion_id: '促銷活動編號',
    type: '類型',
    status: '狀態',
    schedule_time: '排程時間',
    search_for: '搜尋標題',
    [`type_${ TYPE_SEND_NOTIFICATION }`]: '發佈通知訊息',
    [`type_${ TYPE_SEND_COUPON_AND_NOTIFICATION }`]: '發送折扣',
    [`status_${ STATUS_DRAFT }`]: '草案',
    [`status_${ STATUS_SCHEDULED }`]: '已排程',
    [`status_${ STATUS_PROCESSING }`]: '處理中',
    [`status_${ STATUS_FINISHING }`]: '完成',
    [`status_${ STATUS_FAILED }`]: '異常',
    new_promotion: '新增促銷活動',
    edit_promotion_info: '編輯促銷活動',
    details: '詳細資料',
    coupon_name: '折扣名稱',
    notification_title: '通知訊息標題',
    notification_body: '通知訊息內容',
    notification_sound: '通知音效',
    notification_open_url: '通知訊息連結網址',
    user_list_file: '匯入用戶編號',
    notification_coupon_default: '使用預設通知訊息',
    publish: '發佈',
    withdraw: '撤銷',
    view_title: '促銷活動資訊',
    user_list_type: '匯入類型',
    [`import_user_list_type_${ USER_IDS }`]: '匯入用戶編號',
    [`import_user_list_type_${ USER_PHONES }`]: '匯入用戶電話',
    [`user_list_type_${ USER_IDS }`]: '用戶編號',
    [`user_list_type_${ USER_PHONES }`]: '用戶電話',
    result: '促銷發送結果',
    [`error_message_${ TITLE_NOT_VALID }`]: '無效的標題',
    [`error_message_${ TYPE_NOT_VALID }`]: '無效的類型',
    [`error_message_${ SCHEDULE_TIME_NOT_VALID }`]: '無效的排程時間',
    [`error_message_${ COUPON_ID_NOT_VALID }`]: '無效的系統折扣',
    [`error_message_${ NOTIFICATION_TITLE_NOT_VALID }`]: '無效的通知訊息標題',
    [`error_message_${ NOTIFICATION_BODY_NOT_VALID }`]: '無效的通知訊息內容',
    [`error_message_${ NOTIFICATION_SOUND_NOT_VALID }`]: '無效的通知音效',
    [`error_message_${ NOTIFICATION_OPEN_URL_NOT_VALID }`]: '無效的通知訊息連結網址',
    [`error_message_${ USER_FILE_LIST_NOT_VALID }`]: '無效的用戶編號檔案',
    [`error_message_${ USER_FILE_LIST_USER_ID_NOT_EXISTS }`]: '用戶編號不存在',
    [`error_message_${ CAN_NOT_CHANGE_STATUS }`]: '無法修改狀態',
    [`error_message_${ CAN_NOT_MODIFY_CONTENT }`]: '已排程時，無法修改內容',
    [`error_message_${ NOTIFICATION_COUPON_DEFAULT_NOT_VALID }`]: '無效的預設通知訊息',
    [`error_message_${ USER_FILE_LIST_HEADER_NOT_VALID }`]: '用戶檔案的標頭無效',
    [`error_message_${ USER_FILE_LIST_CONTENT_CANNOT_EMPTY }`]: '用戶檔案內容不能為空',
    [`error_message_${ USER_FILE_LIST_USER_ID_CANNOT_EMPTY }`]: '用戶檔案的編號不能為空',
    [`error_message_${ USER_FILE_LIST_USER_ID_FORMAT_NOT_VALID }`]: '用戶檔案的編號無效',
    [`error_message_${ USER_FILE_LIST_PHONE_CANNOT_EMPTY }`]: '用戶檔案的電話不能為空',
    [`error_message_${ USER_FILE_LIST_PHONE_FORMAT_NOT_VALID }`]: '用戶檔案的電話無效',
    [`error_message_${ USER_LIST_TYPE_IS_NOT_VALID }`]: '匯入類型無效',
};

export default promotion;
