import React from 'react';
import PropTypes from 'prop-types';
import Cell from './Cell';
import CheckboxCell from './CheckboxCell';

class ListRow extends React.Component {
    static propTypes = {
        onSelect: PropTypes.func,
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        data: PropTypes.shape().isRequired,
        dataKey: PropTypes.string,
        selectable: PropTypes.bool,
        selected: PropTypes.bool,
    };

    static defaultProps = {
        onSelect: () => {},
        selectable: false,
        selected: false,
        dataKey: undefined,
    };

    handleSelect = () => {
        const { dataKey, data, onSelect } = this.props;

        onSelect([data[dataKey]]);
    };

    render() {
        const { columns, data, selectable, selected } = this.props;

        return  (
            <React.Fragment>
                {
                    selectable ? (
                        <CheckboxCell onSelect={ this.handleSelect } selected={ selected } />
                    ) : null
                }
                {
                    columns.map(({ key, width }) => (
                        <Cell
                            key={ key?.toString() }
                            name={ key }
                            style={ { width } }
                        >
                            <span>{ data[key] }</span>
                        </Cell>
                    ))
                }
            </React.Fragment>
        );
    };
};


export default ListRow;
