import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ToolButton } from './index';


class LocateToCurrentLocation extends Component {
    static propTypes = {
        currentGeoLocation: PropTypes.shape(),
        map: PropTypes.shape(),
    };

    static defaultProps = {
        currentGeoLocation: undefined,
        map: undefined,
    };

    handleLocate = () => {
        const { map, currentGeoLocation } = this.props;

        if (currentGeoLocation && map) {
            const { coords } = currentGeoLocation;
            const { latitude, longitude } = coords;

            map.panTo({
                lat: latitude,
                lng: longitude,
            });
            map.setZoom(15);
        }
    }

    render() {
        return (
            <ToolButton onClick={ this.handleLocate }>
                <span className="fas fa-location-arrow" />
            </ToolButton>
        );
    }
}

export default connect(state => ({
    currentGeoLocation: state.geolocation.get('currentLocation'),
}))(LocateToCurrentLocation);
