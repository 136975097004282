import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import TicketDetail from './TicketDetail';
import Log from './TicketDetail/Form/Log';
import ScooterDetail from './ScooterDetail';

class DetailsSection extends Component {
    static propTypes = {
        params: PropTypes.shape({}).isRequired,
        oneTicket: ImmutablePropTypes.map.isRequired,
        onTicket: PropTypes.func.isRequired,
        onScooter: PropTypes.func.isRequired,
        onBack: PropTypes.func.isRequired,
        onFetch: PropTypes.func.isRequired,
        waitRetrieve: PropTypes.bool,
    };
    static defaultProps = {
        waitRetrieve: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
        };
    };

    componentWillUnmount() {
        const { onFetch } = this.props;
        onFetch(true);
    }

    toggleEditMode = () => {
        const { editMode } = this.state;
        this.setState({ editMode: !editMode });
    }

    render() {
        const { editMode } = this.state;
        const {
            params,
            oneTicket,
            onTicket,
            onScooter,
            onBack,
            waitRetrieve,
        } = this.props;

        const jsonOneTicket = oneTicket.toJS();
        const {
            journal = {},
        } = jsonOneTicket;
        const { logs = [] } = journal;

        if (params['ticketId']) {
            return (
                <>
                    <TicketDetail
                        params={ params }
                        editMode={ editMode }
                        onToggle={ this.toggleEditMode }
                        onFetch={ () => onTicket(params) }
                        customAction={ onBack }
                        waitRetrieve={ waitRetrieve }
                    >
                        {
                            !editMode ?
                                (
                                    <Log logs={ logs } />
                                ) :
                                null
                        }
                    </TicketDetail>
                </>
            );
        }
        return (
            <ScooterDetail
                params={ params }
                onFetch={ () => onScooter(params) }
                customAction={ onBack }
            />
        );
    }
}

export default connect((state) => ({
    oneTicket: state.ticket.get('oneTicket'),
}))(DetailsSection);
