import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from 'components/Form/Button';
import Modal from 'components/Modal';
import AuthView from 'components/AuthView';
import ChoiceGroup from 'components/ChoiceGroup';
import FlexGroup from 'components/FlexGroup';
import { getDefaultPageSize } from 'components/Pagination/PageSizeSelect';
import SearchField from 'components/SearchField';
import MultiselectFilter from 'components/Filter/MultiselectFilter';
import DatetimeRangeFilter from 'components/Filter/DatetimeRangeFilter';
import { Translate, I18n } from 'react-redux-i18n';
import { convertToUTC } from 'helpers/time-handler';
import List from './List';
import {
    updateDocumentTitle,
    fetchRentalLog,
    exportRentalLog,
    closeRentalExport,
} from 'actions';
import {
    ALL_RENTAL_LOG_TYPE,
} from 'constants/log';
import './rental-log-list.scss';

const FILTER_WHO = 'modified_by_user_account';
const FILTER_TYPE = 'type';
const FILTER_TIME = 'auditlog_time';
const FILTER_START_TIME = 'auditlog_start_time';
const FILTER_END_TIME = 'auditlog_end_time';
const FILTER_RENTAL_ID = 'rental_id';

class RentalAudit extends React.Component {
    static propTypes = {
        exportFinished: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        const { start, end } = this.getDefaultPeriod();

        this.state = {
            appliedDateRange: false,
            searchType: FILTER_WHO,
            fromTime: moment(start),
            toTime: moment(end),
            queryPayload: {
                search: {},
                filter: {
                    [FILTER_TYPE]: undefined,
                    [FILTER_START_TIME]: undefined,
                    [FILTER_END_TIME]: undefined,
                },
                sort: {},
                pageSize: getDefaultPageSize().value,
                pageIndex: 1,
            },
        };
    }

    componentDidMount() {
        const { dispatch, location } = this.props;
        const { pathname } = location;
        dispatch(updateDocumentTitle(`routes.${ pathname }`));
    }

    componentDidUpdate(prevProps, prevState) {
        const { queryPayload } = this.state;
        if (prevState.queryPayload !== queryPayload) {
            this.fetchData();
        }

    }

    getDefaultPeriod = () => {
        return {
            start: moment().subtract(30, 'days'),
            end: moment(),
        };
    }

    fetchData = () => {
        let period = this.getDefaultPeriod();

        const { dispatch } = this.props;
        const { queryPayload } = this.state;
        const { search, filter, sort, pageSize, pageIndex } = queryPayload;
        const { auditlog_start_time, auditlog_end_time } = filter;
        return dispatch(fetchRentalLog({
            ...filter,
            ...search,
            sort,
            auditlog_start_time: convertToUTC(auditlog_start_time || period.start.startOf('day')),
            auditlog_end_time:
                convertToUTC(auditlog_end_time || period.end.startOf('day').add(1, 'd')),
            page: pageIndex,
            size: pageSize,
        }));
    }

    handleListSort = ({ field, order }) => {
        const { queryPayload } = this.state;
        this.setState({
            queryPayload: {
                ...queryPayload,
                pageIndex: 1,
                sort: field && order ? `${ field },${ order }` : undefined,
            }
        });
    }

    handleFilterChange = type => value => {
        let { searchType } = this.state;
        const { queryPayload } = this.state;
        let { filter, search } = queryPayload;
        const updatedState = {};

        switch (type) {
        case FILTER_WHO:
        case FILTER_RENTAL_ID:
            this.keyword = value === '' ? undefined : value;
            search = {
                [searchType]: this.keyword,
            };
            filter = {};
            updatedState.appliedDateRange = false;
            break;
        case FILTER_TYPE:
            filter[FILTER_TYPE] = value.inputSelect;
            break;
        case FILTER_TIME:
            const { from, to } = value;
            filter[FILTER_START_TIME] = from;
            filter[FILTER_END_TIME] = to;
            updatedState.appliedDateRange = !!(from && to);
            break;
        default:
        }

        let period = {};
        period = this.getDefaultPeriod();

        this.setState({
            fromTime: filter[FILTER_START_TIME] ? undefined : period.start,
            toTime: filter[FILTER_END_TIME] ? undefined : period.end,
            queryPayload: {
                ...queryPayload,
                pageIndex: 1,
                search,
                filter,
            }
        });

        if (Object.keys(updatedState).length > 0) {
            this.setState(updatedState);
        }
    };

    handlePageSelect = value => {
        const { queryPayload } = this.state;
        this.setState({
            queryPayload: {
                ...queryPayload,
                pageIndex: value,
            }
        });
    }

    handlePageSizeChange = pageSizeOption => {
        const { queryPayload } = this.state;
        this.setState({
            queryPayload: {
                ...queryPayload,
                pageIndex: 1,
                pageSize: pageSizeOption.value,
            }
        });
    }

    handlePeriodChanged = ({ from, to }) => {
        const { queryPayload } = this.state;
        const { filter } = queryPayload;

        filter[FILTER_START_TIME] = from;
        filter[FILTER_END_TIME] = to;

        this.setState({});
    }

    handleSearchTypeChange = e => {
        const nextSearchType = e.currentTarget.value;

        this.setState({
            searchType: nextSearchType,
        }, () => {
            const { searchType } = this.state;
            this.handleFilterChange(searchType)(this.keyword);
        });
    }

    handleExport = () => {
        const { dispatch } = this.props;
        const { queryPayload } = this.state;
        const { search, filter } = queryPayload;
        const { auditlog_start_time, auditlog_end_time } = filter;

        dispatch(exportRentalLog({
            ...filter,
            ...search,
            auditlog_start_time: auditlog_start_time ?
                convertToUTC(auditlog_start_time) : convertToUTC(moment().subtract(30, 'days')),
            auditlog_end_time: auditlog_end_time ?
                convertToUTC(auditlog_end_time) : convertToUTC(moment()),
        }));
    }

    render() {
        const { exportFinished, dispatch } = this.props;
        const { appliedDateRange, searchType, fromTime, toTime, queryPayload } = this.state;
        const { filter, pageSize } = queryPayload;
        const options = ALL_RENTAL_LOG_TYPE.map(
            value => ({
                text: I18n.t(`log.type_${ value }`),
                value,
            })
        );

        return (
            <AuthView className="rental-log-list">
                <FlexGroup start className="search-section">
                    <Translate className="search-for-caption" value="search_for" />
                    <ChoiceGroup
                        defaultChecked={ searchType }
                        options={ [
                            {
                                id: FILTER_WHO,
                                name: I18n.t('email'),
                                value: FILTER_WHO,
                            },
                            {
                                id: FILTER_RENTAL_ID,
                                name: I18n.t('rental.rental_no'),
                                value: FILTER_RENTAL_ID,
                            },
                        ] }
                        onChange={ this.handleSearchTypeChange }
                    />
                    <SearchField
                        placeholder={ I18n.t(`log.search_for_${ searchType }`) }
                        onSubmit={ this.handleFilterChange(searchType) }
                        value={ this.keyword }
                    />
                    <FlexGroup start className="filters">
                        <MultiselectFilter
                            title={ I18n.t('log.type') }
                            options={ options }
                            defaultSelected={ filter[FILTER_TYPE] }
                            onChange={ this.handleFilterChange(FILTER_TYPE) }
                        />
                        <DatetimeRangeFilter
                            range={ [30, 'days'] }
                            title={ I18n.t('log.create_time') }
                            defaultFrom={ filter[FILTER_START_TIME] }
                            defaultTo={ filter[FILTER_END_TIME] }
                            onChange={ this.handlePeriodChanged }
                            onApply={ this.handleFilterChange(FILTER_TIME) }
                            applied={ appliedDateRange }
                        />
                    </FlexGroup>
                    <Button
                        color="primary"
                        onClick={ this.handleExport }
                        type="button"
                        className="export-button"
                    >
                        <Translate value="export" />
                    </Button>
                </FlexGroup>
                <List
                    fromTime={ fromTime }
                    toTime={ toTime }
                    onSort={ this.handleListSort }
                    onSelect={ this.handlePageSelect }
                    onChange={ this.handlePageSizeChange }
                    pageSize={ pageSize }
                    onFetch={ this.fetchData }
                />
                {
                    exportFinished && (
                        <Modal
                            title={ I18n.t('export') }
                            onClose={ () => {
                                dispatch(closeRentalExport());
                            } }
                        >
                            <FlexGroup start>
                                <Translate value="log.export_finish" />
                            </FlexGroup>
                            <FlexGroup end>
                                <Button
                                    color="primary"
                                    onClick={ () => {
                                        dispatch(closeRentalExport());
                                    } }
                                >
                                    <Translate value="ok" />
                                </Button>
                            </FlexGroup>
                        </Modal>
                    )
                }
            </AuthView>
        );
    }
};

export default connect(state => ({
    i18n: state.i18n,
    exportFinished: state.log.get('exportFinished'),
}))(RentalAudit);
