import { VIEW_VM_LIST } from 'constants/permission';
import { VMS } from './../Toolbox';
import permissionHandler from 'helpers/permission-handler';

const getAuthedToolBoxList = (list) => {
    let result = list;
    if (list.length && !permissionHandler({ requiredList: [VIEW_VM_LIST] })) {
        result = list.filter(item => item !== VMS ); 
    }
    return result;
};
export default getAuthedToolBoxList;