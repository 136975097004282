const datetimeFormat = {
    'date': 'YYYY-MM-DD',
    'time': 'HH:mm:ss',
    'long': 'YYYY-MM-DD HH:mm:ss',
    'utc': 'YYYY-MM-DDTHH:mm:ss',
};
const translation = {
    'lang': '中文',
    'km': '公里',
    'times': '次',
    'm': 'm',
    'fleet_management': 'Fleet Management',
    'login': '登入',
    'logout': '登出',
    'refrsh': '刷新頁面',
    'export': '匯出',
    'import': '匯入',
    'close': '關閉',
    'cancel': '取消',
    'continue': '繼續',
    'update_time': '更新時間',
    'disabled': '已停用',
    'unknown': '不明',
    'from': '起',
    'to': '迄',
    'go': 'Go',
    'duration': '時間',
    'distance': '距離',
    'do_action': 'Do Action',
    'user_id': '使用者ID',
    'id': 'ID',
    'id_no': '身份證',
    'id_verify': '身份驗證',
    'license_no': '駕照',
    'license_verify': '駕照驗證',
    'birthday': '出生日期',
    'email': '電子信箱',
    'total_orders': '總訂單',
    'credit_card_no': '信用卡',
    'payment_method': '付款方式',
    'join_date': '加入日期',
    'success': '成功',
    'fail': '失敗',
    'unverified': '未驗證',
    'readability_issue': '無法辨識',
    'document_not_match': '證件不符',
    'flawed': '問題證件',
    'ok': '好',
    'login_failed': '登入失敗',
    'update_failed': '更新失敗',
    'search': '搜尋',
    'reservation_time': '預約時間',
    'start_time': '開始騎乘',
    'end_time': '結束時間',
    'price': '價錢',
    'payment_status': '付款狀態',
    'edit': '編輯',
    'save': '儲存',
    'apply': '套用',
    'yes': '是',
    'no': '否',
    'know': '我知道了',
    'have': '有',
    'not_have': '無',
    'warning': '警告',
    'confirm': '確認',
    'language_settings': '語言設定',
    'browse_files': '瀏覽',
    'choose_files': '選擇檔案...',
    'choose_file': '選擇檔案',
    'submit': '送出',
    'replace': '置換',
    'datetime_format': datetimeFormat,
    'total_count': '總數',
    'general_error': '發生錯誤，請重新操作',
    'search_a_place': '搜尋地點',
    'select_all': '全選',
    'total_selection': '已選',
    'rental_management': '租賃管理',
    'clear': '清除',
    'clear_all': '清除全部',
    'years': '年',
    'months': '月',
    'weeks': '週',
    'days': '天',
    'hours': '小時',
    'minutes': '分鐘',
    'seconds': '秒',
    'search_for': '搜尋',
    'empty': '未設定',
    'preview': '預覽',
    'without_permission_error_message': '沒有存取權限',
    'apiValidateError': '連線階段已過期，請重新登入',
    'jump_to': '跳轉至',
    'digits': '位數',
    'map_layer': '地圖圖層',
    'download': '下載',
    'download＿tmpl': '下載範本',
    'file_size_5MB_warning': '檔案大小不得超過 5 MB',
    'file_type_csv_warning': '檔案應為 csv 格式',
    'please_login': '請再次登入',
    'please_login_message': (
        '為了維護您的工作安全，連線階段已過期。' +
        '請重新整理瀏覽器, 或點擊登入'
    ),
    'general_error_message': '哦喔! 好像發生問題了，請再試一次',
    'link': '連結',
    'restore': '復原',
    'corp_warning': '同時有分屬不同公司的車輛被選取(%{companies})',
    'enable': '啟用',
    'disable': '停用',
    'attachments': '附件',
    'no_match': '查無結果',
    'no_file_uploaded': '未上傳檔案',
    'reupload': '重新選擇',
    'no_access_right': '無權限',
    'copy': '複製',
    'data_update_time': '下列資料更新於',
};

export default translation;
