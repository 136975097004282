/**
 * convert into Array<String>
 */
import typeArray from './index';

const arrayString = val => {
    const checker = n => n || n === '' || n === 0;
    /* istanbul ignore next */
    const converter = n => checker(n) ? String(n) : undefined;

    return typeArray(val, checker, converter);
};

export default arrayString;
