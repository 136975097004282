import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-redux-i18n';

class CustomView extends Component {
    static propTypes = {
        // translation key
        caption: PropTypes.string,
        withCaption: PropTypes.bool,
        viewMode: PropTypes.bool,
        onEditValue: PropTypes.string,
    };

    static defaultProps = {
        caption: '',
        withCaption: true,
        viewMode: false,
        onEditValue: '',
    };

    renderControl () {
        const { viewMode, children, onEditValue } = this.props;
        // this.props.chidren is view mode content
        return !viewMode ?
            (
                <span className="view-control">{ onEditValue }</span>
            ) :
            (
                <div className="view-control">{ children }</div>
            );
    }

    render() {
        const { caption, viewMode, withCaption } = this.props;
        const wrapperClass = classNames({
            'custom-view': true,
            'control-group': true,
            'view-mode': viewMode,
        });
        const captionClass = classNames({
            caption: true,
        });
        const controlClass = classNames({
            'control-wrapper': true,
            'full-width': !withCaption,
        });

        return (
            <div className={ wrapperClass }>
                {
                    withCaption ?
                        (
                            <Translate className={ captionClass } value={ caption } tag="h5" />
                        ) :
                        null
                }
                <div className={ controlClass }>
                    { this.renderControl() }
                </div>
            </div>
        );
    }
}

export default CustomView;
