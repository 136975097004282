import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import mobile from 'is-mobile';
import ImmutablePropTypes from 'react-immutable-proptypes';
import OrderableListView from 'components/OrderableListView';
import MissionOrderListRow from './MissionOrderListRow';
import { headerConfig } from './headerConfig';

class MissionOrderList extends React.Component {
    static propTypes = {
        orderableList: ImmutablePropTypes.map.isRequired,
        onMoveMission: PropTypes.func,
    };

    static defaultProps = {
        onMoveMission: () => {},
    };


    render() {
        const { orderableList, onMoveMission } = this.props;
        const { data_list: missionDataList } = orderableList.toJS();
        return (
            <section className="orderable-mission-list">
                <OrderableListView
                    dataKey="id"
                    header={ headerConfig }
                    list={ missionDataList }
                    renderOrderableListRow={ MissionOrderListRow }
                    onMoveRow={ onMoveMission }
                    fixed={ mobile() }
                />
            </section>
        );
    }
};


export default connect(state => ({
    i18n: state.i18n,
    orderableList: state.mission.get('orderableList'),
}))(MissionOrderList);
